import React, { Component } from 'react';
import styled from 'styled-components/macro';
import { connect } from 'react-redux';
import { dismissAlert } from 'actions';
import { FLASH_MESSAGES } from 'constants/appRelated';

const Notification = styled.div`
  border-left: 4px solid
    ${(p) => (p.type === FLASH_MESSAGES.SUCCESS ? '#44AF69' : '#E32019')};
  position: fixed;
  top: 15px;
  right: 15px;
  z-index: 9999;
  box-shadow: ${(p) => p.theme.shadow};
  padding: 16px 16px 16px 64px;
  background: #fff
    url(/images/icons/notifications/${(p) =>
        p.type === FLASH_MESSAGES.SUCCESS ? 'ico_success.svg' : 'ico_error.svg'})
    no-repeat 20px center;
  max-width: 500px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  visibility: ${(p) => (p.open ? 'visible' : 'hidden')};
`;

const Content = styled.div`
  width: 100%;
`;

const NotificationType = styled.div`
  font-weight: 600;
  color: #2b4c78;
  margin-bottom: 8px;
`;

const NotificationText = styled.span`
  color: #2f243a;
`;

const CloseBtn = styled.button`
  appearance: none;
  border: none;
  outline: none;
  background: transparent;
  padding: 4px 12px;
  cursor: pointer;
`;

class Alert extends Component {
  componentDidUpdate() {
    const { opened } = this.props;
    if (opened) {
      setTimeout(() => {
        this.handleRequestClose();
      }, 6000);
    }
  }

  handleRequestClose = () => {
    const { dispatch } = this.props;
    dispatch(dismissAlert());
  };

  render() {
    const { message, opened, type } = this.props;
    return (
      <Notification open={opened} type={type}>
        <Content>
          <NotificationType>
            {type === FLASH_MESSAGES.SUCCESS ? 'Success' : 'Error'}
          </NotificationType>
          <NotificationText>{message}</NotificationText>
        </Content>
        <CloseBtn onClick={this.handleRequestClose}>Close</CloseBtn>
      </Notification>
    );
  }
}

function select(state) {
  const {
    alert: { message, type, opened },
  } = state;
  return {
    message,
    type,
    opened,
  };
}

export default connect(select)(Alert);
