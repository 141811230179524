import styled from 'styled-components/macro';
import { ButtonExtended } from './Button';

export const ButtonText = styled(ButtonExtended)`
  padding: 0;
  margin: 0;
  outline: none;
  color: #4394ea;
  font-size: 14px;
  line-height: 16px;
  background-color: #fff;
  border: none;
  font-weight: normal;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }

  &:disabled {
    text-decoration: none;
  }
`;

ButtonText.displayName = 'ButtonText';
