import React from 'react';
import { UserAvatar } from 'components/UserAvatar';
import styled from 'styled-components/macro';
import { LENDER } from 'constants/appRelated';
import { Text } from 'ui/Text';
import { connect } from 'react-redux';
import {
  getLenderDetailsRequest,
  getBbDetailsRequest,
} from 'containers/UserDetails/actions';
import { UserRepresentation } from 'styles/global';
import { userTypeSelector } from 'selectors/userType';

const User = ({ user, userType, dispatch }) => {
  const handleClick = () => {
    if (user?.pk) {
      if (user?.accountType === LENDER && !userType.lender) {
        dispatch(getLenderDetailsRequest(user?.pk));
      }

      if (user?.accountType !== LENDER) {
        dispatch(getBbDetailsRequest(user?.pk));
      }
    }
  };

  return (
    <OfferUserWrapper onClick={handleClick}>
      <UserAvatar author={user} />
      {user?.accountType === LENDER ? (
        <OfferUserData>
          <Heading>
            <UserRepresentation data-testid="userRepresentation">
              {`${user?.firstName} ${user?.lastName} `}
              {(user?.businessName && `from ${user?.businessName}`) || ''}
            </UserRepresentation>
          </Heading>
          <SubHeading>{user?.lenderTypeDisplay || 'Default Lender'}</SubHeading>
        </OfferUserData>
      ) : (
        <OfferUserData>
          <Heading>
            {user?.pk ? (
              <UserRepresentation data-testid="userRepresentationCounterOfferAccepted">
                {`${user?.firstName} ${user?.lastName}`}
              </UserRepresentation>
            ) : (
              `${user?.firstName} ${user?.lastName}`
            )}
          </Heading>
          {user?.businessName && (
            <SubHeading data-testid="businessNameCounterOfferAccepted">
              {user?.businessName}
            </SubHeading>
          )}
        </OfferUserData>
      )}
    </OfferUserWrapper>
  );
};

function mapStateToProps(state) {
  return {
    userType: userTypeSelector(state),
  };
}

export default connect(mapStateToProps)(User);

const OfferUserWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
`;

const OfferUserData = styled.div`
  margin-left: 8px;
`;

const Heading = styled(Text)`
  margin-bottom: 2px;
  font-size: 14px;
  line-height: 1;
`;

const SubHeading = styled(Text)`
  font-size: 12px;
  color: #778598;

  margin-bottom: 0;
  line-height: 1;
`;
