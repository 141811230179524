import types from 'constants/actionTypes';

export const getLoanActivityItemsSuccess = (payload = {}) => ({
  type: types.GET_LOAN_ACTIVITY_ITEMS_SUCCESS,
  payload,
});

export const getLoanActivityItemsFailure = (payload = {}) => ({
  type: types.GET_LOAN_ACTIVITY_ITEMS_FAILURE,
  payload,
});

export const getLoanActivityItemsRequest = (payload = {}) => ({
  type: types.GET_LOAN_ACTIVITY_ITEMS_REQUEST,
  payload,
});

export const showLoanActivityWidget = (payload = {}) => ({
  type: types.SHOW_LOAN_ACTIVITY_WIDGET,
  payload,
});

export const hideLoanActivityWidget = (payload = {}) => ({
  type: types.HIDE_LOAN_ACTIVITY_WIDGET,
  payload,
});