import React from 'react';
import { Text } from 'ui/Text';
import { NotificationWrapper, NotificationBottomLine } from 'features/Dashboard/styles';
import { AnswerForm } from './AnswerForm';

export const LenderRequiredComment = ({ question: { question, id }, submitAnswer }) => (
  <NotificationWrapper>
    <Text>{question}</Text>
    <NotificationBottomLine />
    <AnswerForm
      submitAnswer={(message) => {
        submitAnswer({ message, id });
      }}
    />
  </NotificationWrapper>
);
