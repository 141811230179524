import React from 'react';
import styled from 'styled-components/macro';

const Wrapper = styled.div`
  width: 100%;
  max-width: 555px;
  margin: 0 auto;
  background-color: #f6f6f8;
  border-radius: 8px;
  text-align: center;
  padding: 18px 8px 24px;
`;

const Title = styled.p`
  margin: 0 0 16px;
  font-weight: bold;
  line-height: 24px;
  font-size: 18px;
`;

export const UnlockStub = () => (
  <Wrapper>
    <Title>This information is hidden from you</Title>
  </Wrapper>
);
