import React from 'react';
import styled from 'styled-components/macro';
import { Text } from 'ui/Text';

const HeadTitle = styled(Text)`
  margin-bottom: 24px;
  text-align: center;
  font-weight: bold;
`;

const Paragraph = styled(Text)`
  margin-bottom: 24px;

  a {
    color: #4394ea;
  }
`;

const IndentParagraph = styled(Paragraph)`
  text-indent: 1.5em;
`;

const ListItem = styled.li`
  ${Paragraph} {
    margin-bottom: 24px;
  }
`;

export const ConfidentialityAgreementText = () => (
  <div>
    <HeadTitle>
      Only serious participants and eligible lenders authorized by RealAtom are permitted
      to accept the terms of this Confidentiality Agreement. Access for any other purpose
      is strictly prohibited.
    </HeadTitle>
    <HeadTitle>CONFIDENTIALITY AGREEMENT</HeadTitle>
    <Paragraph>
      This Confidentiality Agreement (<b>&quot;Agreement&quot;</b>) is entered into by and
      among RealAtom, Inc. (<b>&quot;RealAtom&quot;</b>), the owner of certain
      confidential information available through RealAtom (<b>&quot;Client&quot;</b>), and
      the party seeking to access such information (<b>&quot;Lender&quot;</b>,{' '}
      <b>&quot;Lender’s&quot;</b>), and is effective on the date executed and accepted by
      Lender.
    </Paragraph>
    <IndentParagraph>
      A. RealAtom provides loan management services to Client as to certain of
      Client&apos;s commercial real estate assets or notes secured by real property (each,
      a <b>&quot;Property&quot;</b>), and in connection therewith makes available to
      Lender certain Confidential Information (as defined below) related to the Property
      or Properties ;
    </IndentParagraph>
    <IndentParagraph>
      B. Lender desires to gain access to certain Confidential Information via RealAtom
      for a Property or properties in addition to information on the owner or owners of
      said properties to extend commercial financing for such property or properties. (the
      <b> &quot;Transaction&quot;</b>). RealAtom and Client are willing to provide access
      to Lender, provided Lender agree as follows:
    </IndentParagraph>
    <ol>
      <ListItem>
        <Paragraph>
          <u>Confidential Information.</u> <b>&quot;Confidential Information&quot;</b>{' '}
          shall mean any and all information provided orally, in writing, or by any other
          media, to Lender or Lender Representatives (as defined below) in connection with
          the Property by RealAtom, the Client and/or agents thereof. Confidential
          Information shall include, but is not limited to, Property information, Property
          owner and owner affiliates information, tenant information, leasing information,
          marketing data and plans, previous or existing financing information, title
          reports, appraisal reports, environmental information, trade secrets, and any
          intellectual property therein or related thereto. This Agreement pertains to all
          Confidential Information regarding the Property provided by RealAtom or Client
          to Lender, whether provided before or after the date of this Agreement.
        </Paragraph>
      </ListItem>
      <ListItem>
        <Paragraph>
          <u>Limitations on Use.</u> Confidential Information is proprietary, secret,
          and/or confidential. Lender agree to use Confidential Information solely for
          evaluating the feasibility of offering financing on a Property and not for any
          other purpose. Access to Confidential Information for any other purpose other
          than as authorized by this Agreement is prohibited, and may cause irreparable
          harm and damage to RealAtom and Client. The Confidential Information may not be
          relied on by Lender or any third party for any other purpose, including, but not
          limited to, securing financing necessary to acquire, refinance or construct the
          Property. Lender agrees that Lender does not acquire any title, ownership, or
          other intellectual property right or license in or to the Confidential
          Information under this Agreement. Neither Lender, nor any of Lender’s agents or
          Representatives, shall communicate with any third party preparer of the
          Confidential Information or any other person whose name is obtained from the
          Confidential Information, without prior written consent of both RealAtom and
          Client.
        </Paragraph>
      </ListItem>
      <ListItem>
        <Paragraph>
          <u>Limitations on Disclosure</u>. Lender shall not publish or disclose
          Confidential Information to any third party without the prior written consent of
          RealAtom and Client, and only after requiring any such third party to treat all
          Confidential Information confidentially and having a written confidentiality
          agreement in place with such third parties. However, Lender may disclose
          Confidential Information on a need to know basis to evaluate the Transaction to
          Lender’s (i) employees, officers, agents, directors, members or managers, or
          (ii) attorneys, accountants, financial advisors, partners, and other consultants
          (collectively (i) and (ii), referred to as <b>&quot;Representatives&quot;</b>).
          Lender shall advise Lender’s Representatives of their duties under this
          Agreement before disclosing Confidential Information to them. Should Lender or
          any of Lender’s Representative breach the terms of this Agreement, Lender and
          Lender’s Representatives shall be jointly and severally liable for such breach.
        </Paragraph>
      </ListItem>
      <ListItem>
        <Paragraph>
          <u>Maintenance/Destruction.</u> Confidential Information shall be kept and
          maintained in a safe and secure place with commercially reasonable safeguards to
          insure that unauthorized persons do not have access to the Confidential
          Information. Lender agrees to promptly notify RealAtom and Client of any actual
          or suspected misuse, misappropriation, unauthorized disclosure, or security
          breach of or affecting Confidentiality Information in violation of this
          Agreement. At any time during or after the term of this Agreement, and at
          RealAtom&apos;s or Client&apos;s request, Lender and Lender’s Representatives
          shall cease all use of Confidential Information, and within a reasonable time
          after receipt of the request from RealAtom or Client, return all Confidential
          Information and copies thereof received from RealAtom or Client under this
          Agreement or destroy all Confidential Information (with confirmation of
          destruction provided to RealAtom and Client), except for a single archival copy
          which may be retained by Lender’s legal department to the extent required
          pursuant to applicable legal or regulatory requirement or internal document
          retention policies, provided that any Confidential Information not destroyed
          shall continue to be bound by the terms of this Agreement.
        </Paragraph>
      </ListItem>
      <ListItem>
        <Paragraph>
          <u>Communication with Third Parties.</u> Other than part of Lender ordinary
          course of business unrelated to the Transaction, Lender shall not use
          Confidential Information, nor authorize any other person or entity to use
          Confidential Information, to communicate with any borrower, guarantor, any third
          party preparer of the Confidential Information or other obligor with respect to
          any Property in connection with the Transaction without prior written consent of
          RealAtom and Client, which consent may be withheld for any reason or no reason
          in the sole discretion of RealAtom and Client. Nor shall Lender or any other
          person or entity on Lender’s behalf use Confidential Information to communicate
          with the accountants or attorneys of any such obligor, or with any person or
          party, including any appraiser, tenant, managing or leasing agent, environmental
          consultant or engineering consultant, connected with, related to, or whose name
          is obtained from the Confidential Information in connection with the Transaction
          without prior written consent of RealAtom and Client, which consent may be
          withheld for any reason or no reason in the sole discretion of RealAtom and
          Client.
        </Paragraph>
      </ListItem>
      <ListItem>
        <Paragraph>
          <u>Exceptions to Confidentiality Requirements.</u> Notwithstanding anything to
          the contrary in this Agreement, Confidential Information does not include any
          information or material that: (a) was previously known to Lender and was in
          Lender’s possession free of any obligation to keep it confidential as shown by
          written records, so long as Lender did not receive such information directly or
          indirectly from Client; (b) is or becomes publicly available other than by
          unauthorized disclosure by Lender in breach of this Agreement; (c) is
          independently developed by Lender or Lender’s Representative without knowledge
          or use of the Confidential Information as shown by written records; (d) is
          disclosed to third parties by Client without restriction; or (e) is lawfully
          received from a third party whose disclosure, to Lender’s knowledge, would not
          violate any confidentiality or other legal obligation owed to Client or
          RealAtom.
        </Paragraph>
      </ListItem>
      <ListItem>
        <Paragraph>
          <u>Required Disclosure.</u> No liability shall arise under this Agreement
          because of Lender disclosure of Confidential Information pursuant to judicial or
          governmental order, provided Lender promptly notifies RealAtom and Client before
          such disclosure and cooperates with RealAtom and Client if either RealAtom or
          Client elects to legally contest and avoid such disclosure through a protective
          order or other legal process.
        </Paragraph>
      </ListItem>
      <ListItem>
        <Paragraph>
          <u>Compliance with Laws and Regulations/Notification Obligations.</u> Lender
          agrees to comply with all applicable laws and regulations applicable to the
          Confidential Information, including, without limitation, the Gramm-Leach-Bliley
          Act.
        </Paragraph>
      </ListItem>
      <ListItem>
        <Paragraph>
          <u>Lender Liability.</u> If RealAtom or Client determines that Lender has
          breached any provision of this Agreement, RealAtom and/or Client may, in their
          sole discretion, exercise any or all legal or equitable rights or remedies to
          which they are entitled because of Lender’s breach. Neither RealAtom nor Client
          shall be deemed to have waived any rights or remedies because of failure, delay
          or forbearance in exercising any such right or remedy in a particular instance.
          Lender also agrees that RealAtom and Client shall have the right to seek an
          order enjoining any such further breach or misappropriation without posting of a
          bond, and for such other relief as RealAtom and Client deems appropriate.
        </Paragraph>
      </ListItem>
      <ListItem>
        <Paragraph>
          <u>Disclaimers.</u> WITH RESPECT TO THE CONFIDENTIAL INFORMATION, REALATOM AND
          CLIENT AND EACH OF THEIR RESPECTIVE REPRESENTATIVES (INCLUDING CLIENT&apos;S
          BROKER) DISCLAIM ANY IMPLIED WARRANTY OF MERCHANTABILITY, FITNESS FOR A
          PARTICULAR PURPOSE, AND NON-INFRINGEMENT, AND ANY EXPRESS OR IMPLIED WARRANTY AS
          TO THE COMPLETENESS, CONTENT OR ACCURACY OF SUCH CONFIDENTIAL INFORMATION AND
          DOCUMENTATION. RealAtom and Client and each of their respective Representatives
          (including Client&apos;s broker) accept no responsibility as a result of any
          expenses, losses, damages, or actions incurred or undertaken by Lender or
          Lender’s Representatives because of the receipt or use of any Confidential
          Information. Lender agrees that unless and until a definitive agreement
          (expressly excluding any executed letter of intent or other preliminary written
          agreement and any written or oral acceptance of an offer to purchase) with
          respect to a Property has been executed and delivered by all parties, Client
          shall not be under any legal obligation of any kind whatsoever with respect to
          Lender or a Property.
        </Paragraph>
      </ListItem>
      <ListItem>
        <Paragraph>
          <u>Limitations on Liability.</u> Lender and Lender’s Representatives
          specifically release RealAtom and Client and each of their respective
          representatives or agents, including but not limited to Client&apos;s broker,
          and the third party preparers of any Confidential Information, from all claims,
          demands, causes of action, judgments, losses, damages, liabilities, costs and
          expense (including attorneys&apos; fees whether suit is instituted or not)
          whether known or unknown, liquidated or contingent (&quot;Claims&quot;), by
          reason of the information contained in, or that should have been contained in,
          the Confidential Information. Lender and Lender’s Representatives further agree
          that Lender will indemnify and hold harmless RealAtom and Client and each of
          their respective Representatives and/or agents,including but not limited to
          Client&apos;s broker, from any and all losses, damages, claims or expenses
          arising from any breach or threatened breach of this Agreement by Lender or
          Lender’s Representatives.
        </Paragraph>
      </ListItem>
      <ListItem>
        <Paragraph>
          <u>Termination</u>. The obligations of this Agreement with respect to the
          disclosure of Confidential Information shall expire upon the earlier of (i) the
          date that is two (2) years from the date hereof, and (ii) the date that Client
          and Lender, or their respective affiliates, close the proposed Transaction.
          Notwithstanding the foregoing, this Agreement may be terminated by RealAtom or
          Client without cause upon thirty (30) days prior written notice provided,
          however, that this Agreement may be terminated for cause immediately and the
          immediate return or destruction of all Confidential Information may be demanded
          by RealAtom or Client upon written notice if Lender or Lender’s Representatives
          breach any term of this Agreement. Lender remain bound to the provisions hereof
          with respect to the disclosure of Confidential Information received by Lender
          before termination.
        </Paragraph>
      </ListItem>
      <ListItem>
        <Paragraph>
          <u>Governing Law.</u> This Agreement shall be governed by, and construed under
          the laws of the Commonwealth of Virginia, without regard to the conflicts of law
          provisions of any jurisdiction. The parties agree that the sole venue for any
          action related to this Agreement shall be in any federal or state court within
          the Commonwealth of Virginia, and the parties irrevocably submit to the personal
          jurisdiction and venue of said court.
        </Paragraph>
      </ListItem>
      <ListItem>
        <Paragraph>
          <u>Assignment.</u> This Agreement shall be binding on, and inure to the benefit
          of the parties and their successors and assigns, heirs, executors,
          administrators and personal representatives. Lender may not assign or transfer
          Lender’s rights or obligations under this Agreement without the prior written
          consent of RealAtom and Client. Any assignment or transfer in violation of this
          section shall be void.
        </Paragraph>
      </ListItem>
      <ListItem>
        <Paragraph>
          <u>Miscellaneous.</u> This Agreement merges and supersedes all prior Agreements
          between the parties with respect to its subject matter. The waiver by either
          party of any breach of the terms and conditions will not be considered a
          modification of any provision, nor shall such a waiver act to bar enforcing any
          subsequent breach. This Agreement may only be modified by a written agreement
          executed by all the parties. The disclosure of Confidential Information shall
          not be construed as evidencing any intent by a party to purchase or sell any
          products or services of the other party, nor an agreement to offer financing for
          a Property or a commitment to offer financing, nor as an encouragement to expend
          funds in development or research efforts. If Lender are signing this Agreement
          for a legal entity, Lender represent and warrant that Lender are fully
          authorized to enter this Agreement and to legally bind such entity. If any term
          of this Agreement is to any extent illegal, otherwise invalid, or incapable of
          being enforced, such term shall be excluded to the extent of such invalidity or
          unenforceability; all other terms hereof shall remain in full force and effect;
          and, to the extent permitted and possible, the invalid or unenforceable term
          shall be deemed replaced by a term that is valid and enforceable and that comes
          closest to expressing the intention of such invalid or unenforceable term.
        </Paragraph>
      </ListItem>
    </ol>
    <Paragraph>
      <b>
        Electronic Acceptance: Lender acknowledges that Lender is executing this Agreement
        and accepting the terms by clicking the &quot;ACCEPT&quot; button. Lender
        acknowledges that such form of acceptance shall be binding and enforceable
        pursuant to Electronic Signatures in Global and National Commerce Act
        (&quot;E-Sign Act&quot;), Title 15, United States Code, Sections 7001 et seq., the
        Uniform Electronic Transaction Act (&quot;UETA&quot;), and applicable state laws.
      </b>
    </Paragraph>
    <Paragraph>
      <b>
        Terms of Use: By clicking &quot;ACCEPT&quot; Lender is also re-acknowledging
        RealAtom&apos;s Terms of Use located at{' '}
        <a href="https://realatom.com/terms-of-use/">
          https://realatom.com/terms-of-use/
        </a>
      </b>
    </Paragraph>
  </div>
);
