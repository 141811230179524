import types from 'constants/actionTypes';

// Customer Support
export const supportRequest = (payload = {}) => ({
  type: types.SUPPORT_REQUEST,
  payload,
});
export const supportSuccess = (payload = {}) => ({
  type: types.SUPPORT_SUCCESS,
  payload,
});
export const supportFailure = (payload = {}) => ({
  type: types.SUPPORT_FAILURE,
  payload,
});

//  Lending Criteria
export const getLendingCriteriaRequest = (payload = {}) => ({
  type: types.GET_LENDING_CRITERIA_REQUEST,
  payload,
});
export const getLendingCriteriaSuccess = (payload = {}) => ({
  type: types.GET_LENDING_CRITERIA_SUCCESS,
  payload,
});
export const getLendingCriteriaFailure = (payload = {}) => ({
  type: types.GET_LENDING_CRITERIA_FAILURE,
  payload,
});

export const changeLendingCriteriaRequest = (payload = {}) => ({
  type: types.CHANGE_LENDING_CRITERIA_REQUEST,
  payload,
});
export const changeLendingCriteriaSuccess = (payload = {}) => ({
  type: types.CHANGE_LENDING_CRITERIA_SUCCESS,
  payload,
});
export const changeLendingCriteriaFailure = (payload = {}) => ({
  type: types.CHANGE_LENDING_CRITERIA_FAILURE,
  payload,
});

// Sponsor Profile

export const getUserProfileRequest = (payload = {}) => ({
  type: types.GET_USER_PROFILE_REQUEST,
  payload,
});
export const getUserProfileSuccess = (payload = {}) => ({
  type: types.GET_USER_PROFILE_SUCCESS,
  payload,
});
export const getUserProfileFailure = (payload = {}) => ({
  type: types.GET_USER_PROFILE_FAILURE,
  payload,
});

// User Profile
export const getUserInfoRequest = (payload = {}) => ({
  type: types.GET_USER_INFO_REQUEST,
  payload,
});
export const getUserInfoSuccess = (payload = {}) => ({
  type: types.GET_USER_INFO_SUCCESS,
  payload,
});
export const getUserInfoFailure = (payload = {}) => ({
  type: types.GET_USER_INFO_FAILURE,
  payload,
});

export const changeUserInfoRequest = (payload = {}) => ({
  type: types.CHANGE_USER_INFO_REQUEST,
  payload,
});
export const changeUserInfoSuccess = (payload = {}) => ({
  type: types.CHANGE_USER_INFO_SUCCESS,
  payload,
});
export const changeUserInfoFailure = (payload = {}) => ({
  type: types.CHANGE_USER_INFO_FAILURE,
  payload,
});

export const changePasswordRequest = (payload = {}) => ({
  type: types.CHANGE_PASSWORD_REQUEST,
  payload,
});
export const changePasswordSuccess = (payload = {}) => ({
  type: types.CHANGE_PASSWORD_SUCCESS,
  payload,
});
export const changePasswordFailure = (payload = {}) => ({
  type: types.CHANGE_PASSWORD_FAILURE,
  payload,
});

export const borrowerAddNewMemberRequest = (payload = {}) => ({
  type: types.BORROWER_ADD_NEW_MEMBER_REQUEST,
  payload,
});
export const borrowerAddNewMemberSuccess = (payload = {}) => ({
  type: types.BORROWER_ADD_NEW_MEMBER_SUCCESS,
  payload,
});
export const borrowerAddNewMemberFailure = (payload = {}) => ({
  type: types.BORROWER_ADD_NEW_MEMBER_FAILURE,
  payload,
});

export const removeBorrowerMemberRequest = (payload = {}) => ({
  type: types.BORROWER_REMOVE_MEMBER_REQUEST,
  payload,
});
export const removeBorrowerMemberSuccess = (payload = {}) => ({
  type: types.BORROWER_REMOVE_MEMBER_SUCCESS,
  payload,
});
export const removeBorrowerMemberFailure = (payload = {}) => ({
  type: types.BORROWER_REMOVE_MEMBER_FAILURE,
  payload,
});

export const changeBorrowerMemberRequest = (payload = {}) => ({
  type: types.CHANGE_BORROWER_MEMBER_REQUEST,
  payload,
});
export const changeBorrowerMemberSuccess = (payload = {}) => ({
  type: types.CHANGE_BORROWER_MEMBER_SUCCESS,
  payload,
});
export const changeBorrowerMemberFailure = (payload = {}) => ({
  type: types.CHANGE_BORROWER_MEMBER_FAILURE,
  payload,
});

export const resendTeamMemberInvitationRequest = (payload = {}) => ({
  type: types.RESEND_TEAM_MEMBER_INVITATION_REQUEST,
  payload,
});
export const resendTeamMemberInvitationSuccess = (payload = {}) => ({
  type: types.RESEND_TEAM_MEMBER_INVITATION_SUCCESS,
  payload,
});
export const resendTeamMemberInvitationFailure = (payload = {}) => ({
  type: types.RESEND_TEAM_MEMBER_INVITATION_FAILURE,
  payload,
});

// team

export const changeTeamDataRequest = (payload = {}) => ({
  type: types.CHANGE_TEAM_DATA_REQUEST,
  payload,
});
export const changeTeamDataSuccess = (payload = {}) => ({
  type: types.CHANGE_TEAM_DATA_SUCCESS,
  payload,
});
export const changeTeamDataFailure = (payload = {}) => ({
  type: types.CHANGE_TEAM_DATA_FAILURE,
  payload,
});

export const removeTeamLogoRequest = (payload = {}) => ({
  type: types.REMOVE_TEAM_LOGO_REQUEST,
  payload,
});
export const removeTeamLogoSuccess = (payload = {}) => ({
  type: types.REMOVE_TEAM_LOGO_SUCCESS,
  payload,
});
export const removeTeamLogoFailure = (payload = {}) => ({
  type: types.REMOVE_TEAM_LOGO_FAILURE,
  payload,
});

export const resetProfile = (payload = {}) => ({
  type: types.RESET_PROFILE,
  payload,
});

export const removeLendingCriteriaBrochureRequest = (payload = {}) => ({
  type: types.REMOVE_LENDING_CRITERIA_BROCHURE_REQUEST,
  payload,
});
export const removeLendingCriteriaBrochureSuccess = (payload = {}) => ({
  type: types.REMOVE_LENDING_CRITERIA_BROCHURE_SUCCESS,
  payload,
});
export const removeLendingCriteriaBrochureFailure = (payload = {}) => ({
  type: types.REMOVE_LENDING_CRITERIA_BROCHURE_FAILURE,
  payload,
});

export const getInstalledAppsRequest = (payload = {}) => ({
  type: types.GET_INSTALLED_APPS_REQUEST,
  payload,
});
export const getInstalledAppsSuccess = (payload = {}) => ({
  type: types.GET_INSTALLED_APPS_SUCCESS,
  payload,
});
export const getInstalledAppsFailure = (payload = {}) => ({
  type: types.GET_INSTALLED_APPS_FAILURE,
  payload,
});

export const removeInstalledAppRequest = (payload = {}) => ({
  type: types.REMOVE_INSTALLED_APP_REQUEST,
  payload,
});
export const removeInstalledAppSuccess = (payload = {}) => ({
  type: types.REMOVE_INSTALLED_APP_SUCCESS,
  payload,
});
export const removeInstalledAppFailure = (payload = {}) => ({
  type: types.REMOVE_INSTALLED_APP_FAILURE,
  payload,
});
