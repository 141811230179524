import types from 'constants/actionTypes';

export const myMatchingLoansOrderingUpdate = (payload = {}) => ({
  type: types.MY_MATCHING_LOANS_ORDERING_UPDATE,
  payload,
});

export const myMatchingLoansDataDataRequest = (payload = {}) => ({
  type: types.MY_MATCHING_LOANS_DATA_REQUEST,
  payload,
});
export const myMatchingLoansDataDataSuccess = (payload = {}) => ({
  type: types.MY_MATCHING_LOANS_DATA_SUCCESS,
  payload,
});
export const myMatchingLoansDataDataFailure = (payload = {}) => ({
  type: types.MY_MATCHING_LOANS_DATA_FAILURE,
  payload,
});

export const updateMyMatchingLoansDataRequest = (payload = {}) => ({
  type: types.UPDATE_MY_MATCHING_LOANS_DATA_REQUEST,
  payload,
});
export const updateMyMatchingLoansDataSuccess = (payload = {}) => ({
  type: types.UPDATE_MY_MATCHING_LOANS_DATA_SUCCESS,
  payload,
});
export const updateMyMatchingLoansDataFailure = (payload = {}) => ({
  type: types.UPDATE_MY_MATCHING_LOANS_DATA_FAILURE,
  payload,
});

export const hideLoanRequest = (payload = {}) => ({
  type: types.HIDE_LOAN_REQUEST,
  payload,
});
export const hideLoanSuccess = (payload = {}) => ({
  type: types.HIDE_LOAN_SUCCESS,
  payload,
});
export const hideLoanFailure = (payload = {}) => ({
  type: types.HIDE_LOAN_FAILURE,
  payload,
});

export const unhideLoanRequest = (payload = {}) => ({
  type: types.UNHIDE_LOAN_REQUEST,
  payload,
});
export const unhideLoanSuccess = (payload = {}) => ({
  type: types.UNHIDE_LOAN_SUCCESS,
  payload,
});
export const unhideLoanFailure = (payload = {}) => ({
  type: types.UNHIDE_LOAN_FAILURE,
  payload,
});

export const getCompanyClientsRequest = (payload = {}) => ({
  type: types.GET_COMPANY_CLIENTS_REQUEST,
  payload,
});
export const getCompanyClientsSuccess = (payload = {}) => ({
  type: types.GET_COMPANY_CLIENTS_SUCCESS,
  payload,
});
export const getCompanyClientsFailure = (payload = {}) => ({
  type: types.GET_COMPANY_CLIENTS_FAILURE,
  payload,
});

export const setMyMatchingLoansDisplayVariantRequest = (payload = {}) => ({
  type: types.SET_MY_MATCHING_LOANS_DISPLAY_VARIANT_REQUEST,
  payload,
});
export const setMyMatchingLoansDisplayVariantSuccess = (payload = {}) => ({
  type: types.SET_MY_MATCHING_LOANS_DISPLAY_VARIANT_SUCCESS,
  payload,
});
export const setMyMatchingLoansDisplayVariantFailure = (payload = {}) => ({
  type: types.SET_MY_MATCHING_LOANS_DISPLAY_VARIANT_FAILURE,
  payload,
});
