import React, { useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components/macro';
import {
  ModalBody,
  ModalHeading,
  ModalWithContentWrapper,
  ModalActions,
} from 'ui/Modal/styles';
import { LENDER } from 'constants/appRelated';
import { UserAvatar } from 'components/UserAvatar';
import { Text } from 'ui/Text';
import { Radio } from 'ui/Radio';
import { Button, ButtonGhost } from 'ui/Button';
import { assignLoanToLenderRequest } from 'features/LenderCompany/actions';
import { getCurrentLenderTeam } from 'features/LenderCompany/selectors';

const AssignLoanModal = ({
  team,
  hideModal,
  submit,
  currentUserId,
  loanId,
  onSubmit,
}) => {
  const [selectedId, setUserId] = useState(currentUserId);
  const submitAssign = () => {
    if (selectedId !== currentUserId) {
      submit(selectedId, loanId);

      if (onSubmit) {
        onSubmit();
      }
    }
    hideModal();
  };
  return (
    <ModalWithContentWrapper>
      <ModalHeading>
        Select a team member to assign <br /> this deal
      </ModalHeading>
      <Description>Once assigned you will lose access to the deal</Description>
      <ModalBody>
        {team?.members &&
          team.members.map((member) => (
            <TeamMember
              key={member.userId}
              onClick={() => {
                setUserId(member.userId);
              }}
            >
              <UserAvatar
                author={{
                  initials: `${member.firstName[0]}${member.lastName[0]}`,
                  accountType: LENDER,
                }}
              />
              <MemberInfo>
                <Text>
                  {member.firstName} {member.lastName}
                </Text>
                {member.title && <MemberTitle>{member.title}</MemberTitle>}
              </MemberInfo>
              <Radio checked={selectedId === member.userId} />
            </TeamMember>
          ))}
      </ModalBody>
      <Controls>
        <ButtonGhost type="button" onClick={hideModal}>
          Cancel
        </ButtonGhost>
        <Button secondary disabled={!selectedId} onClick={submitAssign}>
          Assign
        </Button>
      </Controls>
    </ModalWithContentWrapper>
  );
};

function mapStateToProps(state) {
  const { currentUser } = state;

  return {
    team: getCurrentLenderTeam(state),
    currentUserId: currentUser.id,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    submit: (userId, loanId) => {
      dispatch(assignLoanToLenderRequest({ userId, loanId }));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AssignLoanModal);

const TeamMember = styled.div`
  display: flex;
  align-items: center;
  padding: 16px 0;
  cursor: pointer;
  border-top: 1px solid #e8e9ed;

  &:last-child {
    border-bottom: 1px solid #e8e9ed;
  }

  &:hover {
    background-color: #f6fafe;
  }
`;

const MemberInfo = styled.div`
  margin-left: 8px;
  margin-right: auto;
`;

const MemberTitle = styled(Text)`
  font-size: 12px;
  color: #778598;
`;

const Controls = styled(ModalActions)`
  ${Button} {
    margin-left: 16px;
  }
`;

const Description = styled(Text)`
  text-align: center;
  color: #778598;
`;
