import React from 'react';
import { DropDown } from 'ui/DropDown';
import styled from 'styled-components/macro';
import { cutStringByLength } from 'lib/strings';

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 32px;
`;

const DropdownWrapper = styled.div`
  max-width: 300px;
  width: 100%;
`;

const optionStyles = {
  option: (provided, { data }) => ({
    ...provided,
    padding: 20,
    ...thumb(data.img),
  }),
};

const thumb = (img) => ({
  alignItems: 'center',
  display: 'flex',

  ':before': {
    background: `url(${img}) no-repeat center center`,
    backgroundSize: 'cover',
    borderRadius: 4,
    content: '" "',
    display: 'block',
    marginRight: 12,
    width: 30,
    height: 30,
    flexShrink: 0,
  },
});

export class LoansDropdown extends React.PureComponent {
  changeInput = ({ value }) => {
    const { updateAnalytics } = this.props;
    updateAnalytics(value);
  };

  render() {
    const { loans } = this.props;

    const loanOptions = loans.map((loan) => ({
      value: String(loan.id),
      label: cutStringByLength(loan.propertyName, 50),
      img: loan.cover?.thumbnail || '/images/no_photo.svg',
    }));

    const options = [{ value: '', label: 'All loans' }, ...loanOptions];

    return (
      <Wrapper>
        <DropdownWrapper>
          <DropDown
            options={options}
            styles={optionStyles}
            label="Filter by loan request"
            defaultValue={options[0]}
            onChange={this.changeInput}
          />
        </DropdownWrapper>
      </Wrapper>
    );
  }
}
