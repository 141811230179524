import React, { Component } from 'react';
import { Form, Field } from 'react-final-form';
import Yup from 'yup';
import { validate } from 'utils/validate';
import { Textarea } from 'components/Form';
import { rejectTermsheetRequest } from 'features/LoanNew/actions';
import { ModalHeader, ModalBody, ModalControls, ModalTitle } from 'ui/Modal/styles';
import { Button } from 'ui/Button';

const schema = Yup.object().shape({
  reason: Yup.string().required('Reason is required!'),
});

export class RejectTermsheetForm extends Component {
  formSubmit = ({ reason }) => {
    const { dispatch, negotiationId, hideModal, termsheetId, loanId } = this.props;

    hideModal();

    dispatch(
      rejectTermsheetRequest({
        negotiationId,
        reason,
        loanId,
        termsheetId,
      })
    );
  };

  render() {
    const { hideModal } = this.props;
    return (
      <Form
        onSubmit={this.formSubmit}
        validate={(values) => validate(values, schema)}
        render={({ handleSubmit, submitting, validating }) => (
          <form onSubmit={handleSubmit}>
            <ModalHeader>
              <ModalTitle>
                Please comment to the lender why you are returning their term sheet
              </ModalTitle>
            </ModalHeader>
            <ModalBody>
              <Field
                component={Textarea}
                name="reason"
                rows={3}
                placeholder="Type the reason"
              />
              <ModalControls>
                <Button disabled={submitting || validating} type="submit" mr={2}>
                  Submit
                </Button>
                <Button onClick={hideModal} secondary>
                  Cancel
                </Button>
              </ModalControls>
            </ModalBody>
          </form>
        )}
      />
    );
  }
}
