import React from 'react';
import { Field } from 'react-final-form';
import styled from 'styled-components/macro';
import { WrappedRadio } from 'components/Form';
import { Section } from 'components/Modals/ManualOfferModal/styles';
import { LENDER_SOURCE } from 'components/Modals/ManualOfferModal/constants';

const LenderSourceWrapper = styled(Section)`
  display: flex;

  label {
    margin-right: 37px;

    input + span {
      flex-shrink: 0;
    }
  }
`;

export const LenderSourceTypeSwitch = () => (
  <LenderSourceWrapper>
    <Field
      component={WrappedRadio}
      name="lenderSourceType"
      label="New lender"
      value={LENDER_SOURCE.NEW}
      type="radio"
    />
    <Field
      component={WrappedRadio}
      name="lenderSourceType"
      label="Select from capital sources"
      value={LENDER_SOURCE.INVESTMENT_SOURCES}
      type="radio"
    />
  </LenderSourceWrapper>
);
