import React from 'react';
import styled, { css } from 'styled-components/macro';
import { format } from 'date-fns';

const Wrapper = styled.div`
  display: flex;
  margin-bottom: 5px;
`;

const CommentContainer = styled.div`
  padding: 10px 15px;
  border-radius: 15px;
  background-color: #eee;
  color: #333;

  ${(p) =>
    p.right &&
    css`
      margin-left: auto;
    `}
`;

const CommentTitle = styled.div`
  font-weight: bold;
  margin-bottom: 10px;
`;

const OfferComment = ({ message, userId, isLender }) => (
  <Wrapper>
    <CommentContainer right={message.author && userId === message.author.id}>
      <CommentTitle>
        {`${
          message.author && userId === message.author.id
            ? 'Me'
            : isLender
            ? 'Borrower'
            : 'Lender'
        },
        ${format(new Date(message.timestamp), 'MM.dd.yyyy, h:mm a')}`}
      </CommentTitle>
      <div>{message.message}</div>
    </CommentContainer>
  </Wrapper>
);

export { OfferComment };
