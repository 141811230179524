import types from 'constants/actionTypes';
import immutable from 'object-path-immutable';
import { findIndex, propEq } from 'ramda';
import { LOAN_STATUS, LOAN_STATUS_TITLE, LENDER_LOAN_STAGE } from 'constants/appRelated';

const initialState = {
  loans: [],
  displayVariant: null,
  ordering: null,
  isFetched: false,
  closeLoan: false,
};

export default function reducer(state, action = {}) {
  const currentState = state || initialState;

  const { type, payload } = action;

  switch (type) {
    case types.MY_MATCHING_LOANS_DATA_SUCCESS:
      return {
        ...currentState,
        loans: [...payload.data.map(loan => loan ? ({...loan, id: loan.uuid, pk: loan.uuid}) : null)],
        displayVariant: payload.displayVariant,
        ordering: payload.ordering,
        isFetched: true,
      };

    case types.UPDATE_MY_MATCHING_LOANS_DATA_SUCCESS:
      return {
        ...currentState,
        loans: [...payload.data.map(loan =>  loan ? ({...loan, id: loan.uuid, pk: loan.uuid}): null)],
      };

    case types.HIDE_LOAN_SUCCESS: {
      const index = findIndex(propEq('id', payload.id))(currentState.loans);
      return immutable.set(currentState, `loans.${index}`, {
        ...currentState.loans[index],
        hidden: true,
      });
    }

    case types.UNHIDE_LOAN_SUCCESS: {
      const index = findIndex(propEq('id', payload.id))(currentState.loans);
      return immutable.set(currentState, `loans.${index}`, {
        ...currentState.loans[index],
        hidden: false,
      });
    }

    case types.CLOSE_LOAN_ON_THE_PAGE_SUCCESS: 
      return {
        ...currentState,
        closeLoan: action.payload,
      };
    
    case types.CLOSE_MATCHING_LOAN_SUCCESS: {
      const { closeType, loanId, loan } = payload;
      const myIndex = findIndex(propEq('id', loanId))(currentState.loans);

      return immutable.update(currentState, `loans.${myIndex}`, (loan) => ({
        ...loan,
        title: "Closed",
        lenderStatus: LENDER_LOAN_STAGE.CLOSED,
        winningStatus: closeType
      }));
    }
    case types.SET_MY_MATCHING_LOANS_DISPLAY_VARIANT_SUCCESS:
      return {
        ...currentState,
        displayVariant: action.payload,
      };

    case types.MY_MATCHING_LOANS_ORDERING_UPDATE:
      return {
        ...currentState,
        ordering: action.payload,
      };

    default:
      return currentState;
  }
}
