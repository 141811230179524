import { FIELD_TYPES } from 'constants/appRelated';

export const fieldsConfig = {
  // loan overview
  address: {
    type: 'mainAddress',
    fieldName: 'addresses',
  },
  additionalAddresses: {
    type: 'additionalAddresses',
    fieldName: 'addresses',
  },
  city: { type: FIELD_TYPES.TEXT, fieldName: 'city', maxLength: 50 },
  zip: { type: FIELD_TYPES.TEXT, fieldName: 'zip', maxLength: 7 },
  state: { type: FIELD_TYPES.TEXT, fieldName: 'state', maxLength: 50 },
  firstAddressLng: { type: FIELD_TYPES.TEXT, fieldName: 'firstAddressLng' },
  firstAddressLat: { type: FIELD_TYPES.TEXT, fieldName: 'firstAddressLat' },
  propertyType: {
    type: FIELD_TYPES.CHOICE,
    fieldName: 'propertyType',
    label: 'Property type',
  },
  planUseProperty: {
    type: FIELD_TYPES.CHOICE,
    fieldName: 'planUseProperty',
    label: 'Purpose',
  },
  loanType: { type: FIELD_TYPES.CHOICE, fieldName: 'loanType', label: 'Loan Type' },
  loanAmount: {
    type: FIELD_TYPES.DOLLARS,
    fieldName: 'loanAmount',
    label: 'Loan Amount',
  },
  loanTerm: { type: FIELD_TYPES.DURATION, fieldName: 'loanTerm', label: 'Loan term' },
  loanTermUnit: { type: FIELD_TYPES.DURATION_UNIT, fieldName: 'loanTermUnit' },
  desiredClosingDate: {
    type: FIELD_TYPES.DATE,
    fieldName: 'desiredClosingDate',
    label: 'Closing Date',
  },
  propertyName: {
    type: FIELD_TYPES.TEXT,
    fieldName: 'propertyName',
    label: 'Property name',
  },
  propertyName: {
    type: FIELD_TYPES.TEXT,
    fieldName: 'propertyName',
    label: 'Loan Title',
  },
  usePropertyName: {
    type: FIELD_TYPES.BOOLEAN,
    fieldName: 'usePropertyName',
  },
  guarantor: {
    fieldName: 'guarantor',
    type: FIELD_TYPES.CHOICE,
    label: 'Guarantor',
  },
  guarantorValue: {
    fieldName: 'guarantorValue',
    type: FIELD_TYPES.PERCENT,
    label: 'Recourse percent',
  },

  // desired terms
  index: {
    fieldName: 'index',
    type: FIELD_TYPES.CHOICE,
    label: 'Index',
  },
  fixedOrVariable: {
    fieldName: 'fixedOrVariable',
    type: FIELD_TYPES.CHOICE,
    label: 'Fixed or Variable',
  },
  lenderFee: { fieldName: 'lenderFee', type: FIELD_TYPES.PERCENT, label: 'Lender Fee' },
  exitFee: { fieldName: 'exitFee', type: FIELD_TYPES.PERCENT, label: 'Exit Fee' },
  actualDscr: {
    fieldName: 'actualDscr',
    type: FIELD_TYPES.FLOAT,
    label: 'Actual DSCR',
  },
  occupancyDate: {
    fieldName: 'occupancyDate',
    type: FIELD_TYPES.DATE,
    label: 'Occupancy Rate as of date',
  },
  currentMortgageBalance: {
    type: FIELD_TYPES.DOLLARS,
    fieldName: 'currentMortgageBalance',
    label: 'Current Mortgage Balance',
    hint: 'Latest Mortgage Statement will have this information.',
    placeholder: 'ex. $100,000',
  },
  totalProjectCost: {
    type: FIELD_TYPES.DOLLARS,
    fieldName: 'totalProjectCost',
    label: 'Total Project Cost',
    hint: 'Land cost+Soft Cost+Hard Cost',
    placeholder: 'ex.$100,000',
  },
  renovationCosts: {
    type: FIELD_TYPES.DOLLARS,
    fieldName: 'renovationCosts',
    label: 'Renovation Costs',
    hint: 'Soft Cost+ Hard Cost',
    placeholder: 'ex.$100,000',
  },
  pip: {
    type: FIELD_TYPES.DOLLARS,
    fieldName: 'pip',
    label: 'How Much Is The Pip?',
    hint: 'How much are you willing to invest in property improvement?',
    placeholder: 'ex. $100,000',
  },
  buyAndHold: {
    type: FIELD_TYPES.CHOICE_BOOLEAN,
    fieldName: 'buyAndHold',
    label: 'Is it buy & hold?',
    hint: 'If you plan to keep the property for more then 5 years.',
    placeholder: 'yes/no',
  },
  refinanceReason: {
    type: FIELD_TYPES.TEXT,
    fieldName: 'refinanceReason',
    label: 'Refinance Reason',
    hint: 'Explain why would you want to refinance?',
    placeholder: 'Ex.: loan matures or lock lower interest rate ',
  },
  existingLoan: {
    type: FIELD_TYPES.CHOICE_BOOLEAN,
    fieldName: 'existingLoan',
    label: 'Do you have an existing loan?',
    placeholder: 'yes/no',
  },
  recourse: {
    type: FIELD_TYPES.CHOICE,
    fieldName: 'recourse',
    label: 'Recourse',
    // hint: 'Choose "Yes" if you are willing to guarantee your loan',
    placeholder: 'No Recourse',
  },
  interest: {
    type: FIELD_TYPES.PERCENT,
    fieldName: 'interest',
    label: 'Interest',
    hint: 'The percent of principal charged by the lenders for the use of its money.',
    placeholder: 'ex. 5.5%',
  },
  interestOnly: {
    type: FIELD_TYPES.CHOICE,
    fieldName: 'interestOnly',
    label: 'Interest Only',
    hint:
      'Lender will determine the eligibility depending on the underwriting loan metrics',
    placeholder: 'ex. 6 months',
  },
  interestOnlyUnit: { type: FIELD_TYPES.DURATION_UNIT, fieldName: 'interestOnlyUnit' },
  cashoutToBorrower: {
    type: FIELD_TYPES.DOLLARS,
    fieldName: 'cashoutToBorrower',
    label: 'Cash-Out to Borrower',
    hint:
      "Depending on lender's underwriting lender will determine final amount and borrower's eligibility",
    placeholder: 'ex.$100,000',
  },
  extensions: {
    type: FIELD_TYPES.TEXT,
    fieldName: 'extensions',
    label: 'Extensions',
    hint: 'Extension of the loan term past the original term',
    placeholder: 'ex. 2-6 months',
    maxLength: 200,
  },
  ltv: {
    type: FIELD_TYPES.PERCENT,
    fieldName: 'ltv',
    label: 'LTV',
    hint:
      'Ratio comparing the loan amount requested to the value of the property. Expressed as a percentage.',
    placeholder: 'ex. 85%',
  },
  ltc: {
    type: FIELD_TYPES.PERCENT,
    fieldName: 'ltc',
    label: 'LTC',
    hint:
      'Ratio comparing the loan amount used to finance the project to the cost to build the project. Expressed as a percentage.',
    placeholder: 'ex. 85%',
  },
  amortization: {
    type: FIELD_TYPES.CHOICE,
    fieldName: 'amortization',
    label: 'Amortization',
    hint:
      'Amortization is the paying off of debt with a fixed repayment schedule in regular installments over time.',
  },
  amortizationRepr: {
    type: FIELD_TYPES.DURATION,
    fieldName: 'amortizationRepr',
    label: 'Amortization',
    unitName: 'amortizationReprUnit',
  },
  amortizationCustom: {
    type: FIELD_TYPES.DURATION,
    fieldName: 'amortizationCustom',
    label: 'Amortization',
    placeholder: 'ex. 22 years',
    unitName: 'amortizationUnit',
  },
  amortizationUnit: { type: FIELD_TYPES.DURATION_UNIT, fieldName: 'amortizationUnit' },
  prepayment: {
    type: FIELD_TYPES.CHOICE_BOOLEAN,
    fieldName: 'prepayment',
    label: 'Prepayment',
    hint:
      'Choose "Yes" if you\'re willing to pay a penalty in case if you close this loan before its expiration date. Otherwise choose "NO"',
    placeholder: 'yes/no',
  },
  // property profile
  entitiledReady: {
    type: FIELD_TYPES.TEXT,
    hint: 'Is the property ready for construction',
    fieldName: 'entitiledReady',
    label: 'Property Entitled / Shovel Ready?',
    placeholder: 'Current state of the project',
  },
  exitStrategy: {
    type: FIELD_TYPES.TEXT,
    fieldName: 'exitStrategy',
    label: 'Exit Strategy',
    hint: 'Your long term plan ',
    placeholder: 'ex. Develop and sell',
  },
  estMarketValueAsIs: {
    type: FIELD_TYPES.DOLLARS,
    hint:
      'Your estimate of the current value of the property (before any rehabilitation). Please provide a value from your most recent appraisal (not older than 180 days ago)',
    fieldName: 'estMarketValueAsIs',
    label: 'Market Value "As-Is"',
    placeholder: 'ex. $1,250,000',
  },
  estMarketValueAsCompleted: {
    type: FIELD_TYPES.DOLLARS,
    hint:
      'Your estimate of the value of the property after completion. Usually estimated as new construction replacement cost minus land.',
    fieldName: 'estMarketValueAsCompleted',
    label: 'Market Value "As-Completed"',
  },
  propertyAsStabilized: {
    type: FIELD_TYPES.DOLLARS,
    fieldName: 'propertyAsStabilized',
    label: 'Property "As Stabilized"',
    hint:
      'Forecasted property value once property achieves market occupancy at 1.25xDSCR',
    placeholder: 'ex. $1,000,000',
  },
  isConstructionComponent: {
    type: FIELD_TYPES.CHOICE_BOOLEAN,
    fieldName: 'isConstructionComponent',
    label: 'Is there a construction component?',
  },
  constrCompletionTiming: {
    type: FIELD_TYPES.DURATION,
    hint: 'How long the construction will take',
    fieldName: 'constrCompletionTiming',
    unitName: 'constrCompletionTimingUnit',
    label: 'Construction Completion Timing',
    placeholder: 'ex. 12 months',
  },
  constrCompletionTimingUnit: {
    type: 'durationUnit',
    fieldName: 'constrCompletionTimingUnit',
  },
  propertyValue: {
    type: FIELD_TYPES.DOLLARS,
    fieldName: 'propertyValue',
    label: 'Property Value',
    hint:
      'Your estimate of the current value of the property (before any rehabilitation) based on most recent appraisal, tax assessment or COMPs',
    placeholder: 'ex. $1,000,000',
  },
  improvementsPurchase: {
    type: FIELD_TYPES.DOLLARS,
    fieldName: 'improvementsPurchase',
    label: 'Improvements Since Purchase',
    hint: 'How much have you spent to improve the property since you acquired it?',
    placeholder: 'ex. $100,000',
  },
  flagName: {
    type: FIELD_TYPES.TEXT,
    hint: 'Flag name that belongs to a hotel franchise',
    fieldName: 'flagName',
    label: 'Flag Name',
    placeholder: 'ex. Marigold Hotel',
    maxLength: 200,
  },
  flagFranchise: {
    type: FIELD_TYPES.CHOICE,
    hint: 'When does your franchise agreement expires?',
    fieldName: 'flagFranchise',
    label: 'Expiration of Franchise Agreement',
    placeholder: 'ex. 2020',
  },
  reTaxes: {
    type: FIELD_TYPES.DOLLARS,
    fieldName: 'reTaxes',
    label: 'RE Taxes,$',
    placeholder: 'ex. $250,000',
  },
  reInsurance: {
    type: FIELD_TYPES.DOLLARS,
    fieldName: 'reInsurance',
    label: 'RE Insurance, $',
    placeholder: 'ex. $250,000',
  },
  tenant: {
    type: FIELD_TYPES.CHOICE_BOOLEAN,
    fieldName: 'tenant',
    label: 'Is There An Existing Tenant?',
  },
  tenants: {
    type: FIELD_TYPES.TEXT,
    hint: 'Are There Any Tenants?',
    fieldName: 'tenants',
    label: 'Are There Any Tenants?',
  },
  occupyProperty: {
    type: FIELD_TYPES.CHOICE_BOOLEAN,
    fieldName: 'occupyProperty',
    hint: 'Is property occupied by owner?',
    label: 'Owner-occupied',
  },
  occupancyRate: {
    type: FIELD_TYPES.PERCENT,
    hint:
      'Find this number in your most current Rent Roll. Can be calculated as a ratio of Vacant units divided by Total units',
    placeholder: 'ex. 90',
    fieldName: 'occupancyRate',
    label: 'Occupancy Rate',
  },
  noi: {
    type: FIELD_TYPES.DOLLARS,
    hint: 'Can be calculated as a Total Revenue minus Operation Expenses',
    fieldName: 'noi',
    label: 'Net Operating Income (NOI)',
    placeholder: 'ex. $100,000',
  },
  grossRentalIncome: {
    type: FIELD_TYPES.DOLLARS,
    hint: 'Total Rental Revenue of a subject property',
    fieldName: 'grossRentalIncome',
    label: 'Gross Rental Income',
    placeholder: 'ex. $100,000',
  },
  revPar: {
    type: FIELD_TYPES.TEXT,
    hint:
      "Revenue per available room (RevPAR) is calculated by multiplying a hotel's average daily room rate (ADR) by its occupancy rate or by dividing a hotel's total room revenue by the total number of available rooms in the period being measured.",
    fieldName: 'revPar',
    label: 'RevPAR',
    placeholder: 'ex. $100',
  },
  numberOfKeys: {
    type: FIELD_TYPES.NUMBER,
    fieldName: 'numberOfKeys',
    label: 'Number of keys',
    placeholder: 'ex. 100',
  },
  purchasePrice: {
    type: FIELD_TYPES.DOLLARS,
    fieldName: 'purchasePrice',
    label: 'Property Purchase Price',
    hint:
      "If currently owned, purchase price paid, otherwise - contract price. Don't forget to upload you Purchase contract in the document vault (step 5)",
    placeholder: 'ex. $100,000',
    // Take datePurchase from form and use it to define if datePurchase is long than 2 years from now
  },
  datePurchase: {
    type: FIELD_TYPES.DATE,
    fieldName: 'datePurchase',
    label: 'Date Of Purchase',
    allowPast: true,
  },
  purchaseProcess: {
    type: FIELD_TYPES.CHOICE,
    fieldName: 'purchaseProcess',
    label: 'Property Purchase Process',
  },
  buildingSize: {
    type: FIELD_TYPES.NUMBER,
    fieldName: 'buildingSize',
    label: 'Building Size, SqFt',
    placeholder: 'ex. 1,000 SqFt',
  },
  yearBuild: { type: FIELD_TYPES.CHOICE, fieldName: 'yearBuild', label: 'Year built' },
  yearRenovated: {
    type: FIELD_TYPES.CHOICE,
    fieldName: 'yearRenovated',
    label: 'Year renovated',
  },
  storyNumber: {
    type: FIELD_TYPES.NUMBER,
    fieldName: 'storyNumber',
    label: 'Number of Stories',
    placeholder: 'ex. 3',
  },
  buildingNumber: {
    type: FIELD_TYPES.NUMBER,
    fieldName: 'buildingNumber',
    label: 'Number of Buildings',
    placeholder: 'ex. 3',
  },
  lotSize: {
    type: FIELD_TYPES.FLOAT,
    fieldName: 'lotSize',
    label: 'Lot Size, Acres',
    placeholder: 'ex. 10 Acres',
  },
  netRentableArea: {
    type: FIELD_TYPES.NUMBER,
    fieldName: 'netRentableArea',
    label: 'Net Rentable Area, SqFt',
    hint: 'Net rentable area is the area for which rent can be charged',
    placeholder: 'ex. 1,000 SqFt',
  },
  unitsExactNumber: {
    type: FIELD_TYPES.NUMBER,
    fieldName: 'unitsExactNumber',
    label: 'Number of units',
    placeholder: 'ex. 10',
  },
  isRefinancingConstruction: {
    fieldName: 'isRefinancingConstruction',
    type: FIELD_TYPES.CHOICE_BOOLEAN,
    label: 'Are you refinancing construction loan',
  },
  askingPrice: {
    fieldName: 'askingPrice',
    type: FIELD_TYPES.DOLLARS,
    label: 'Property asking price',
  },
  priceSqft: {
    fieldName: 'priceSqft',
    type: FIELD_TYPES.DOLLARS,
    label: 'Price/SqFt',
  },
  priceUnit: {
    fieldName: 'priceUnit',
    type: FIELD_TYPES.DOLLARS,
    label: 'Price/unit',
  },
  assetClass: {
    fieldName: 'assetClass',
    type: FIELD_TYPES.TEXT,
    label: 'Asset class',
  },
  capRate: {
    fieldName: 'capRate',
    type: FIELD_TYPES.NUMBER,
    label: 'Cap rate',
  },
  leaseType: {
    fieldName: 'leaseType',
    type: FIELD_TYPES.CHOICE,
    label: 'Lease type',
  },
  permittedZoning: {
    fieldName: 'permittedZoning',
    type: FIELD_TYPES.TEXT,
    label: 'Permitted zoning',
  },
  parking: {
    fieldName: 'parking',
    type: FIELD_TYPES.NUMBER,
    label: 'Parking',
  },
  walkScore: {
    fieldName: 'walkScore',
    type: FIELD_TYPES.NUMBER,
    label: 'Walk score',
  },
  unitsNumber: {
    fieldName: 'unitsNumber',
    type: FIELD_TYPES.NUMBER,
    label: 'Number of units',
  },
  whatLand: {
    fieldName: 'whatLand',
    type: FIELD_TYPES.TEXT,
    label: 'What are you going to do with the land?',
  },
  improvements: {
    fieldName: 'improvements',
    type: FIELD_TYPES.TEXT,
    label: 'Are there any improvements on a land?',
  },
  professionallyManaged: {
    fieldName: 'professionallyManaged',
    type: FIELD_TYPES.CHOICE_BOOLEAN,
    label: 'Is this property professionally managed?',
  },
  totalAnnualRent: {
    fieldName: 'totalAnnualRent',
    type: FIELD_TYPES.NUMBER,
    label: 'Total annual rent',
  },
  totalAnnualExpense: {
    fieldName: 'totalAnnualExpense',
    type: FIELD_TYPES.NUMBER,
    label: 'Total annual expenses',
  },
  spread: {
    fieldName: 'spread',
    type: FIELD_TYPES.NUMBER,
    label: 'Spread',
  },
  dscr: {
    fieldName: 'dscr',
    type: FIELD_TYPES.FLOAT,
    label: 'Min DSCR',
  },
  payment: {
    fieldName: 'payment',
    type: FIELD_TYPES.NUMBER,
    label: 'Payment',
  },
  proFormaOccupancyRate: {
    type: FIELD_TYPES.PERCENT,
    hint:
      'Find this number in your most current Rent Roll. Can be calculated as a ratio of Vacant units divided by Total units',
    placeholder: 'ex. 90%',
    fieldName: 'proFormaOccupancyRate',
    label: 'Pro Forma Occupancy rate',
  },
  proFormaNoi: {
    type: FIELD_TYPES.DOLLARS,
    hint: 'Can be calculated as a Total Revenue minus Operation Expenses',
    fieldName: 'proFormaNoi',
    label: 'Pro Forma NOI',
    placeholder: 'ex. $100,000',
  },
  proFormaGrossRentalIncome: {
    type: FIELD_TYPES.DOLLARS,
    hint: 'Total Rental Revenue of a subject property',
    fieldName: 'proFormaGrossRentalIncome',
    label: 'Pro Forma Gross Rental Income',
    placeholder: 'ex. $100,000',
  },
  proFormaRevPar: {
    type: FIELD_TYPES.TEXT,
    hint:
      "Revenue per available room (RevPAR) is calculated by multiplying a hotel's average daily room rate (ADR) by its occupancy rate or by dividing a hotel's total room revenue by the total number of available rooms in the period being measured.",
    fieldName: 'proFormaRevPar',
    label: 'Pro Forma Rev Par',
    placeholder: 'ex. $100',
  },
  proFormaNumberOfKeys: {
    type: FIELD_TYPES.NUMBER,
    fieldName: 'proFormaNumberOfKeys',
    label: 'Pro Forma Number of keys',
    placeholder: 'ex. 100',
  },
  maxRate: {
    type: FIELD_TYPES.PERCENT,
    fieldName: 'maxRate',
  },
  minRate: {
    type: FIELD_TYPES.PERCENT,
    fieldName: 'minRate',
  },
  // sponsor profile
  sponsorProfile: {
    address: { type: FIELD_TYPES.TEXT, fieldName: 'address' },
    avgPropertyPrice: {
      type: FIELD_TYPES.CHOICE,
      fieldName: 'avgPropertyPrice',
      label: 'Average property price',
    },
    ficoScore: { type: FIELD_TYPES.CHOICE, fieldName: 'ficoScore', label: 'FICO score' },
    investmentOwn: {
      type: FIELD_TYPES.CHOICE,
      fieldName: 'investmentOwn',
      label: 'Investment properties own',
    },
    liquidity: { type: FIELD_TYPES.DOLLARS, fieldName: 'liquidity', label: 'Liquidity' },
    netWorth: {
      type: FIELD_TYPES.DOLLARS,
      fieldName: 'netWorth',
      label: 'Net worth',
    },
    propertiesPurchased: {
      type: FIELD_TYPES.CHOICE,
      fieldName: 'propertiesPurchased',
      label: 'Properties purchased last year',
    },
    state: { type: FIELD_TYPES.CHOICE, fieldName: 'state', label: "Sponsor's residence" },
    story: { type: FIELD_TYPES.TEXT, fieldName: 'story', label: 'Sponsor Story' },
    yearsOfExperienceInCre: {
      type: FIELD_TYPES.NUMBER,
      fieldName: 'yearsOfExperienceInCre',
      label: 'CRE ownership experience (in years)',
    },
    zip: { type: FIELD_TYPES.NUMBER, fieldName: 'zip' },
  },
  // sources and uses
  sourceCustom1Field: {
    fieldName: 'sourceCustom1Field',
    type: FIELD_TYPES.TEXT,
    maxLength: 200,
  },
  sourceCustom1Value: { fieldName: 'sourceCustom1Value', type: FIELD_TYPES.DOLLARS },
  sourceCustom2Field: {
    fieldName: 'sourceCustom2Field',
    type: FIELD_TYPES.TEXT,
    maxLength: 200,
  },
  sourceCustom2Value: { fieldName: 'sourceCustom2Value', type: FIELD_TYPES.DOLLARS },
  sourceCustom3Field: {
    fieldName: 'sourceCustom3Field',
    type: FIELD_TYPES.TEXT,
    maxLength: 200,
  },
  sourceCustom3Value: { fieldName: 'sourceCustom3Value', type: FIELD_TYPES.DOLLARS },
  sourceCustom4Field: {
    fieldName: 'sourceCustom4Field',
    type: FIELD_TYPES.TEXT,
    maxLength: 200,
  },
  sourceCustom4Value: { fieldName: 'sourceCustom4Value', type: FIELD_TYPES.DOLLARS },
  sourceCustom5Field: {
    fieldName: 'sourceCustom5Field',
    type: FIELD_TYPES.TEXT,
    maxLength: 200,
  },
  sourceCustom5Value: { fieldName: 'sourceCustom5Value', type: FIELD_TYPES.DOLLARS },
  sourceCustom6Field: {
    fieldName: 'sourceCustom6Field',
    type: FIELD_TYPES.TEXT,
    maxLength: 200,
  },
  sourceCustom6Value: { fieldName: 'sourceCustom6Value', type: FIELD_TYPES.DOLLARS },
  sourceCustom7Field: {
    fieldName: 'sourceCustom7Field',
    type: FIELD_TYPES.TEXT,
    maxLength: 200,
  },
  sourceCustom7Value: { fieldName: 'sourceCustom7Value', type: FIELD_TYPES.DOLLARS },
  sourceCustom8Field: {
    fieldName: 'sourceCustom8Field',
    type: FIELD_TYPES.TEXT,
    maxLength: 200,
  },
  sourceCustom8Value: { fieldName: 'sourceCustom8Value', type: FIELD_TYPES.DOLLARS },
  sourceCustom9Field: {
    fieldName: 'sourceCustom9Field',
    type: FIELD_TYPES.TEXT,
    maxLength: 200,
  },
  sourceCustom9Value: { fieldName: 'sourceCustom9Value', type: FIELD_TYPES.DOLLARS },
  sourceCustom10Field: {
    fieldName: 'sourceCustom10Field',
    type: FIELD_TYPES.TEXT,
    maxLength: 200,
  },
  sourceCustom10Value: { fieldName: 'sourceCustom10Value', type: FIELD_TYPES.DOLLARS },
  sourcesOther: { fieldName: 'sourcesOther', type: FIELD_TYPES.DOLLARS, label: 'Other' },
  useCustom1Field: {
    fieldName: 'useCustom1Field',
    type: FIELD_TYPES.TEXT,
    maxLength: 200,
  },
  useCustom1Value: { fieldName: 'useCustom1Value', type: FIELD_TYPES.DOLLARS },
  useCustom2Field: {
    fieldName: 'useCustom2Field',
    type: FIELD_TYPES.TEXT,
    maxLength: 200,
  },
  useCustom2Value: { fieldName: 'useCustom2Value', type: FIELD_TYPES.DOLLARS },
  useCustom3Field: {
    fieldName: 'useCustom3Field',
    type: FIELD_TYPES.TEXT,
    maxLength: 200,
  },
  useCustom3Value: { fieldName: 'useCustom3Value', type: FIELD_TYPES.DOLLARS },
  useCustom4Field: {
    fieldName: 'useCustom4Field',
    type: FIELD_TYPES.TEXT,
    maxLength: 200,
  },
  useCustom4Value: { fieldName: 'useCustom4Value', type: FIELD_TYPES.DOLLARS },
  useCustom5Field: {
    fieldName: 'useCustom5Field',
    type: FIELD_TYPES.TEXT,
    maxLength: 200,
  },
  useCustom5Value: { fieldName: 'useCustom5Value', type: FIELD_TYPES.DOLLARS },
  useCustom6Field: {
    fieldName: 'useCustom6Field',
    type: FIELD_TYPES.TEXT,
    maxLength: 200,
  },
  useCustom6Value: { fieldName: 'useCustom6Value', type: FIELD_TYPES.DOLLARS },
  useCustom7Field: {
    fieldName: 'useCustom7Field',
    type: FIELD_TYPES.TEXT,
    maxLength: 200,
  },
  useCustom7Value: { fieldName: 'useCustom7Value', type: FIELD_TYPES.DOLLARS },
  useCustom8Field: {
    fieldName: 'useCustom8Field',
    type: FIELD_TYPES.TEXT,
    maxLength: 200,
  },
  useCustom8Value: { fieldName: 'useCustom8Value', type: FIELD_TYPES.DOLLARS },
  useCustom9Field: {
    fieldName: 'useCustom9Field',
    type: FIELD_TYPES.TEXT,
    maxLength: 200,
  },
  useCustom9Value: { fieldName: 'useCustom9Value', type: FIELD_TYPES.DOLLARS },
  useCustom10Field: {
    fieldName: 'useCustom10Field',
    type: FIELD_TYPES.TEXT,
    maxLength: 200,
  },
  useCustom10Value: { fieldName: 'useCustom10Value', type: FIELD_TYPES.DOLLARS },
  usesOther: { fieldName: 'usesOther', type: FIELD_TYPES.DOLLARS, label: 'Other' },
  softCost: { fieldName: 'softCost', type: FIELD_TYPES.DOLLARS, label: 'Soft Cost' },
  hardCost: { fieldName: 'hardCost', type: FIELD_TYPES.DOLLARS, label: 'Hard Cost' },
  closingCost: {
    fieldName: 'closingCost',
    type: FIELD_TYPES.DOLLARS,
    label: 'Closing Cost',
  },
  mezzanine: {},
  equityAmount: {
    fieldName: 'equityAmount',
    type: FIELD_TYPES.DOLLARS,
    label: 'Borrower Equity',
  },
  other: { fieldName: 'other', type: FIELD_TYPES.DOLLARS, label: 'Other' },
  rehab: { fieldName: 'rehab', type: FIELD_TYPES.DOLLARS, label: 'Rehab' },
  // market info
  marketInformation: { type: FIELD_TYPES.TEXT, fieldName: 'marketInformation' },

  // executive summary

  summary: { type: FIELD_TYPES.TEXT, fieldName: 'summary' },
  ownerId: { type: FIELD_TYPES.CHOICE, fieldName: 'ownerId' },
  borrowerContactId: { type: FIELD_TYPES.CHOICE, fieldName: 'borrowerContactId' },
};
