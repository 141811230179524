import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';

import * as d3 from 'd3';
import PropTypes from 'prop-types';

import { CHART_BOXPLOT_CONFIGURATION } from 'features/Analytics/config';
import { Portal, BoxplotTooltip, BoxplotItem } from 'features/Analytics/components';
import { useWindowSize } from 'lib/hooks';

const chartHeight =
  CHART_BOXPLOT_CONFIGURATION.CHART_INITIAL_HEIGHT -
  CHART_BOXPLOT_CONFIGURATION.CHART_MARGIN_TOP -
  CHART_BOXPLOT_CONFIGURATION.CHART_MARGIN_BOTTOM;

const scaleYAdditionalOffset = 0.5;

export const Chart = ({
  boxColor,
  lineColor,
  boxHoverColor,
  boxPlotData,
  outliersData,
  title,
  titleSymbol,
  loansCount = [],
  ranges = [],
  minMax = [],
  trackSeeAll,
  trackBoxplot,
  xAxis,
}) => {
  const ref = useRef();
  const size = useWindowSize();
  const Xcount = boxPlotData.length;

  const yMinMax = calculateMinMaxY([...boxPlotData, ...outliersData]);
  const yHeightIndex = chartHeight / (yMinMax.max - yMinMax.min);

  const titleText = `${title}${titleSymbol ? `, ${titleSymbol}` : ''}`;

  const chartWidth = ref.current
    ? ref.current.getBoundingClientRect().width
    : CHART_BOXPLOT_CONFIGURATION.CHART_WIDTH;

  const chartBarPaneWidth =
    chartWidth -
    CHART_BOXPLOT_CONFIGURATION.CHART_MARGIN_LEFT -
    CHART_BOXPLOT_CONFIGURATION.CHART_MARGIN_RIGHT;

  const barWidth = Math.min(
    CHART_BOXPLOT_CONFIGURATION.BAR_WIDTH,
    chartBarPaneWidth / Xcount
  );

  const tooltipRightOffset =
    CHART_BOXPLOT_CONFIGURATION.CHART_MARGIN_LEFT +
    CHART_BOXPLOT_CONFIGURATION.TOOLTIP_WIDTH +
    CHART_BOXPLOT_CONFIGURATION.CHART_MARGIN_RIGHT;

  // Less or equals 1 means show every label
  // Greater than 1 means skip every {skipXLabelCount} label
  const skipXLabelCount =
    Math.floor(1 / (chartBarPaneWidth / Xcount / CHART_BOXPLOT_CONFIGURATION.BAR_WIDTH)) +
    1;

  const x = d3
    .scaleLinear()
    .domain([0, Xcount])
    .range([0, chartBarPaneWidth]);

  useEffect(() => {
    const svg = d3
      .select(ref.current)
      .append('g')
      .attr('transform', `translate(${CHART_BOXPLOT_CONFIGURATION.CHART_MARGIN_LEFT},0)`);

    svg
      .append('g')
      .attr('transform', `translate(0,${chartHeight})`)
      .call(
        d3
          .axisBottom(x)
          .ticks(Xcount)
          .tickFormat((i) =>
            skipXLabelCount <= 1 || (i - 1) % skipXLabelCount === 0
              ? xAxis[i - 1] || ''
              : ''
          )
      )
      .selectAll('text')
      .style('text-anchor', 'end')
      .attr('dx', '-.8em')
      .attr('dy', '.15em')
      .attr('transform', 'rotate(-65)');

    // Show the Y scale
    const y = d3
      .scaleLinear()
      .domain([yMinMax.min, yMinMax.max])
      .range([chartHeight, 0]);

    svg.append('g').call(d3.axisLeft(y));
    svg
      .append('text')
      .attr('transform', 'rotate(-90)')
      .attr('y', 0 - CHART_BOXPLOT_CONFIGURATION.CHART_MARGIN_LEFT)
      .attr('x', 0 - chartHeight / 2)
      .attr('dy', '1em')
      .style('text-anchor', 'middle')
      .style('text-transform', 'capitalize')
      .text(titleText);

    return () => svg && svg.remove();
  }, [
    Xcount,
    yMinMax.max,
    yMinMax.min,
    xAxis,
    titleText,
    size,
    chartWidth,
    x,
    skipXLabelCount,
  ]);

  const [data, setData] = useState({});

  return (
    <Wrapper onMouseLeave={() => setData({})}>
      <Title>{titleText}</Title>
      <SvgWrapper
        ref={ref}
        boxColor={boxColor}
        lineColor={lineColor}
        boxHoverColor={boxHoverColor}
      >
        {boxPlotData.map((item, i) =>
          item.length ? (
            <BoxplotItem
              key={i}
              index={i}
              maxValue={yMinMax.max}
              data={item}
              heightIndex={yHeightIndex}
              outliers={outliersData[i]}
              setData={setData}
              boxHoverColor={boxHoverColor}
              active={i === data.index}
              trackBoxplot={trackBoxplot}
              title={title}
              barWidth={barWidth}
              // Note that D3 x[0] means (0,0)
              x={x(i + 1)}
            />
          ) : null
        )}
      </SvgWrapper>

      {data.left && (
        <Portal>
          <BoxplotTooltip
            data={data}
            tooltipPositionRight={data.left + tooltipRightOffset > chartBarPaneWidth}
            onMouseLeave={() => setData({})}
            boxPlotData={boxPlotData[data.index]}
            outliers={outliersData[data.index]}
            loansCount={loansCount[data.index]}
            ranges={ranges[data.index]}
            minMax={minMax[data.index]}
            title={title}
            titleSymbol={titleSymbol}
            boxColor={boxColor}
            trackSeeAll={trackSeeAll}
          />
        </Portal>
      )}
    </Wrapper>
  );
};

Chart.propTypes = {
  boxColor: PropTypes.string.isRequired,
  lineColor: PropTypes.string.isRequired,
  boxHoverColor: PropTypes.string.isRequired,
  boxPlotData: PropTypes.arrayOf(PropTypes.array).isRequired,
  outliersData: PropTypes.arrayOf(PropTypes.array).isRequired,
  ranges: PropTypes.arrayOf(PropTypes.array).isRequired,
  minMax: PropTypes.arrayOf(PropTypes.array).isRequired,
  loansCount: PropTypes.arrayOf(PropTypes.number).isRequired,
  title: PropTypes.string.isRequired,
  titleSymbol: PropTypes.string,
  trackSeeAll: PropTypes.func,
  trackBoxplot: PropTypes.func,
};

const calculateMinMaxY = (values = []) => {
  const flatternValues = values.flat();
  const maxValue = Math.max.apply(null, flatternValues);
  const minValue = Math.min.apply(null, flatternValues);

  const maxPossibleValue = maxValue + scaleYAdditionalOffset;
  // we need to show our axis a bit larger than our data
  return {
    min: minValue - 0.5,
    max: maxPossibleValue > 100 ? maxValue : maxPossibleValue,
  };
};

const SvgWrapper = styled.svg`
  width: 100%;
  min-width: ${(p) => p.maxWidth + 100}px;
  height: ${chartHeight +
    CHART_BOXPLOT_CONFIGURATION.CHART_MARGIN_TOP +
    CHART_BOXPLOT_CONFIGURATION.CHART_MARGIN_BOTTOM}px;

  .line,
  .median {
    stroke: ${(p) => p.lineColor};
  }

  .box {
    fill: ${(p) => p.boxColor};
  }

  .circle {
    fill: ${(p) => p.boxColor};
  }

  .boxplot {
    cursor: pointer;
    &:hover {
      .box {
        fill: ${(p) => p.boxHoverColor};
      }
    }
  }
`;

const Wrapper = styled.div`
  background: #fff;
  padding: 24px;
  margin-top: 32px;
  border-radius: 16px;
  overflow-x: scroll;
`;

const Title = styled.div`
  font-size: 20px;
  font-size: 24px;
  margin-bottom: 24px;
  text-transform: capitalize;
`;
