import styled, { css } from 'styled-components/macro';

import { ReactComponent as Icon } from 'icons/ico_cross.svg';

export const CrossIcon = styled(Icon)`
  ${(p) =>
    p.color &&
    css`
      path {
        stroke: ${p.color};
      }
    `}
`;
