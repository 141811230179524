import { unitOptions, toggleOptions, choiceBooleanOptions } from 'utils/fields';
import { keys, findIndex } from 'ramda';
import { normalizeNumbers, normalizeDollars, normalizeThousands } from 'lib/normalizers';
import { makeSelectItem } from 'lib/loan';
import { wordsRemove } from 'lib/strings';
import { format } from 'date-fns';
import { camelize } from 'humps';

export const processInitialValue = (loan, field = {}, options) => {
  const { fieldName, type } = field;
  switch (type) {
    case 'text':
    case 'duration':
    case 'percent':
      return loan[fieldName];
    case 'number':
      return normalizeThousands(loan[fieldName]);
    case 'float':
      return normalizeNumbers(loan[fieldName]);
    case 'date':
      return loan[fieldName] ? format(new Date(loan[fieldName]), 'MM-dd-yyyy') : null;
    case 'choice':
      if (fieldName !== 'ownerId') {
        return options ? makeSelectItem(loan[fieldName], options) : null;
      }

      return makeSelectItem(loan?.owner?.id, options);
    case 'dollars':
      return normalizeDollars(loan[fieldName]);
    case 'durationUnit':
      return unitOptions[loan[fieldName] || 0];
    case 'toggle':
      return toggleOptions[loan[fieldName] ? 1 : 0];
    case 'mainAddress':
      return loan?.[fieldName]?.length ? loan[fieldName][0] : '';
    case 'additionalAddresses':
      return loan?.[fieldName]?.length ? loan[fieldName].slice(1) : [];
    case 'choice_boolean':
      return choiceBooleanOptions.find((item) => item.value === loan[fieldName]);
    default:
      return null;
  }
};

export const normalizeFormData = (formData, config) => {
  const result = {};

  keys(formData).forEach((field) => {
    const formValue = formData[field];

    if (!config[field]) {
      return;
    }

    const { type, fieldName } = config[field];
    switch (type) {
      case 'text':
      case 'duration':
      case 'percent':
      case 'float':
        result[fieldName] = formData[field] || null;
        break;
      case 'dollars':
      case 'number':
        if (formData[field] === null || formData[field] === undefined) {
          result[fieldName] = null;
        } else {
          result[fieldName] = Number(wordsRemove(formData[field]));
        }

        break;
      case 'date':
        if (!formData[field]) {
          result[fieldName] = null;
        } else if (typeof formData[field] === 'string') {
          const dateParts = formData[field].split('-');
          result[fieldName] = `${dateParts[2]}-${dateParts[0]}-${dateParts[1]}`;
        } else {
          result[fieldName] = format(new Date(formData[field]), 'yyyy-MM-dd');
        }
        break;
      case 'choice':
      case 'toggle':
      case 'durationUnit':
        result[fieldName] = formValue && formValue.value;
        break;
      case 'mainAddress':
        result[fieldName] = [...(result[fieldName] || []), formValue];
        break;
      case 'additionalAddresses':
        result[fieldName] = [...(result[fieldName] || []), ...(formValue || [])];
        break;
      case 'boolean':
        result[fieldName] = formValue ? true : false;
        break;
      case 'choice_boolean':
        result[fieldName] = formValue && formValue.value;
        break;

      default:
        break;
    }
  });
  return result;
};

// Takes fields schema and return flat list of fields
export const processFieldsScheme = ({ optional, required }) => {
  const requiredFields = required.map(camelize);
  const optionalFields = optional.map(({ fields }) => fields.map(camelize));
  return [
    ...requiredFields,
    ...optionalFields.reduce((acc, items) => [...acc, ...items], []),
  ];
};

// TODO: Need to implement loan documents section normalization for manage purpose
export const getDocumentsUpdatePath = ({
  currentState,
  folderId,
  sectionId,
  documentId,
}) => {
  let path = 'loanData.documentsAll.';

  if (sectionId) {
    const sectionIndex = findIndex(
      (item) => item.sectionId === sectionId,
      currentState.loanData.documentsAll.sections
    );
    const folderIndex = findIndex(
      ({ id }) => id === folderId,
      currentState.loanData.documentsAll.sections[sectionIndex].folders
    );

    path = `${path}sections.${sectionIndex}.folders.${folderIndex}.documents`;

    if (documentId) {
      const documentIndex = findIndex(
        (item) => item.id === documentId,
        currentState.loanData.documentsAll.sections[sectionIndex].folders[folderIndex]
          .documents
      );
      path = `${path}.${documentIndex}`;
    }
  } else {
    const folderIndex = findIndex(
      ({ id }) => id === folderId,
      currentState.loanData.documentsAll.recommended
    );
    path = `${path}recommended.${folderIndex}.documents`;

    if (documentId) {
      const documentIndex = findIndex(
        (item) => item.id === documentId,
        currentState.loanData.documentsAll.recommended[folderIndex].documents
      );
      path = `${path}.${documentIndex}`;
    }
  }

  return path;
};
