export const makeLoanTerm = (term) => {
  if (term > 12) {
    const years = parseInt(term / 12, 10);
    const months = term % 12;
    return `${years} ${years > 1 ? 'years' : 'year'} ${
      months !== 0 ? ` ${months} ${months > 1 ? 'months' : 'month'}` : ''
    }`;
  }

  return `${term} ${term > 1 ? 'months' : 'month'}`;
};
