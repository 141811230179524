import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import {withRouter} from 'react-router-dom';
import { MenuButton } from 'ui/MenuButton';
import { LOAN_STATUS, LENDER_LOAN_STAGE } from 'constants/appRelated';
import { showModal, manualOfferModalRequest } from 'actions/modalActions';
import { modals, INFO_MODAL_TYPE } from 'constants/modalTypes';
import { exportStatusReportRequest, createNewDealRoomRequest } from 'features/LoanNew/actions';
import { userTypeSelector } from 'selectors/userType';
import { getLenderTeamPresence } from 'containers/LoanWrapper/selectors';

import {
  ExportStatusReportControl,
  PrintControl,
  ShareControl,
  AddQuoteControl,
  CloseLoanControl,
  AssignToControl,
  AssignToLenderControl,
  DuplicateLoanControl,
  OpenDealRoom,
} from './Buttons';

const LoanMenuActions = ({
                           match,
                           userType,
                           loan,
                           openManualQuoteModal,
                           closeLoan,
                           openAssignDealModal,
                           duplicateLoan,
                           bbTeam,
                           exportStatusReport,
                           lenderHasTeam,
                           openAssignLenderLoanModal,
                           onAssignLoanToLender,
                           openDealRoomModal,
                           hasOpenedDealRoom,
                           loanId,
                           enableOpenDealRoomInMenu,
                           ...rest
                         }) => {
  let actions = [];

  const handleDuplicateLoan = useCallback(() => {
    duplicateLoan({ loanId: loan.id, propertyName: loan.propertyName });
  }, [loan.id, loan.propertyName, duplicateLoan]);

  const handleOpenAssignLenderModal = useCallback(() => {
    openAssignDealModal({ team: bbTeam, loanId: loan.id, owner: loan.owner });
  }, [bbTeam, loan.id, loan.owner, openAssignDealModal]);

  const handleLoanClose = useCallback(() => {
    closeLoan({ loanId: loan.id, userType });
  }, [loan.id, closeLoan]);

  const handleOpenManualQuoteModal = useCallback(() => {
    openManualQuoteModal({ loanId: loan.id });
  }, [loan.id, openManualQuoteModal]);

  const handleExportStatusReport = useCallback(() => {
    exportStatusReport({ loanId: loan.id });
  }, [loan.id, exportStatusReport]);

  const handleOpenAssignLenderLoanModal = useCallback(() => {
    openAssignLenderLoanModal({ loanId: loan.id, onAssignLoanToLender });
  }, [loan.id, openAssignLenderLoanModal, onAssignLoanToLender]);

  const handleOpenDealRoomConfirmModal = useCallback(() => {
    openDealRoomModal(loan.id);
  }, [loan.id, openDealRoomModal]);

  if (userType.lender) {
    actions = [
      <PrintControl key='PrintControl' loanId={loan.id} />,
      <CloseLoanControl key="CloseLoanControl" handleLoanClose={handleLoanClose} />,
    ];

    if(loan.lenderStatus === LENDER_LOAN_STAGE.CLOSED){
      actions = [
        <PrintControl key='PrintControl' loanId={loan.id} />,
      ];
    }

    if (lenderHasTeam) {
      actions = [
        ...actions,
        <AssignToLenderControl
          key="AssignToLenderControl"
          handleOpenAssignLenderLoanModal={handleOpenAssignLenderLoanModal}
        />,
      ];
    }

    if(enableOpenDealRoomInMenu && !hasOpenedDealRoom) {
      actions = [
        ...actions,
        <OpenDealRoom
          handleOpenDealRoomConfirmModal={handleOpenDealRoomConfirmModal}
          key='OpenDealRoom'
        />,
      ];
    }
  } else {
    if (loan.status === LOAN_STATUS.DRAFT) {
      actions = [
        <DuplicateLoanControl
          key="DuplicateLoanControl"
          handleDuplicateLoan={handleDuplicateLoan}
        />,
        <AssignToControl
          key="AssignToControl"
          handleOpenAssignLenderModal={handleOpenAssignLenderModal}
        />,
        <PrintControl key="PrintControl" loanId={loan.id} />,
        <CloseLoanControl key="CloseLoanControl" handleLoanClose={handleLoanClose} />,
      ];
    } else if (
      loan.status === LOAN_STATUS.POSTED_TO_MARKETPLACE ||
      loan.status === LOAN_STATUS.PENDING_CLOSING
    ) {
      actions = [
        <ShareControl
          key="ShareControl"
          defaultInvitationSubject={loan.defaultInvitationSubject}
          loanUid={loan.uid}
        />,
        <AddQuoteControl
          key="AddQuoteControl"
          handleOpenManualQuoteModal={handleOpenManualQuoteModal}
        />,
        <DuplicateLoanControl
          key="DuplicateLoanControl"
          handleDuplicateLoan={handleDuplicateLoan}
        />,
        <AssignToControl
          key="AssignToControl"
          handleOpenAssignLenderModal={handleOpenAssignLenderModal}
        />,
        <ExportStatusReportControl
          key="ExportStatusReportControl"
          handleExportStatusReport={handleExportStatusReport}
        />,
        <PrintControl key="PrintControl" loanId={loan.id} />,
        <CloseLoanControl key="CloseLoanControl" handleLoanClose={handleLoanClose} />,
      ];
    } else if (
      loan.status === LOAN_STATUS.CLOSED_LOST ||
      loan.status === LOAN_STATUS.CLOSED_WON ||
      loan.status === LOAN_STATUS.CLOSED_WITHDRAWN
    ) {
      actions = [
        <DuplicateLoanControl
          key="DuplicateLoanControl"
          handleDuplicateLoan={handleDuplicateLoan}
        />,
        <ExportStatusReportControl
          key="ExportStatusReportControl"
          handleExportStatusReport={handleExportStatusReport}
        />,
        <PrintControl key="PrintControl" loanId={loan.id} />,
      ];
    }
  }

  return actions?.length ? (
    <div data-testid="loanMenuActions">
      <MenuButton {...rest}>{actions}</MenuButton>
    </div>
  ) : null;
};

function mapStateToProps(state) {
  const { currentUser, dealRooms, dealRoom } = state;

  return {
    bbTeam: currentUser?.team?.filter(({ joined }) => joined),
    currentUserId: currentUser.id,
    userType: userTypeSelector(state),
    lenderHasTeam: getLenderTeamPresence(state),
    hasOpenedDealRoom: !!dealRooms?.rooms?.length,
    loanId: dealRoom?.room?.loanId,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    duplicateLoan: ({ propertyName, loanId }) => {
      dispatch(
        showModal(modals.DUPLICATE_LOAN, {
          loanId,
          size: 'sm',
          propertyName,
        })
      );
    },

    openDealRoomModal: (loanId) => {
      dispatch(showModal(modals.INFO_MODAL, {
          infoType: INFO_MODAL_TYPE.PROCESS,
          title: 'Do you want to open a deal room?',
          text:
            'In the deal room, create checklists to have all loan documents organized at any stage of the transaction.',
          buttonText: 'Yes',
          size: 'sm',
          buttonRejectText: 'No',
          onButtonClick: (dispatch) => {
            dispatch(createNewDealRoomRequest(loanId))
          },
        }),
      );
    },

    openAssignDealModal: ({ team, loanId, owner }) => {
      dispatch(
        showModal(modals.ASSIGN_DEAL_MODAL, {
          loanId,
          size: 'sm',
          team,
          currentOwner: owner,
        })
      );
    },
    closeLoan: ({ loanId, userType }) => {
      dispatch(showModal(modals.LOAN_CLOSE_SELECT, { loanId, size: 'sm', userType }));
    },
    openManualQuoteModal: ({ loanId }) => {
      dispatch(manualOfferModalRequest({ loanId }));
    },
    exportStatusReport: ({ loanId }) => {
      dispatch(
        showModal(modals.INFO_MODAL, {
          size: 'sm',
          infoType: INFO_MODAL_TYPE.PROCESS,
          title: 'We will send you link to download the report soon',
          text:
            'Not getting our emails? Make sure that you’ve added info@realatom.com to your whitelist.',
        })
      );

      dispatch(exportStatusReportRequest({ loanId }));
    },
    openAssignLenderLoanModal: ({ loanId, onAssignLoanToLender }) => {
      dispatch(
        showModal(modals.ASSIGN_LOAN_MODAL, {
          size: 'md',
          loanId,
          onSubmit: () => {
            if (onAssignLoanToLender) {
              onAssignLoanToLender();
            }
          },
        })
      );
    },
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LoanMenuActions));
