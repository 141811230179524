import React from 'react';
import {
  SectionTitle,
  SectionHead,
  OffersSection,
  OffersListSection,
  OffersSectionLink,
} from 'styles/loan';
import { OFFER_STATE } from 'constants/appRelated';
import { ButtonIcon } from 'ui/Button';
import { Tooltip } from 'ui/Tooltip';

import { OffersSectionLabel } from './styles';
import { OffersListTable } from './OffersListTable';

const offersOrder = [
  OFFER_STATE.WINNING_BID,
  OFFER_STATE.NEW_OFFER,
  OFFER_STATE.NEGOTIATION,
  OFFER_STATE.ACCEPTED,
  OFFER_STATE.MANUAL_QUOTE,
  OFFER_STATE.REJECTED,
  OFFER_STATE.CANCELLED,
];

const getOfferStatus = (state) => {
  const status = state.replace('_', ' ')?.toUpperCase();

  switch (status) {
    case 'REJECTED':
      return 'PASSED';
    case 'NEW OFFER':
      return 'NEW QUOTE';

    default:
      return status;
  }
};

export const OffersList = React.forwardRef(({ negotiations, isOwner, id }, ref) => (
  <OffersSection ref={ref}>
    <SectionHead>
      <SectionTitle>Quotes</SectionTitle>
      <OffersSectionLink to={`/dashboard/property-details/${id}/offers`}>
        <ButtonIcon iconUrl="/images/icons/ico_arrow_right.svg">
          Go to Quotes Page
        </ButtonIcon>
      </OffersSectionLink>
    </SectionHead>
    {offersOrder.map(
      (state) =>
        negotiations[state] && (
          <OffersListSection key={state}>
            <OffersSectionLabel state={state}>
              <span>{getOfferStatus(state)}</span>
              {state === OFFER_STATE.MANUAL_QUOTE && (
                <Tooltip text="This is a quote you have logged" />
              )}
            </OffersSectionLabel>
            <OffersListTable offers={negotiations[state]} isOwner={isOwner} />
          </OffersListSection>
        )
    )}
  </OffersSection>
));

OffersList.displayName = 'OffersList';
