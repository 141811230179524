import React from 'react';
import styled from 'styled-components/macro';
import { useDispatch } from 'react-redux';
import { getLenderDetailsRequest } from 'containers/UserDetails/actions';
import { UserRepresentation } from 'styles/global';

const LabelWrapper = styled.div``;

const LabelCompany = styled.div`
  font-size: 12px;
  color: #778598;
`;

export const OffersListLender = ({ companyName, firstName, lastName, id }) => {
  const dispatch = useDispatch();
  return (
    <LabelWrapper>
      <UserRepresentation
        onClick={id ? () => dispatch(getLenderDetailsRequest(id)) : null}
      >{`${firstName} ${lastName}`}</UserRepresentation>
      <LabelCompany>{companyName}</LabelCompany>
    </LabelWrapper>
  );
};
