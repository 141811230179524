export const AUTH_ROUTES = {
  LOGIN: '/login',
  REGISTRATION: '/registration',
  REGISTRATION_LENDER: '/registration/lender',
  REGISTRATION_BROKER: '/registration/broker',
  REGISTRATION_BORROWER: '/registration/borrower',
  REGISTRATION_LISTING: '/registration-listing',
  PASSWORD_RESET: '/password-reset',
  SET_PASSWORD_LISTING: '/set-password-listing',
  SET_NEW_PASSWORD: '/set-new-password',
  SET_LENDER_PASSWORD: '/set-lender-password',
  TEAM_MEMBER: '/team-member',
  CONFIRM_EMAIL: '/auth/confirm-email',
  PASSWORD_RESET_CHECK: '/reset_password',
  CONFIRM_AUTH: '/confirm-auth',
  RECOVERY_LOGIN: '/recovery-login',
};
