import React from 'react';
import { Checkbox } from 'ui/Checkbox';

export const NewCheckbox = ({
  input: { name, value, checked, ...restInput },
  label,
  style,
  ...custom
}) => (
  <React.Fragment>
    <Checkbox {...custom} {...restInput} checked={checked} name={name} label={label} />
  </React.Fragment>
);
