import React from 'react';
import { Form, Field } from 'react-final-form';
import styled from 'styled-components/macro';
import { Input, DropdownList } from 'components/Form';
import {
  ModalBody,
  ModalHeading,
  ModalActions,
  modalWithContentWrapperStyles,
} from 'ui/Modal/styles';
import { normalizeMobile } from 'lib/normalizers';
import { Button } from 'ui/Button';
import { delay, simpleMemoize } from 'lib/utils';
import Yup from 'yup';
import { validate, validateEmail } from 'utils/validate';
import {
  contactDirectoryInviteRequest,
  checkContactDirectoryEmailRequest,
} from 'features/Directory/pages/ContactsDirectory/actions';

import { CreatableDropDown } from 'ui/DropDown';

const schema = Yup.object().shape({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  phone: Yup.string().required('Phone is required!'),
  types: Yup.array()
    .min(1, 'Contact type is required!')
    .required('Contact type is required!'),
});

export class AddContact extends React.Component {
  state = { tags: [] };

  handleChange = (value) => {
    this.setState({ tags: value });
  };

  noOptionsMessage() {
    return 'No tags available';
  }

  asyncValidate = simpleMemoize(async (email) => {
    const { dispatch } = this.props;

    if (!email) {
      return 'Email is required';
    }

    if (!validateEmail(email)) {
      return 'Invalid email address';
    }

    if (email) {
      const res = await delay(1000).then(() =>
        new Promise((resolve, reject) => {
          dispatch(checkContactDirectoryEmailRequest({ email, resolve, reject }));
        }).then((v) => v, (error) => error)
      );
      return res;
    }
  });

  submitForm = (values) => {
    const { dispatch, hideModal, IsAddContactLoan, IsAddContactContacts } = this.props;
    const { tags } = this.state;
    let request = { ...values, tags };

    if (request.phone) {
      request.phone = request.phone.replace(/[^0-9]/g, '');
    }

    if (tags) {
      request.tags = request.tags.map((tag) => tag.value);
    }

    if (values.types) {
      request.types = request.types.map((tag) => tag.value);
    }

    hideModal();
    dispatch(
      contactDirectoryInviteRequest({ request, IsAddContactLoan, IsAddContactContacts })
    );
  };

  render() {
    const { possibleTags, contactsTypes, initialValues, IsAddContactLoan } = this.props;
    const { tags } = this.props;
    return (
      <Form
        onSubmit={this.submitForm}
        validate={(values) => validate(values, schema)}
        initialValues={initialValues}
        render={({ handleSubmit, submiting, validating }) => (
          <FormWrapper onSubmit={handleSubmit}>
            <ModalHeading>Add a new contact</ModalHeading>
            <ModalBody>
              <Field
                name="types"
                component={DropdownList}
                options={contactsTypes}
                isMulti
                disabled={IsAddContactLoan}
                mb={3}
                label="Please select type of contact"
                required
              />
              <Field name={'firstName'} component={Input} label="First Name" required />
              <Field name={'lastName'} component={Input} label="Last Name" required />
              <Field
                name={'email'}
                component={Input}
                validate={this.asyncValidate}
                label="Email"
                required
              />
              <Field name={'companyName'} component={Input} label="Company name" />
              <Field
                name={'phone'}
                component={Input}
                label="Work phone"
                parse={normalizeMobile}
                required
              />
              <TagsWrapper>
                <TagsTitle>
                  <TagsImg src="/images/icons/ico_tag.svg" alt="" />
                  Tags
                </TagsTitle>
                <TagsSelect>
                  <CreatableDropDown
                    isMulti
                    onChange={this.handleChange}
                    noOptionsMessage={this.noOptionsMessage}
                    options={possibleTags}
                    value={tags}
                    dropup
                    placeholder="Type to add a new tag or select from the list"
                  />
                </TagsSelect>
              </TagsWrapper>
            </ModalBody>
            <ModalActions>
              <Button type="submit" secondary disabled={submiting || validating}>
                Add contact
              </Button>
            </ModalActions>
          </FormWrapper>
        )}
      />
    );
  }
}

const FormWrapper = styled.form`
  ${modalWithContentWrapperStyles}
`;
const TagsWrapper = styled.div``;

const TagsTitle = styled.span`
  color: #000000;
  margin-right: 16px;
`;

const TagsSelect = styled.div`
  flex: 1;
  width: 100%;
  margin-top: 8px;
`;

const TagsImg = styled.img`
  display: inline-block;
  vertical-align: middle;
  margin-right: 8px;
`;
