import { FIELD_TYPES } from 'constants/appRelated';

export const isFormEmpty = ({ values, fields, fieldsConfig }) => {
  let isFormFilled = false;

  fields.forEach((field) => {
    const value = values[field];
    const fieldData = fieldsConfig[field];

    switch (fieldData.type) {
      case FIELD_TYPES.DOLLARS:
      case FIELD_TYPES.NUMBER:
      case FIELD_TYPES.TEXT:
      case FIELD_TYPES.DATE:
      case FIELD_TYPES.CHOICE:
      case FIELD_TYPES.PERCENT:
      case FIELD_TYPES.TOGGLE:
      case FIELD_TYPES.DURATION:
      case FIELD_TYPES.FLOAT:
        isFormFilled = isFormFilled || !!value;
        break;
      case FIELD_TYPES.CHOICE_BOOLEAN:
        isFormFilled = isFormFilled || !!value?.value;
        break;

      case FIELD_TYPES.DURATION_UNIT:
        break;

      default:
        break;
    }
  });

  return !isFormFilled;
};
