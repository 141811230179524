import React from 'react';
import styled from 'styled-components/macro';
import { ModalHeader, ModalBody, ModalTitle } from 'ui/Modal/styles';
import { Text } from 'ui/Text';

const CustomText = styled(Text)`
  font-size: 18px;
`;

const AnswerModal = ({ question, answer }) => (
  <div>
    <ModalHeader>
      <ModalTitle>Answer</ModalTitle>
    </ModalHeader>
    <ModalBody>
      <CustomText>
        <b>Question:</b> {question}
      </CustomText>
      <CustomText>
        <b>Answer:</b> {answer}
      </CustomText>
    </ModalBody>
  </div>
);

export { AnswerModal };
