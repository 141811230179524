export const rmLastCh = (str) => (str.length > 1 ? str.slice(0, str.length - 1) : '');

export const formatDigits = (str) => {
  if (str.indexOf('.') === -1) {
    return str;
  }

  const [integer, fractional] = str.split('.');

  return `${integer}.${fractional.substring(0, 2)}`;
};

export const limitDigits = (str, max = 100) => {
  if (str.indexOf('.') === -1) {
    return str > max ? rmLastCh(str) : str;
  }

  const [integer, fractional] = str.split('.');

  if (integer === max.toString()) {
    return integer;
  }
  return `${integer > max ? rmLastCh(integer) : integer}.${fractional.substring(0, 2)}`;
};
