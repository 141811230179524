import styled, { css } from 'styled-components/macro';

import { ReactComponent as Icon } from 'icons/ico_negotiation_arrows.svg';

export const NegotiationIcon = styled(Icon)`
  ${(p) =>
    p.hoverColor &&
    css`
      &:hover {
        path {
          fill: ${p.hoverColor};
          stroke: ${p.hoverColor};
        }
      }
    `}
`;
