import React from 'react';
import { connect } from 'react-redux';
import { fieldsConfig } from 'config/fieldsConfig';
import { FIELD_TYPES, PERCENT, CURRENCY } from 'constants/appRelated';
import { makeLoanTerm } from 'lib/loan';
import { FormattedNumber } from 'react-intl';
import { choiceBooleanOptions } from 'utils/fields';

const DisplayLoanValue = ({
  field,
  value,
  durationUnit,
  normalizedOptions,
  namespace,
}) => {
  const config = namespace ? fieldsConfig[namespace][field] : fieldsConfig[field];

  if (value === null || value === undefined || config === undefined) {
    return 'N/A';
  }

  switch (config.type) {
    case FIELD_TYPES.CHOICE:
      return normalizedOptions[`${field}Options`]?.[value]?.label || 'N/A';
    case FIELD_TYPES.DURATION:
      return !durationUnit ? makeLoanTerm(value) : `${value} years`;
    case FIELD_TYPES.DOLLARS:
      return (
        <FormattedNumber
          style={CURRENCY}
          currency="USD"
          maximumFractionDigits={5}
          minimumFractionDigits={0}
          value={value}
        />
      );
    case FIELD_TYPES.PERCENT:
      return (
        <FormattedNumber
          style={PERCENT}
          maximumFractionDigits={5}
          minimumFractionDigits={0}
          value={value / 100}
        />
      );
    case FIELD_TYPES.DATE:
      const dateParts = value.split('-');
      return `${dateParts[1]}/${dateParts[2]}/${dateParts[0]}`;

    case FIELD_TYPES.NUMBER:
      return <FormattedNumber value={value} />;

    case FIELD_TYPES.TOGGLE:
      return value ? 'Yes' : 'No';

    case FIELD_TYPES.TEXT:
      return value;

    case FIELD_TYPES.CHOICE_BOOLEAN:
      return choiceBooleanOptions.find((item) => item.value === value)?.label;
    default:
      return null;
  }
};

function mapStateToProps(state) {
  const { configuration } = state;

  return {
    normalizedOptions: configuration.normalizedOptions,
  };
}

export default connect(mapStateToProps)(DisplayLoanValue);
