import { createSelector } from 'reselect';
import { userTypeSelector } from 'selectors/userType';
import { getCurrentUser } from 'selectors';

export const listingSelector = (state) => state.listing.listingData;

export const listingQuotesSelector = createSelector(
  [listingSelector, userTypeSelector, getCurrentUser],
  (listing, userType, currentUser) => {
    if (!listing.preQualifications) {
      return [];
    }

    if (userType.lender) {
      return listing.preQualifications.filter(
        ({ author }) => author.id === currentUser.id
      );
    }

    return listing.preQualifications;
  }
);
