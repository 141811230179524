import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components/macro';
import { Button } from 'ui/Button';
import { withRouter } from 'react-router';
import { conciergeActionRequest } from 'actions';
import { showModal } from 'actions/modalActions';
import { push } from 'connected-react-router';
import { LENDER, LOAN_STATUS } from 'constants/appRelated';
import {
  submitForVerificationRequest,
  submitTermsheetRequest,
} from 'features/LoanNew/actions';
import queryString from 'query-string';
import { modals, INFO_MODAL_TYPE } from 'constants/modalTypes';
import {
  loanAccessSelector,
  signedTermsheetsSelector,
  uploadedTermsheetsSelector,
} from 'features/LoanNew/selectors';

import { DraftPanel, LenderPanel, BorowerPanel } from './';
import { LoanActionsPanel } from './LoanActionsPanel';
import { StandAloneActions } from './StandAloneActions';
import { showQuotesWidget } from 'containers/QuotesWidget/actions';

const ActionsWrapper = styled.div`
  flex-shrink: 0;
  ${Button} {
    margin-left: 16px;
  }

  @media (max-width: ${(p) => p.theme.breakpoints[0]}px) {
    ${Button} {
      margin: 0 8px 8px 0;
    }
  }
`;

class LoanActions extends Component {
  submitForVerification = () => {
    const { dispatch, loan, id } = this.props;
    dispatch(submitForVerificationRequest({ id: id || loan.uuid }));

    if (loan.status === LOAN_STATUS.DRAFT) {
      dispatch(push(`/dashboard/loan-creation/${id || loan.uuid}/send-to-lenders`));
    }
  };

  openQuotesWidget = (item, author, id, accountType) => {
    const { dispatch, loan } = this.props;
    dispatch(
      showQuotesWidget({
        roomId: loan.id,
        item,
        author,
        id,
        accountType,
      })
    );
  };

  setNotIntrested = () => {
    const {
      dispatch,
      loan: { uuid },
    } = this.props;
    dispatch(showModal(modals.HIDE_LOAN_FORM, { pk: uuid }));
  };

  uploadTermsheet = (files) => {
    const {
      dispatch,
      loan: { uuid, negotiations },
      currentUser,
    } = this.props;

    const negotiationPk = negotiations[currentUser.id]?.negotiations.filter(
      (negotiation) => negotiation.state === 'accepted'
    )[0].pk;

    const formData = new FormData();
    formData.append('termsheet', files[0], files[0].name || 'file');

    dispatch(
      submitTermsheetRequest({
        form: formData,
        uuid,
        negotiationPk,
      })
    );
  };

  openCompareOffersModal = () => {
    const { dispatch, loan } = this.props;
    dispatch(push(`/dashboard/compare-offers/${loan.uuid}`));
  };

  openFinalOfferModal = (negotiation) => {
    const { loan, dispatch } = this.props;
    dispatch(
      showModal(modals.FINAL_OFFER, {
        loan,
        negotiation,
        size: 'sm',
      })
    );
  };

  sendConciergeApprove = () => {
    const { dispatch, loan } = this.props;
    dispatch(conciergeActionRequest({ loanId: loan.uuid }));
  };

  openConciergeModal = () => {
    const { dispatch, loan } = this.props;
    dispatch(showModal(modals.CONCIERGE_REJECT, { loanId: loan.uuid }));
  };

  showFailedReason = (text) => {
    const { dispatch } = this.props;
    dispatch(
      showModal(modals.INFO_MODAL, {
        size: 'sm',
        infoType: INFO_MODAL_TYPE.WARNING,
        title: 'Verification failed reason',
        text,
        buttonText: 'OK',
      })
    );
  };

  openPasswordModal = ({ label, toMarketplace }) => {
    const {
      dispatch,
      loan,
      search,
    } = this.props;
    const uriObj = queryString.parse(search);
    const { uuid } = uriObj;

    dispatch(
      push(
        `/set-lender-password?label=${label}&uuid=${uuid}&loanId=${loan.uuid}&withRedirectToMarketplace=true`
      )
    );
  };

  render() {
    const {
      loan,
      loan: {uuid, canSubmitForVerification, verificationFailedReason, status },
      isOwner,
      id,
      isLender,
      currentUser: { id: userId, accountType },
      access,
      uploadedTermsheets,
      standAloneLoan,
      lenderRegistered,
      standaloneUuid,
    } = this.props;

    if (standAloneLoan) {
      return (
        <ActionsWrapper>
          <StandAloneActions
            loanId={uuid}
            lenderStatus={lenderRegistered}
            uuid={standaloneUuid}
          />
        </ActionsWrapper>
      );
    }

    return (
      <ActionsWrapper>
        <LoanActionsPanel
          accessPreview={access.preview}
          status={status}
          isOwner={isOwner}
          isLender={isLender}
          draftPanel={
            <DraftPanel
              canSubmitForVerification={canSubmitForVerification}
              submitForVerification={this.submitForVerification}
              status={status}
              id={id}
              verificationFailedReason={verificationFailedReason}
              showFailedReason={this.showFailedReason}
            />
          }
          lenderPanel={
            <LenderPanel
              loan={loan}
              userId={userId}
              accountType={accountType}
              openFinalOfferModal={this.openFinalOfferModal}
              onTermDrop={this.uploadTermsheet}
              openOfferModal={this.openQuotesWidget}
              setNotIntrested={this.setNotIntrested}
              unlockLoan={this.unlockLoan}
              loanBlocked={!access.full}
            />
          }
          borowerPanel={
            <BorowerPanel
              loan={loan}
              accountType={accountType}
              uploadedTermsheets={uploadedTermsheets}
              openOfferModal={this.openQuotesWidget}
              openCompareOffersModal={this.openCompareOffersModal}
            />
          }
        />
      </ActionsWrapper>
    );
  }
}

const regularLoanSelector = (state) => {
  const {
    loanNew: { data },
    currentUser,
    router,
  } = state;
  return {
    loan: data,
    id: data.id,
    access: loanAccessSelector(state),
    currentUser,
    accountType: currentUser.accountType,
    isOwner: data.isMy,
    isLender: currentUser.accountType === LENDER,
    signedTermsheets: signedTermsheetsSelector(state),
    uploadedTermsheets: uploadedTermsheetsSelector(state),
    search: router.location.search,
  };
};

const standAloneLoanSelector = (state) => {
  const {
    loanNew: { data },
    router,
  } = state;

  const uriObj = queryString.parse(router.location.search);
  const { uuid } = uriObj;
  return {
    loan: data,
    id: data.id,
    access: { full: true },
    currentUser: {},
    accountType: LENDER,
    isOwner: false,
    isLender: true,
    standAloneLoan: true,
    lenderRegistered: data.lenderRegistered,
    search: router.location.search,
    standaloneUuid: uuid,
  };
};

function mapStateToProps(state, props) {
  const {
    match: { url },
  } = props;
  const pathName = url.split('/').filter(Boolean)[0];

  return pathName === 'property-details'
    ? standAloneLoanSelector(state)
    : regularLoanSelector(state);
}

export default withRouter(connect(mapStateToProps)(LoanActions));
