import React from 'react';
import styled from 'styled-components/macro';
import {
  ModalBody,
  ModalHeading,
  ModalActions,
  ModalWithContentWrapper,
} from 'ui/Modal/styles';
import { Text } from 'ui/Text/';
import { Button } from 'ui/Button';
import { UserView } from 'components/UserView';
import { LENDER } from 'constants/appRelated';

export const DealRoomDocumentRejectionReasonModal = ({ hideModal, reason, lender }) => (
  <ModalWithContentWrapper>
    <ModalHeading>Rejection reason</ModalHeading>
    <StyledModalBody>
      <UserViewWrapper>
        <UserView
          lenderTypeColor={lender.lenderTypeColor}
          name={`${lender.firstName} ${lender.lastName}`}
          accountType={LENDER}
          companyName={lender.companyName}
          initials={lender.initials}
          lenderType={1}
        />
      </UserViewWrapper>
      <Text>{reason}</Text>
    </StyledModalBody>
    <StyledModalActions>
      <Button secondary onClick={hideModal}>
        OK
      </Button>
    </StyledModalActions>
  </ModalWithContentWrapper>
);

const StyledModalBody = styled(ModalBody)`
  //min-height: 170px;
`;

const StyledModalActions = styled(ModalActions)`
  margin: 0 24px;
  border-top: 1px solid #e8e9ed;
`;

const UserViewWrapper = styled.div`
  margin-bottom: 16px;
`;
