import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import { ModalBody, ModalHeading, ModalWrapper } from 'ui/Modal/styles';
import { Text } from 'ui/Text';
import { DropDown } from 'ui/DropDown';
import { ButtonGhost, Button } from 'ui/Button';
import { Textarea } from 'ui/Textarea';
import { reminderOptions } from 'features/LoanNew/config';

export const LenderLoanReminder = ({
  handlePass,
  hideModal,
  handleReminderSet,
  owner,
}) => {
  const [isPassFormView, setPassFormView] = useState(false);
  const [passReason, handleTextareaChange] = useState(null);
  const [browserIsIE, setBrowserIsIE] = useState(false);

  useEffect(() => {
    const isIE = /*@cc_on!@*/false || !!document.documentMode;
    setBrowserIsIE(isIE);
  }, [])

  const handleDropDownSelect = ({ value }) => {
    handleReminderSet(value);
    hideModal();
  };

  const handleDropDownSelectIE = (e) => {
    handleReminderSet(Number(e.target.value));
    hideModal();
  }

  const handlePassSubmit = () => {
    if (passReason) {
      handlePass(passReason);
      hideModal();
    }
  };

  const reminderOptionsRender = reminderOptions?.map(op => {
    return (
      <option value={op.value}>{op.label}</option>
    )
  })

  return (
    <ModalWrapper>
      <ModalHeading>Not interested now?</ModalHeading>
      <ModalBody>
        <Description>
          Help {owner.firstName} {owner.lastName} understand your level of interest for
          this deal
        </Description>
        {isPassFormView ? (
          <div>
            <Textarea
              placeholder="Why are you passing on this loan?"
              rows={5}
              onChange={(e) => {
                handleTextareaChange(e.target.value);
              }}
            />
            <Button disabled={!passReason} onClick={handlePassSubmit}>
              Pass
            </Button>
          </div>
        ) : (
          <Controls>
            <DropDownWrapper>
              {browserIsIE ? (
                <select
                  onChange={(e) => handleDropDownSelectIE(e)}
                >
                  <option value=''>Remind me about loan</option>
                  {reminderOptionsRender}
                </select>
              ) : (
                <DropDown
                  placeholder="Remind me about loan"
                  onChange={handleDropDownSelect}
                  options={reminderOptions}
                  portalMenuConfig={{
                    offsetX: 200,
                    offsetY: 30,
                    menuWidth: 190,
                  }}
                />
              )}
            </DropDownWrapper>
            <Text>or</Text>
            <ButtonGhost
              onClick={() => {
                setPassFormView(true);
              }}
            >
              Pass
            </ButtonGhost>
          </Controls>
        )}
      </ModalBody>
    </ModalWrapper>
  );
};

const Description = styled(Text)`
  font-size: 14px;
  color: #778598;
  text-align: center;
  margin-bottom: 24px;
`;

const Controls = styled.div`
  display: flex;
  align-items: center;

  ${ButtonGhost} {
    margin-left: 18px;
  }
`;

const DropDownWrapper = styled.div`
  flex: 1 0;
  margin-right: 18px;
  
  select {
    width: 218px;
    height: 34px;
    border-radius: 5px;
    border-color: lightgrey;
  }
`;
