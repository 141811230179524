export const asyncActions = [
  'GET_LENDER_COMPANY_DATA',
  'UPDATE_LENDER_COMPANY_DATA',
  'CREATE_LENDER_COMPANY_TEAM',
  'UPDATE_LENDER_COMPANY_TEAM',
  'ADD_LENDER_COMPANY_MEMBER',
  'UPDATE_LENDER_COMPANY_MEMBER',
  'REMOVE_LENDER_COMPANY_MEMBER',
  'RESEND_LENDER_COMPANY_MEMBER_INVITATION',
  'UPDATE_LENDER_COMPANY_SETTINGS',
  'CHECK_LENDER_COMPANY_MEMBER_EMAIL',
  'ASSIGN_LOAN_TO_LENDER',
  'SEARCH_LENDER_COMPANIES',
  'GET_LENDER_TEAM_MEMBERS',
  'GET_INVITED_LENDERS'
];
