import React from 'react';
import styled from 'styled-components/macro';
import { Form, Field } from 'react-final-form';
import { Textarea, NewCheckbox } from 'components/Form';
import { Button } from 'ui/Button';
import { postAnswerRequest } from 'features/LoanNew/actions';
import { postQuestionRequest } from 'actions';

const Wrapper = styled.div`
  position: relative;
  padding: 40px 24px;
  background-color: #fff;
  border-radius: 8px;
`;

const Title = styled.p`
  font-size: 24px;
  line-height: 28px;
  font-weight: bold;
  margin: 0 0 24px;
  text-align: center;
`;

const Notification = styled.p`
  font-size: 14px;
  line-height: 21px;
  color: #2f243a;
  text-align: center;
  margin: 0 0 24px;
`;

const TextareaField = styled.div`
  margin-bottom: 16px;
`;

const Controls = styled.div`
  display: flex;
  justify-content: center;

  ${Button} {
    width: 100%;
    max-width: 350px;
  }
`;

const PrivateNotification = styled.p`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin-bottom: 16px;

  &::before {
    content: '';
    width: 18px;
    height: 18px;
    background-size: cover;
    background-image: url('/images/icons/ico_info_warning.svg');
    margin-right: 8px;
  }
`;

// TODO: split to two different modals
export const QuestionForm = ({
  dispatch,
  isLender,
  loanId,
  questionId,
  questionText,
  hideModal,
  isQuestionPrivate,
}) => {
  return (
    <Wrapper>
      <Title>{isLender ? 'Ask the client a question' : 'Answer Lender’s Question'}</Title>
      {!isLender && isQuestionPrivate && (
        <PrivateNotification>Lender made this question private</PrivateNotification>
      )}
      <Notification>{!isLender && questionText}</Notification>
      <Form
        onSubmit={({ isPrivate, answer, question }) => {
          hideModal();
          if (!isLender) {
            dispatch(
              postAnswerRequest({
                answer,
                loanId,
                id: questionId,
                isPublic: !isPrivate,
              })
            );
          } else {
            dispatch(
              postQuestionRequest({
                question,
                loanId,
                isPublic: true,
              })
            );
          }
        }}
        initialValues={{ isPrivate: true }}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <TextareaField>
              <Field
                component={Textarea}
                name={isLender ? 'question' : 'answer'}
                placeholder={
                  isLender ? 'Type your question here' : 'Type your answer here'
                }
                validate={(value) =>
                  value ? undefined : `${isLender ? 'Question' : 'Answer'} is required`
                }
                rows={13}
              />
            </TextareaField>
            {!isLender && (
              <Field
                component={NewCheckbox}
                label="Make private"
                name="isPrivate"
                type="checkbox"
                disabled={isQuestionPrivate}
              />
            )}
            <Controls>
              <Button type="submit" secondary>
                {isLender ? 'Send a question' : 'Send Answer'}
              </Button>
            </Controls>
          </form>
        )}
      />
    </Wrapper>
  );
};
