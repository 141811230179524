import React from 'react';
import {
  ModalBody,
  ModalHeading,
  ModalActions,
  modalWithContentWrapperStyles,
} from 'ui/Modal/styles';
import { Button } from 'ui/Button';

import { Form, Field } from 'react-final-form';
import { Input } from 'components/Form';
import { validateEmail } from 'utils/validate';
import { normalizeIntegerLimit, normalizeDollars } from 'lib/normalizers';
import { closeLoanRequest } from 'features/MyLoans/actions';
import { delay, simpleMemoize } from 'lib/utils';
import { LENDER } from 'constants/appRelated';
import {
  AddLenderForm,
  LenderSourceTypeSwitch,
  LenderSelect,
} from 'components/Modals/ManualOfferModal/components';
import { LENDER_SOURCE } from 'components/Modals/ManualOfferModal/constants';
import { Condition, NewDatepicker, DropdownList } from 'components/Form';
import styled from 'styled-components/macro';
import { wordsRemove } from 'lib/strings';
import { normalizeNumbers } from 'lib/normalizers';
import { InputSelect } from 'ui/InputSelect';
import { unitOptions } from 'utils/fields';
import { Section } from 'components/Modals/ManualOfferModal/styles';
import { checkLenderDirectoryEmailRequest } from 'features/Directory/pages/LenderDirectory/actions';
import { recourseOptions } from 'utils/fields';
import { connect } from 'react-redux';

const formValidation = (values, userType) => {
  const { lenderSourceType, selectLender } = values;
  const errors = {};

  if(!userType?.lender){
    if (lenderSourceType === LENDER_SOURCE.NEW) {
      errors.customLenderFirstName = values.customLenderFirstName
        ? undefined
        : 'First name is required';
      errors.customLenderLastName = values.customLenderLastName
        ? undefined
        : 'Last name is required';
      errors.customLenderCompany = values.customLenderCompany
        ? undefined
        : 'Company name is required';
    } else {
      errors.selectLender = selectLender?.lender
        ? undefined
        : 'Please choose investment source';
    }
  }

  errors.finalRate = values.finalRate ? undefined : 'Final rate is required';
  errors.loanTerm = values.loanTerm ? undefined : 'Loan term is required';
  errors.loanAmount = values.loanAmount ? undefined : 'Loan amount is required';
  errors.timestamp = values.timestamp ? undefined : 'Deal date is required';

  return errors;
};

class LoanDealWonForm extends React.Component {
  shouldComponentUpdate(nextProps) {
    return nextProps.loanId !== this.props.loanId;
  }

  closeLoan = (values) => {
    const {userType, dispatch, loanId, hideModal, invitedLenderId, loan } = this.props;
    const isLenderNew = values.lenderSourceType === LENDER_SOURCE.NEW && !invitedLenderId;

    const request = {
      loanAmount: wordsRemove(values.loanAmount),
      loanTerm: values.loanTerm,
      loanTermUnit: values.loanTermUnit?.value || 0,
      finalRate: values.finalRate,
      ltv: values.ltv,
      recourse: values?.recourse?.value ?? null,
      timestamp: values.timestamp,
    };

    if (isLenderNew) {
      request.lenderFirstName = values.customLenderFirstName;
      request.lenderLastName = values.customLenderLastName;
      request.lenderCompany = values.customLenderCompany;
      request.lenderEmail = values.customLenderEmail;
      dispatch(
        closeLoanRequest({
          loanId,
          closeType: userType?.lender ? 'funded' : 'won',
          data: request,
          loan
        })
      );
      hideModal();
    } else {
      request.lenderId =
        values.lenderSourceType === LENDER_SOURCE.INVESTMENT_SOURCES
          ? values?.selectLender?.lender?.lender
          : invitedLenderId;
      dispatch(closeLoanRequest({ loanId, closeType: userType?.lender ? 'funded' : 'won', data: request, loan }));
      hideModal();
    }
  };

  asyncValidate = simpleMemoize(async (email) => {
    const { dispatch } = this.props;

    if (!email) {
      return 'Email is required';
    }

    if (!validateEmail(email)) {
      return 'Invalid email address';
    }

    if (email) {
      const res = await delay(1000).then(() =>
        new Promise((resolve, reject) => {
          dispatch(
            checkLenderDirectoryEmailRequest({
              email,
              resolve,
              reject,
              accountType: LENDER,
              checkLenderId: true,
            })
          );
        }).then(
          (v) => v,
          ({ error, data }) => {
            if (data?.result !== 'exists') {
              return error;
            }

            return undefined;
          }
        )
      );
      return res;
    }
  });

  render() {
    const { invitedLenders, userType } = this.props;    
    return (
      <Form
        onSubmit={this.closeLoan}
        validate={(values) => formValidation(values, userType)}
        initialValues={{
          lenderSourceType: LENDER_SOURCE.NEW,
          loanTermUnit: unitOptions[0],
          timestamp: new Date(),
        }}
        render={({ handleSubmit }) => (
          <FormWrapper onSubmit={handleSubmit}>
            <ModalHeading>{userType?.lender ? 'Add closing info':'Add a winning bid info'}</ModalHeading>
            <ModalBody>
              {userType?.lender ? <></> : 
                <>
                  <LenderSourceTypeSwitch />
                  <Condition when="lenderSourceType" is={LENDER_SOURCE.NEW}>
                    <AddLenderForm validateEmail={!userType?.lender && this.asyncValidate} />
                  </Condition>
                  <Condition when="lenderSourceType" is={LENDER_SOURCE.INVESTMENT_SOURCES}>
                    <Section>
                      {!!invitedLenders.length && <LenderSelect lendersList={invitedLenders} />}
                    </Section>
                  </Condition>
                </>
              }
              
              <Field
                component={NewDatepicker}
                name="timestamp"
                label="Close date"
                required
                dropDown
              />
              <Field
                component={Input}
                label="Loan amount"
                name="loanAmount"
                parse={normalizeDollars}
                required
              />
              <InputSelect
                options={unitOptions}
                parse={normalizeNumbers}
                inputName="loanTerm"
                selectName="loanTermUnit"
                label="Loan term"
                required
                mb={4}
              />
              <Field
                component={Input}
                label="Final rate"
                name="finalRate"
                parse={normalizeNumbers}
                required
              />
              <Field component={Input} label="Amortization" name="amortization" />
              <Field
                component={Input}
                label="LTV"
                name="ltv"
                parse={normalizeIntegerLimit}
              />
              <Field
                component={DropdownList}
                options={recourseOptions}
                label="Recourse"
                name="recourse"
                dropup
                mb={0}
              />
            </ModalBody>
            <ModalActions>
              <Button secondary>Close loan</Button>
            </ModalActions>
          </FormWrapper>
        )}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
  };
}

export default connect(mapStateToProps)(LoanDealWonForm);

const FormWrapper = styled.form`
  ${modalWithContentWrapperStyles}
`;
