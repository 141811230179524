import React from 'react';
import { Field } from 'react-final-form';
import { DropDown } from 'ui/DropDown';
import styled from 'styled-components/macro';
import { Input, DropdownList } from 'components/Form';

export const ManualFieldSet = ({
  fields,
  stateOptions,
}) => (
  <div>
    {fields?.addresses && (
      <Field
        component={Input}
        name={fields.addresses}
        placeholder="Address"
        maxLength={255}
      />
    )}
    {fields?.city && (
      <Field component={Input} name={fields.city} placeholder="City" maxLength={50} />
    )}
    {fields?.state && (
      <Field
        component={DropdownList}
        options={stateOptions}
        name={fields.state}
        placeholder="State"
        mb={4}
      />
    )}
    {fields?.zip && (
      <Field component={Input} name={fields.zip} placeholder="Zip" mb={0} maxLength={7} />
    )}
  </div>
);

const StatesWrapper = styled.div`
  margin-bottom: 24px;
`;
