import types from 'constants/actionTypes';

export const logoutRequest = (payload = {}) => ({ type: types.LOGOUT_REQUEST, payload });
export const logoutSuccess = (payload = {}) => ({ type: types.LOGOUT_SUCCESS, payload });
export const logoutFailure = (payload = {}) => ({ type: types.LOGOUT_FAILURE, payload });

export const settingDataSuccess = (payload = {}) => ({
  type: types.SETTING_DATA_SUCCESS,
  payload,
});

export const settingDataFailure = (payload = {}) => ({
  type: types.SETTING_DATA_FAILURE,
  payload,
});

export const settingDataRequest = () => ({
  type: types.SETTING_DATA_REQUEST,
});

export const emailExistsRequest = (payload = {}) => ({
  type: types.EMAIL_EXISTS_REQUEST,
  payload,
});
export const emailExistsSuccess = (payload = {}) => ({
  type: types.EMAIL_EXISTS_SUCCESS,
  payload,
});
export const emailExistsFailure = (payload = {}) => ({
  type: types.EMAIL_EXISTS_FAILURE,
  payload,
});

export const postQuestionRequest = (payload = {}) => ({
  type: types.POST_QUESTION_REQUEST,
  payload,
});
export const postQuestionSuccess = (payload = {}) => ({
  type: types.POST_QUESTION_SUCCESS,
  payload,
});
export const postQuestionFailure = (payload = {}) => ({
  type: types.POST_QUESTION_FAILURE,
  payload,
});

export const postInboxAnswerRequest = (payload = {}) => ({
  type: types.POST_INBOX_ANSWER_REQUEST,
  payload,
});
export const postInboxAnswerSuccess = (payload = {}) => ({
  type: types.POST_INBOX_ANSWER_SUCCESS,
  payload,
});
export const postInboxAnswerFailure = (payload = {}) => ({
  type: types.POST_INBOX_ANSWER_FAILURE,
  payload,
});

export const socketRequest = (payload = {}) => ({
  type: types.SOCKET_REQUEST,
  payload,
});
export const socketSuccess = (payload = {}) => ({
  type: types.SOCKET_SUCCESS,
  payload,
});
export const socketFailure = (payload = {}) => ({
  type: types.SOCKET_FAILURE,
  payload,
});

export const conciergeActionRequest = (payload = {}) => ({
  type: types.CONCIERGE_ACTION_REQUEST,
  payload,
});
export const conciergeActionSuccess = (payload = {}) => ({
  type: types.CONCIERGE_ACTION_SUCCESS,
  payload,
});
export const conciergeActionFailure = (payload = {}) => ({
  type: types.CONCIERGE_ACTION_FAILURE,
  payload,
});

export const setPasswordRequest = (payload = {}) => ({
  type: types.SET_PASSWORD_REQUEST,
  payload,
});
export const setPasswordSuccess = (payload = {}) => ({
  type: types.SET_PASSWORD_SUCCESS,
  payload,
});
export const setPasswordFailure = (payload = {}) => ({
  type: types.SET_PASSWORD_FAILURE,
  payload,
});

export const confirmEmailRequest = (payload = {}) => ({
  type: types.CONFIRM_EMAIL_REQUEST,
  payload,
});
export const confirmEmailSuccess = (payload = {}) => ({
  type: types.CONFIRM_EMAIL_SUCCESS,
  payload,
});
export const confirmEmailFailure = (payload = {}) => ({
  type: types.CONFIRM_EMAIL_FAILURE,
  payload,
});

export const notificationsRequest = (payload = {}) => ({
  type: types.NOTIFICATIONS_REQUEST,
  payload,
});

export const changeUserProfileRequest = (payload = {}) => ({
  type: types.CHANGE_USER_PROFILE_REQUEST,
  payload,
});
export const changeUserProfileSuccess = (payload = {}) => ({
  type: types.CHANGE_USER_PROFILE_SUCCESS,
  payload,
});
export const changeUserProfileFailure = (payload = {}) => ({
  type: types.CHANGE_USER_PROFILE_FAILURE,
  payload,
});

export const getConfigurationRequest = (payload = {}) => ({
  type: types.GET_CONFIGURATION_REQUEST,
  payload,
});
export const getConfigurationSuccess = (payload = {}) => ({
  type: types.GET_CONFIGURATION_SUCCESS,
  payload,
});
export const getConfigurationFailure = (payload = {}) => ({
  type: types.GET_CONFIGURATION_FAILURE,
  payload,
});

export const finishRegistrationRequest = (payload = {}) => ({
  type: types.FINISH_REGISTRATION_REQUEST,
  payload,
});
export const finishRegistrationSuccess = (payload = {}) => ({
  type: types.FINISH_REGISTRATION_SUCCESS,
  payload,
});
export const finishRegistrationFailure = (payload = {}) => ({
  type: types.FINISH_REGISTRATION_FAILURE,
  payload,
});

export const checkResetPasswordRequest = (payload = {}) => ({
  type: types.CHECK_RESET_PASSWORD_REQUEST,
  payload,
});
export const checkResetPasswordSuccess = (payload = {}) => ({
  type: types.CHECK_RESET_PASSWORD_SUCCESS,
  payload,
});
export const checkResetPasswordFailure = (payload = {}) => ({
  type: types.CHECK_RESET_PASSWORD_FAILURE,
  payload,
});

export const enterpriseLenderRegistrationRequest = (payload = {}) => ({
  type: types.ENTERPRISE_LENDER_REGISTRATION_REQUEST,
  payload,
});
export const enterpriseLenderRegistrationSuccess = (payload = {}) => ({
  type: types.ENTERPRISE_LENDER_REGISTRATION_SUCCESS,
  payload,
});
export const enterpriseLenderRegistrationFailure = (payload = {}) => ({
  type: types.ENTERPRISE_LENDER_REGISTRATION_FAILURE,
  payload,
});

export const loanActionsRequest = (payload = {}) => ({
  type: types.LOAN_ACTIONS_REQUEST,
  payload,
});
export const loanActionsSuccess = (payload = {}) => ({
  type: types.LOAN_ACTIONS_SUCCESS,
  payload,
});
export const loanActionsFailure = (payload = {}) => ({
  type: types.LOAN_ACTIONS_FAILURE,
  payload,
});

export const uploadActionDocumentRequest = (payload = {}) => ({
  type: types.UPLOAD_ACTION_DOCUMENT_REQUEST,
  payload,
});
export const uploadActionDocumentSuccess = (payload = {}) => ({
  type: types.UPLOAD_ACTION_DOCUMENT_SUCCESS,
  payload,
});
export const uploadActionDocumentFailure = (payload = {}) => ({
  type: types.UPLOAD_ACTION_DOCUMENT_FAILURE,
  payload,
});

export const deleteActionDocumentRequest = (payload = {}) => ({
  type: types.DELETE_ACTION_DOCUMENT_REQUEST,
  payload,
});
export const deleteActionDocumentSuccess = (payload = {}) => ({
  type: types.DELETE_ACTION_DOCUMENT_SUCCESS,
  payload,
});
export const deleteActionDocumentFailure = (payload = {}) => ({
  type: types.DELETE_ACTION_DOCUMENT_FAILURE,
  payload,
});

export const getProfileRequest = (payload = {}) => ({
  type: types.GET_PROFILE_REQUEST,
  payload,
});
export const getProfileSuccess = (payload = {}) => ({
  type: types.GET_PROFILE_SUCCESS,
  payload,
});
export const getProfileFailure = (payload = {}) => ({
  type: types.GET_PROFILE_FAILURE,
  payload,
});

export const deleteCredentialsRequest = (payload = {}) => ({
  type: types.DELETE_CREDENTIALS_REQUEST,
  payload,
});
export const deleteCredentialsSuccess = (payload = {}) => ({
  type: types.DELETE_CREDENTIALS_SUCCESS,
  payload,
});
export const deleteCredentialsFailure = (payload = {}) => ({
  type: types.DELETE_CREDENTIALS_FAILURE,
  payload,
});

export const requestPermissionRequest = (payload = {}) => ({
  type: types.REQUEST_PERMISSION_REQUEST,
  payload,
});

export const requestPermissionSuccess = (payload = {}) => ({
  type: types.REQUEST_PERMISSION_SUCCESS,
  payload,
});

export const requestPermissionFailure = (payload = {}) => ({
  type: types.REQUEST_PERMISSION_FAILURE,
  payload,
});

export const shareOfferRequest = (payload = {}) => ({
  type: types.SHARE_OFFER_REQUEST,
  payload,
});
export const shareOfferSuccess = (payload = {}) => ({
  type: types.SHARE_OFFER_SUCCESS,
  payload,
});
export const shareOfferFailure = (payload = {}) => ({
  type: types.SHARE_OFFER_FAILURE,
  payload,
});

export const shareListingRequest = (payload = {}) => ({
  type: types.SHARE_LISTING_REQUEST,
  payload,
});

export const shareListingSuccess = (payload = {}) => ({
  type: types.SHARE_LISTING_SUCCESS,
  payload,
});
export const shareListingFailure = (payload = {}) => ({
  type: types.SHARE_LISTING_FAILURE,
  payload,
});

export const listingLoginRequest = (payload = {}) => ({
  type: types.LISTING_LOGIN_REQUEST,
  payload,
});
export const listingLoginSuccess = (payload = {}) => ({
  type: types.LISTING_LOGIN_SUCCESS,
  payload,
});
export const listingLoginFailure = (payload = {}) => ({
  type: types.LISTING_LOGIN_FAILURE,
  payload,
});

export const getContactsRequest = (payload = {}) => ({
  type: types.GET_CONTACTS_REQUEST,
  payload,
});
export const getContactsSuccess = (payload = {}) => ({
  type: types.GET_CONTACTS_SUCCESS,
  payload,
});
export const getContactsFailure = (payload = {}) => ({
  type: types.GET_CONTACTS_FAILURE,
  payload,
});

export const getContactsTagsRequest = (payload = {}) => ({
  type: types.GET_CONTACTS_TAGS_REQUEST,
  payload,
});
export const getContactsTagsSuccess = (payload = {}) => ({
  type: types.GET_CONTACTS_TAGS_SUCCESS,
  payload,
});
export const getContactsTagsFailure = (payload = {}) => ({
  type: types.GET_CONTACTS_TAGS_FAILURE,
  payload,
});

export const getContactsTypesRequest = (payload = {}) => ({
  type: types.GET_CONTACTS_TYPES_REQUEST,
  payload,
});
export const getContactsTypesSuccess = (payload = {}) => ({
  type: types.GET_CONTACTS_TYPES_SUCCESS,
  payload,
});
export const getContactsTypesFailure = (payload = {}) => ({
  type: types.GET_CONTACTS_TYPES_FAILURE,
  payload,
});

export const backToTeamViewRequest = (payload = {}) => ({
  type: types.BACK_TO_TEAM_VIEW_REQUEST,
  payload,
});
export const backToTeamViewSuccess = (payload = {}) => ({
  type: types.BACK_TO_TEAM_VIEW_SUCCESS,
  payload,
});
export const backToTeamViewFailure = (payload = {}) => ({
  type: types.BACK_TO_TEAM_VIEW_FAILURE,
  payload,
});
export const forcePasswordChangeRequest = (payload = {}) => ({
  type: types.FORCE_PASSWORD_CHANGE_REQUEST,
  payload,
});
export const forcePasswordChangeSuccess = (payload = {}) => ({
  type: types.FORCE_PASSWORD_CHANGE_SUCCESS,
  payload,
});
export const forcePasswordChangeFailure = (payload = {}) => ({
  type: types.FORCE_PASSWORD_CHANGE_FAILURE,
  payload,
});
export const setCookieRequest = (payload = {}) => ({
  type: types.SET_COOKIE_REQUEST,
  payload,
});
export const setCookieSuccess = (payload = {}) => ({
  type: types.SET_COOKIE_SUCCESS,
  payload,
});
export const setCookieFailure = (payload = {}) => ({
  type: types.SET_COOKIE_FAILURE,
  payload,
});

export const checkPasswordNeedRequest = (payload = {}) => ({
  type: types.CHECK_PASSWORD_NEED_REQUEST,
  payload,
});
export const checkPasswordNeedSuccess = (payload = {}) => ({
  type: types.CHECK_PASSWORD_NEED_SUCCESS,
  payload,
});
export const checkPasswordNeedFailure = (payload = {}) => ({
  type: types.CHECK_PASSWORD_NEED_FAILURE,
  payload,
});
export const updatePasswordNeedRequest = (payload = {}) => ({
  type: types.UPDATE_PASSWORD_NEED_REQUEST,
  payload,
});
export const updatePasswordNeedSuccess = (payload = {}) => ({
  type: types.UPDATE_PASSWORD_NEED_SUCCESS,
  payload,
});
export const updatePasswordNeedFailure = (payload = {}) => ({
  type: types.UPDATE_PASSWORD_NEED_FAILURE,
  payload,
});
export const backToLoginRequest = (payload = {}) => ({
  type: types.BACK_TO_LOGIN_REQUEST,
  payload,
});
export const backToLoginSuccess = (payload = {}) => ({
  type: types.BACK_TO_LOGIN_SUCCESS,
  payload,
});
export const backToLoginFailure = (payload = {}) => ({
  type: types.BACK_TO_LOGIN_FAILURE,
  payload,
});
