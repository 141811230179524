import React from 'react';
import { FileUpload } from 'components/FileUpload';
import { Button } from 'ui/Button';
import { allowedDocTypes } from 'config/allowedDocTypes';
import { DocumentRejectionHandler } from 'components/DocumentRejectionHandler';

export const LenderTermsheet = React.memo(({ termsheets, uploadTermsheet }) => {
  const hasTermsheets = termsheets.length > 0;
  const lastTermsheet = termsheets[termsheets.length - 1];
  const isRejected = lastTermsheet && !!lastTermsheet.rejectionReason;
  const signedTermsheet = termsheets.filter((i) => i.signedTermsheet)[0];

  if (!hasTermsheets) {
    return (
      <DocumentRejectionHandler>
        {({ handleOnDropReject }) => (
          <FileUpload
            onDrop={uploadTermsheet}
            accept={allowedDocTypes.loanDocs}
            onDropRejected={handleOnDropReject}
          >
            <Button>Attach term sheet</Button>
          </FileUpload>
        )}
      </DocumentRejectionHandler>
    );
  }

  if (signedTermsheet) {
    return (
      <a href={signedTermsheet.signedTermsheet} target="_blank" rel="noopener noreferrer">
        <Button>Open signed termsheet</Button>
      </a>
    );
  }

  if (isRejected && lastTermsheet.status < 5) {
    return (
      <DocumentRejectionHandler>
        {({ handleOnDropReject }) => (
          <FileUpload
            onDrop={uploadTermsheet}
            accept={allowedDocTypes.loanDocs}
            onDropRejected={handleOnDropReject}
          >
            <Button>Reattach term sheet</Button>
          </FileUpload>
        )}
      </DocumentRejectionHandler>
    );
  }

  return (
    <a href={lastTermsheet.termsheet} target="_blank" rel="noopener noreferrer">
      <Button>Open my term sheet</Button>
    </a>
  );
});

LenderTermsheet.displayName = 'LenderTermsheet';
