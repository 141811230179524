import styled, { css } from 'styled-components/macro';

import { ReactComponent as Icon } from 'icons/ico_tfa_lock.svg';

export const TfaLock = styled(Icon)`
  ${(p) =>
    p.color &&
    css`
      g {
        fill: ${p.color};
      }
      path {
        stroke: ${p.color};
      }
    `}
`;
