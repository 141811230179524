import React, { useState, useRef, useEffect } from 'react';
import styled, { css } from 'styled-components';

import DayPicker, { DateUtils } from 'react-day-picker';
import { CalendarIcon } from 'components/SvgIcons';

export const DateRange = ({ fromTo, onDateChange }) => {
  const refDayPicker = useRef(null);
  const [pickerVisible, changeVisibility] = useState(false);
  const togglePickerVisibility = () => changeVisibility(!pickerVisible);

  const { from, to } = fromTo;

  useEffect(() => {
    const listener = (event) => {
      if (!refDayPicker.current.contains(event.target))
        changeVisibility(false)
    };
    document.addEventListener('click', listener);
    return () => document.removeEventListener('click', listener);
  }, []);
  return (
    <RangePickerWrapper ref={refDayPicker}>
      <span onClick={togglePickerVisibility}>
        <FromToDate>
          {!from && !to && 'Please select the first day.'}
          {from && !to && 'Please select the last day.'}
          {from && to && (
            <>
              <CalendarIcon /> &nbsp;&nbsp;
              {from.toLocaleDateString('en-US')} to {to.toLocaleDateString('en-US')}
            </>
          )}
        </FromToDate>
      </span>
      {pickerVisible && <DayPickerComponent onDateChange={onDateChange} />}
    </RangePickerWrapper>
  );
};

const DayPickerComponent = ({ onDateChange }) => {
  const [fromTo, setFromTo] = useState({ from: null, to: null });

  const handleDayClick = (day) => {
    const range = DateUtils.addDayToRange(day, fromTo);
    setFromTo(range);
    if (range.to && range.from) {
      onDateChange(range);
    }
  };

  const { from, to } = fromTo;
  const modifiers = { start: from, end: to };

  return (
    <DayPicker
      className="Selectable"
      numberOfMonths={2}
      selectedDays={[from, { from, to }]}
      modifiers={modifiers}
      onDayClick={handleDayClick}
    />
  );
};

const RangePickerWrapper = styled.div`
  position: relative;
  z-index:1;
  ${css`
    .Selectable {
      min-width: 350px;
      position: absolute;
      top: 35px;
      left: 0;
      border-radius: 4px;
      background: #fff;
      box-shadow: ${(p) => p.theme.shadow};
    }
    .Selectable
      .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
      background-color: #f0f8ff !important;
      color: #4a90e2;
    }
    .Selectable .DayPicker-Day {
      border-radius: 0 !important;
    }
    .Selectable .DayPicker-Day--start {
      border-top-left-radius: 50% !important;
      border-bottom-left-radius: 50% !important;
    }
    .Selectable .DayPicker-Day--end {
      border-top-right-radius: 50% !important;
      border-bottom-right-radius: 50% !important;
    }
  `}
`;

const FromToDate = styled.div`
  color: #4394ea;
  font-size: 12px;
  cursor: pointer;
  line-height: 16px;
`;
