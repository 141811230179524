import React from 'react';
import styled, { css } from 'styled-components/macro';
import CreatableSelect from 'react-select/creatable';
import PropTypes from 'prop-types';
import { Text } from 'ui/Text';
import { Tooltip } from 'ui/Tooltip';
import { DropdownIndicator, MultiValueRemove } from './components';

const WrapperContainer = styled.div`
  ${(p) =>
    (p.mb || typeof p.mb === 'number') &&
    css`
      margin-bottom: ${p.theme.space[p.mb] ? `${p.theme.space[p.mb]}px` : p.mb};
    `};

  ${(p) =>
    p.disabled &&
    css`
      opacity: 0.5;
    `}
`;

const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;

const WrapperCommon = styled.div`
  font-size: ${(p) => p.theme.fontSizes[0]}px;

  .Select {
    z-index: 5;
  }

  .Select__control {
    position: relative;
    min-height: 32px;
    background-color: #fff;
    border-radius: ${(p) => p.theme.radii[1]}px;
    border: 1px solid #cfd0d7;
    flex-wrap: nowrap;
  }

  .Select__control--is-focused {
    box-shadow: none;
    border-color: ${(p) => p.theme.colors.blues[6]};
  }

  .Select__indicators {
    position: relative;
  }

  .Select__dropdown-indicator {
    padding: 0;
    padding-right: 10px;
    margin-left: auto;
  }

  .Select__indicator-separator {
    display: none;
  }

  .Select__clear-indicator {
    display: none;
  }

  .Select__menu {
    border-radius: 4px;
    border: none;
    box-shadow: 0 2px 14px 0 rgba(194, 198, 210, 0.5);
    z-index: 10;
    margin-bottom: 4px;
    margin-top: 4px;

    &-list {
      padding: 0;
    }

    ${(p) =>
      p.dropup &&
      css`
        bottom: 100%;
        top: unset;
      `};
  }

  .Select__menu-list {
    max-height: 250px;
    z-index: 10;
  }

  .Select__option {
    position: relative;
    color: ${(p) => p.theme.colors.black};
    padding: 15px 20px;

    &:hover {
      background: linear-gradient(45deg, #f0f3ff, #effaff);
    }
  }

  .Select__option.Select__option--is-selected {
    background: linear-gradient(45deg, #f0f3ff, #effaff);
  }

  .Select__multi-value {
    position: relative;
    display: inline-flex;
    align-items: center;
    padding: 0 4px;
    border-radius: 15px;
    border: none;
    font-size: ${(p) => p.theme.fontSizes[0]}px;
    background-color: rgba(67, 148, 234, 0.1);
  }

  .Select__multi-value__label {
    font-size: 14px;
    color: ${(p) => p.theme.colors.black};
  }

  .Select__multi-value__remove {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 8px;
    border: none;
    width: 18px;
    height: 18px;
    background-color: #fff;
    border-radius: 50%;
    color: transparent;
    cursor: pointer;

    &:hover {
      background-color: #fff;
    }
  }

  .Select__clear-indicator {
    left: 0;
    width: 8px;
    height: 8px;
    padding: 0;
    cursor: pointer;

    &:after {
      content: '';
      display: inline-block;
      background-image: url('/images/icons/ico_modal_close.svg');
      background-size: 8px 8px;
      width: 8px;
      height: 8px;
      left: 0;
      font-size: 0;
    }

    svg {
      display: none;
    }

    .Select-clear {
      display: none;
    }
  }
`;

const Wrapper = styled(WrapperCommon)`
  ${(p) => {
    switch (p.variant) {
      case 'error':
        return css`
          .Select__control {
            border-color: ${(p) => p.theme.colors.error};
          }
        `;

      case 'transparent':
        return css`
          .Select__control {
            border-color: transparent;
            &:hover {
              border-color: transparent;
            }
          }
        `;

      default:
        return '';
    }
  }};

  ${(p) => {
    switch (p.align) {
      case 'right':
        return css`
          .Select__value-container {
            justify-content: flex-end;
          }
        `;

      default:
        return '';
    }
  }};
`;

const customStyles = {
  container: (base, state) => ({
    ...base,
    zIndex: state.isFocused ? '99 !important' : '5',
  }),
};

const Asterix = styled.span`
  color: #e32019;
`;

export const CreatableDropDown = ({
  error,
  isMulti,
  options,
  label,
  simpleValue,
  dropup,
  mb,
  components,
  hint,
  onChange,
  required,
  name,
  disabled,
  variant,
  align,
  ...rest
}) => (
  <WrapperContainer mb={mb} disabled={disabled}>
    {(label || hint) && (
      <LabelContainer>
        {label && (
          <Text fontSize="0" mr={2} color={error ? 'error' : ''} fontFamily="muli">
            {label}
            {required && <Asterix> *</Asterix>}
          </Text>
        )}
        {hint && <Tooltip text={hint} />}
      </LabelContainer>
    )}
    <Wrapper
      dropup={dropup}
      variant={(error && 'error') || variant || ''}
      align={align}
      {...rest}
    >
      <CreatableSelect
        isMulti={isMulti}
        options={options}
        simpleValue={simpleValue}
        className="Select"
        classNamePrefix="Select"
        styles={customStyles}
        onChange={(value) => {
          if (!value && isMulti) {
            onChange([]);
          } else {
            onChange(value);
          }
        }}
        components={{ ...components, DropdownIndicator, MultiValueRemove }}
        name={name}
        inputId={name}
        isDisabled={disabled}
        {...rest}
      />
    </Wrapper>
    {error && (
      <Text fontSize="12px" color="error">
        {error}
      </Text>
    )}
  </WrapperContainer>
);

CreatableDropDown.propTypes = {
  error: PropTypes.string,
  isMulti: PropTypes.bool,
  dropup: PropTypes.bool,
  placeholder: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([
        PropTypes.string.isRequired,
        PropTypes.number.isRequired,
      ]),
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
};

CreatableDropDown.defaultProps = {
  error: '',
  isMulti: false,
  dropup: false,
  placeholder: 'Select',
};
