import React, { Component } from 'react';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { connect } from 'react-redux';
import { requestDocumentsRequest } from 'features/LoanNew/actions';
import { getLoanTypes, getPropertyTypes } from 'selectors';
import {
  ModalHeading,
  ModalBody,
  ModalActions,
  modalWithContentWrapperStyles,
} from 'ui/Modal/styles';
import { Button } from 'ui/Button';
import styled from 'styled-components/macro';
import { values, keys } from 'ramda';
import { Input as SearchInput } from 'ui/Input';
import { DOCUMENTS_SECTIONS_IDS } from 'constants/appRelated';

import { filterDocuments } from './lib';
import { Folder, Section, FolderRequest } from './components';

class RequestDocumentsForm extends Component {
  state = { searchString: '' };

  onSearchStringUpdate = (e) => {
    const searchString = e.target.value;

    if (!searchString.length || searchString.trim().length) {
      this.setState({ searchString });
    }
  };

  formSubmit = (values) => {
    const { loanId, hideModal, dispatch, isLender } = this.props;
    const { requested = [], ...folders } = values;

    // finalForm isNan check workaround
    const foldersArray = keys(folders).reduce((acc, curr) => {
      return folders[curr] ? [...acc, { folder: curr.replace('folder', '') }] : acc;
    }, []);

    const requestedFoldersArray = requested.reduce((acc, curr) => {
      return curr.label ? [...acc, { new_folder: curr.label }] : acc;
    }, []);

    const documentsRequest = [...foldersArray, ...requestedFoldersArray];

    if(!isLender) {
      hideModal();
    }

    dispatch(
      requestDocumentsRequest({
        documentsRequest,
        pk: loanId,
        isLender,
      })
    );
  };

  render() {
    const { isLender, documentsAll, openConfidentialityAgreementModal } = this.props;
    const { searchString } = this.state;
    const filteredDocuments = filterDocuments(documentsAll, searchString);

    return (
      <Form
        onSubmit={this.formSubmit}
        mutators={{
          ...arrayMutators,
        }}
        render={({
          handleSubmit,
          values: formValues,
          form: {
            mutators: { push },
          },
        }) => {
          const { requested, ...folders } = formValues;
          const hasSelectedFolders = values(folders).filter((value) => !!value).length;
          const hasRequestedDocs = (requested && requested.length) || hasSelectedFolders;

          return (
            <FormWrapper onSubmit={handleSubmit}>
              {!isLender && <ModalHeading>Request Documents</ModalHeading>}
              <ModalBody white>
                {!isLender && (
                  <SearchInputWrapper>
                    <SearchInput
                      onChange={this.onSearchStringUpdate}
                      value={searchString}
                      placeholder="Search document"
                    />
                  </SearchInputWrapper>
                )}
                {filteredDocuments?.recommended.map((folder) => (
                  <Folder
                    key={folder.id}
                    folderId={folder.id}
                    label={folder.name}
                    status={folder.status}
                    documents={folder.documents}
                    isLenderCreated={!!isLender}
                    openConfidentialityAgreementModal={openConfidentialityAgreementModal}
                  />
                ))}
                {filteredDocuments?.sections.map(({ sectionName, folders, sectionId }) =>
                  sectionId !== DOCUMENTS_SECTIONS_IDS.OTHER ? (
                    <Section
                      key={sectionId}
                      sectionName={
                        sectionId === DOCUMENTS_SECTIONS_IDS.REQUESTED && !folders.length
                          ? null
                          : sectionName
                      }
                      sectionId={sectionId}
                    >
                      {folders.map((folder) => (
                        <Folder
                          key={folder.id}
                          folderId={folder.id}
                          sectionId={sectionId}
                          label={folder.name}
                          status={folder.status}
                          documents={folder.documents}
                          isLenderCreated={!!isLender}
                          openConfidentialityAgreementModal={
                            openConfidentialityAgreementModal
                          }
                        />
                      ))}
                    </Section>
                  ) : null
                )}
                <FolderRequest push={push} />
              </ModalBody>
              <ModalActions>
                <Button type="submit" disabled={!hasRequestedDocs}>
                  Request Selected Documents
                </Button>
              </ModalActions>
            </FormWrapper>
          );
        }}
      />
    );
  }
}

function mapStateToProps(state) {
  const {
    loanNew: { data },
  } = state;
  return {
    loanId: data.id,
    documentsAll: data.documentsAll,
    loanType: data.loanType,
    propertyType: data.propertyType,
    documents: data.documents.map((item) => item.type),
    loanTypes: getLoanTypes(state).map((item) => item.value),
    propertyTypes: getPropertyTypes(state).map((item) => item.value),
  };
}

export default connect(mapStateToProps)(RequestDocumentsForm);

const FormWrapper = styled.form`
  ${modalWithContentWrapperStyles}
`;

const SearchInputWrapper = styled.div`
  position: relative;
  width: 100%;

  &:before {
    content: '';
    z-index: 3;
    position: absolute;
    left: 12px;
    top: 50%;
    transform: translateY(-50%);
    height: 18px;
    width: 18px;
    background: url('/images/icons/ico_search_gray.svg') no-repeat;
  }

  input {
    padding-left: 40px;
  }
`;
