import React from 'react';
import styled from 'styled-components/macro';
import { Section, WidgetTitle } from 'features/DealRoom/styles';
import { DueDatePicker } from 'components/DueDatePicker';
import { Text } from 'ui/Text';
import { convertCommonDate } from 'lib/utils';

export const ClosingWidget = ({
  editable,
  closingDate,
  setClosingDate,
  interest,
  termsheet,
}) => (
  <Section>
    <WidgetTitle>Terms</WidgetTitle>
    <div>
      <StatisticsContent>
        <SubTitle>CLOSING DATE</SubTitle>
        <Cell>
          <span>{convertCommonDate(closingDate)}</span>
          <div>
            {editable && (
              <div>
                <DueDatePicker
                  currentDate={closingDate}
                  dateExistsLabel="CHANGE DATE"
                  noDateLabel="ADD CLOSING DATE"
                  onlyIcon
                  onDayChange={(date) => {
                    setClosingDate(date);
                  }}
                />
              </div>
            )}
          </div>
        </Cell>
      </StatisticsContent>
      {/* <StatisticsContent>
        <SubTitle>ACCEPTED RATE</SubTitle>
        <Text>{interest}%</Text>
      </StatisticsContent> */}
      {termsheet?.signedTermsheet && (
        <StatisticsContent>
          <SubTitle>TERM SHEET</SubTitle>
          <Link
            href={termsheet.signedTermsheet}
            target="_blank"
            rel="noopener noreferrer"
          >
            DOWNLOAD
          </Link>
        </StatisticsContent>
      )}
    </div>
  </Section>
);

const StatisticsContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  padding: 10px 0;
  border-bottom: 1px solid #e8e9ed;

  &:last-child {
    border-bottom: none;
  }
`;

const Cell = styled.div`
  display: flex;
  align-items: center;
`;

const SubTitle = styled(Text)`
  color: #778598;
  font-size: 10px;
`;

const Link = styled.a`
  text-decoration: none;
  color: #4394ea;
  cursor: pointer;
  font-size: 10px;
`;
