import { put, takeLatest, call, select } from 'redux-saga/effects';
import types from 'constants/actionTypes';
import * as API from 'api';
import { SALES_BROKER } from 'constants/appRelated';
import { getError } from 'sagas/utils';
import {
  listingsSuccess,
  listingsFailure,
  additionalListingsSuccess,
  additionalListingsFailure,
} from './actions';

function* listings(action) {
  const { query } = action.payload;
  try {
    const { currentUser } = yield select();
    const isSalesBroker = currentUser.accountType === SALES_BROKER;
    const {
      data: { response },
    } = yield call(
      API.axiosApi.get,
      `listings?${isSalesBroker ? 'only_my=1' : 'only_matched=1'}${
        query ? `&${query}` : ''
      }`
    );

    yield put(listingsSuccess(response));
  } catch (e) {
    console.error(e);
    yield put(listingsFailure({ message: getError(e) }));
  }
}

function* additionalListings(action) {
  const { page } = action.payload;

  try {
    const { currentUser } = yield select();
    const isSalesBroker = currentUser.accountType === SALES_BROKER;

    const {
      data: { response },
    } = yield call(
      API.axiosApi.get,
      `listings?page=${page}${isSalesBroker ? '&only_my=1' : '&only_matched=1'}`
    );

    yield put(additionalListingsSuccess(response));
  } catch (e) {
    console.error(e);
    yield put(additionalListingsFailure({ message: getError(e) }));
  }
}

export default [
  takeLatest(types.LISTINGS_REQUEST, listings),
  takeLatest(types.ADDITIONAL_LISTINGS_REQUEST, additionalListings),
];
