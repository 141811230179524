import { put, takeLatest, call, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { hideModal, showModal } from 'actions/modalActions';
import types from 'constants/actionTypes';
import { modals, INFO_MODAL_TYPE } from 'constants/modalTypes';
import * as API from 'api';
import { getError } from 'sagas/utils';
import { userTypeSelector } from 'selectors/userType';

import {
  dashboardDataSuccess,
  dashboardDataFailure,
  emailConfirmationSuccess,
  emailConfirmationFailure,
  getLoansDashboardSuccess,
  getLoansDashboardFailure,
  confirmLendingCriteriaSuccess,
  confirmLendingCriteriaFailure,
} from './actions';

import {
  getNumberTasksSuccess,
  getNumberTasksFailure,
} from 'containers/MyTasksWrapper/actions';

function* dashboardData() {
  try {
    const {
      data: { response },
    } = yield call(API.axiosApi.get, '/auth/dashboard');

    const userType = userTypeSelector(yield select());
    let loans;
    let matchedLoans;

    if (userType.borrower || userType.broker) {
      loans = yield call(API.axiosApi.get, '/loans/my');
    }

    if (userType.lender || userType.privateLender) {
      matchedLoans = yield call(API.axiosApi.get, '/loans/match?status=2&status=3');
    }

    yield put(
      dashboardDataSuccess({
        ...response,
        //unreadMessagesCount: {loan: 4, personal: 3, team: 0},
        loans: loans?.data?.response?.results,
        matchedLoans: matchedLoans?.data?.response?.results,
      })
    );
    yield put(
      getNumberTasksSuccess({
        unseenTasksCount: response.unseenTasksCount,
      })
    );
  } catch (e) {
    yield put(dashboardDataFailure({ message: getError(e) }));
    yield put(getNumberTasksFailure({ message: getError(e) }));
  }
}

function* emailConfirmation(action) {
  try {
    const { fromLoan, disableNotificationModalClose } = action.payload || {};
    yield call(API.axiosApi.post, 'auth/resend-confirmation-email', {});

    if (fromLoan) {
      yield put(hideModal());
      yield put(push('/dashboard'));
    }

    yield put(emailConfirmationSuccess());
    yield put(
      showModal(modals.INFO_MODAL, {
        size: 'sm',
        infoType: INFO_MODAL_TYPE.SUCCESS,
        title: 'We have sent you a confirmation email',
        text:
          'If you don’t see this email in your inbox within 15 minutes, look for it in your junk mail folder. If you find it there, please mark the email as Not Junk and add info@realatom.com to your address book.',
        buttonText: 'OK',
        withoutControls: disableNotificationModalClose,
        disableClose: disableNotificationModalClose,
      })
    );
  } catch (e) {
    console.error(e);
    yield put(emailConfirmationFailure({ message: getError(e) }));
  }
}

function* getLoans(action) {
  try {
    const { filter } = action.payload;
    const {
      data: { response },
    } = yield call(
      API.axiosApi.get,
      `/loans/my?${
        filter
          ? `${filter
              .split(',')
              .map((item) => `status_order=${item}&`)
              .join('')}`
          : ''
      }`
    );

    yield put(getLoansDashboardSuccess({ loans: response.results }));
  } catch (e) {
    console.error(e);
    yield put(getLoansDashboardFailure({ message: getError(e) }));
  }
}

function* confirmLendingCriteriaRequest() {
  try {
    yield call(API.axiosApi.post, '/lenders/reconfirm-criteria/');
    yield put(confirmLendingCriteriaSuccess());
  } catch (e) {
    yield put(confirmLendingCriteriaFailure({ message: getError(e) }));
  }
}

export default [  
  takeLatest(types.DASHBOARD_DATA_REQUEST, dashboardData),
  takeLatest(types.EMAIL_CONFIRMATION_REQUEST, emailConfirmation),
  takeLatest(types.GET_LOANS_DASHBOARD_REQUEST, getLoans),
  takeLatest(types.CONFIRM_LENDING_CRITERIA_REQUEST, confirmLendingCriteriaRequest),
];
