import { put, call, takeLatest } from 'redux-saga/effects';
import types from 'constants/actionTypes';
import * as API from 'api';
import { getError } from 'sagas/utils';
import { showModal } from 'actions/modalActions';
import { modals, INFO_MODAL_TYPE } from 'constants/modalTypes';
import { push } from 'connected-react-router';
import { checkPasswordNeedRequest } from 'actions';

import {
  requestQrCodeSuccess,
  requestQrCodeFailure,
  confirmAuthenticatorDeviceSuccess,
  confirmAuthenticatorDeviceFailure,
  requestSmsAuthSuccess,
  requestSmsAuthFailure,
  disableTfaSuccess,
  disableTfaFailure,
  resendSmsSuccess,
  resendSmsFailure,
  confirmBySmsSuccess,
  confirmBySmsFailure,
} from './actions';

const checkIsPasswordNeedErrorMessage = (e) =>
  e.response?.status === 403 ? `Please enter your password again` : getError(e);

const checkIsPasswordNeedError = (e) => e.response?.status === 403;

const TFA_PATH = '/dashboard/two-factor-setup';
const PROFILE_PATH = '/dashboard/profile';

function* requestQrCodeRequest() {
  try {
    const deviceIdResponse = yield call(API.axiosApi.post, '/auth/tfa/totp/create', {});
    const deviceId = deviceIdResponse.data.response.id;
    const qrCodeResponse = yield call(
      API.axiosApi.get,
      `/auth/tfa/totp/${deviceId}/qr-code`
    );
    yield put(requestQrCodeSuccess({ deviceId, qrCode: qrCodeResponse.data }));
  } catch (e) {
    yield put(requestQrCodeFailure({ message: checkIsPasswordNeedErrorMessage(e) }));
    if (checkIsPasswordNeedError(e)) {
      yield put(push(PROFILE_PATH));
      yield put(checkPasswordNeedRequest({ path: TFA_PATH }));
    }
  }
}

function* confirmAuthenticatorDevice(action) {
  const { token, deviceId } = action.payload;
  try {
    yield call(API.axiosApi.put, `/auth/tfa/totp/${deviceId}/confirm`, { token });
    const {
      data: { response },
    } = yield call(API.axiosApi.get, '/auth/profile');

    yield put(confirmAuthenticatorDeviceSuccess({ user: response.user }));
    yield put(
      showModal(modals.INFO_MODAL, {
        size: 'sm',
        infoType: INFO_MODAL_TYPE.SUCCESS,
        title: 'Two-Factor authentication (2FA) is enabled now.',
        text: 'Thank you for keeping your account secure. ',
        buttonText: 'View My Recovery Codes',
        onButtonClick: (dispatch) => {
          dispatch(push('/dashboard/recovery-codes'));
        },
      })
    );
  } catch (e) {
    yield put(
      confirmAuthenticatorDeviceFailure({
        message: 'You entered the wrong one-time password',
      })
    );
  }
}

function* smsAuthRequest(action) {
  try {
    const { number } = action.payload;
    const deviceIdResponse = yield call(API.axiosApi.post, '/auth/tfa/phone/create', {
      phone_number: number,
    });
    const deviceId = deviceIdResponse.data.response.id;
    yield put(requestSmsAuthSuccess({ number, deviceId }));
  } catch (e) {
    yield put(requestSmsAuthFailure({ message: checkIsPasswordNeedErrorMessage(e) }));
    if (checkIsPasswordNeedError(e)) {
      yield put(push(PROFILE_PATH));
      yield put(checkPasswordNeedRequest({ path: TFA_PATH }));
    }
  }
}

function* resendSms(action) {
  try {
    const { deviceId } = action.payload;
    yield call(API.axiosApi.post, `/auth/tfa/phone/${deviceId}/issue-code`);
    yield put(resendSmsSuccess());
  } catch (e) {
    yield put(resendSmsFailure({ message: checkIsPasswordNeedErrorMessage(e) }));
    if (checkIsPasswordNeedError(e)) {
      yield put(push('/dashboard/profile'));
      yield put(checkPasswordNeedRequest({ path: TFA_PATH }));
    }
  }
}

function* confirmBySms(action) {
  try {
    const { deviceId, token } = action.payload;
    yield call(API.axiosApi.put, `/auth/tfa/phone/${deviceId}/confirm`, { token });
    const {
      data: { response },
    } = yield call(API.axiosApi.get, '/auth/profile');
    yield put(confirmBySmsSuccess({ user: response.user }));
    yield put(
      showModal(modals.INFO_MODAL, {
        size: 'sm',
        infoType: INFO_MODAL_TYPE.SUCCESS,
        title: 'Two-Factor authentication (2FA) is enabled now.',
        text: 'Thank you for keeping your account secure. ',
        buttonText: 'View My Recovery Codes',
        onButtonClick: (dispatch) => {
          dispatch(push('/dashboard/recovery-codes'));
        },
      })
    );
  } catch (e) {
    yield put(
      confirmBySmsFailure({
        message:
          'You entered wrong one-time password. Try again or start from the beginning',
      })
    );
  }
}

function* disableTfa() {
  try {
    yield call(API.axiosApi.post, 'auth/tfa/disable');
    const {
      data: { response },
    } = yield call(API.axiosApi.get, '/auth/profile');
    yield put(disableTfaSuccess({ user: response.user }));
  } catch (e) {
    yield put(disableTfaFailure({ message: checkIsPasswordNeedErrorMessage(e) }));
    if (checkIsPasswordNeedError(e)) {
      yield put(push(PROFILE_PATH));
      yield put(checkPasswordNeedRequest({ path: TFA_PATH }));
    }
  }
}

export default [
  takeLatest(types.REQUEST_QR_CODE_REQUEST, requestQrCodeRequest),
  takeLatest(types.CONFIRM_AUTHENTICATOR_DEVICE_REQUEST, confirmAuthenticatorDevice),
  takeLatest(types.REQUEST_SMS_AUTH_REQUEST, smsAuthRequest),
  takeLatest(types.DISABLE_TFA_REQUEST, disableTfa),
  takeLatest(types.RESEND_SMS_REQUEST, resendSms),
  takeLatest(types.CONFIRM_BY_SMS_REQUEST, confirmBySms),
];
