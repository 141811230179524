import { FIELD_TYPES } from 'constants/appRelated';

export const RANGE_STATE = {
  LAST_WEEK: 0,
  LAST_MONTH: 1,
  LAST_QUARTER: 2,
  LAST_YEAR: 3,
  CUSTOM: 4,
};

export const RANGE_DAYS = {
  WEEK: 7,
  MONTH: 30,
  QUARTER: 90,
  YEAR: 365,
};

export const groupOptions = [
  { value: 'week', label: 'Group by Week' },
  { value: 'month', label: 'Group by Month' },
  { value: 'quarter', label: 'Group by Quarter' },
];

export const normalizedGroupOptions = {
  week: { value: 'week', label: 'Group by Week' },
  month: { value: 'month', label: 'Group by Month' },
  quarter: { value: 'quarter', label: 'Group by Quarter' },
};

export const rangeOptions = [
  { value: RANGE_STATE.LAST_WEEK, label: 'Last 7 days' },
  { value: RANGE_STATE.LAST_MONTH, label: 'Last 30 days' },
  { value: RANGE_STATE.LAST_QUARTER, label: 'Last 90 days' },
  { value: RANGE_STATE.LAST_YEAR, label: 'Last 365 days' },
  { value: RANGE_STATE.CUSTOM, label: 'Custom' },
];

export const normalizedRangeOptions = {
  [RANGE_STATE.LAST_WEEK]: { value: RANGE_STATE.LAST_WEEK, label: 'Last 7 days' },
  [RANGE_STATE.LAST_MONTH]: { value: RANGE_STATE.LAST_MONTH, label: 'Last 30 days' },
  [RANGE_STATE.LAST_QUARTER]: { value: RANGE_STATE.LAST_QUARTER, label: 'Last 90 days' },
  [RANGE_STATE.LAST_YEAR]: { value: RANGE_STATE.LAST_YEAR, label: 'Last 365 days' },
  [RANGE_STATE.CUSTOM]: { value: RANGE_STATE.CUSTOM, label: 'Custom' },
};

export const marketAnalyticsFiltersConfig = {
  timestamp: {
    name: 'timestamp',
    urlName: 'timestamp',
    type: FIELD_TYPES.DATE,
  },
  haveOffers: {
    name: 'haveOffers',
    urlName: 'have_offers',
    type: FIELD_TYPES.DATE,
  },
  grouping: {
    name: 'grouping',
    urlName: 'grouping',
    type: FIELD_TYPES.CHOICE,
  },
  q1: {
    name: 'q1',
    urlName: 'q1',
    type: FIELD_TYPES.TEXT,
  },
  q2: {
    name: 'q2',
    urlName: 'q2',
    type: FIELD_TYPES.TEXT,
  },
  q3: {
    name: 'q3',
    urlName: 'q3',
    type: FIELD_TYPES.TEXT,
  },
  min: {
    name: 'min',
    urlName: 'min',
    type: FIELD_TYPES.NUMBER,
  },
  max: {
    name: 'max',
    urlName: 'max',
    type: FIELD_TYPES.NUMBER,
  },
  loansCount: {
    name: 'loansCount',
    urlName: 'loans_count',
    type: FIELD_TYPES.TEXT,
  },
  outliers: {
    name: 'outliers',
    urlName: 'outliers',
    type: FIELD_TYPES.CHOICE_MULTI,
  },
  titleSymbol: {
    name: 'titleSymbol',
    urlName: 'title_symbol',
    type: FIELD_TYPES.TEXT,
  },
  type: {
    name: 'type',
    urlName: 'type',
    type: FIELD_TYPES.TEXT,
  },
  boxColor: {
    name: 'boxColor',
    urlName: 'box_color',
    type: FIELD_TYPES.TEXT,
  },
  showForUser: {
    name: 'showForUser',
    urlName: 'show_for_user',
    type: FIELD_TYPES.TEXT,
  },
};

export const CHART_BOXPLOT_CONFIGURATION = {
  BAR_WIDTH: 40,
  TOOLTIP_BOXPLOT_HEIGHT: 270,
  TOOLTIP_BOXPLOT_PADDING: 15,
  BOXPLOT_ITEM_MARGIN: 5,
  CHART_INITAL_OFFSET_X: 40,
  CHART_SCALE_Y_ODDITIONAL_OFFSET: 5,
  CHART_WIDTH: 700,
  CHART_INITIAL_HEIGHT: 504,
  CHART_MARGIN_TOP: 10,
  CHART_MARGIN_RIGHT: 30,
  CHART_MARGIN_BOTTOM: 60,
  CHART_MARGIN_LEFT: 60,
  TOOLTIP_WIDTH: 250,
};

export const ANALYTICS_LOANS_VIEW = {
  TABLE: 'table',
  TILE: 'tile',
};

export const ANALYTICS_LOAN_MAPPING = {
  LOAN_NAME: 'property_name',
  AMOUNT: 'loan_amount',
  RATE: 'stat__min_interest',
  LTV: 'stat__min_ltv',
  LTC: 'stat__min_ltc',
  OCCUPANCY_RATE: 'occupancy_rate',
  DESIRED_RATE: 'desired_rate',
  QUOTED_RATE: 'quoted_rate',
  STATE: 'state',
  DSCR: 'dscr',
};


export const CHARTS_TYPES = {
  RATE: { title: 'rate', symbol: '%' },
  DSCR: { title: 'DSCR', symbol: '' },
  AMOUNT: { title: 'amount', symbol: '$MM', pattern: '$$$& MM' },
  LTV: { title: 'LTV', symbol: '%' },
};
