import React, { Component } from 'react';
import { NewDatepicker } from 'components/Form';
import styled from 'styled-components/macro';
import enhanceWithClickOutside from 'react-click-outside';
import { format } from 'date-fns';
import { CalendarIcon } from 'components/SvgIcons';
import PropTypes from 'prop-types';
import { convertCommonDate } from 'lib/utils';
class DueDatePicker extends Component {
  state = { datePickerVisible: false };

  handleClickOutside() {
    if (this.state.datePickerVisible) {
      this.setState({ datePickerVisible: false });
    }
  }

  render() {
    const {
      onDayChange,
      currentDate,
      dateExistsLabel,
      noDateLabel,
      onlyIcon,
    } = this.props;
    return (
      <DueDateWrapper>
        <DueDateBtn
          type="button"
          onClick={() => {
            this.setState((state) => ({ datePickerVisible: !state.datePickerVisible }));
          }}
        >
          <CalendarIcon />
          {!onlyIcon && (
            <>
              &nbsp;&nbsp;
              {currentDate
                ? dateExistsLabel || `Due by: ${convertCommonDate(currentDate)}`
                : noDateLabel || 'ADD DUE DATE'}
            </>
          )}
        </DueDateBtn>
        {this.state.datePickerVisible && (
          <DatePickerWrapper>
            <NewDatepicker
              withoutInput
              minDate={new Date()}
              input={{
                onChange: (date) => {
                  onDayChange(format(new Date(date), 'yyyy-MM-dd'));
                  this.setState({ datePickerVisible: false });
                },
              }}
            />
          </DatePickerWrapper>
        )}
      </DueDateWrapper>
    );
  }
}

DueDatePicker.propTypes = {
  onDayChange: PropTypes.func.isRequired,
  currentDate: PropTypes.string.isRequired,
  dateExistsLabel: PropTypes.string,
  noDateLabel: PropTypes.string,
};

const DueDateBtn = styled.button`
  padding: 6px 8px;
  color: #4394ea;
  border-radius: 4px;
  border: none;
  outline: none;
  font-size: 10px;
  white-space: nowrap;
  flex-shrink: 0;
  background-color: #fafafb;
  cursor: pointer;
  display: flex;
  align-items: flex-end;

  &:focus,
  &:active,
  &:hover {
    background-color: rgba(67, 148, 234, 0.1);
  }
`;

const DatePickerWrapper = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  top: 24px;
  z-index: 5;
`;

const DueDateWrapper = styled.div`
  position: relative;
`;

export default enhanceWithClickOutside(DueDatePicker);
