import { put, takeLatest, call } from 'redux-saga/effects';
import types from 'constants/actionTypes';
import * as API from 'api';
import { getError } from 'sagas/utils';

import { getDealRoomsSuccess, getDealRoomsFailure } from './actions';

function* getRooms() {
  try {
    const {
      data: { response },
    } = yield call(API.axiosApi.get, '/deal-rooms');

    yield put(getDealRoomsSuccess(response));
  } catch (e) {
    console.error(e);
    yield put(getDealRoomsFailure({ message: getError(e) }));
  }
}

export default [takeLatest(types.GET_DEAL_ROOMS_REQUEST, getRooms)];
