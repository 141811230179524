import React from 'react';
import styled from 'styled-components';
import { getIEStatus } from 'lib/browser';

// Component used height property to define height of svg content on IE11

export const BlurContent = ({ blured, height, children }) => {
  const isIe = getIEStatus();

  if (!blured) {
    return children;
  }

  return isIe ? (
    <Blur>
      <svg className="blur-ie" height={height || 'auto'}>
        <filter id="blur">
          <feGaussianBlur stdDeviation="6" />
        </filter>
        <foreignObject filter="url(#blur)">{children}</foreignObject>
      </svg>
    </Blur>
  ) : (
    <Blur>{children}</Blur>
  );
};

export const Blur = styled.div`
  position: relative;

  pointer-events: none;
  user-select: none;
  filter: blur(3px);

  &::before {
    display: block;
  }

  .blur-ie {
    width: 100%;
    height: 100%;
  }

  &::before {
    content: '';
    position: absolute;
    display: none;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  foreignObject {
    width: 100%;
    height: 100%;
  }
`;
