import React from 'react';
import styled, { css } from 'styled-components/macro';
import { Text } from 'ui/Text';

const Content = styled.div`
  position: absolute;
  top: -10px;
  left: calc(100% + 18px);
  display: none;
  padding: 5px 10px;
  background-color: #fff;
  box-shadow: 0 2px 14px 0 rgba(194, 198, 210, 0.5);
  z-index: 100;
  width: 180px;

  &::after {
    content: '';
    position: absolute;
    left: -5px;
    top: 8px;
    width: 12px;
    height: 12px;
    background-color: #fff;
    transform: rotate(45deg);
    box-shadow: -2px 3px 3px 0 rgba(194, 198, 210, 0.2);
  }

  &::before {
    content: '';
    position: absolute;
    width: 30px;
    left: -30px;
    height: 100%;
  }

  ${(p) =>
    p.position === 'bottom' &&
    css`
      left: -84px;
      top: 25px;

      &::after {
        content: '';
        left: calc(50% - 6px);
        top: -6px;
      }

      &::before {
        left: calc(50% - 15px);
        height: 100%;
        top: 0;
        z-index: 2;
        background: #fff;
      }
    `}

    ${(p) =>
      p.position === 'left' &&
      css`
        right: 25px;
        left: auto;

        &::after {
          right: -5px;
          left: auto;
          box-shadow: 3px -3px 3px rgba(194, 198, 210, 0.2);
        }

        &::before {
          right: -30px;
          left: auto;
        }
      `}


  ${(p) =>
    p.position === 'center' &&
    css`
      top: 50%;
      transform: translateY(-50%);
      width: auto;
      white-space: nowrap;
    `}
`;

const Wrapper = styled.div`
  position: relative;
  display: inline-block;

  img {
    display: block;
  }

  &:hover {
    ${Content} {
      display: block;
    }
  }
`;

const ContentIn = styled.div`
  position: relative;
  z-index: 3;
`;

export const Tooltip = ({ component, text, position, children, hidden }) => (
  <Wrapper>
    {children || <img src="/images/icons/ico_info.svg" alt="tooltip icon" />}
    {!hidden && (
      <Content position={position}>
        <ContentIn>
          {component || (
            <Text fontSize="12px" fontFamily="muli" as="div">
              {text}
            </Text>
          )}
        </ContentIn>
      </Content>
    )}
  </Wrapper>
);
