import { createSelector } from 'reselect';
import { contains } from 'ramda';
import { LOAN_STATUS } from 'constants/appRelated';
import { userTypeSelector } from 'selectors/userType';
import { getLoanStatus, getLenderAcceptedOffer } from 'features/LoanNew/selectors';
import { getCurrentLenderTeam } from 'features/LenderCompany/selectors';

export const getTerhmeetsTabAllowedStatus = createSelector(
  [userTypeSelector, getLoanStatus, getLenderAcceptedOffer],
  (userType, loanStatus, lenderAcceptedOffer) =>
    userType.lender
      ? !!lenderAcceptedOffer
      : contains(loanStatus, [
          LOAN_STATUS.POSTED_TO_MARKETPLACE,
          LOAN_STATUS.PENDING_CLOSING,
          LOAN_STATUS.CLOSED_LOST,
          LOAN_STATUS.CLOSED_WON,
          LOAN_STATUS.CLOSED_WITHDRAWN,
        ])
);

export const getLenderTeamPresence = createSelector(
  [userTypeSelector, getCurrentLenderTeam],
  (userType, team) => userType.lender && !!(team?.members?.length > 1)
);
