import types from 'constants/actionTypes';

export const resendInviteRequest = (payload = {}) => ({
  type: types.RESEND_INVITE_REQUEST,
  payload,
});
export const resendInviteSuccess = (payload = {}) => ({
  type: types.RESEND_INVITE_SUCCESS,
  payload,
});
export const resendInviteFailure = (payload = {}) => ({
  type: types.RESEND_INVITE_FAILURE,
  payload,
});

export const registrationRequest = (payload = {}) => ({
  type: types.REGISTRATION_REQUEST,
  payload,
});
export const registrationSuccess = (payload = {}) => ({
  type: types.REGISTRATION_SUCCESS,
  payload,
});
export const registrationFailure = (payload = {}) => ({
  type: types.REGISTRATION_FAILURE,
  payload,
});
