import React from 'react';
import styled from 'styled-components/macro';
import { Section, SectionTitle, EditLink } from 'styles/loan';
import { FieldEdited } from 'features/LoanNew/components';
import PropTypes from 'prop-types';

import { Row } from './Row';

import {
  SourcesTable,
  SourcesHeading,
  SourcesSum,
  SourcesCell,
  SourcesCellWide,
  Total,
  DefaultText,
  ValueCell,
} from './styles';

export const SourcesUses = React.forwardRef(
  ({ loan, editable, id, isOwner, fieldsHistory = {}, sourcesUsesData }, ref) => (
    <Section ref={ref} data-testid="suSection">
      <SectionTitle>Sources and Uses</SectionTitle>
      {editable && <EditLink to={`/dashboard/loan-creation/${id}/su`} />}
      {isOwner && !(sourcesUsesData.sources.sum === sourcesUsesData.uses.sum) && (
        <Warn>Sources and Uses total should be equal</Warn>
      )}
      <SourcesTable>
        <SourcesHeading>
          <SourcesCellWide>Sources</SourcesCellWide>
          <LabelCell>Value</LabelCell>
          <LabelCell>%</LabelCell>
        </SourcesHeading>
        {loan.projectCostFormula && (
          <>
            {sourcesUsesData.sources.fields.map((item) => (
              <Row
                key={item.field}
                value={item.value}
                percent={item.percent}
                title={item.title}
                EditedField={
                  fieldsHistory[item.field] && <FieldEdited fieldName={item.field} />
                }
              />
            ))}
            {sourcesUsesData.sources.additionalFields.map((item) => (
              <Row
                key={item.fieldName}
                value={item.value}
                percent={item.percent}
                title={item.title}
                EditedField={
                  fieldsHistory[item.valueName] && (
                    <FieldEdited fieldName={item.valueName} />
                  )
                }
              />
            ))}
          </>
        )}
        <SourcesSum data-testid="sourcesSumRow">
          <SourcesCellWide>Total Sources</SourcesCellWide>
          <ValueCell>
            <SourcesCell>
              <Total
                variant={
                  !(sourcesUsesData.sources.sum === sourcesUsesData.uses.sum) && isOwner
                    ? 'warn'
                    : 'normal'
                }
              >
                {sourcesUsesData.sources.sumLabel}
              </Total>
            </SourcesCell>
            <SourcesCell>
              <DefaultText>100%</DefaultText>
            </SourcesCell>
          </ValueCell>
        </SourcesSum>
      </SourcesTable>
      <SourcesTable>
        <SourcesHeading>
          <SourcesCellWide>Uses</SourcesCellWide>
          <LabelCell>Value</LabelCell>
          <LabelCell>%</LabelCell>
        </SourcesHeading>
        {loan.projectCostFormula && (
          <>
            {sourcesUsesData.uses.fields.map((item) => (
              <Row
                key={item.field}
                value={item.value}
                percent={item.percent}
                title={item.title}
                EditedField={
                  fieldsHistory[item.field] && <FieldEdited fieldName={item.field} />
                }
              />
            ))}
            {sourcesUsesData.uses.additionalFields.map((item) => (
              <Row
                key={item.fieldName}
                value={item.value}
                percent={item.percent}
                title={item.title}
                EditedField={
                  fieldsHistory[item.valueName] && (
                    <FieldEdited fieldName={item.valueName} />
                  )
                }
              />
            ))}
          </>
        )}
        <SourcesSum data-testid="usesSumRow">
          <SourcesCellWide>Total Uses</SourcesCellWide>
          <ValueCell>
            <SourcesCell>
              <Total
                variant={
                  !(sourcesUsesData.sources.sum === sourcesUsesData.uses.sum) && isOwner
                    ? 'warn'
                    : 'normal'
                }
              >
                {sourcesUsesData.uses.sumLabel}
              </Total>
            </SourcesCell>
            <SourcesCell>
              <DefaultText>100%</DefaultText>
            </SourcesCell>
          </ValueCell>
        </SourcesSum>
      </SourcesTable>
    </Section>
  )
);

SourcesUses.displayName = 'SourcesUses';
SourcesUses.propTypes = {
  loan: PropTypes.objectOf(PropTypes.any).isRequired,
  editable: PropTypes.bool,
  id: PropTypes.number,
  isOwner: PropTypes.bool,
  fieldsHistory: PropTypes.objectOf(PropTypes.any),
};

const LabelCell = styled(SourcesCell)`
  @media (max-width: ${(p) => p.theme.breakpoints[0]}px) {
    display: none;
  }
`;

const Warn = styled.p`
  color: #ff6f57;
`;
