import React from 'react';
import styled from 'styled-components/macro';
import { Field } from 'react-final-form';

import { FolderCheckItem, File } from '.';

export const Folder = ({
  label,
  status,
  folderId,
  sectionId,
  documents,
  isLenderCreated,
  openConfidentialityAgreementModal,
}) => (
  <Wrapper isLenderCreated={isLenderCreated}>
    <Field
      key={folderId}
      component={FolderCheckItem}
      name={`folder${folderId}`}
      type="checkbox"
      label={label}
      status={status}
      mb={0}
    />
    {documents && !!documents.length && (
      <FilesWrapper>
        {documents.map((item) => (
          <File
            key={item.id}
            folderId={folderId}
            sectionId={sectionId}
            item={item}
            openConfidentialityAgreementModal={openConfidentialityAgreementModal}
          />
        ))}
      </FilesWrapper>
    )}
  </Wrapper>
);

const Wrapper = styled.div`
  margin-bottom: 24px;

  ${({ isLenderCreated }) => isLenderCreated && `
    border-bottom: 1px solid #E8E9ED;
    padding-bottom: 16px;
  `}
`;

const FilesWrapper = styled.div`
  margin-top: 16px;
  padding-top: 16px;
  border-top: 1px solid #e8e9ed;
`;
