import React from 'react';
import styled from 'styled-components';
import { Text } from 'ui/Text';
import { DueToDate } from 'features/DealRoom/components';
import { DocumentIcon } from 'components/SvgIcons';

export const DDRItem = ({ name, closingDate, withoutIcon = false }) => (
  <Wrapper>
    <Lable>
      {!withoutIcon && <DocumentIcon />}
      {name}
    </Lable>
    {closingDate && <DueToDate closingDate={closingDate} />}
  </Wrapper>
);

const Wrapper = styled.div`
  margin-bottom: 8px;
  display: flex;
  align-items: center;
`;

const Lable = styled(Text)`
  display: flex;
  align-items: center;

  svg {
    margin-right: 6px;
  }
`;
