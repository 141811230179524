import React from 'react';
import styled from 'styled-components/macro';
import { Field } from 'react-final-form';
import { Text } from 'ui/Text';
import { WrappedRadio } from 'components/Form';
import { LENDER, BROKER, BORROWER } from 'constants/appRelated';

const Title = styled(Text)`
  white-space: nowrap;
`;

const Wrapper = styled.div`
  margin-bottom: 32px;
`;

const Controls = styled.div`
  display: flex;
`;

const FieldWrapper = styled.div`
  flex: 1;
`;

export const AccountTypes = ({invitedByLender}) => (
  <Wrapper>
    <Title mb={3}>Please select type of the account</Title>
    <Controls>
      <FieldWrapper>
        <Field
          component={WrappedRadio}
          name="accountType"
          label="Broker"
          value={BROKER}
          type="radio"
        />
      </FieldWrapper>
      <FieldWrapper>
        <Field
          component={WrappedRadio}
          name="accountType"
          label="Borrower"
          value={BORROWER}
          type="radio"
        />
      </FieldWrapper>
      {!invitedByLender && (
        <div>
          <Field
            component={WrappedRadio}
            name="accountType"
            label="Direct Lender"
            value={LENDER}
            type="radio"
          />
        </div>
      )}
    </Controls>
  </Wrapper>
);
