export const syncActions = ['CLEAR_LENDER_DETAILS'];

export const asyncActions = [
  'GET_LENDER_DETAILS',
  'ADD_TAG_TO_LENDER',
  'REMOVE_TAG_FROM_LENDER',
  'ADD_NOTE_TO_LENDER',
  'GET_LENDER_ACTIVITIES',
  'ARCHIVE_LENDER',
  'GET_BB_DETAILS',
  'RESEND_INVITATION',
  'ADD_TAG_TO_CLIENT',
  'REMOVE_TAG_FROM_CLIENT',
  'ADD_NOTE_TO_CLIENT',
  'GET_USERS'
];
