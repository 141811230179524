import React, { useState } from 'react';
import styled from 'styled-components/macro';
import {
  ModalBody,
  ModalHeading,
  ModalActions,
  ModalWithContentWrapper,
} from 'ui/Modal/styles';
import { Textarea } from 'ui/Textarea';
import { Button } from 'ui/Button';

export const DealRoomDocumentRejectModal = ({ hideModal, submit }) => {
  const [reason, updateReason] = useState('');

  return (
    <ModalWithContentWrapper>
      <ModalHeading>Please write a rejection reason</ModalHeading>
      <ModalBody>
        <StyledTextarea
          placeholder="Write your rejection reason here"
          value={reason}
          onChange={(e) => {
            updateReason(e.target.value);
          }}
        />
      </ModalBody>
      <StyledModalActions>
        <Button
          secondary
          type="button"
          onClick={() => {
            if (reason.trim()) {
              submit(reason);
              hideModal();
            }
          }}
        >
          Send
        </Button>
      </StyledModalActions>
    </ModalWithContentWrapper>
  );
};

const StyledTextarea = styled(Textarea)`
  height: 230px;
  margin-bottom: 0;
`;

const StyledModalActions = styled(ModalActions)`
  margin: 0 24px;
  border-top: 1px solid #e8e9ed;
`;
