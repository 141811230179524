import { put, takeLatest, call, select } from 'redux-saga/effects';
import types from 'constants/actionTypes';
import * as API from 'api';
import { getError } from 'sagas/utils';
import { history } from 'store';
import { featuresUrlParamsMap } from 'config/featuresUrlParamsMap';
import { makeObjFromUrlString } from 'lib/url';
import { setCurrentUri } from 'actions';

import {
  marketplaceDataSuccess,
  marketplaceDataFailure,
  updateMarketplaceDataSuccess,
  updateMarketplaceDataFailure,
} from './actions';

function* marketplace() {
  const {
    router: { location },
  } = yield select();
  const query = location.search.replace('?', '');
  const isStorageURL = localStorage.getItem('url_marketplace_page');

  const {
    data: { ordering },
  } = makeObjFromUrlString({
    uri: location.search,
    config: { ordering: featuresUrlParamsMap.ordering },
  });

  try {
    const {
      data: { response },
    } = yield call(API.axiosApi.get, `loans/preview${query ? `?${query}` : `?${isStorageURL}`}`);

    yield put(marketplaceDataSuccess({ response, ordering }));
  } catch (e) {
    console.error(e);
    yield put(marketplaceDataFailure({ message: getError(e) }));
  }
}

function* updateMarketplaceData(action) {
  try {
    let search = null;
    const { query } = action.payload;
    const {
      filter,
      marketplace: { ordering },
    } = yield select();

    if (query) {
      search = query;
    } else {
      search = [ordering && `ordering=${ordering}`, filter.uri].filter(Boolean).join('&');

      history.push(`?${search}`);
      yield put(setCurrentUri(search));
    }

    const {
      data: { response },
    } = yield call(API.axiosApi.get, `loans/preview${search ? `?${search}` : ''}`);

    yield put(updateMarketplaceDataSuccess(response));
  } catch (e) {
    yield put(updateMarketplaceDataFailure({ message: getError(e) }));
  }
}

export default [
  takeLatest(types.MARKETPLACE_DATA_REQUEST, marketplace),
  takeLatest(types.UPDATE_MARKETPLACE_DATA_REQUEST, updateMarketplaceData),
];
