import React, { Component } from 'react';
import { find } from 'ramda';
import Geosuggest from 'react-geosuggest';
import { DEFAULT_LOCATION } from 'constants/appRelated';
import PropTypes from 'prop-types';

import { SUGGEST_ERROR_TYPE } from './constants';

const makeGoogleSuggestLocation = (location) => ({
  lat: () => location.lat,
  lng: () => location.lng,
});

class GoogleSuggest extends Component {
  handleBlur = (suggestion) => {
    const { pushAddress } = this.props;

    if (suggestion) {
      pushAddress(null, {
        message: 'Please, select a suggestion from the list',
        type: SUGGEST_ERROR_TYPE.BLUR_SUGGESTION_LIST,
      });
    }
  };

  handleSelectSuggest = (suggest) => {
    const { pushAddress } = this.props;

    if (!suggest?.gmaps?.address_components) {
      pushAddress(false);
      return;
    }

    const address = suggest.gmaps.address_components;

    const city = find((item) =>
      find(
        (type) =>
          type === 'locality' || type === 'sublocality_level_1' || type === 'sublocality'
      )(item.types)
    )(address);

    const state = find((item) =>
      find((type) => type === 'administrative_area_level_1')(item.types)
    )(address);

    const zip = find((item) => find((type) => type === 'postal_code')(item.types))(
      address
    );

    if (!city || !state || !zip) {
      pushAddress(null, {
        message: 'Not valid address',
        type: SUGGEST_ERROR_TYPE.INVALID_ADDRESS,
      });
    } else {
      const { location, label } = suggest;
      pushAddress({
        city: city.long_name,
        state: state.short_name,
        zip: zip.long_name,
        location,
        label,
      });
    }
  };

  render() {
    const { placeholder, label } = this.props;

    return (
      <Geosuggest
        placeholder={placeholder || 'Address'}
        onSuggestSelect={this.handleSelectSuggest}
        onBlur={this.handleBlur}
        location={makeGoogleSuggestLocation(DEFAULT_LOCATION)}
        radius="20"
        initialValue={label || ''}
        country={['us', 'ca']}
        autoActivateFirstSuggest
      />
    );
  }
}

GoogleSuggest.propTypes = {
  placeholder: PropTypes.string,
  label: PropTypes.string,
  pushAddress: PropTypes.func.isRequired,
};

export default GoogleSuggest;
