import { put, takeLatest, call, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import types from 'constants/actionTypes';
import * as API from 'api';
import { getError } from 'sagas/utils';

import {
  validateAuthParamsSuccess,
  validateAuthParamsFailure,
  allowAuthSuccess,
  allowAuthFailure,
} from './actions';

function* validateAuthParams(action) {
  try {
    const { response_type, client_id, redirect_uri, scope, state } = action.payload;
    const {
      data: { response },
    } = yield call(
      API.axiosApiNoBase.get,
      `external_api/oauth/authorize?response_type=${response_type}&client_id=${client_id}&redirect_uri=${redirect_uri}&scope=${scope}&state=${state}`
    );
    yield put(
      validateAuthParamsSuccess({
        response_type,
        client_id,
        redirect_uri,
        scope,
        state,
        scopes: response.scopes,
      })
    );
  } catch (e) {
    yield put(push('/dashboard'));
    yield put(validateAuthParamsFailure(getError(e)));
  }
}

function* allowAuth() {
  try {
    const {
      oAuth: { response_type, client_id, redirect_uri, scope, state },
    } = yield select();
    const {
      data: { response },
    } = yield call(API.axiosApiNoBase.post, `external_api/oauth/authorize/`, {
      response_type,
      client_id,
      redirect_uri,
      scope,
      state,
    });
    const { redirectTo } = response;
    yield put(allowAuthSuccess());
    window.location.replace(redirectTo);
  } catch (e) {
    yield put(push('/dashboard'));
    yield put(allowAuthFailure(getError(e)));
  }
}

export default [
  takeLatest(types.VALIDATE_AUTH_PARAMS_REQUEST, validateAuthParams),
  takeLatest(types.ALLOW_AUTH_REQUEST, allowAuth),
];
