import styled, { css } from 'styled-components/macro';
import { Text } from 'ui/Text';

export const ModalClose = styled.button`
  position: absolute;
  right: 24px;
  top: 24px;
  width: 12px;
  height: 12px;
  background: url(/images/icons/ico_modal_close.svg) no-repeat center;
  border: none;
  padding: 0;
  margin: 0;
  outline: none;
  z-index: 10;
  cursor: pointer;
`;

export const ModalHeading = styled.div`
  margin-top: 0;
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 24px;
  font-weight: 700;
  text-align: center;
  color: #1c3353;
`;

export const ModalSubHeading = styled.p`
  margin-top: 0;
  margin-bottom: 24px;
  text-align: center;
  color: #1c3353;
`;

export const ModalBody = styled.div`
  padding: 30px;
  background-color: #fafafb;

  &.modalBody-switcher{
    ::-webkit-scrollbar {
      width: 6px;     
    }
    ::-webkit-scrollbar-track {
      background: rgba(196, 196, 196, 0.4);
      border-radius: 2px;
    }
    ::-webkit-scrollbar-thumb {
      background: rgba(196, 196, 196, 0.7);    
      border-radius: 2px;
    }
  }

  ${({ white }) => white && `
    background: #FFF;
  `};

  @media (max-width: ${(p) => p.theme.breakpoints[1]}px) {
    padding: 16px;
  }
`;

const ModalControlsCommon = styled.div`
  margin-top: 30px;
  text-align: right;
`;

export const ModalControls = styled(ModalControlsCommon)`
  ${(p) =>
    p.alignCenter &&
    css`
      display: flex;
      justify-content: center;
    `};
`;

export const ModalHeader = styled.div`
  position: relative;
  min-height: 60px;
  padding: 15px 45px 15px 30px;
  background-color: #fff;
  box-shadow: 0 4px 10px 0 rgba(194, 198, 210, 0.2);
`;

export const ModalTitle = styled(Text)`
  font-size: ${(p) => p.theme.fontSizes[3]}px;
`;

export const modalWithContentWrapperStyles = css`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;

  > * {
    position: relative;
  }

  ${ModalHeading} {
    padding: 20px 40px;
    margin-bottom: 0;
    flex-shrink: 0;
  }
  ${ModalBody} {
    flex: 1;
    padding: 24px;
    //overflow-y: auto;
    
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      flex: 1 1 auto;
    }
  }
`;

export const ModalWrapper = styled.div`
  /* padding: 40px 24px; */

  ${ModalHeading} {
    padding: 20px 40px;
    margin-bottom: 0;
    flex-shrink: 0;
  }
`;

export const ModalWithContentWrapper = styled.div`
  ${modalWithContentWrapperStyles}
`;

export const ModalActions = styled.div`
  padding: 24px;
  display: flex;
  justify-content: center;
  flex-shrink: 0;
`;
