import { values as getValues } from 'ramda';
import { OFFER_STATE } from 'constants/appRelated';

export const getPercentValue = (val, sum) =>
  val ? `${((val / sum) * 100).toFixed(2)} %` : '0 %';

export const isOfferAuthor = (negotiation) => {
  if (!negotiation) {
    return false;
  }
  const { offers } = negotiation;
  return offers[offers.length - 1].isMy;
};

export const getAcceptedOffers = (loan) => {
  const { negotiations } = loan;
  return getValues(negotiations).reduce((acc, item) => {
    const acceptedOffer = item.negotiations.filter(
      (negotiation) => negotiation.state === OFFER_STATE.ACCEPTED
    )[0];
    return acceptedOffer ? [...acc, { ...acceptedOffer, profile: item.profile }] : acc;
  }, []);
};
