import React from 'react';
import Dropzone from 'react-dropzone';
import styled from 'styled-components/macro';
import { Text } from 'ui/Text';
import { allowedDocTypes } from 'config/allowedDocTypes';
import { DocumentRejectionHandler } from 'components/DocumentRejectionHandler';

const Droparea = styled(Dropzone)`
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 2px dashed #6987ff;
  border-radius: 4px;
  padding: 16px 0;
`;

const Description = styled(Text)`
  width: 100%;
  max-width: 352px;
  font-size: 14px;
  color: #2b4c78;
  line-height: 21px;
  text-align: center;

  span {
    color: #4394ea;
    cursor: pointer;
  }
`;

const ImgWrap = styled.div`
  margin-bottom: 16px;
`;

export const UploadCsv = ({ onDrop }) => (
  <DocumentRejectionHandler>
    {({ handleOnDropReject }) => (
      <Droparea
        onDrop={onDrop}
        accept={allowedDocTypes.csv}
        onDropRejected={handleOnDropReject}
      >
        <ImgWrap>
          <img src="/images/icons/ico_document_upload.svg" alt="upload" />
        </ImgWrap>
        <Description fontFamily="muli">Upload your Lender Database</Description>
      </Droparea>
    )}
  </DocumentRejectionHandler>
);
