import React from 'react';
import styled, { css } from 'styled-components/macro';
import PropTypes from 'prop-types';
import { Text } from 'ui/Text';

const Content = styled.div`
  ${(p) =>
    (p.mb || typeof p.mb === 'number') &&
    css`
      margin-bottom: ${p.theme.space[p.mb] ? `${p.theme.space[p.mb]}px` : p.mb};
    `}
`;

const Wrapper = styled.label`
  display: flex;
  align-items: center;
  color: #1c3353;
  font-size: 16px;

  input {
    position: absolute;
    opacity: 0;
  }

  input:checked + div {
    background: linear-gradient(45deg, #ffe269, #fd8440);

    &::before {
      left: 36px;
    }

    div {
      left: 11px;
    }
  }

  input:disabled + div {
    background: ${(p) => p.theme.colors.grays[1]};
  }
`;

const SwitchArea = styled.div`
  position: relative;
  width: 60px;
  margin: 0 5px;
  height: 26px;
  background: linear-gradient(45deg, #24b8fe, #6987ff);
  border-radius: 20px;
  outline: none;
  border: none;
  cursor: pointer;

  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 6px;
    transform: translateY(-50%);
    transition: left 0.2s;
    width: 18px;
    height: 18px;
    border-radius: 100%;
    box-sizing: border-box;
    border: 1px solid #ddd;
    background-color: #f8f4f4;
  }
`;

export const Switch = ({
  disabled,
  value1,
  value2,
  checked,
  name,
  label,
  mb,
  ...rest
}) => (
  <Content mb={mb}>
    {label && (
      <Text fontSize="1" mb={2} fontFamily="muli">
        {label}
      </Text>
    )}
    <Wrapper>
      <span>{value1}</span>
      <input
        type="checkbox"
        checked={checked}
        disabled={disabled}
        name={name}
        {...rest}
      />
      <SwitchArea />
      <span>{value2}</span>
    </Wrapper>
  </Content>
);

Switch.propTypes = {
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  value1: PropTypes.string,
  value2: PropTypes.string,
  name: PropTypes.string,
};

Switch.defaultProps = {
  checked: false,
  disabled: false,
  value1: '',
  value2: '',
  name: '',
};
