import types from 'constants/actionTypes';

export const checkTeamMemberTokenRequest = ({ uuid }) => ({
  type: types.CHECK_TEAM_MEMBER_TOKEN_REQUEST,
  payload: { uuid },
});

export const checkTeamMemberTokenSuccess = (payload = {}) => ({
  type: types.CHECK_TEAM_MEMBER_TOKEN_SUCCESS,
  payload,
});
export const checkTeamMemberTokenFailure = (payload = {}) => ({
  type: types.CHECK_TEAM_MEMBER_TOKEN_FAILURE,
  payload,
});

export const teamMemberRegistrationRequest = (payload = {}) => ({
  type: types.TEAM_MEMBER_REGISTRATION_REQUEST,
  payload,
});

export const teamMemberRegistrationSuccess = (payload = {}) => ({
  type: types.TEAM_MEMBER_REGISTRATION_SUCCESS,
  payload,
});
export const teamMemberRegistrationFailure = (payload = {}) => ({
  type: types.TEAM_MEMBER_REGISTRATION_FAILURE,
  payload,
});

export const resetTeamMemberData = (payload = {}) => ({
  type: types.RESET_TEAM_MEMBER_DATA,
  payload,
});
