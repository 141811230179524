import React, { Component } from 'react';
import { Offer } from 'components/Modals';
import { LENDER, OFFER_STATE } from 'constants/appRelated';
import {
  OfferModalDescription,
  OfferModalLegend,
  OfferModalTabs,
  OfferModalTab,
  OfferModalAdd,
  StateIco,
} from 'styles/offer';
import styled from 'styled-components/macro';

import { ModalBody, ModalHeading, ModalWithContentWrapper } from 'ui/Modal/styles';

const renderTitle = (negotiations, accountType) => {
  if (negotiations) {
    if (accountType === LENDER) {
      return negotiations.length > 1 ? 'My Quotes' : 'My Quote';
    }
    return 'Quote';
  }
  return 'Submit Soft Quote';
};

const renderIcon = (state) => {
  switch (state) {
    case OFFER_STATE.NEGOTIATION:
      return (
        <StateIco
          src="/images/icons/ico_negotiation.svg"
          alt="negotiation"
          title="Negotiation"
        />
      );
    case OFFER_STATE.REJECTED:
      return (
        <StateIco src="/images/icons/ico_close_bold.svg" alt="reject" title="Passed" />
      );
    case OFFER_STATE.ACCEPTED:
      return (
        <StateIco src="/images/icons/ico_check_bold.svg" alt="accept" title="Accepted" />
      );
    case OFFER_STATE.NEW_OFFER:
      return (
        <StateIco
          src="/images/icons/ico_paragraph.svg"
          alt="new quote"
          title="New Quote"
        />
      );
    default:
      return null;
  }
};

export default class OfferModal extends Component {
  constructor(props) {
    super(props);
    const { item, author, id } = props;
    const negotiations =
      item.negotiations[author] &&
      item.negotiations[author].negotiations.filter(
        ({ state }) => state !== OFFER_STATE.CANCELLED
      );
    const hasNegotiations = negotiations && negotiations.length;

    this.state = {
      currentTab: hasNegotiations
        ? negotiations.map((item, i) => item.pk === id && i).filter((i) => i !== false)[0]
        : 0,
      negotiations: hasNegotiations ? negotiations : [false],
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.item) {
      const { author } = this.props;
      const negotiations =
        nextProps.item.negotiations &&
        nextProps.item.negotiations[author] &&
        nextProps.item.negotiations[author].negotiations.filter(
          ({ state }) => state !== OFFER_STATE.CANCELLED
        );
      this.setState({
        currentTab: (negotiations && negotiations.length - 1) || 0,
        negotiations,
      });
    }
  }

  addOffer = () => {
    const { negotiations } = this.state;

    if (negotiations.length < 3) {
      this.setState((prevState) => ({
        negotiations: [...prevState.negotiations, false],
        currentTab: prevState.negotiations.length,
      }));
    }
  };

  render() {
    const { item, author, accountType, forceEdit, afterSubmit, hideModal } = this.props;
    const { currentTab, negotiations } = this.state;
    const canAddOffer =
      accountType === LENDER &&
      negotiations &&
      negotiations[0] &&
      negotiations.length < 3;

    const hasAccepted =
      negotiations &&
      negotiations.filter((negotiation) => negotiation.state === OFFER_STATE.ACCEPTED)
        .length;

    const currentNegotiation = negotiations[currentTab] || false;

    return (
      <StyledModalWithContentWrapper>
        <ModalHeading>
          {renderTitle(negotiations, accountType)}
          {negotiations && negotiations[0] && (
            <OfferModalDescription>
              <OfferModalLegend>
                <img
                  src="/images/icons/ico_negotiation.svg"
                  alt="negotiation"
                  title="Negotiation"
                />
                &nbsp; - Negotiation
              </OfferModalLegend>
              <OfferModalLegend>
                <img src="/images/icons/ico_close_bold.svg" alt="reject" title="Passed" />
                &nbsp; - Passed
              </OfferModalLegend>
              <OfferModalLegend>
                <img
                  src="/images/icons/ico_check_bold.svg"
                  alt="accept"
                  title="Accepted"
                />
                &nbsp; - Accepted
              </OfferModalLegend>
              <OfferModalLegend>
                <img
                  src="/images/icons/ico_paragraph.svg"
                  alt="new offer"
                  title="New Quote"
                />
                &nbsp; - New Quote
              </OfferModalLegend>
            </OfferModalDescription>
          )}
        </ModalHeading>
        <StyledModalBody>
          <OfferModalTabs>
            {negotiations &&
              negotiations.map((negotiation, i) => (
                <OfferModalTab
                  key={i}
                  onClick={() => this.setState({ currentTab: i })}
                  active={currentTab === i}
                >
                  {negotiation && negotiation.state && renderIcon(negotiation.state)}
                  Quote {i + 1}
                </OfferModalTab>
              ))}
            {canAddOffer && !hasAccepted && (
              <OfferModalAdd
                onClick={this.addOffer}
                title="Clicking + will allow you to create up to 3 alternative quotes for the same loan request"
                data-testid="addOfferBtn"
              />
            )}
          </OfferModalTabs>
          <WrapperOffer>
            <Offer
              loan={item}
              negotiation={currentNegotiation}
              accountType={accountType}
              forceEdit={forceEdit}
              hasAccepted={hasAccepted}
              afterSubmit={afterSubmit}
              hideModal={hideModal}
              profile={
                (item.negotiations[author] && item.negotiations[author].profile) || {
                  id: author,
                }
              }
            />
          </WrapperOffer>
        </StyledModalBody>
      </StyledModalWithContentWrapper>
    );
  }
}

const StyledModalBody = styled(ModalBody)`
  position: static;
  background: white;
  padding: 24px 0 !important;
`;

const StyledModalWithContentWrapper = styled(ModalWithContentWrapper)`
  background: white;
`;

const WrapperOffer = styled.div`
  background: #fafafb;
  padding: 0 24px;
`;