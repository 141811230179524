import React from 'react';
import { Form, Field } from 'react-final-form';
import { Input } from 'components/Form';
import styled from 'styled-components/macro';
import { ModalBody, ModalHeading, ModalWithContentWrapper } from 'ui/Modal/styles';
import { Button } from 'ui/Button';
import { updatePasswordNeedRequest } from 'actions';
import Yup from 'yup';
import { validate } from 'utils/validate';
import { ErrorMessage } from 'styles/global';

export const AskPassword = ({ path, dispatch, error }) => (
  <Form
    onSubmit={({ password }) => {
      dispatch(updatePasswordNeedRequest({ password, path }));
    }}
    validate={(values) => validate(values, schema)}
    render={({ handleSubmit }) => (
      <form onSubmit={handleSubmit}>
        <ModalWithContentWrapper>
          <ModalHeading>
            Confirm it's you to change <br />
            2-Factor Authentication settings
          </ModalHeading>
          <ModalBody>
            <Field
              component={Input}
              label="Enter the password here"
              type="password"
              name="password"
            />
            {error && <ErrorMessage>{error}</ErrorMessage>}
            <SubmitButton secondary type="submit">
              Confirm
            </SubmitButton>
          </ModalBody>
        </ModalWithContentWrapper>
      </form>
    )}
  />
);

const schema = Yup.object().shape({
  password: Yup.string()
    .min(8, 'Password must be at least 8 characters')
    .required('Password is required'),
});

const SubmitButton = styled(Button)`
  width: 100%;
`;
