import React, { useState } from 'react';
import styled from 'styled-components/macro';


export const Tag = ({name, handleTags, handleClearTags, isNotClickable}) => (
    <Wrapper onClick={handleTags} isNotClickable={isNotClickable}>
        <span>{name}</span>
        {handleClearTags && <Clear onClick={handleClearTags}>X</Clear>}
    </Wrapper>
);


const Clear = styled.span`
    color:#444444;
    width: 7px;
    height: 7px;
    font-size: 7px;
    margin-left: 8px;
    cursor: pointer;
`;

const Wrapper = styled.div`
    padding: 3px 16px 5px;
    background: rgba(67, 148, 234, 0.1);
    border-radius: 15px;
    //margin-bottom: 10px;
    margin-right: 8px;
    cursor: ${(p) => p.isClear ? 'initial' : 'pointer'};
    &:last-child{
        margin-right: 0;
    }
    span{
        font-size: 12px;
        line-height: 16px;
        color: #1C3353;
    }
`;
