import { schema } from 'normalizr';

export const messageSchema = new schema.Entity('messages', {}, { idAttribute: 'uuid' });
export const messageListSchema = new schema.Array(messageSchema);

export const roomSchema = new schema.Entity('rooms', {}, { idAttribute: 'id' });
export const roomListSchema = new schema.Array(roomSchema);

export const documentTypeSchema = new schema.Entity(
  'documents',
  {},
  { idAttribute: 'value' }
);
export const documentsTypesSchema = new schema.Array(documentTypeSchema);

export const negotiationsSchema = new schema.Entity(
  'negotiations',
  {},
  { idAttribute: 'pk' }
);
export const negotiationsListSchema = new schema.Array(negotiationsSchema);

export const optionsSchema = new schema.Entity('options', {}, { idAttribute: 'value' });
export const optionsListSchema = new schema.Array(optionsSchema);

export const lenderDirectorySchema = new schema.Entity(
  'lenders',
  {},
  { idAttribute: 'lender' }
);

export const lendersDirectoryListSchema = new schema.Array(lenderDirectorySchema);

export const loanSchema = new schema.Entity('loans', {}, { idAttribute: 'id' });
export const loanListSchema = new schema.Array(loanSchema);

export const contactsDirectorySchema = new schema.Entity(
  'contacts',
  {},
  { idAttribute: 'id' }
);

export const contactsDirectoryListSchema = new schema.Array(contactsDirectorySchema);

export const clientsDirectorySchema = new schema.Entity(
  'clients',
  {},
  { idAttribute: 'id' }
);

export const clientsDirectoryListSchema = new schema.Array(clientsDirectorySchema);