import React from 'react';
import { MenuButtonItem } from 'ui/MenuButton';
import { PlusIconBold, CrossIcon, DuplicateIcon, DealRoomIcon } from 'components/SvgIcons';
import config from 'constants/config';

export const ExportStatusReportControl = React.memo(({ handleExportStatusReport }) => (
  <MenuButtonItem
    iconUrl="/images/icons/ico_download_blue_thin.svg"
    onClick={handleExportStatusReport}
  >
    Export Status Report
  </MenuButtonItem>
));
ExportStatusReportControl.displayName = 'ExportStatusReportControl';

export const PrintControl = React.memo(({ loanId }) => (
  <MenuButtonItem iconUrl="/images/icons/ico_print.svg" key="print">
    <a
      href={`/property-details/${loanId}/print`}
      target="_blank"
      rel="noopener noreferrer"
    >
      Print package
    </a>
  </MenuButtonItem>
));
PrintControl.displayName = 'PrintControl';

export const ShareControl = React.memo(({ loanUid, defaultInvitationSubject }) => (
  <MenuButtonItem iconUrl="/images/icons/ico_share_blue.svg">
    <a
      href={`mailto:?subject=${defaultInvitationSubject}&body=I%20have%20a%20new%20loan%20for%20you%20${defaultInvitationSubject}.%20Let%20me%20know%20if%20you%20can%20do%20something%20here.%0D%0A%0D%0AReview%20the%20details%20in%20this%20link%3A%20${config.CLIENT_HOST}/property-details?uuid=${loanUid}%26atu%3D1%20.%0D%0A%0D%0AFYI%2C%20my%20company%20is%20now%20using%20this%20new%20technology%20RealAtom%2C%20created%20specifically%20to%20share%20CRE%20loan%20information.%20We%20like%20it%20because%20it%20keeps%20all%20important%20and%20sensitive%20info%20organized%20and%20super%20secure.%20This%20is%20why%20they%20ask%20you%20to%20register%20and%20create%20your%20log%20in%20and%20password.%20The%20good%20thing%20is%20you%20will%20be%20able%20to%20see%20all%20the%20loan%20documents%20from%20me%20in%20one%20place.`}
      target="_blank"
      rel="noopener noreferrer"
    >
      Share
    </a>
  </MenuButtonItem>
));
ShareControl.displayName = 'ShareControl';

export const AddQuoteControl = React.memo(({ handleOpenManualQuoteModal }) => (
  <MenuButtonItem
    Icon={<PlusIconBold color="#4394EA" />}
    onClick={handleOpenManualQuoteModal}
  >
    Add Quote
  </MenuButtonItem>
));
AddQuoteControl.displayName = 'AddQuoteControl';

export const CloseLoanControl = React.memo(({ handleLoanClose }) => (
  <MenuButtonItem
    Icon={<CrossIcon color="#4394EA" width="15" height="15" />}
    onClick={handleLoanClose}
  >
    Close Loan
  </MenuButtonItem>
));
CloseLoanControl.displayName = 'CloseLoanControl';

export const AssignToControl = React.memo(({ handleOpenAssignLenderModal }) => (
  <MenuButtonItem
    iconUrl="/images/icons/ico_person_blue.svg"
    onClick={handleOpenAssignLenderModal}
  >
    Assign to
  </MenuButtonItem>
));
AssignToControl.displayName = 'AssignToControl';

export const AssignToLenderControl = React.memo(({ handleOpenAssignLenderLoanModal }) => (
  <MenuButtonItem
    iconUrl="/images/icons/ico_person_blue.svg"
    onClick={handleOpenAssignLenderLoanModal}
  >
    Assign to
  </MenuButtonItem>
));
AssignToLenderControl.displayName = 'AssignToLenderControl';

export const DuplicateLoanControl = React.memo(({ handleDuplicateLoan }) => {
  return (
    <MenuButtonItem Icon={<DuplicateIcon />} onClick={handleDuplicateLoan}>
      Duplicate
    </MenuButtonItem>
  );
});
DuplicateLoanControl.displayName = 'DuplicateLoanControl';

export const OpenDealRoom = React.memo(({handleOpenDealRoomConfirmModal}) => (
  <MenuButtonItem
    Icon={<DealRoomIcon />}
    key='OpenDealRoom'
    onClick={handleOpenDealRoomConfirmModal}
  >
    Open deal room
  </MenuButtonItem>
));
OpenDealRoom.displayName = 'OpenDealRoom';