import styled from 'styled-components/macro';

export const SourcesTable = styled.div`
  margin-bottom: 48px;
  &:last-child {
    margin-bottom: 0;
  }
`;

export const SourcesRow = styled.div`
  border-bottom: 1px solid #e8e9ed;
  padding: 16px 0;
  display: flex;
  &:last-child {
    border: none;
  }

  @media (max-width: ${(p) => p.theme.breakpoints[0]}px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const SourcesHeading = styled(SourcesRow)`
  padding: 8px 0;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
`;

export const SourcesCell = styled.div`
  width: 20%;
  text-align: right;
  padding-right: 20px;

  @media (max-width: ${(p) => p.theme.breakpoints[0]}px) {
    text-align: left;
    display: flex;
    line-height: 24px;
  }
`;

export const SourcesSum = styled(SourcesHeading)`
  @media (max-width: ${(p) => p.theme.breakpoints[0]}px) {
    ${SourcesCell} {
      font-size: 14px;
    }
  }
`;

export const SourcesCellWide = styled.div`
  width: 60%;
  font-weight: 600;
`;

export const Total = styled.div`
  color: ${(p) => (p.variant === 'warn' ? '#FF6F57' : '#44af69')};
`;

export const DefaultText = styled.span`
  font-size: 14px;
  font-weight: 400;
`;

export const ValueCell = styled.div`
  display: flex;
  flex: 1;

  ${SourcesCell} {
    width: 50%;
  }

  @media (max-width: ${(p) => p.theme.breakpoints[0]}px) {
    width: 100%;
    justify-content: space-between;
    ${SourcesCell} {
      width: auto;
    }
  }
`;
