import React from 'react';
import { NotificationWrapper } from 'features/Dashboard/styles';

import { DocumentForm } from './DocumentForm';

export const LenderRequiredDocument = ({ uploadFile, document, id }) => (
  <NotificationWrapper>
    <DocumentForm
      requests={[document]}
      onDrop={(file) => {
        uploadFile(file, id);
      }}
    />
  </NotificationWrapper>
);
