import React from 'react';
import styled from 'styled-components/macro';
import { Text } from 'ui/Text';
import { Link } from 'react-router-dom';

export const LoginRequest = ({ hideModal }) => (
  <Wrapper>
    <img src="/images/icons/ico_info_warning.svg" width="65" alt="" />
    <Body>
      <Text>
        Details are available only for verified lenders, please log in to access details.
      </Text>
      <Link to="/login" onClick={hideModal}>
        <LogInBtn>Log In</LogInBtn>
      </Link>
      <Text>
        Don&#39;t Have an account?{' '}
        <CreateBtn to="/registration" onClick={hideModal}>
          Create your account
        </CreateBtn>{' '}
        it takes less than a minute.
      </Text>
    </Body>
  </Wrapper>
);

const Wrapper = styled.div`
  padding: 20px;
  display: flex;
  align-content: flex-start;
`;

const Body = styled.div`
  margin-left: 45px;
  text-align: center;
`;

const LogInBtn = styled.button`
  background-color: #2095f3;
  padding: 15px 0;
  width: 180px;
  margin: 14px 0;
  border-radius: 30px;
  border: none;
  color: #fff;
  font-size: 22px;
  cursor: pointer;
`;

const CreateBtn = styled(Link)`
  padding: 0;
  margin: 0;
  outline: none;
  border: none;
  background-color: #fafafb;
  color: #2095f3;
  text-decoration: underline;
  cursor: pointer;
`;
