import React, { Component } from 'react';
import { connect } from 'react-redux';
import { GoogleSuggest } from 'components/GoogleSuggest';
import { Text } from 'ui/Text';
import styled, { css } from 'styled-components/macro';
import { Field } from 'react-final-form';
import { values } from 'ramda';
import { Input } from 'components/Form';
import { SUGGEST_ERROR_TYPE } from 'components/GoogleSuggest/constants';

import { ManualFieldSet } from './ManualFieldSet';

class GeoSuggest extends Component {
  state = {
    errorMessage: null,
    errorType: null,
    manualFieldSetVisible: !!this.props.isManual,
  };

  componentDidMount() {
    const { isManual } = this.props;

    if (isManual) {
      this.setLatLngNull();
    }
  }

  handleAddress = (location, errorMessage = {}) => {
    const { formFieldChange, fields } = this.props;

    if (location) {
      if (fields.firstAddressLng) {
        formFieldChange(fields.firstAddressLng, location.location.lng);
      }

      if (fields.firstAddressLat) {
        formFieldChange(fields.firstAddressLat, location.location.lat);
      }

      if (fields.city) {
        formFieldChange(fields.city, location.city);
      }

      if (fields.state) {
        formFieldChange(fields.state, location.state);
      }

      if (fields.zip) {
        formFieldChange(fields.zip, location.zip);
      }

      if (fields.addresses) {
        formFieldChange(fields.addresses, location.label);
      }

      this.setState({ errorMessage: null });
    } else {
      if (fields.firstAddressLng) {
        formFieldChange(fields.firstAddressLng, '');
      }
      if (fields.firstAddressLat) {
        formFieldChange(fields.firstAddressLat, '');
      }
      if (fields.city) {
        formFieldChange(fields.city, '');
      }
      if (fields.state) {
        formFieldChange(fields.state, '');
      }
      if (fields.zip) {
        formFieldChange(fields.zip, '');
      }

      if (fields.addresses) {
        formFieldChange(fields.addresses, location === null ? null : '');
      }
    }

    this.setState({ errorMessage: errorMessage.message, errorType: errorMessage.type });
  };

  toggleManualFieldSet = () => {
    this.setState(
      (state) => ({ manualFieldSetVisible: !state.manualFieldSetVisible }),
      () => {
        if (this.state.manualFieldSetVisible) {
          this.setLatLngNull();
        }
      }
    );
  };

  setLatLngNull = () => {
    const { fields, formFieldChange } = this.props;

    if (fields.firstAddressLng) {
      formFieldChange(fields.firstAddressLng, null);
    }

    if (fields.firstAddressLat) {
      formFieldChange(fields.firstAddressLat, null);
    }
  };

  handleStateUpdate = ({ value }) => {
    const { fields, formFieldChange } = this.props;

    if (fields.state) {
      formFieldChange(fields.state, value);
    }
  };

  render() {
    const {
      fields,
      initialValue,
      meta: { touched, error },
      placeholder,
      label,
      dropup,
      isManual,
      stateOptions,
      normalizedStateOptions,
      currentState,
      required,
    } = this.props;
    const { errorMessage, errorType, manualFieldSetVisible } = this.state;
    const hasError = (error && touched) || errorMessage;
    return manualFieldSetVisible ? (
      <>
        <ManualFieldSet
          fields={fields}
          currentState={currentState}
          stateOptions={stateOptions}
          normalizedStateOptions={normalizedStateOptions}
          onStateChange={this.handleStateUpdate}
        />
        <StyledAddressBtn onClick={this.toggleManualFieldSet}>
          Geo suggestion input
        </StyledAddressBtn>
      </>
    ) : (
      <Wrapper dropup={dropup} error={(error && touched) || errorMessage}>
        {label && (
          <Text
            fontSize="0"
            mb={2}
            color={((error && touched) || errorMessage) && 'error'}
            fontFamily="muli"
          >
            {label}
            {required && <Asterix> *</Asterix>}
          </Text>
        )}
        <GoogleSuggest
          pushAddress={this.handleAddress}
          label={initialValue}
          placeholder={placeholder || 'Address'}
        />

        {values(fields).map((field) => (
          <FieldWrapper key={field}>
            <Field name={field} component={Input} />
          </FieldWrapper>
        ))}
        {hasError && (
          <ErrorMessage>
            {errorMessage || error}.{' '}
            {(errorType === SUGGEST_ERROR_TYPE.INVALID_ADDRESS ||
              errorType === SUGGEST_ERROR_TYPE.BLUR_SUGGESTION_LIST) && (
              <AddressBtn onClick={this.toggleManualFieldSet}>
                Direct address input
              </AddressBtn>
            )}
          </ErrorMessage>
        )}
        {!hasError && isManual && (
          <StyledAddressBtn onClick={this.toggleManualFieldSet}>
            Direct address input
          </StyledAddressBtn>
        )}
      </Wrapper>
    );
  }
}

function mapStateToProps(state) {
  return {
    stateOptions: state.configuration.options.stateOptions,
    normalizedStateOptions: state.configuration.normalizedOptions.stateOptions,
  };
}

export default connect(mapStateToProps)(GeoSuggest);

const FieldWrapper = styled.div`
  position: absolute;
  visibility: hidden;
`;

const Wrapper = styled.div`
  position: relative;
  font-size: 14px;

  .geosuggest {
    margin-bottom: 0;
    width: 100%;
  }
  .geosuggest__suggests--hidden {
    display: none;
  }

  .geosuggest.invalid .geosuggest__input-wrapper {
    position: relative;
  }
  .geosuggest__item {
    cursor: pointer;
  }
  .geosuggest__item:hover {
    background-color: #e0e0e0;
  }

  .geosuggest__input {
    width: 100%;
    outline: none;
    border: none;
    border-radius: ${(p) => p.theme.radii[1]}px;

    font-size: 14px;
    padding: 7px 16px 7px;
    border: 1px solid #cfd0d7;
    border-radius: 4px;

    ${({ error }) =>
      error &&
      css`
        border-color: ${(p) => p.theme.colors.error};
      `};

    &:focus {
      border-color: ${(p) => p.theme.colors.blues[6]};
    }
  }

  .geosuggest__input-wrapper {
    z-index: 1;
  }

  .geosuggest__suggests {
    ${({ dropup }) =>
      dropup
        ? css`
            position: absolute;
            bottom: calc(100% + 15px);
          `
        : css`
            position: absolute;
            top: 65px;
          `} list-style:none;
    padding: 0;
    margin: 0;
    border-radius: 4px;
    box-shadow: 0 2px 14px 0 rgba(194, 198, 210, 0.5);
    background: #fff;
    z-index: 10;
  }

  .geosuggest__item {
    position: relative;
    background-color: transparent;
    color: ${(p) => p.theme.colors.black};
    padding: 15px 20px;

    &:hover {
      background: linear-gradient(45deg, #f0f3ff, #effaff);
    }
  }
`;

const ErrorMessage = styled.p`
  color: #ff6f57;
  font-size: 12px;
`;

const AddressBtn = styled.button`
  padding: 0;
  margin: 0;
  outline: none;
  border: none;
  background-color: transparent;
  color: #4394ea;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const StyledAddressBtn = styled(AddressBtn)`
  margin-top: 16px;
`;

const Asterix = styled.span`
  color: #e32019;
`;
