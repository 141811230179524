import React from 'react';
import { DropDown } from 'ui/DropDown';

export const DropdownList = ({
  input,
  meta: { touched, error },
  alertMessage,
  semiRequired,
  ...rest
}) => (
  <DropDown
    error={touched && error ? error : ''}
    semiRequired={semiRequired}
    alertMessage={alertMessage}
    {...rest}
    {...input}
  />
);
