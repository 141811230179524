import React from 'react';
import {
  ModalBody,
  ModalHeading,
  ModalActions,
  modalWithContentWrapperStyles,
} from 'ui/Modal/styles';
import { Button } from 'ui/Button';

import { Form, Field } from 'react-final-form';
import { Input, NewCheckbox } from 'components/Form';
import { duplicateLoanRequest } from 'features/MyLoans/actions';
import styled from 'styled-components/macro';

import Yup from 'yup';
import { validate } from 'utils/validate';

const schema = Yup.object().shape({
  name: Yup.string()
    .required('Loan name is required')
    .max(86, 'Please use shorter loan name'),
});

export const DuplicateLoan = ({ loanId, propertyName, dispatch, hideModal }) => {
  return (
    <Form
      onSubmit={(data) => {
        hideModal();
        dispatch(duplicateLoanRequest({ loanId, data }));
      }}
      validate={(values) => validate(values, schema)}
      initialValues={{
        name: `Duplicate of ${propertyName}`,
        photos: false,
        documents: false,
      }}
      render={({ handleSubmit }) => (
        <FormWrapper onSubmit={handleSubmit}>
          <ModalHeading>Duplicate loan request</ModalHeading>
          <ModalBody>
            <CopyWrapper>
              <Field component={Input} label="Loan name" name="name" />
            </CopyWrapper>
            <CopyWrapper>
              <CopyLabel>Which loan parameters you want to copy</CopyLabel>
              <Field
                component={NewCheckbox}
                label="Copy loan documents"
                name="documents"
                type="checkbox"
              />
              <Field
                component={NewCheckbox}
                label="Copy loan photos"
                name="photos"
                type="checkbox"
              />
            </CopyWrapper>
          </ModalBody>
          <ModalActions>
            <Button secondary type="submit">
              Duplicate
            </Button>
          </ModalActions>
        </FormWrapper>
      )}
    />
  );
};

const FormWrapper = styled.form`
  ${modalWithContentWrapperStyles}
`;

const CopyWrapper = styled.div`
  margin-bottom: 24px;
  border-bottom: 1px solid #e8e9ed;
  &:last-child {
    margin-bottom: 0;
  }
`;

const CopyLabel = styled.p`
  margin-top: 0;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 24px;
`;
