/* eslint-disable  */
const isNan = (value) => (Number.isNaN ? Number.isNaN(value) : value !== value);
/* eslint-enable  */

export const isNumber = (number) => {
  if (number === null || number === undefined) {
    return false;
  }

  if (typeof number === 'number' && !isNan(number)) {
    return true;
  }
  if (typeof number === 'string') {
    return !isNan(Number(number));
  }
  return false;
};
