import { keys } from 'ramda';
import { format } from 'date-fns';
import { CHAT_ROOM_STAGE } from 'constants/appRelated';
import { unitOptions, toggleOptions } from 'utils/fields';
import { normalizeDollars } from 'lib/normalizers';
import { makeSelectItem } from 'lib/loan';
import { wordsRemove } from 'lib/strings';

export const getRoomStageLabel = (stage) => {
  switch (stage) {
    case CHAT_ROOM_STAGE.OFFER:
      return 'Quote';
    case CHAT_ROOM_STAGE.TERM_SHEET:
      return 'Term Sheet';
    case CHAT_ROOM_STAGE.DEAL_ROOM:
      return 'Deal Room';
    default:
      return 'Active';
  }
};

export const getMessageDate = (date, dateFormat = 'MM/dd/yyyy') => {
  const messageDate = date ? new Date(date) : new Date();
  return format(new Date(messageDate), dateFormat);
};

export const groupMessages = (messages, current = {}) => {
  const result = current;

  const messagesKeys = keys(messages);

  messagesKeys.forEach((key) => {
    const messageDay = getMessageDate(messages[key].time);

    if (!result[messageDay]) {
      result[messageDay] = {};
    }

    result[messageDay][key] = messages[key];
  });

  return result;
};

export const mergeMessages = ({ currentGroup, prevGroup }) => {
  const result = {};
  const dateKeys = [...keys(prevGroup), ...keys(currentGroup)];

  dateKeys.forEach((date) => {
    if (currentGroup[date] && prevGroup[date]) {
      result[date] = { ...prevGroup[date], ...currentGroup[date] };
    } else if (currentGroup[date]) {
      result[date] = currentGroup[date];
    } else {
      result[date] = prevGroup[date];
    }
  });

  return result;
};

export const getRoomRedirectionUrl = (url, roomId) => {
  const isRoomPresent = url.match(/\d/g);
  return isRoomPresent
    ? url.replace(/\d+.*$/, roomId)
    : `${url[url.length - 1] === '/' ? url : `${url}/`}${roomId}`;
};

export const makeOfferInitialValues = ({
  offer,
  indexOptions,
  fixedOrVariableOptions,
  recourseOptions,
}) => ({
  loanAmount: normalizeDollars(offer.loanAmount),
  loanTerm: offer.loanTerm,
  loanTermUnit: offer.loanTermUnit ? unitOptions[1] : unitOptions[0],
  interest: offer.interest,
  index: makeSelectItem(offer.index, indexOptions),
  spread: offer.spread,
  fixedOrVariable: makeSelectItem(offer.fixedOrVariable, fixedOrVariableOptions),
  interestOnly: offer.interestOnly,
  interestOnlyUnit: offer.interestOnlyUnit ? unitOptions[1] : unitOptions[0],
  amortization: offer.amortization,
  termExtension: offer.termExtension,
  lenderFee: offer.lenderFee,
  recourse: makeSelectItem(offer.recourse, recourseOptions),
  ltv: offer.ltv,
  ltc: offer.ltc,
  dscr: offer.dscr,
  debtYield: offer.debtYield,
  exitFee: offer.exitFee,
  floor: offer.floor,
  prepayment: offer.prepayment,
  escrowReserves: offer.escrowReserves ? toggleOptions[1] : toggleOptions[0],
});

export const normalizeOfferFormData = (offer) => ({
  loanAmount: wordsRemove(offer.loanAmount) || null,
  loanTerm: offer.loanTerm || null,
  loanTermUnit: offer.loanTermUnit && offer.loanTermUnit.value,
  interest: offer.interest || null,
  index: offer.index && offer.index.value,
  spread: offer.spread || null,
  fixedOrVariable: offer.fixedOrVariable && offer.fixedOrVariable.value,
  interestOnly: offer.interestOnly || null,
  interestOnlyUnit: offer.interestOnlyUnit && offer.interestOnlyUnit.value,
  amortization: offer.amortization || null,
  termExtension: offer.termExtension || null,
  lenderFee: offer.lenderFee || null,
  recourse: offer.recourse && offer.recourse.value,
  ltv: offer.ltv || null,
  ltc: offer.ltc || null,
  dscr: offer.dscr || null,
  debtYield: offer.debtYield || null,
  exitFee: offer.exitFee || null,
  floor: offer.floor || null,
  prepayment: offer.prepayment || null,
  escrowReserves: offer.escrowReserves && offer.escrowReserves.value,
});

export const getChatRoomsUnreadMessages = (rooms) =>
  rooms.reduce((acc, room) => acc + room.unreadMessages, 0);

export const getChatLoansUnreadMessages = (loans) => {
  const rooms = loans.reduce((acc, loan) => [...acc, ...loan.rooms], []);
  return getChatRoomsUnreadMessages(rooms);
};
