import types from 'constants/actionTypes';

export const showChatWidget = (payload = {}) => ({
  type: types.SHOW_CHAT_WIDGET_REQUEST,
  payload,
});

export const showChatWidgetSuccess = (payload = {}) => ({
  type: types.SHOW_CHAT_WIDGET_SUCCESS,
  payload,
});

export const showChatWidgetFailure = (payload = {}) => ({
  type: types.SHOW_CHAT_WIDGET_FAILURE,
  payload,
});

export const hideChatWidget = (payload = {}) => ({
  type: types.HIDE_CHAT_WIDGET,
  payload,
});
