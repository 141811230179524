import styled, { css } from 'styled-components/macro';

import { ReactComponent as Icon } from 'icons/ico_calendar.svg';

export const CalendarIcon = styled(Icon)`
  ${(p) =>
    p.color &&
    css`
      g g {
        fill: ${p.color};
      }
  `}

  ${(p) =>
    p.stroke && 
    css`
      path {
        stroke: ${p.stroke};
      }
  `} 
`;
