import React from 'react';
import { AttachesTitle } from './styles';

export const OfferDocuments = ({ documents }) => (
  <div>
    <AttachesTitle>Attached Files</AttachesTitle>
    <ul>
      {documents.map((file) => (
        <li key={file.id}>
          <a href={file.file} rel="noopener noreferrer" target="_blank">
            {file.filename}
          </a>
        </li>
      ))}
    </ul>
  </div>
);
