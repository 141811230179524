import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {
  ModalBody,
  ModalHeading,
  ModalActions,
  ModalWithContentWrapper,
} from 'ui/Modal/styles';
import { Button } from 'ui/Button';
import { CheckCircle } from 'ui/CheckCircle';
import { Text } from 'ui/Text';
import { FileUpload } from 'components/FileUpload';
import { DocumentRejectionHandler } from 'components/DocumentRejectionHandler';
import { allowedDocTypes } from 'config/allowedDocTypes';
import { saveFilesRequest } from '../../features/DealRoom/actions';
import RequestsSignatureDocs  from '../../features/DealRoom/components/RequestsSignautreDocs';

const DealRoomRequestSignatureModal = ({
  roomId, savedFiles, hideModal, saveFilesRequest}) => {
  
  const [files, setFiles] = useState([]);

  const uploadFile = (newFiles) => {
    setFiles([...files, ...newFiles]);
  }

  const semiRequired = () => {}

  const handleRequestSend = () => {

    const formData = new FormData();

    if (files) {
      files.forEach((file, i) => {
        formData.append(`files[${i}]`, file);
      });
    }
    
    files.forEach((file, i) => {
      formData.append(`names[${i}]`, file.name);
    });

    saveFilesRequest({formData, roomId});

    // remove it
    //saveFilesSuccess([...savedFiles, ...files]);
    hideModal();
  }

  return (
    <ModalWithContentWrapper>
      <ModalHeading style={{padding: '40px 0 0'}}>Upload documents for signing</ModalHeading>
      <ModalBody style={files.length ? {padding: '10px 37px 18px'} : {padding: '10px 37px 41px'} }>
        <DocumentsWrapper>
          {files.length ? <RequestsSignatureDocs modalWindow={true} files={files} setFiles={setFiles}/> : <></> }
        </DocumentsWrapper>

        <DocumentRejectionHandler>
          {({ handleOnDropReject }) => (
            <WrapFileUpload 
              onDrop={uploadFile}
              accept={allowedDocTypes.common}
              onDropRejected={handleOnDropReject}
              multiple
            >
              <UploadWrapper semiRequired={semiRequired} fullHeight={files.length}>
                <PhotoText>
                  Drag and drop files here or <span>browse</span>
                </PhotoText>
              </UploadWrapper>
            </WrapFileUpload>
          )}
        </DocumentRejectionHandler>
      </ModalBody>
      {files.length ? (
        <div style={{textAlign: 'center', marginBottom: '41px'}}>
          <Button
            style={{height: '43px', fontSize: '16px'}}
            secondary 
            onClick={handleRequestSend}>
            Request signature
          </Button>
        </div>) : (<></>)}
    </ModalWithContentWrapper>
  );
};


function mapStateToProps(store) {

  return {
    savedFiles: store.dealRoom.files,
    roomId: store.dealRoom.room.id,
  }
}

const mapDispatchToProps = {
  saveFilesRequest,
  //saveFilesSuccess,
}

export default connect(mapStateToProps, mapDispatchToProps)(DealRoomRequestSignatureModal);

//withRouter(connect({}, mapDispatchToProps)(DealRoomRequestSignatureModal));

const WrapFileUpload = styled(FileUpload)`
  @media (max-width: 768px){
    width: 100%;
  }
  @media (max-width: 425px){
    p{
      margin: 0 30px;
      font-size: 14px;
    }
  }
  
`
const DocumentsWrapper = styled.ul`
  padding-left: 0;

  li{
    width: 97%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    p{
      font-family: Mulish;
      line-height: 16px;
      color: #1C3353;
    }

    div{
      display: flex;
      align-items: center;
      img{
        cursor: pointer;
      }
      //img:first-child{ margin-right: 13px }
    }
  }
`;


const UploadWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  min-height: ${(p) => (p.fullHeight ? '86px' : '198px')};
  border: 1px dashed #778598;
  border-radius: 4px;
`;

const PhotoText = styled(Text)`
  font-size: 16px;
  color: #778598;
  // font-family: Mulish;
  font-style: normal;
  font-weight: normal;
  margin: 0 100px;
  line-height: 20px;
`;


const StyledCheckCircle = styled(CheckCircle)``;
