import types from 'constants/actionTypes';

const initialState = {
  // roomType: null,
  // roomId: null,
  // item: null,
  // author: null,
  // id: null,
  // accountType: null,
  actionItems: [],
  loanId: null,
};

export function loanActivityWidget(state = initialState, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case types.GET_LOAN_ACTIVITY_ITEMS_SUCCESS:
      return {
        ...state,
        actionItems: payload.page
          ? [...state.actionItems, ...payload.notifications]
          : payload.notifications,
        nextActionItems: payload.next,
      };
    case types.SHOW_LOAN_ACTIVITY_WIDGET: 
      return {
        ...state,
        //roomType: payload.roomType,
        loanId: payload.loanId,
        // item: payload.item,
        // author: payload.author,
        // id: payload.id,
        // accountType: payload.accountType,

      };

    case types.HIDE_LOAN_ACTIVITY_WIDGET:
      return initialState;

    default:
      return state;
  }
}
