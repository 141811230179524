import styled from 'styled-components/macro';
import { NavLink } from 'react-router-dom';
import { Button } from 'ui/Button';

export const WrapperForm = styled.form`
  /* height: 100vh; */
`;

export const SubmitButton = styled(Button)`
  width: 100%;
`;

export const RelatedLinksGroup = styled.div`
  padding-top: 32px;
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
`;

export const RelatedLink = styled(NavLink)`
  font-size: 10px;
  line-height: 16xp;
  border-bottom: 2px solid #fff;
  padding-bottom: 8px;
  color: #778598;
  text-decoration: none;
  text-transform: uppercase;
  margin-right: 32px;
  &:last-child {
    margin-right: 0;
  }

  &.active {
    color: #4394ea;
    border-color: #4394ea;
  }
`;

export const Heading = styled.h2`
  font-size: 24px;
  line-height: 28px;
  font-weight: bold;
  margin: 0 0 24px 0;
  text-align: center;
`;

export const ExternalLink = styled.a`
  color: #fd8a42;
  text-decoration: none;
`;

export const SslText = styled.div`
  display: flex;
  align-items: center;
  margin: 24px 0;
  font-size: 12px;
  line-height: 20px;
  color: #44af69;

  svg {
    margin-right: 16px;
    flex-shrink: 0;
  }
`;
