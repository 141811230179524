import { makeLoanTerm } from 'lib/loan';
import { normalizeDollars, normalizeThousands } from 'lib/normalizers';
import { choiceBooleanOptions } from 'utils/fields';
import { FIELD_TYPES } from 'constants/appRelated';

export const getDataByFieldsConfig = ({ data, fields, options, config, extraRule }) => {
  const results = {};
  if (!config) {
    return results;
  }

  const filteredFields = fields.filter((field) => !!config[field]);

  filteredFields.forEach((field) => {
    const [value, fieldConfig] = extraRule
      ? extraRule({ field, data, config })
      : [data[field], config[field]];

    if (value !== undefined && fieldConfig) {
      results[field] = {
        label: fieldConfig?.label,
        value: getDisplayValue({
          field,
          fieldConfig,
          value,
          fieldOptions: options[`${field}Options`],
          durationUnit: data[`${field}Unit`],
        }),
      };
    }
  });

  return results;
};

const getDisplayValue = ({ fieldConfig, value, fieldOptions, durationUnit, field }) => {
  switch (fieldConfig.type) {
    case FIELD_TYPES.CHOICE:
      return fieldOptions?.[value]?.label || 'N/A';

    case FIELD_TYPES.DURATION:
      if (!value) {
        return 'N/A';
      } else {
        return !durationUnit ? makeLoanTerm(value) : `${value} years`;
      }

    case FIELD_TYPES.DOLLARS:
      return value !== undefined && value !== null ? normalizeDollars(value) : 'N/A';

    case FIELD_TYPES.PERCENT:
      return value !== undefined && value !== null ? `${value}%` : 'N/A';

    case FIELD_TYPES.DATE:
      if (!value) {
        return 'N/A';
      } else {
        const dateParts = value.split('-');
        return `${dateParts[1]}/${dateParts[2]}/${dateParts[0]}`;
      }

    case FIELD_TYPES.NUMBER:
      return value !== undefined && value !== null ? normalizeThousands(value) : 'N/A';

    case FIELD_TYPES.TOGGLE:
      return value ? 'Yes' : 'No';

    case FIELD_TYPES.TEXT:
      return value && typeof value === 'string' ? value.replace(/\r?\n|\r/g, ' ') : value;

    case FIELD_TYPES.CHOICE_BOOLEAN:
      return choiceBooleanOptions.find((item) => item.value === value)?.label;

    default:
      return value;
  }
};
