import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { modals } from 'constants/modalTypes';
import { showModal } from 'actions/modalActions';
import { connect } from 'react-redux';
import { Spinner } from 'components/Spinner';
import { setChosenCalendarsRequest, getAuthCalendarsRequest } from '../../actions';
import CalendarItems from './CalendarItems';

const Calendar = ({
  isFetching,
  isInitCalendar,
  isConnect,
  type,
  text,
  img,
  showModal,
  calendarData,
  hideModal,
  setChosenCalendars,
  getAuthCalendars,
  children,
}) => {
  const [calendarsUpdated, setCalendarsUpdated] = useState([]);
  const [showBtn, setShowBtn] = useState(true);

  const handleCloseCalendarSync = (type) => {
    showModal(modals.STOP_SYNCING_MODAL, {
      size: 'sm',
      email: calendarData.email,
      onSubmit: () => {
        getAuthCalendars({ type, logout: true, calendarEnabled: false });
      },
    });
  };

  const handleCheckbox = (target, id) => {
    setShowBtn(true);

    const isExist = !calendarsUpdated.some((item) => item === id);

    if (isExist) {
      setCalendarsUpdated([...calendarsUpdated, id]);
    } else {
      setCalendarsUpdated(calendarsUpdated.filter((item) => item !== id && item));
    }
  };

  const submitForm = (values) => {
    const data = calendarData.calendarList.filter((calendar) => {
      if (values.myCalendar && calendarData.email === calendar.summary)
        return calendar.id;

      for (const [key, value] of Object.entries(values)) {
        if (calendar.summary === key && value) return calendar.id;
      }
    });

    setChosenCalendars({
      type: 'google',
      calendars: {
        calendarsUpdated: data.map((item) => item.id),
        values,
      },
    });
    setShowBtn(false);
    setCalendarsUpdated([]);
    hideModal && hideModal();
  };

  return (
    <Wrapper>
      <ConnectWrap>
        <div>
          <ImgWrap src={img} />
          <Title>{isConnect ? calendarData.email : text}</Title>
        </div>
        {!isInitCalendar && (
          <>
            {isConnect ? (
              <button onClick={() => handleCloseCalendarSync(type)}>Disconnect</button>
            ) : (
              children
            )}
          </>
        )}
      </ConnectWrap>
    </Wrapper>
  );
};

const mapDispatchToProps = {
  showModal,
  setChosenCalendars: setChosenCalendarsRequest,
  getAuthCalendars: getAuthCalendarsRequest,
};

export default connect(null, mapDispatchToProps)(Calendar);

const ConnectWrap = styled.div`
  // border: 1px solid #E8E9ED;
  box-sizing: border-box;
  padding: 20px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 375px) {
    padding: 20px 10px;
  }

  div {
    display: flex;
    align-items: center;
  }

  button {
    border: 1px solid #cfd0d7;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 8px 25px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #1c3353;
    background: #fafafb;
    cursor: pointer;
  }
`;
const ImgWrap = styled.img`
  margin-right: 16px;
`;
const Title = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #1c3353;
`;
const Wrapper = styled.div`
  border: 1px solid #e8e9ed;
  box-sizing: border-box;
  margin-bottom: 16px;
`;
