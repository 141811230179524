import types from 'constants/actionTypes';

export const recoveryLoginRequest = (payload = {}) => ({
  type: types.RECOVERY_LOGIN_REQUEST,
  payload,
});
export const recoveryLoginSuccess = (payload = {}) => ({
  type: types.RECOVERY_LOGIN_SUCCESS,
  payload,
});
export const recoveryLoginFailure = (payload = {}) => ({
  type: types.RECOVERY_LOGIN_FAILURE,
  payload,
});
export const resetRecoveryLogin = (payload = {}) => ({
  type: types.RESET_RECOVERY_LOGIN,
  payload,
});
