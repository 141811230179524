import { put, takeLatest, takeEvery, call } from 'redux-saga/effects';
import { modals, INFO_MODAL_TYPE } from 'constants/modalTypes';
import { showModal } from 'actions/modalActions';
import { decamelizeKeys } from 'humps';
import types from 'constants/actionTypes';
import * as API from 'api';
import { getError } from 'sagas/utils';
import { hideModal } from 'actions/modalActions';

import {
  getLenderCompanyDataSuccess,
  getLenderCompanyDataFailure,
  updateLenderCompanyDataSuccess,
  updateLenderCompanyDataFailure,
  createLenderCompanyTeamSuccess,
  createLenderCompanyTeamFailure,
  updateLenderCompanyTeamSuccess,
  updateLenderCompanyTeamFailure,
  addLenderCompanyMemberSuccess,
  addLenderCompanyMemberFailure,
  updateLenderCompanyMemberSuccess,
  updateLenderCompanyMemberFailure,
  removeLenderCompanyMemberSuccess,
  removeLenderCompanyMemberFailure,
  resendLenderCompanyMemberInvitationSuccess,
  resendLenderCompanyMemberInvitationFailure,
  checkLenderCompanyMemeberEmailSuccess,
  checkLenderCompanyMemeberEmailFailure,
  updateLenderCompanySettingsSuccess,
  updateLenderCompanySettingsFailure,
  assignLoanToLenderSuccess,
  assignLoanToLenderFailure,
  searchLenderCompanyDataFailure,
  searchLenderCompanyDataSuccess,
  getLenderTeamMembersFailure,
  getLenderTeamMembersSuccess,
  getInvitedLendersSuccess, 
  getInvitedLendersFailure
} from './actions';

// /auth/company
function* getLenderTeamMembers(action) {
  try {
    const {
      data: { response },
    } = yield call(API.axiosApi.get, '/auth/team-members');

    yield put(getLenderTeamMembersSuccess(response));
  } catch (e) {
    console.error(e);
    yield put(getLenderTeamMembersFailure({ message: getError(e) }));
  }
}
function* getLenderCompanyData(action) {
  try {
    const {
      data: { response },
    } = yield call(API.axiosApi.get, '/auth/company');

    yield put(getLenderCompanyDataSuccess(response));
  } catch (e) {
    console.error(e);
    yield put(getLenderCompanyDataFailure({ message: getError(e) }));
  }
}

// Change company: `PUT /api/auth/company`. Fields: `name, url, logo, address`
function* updateLenderCompanyData(action) {
  try {
    const {
      data: { response },
    } = yield call(API.axiosApi.put, '/auth/company');

    yield put(updateLenderCompanyDataSuccess(response));
  } catch (e) {
    console.error(e);
    yield put(updateLenderCompanyDataFailure({ message: getError(e) }));
  }
}

// Create team: `POST /api/auth/company/team`. Fields: `name`
function* createLenderCompanyTeam(action) {
  try {
    const { name } = action.payload;
    const {
      data: { response },
    } = yield call(API.axiosApi.post, '/auth/company/team/', { name });
    // { response = [] } Array of teams created
    yield put(createLenderCompanyTeamSuccess({ team: response }));
  } catch (e) {
    console.error(e);
    yield put(createLenderCompanyTeamFailure({ message: getError(e) }));
  }
}

// Edit team: `PUT /auth/company/team/${teamId}`. Fields: `name`.
function* updateLenderCompanyTeam(action) {
  try {
    const { teamId, name } = action.payload;
    yield call(API.axiosApi.put, `/auth/company/team/${teamId}/`, { name });
    yield put(updateLenderCompanyTeamSuccess({ teamId, name }));
  } catch (e) {
    console.error(e);
    yield put(updateLenderCompanyTeamFailure({ message: getError(e) }));
  }
}

// TODO:
// Invitation to company, same as bb endpoint: `POST /api/auth/team`. Fields: `first_name, last_name, email, permissions_level, team_id`
function* addLenderCompanyMember(action) {
  try {
    const { firstName, lastName, email, permissionsLevel, teamId } = action.payload;
    const {
      data: { response },
    } = yield call(API.axiosApi.post, '/auth/team/', {
      ...decamelizeKeys({ firstName, lastName, email, permissionsLevel, team: teamId }),
    });

    yield put(
      addLenderCompanyMemberSuccess({
        user: { ...response, firstName: firstName, lastName: lastName },
        teamId,
      })
    );
  } catch (e) {
    console.error(e);
    yield put(addLenderCompanyMemberFailure({ message: getError(e) }));
  }
}

// Change user rights: `PUT /api/auth/company/member/{user_id}/set_permissions_level/`. Fields: `permissions_level`
function* updateLenderCompanyMember(action) {
  try {
    const { permissionsLevel, userId, teamId } = action.payload;

    yield call(
      API.axiosApi.put,
      `/auth/company/member/${userId}/set_permissions_level/`,
      { permissions_level: permissionsLevel }
    );

    yield put(updateLenderCompanyMemberSuccess({ permissionsLevel, userId, teamId }));
  } catch (e) {
    console.error(e);
    yield put(updateLenderCompanyMemberFailure({ message: getError(e) }));
  }
}

// TODO:
// Resend invitation, same as bb endpoint: `POST /api/auth/team/{membership_id}/resend_invite/`
function* resendLenderCompanyMemberInvitation(action) {
  const { membershipId } = action.payload;

  try {
    yield call(API.axiosApi.post, `/auth/team/${membershipId}/resend-invite/`);

    yield put(
      showModal(modals.INFO_MODAL, {
        size: 'sm',
        infoType: INFO_MODAL_TYPE.SUCCESS,
        title: 'You have successfully resent the invitation',
      })
    );
    yield put(resendLenderCompanyMemberInvitationSuccess());
  } catch (e) {
    yield put(resendLenderCompanyMemberInvitationFailure({ message: getError(e) }));
  }
}

// Deletion from company, same as bb endpoint: `POST /api/auth/team/{membership_id}/remove_member/`
function* removeLenderCompanyMember(action) {
  const { teamId, membershipId } = action.payload;

  try {
    yield call(API.axiosApi.post, `/auth/team/${membershipId}/remove_member/`, {});
    yield put(removeLenderCompanyMemberSuccess({ teamId, membershipId }));
  } catch (e) {
    yield put(removeLenderCompanyMemberFailure({ message: getError(e) }));
  }
}

function* updateLenderCompanySettings(action) {
  try {
    const { form } = action.payload;
    const {
      data: { response },
    } = yield call(API.axiosApi.put, '/auth/company', form);
    yield put(updateLenderCompanySettingsSuccess(response));
  } catch (e) {
    yield put(updateLenderCompanySettingsFailure({ message: getError(e) }));
  }
}

function* checkLenderCompanyMemberEmail(action) {
  const { email, reject, resolve } = action.payload;

  try {
    yield call(
      API.axiosApi.get,
      `/auth/team/check_email/?email=${encodeURIComponent(email)}`
    );
    yield put(checkLenderCompanyMemeberEmailSuccess());
    resolve();
  } catch (e) {
    yield put(checkLenderCompanyMemeberEmailFailure());
    if (e.response) {
      reject(new Error(e.response.data?.error?.email?.[0] || 'Email could not be used'));
    } else {
      console.log(e);
      reject(new Error('Email could not be used'));
    }
  }
}

function* getInvitedLendersRequest(action) {
  try {
    const { loanId } = action.payload;
    const {
      data: { response },
    } = yield call(API.axiosApi.get, `/loans/${loanId}/colleagues`);
    yield put(getInvitedLendersSuccess(response));
  } catch (e) {
    console.error(e);
    yield put(getInvitedLendersFailure({ message: getError(e) }));
  }
}
  
function* assignLoanToLender(action) {
  try {
    const { loanId, invitedLenders, chosenLenders } = action.payload;

    const {
      data: { response },
    } = yield call(API.axiosApi.post, `/loans/${loanId}/share`, {teammates: invitedLenders});
    
    //yield put(getInvitedLendersRequest({payload:loanId}));
    yield put(assignLoanToLenderSuccess(response));
    yield put(hideModal());

  } catch (e) {
    yield put(assignLoanToLenderFailure({ message: getError(e) }));
  }
}

function* searchLenderCompaniesBy(action) {
  try {
    const {
      data: { response },
    } = yield call(API.axiosApi.get, `/auth/company/team/?search=${action.payload}`);
    yield put(searchLenderCompanyDataSuccess(response));
  } 
  catch (e) {
   console.error(e);
   yield put(searchLenderCompanyDataFailure({ message: getError(e) }));
  }
}

export default [
  takeLatest(types.GET_INVITED_LENDERS_REQUEST, getInvitedLendersRequest),
  takeLatest(types.GET_LENDER_TEAM_MEMBERS_REQUEST, getLenderTeamMembers),
  takeLatest(types.GET_LENDER_COMPANY_DATA_REQUEST, getLenderCompanyData),
  takeLatest(types.UPDATE_LENDER_COMPANY_DATA_REQUEST, updateLenderCompanyData),
  takeLatest(types.CREATE_LENDER_COMPANY_TEAM_REQUEST, createLenderCompanyTeam),
  takeLatest(types.UPDATE_LENDER_COMPANY_TEAM_REQUEST, updateLenderCompanyTeam),
  takeLatest(types.ADD_LENDER_COMPANY_MEMBER_REQUEST, addLenderCompanyMember),
  takeLatest(types.UPDATE_LENDER_COMPANY_MEMBER_REQUEST, updateLenderCompanyMember),
  takeLatest(types.REMOVE_LENDER_COMPANY_MEMBER_REQUEST, removeLenderCompanyMember),
  takeLatest(types.SEARCH_LENDER_COMPANIES_REQUEST, searchLenderCompaniesBy),
  takeLatest(
    types.RESEND_LENDER_COMPANY_MEMBER_INVITATION_REQUEST,
    resendLenderCompanyMemberInvitation
  ),
  takeLatest(types.UPDATE_LENDER_COMPANY_SETTINGS_REQUEST, updateLenderCompanySettings),
  takeEvery(
    types.CHECK_LENDER_COMPANY_MEMBER_EMAIL_REQUEST,
    checkLenderCompanyMemberEmail
  ),
  takeEvery(types.ASSIGN_LOAN_TO_LENDER_REQUEST, assignLoanToLender),
];
