import React from 'react';
import { Section, SectionTitle, SectionHead, EditLink } from 'styles/loan';
import { fieldsConfig } from 'config/fieldsConfig';
import styled from 'styled-components/macro';
import { bluredContent } from 'features/LoanNew/constants';
import { FieldSet } from 'components/Loans/FieldSet';
import { Field } from 'components/Loans/Field';
import PropTypes from 'prop-types';
import { BlurContent } from 'components/BlurContent';
import { FieldEdited } from 'features/LoanNew/components';

export const SponsorProfile = React.forwardRef(
  (
    { data, fields, normalizedOptions, story, access, id, editable, fieldsHistory },
    ref
  ) => {
    const isBlured = access.preview || access.limited;

    return (
      <Section ref={ref}>
        {editable && <EditLink to={`/dashboard/loan-creation/${id}/sponsor`} />}
        <SectionHead>
          <SectionTitle>Sponsor Profile</SectionTitle>
        </SectionHead>
        <BlurContent blured={isBlured} height={80}>
          {isBlured ? (
            bluredContent.sponsorProfile
          ) : (
            <>
              <FieldSet>
                {fields.map((field) => (
                  <Field
                    key={field}
                    item={fieldsConfig.sponsorProfile[field]}
                    loan={data}
                    fieldTest={field}
                    normalizedOptions={normalizedOptions[`${field}Options`]}
                    FieldEdited={
                      fieldsHistory[field] && (
                        <FieldEdited sponsorProfileField fieldName={field} />
                      )
                    }
                  />
                ))}
              </FieldSet>
              {story && (
                <div>
                  <Label>Sponsor Story</Label>
                  <StoryWrapper>{story}</StoryWrapper>
                  {fieldsHistory.story && (
                    <FieldEdited sponsorProfileField fieldName="story" />
                  )}
                </div>
              )}
            </>
          )}
        </BlurContent>
      </Section>
    );
  }
);

SponsorProfile.displayName = 'Sponsor Profile';
SponsorProfile.propTypes = {
  data: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string]))
    .isRequired,
  fields: PropTypes.arrayOf(PropTypes.string).isRequired,
  normalizedOptions: PropTypes.objectOf(PropTypes.object).isRequired,
  story: PropTypes.string,
  access: PropTypes.objectOf(PropTypes.bool),
  id: PropTypes.number,
  editable: PropTypes.bool,
};

const Label = styled.div`
  font-weight: 600;
  margin-bottom: 12px;
`;

const StoryWrapper = styled.div`
  white-space: pre-wrap;
`;
