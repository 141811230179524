import React from 'react';
import styled from 'styled-components';
import { FileUpload } from 'components/FileUpload';
import { AttachVerticalIcon } from 'components/SvgIcons';
import { ReactComponent as RemoveIcon } from 'icons/ico_select_item_remove.svg';

export const FileAttachment = ({ onDrop, document, removeDocument }) =>
  document ? (
    <Label>
      <AttachVerticalIcon color="#778598" />
      <span>{document.name}</span>
      <DltBtn type="button" onClick={removeDocument}>
        <RemoveIcon />
      </DltBtn>
    </Label>
  ) : (
    <FileUpload
      onDrop={([file]) => {
        onDrop(file);
      }}
    >
      <Label>
        <AttachVerticalIcon color="#778598" />
        <span>Attach file</span>
      </Label>
    </FileUpload>
  );

const Label = styled.div`
  display: flex;
  align-items: center;
  padding-top: 8px;
  cursor: pointer;

  span {
    margin-left: 8px;
    font-size: 12px;
    line-height: 16px;
    word-break: break-word;
  }
`;

const DltBtn = styled.button`
  padding: 0 4px;
  margin: 0;
  border: none;
  background-color: #fafafb;
  cursor: pointer;
`;
