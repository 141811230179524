import React from 'react';
import { LENDER } from 'constants/appRelated';
import { OfferHeading, OfferHeadingTitle } from 'styles/offer';

export const OfferTitle = ({ negotiation, accountType, edit }) => {
  const isLender = accountType === LENDER;

  let leftTitle;
  let rightTitle;

  if (edit) {
    rightTitle = isLender ? 'My Quote' : 'My counteroffer';
    if (!negotiation) {
      leftTitle = 'Loan Request';
    } else if (negotiation.offers.length === 1) {
      leftTitle = isLender ? 'Loan Request' : 'Lender quote';
    } else {
      leftTitle = isLender ? 'Client’s counteroffer' : 'Lender quote';
    }
  } else if (negotiation && negotiation.offers.length > 1) {
    rightTitle = isLender ? 'My Quote' : 'My counteroffer';
    leftTitle = isLender ? 'Client’s counteroffer' : 'Lender quote';
  } else {
    rightTitle = isLender ? 'My Quote' : 'Lender quote';
    leftTitle = 'Loan Request';
  }

  return (
    <OfferHeading>
      <OfferHeadingTitle><span>{leftTitle}</span></OfferHeadingTitle>
      <OfferHeadingTitle><span>{rightTitle}</span></OfferHeadingTitle>
    </OfferHeading>
  );
};
