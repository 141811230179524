export const asyncActions = [
  'DASHBOARD_NOTIFICATIONS',
  'NOTIFICATION_ANSWER_QUESTION',
  'NOTIFICATION_UPLOAD_DOCUMENT',
  'NOTIFICATION_UPDATE_DOCUMENT_PROTECTION',
  'GET_MORE_DASHBOARD_ACTION_ITEMS',
  'FILTER_DASHBOARD_ACTION_ITEMS',
  'REMOVE_NOTIFICATION',
  'ANSWER_LENDER_COMMENT',
  'EMAIL_CONFIRMATION',
  'UPLOAD_LENDER_DOCUMENT',
  'UPDATE_USER_DASHBOARD_FEED_TYPE',
  'SIGN_CA_AND_OPEN_DAI_DOC',
];
export const syncActions = [
  'UPDATE_ACTION_ITEMS_FILTERS',
  'SET_USER_FEED_TYPE',
  'SET_TEAMMATE_ACTION_ITEMS',
];
