import types from 'constants/actionTypes';

export const myOffersRequest = (payload = {}) => ({
  type: types.MY_OFFERS_REQUEST,
  payload,
});
export const myOffersSuccess = (payload = {}) => ({
  type: types.MY_OFFERS_SUCCESS,
  payload,
});
export const myOffersFailure = (payload = {}) => ({
  type: types.MY_OFFERS_FAILURE,
  payload,
});
