import types from 'constants/actionTypes';

export const passwordResetRequest = (payload = {}) => ({
  type: types.PASSWORD_RESET_REQUEST,
  payload,
});
export const passwordResetSuccess = (payload = {}) => ({
  type: types.PASSWORD_RESET_SUCCESS,
  payload,
});
export const passwordResetFailure = (payload = {}) => ({
  type: types.PASSWORD_RESET_FAILURE,
  payload,
});
