import React, { useEffect, useState } from 'react';
import { useDispatch, connect } from 'react-redux';
import { modals } from 'constants/modalTypes';
import { showModal } from 'actions/modalActions';
import { Button, ButtonGhost } from 'ui/Button';
import { hideModal } from 'actions/modalActions';
import {
    ModalBody,
    ModalHeading,
    ModalActions,
    modalWithContentWrapperStyles,
  } from 'ui/Modal/styles';
import styled from 'styled-components/macro';
import TRASH_GREY from '../../../icons/ico_trash_grey.svg';
import { editTaskRequest } from 'containers/MyTasksWrapper/actions';
import { 
  removeTaskRequest, 
  completeTaskRequest, 
  seenTaskRequest, 
  getTaskIdRequest 
} from 'containers/MyTasksWrapper/actions';
import { format } from 'date-fns';
import { parseDueDate } from 'lib/date/parseDueDate';
import { Spinner } from 'components/Spinner';
import { createLoadingSelector, getRoughRequestAction } from 'lib/state';
import { PAGES_REGEXP_PATH } from 'constants/appRelated';
import { history } from 'store';
import { test } from 'ramda';

const InfoRow = ({
  dangerouslySetInnerHTML, 
  isHighlightValue, 
  taskProperty, 
  taskValue, 
  taskLabel, 
  link, 
  isTextarea
}) => {
  return (
    <InfoRowWrapper>
        <TaskProperty>{taskLabel}</TaskProperty>
        {isHighlightValue ?
          link ? 
            <TaskValue 
              style={taskProperty === 'taskDetails' ? {whiteSpace: 'pre-wrap'} : {}} 
              isTextarea
            >
              <a href={link} dangerouslySetInnerHTML={dangerouslySetInnerHTML}/>
            </TaskValue>
            :
            <TaskValue 
              style={taskProperty === 'taskDetails' ? {whiteSpace: 'pre-wrap'} : {}} 
              isTextarea
              dangerouslySetInnerHTML={dangerouslySetInnerHTML}
            />
        : 
        <TaskValue 
          style={taskProperty === 'taskDetails' ? {whiteSpace: 'pre-wrap'} : {}} 
          isTextarea
        >
          {link ? <a href={link}>{taskValue}</a>  : taskValue}
        </TaskValue>
        }
    </InfoRowWrapper>
  )
}

const ReviewTask = ({
  isFetching, 
  task, 
  isLoanPage, 
  currentUserId,  
  displayVariant, 
  displayFormat, 
  pathname
}) => {
    const dispatch = useDispatch();

    useEffect(() => {
      /* openning modal window by link */
      if (test(PAGES_REGEXP_PATH.TASK_MODAL_REVIEW, pathname)) {
        const url = pathname.split('/');
        dispatch(getTaskIdRequest({id: url[url?.length - 1]}));
      }
      if(task && !task.seen && displayVariant !== displayFormat?.createdByMe){
        dispatch(seenTaskRequest(task.id));
      }
    }, []);

    useEffect(() => {
      /* reset link for modal window after openning */
      if (test(PAGES_REGEXP_PATH.TASK_MODAL_REVIEW, pathname)) {
        history.push('/dashboard')
      }
    }, [task])

    const openEditTaskModal = () => {
      dispatch(
        showModal(modals.CURRENT_TASK_MODAL, {
          size: 'sm',
          task,
          isLoanPage,
          overflow: 'overflowInit',
          submit: (data) => {
            dispatch(editTaskRequest({...data}));
            dispatch(hideModal());
          },
        })
      );
    };

    const setCompleteOnTask = () => {
      dispatch(completeTaskRequest(task.id));
      dispatch(hideModal());
    };

    const openRemoveTaskModal = () => {
      dispatch(
          showModal(modals.REMOVE_TASK_MODAL, {
            size: 'sm',
            task,
            remove: () => {
              dispatch(removeTaskRequest(task.id));
              dispatch(hideModal());
            },
            cancel: () => {
              dispatch(hideModal());
            }
          })
      );
    }

    if(isFetching){
      return <Spinner show />;
    }

    return task.name ? (
      <ReviewTasksWrapper>
        {task.highlights?.name ? 
          <ModalHeadingExtended dangerouslySetInnerHTML={{__html: task.highlights.name}}/> 
        :
          <ModalHeadingExtended>{task.name}</ModalHeadingExtended>         
        }
        <ModalBodyExtended>
          <CreatedAt>{`Created by ${task.owner.fullname} on ${format(new Date(task.created), 'MM/dd/yyyy')}`}</CreatedAt>
          <InfoRow 
            taskLabel={'Assigned to:'} 
            taskProperty={'AssignedTo'} 
            taskValue={task.assigned?.map((user) => ' ' + user.fullname).toString()}
          />
          <InfoRow 
            taskLabel={'Due date:'} 
            taskProperty={'dueDate'} 
            taskValue={task.dueDate ? parseDueDate(task.dueDate, 'MM/dd/yyyy') : ''}
          />
          <Line/>
          <InfoRow 
            taskLabel={'Task details:'} 
            isTextarea={true} 
            taskProperty={'taskDetails'}
            taskValue={task.description} 
            isHighlightValue={task.highlights?.description}
            dangerouslySetInnerHTML={{__html: task.highlights?.description}}
          />
          <Line/>
          <InfoRow 
            taskLabel={'Linked loan:'} 
            taskProperty={'linkedLoan'} 
            link={`/dashboard/property-details/${task.loan?.uuid}`}
            taskValue={task.loan?.propertyName}
            isHighlightValue={task.highlights?.loanPropertyName}
            dangerouslySetInnerHTML={{__html: task.highlights?.loanPropertyName}}
          />
          <Line/>
        </ModalBodyExtended>

        <ModalActions style={{justifyContent: 'flex-start'}}>
          <ButtonExtended onClick={setCompleteOnTask}
            type="submit"
            secondary
          >
            {'Mark as completed'}
          </ButtonExtended>
          
          <ButtonGhost type="button" onClick={openEditTaskModal}>
              {'Edit'}
          </ButtonGhost>

          {currentUserId === task.owner.id &&  
          <ButtonGhostExtended type="button" onClick={openRemoveTaskModal}>
              <img style={{width: '14px', height: '15px'}} src={TRASH_GREY}/>
          </ButtonGhostExtended>}
        </ModalActions>
      </ReviewTasksWrapper>
    ) : (<></>);
}

const loadingSelector = createLoadingSelector([
  getRoughRequestAction(getTaskIdRequest().type),
]);

function mapStateToProps(state) {
  return {
    currentUserId: state.currentUser.id,
    task: state.myTasks.currentTask,
    isFetching: loadingSelector(state),
  };
}

export default connect(mapStateToProps)(ReviewTask);

const ModalHeadingExtended = styled(ModalHeading)`
    text-align: start;
    padding: 50px 24px 8px !important;    
`;

const ButtonGhostExtended = styled(ButtonGhost)`
    margin-left: auto;
`;

const ButtonExtended = styled(Button)`
    margin-right: 17px;
`;

const ModalBodyExtended = styled(ModalBody)`
    padding-top: 0 !important;
    padding-bottom: 0 !important;
`;

const ReviewTasksWrapper = styled.div`
    ${modalWithContentWrapperStyles};
`;

const InfoRowWrapper = styled.div`
    padding-bottom: 16px;
    display: flex;
    align-items: center;
`;

const Line = styled.div`
    height: 1px;
    width: 100%;
    background: #E8E9ED;
    margin-bottom: 16px;
`;

const CreatedAt = styled.p`
    font-size: 12px;
    line-height: 16px;
    color: #778598;
    margin-bottom: 24px;
    margin-top: 0;
`;


const TaskProperty = styled.span`
    display: block;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #1C3353;
    margin-right: 23px;
    width: 20%; 
`;

const TaskValue = styled.span`
    display: block;
    font-size: 14px;
    color: #1C3353;
    width: ${(p) => (p.isTextarea ?  '60%' : 'auto')};
    line-height: ${(p) => (p.isTextarea ?  '21px' : '16px' )};
`;