import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { CHART_BOXPLOT_CONFIGURATION } from 'features/Analytics/config';

// const barWidth = CHART_BOXPLOT_CONFIGURATION.BAR_WIDTH;

export const BoxplotItem = ({
  index,
  data = [],
  maxValue,
  heightIndex,
  outliers,
  setData,
  active,
  boxHoverColor,
  trackBoxplot,
  title,
  barWidth = CHART_BOXPLOT_CONFIGURATION.BAR_WIDTH,
  x,
}) => {
  const min = data[0];
  const q1 = data[1];
  const q2 = data[2];
  const q3 = data[3];
  const max = data[4];

  const itemMargin = 4;
  const itemWidth = barWidth - itemMargin;

  const topLineY = (maxValue - max) * heightIndex;

  const boxHeight = (q3 - q1) * heightIndex;
  const boxOffset = (maxValue - q3) * heightIndex;
  const boxWidth = barWidth - itemMargin * 2;

  const bottomLineY = (maxValue - min) * heightIndex;

  const midLineY = (maxValue - q2) * heightIndex;

  const centerLineX = barWidth / 2;

  return (
    <BoxPlotItemWrapper
      // x position + margin left (Chart left offset) - a half of a bar
      transform={`translate(${x +
        CHART_BOXPLOT_CONFIGURATION.CHART_MARGIN_LEFT -
        barWidth / 2},0)`}
      onMouseEnter={(e) => {
        const rect = e.currentTarget.querySelector('.box').getBoundingClientRect();
        trackBoxplot(title);
        setData({
          left: rect.x + rect.width / 2 + 15,
          top: rect.y + window.scrollY + rect.height / 2,
          index,
        });
      }}
      active={active}
      boxHoverColor={boxHoverColor}
    >
      <g className="boxplot">
        {/* top line */}
        <line
          className="line"
          x1={itemMargin}
          y1={bottomLineY}
          x2={itemWidth}
          y2={bottomLineY}
        />
        {/* bottom line */}
        <line
          className="line"
          x1={itemMargin}
          y1={topLineY}
          x2={itemWidth}
          y2={topLineY}
        />

        {/* center line */}
        <line
          className="line"
          x1={centerLineX}
          y1={topLineY}
          x2={centerLineX}
          y2={bottomLineY}
        />

        <rect
          x={itemMargin}
          y={boxOffset}
          width={boxWidth}
          height={boxHeight}
          className="box"
        />

        {/* midline line */}
        <line className="median" x1="5" y1={midLineY} x2={itemWidth} y2={midLineY} />
      </g>

      {outliers.map((item, i) => {
        const outlierOffset = (maxValue - item) * heightIndex;
        return (
          <circle className="circle" key={i} cx={centerLineX} cy={outlierOffset} r="4" />
        );
      })}
    </BoxPlotItemWrapper>
  );
};

BoxplotItem.propTypes = {
  index: PropTypes.number.isRequired,
  data: PropTypes.arrayOf(PropTypes.array).isRequired,
  maxValue: PropTypes.number.isRequired,
  heightIndex: PropTypes.number.isRequired,
  outliers: PropTypes.arrayOf(PropTypes.array).isRequired,
  setData: PropTypes.func.isRequired,
  active: PropTypes.bool,
  boxHoverColor: PropTypes.string,
  trackBoxplot: PropTypes.func,
  title: PropTypes.string,
};

const BoxPlotItemWrapper = styled.g`
  ${(p) =>
    p.active &&
    css`
      .box,
      .circle {
        fill: ${(p) => p.boxHoverColor} !important;
      }
    `}
`;
