import React from 'react';

export const Eye = ({ fill }) => (
  <svg
    width="20px"
    height="16px"
    viewBox="0 0 20 16"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="design-system" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Atoms" transform="translate(-529.000000, -2760.000000)" fill={fill}>
        <g id="Group-5" transform="translate(519.000000, 2748.000000)">
          <g id="add">
            <g id="Group-7" transform="translate(10.000000, 11.000000)">
              <g
                id="Group-12"
                transform="translate(0.000000, 3.000000)"
                fillRule="nonzero"
              >
                <path
                  d="M2,6 C3.59687053,8.98900685 6.58154172,11 10,11 C13.4184583,11 16.4031295,8.98900685 18,6 C16.4031295,3.01099315 13.4184583,1 10,1 C6.58154172,1 3.59687053,3.01099315 2,6 Z M1.11798222,5.52878388 C2.92691119,2.14284807 6.28427882,0 10,0 C13.7157212,0 17.0730888,2.14284807 18.8820178,5.52878388 L19.133764,6 L18.8820178,6.47121612 C17.0730888,9.85715193 13.7157212,12 10,12 C6.28427882,12 2.92691119,9.85715193 1.11798222,6.47121612 L0.866236012,6 L1.11798222,5.52878388 Z"
                  id="Combined-Shape"
                />
                <path
                  d="M10,9 C11.6568542,9 13,7.65685425 13,6 C13,4.34314575 11.6568542,3 10,3 C8.34314575,3 7,4.34314575 7,6 C7,7.65685425 8.34314575,9 10,9 Z M10,10 C7.790861,10 6,8.209139 6,6 C6,3.790861 7.790861,2 10,2 C12.209139,2 14,3.790861 14,6 C14,8.209139 12.209139,10 10,10 Z"
                  id="Oval-3"
                />
                <path
                  d="M11.5,5 C11.7761424,5 12,4.77614237 12,4.5 C12,4.22385763 11.7761424,4 11.5,4 C11.2238576,4 11,4.22385763 11,4.5 C11,4.77614237 11.2238576,5 11.5,5 Z M11.5,6 C10.6715729,6 10,5.32842712 10,4.5 C10,3.67157288 10.6715729,3 11.5,3 C12.3284271,3 13,3.67157288 13,4.5 C13,5.32842712 12.3284271,6 11.5,6 Z"
                  id="Oval-6"
                />
              </g>
              <rect
                id="Rectangle-10"
                transform="translate(10.500000, 9.000000) rotate(-330.000000) translate(-10.500000, -9.000000) "
                x="10"
                y="0"
                width="1"
                height="18"
                rx="0.5"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
