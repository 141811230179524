import React from 'react';
import styled from 'styled-components/macro';

export const DocumentItem = ({
  id,
  folderId,
  sectionId,
  name,
  url,
  canDownloadDocument,
  standAlone,
  openConfidentialityAgreementModal,
  protectedDocument,
  performActionOnDocument,
  highlightsDoc,
}) => {
  let clickHandler = null;

  if (standAlone) {
    clickHandler = performActionOnDocument;
  } else if (!url && protectedDocument) {
    clickHandler = () => {
      openConfidentialityAgreementModal({ documentId: id, folderId, sectionId });
    };
  }

  if(highlightsDoc?.name === name){
    return (
      <Wrapper
        key={id}
        target="_blank"
        disabled={!canDownloadDocument}
        rel="noopener noreferrer"
        href={standAlone || !canDownloadDocument || !url ? null : url}
        onClick={clickHandler}
        dangerouslySetInnerHTML={{__html: highlightsDoc.highlights.name}}
      />
    );
  }else{
    return (
      <Wrapper
        key={id}
        target="_blank"
        disabled={!canDownloadDocument}
        rel="noopener noreferrer"
        href={standAlone || !canDownloadDocument || !url ? null : url}
        onClick={clickHandler}
      >
        {name}
      </Wrapper>
    );
  }
};

const Wrapper = styled.a`
  text-decoration: none;
  padding: 16px 24px 16px 0;
  border-bottom: 1px solid #e8e9ed;
  display: flex;
  align-items: center;
  background: url('/images/icons/ico_view.svg') no-repeat right center;
  cursor: pointer;
  color: #1c3353;

  word-break: break-word;
  &:disabled {
    cursor: not-allowed;
  }

  &:before {
    content: '';
    flex-shrink: 0;
    background: url('/images/icons/ico_file.svg');
    width: 36px;
    height: 40px;
    display: block;
    margin-right: 12px;
  }
`;
