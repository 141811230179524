import React from 'react';
import { LENDER } from 'constants/appRelated';
import styled from 'styled-components/macro';
import { useDispatch } from 'react-redux';
import { showModal } from 'actions/modalActions';
import { modals } from 'constants/modalTypes';
import { createDealRoomWithDocumentsRequest } from 'features/DealRoom/actions';

import User from './User';
import { DocumentPrivacyForm } from './DocumentPrivacyForm';

export const RequestedDocuments = ({
  lender,
  requests,
  uploadDocument,
  notificationId,
  loanId,
  updateDocumentProtectionState,
}) => {
  const dispatch = useDispatch();
  const openCreatDealRoomModal = () => {
    dispatch(
      showModal(modals.INFO_MODAL, {
        size: 'sm',
        title: `Do you want to create a deal room for ${lender.firstName} ${lender.lastName} from ${lender.businessName}`,
        text:
          'When you create a deal room with a lender you can send some documents to them privately',
        buttonRejectText: 'Cancel',
        buttonText: 'Create deal room',
        onButtonClick: () => {
          dispatch(
            createDealRoomWithDocumentsRequest({
              documents: requests.map(({ id }) => id),
            })
          );
        },
      })
    );
  };
  return (
    <div data-testid="requestedDocumentsItemWrapper">
      <User key={lender.pk} user={{ ...lender, accountType: LENDER }} />
      <DocumentPrivacyForm
        uploadDocument={uploadDocument}
        updateDocumentProtectionState={updateDocumentProtectionState}
        notificationId={notificationId}
        loanId={loanId}
        requests={requests}
      />
      {!!requests.filter(({ uploaded }) => !uploaded).length && (
        <ShareDocumentsBtn onClick={openCreatDealRoomModal}>
          SHARE DOCUMENTS PRIVATELY
        </ShareDocumentsBtn>
      )}
    </div>
  );
};

const ShareDocumentsBtn = styled.button`
  display: flex;
  align-items: center;
  font-size: 10px;
  color: #4394ea;
  cursor: pointer;
  background-color: #fff;
  border: none;
  outline: none;

  &::before {
    content: url('/images/icons/ico_document_protected.svg');
    margin-right: 5px;
  }
`;
