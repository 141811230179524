export const asyncActions = [
  'SUBMIT_MANUAL_TERMSHEET',
  'EDIT_MANUAL_TERMSHEET',
  'DELETE_MANUAL_TERMSHEET',
  'RESTORE_MANUAL_TERMSHEET',
  'GET_LOAN_TERMSHEETS',
  'GET_LENDER_LOAN_TERMSHEETS',
  'UPLOAD_LENDER_TERMSHEET',
];

export const syncActions = ['RESET_TERMSHEETS'];
