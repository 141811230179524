import React from 'react';
import styled from 'styled-components/macro';
import { Button } from 'ui/Button';

export const DocumentsEmpty = ({ requestDocuments, canRequestDocuments, standAlone }) => (
  <Wrapper>
    <img src="/images/icons/ico_documents_round.svg" alt="" />
    {canRequestDocuments || standAlone ? (
      <>
        <Title>No documents were uploaded</Title>
        <Description>Client didn't upload any documents</Description>
        <Button secondary onClick={requestDocuments} disabled={!canRequestDocuments}>
          Request documents
        </Button>
      </>
    ) : (
      <Title>No documents available</Title>
    )}
  </Wrapper>
);

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 312px;
  width: 100%;
  margin: 0 auto;
`;

const Title = styled.span`
  margin-top: 16px;
  font-weight: 600;
  font-size: 16px;
`;

const Description = styled.p`
  color: #778598;
`;
