import React from 'react';
import { Text } from 'ui/Text';

export const MobilePhoneFieldHint = ({ visible }) => {
  if (visible) {
    return (
      <Text fontSize="12px" mt="-20px" mb="24px" color="#787878">
        We will send you only important notifications
      </Text>
    );
  }

  return null;
};
