import React from 'react';
import styled from 'styled-components';
import { Text } from 'ui/Text';
import { UserAvatar } from 'components/UserAvatar';
import { LENDER } from 'constants/appRelated';
import { Field } from 'react-final-form';
import { DropdownList } from 'components/Form';

const LenderSelectItem = ({ selectOption, data, data: { lender } }) => (
  <LenderItem
    onClick={() => {
      selectOption(data);
    }}
  >
    <Avatar>
      <UserAvatar author={{ ...lender, accountType: LENDER }} />
    </Avatar>
    <Label>{`${lender.firstName} ${lender.lastName}`}</Label>
    <CompanyLabel>({lender.companyName})</CompanyLabel>
  </LenderItem>
);

export const LenderSelect = ({ lendersList }) => {
  const lendersOptions = lendersList.map((lender) => ({
    label: `${lender.firstName} ${lender.lastName}`,
    lender,
  }));
  return (
    <Wrapper>
      <Field
        name="selectLender"
        component={DropdownList}
        options={lendersOptions}
        filterOption={filterOptionsHandler}
        components={{
          Option: LenderSelectItem,
        }}
      />
    </Wrapper>
  );
};

const filterOptionsHandler = ({ data: { lender } }, input) => {
  if (!input) return true;

  return [lender.firstName, lender.lastName, lender.companyName].reduce(
    (acc, string) => string.toLowerCase().includes(input.toLowerCase()) || acc,
    false
  );
};

const Wrapper = styled.div`
  position: relative;
  max-width: 400px;
  width: 100%;
`;

const LenderItem = styled.div`
  display: flex;
  align-items: center;
  padding: 16px;
  cursor: pointer;

  &:hover {
    background: rgba(0, 145, 249, 0.05);
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

const Label = styled(Text)`
  margin-left: 8px;
`;

const CompanyLabel = styled(Label)`
  font-size: 12px;
  color: #778598;
`;

const Avatar = styled.div`
  flex-shrink: 0;
`;
