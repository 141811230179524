import styled, { css } from 'styled-components/macro';

import { ReactComponent as Icon } from 'icons/ico_lock.svg';

export const LockOpenIcon = styled(Icon)`
  ${(p) =>
    p.hoverColor &&
    css`
      &:hover {
        g {
          stroke: ${p.hoverColor};
        }
      }
    `}
`;
