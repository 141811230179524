import { put, takeLatest, call, takeEvery, select } from 'redux-saga/effects';
import types from 'constants/actionTypes';
import * as API from 'api';
import { decamelizeKeys } from 'humps';
import { getError } from 'sagas/utils';
import { downloadBlobFile } from 'lib/browser';
import { userTypeSelector } from 'selectors/userType';

import { teamAnalyticsSuccess, teamAnalyticsFailure, exportTeamAnalyticsSuccess, exportTeamAnalyticsFailure } from './actions';

function* teamAnalyticsRequest(action) {
  try {
    const state = yield select();
    const userType = userTypeSelector(state);
    const companyTeamPart = userType.lender ? 'company' : 'team';
    const res = yield API.axiosApi.get(`/analytics/${companyTeamPart}/loans` + action.payload);

    yield put(teamAnalyticsSuccess({ data: res.data.response }));
  }
  catch (e) {
    yield put(teamAnalyticsFailure(e));
  }
}

function* exportTeamAnalyticsRequest(action) {
  try {
    const state = yield select();
    const userType = userTypeSelector(state);
    const companyTeamPart = userType.lender ? 'company' : 'team';
    const {data, headers} = yield call(API.axiosApiRaw.get,
      `/analytics/${companyTeamPart}/loans/export` + action.payload, { responseType: 'blob' });

    downloadBlobFile(data, headers['content-disposition'].split('=')[1]);
    yield put(exportTeamAnalyticsSuccess());
  }
  catch (e) {
    yield put(exportTeamAnalyticsFailure(e));
  }
}

export default [
  takeLatest(types.TEAM_ANALYTICS_REQUEST, teamAnalyticsRequest),
  takeLatest(types.EXPORT_TEAM_ANALYTICS_REQUEST, exportTeamAnalyticsRequest),
];
