import React from 'react';
import styled from 'styled-components/macro';

import DOWNLOAD from '../../../../../icons/ico_download.svg';
import UPLOAD from '../../../../../icons/ico_upload.svg';
import { CrossIcon, CheckIcon } from 'components/SvgIcons';
import { 
  DOC_SIGNATURE_NEW, 
  DOC_SIGNATURE_REJECTED, 
  DOC_SIGNATURE_ACCEPTED, 
  DOC_SIGNATURE_REJECTED_REASON } from 'constants/appRelated';



const UploadFiles = ({file, handlerActionFile, item,  handlerStateFile}) => {

  function uploadComponent(state) {
    return (
      <div>
        <img alt="" src={UPLOAD}/>
    
        <label for={`file-upload_${item}`} class="custom-file-upload">
           {state ? 'Reupload signed' : 'Upload signed'} 
        </label>
        <input
            onChange={(event) => handlerActionFile('upload', item, event)} 
            className={'upload-signed'} 
            id={`file-upload_${item}`} 
            type="file"
        />
      </div>
    )
  }

  return (
    <UploadFilesWrapper>
      {!file.signedDocument ? (
        <IsNotSignedDocument>
          <div onClick={() => handlerActionFile('dowload', item)}>
            <img alt="" src={DOWNLOAD}/>
            <a href={file.file} download>Download</a>
          </div>
          { uploadComponent() }
        </IsNotSignedDocument>  
      ) : (
        <DocumentState>
          {file.signedDocument.state === DOC_SIGNATURE_NEW ? (
            <p className={'text-approval'}>Awaiting approval</p>
          ):(
          file.signedDocument.state === DOC_SIGNATURE_ACCEPTED ? (
            <div className={'state accept'}>
                <span>Accepted</span>
                <CheckIcon color="#44AF69" />
            </div>
            ):(
              file.signedDocument.state === DOC_SIGNATURE_REJECTED ? (
                <IsNotSignedDocument>
                  <div className={'state reject'}>
                    <div>
                        <span>Rejected</span>
                         (<p onClick={() => handlerStateFile(DOC_SIGNATURE_REJECTED_REASON, file)}>
                            SEE REASON
                        </p>) 
                    </div>
                    <CrossIcon color="#e32019" />
                  </div>
                  { uploadComponent({state: DOC_SIGNATURE_REJECTED}) }
                </IsNotSignedDocument>
            ):(
              <></>
            ))
          )}
        </DocumentState>
      )} 
                
    </UploadFilesWrapper>
  )
};

export default UploadFiles;

const IsNotSignedDocument = styled.div`
  div:first-child{ margin-right: 15px }
  img{ margin-right: 5px }
`;
const DocumentState = styled.div`
  color: #1C3353;

  .state{
    &.await{
        margin-right: 16px;
    }
    &.reject, &.accept{
       // margin-right: 15px;
        display: flex;
        align-items: baseline;
        font-size: 12px;
        line-height: 16px;
        span{margin-right: 5px}
    }
    img{ margin-right: 8px};
    cursor: pointer;
  
    &.accept{
      color: #44AF69;
    }
    
    &.reject{
      span{color: #E32019;}
      div{display: flex; margin-right: 5px}
      p{font-size: 10px; color:#4394ea; margin: 0}
    }
`;

const UploadFilesWrapper = styled.div`
  .text-approval{
    margin: 0;
    font-size: 14px;
    line-height: 16px;
    color: #778598;
  }
  div{
    cursor: pointer;
    img:first-child{ margin-right: 5px }
  }
  a{
    color: black;
  }
  label{
    cursor: pointer;
  }
  .upload-signed{
    display: none;
  }`;
