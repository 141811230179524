import types from 'constants/actionTypes';

const initialState = {
  timestampBefore: null,
  timestampAfter: null,
  grouping: null,
  showForUser: null,
  charts: {},
  xAxis: [],
};

export function marketAnalytics(state, action = {}) {
  const currentState = state || initialState;

  const { type, payload } = action;

  switch (type) {
    case types.UPDATE_MARKET_ANALYTICS_RANGE_DATE:
      return {
        ...currentState,
        timestampAfter: payload.from,
        timestampBefore: payload.to,
      };

    case types.UPDATE_MARKET_ANALYTICS_GROUPING:
      return {
        ...currentState,
        grouping: payload,
      };

    case types.GET_MARKET_ANALYTICS_SUCCESS: {
      return {
        ...currentState,
        charts: payload.response.charts,
        ranges: payload.response.xRanges,
        xAxis: payload.response.xAxis,
        grouping: payload.grouping,
        timestampBefore: payload.timestampBefore,
        timestampAfter: payload.timestampAfter,
        showForUser: payload.showForUser,
      };
    }
    case types.UPDATE_MARKET_ANALYTICS_SUCCESS: {
      return {
        ...currentState,
        charts: payload.response.charts,
        ranges: payload.response.xRanges,
        xAxis: payload.response.xAxis,
      };
    }
    default:
      return currentState;
  }
}
