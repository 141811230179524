import types from 'constants/actionTypes';
import { values, any, last } from 'ramda';
import { takeLastOffer } from 'lib/loan';
import { OFFER_STATE } from 'constants/appRelated';

const initialState = {
  offers: [],
  queryOffers: [],
};

export function compareOffers(state, action = {}) {
  const currentState = state || initialState;

  const { type, payload } = action;

  switch (type) {
    case types.LOAN_DATA_SUCCESS:
      return {
        ...currentState,
        offers: values(payload.loan.negotiations)
          .map((negotiation) => {
            const { profile } = negotiation;
            return negotiation.negotiations.map((offer) => ({
              ...takeLastOffer({
                offers: offer.offers,
                rule: (item) => offer.state === OFFER_STATE.ACCEPTED || !item.isMy,
              }),
              createdTimestamp: offer.createdTimestamp,
              profile,
              state: offer.state,
              comment: offer.messages[0], // Take first message in offer discussion as lender initial comment
              haveAccepted: any(
                (offerItem) => offerItem.state === 'accepted',
                negotiation.negotiations
              ),
              onlyNegotiation: last(offer.offers).author !== profile.user.id,
              manual: offer.manual,
              negotiationId: offer.pk,
              documents: offer.documents,
            }));
          })
          .reduce((acc, item) => [...acc, ...item], []),
      };

    case types.HIDE_COMPARE_OFFER:
      return {
        ...currentState,
        offers: currentState.offers.filter(
          ({ negotiationId }) => negotiationId !== payload.negotiationId
        ),
      };

    case types.SET_VISIBLE_OFFERS_ID:
      return {
        ...currentState,
        queryOffers: payload.queryOffers || [],
      };

    default:
      return currentState;
  }
}
