export const syncActions = [
    'SET_SEARCH_BAR',
    'SET_SHOW_SEARCH_BAR_IN_RESULT_PAGE',
    'SET_TAGS',
    'CLEAR_SEARCH_RESULTS',
    'MORE_SCROLL_SEARCH_RESULTS',
]

export const asyncActions = [
  'GET_SEARCH_RESULTS',
  'GET_SEARCH_HISTORY',
  'GET_SEARCH_COUNT',
];
