import React from 'react';
import styled from 'styled-components/macro';
import { Text } from 'ui/Text';

export const Section = ({ sectionName, children, sectionId }) => {
  if (sectionId === 'custom') return null;

  return (
    <React.Fragment>
      <Title>{sectionName}</Title>
      {children}
    </React.Fragment>
  );
};

const Title = styled(Text)`
  margin: 0 0 16px;
  padding: 0;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
`;
