
import styled, { css } from 'styled-components/macro';
import { ReactComponent as Icon } from 'icons/ico_arrow_direction.svg';

export const ArrowDirection = styled(Icon)`
  ${(p) =>
    p.color &&
    css`
      path {
        fill: ${p.color};
      }
    `}
`;