import React from 'react';
import styled, { css } from 'styled-components/macro';
import { Section, SectionTitle } from 'styles/loan';
import { Text } from 'ui/Text';
import { ChatIcon } from 'features/Directory/components/styles';
import { ButtonGhost } from 'ui/Button';
import { normalizeMobile } from 'lib/normalizers';
import { BORROWER } from 'constants/appRelated';
import { BlurContent } from '../../../components/BlurContent';

export const UserInfo = ({
  data,
  redirectToLoanBrokerChat,
  ownerType,
  isLender,
  isBlur = false,
}) => {
  const logo = isLender ? data.logo : data.partnerIcon;

  const companyName = isBlur ? 'Company Name' : data.companyName;
  const userName = isBlur
    ? 'FirstName LastName FullName123'
    : `${data.firstName} ${data.lastName}`;
  const title = isBlur ? 'Some Title' : data.title && <SubTitle>{data.title}</SubTitle>;
  const mobilePhone = isBlur
    ? '(123) 1234-123'
    : normalizeMobile(data.mobilePhone?.slice(1));
  const phone = isBlur ? '(123) 1234-123' : normalizeMobile(data.phone?.slice(1));
  const email = isBlur ? 'dgergdfgdfhger@10infmat.ru' : data.email;

  return (
    <UserInfoWrapper isBlur={isBlur}>
      <Section>
        {isLender ? (
          <SectionTitle>Lender Info</SectionTitle>
        ) : (
          <SectionTitle>
            {ownerType === BORROWER ? 'Borrower info' : 'Broker info'}
          </SectionTitle>
        )}
        <BlurContent blured={isBlur}>
          <Head>
            {logo && <PartnerIcon src={logo} alt="icon" />}
            <CompanyName>{companyName}</CompanyName>
          </Head>
          <Row>
            <div>
              <Text>{userName}</Text>
              {title}
            </div>
            <ButtonGhost onClick={redirectToLoanBrokerChat}>
              <ChatIcon />
            </ButtonGhost>
          </Row>
          <Row>
            <DataTitle>MOBILE PHONE</DataTitle>
            <Text>{mobilePhone}</Text>
          </Row>
          <Row>
            <DataTitle>WORK PHONE</DataTitle>
            <Text>{phone}</Text>
          </Row>
          <Row>
            <DataTitle>EMAIL</DataTitle>
            <Email href={`mailto:${email}`}>{email}</Email>
          </Row>
        </BlurContent>
      </Section>
    </UserInfoWrapper>
  );
};

const UserInfoWrapper = styled.div`
  height: 100%;

  @media (max-width: 1090px) {
    min-width: 350px;
  }

  ${(p) =>
    p.isBlur &&
    css`
      min-width: 370px;
    `}
`;

const Head = styled.div`
  text-align: center;
  margin-bottom: 8px;
`;

const PartnerIcon = styled.img`
  margin-bottom: 8px;
`;

const CompanyName = styled(Text)`
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 0;
  border-bottom: 1px solid #e8e9ed;
`;

const SubTitle = styled(Text)`
  font-size: 12px;
  color: #778598;
`;

const DataTitle = styled(SubTitle)`
  margin-right: 8px;
  font-size: 10px;
  flex-shrink: 0;
`;

const Email = styled.a`
  color: #4394ea;
  text-decoration: none;
  word-break: break-all;
`;
