import { put, takeLatest, call, select } from 'redux-saga/effects';
import types from 'constants/actionTypes';
import * as API from 'api';
import { getError } from 'sagas/utils';
import { setCurrentUri } from 'actions';
import { history } from 'store';
import { makeObjFromUrlString } from 'lib/url';
import { featuresUrlParamsMap } from 'config/featuresUrlParamsMap';

import {
  myMatchingLoansDataDataSuccess,
  myMatchingLoansDataDataFailure,
  unhideLoanSuccess,
  unhideLoanFailure,
  getCompanyClientsSuccess,
  getCompanyClientsFailure,
  setMyMatchingLoansDisplayVariantSuccess,
  setMyMatchingLoansDisplayVariantFailure,
  updateMyMatchingLoansDataSuccess,
  updateMyMatchingLoansDataFailure,
} from './actions';

function* myMatchingLoans(action) {
  try {
    const { query } = action.payload;

    const {
      router: { location },
      currentUser,
    } = yield select();
    const uriString = location.search.replace('?', '');

    const search = query || location.search.replace('?', '');
    const isStorageURL = localStorage.getItem('url_pipeline_page');

    const {
      data: { response },
    } = yield call(
      API.axiosApi.get,
      `loans/match?no_page=true${search ? `&${search}` : `&${isStorageURL}`}`
    );

    const {
      data: { ordering = null },
    } = makeObjFromUrlString({
      uri: uriString,
      config: { ordering: featuresUrlParamsMap.ordering },
    });

    let displayVariant;
    if (window.localStorage) {
      displayVariant = localStorage.getItem(
        `${currentUser.email}.myMatchingLoansDisplayVariant`
      );
    }

    yield put(
      myMatchingLoansDataDataSuccess({ data: response, displayVariant, ordering })
    );
  } catch (e) {
    console.error(e);
    yield put(myMatchingLoansDataDataFailure({ message: getError(e) }));
  }
}

function* updateMyMatchingLoansData() {
  try {
    const {
      myMatchingLoans: { ordering },
      filter,
    } = yield select();

    const newUri = [ordering && `ordering=${ordering}`, filter.uri]
      .filter(Boolean)
      .join('&');

    history.push(`?${newUri}`);
    yield put(setCurrentUri(newUri));

    const {
      data: { response },
    } = yield call(
      API.axiosApi.get,
      `loans/match?no_page=true${newUri ? `&${newUri}` : ''}`
    );

    yield put(updateMyMatchingLoansDataSuccess({ data: response }));
  } catch (e) {
    yield put(updateMyMatchingLoansDataFailure({ message: getError(e) }));
  }
}

function* unhideLoan(action) {
  try {
    const { id } = action.payload;
    yield call(API.axiosApi.delete, `/hidden-loans/${id}`);

    yield put(unhideLoanSuccess({ id }));
  } catch (e) {
    console.error(e);
    yield put(unhideLoanFailure({ message: getError(e) }));
  }
}

function* getCompanyClients() {
  try {
    const clients = yield call(API.axiosApi.get, '/loans/match/owners');
    const companies = yield call(API.axiosApi.get, '/loans/match/companies');

    yield put(
      getCompanyClientsSuccess({
        clients: clients.data.response.map((item) => ({
          value: item.id,
          label: item.label,
        })),
        companies: companies.data.response.map((item) => ({
          value: item.teamId,
          label: item.label,
        })),
      })
    );
  } catch (e) {
    yield put(getCompanyClientsFailure({ message: getError(e) }));
  }
}

function* setMyMatchingLoansDisplayVariant(action) {
  try {
    const { currentUser } = yield select();

    if (window.localStorage) {
      localStorage.setItem(
        `${currentUser.email}.myMatchingLoansDisplayVariant`,
        action.payload
      );
    }

    yield put(setMyMatchingLoansDisplayVariantSuccess(action.payload));
  } catch (e) {
    yield put(setMyMatchingLoansDisplayVariantFailure({ message: getError(e) }));
  }
}

export default [
  takeLatest(types.MY_MATCHING_LOANS_DATA_REQUEST, myMatchingLoans),
  takeLatest(types.UNHIDE_LOAN_REQUEST, unhideLoan),
  takeLatest(types.GET_COMPANY_CLIENTS_REQUEST, getCompanyClients),
  takeLatest(
    types.SET_MY_MATCHING_LOANS_DISPLAY_VARIANT_REQUEST,
    setMyMatchingLoansDisplayVariant
  ),
  takeLatest(types.UPDATE_MY_MATCHING_LOANS_DATA_REQUEST, updateMyMatchingLoansData),
];
