import React from 'react';
import { CheckIcon } from 'components/SvgIcons';
import styled, { css } from 'styled-components/macro';

const WrapperCommon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  border-radius: 100%;

  svg polygon {
    fill: #fff;
  }
`;

const Wrapper = styled(WrapperCommon)`
  ${(p) => {
    switch (p.variant) {
      case 'green':
        return css`
          background-color: #44af69;
        `;

      case 'blue':
        return css`
          background-color: #4394ea;
        `;

      case 'white':
        return css`
          background-color: #fff;

          svg polygon {
            fill: #4394ea;
          }
        `;

      default:
        return css`
          background-color: #cfd0d7;
        `;
    }
  }}
`;

export const CheckCircle = ({ variant, className }) => (
  <Wrapper variant={variant} className={className}>
    <CheckIcon />
  </Wrapper>
);
