import React from 'react';
import styled from 'styled-components/macro';
import { Field } from 'react-final-form';
import { Input } from 'components/Form';

const FieldWrap = styled.div`
  flex: 1 0;

  margin-bottom: 24px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const AddLenderForm = ({ validateEmail }) => (
  <div>
    <FieldWrap>
      <Field
        name="customLenderFirstName"
        component={Input}
        label="First name"
        required
        mb={0}
      />
    </FieldWrap>
    <FieldWrap>
      <Field
        name="customLenderLastName"
        component={Input}
        label="Last name"
        required
        mb={0}
      />
    </FieldWrap>
    <FieldWrap>
      <Field
        name="customLenderEmail"
        component={Input}
        label="Email"
        required
        mb={0}
        validate={validateEmail}
      />
    </FieldWrap>
    <FieldWrap>
      <Field
        name="customLenderCompany"
        component={Input}
        label="Company"
        required
        mb={0}
      />
    </FieldWrap>
  </div>
);
