import React from 'react';
import styled from 'styled-components/macro';
import { connect } from 'react-redux';
import NotificationSystem from 'react-notification-system';
import { modals } from 'constants/modalTypes';
import { showModal } from 'actions/modalActions';
import { Link } from 'react-router-dom';
import { Button } from 'ui/Button';

const NotificationWrapper = styled.div`
  font-size: 1.4rem;
  margin-bottom: 5px;
`;

class NotificationControl extends React.Component {
  componentDidMount() {
    this.notificationSystem = React.createRef();
  }

  shouldComponentUpdate(nextProps) {
    return nextProps.notifications.length !== this.props.notifications.length;
  }

  componentDidUpdate() {
    const { dispatch, notifications } = this.props;
    if (notifications.length) {
      const lastNotification = notifications[notifications.length - 1];

      if (lastNotification && lastNotification.data && !lastNotification.data.read) {
        const {
          data,
          data: { text, meta },
        } = lastNotification;
        const loan = data.label !== 'lender_account_approved' && data.loan && (
          <NotificationWrapper>
            {data.loan.property_name}, {data.loan.short_address}
          </NotificationWrapper>
        );

        this.notificationSystem.current.addNotification({
          ...lastNotification,
          children: (
            <div>
              {loan}
              <NotificationCta
                label={data.label}
                data={{ ...data.loan, ...meta }}
                text={text}
                dispatch={dispatch}
              />
            </div>
          ),
          uid: data.pk,
        });
      }
    }
  }

  render() {
    return <NotificationSystem ref={this.notificationSystem} autoDismiss={5} />;
  }
}

function mapStateToProps(state) {
  const { notification } = state;

  return {
    notifications: notification.notifications,
  };
}

export default connect(mapStateToProps)(NotificationControl);

const NotificationCta = ({ label, data, dispatch, text }) => {
  const { id, offers_total } = data;
  switch (label) {
    case 'loan_approved':
    case 'loan_rejected':
    case 'counter_offer_created_by_borrower':
    case 'document_request_created':
    case 'lender_cancelled_offer_to_borrower':
    case 'borrower_declined_to_answer':
      return (
        <Link to={`/dashboard/property-details/${id}`}>
          <Button>View Loan</Button>
        </Link>
      );
    case 'negotiation_started':
    case 'counter_offer_created_by_lender':
      return (
        <Link
          to={
            offers_total > 1
              ? `/dashboard/compare-offers/${id}`
              : `/dashboard/property-details/${id}`
          }
        >
          <Button>{offers_total > 1 ? 'Compare Quotes' : 'View Loan'}</Button>
        </Link>
      );
    case 'offer_accepted':
      return (
        <Link to={`/dashboard/property-details/${id}`}>
          <Button>Upload Term sheet</Button>
        </Link>
      );
    case 'offer_rejected':
    case 'lender_account_approved':
      return (
        <Link to="/dashboard/marketplace">
          <Button>View Marketplace</Button>
        </Link>
      );
    case 'question_created':
      return (
        <Link to="/dashboard/inbox/received">
          <Button>View Question</Button>
        </Link>
      );
    case 'question_answered':
      return (
        <Button
          onClick={() =>
            dispatch(
              showModal(modals.ANSWER_MODAL, {
                question: !!data && data.question,
                answer: text,
              })
            )
          }
        >
          View Answer
        </Button>
      );

    default:
      return null;
  }
};
