import types from 'constants/actionTypes';

const initialState = false;

export default function home(state, action = {}) {
  const currentState = state || initialState;

  const { type } = action;

  switch (type) {
    case types.REGISTRATION_REQUEST:
    case types.LOGIN_REQUEST:
    case types.LOGOUT_REQUEST:
    case types.FINISH_REGISTRATION_REQUEST:
    case types.DELETE_LOAN_FILE_REQUEST:
    case types.MY_LOANS_DATA_REQUEST:
    case types.MY_OFFERS_REQUEST:
    case types.MY_MATCHING_LOANS_DATA_REQUEST:
    case types.SUBMIT_OFFER_REQUEST:
    case types.NEGOTIATE_OFFER_REQUEST:
    case types.EDIT_OFFER_REQUEST:
    case types.ACCEPT_OFFER_REQUEST:
    case types.REJECT_OFFER_REQUEST:
    case types.POST_QUESTION_REQUEST:
    case types.POST_ANSWER_REQUEST:
    case types.INBOX_ADDITIONAL_DATA_REQUEST:
    case types.INBOX_REQUEST:
    case types.SUPPORT_REQUEST:
    case types.POST_INBOX_ANSWER_REQUEST:
    case types.LOAN_DATA_REQUEST:
    case types.SUBMIT_TERMSHEET_REQUEST:
    case types.CONCIERGE_ACTION_REQUEST:
    case types.FORGOT_PASSWORD_REQUEST:
    case types.PASSWORD_RESET_REQUEST:
    case types.GET_USER_INFO_REQUEST:
    case types.CHANGE_USER_INFO_REQUEST:
    case types.GET_LENDING_CRITERIA_REQUEST:
    case types.CHANGE_LENDING_CRITERIA_REQUEST:
    case types.EMAIL_CONFIRMATION_REQUEST:
    case types.UPDATE_MARKETPLACE_DATA_REQUEST:
    case types.DOWNLOAD_ALL_DOCUMENTS_REQUEST:
    case types.GET_USER_PROFILE_REQUEST:
    case types.CHANGE_USER_PROFILE_REQUEST:
    case types.SUBMIT_FOR_VERIFICATION_REQUEST:
    case types.SUBMIT_LOAN_FORM_REQUEST:
    case types.CANCEL_OFFER_REQUEST:
    case types.GET_DEAL_ROOM_REQUEST:
    case types.GET_DEAL_ROOMS_REQUEST:
    case types.REJECT_TERMSHEET_REQUEST:
    case types.UPLOAD_SIGNED_TERMSHEET_REQUEST:
    case types.CONFIRM_EMAIL_REQUEST:
    case types.UPLOAD_LENDER_DOCUMENT_REQUEST:
    case types.STANDALONE_LOAN_DATA_REQUEST:
    case types.ENTERPRISE_LENDER_REGISTRATION_REQUEST:
    case types.LOAN_ACTIONS_REQUEST:
    case types.UPLOAD_ACTION_DOCUMENT_REQUEST:
    case types.DELETE_ACTION_DOCUMENT_REQUEST:
    case types.GET_PROFILE_INFO_REQUEST:
    case types.GET_CHAT_ROOM_REQUEST:
    case types.GET_ADDITIONAL_CHAT_ROOMS_REQUEST:
    case types.GET_ADDITIONAL_CHAT_ROOM_REQUEST:
    case types.LENDER_DIRECTORY_INVITE_DOCUMENT_REQUEST:
    case types.ANSWER_LENDER_COMMENT_REQUEST:
    case types.UPLOAD_CHAT_DOCUMENT_REQUEST:
    case types.SUBMIT_PAYMENT_REQUEST:
    case types.INVITE_LENDER_REQUEST:
    case types.CHAT_OFFER_EDIT_REQUEST:
    case types.CHAT_OFFER_NEGOTIATE_REQUEST:
    case types.CHAT_OFFER_SUBMIT_REQUEST:
    case types.CHAT_OFFER_REJECT_REQUEST:
    case types.REQUEST_PERMISSION_REQUEST:
    case types.SHARE_OFFER_REQUEST:
    case types.CHECK_TEAM_MEMBER_TOKEN_REQUEST:
    case types.TEAM_MEMBER_REGISTRATION_REQUEST:
    case types.UPLOAD_REQUESTED_DOCUMENT_REQUEST:
    case types.REQUEST_DOCUMENTS_REQUEST:
    case types.GET_LISTING_REQUEST:
    case types.SUBMIT_QUOTE_REQUEST:
    case types.EDIT_QUOTE_REQUEST:
    case types.LISTING_LOGIN_REQUEST:
    case types.LISTING_REGISTRATION_REQUEST:
    case types.APPLY_FOR_FINANCING_REQUEST:
    case types.CREATE_CHAT_TEAM_GROUP_REQUEST:
    case types.MARKETPLACE_DATA_REQUEST:
    case types.NOTIFICATION_UPLOAD_DOCUMENT_REQUEST:
    case types.SEARCH_MESSAGE_IN_CHAT_REQUEST:
    case types.LENDER_DIRECTORY_INVITE_REQUEST:
    case types.SEND_LOAN_TO_LENDERS_REQUEST:
    case types.GET_LOAN_LENDERS_REQUEST:
    case types.RESEND_LENDER_INVITATION_REQUEST:
    case types.POST_LOAN_NOTE_REQUEST:
    case types.UPDATE_RELATIONSHIP_LENDERS_REQUEST:
    case types.ASSIGN_LOAN_REQUEST:
    case types.UPDATE_CUSTOM_FIELDS_REQUEST:
    case types.GET_BROKER_LOAN_ROOM_REQUEST:
    case types.TOGGLE_DOCUMENT_PROTECTION_STATE_REQUEST:
    case types.TOGGLE_FOLDER_PROTECTION_STATE_REQUEST:
    case types.ACCEPT_CONFIDENTIALITY_AGREEMENT_REQUEST:
    case types.CHANGE_TEAM_DATA_REQUEST:
    case types.CREATE_DEAL_ROOM_CHECKLIST_REQUEST:
    case types.ATTACH_DEAL_ROOM_FILES_REQUEST:
    case types.CHANGE_PROPERTY_NAME_REQUEST:
    case types.GET_RECOVERY_CODES_REQUEST:
    case types.GET_NEW_RECOVERY_CODES_REQUEST:
    case types.REQUEST_QR_CODE_REQUEST:
    case types.REQUEST_SMS_AUTH_REQUEST:
    case types.CONFIRM_AUTHENTICATOR_DEVICE_REQUEST:
    case types.DISABLE_TFA_REQUEST:
    case types.CONFIRM_BY_SMS_REQUEST:
    case types.RECOVERY_LOGIN_REQUEST:
    case types.RESEND_SMS_CODE_REQUEST:
    case types.CONFIRM_AUTH_REQUEST:
    case types.GET_LOAN_TERMSHEETS_REQUEST:
    case types.SUBMIT_MANUAL_TERMSHEET_REQUEST:
    case types.EDIT_MANUAL_TERMSHEET_REQUEST:
    case types.MY_LOANS_UPDATE_REQUEST:
    case types.UPDATE_LENDERS_DIRECTORY_DATA_REQUEST:
    case types.UPDATE_CONTACTS_DIRECTORY_DATA_REQUEST:
    case types.REVOKE_ACCESS_REQUEST:
    case types.SIGN_CA_AND_OPEN_DAI_DOC_REQUEST:
    case types.GET_MARKET_ANALYTICS_LOANS_REQUEST:
    case types.GET_LENDER_LOAN_TERMSHEETS_REQUEST:
    case types.UPLOAD_LENDER_TERMSHEET_REQUEST:
    case types.UPDATE_CLIENT_DIRECTORY_DATA_REQUEST:
    case types.GET_ANALYTICS_LENDERS_DATA_REQUEST:
    case types.UPDATE_ANALYTICS_LENDERS_DATA_REQUEST:
    case types.GET_LENDER_COMPANY_DATA_REQUEST:
    case types.UPDATE_LENDER_COMPANY_SETTINGS_REQUEST:
    case types.UPDATE_MY_MATCHING_LOANS_DATA_REQUEST:
    case types.SET_LOAN_REMINDER_REQUEST:
    case types.MANUAL_OFFER_MODAL_REQUEST:
    case types.ALLOW_AUTH_REQUEST:
    case types.REMOVE_INSTALLED_APP_REQUEST:
    case types.CREATE_DEAL_ROOM_REQUEST:
      return true;

    case types.REGISTRATION_FAILURE:
    case types.REGISTRATION_SUCCESS:
    case types.LOGIN_FAILURE:
    case types.LOGIN_SUCCESS:
    case types.LOGOUT_SUCCESS:
    case types.USER_LOGOUT_FAILURE:
    case types.FINISH_REGISTRATION_FAILURE:
    case types.FINISH_REGISTRATION_SUCCESS:
    case types.DELETE_LOAN_FILE_FAILURE:
    case types.DELETE_LOAN_FILE_SUCCESS:
    case types.MY_LOANS_DATA_FAILURE:
    case types.MY_LOANS_DATA_SUCCESS:
    case types.MY_MATCHING_LOANS_DATA_FAILURE:
    case types.MY_MATCHING_LOANS_DATA_SUCCESS:
    case types.SUBMIT_OFFER_FAILURE:
    case types.SUBMIT_OFFER_SUCCESS:
    case types.NEGOTIATE_OFFER_FAILURE:
    case types.NEGOTIATE_OFFER_SUCCESS:
    case types.EDIT_OFFER_FAILURE:
    case types.EDIT_OFFER_SUCCESSE:
    case types.ACCEPT_OFFER_FAILURE:
    case types.ACCEPT_OFFER_SUCCESS:
    case types.POST_QUESTION_FAILURE:
    case types.POST_QUESTION_SUCCESS:
    case types.POST_ANSWER_FAILURE:
    case types.POST_ANSWER_SUCCESS:
    case types.POST_INBOX_ANSWER_FAILURE:
    case types.POST_INBOX_ANSWER_SUCCESS:
    case types.INBOX_ADDITIONAL_DATA_FAILURE:
    case types.INBOX_ADDITIONAL_DATA_SUCCESS:
    case types.INBOX_FAILURE:
    case types.INBOX_SUCCESS:
    case types.SUPPORT_FAILURE:
    case types.SUPPORT_SUCCESS:
    case types.LOAN_DATA_FAILURE:
    case types.LOAN_DATA_SUCCESS:
    case types.SUBMIT_TERMSHEET_FAILURE:
    case types.SUBMIT_TERMSHEET_SUCCESS:
    case types.CONCIERGE_ACTION_FAILURE:
    case types.CONCIERGE_ACTION_SUCCESS:
    case types.FORGOT_PASSWORD_SUCCESS:
    case types.FORGOT_PASSWORD_FAILURE:
    case types.PASSWORD_RESET_FAILURE:
    case types.PASSWORD_RESET_SUCCESS:
    case types.GET_USER_INFO_SUCCESS:
    case types.GET_USER_INFO_FAILURE:
    case types.CHANGE_USER_INFO_SUCCESS:
    case types.GET_LENDING_CRITERIA_SUCCESS:
    case types.GET_LENDING_CRITERIA_FAILURE:
    case types.CHANGE_LENDING_CRITERIA_SUCCESS:
    case types.CHANGE_LENDING_CRITERIA_FAILURE:
    case types.EMAIL_CONFIRMATION_SUCCESS:
    case types.EMAIL_CONFIRMATION_FAILURE:
    case types.UPDATE_MARKETPLACE_DATA_SUCCESS:
    case types.UPDATE_MARKETPLACE_DATA_FAILURE:
    case types.DOWNLOAD_ALL_DOCUMENTS_SUCCESS:
    case types.DOWNLOAD_ALL_DOCUMENTS_FAILURE:
    case types.GET_USER_PROFILE_SUCCESS:
    case types.GET_USER_PROFILE_FAILURE:
    case types.CHANGE_USER_PROFILE_SUCCESS:
    case types.CHANGE_USER_PROFILE_FAILURE:
    case types.MY_OFFERS_SUCCESS:
    case types.MY_OFFERS_FAILURE:
    case types.SUBMIT_FOR_VERIFICATION_SUCCESS:
    case types.SUBMIT_FOR_VERIFICATION_FAILURE:
    case types.SUBMIT_LOAN_FORM_FAILURE:
    case types.SUBMIT_LOAN_FORM_SUCCESS:
    case types.CANCEL_OFFER_SUCCESS:
    case types.CANCEL_OFFER_FAILURE:
    case types.GET_DEAL_ROOM_FAILURE:
    case types.GET_DEAL_ROOM_SUCCESS:
    case types.GET_DEAL_ROOMS_FAILURE:
    case types.GET_DEAL_ROOMS_SUCCESS:
    case types.REJECT_TERMSHEET_FAILURE:
    case types.CONFIRM_EMAIL_FAILURE:
    case types.CONFIRM_EMAIL_SUCCESS:
    case types.UPLOAD_SIGNED_TERMSHEET_FAILURE:
    case types.UPLOAD_LENDER_DOCUMENT_FAILURE:
    case types.UPLOAD_LENDER_DOCUMENT_SUCCESS:
    case types.ANSWER_LENDER_COMMENT_FAILURE:
    case types.ANSWER_LENDER_COMMENT_SUCCESS:
    case types.STANDALONE_LOAN_DATA_SUCCESS:
    case types.STANDALONE_LOAN_DATA_FAILURE:
    case types.ENTERPRISE_LENDER_REGISTRATION_FAILURE:
    case types.ENTERPRISE_LENDER_REGISTRATION_SUCCESS:
    case types.LOAN_ACTIONS_SUCCESS:
    case types.LOAN_ACTIONS_FAILURE:
    case types.UPLOAD_ACTION_DOCUMENT_SUCCESS:
    case types.UPLOAD_ACTION_DOCUMENT_FAILURE:
    case types.DELETE_ACTION_DOCUMENT_SUCCESS:
    case types.DELETE_ACTION_DOCUMENT_FAILURE:
    case types.GET_PROFILE_INFO_FAILURE:
    case types.GET_PROFILE_INFO_SUCCESS:
    case types.GET_CHAT_ROOM_FAILURE:
    case types.GET_CHAT_ROOM_SUCCESS:
    case types.GET_ADDITIONAL_CHAT_ROOMS_SUCCESS:
    case types.GET_ADDITIONAL_CHAT_ROOMS_FAILURE:
    case types.GET_ADDITIONAL_CHAT_ROOM_SUCCESS:
    case types.GET_ADDITIONAL_CHAT_ROOM_FAILURE:
    case types.LENDER_DIRECTORY_INVITE_DOCUMENT_SUCCESS:
    case types.LENDER_DIRECTORY_INVITE_DOCUMENT_FAILURE:
    case types.UPLOAD_CHAT_DOCUMENT_FAILURE:
    case types.UPLOAD_CHAT_DOCUMENT_SUCCESS:
    case types.SUBMIT_PAYMENT_SUCCESS:
    case types.SUBMIT_PAYMENT_FAILURE:
    case types.INVITE_LENDER_SUCCESS:
    case types.INVITE_LENDER_FAILURE:
    case types.CHAT_OFFER_EDIT_FAILURE:
    case types.CHAT_OFFER_EDIT_SUCCESS:
    case types.CHAT_OFFER_NEGOTIATE_SUCCESS:
    case types.CHAT_OFFER_NEGOTIATE_FAILURE:
    case types.CHAT_OFFER_SUBMIT_SUCCESS:
    case types.CHAT_OFFER_SUBMIT_FAILURE:
    case types.CHAT_OFFER_REJECT_SUCCESS:
    case types.CHAT_OFFER_REJECT_FAILURE:
    case types.REQUEST_PERMISSION_SUCCESS:
    case types.REQUEST_PERMISSION_FAILURE:
    case types.HIDE_SPINNER:
    case types.SHARE_OFFER_SUCCESS:
    case types.SHARE_OFFER_FAILURE:
    case types.CHECK_TEAM_MEMBER_TOKEN_SUCCESS:
    case types.CHECK_TEAM_MEMBER_TOKEN_FAILURE:
    case types.TEAM_MEMBER_REGISTRATION_SUCCESS:
    case types.TEAM_MEMBER_REGISTRATION_FAILURE:
    case types.UPLOAD_REQUESTED_DOCUMENT_SUCCESS:
    case types.UPLOAD_REQUESTED_DOCUMENT_FAILURE:
    case types.REJECT_OFFER_FAILURE:
    case types.REQUEST_DOCUMENTS_SUCCESS:
    case types.REQUEST_DOCUMENTS_FAILURE:
    case types.GET_LISTING_SUCCESS:
    case types.GET_LISTING_FAILURE:
    case types.SUBMIT_QUOTE_SUCCESS:
    case types.SUBMIT_QUOTE_FAILURE:
    case types.EDIT_QUOTE_SUCCESS:
    case types.EDIT_QUOTE_FAILURE:
    case types.LISTING_LOGIN_SUCCESS:
    case types.LISTING_LOGIN_FAILURE:
    case types.LISTING_REGISTRATION_SUCCESS:
    case types.LISTING_REGISTRATION_FAILURE:
    case types.APPLY_FOR_FINANCING_SUCCESS:
    case types.APPLY_FOR_FINANCING_FAILURE:
    case types.CREATE_CHAT_TEAM_GROUP_SUCCESS:
    case types.CREATE_CHAT_TEAM_GROUP_FAILURE:
    case types.MARKETPLACE_DATA_SUCCESS:
    case types.MARKETPLACE_DATA_FAILURE:
    case types.NOTIFICATION_UPLOAD_DOCUMENT_SUCCESS:
    case types.NOTIFICATION_UPLOAD_DOCUMENT_FAILURE:
    case types.SEARCH_MESSAGE_IN_CHAT_SUCCESS:
    case types.SEARCH_MESSAGE_IN_CHAT_FAILURE:
    case types.LENDER_DIRECTORY_INVITE_SUCCESS:
    case types.LENDER_DIRECTORY_INVITE_FAILURE:
    case types.SEND_LOAN_TO_LENDERS_SUCCESS:
    case types.SEND_LOAN_TO_LENDERS_FAILURE:
    case types.GET_LOAN_LENDERS_SUCCESS:
    case types.GET_LOAN_LENDERS_FAILURE:
    case types.RESEND_LENDER_INVITATION_SUCCESS:
    case types.RESEND_LENDER_INVITATION_FAILURE:
    case types.POST_LOAN_NOTE_SUCCESS:
    case types.POST_LOAN_NOTE_FAILURE:
    case types.UPDATE_RELATIONSHIP_LENDERS_SUCCESS:
    case types.UPDATE_RELATIONSHIP_LENDERS_FAILURE:
    case types.ASSIGN_LOAN_SUCCESS:
    case types.ASSIGN_LOAN_FAILURE:
    case types.UPDATE_CUSTOM_FIELDS_SUCCESS:
    case types.UPDATE_CUSTOM_FIELDS_FAILURE:
    case types.GET_BROKER_LOAN_ROOM_SUCCESS:
    case types.GET_BROKER_LOAN_ROOM_FAILURE:
    case types.TOGGLE_DOCUMENT_PROTECTION_STATE_SUCCESS:
    case types.TOGGLE_DOCUMENT_PROTECTION_STATE_FAILURE:
    case types.TOGGLE_FOLDER_PROTECTION_STATE_SUCCESS:
    case types.TOGGLE_FOLDER_PROTECTION_STATE_FAILURE:
    case types.ACCEPT_CONFIDENTIALITY_AGREEMENT_SUCCESS:
    case types.ACCEPT_CONFIDENTIALITY_AGREEMENT_FAILURE:
    case types.CHANGE_TEAM_DATA_SUCCESS:
    case types.CHANGE_TEAM_DATA_FAILURE:
    case types.CREATE_DEAL_ROOM_CHECKLIST_SUCCESS:
    case types.CREATE_DEAL_ROOM_CHECKLIST_FAILURE:
    case types.ATTACH_DEAL_ROOM_FILES_SUCCESS:
    case types.ATTACH_DEAL_ROOM_FILES_FAILURE:
    case types.CHANGE_PROPERTY_NAME_SUCCESS:
    case types.CHANGE_PROPERTY_NAME_FAILURE:
    case types.GET_RECOVERY_CODES_SUCCESS:
    case types.GET_RECOVERY_CODES_FAILURE:
    case types.GET_NEW_RECOVERY_CODES_SUCCESS:
    case types.GET_NEW_RECOVERY_CODES_FAILURE:
    case types.REQUEST_QR_CODE_SUCCESS:
    case types.REQUEST_QR_CODE_FAILURE:
    case types.REQUEST_SMS_AUTH_SUCCESS:
    case types.REQUEST_SMS_AUTH_FAILURE:
    case types.CONFIRM_AUTHENTICATOR_DEVICE_SUCCESS:
    case types.CONFIRM_AUTHENTICATOR_DEVICE_FAILURE:
    case types.DISABLE_TFA_SUCCESS:
    case types.DISABLE_TFA_FAILURE:
    case types.CONFIRM_BY_SMS_SUCCESS:
    case types.CONFIRM_BY_SMS_FAILURE:
    case types.RECOVERY_LOGIN_SUCCESS:
    case types.RECOVERY_LOGIN_FAILURE:
    case types.RESEND_SMS_CODE_SUCCESS:
    case types.RESEND_SMS_CODE_FAILURE:
    case types.CONFIRM_AUTH_SUCCESS:
    case types.CONFIRM_AUTH_FAILURE:
    case types.GET_LOAN_TERMSHEETS_SUCCESS:
    case types.GET_LOAN_TERMSHEETS_FAILURE:
    case types.SUBMIT_MANUAL_TERMSHEET_SUCCESS:
    case types.SUBMIT_MANUAL_TERMSHEET_FAILURE:
    case types.EDIT_MANUAL_TERMSHEET_SUCCESS:
    case types.EDIT_MANUAL_TERMSHEET_FAILURE:
    case types.MY_LOANS_UPDATE_SUCCESS:
    case types.MY_LOANS_UPDATE_FAILURE:
    case types.UPDATE_LENDERS_DIRECTORY_DATA_SUCCESS:
    case types.UPDATE_LENDERS_DIRECTORY_DATA_FAILURE:
    case types.UPDATE_CONTACTS_DIRECTORY_DATA_SUCCESS:
    case types.UPDATE_CONTACTS_DIRECTORY_DATA_FAILURE:
    case types.REVOKE_ACCESS_SUCCESS:
    case types.REVOKE_ACCESS_FAILURE:
    case types.SIGN_CA_AND_OPEN_DAI_DOC_SUCCESS:
    case types.SIGN_CA_AND_OPEN_DAI_DOC_FAILURE:
    case types.GET_MARKET_ANALYTICS_LOANS_SUCCESS:
    case types.GET_MARKET_ANALYTICS_LOANS_FAILURE:
    case types.GET_LENDER_LOAN_TERMSHEETS_SUCCESS:
    case types.GET_LENDER_LOAN_TERMSHEETS_FAILURE:
    case types.UPDATE_CLIENT_DIRECTORY_DATA_SUCCESS:
    case types.UPDATE_CLIENT_DIRECTORY_DATA_FAILURE:
    case types.GET_ANALYTICS_LENDERS_DATA_SUCCESS:
    case types.GET_ANALYTICS_LENDERS_DATA_FAILURE:
    case types.UPDATE_ANALYTICS_LENDERS_DATA_SUCCESS:
    case types.UPDATE_ANALYTICS_LENDERS_DATA_FAILURE:
    case types.GET_LENDER_COMPANY_DATA_SUCCESS:
    case types.GET_LENDER_COMPANY_DATA_FAILURE:
    case types.UPDATE_LENDER_COMPANY_SETTINGS_SUCCESS:
    case types.UPDATE_LENDER_COMPANY_SETTINGS_FAILURE:
    case types.UPDATE_MY_MATCHING_LOANS_DATA_SUCCESS:
    case types.UPDATE_MY_MATCHING_LOANS_DATA_FAILURE:
    case types.SET_LOAN_REMINDER_SUCCESS:
    case types.SET_LOAN_REMINDER_FAILURE:
    case types.MANUAL_OFFER_MODAL_SUCCESS:
    case types.MANUAL_OFFER_MODAL_FAILURE:
    case types.ALLOW_AUTH_SUCCESS:
    case types.ALLOW_AUTH_FAILURE:
    case types.REMOVE_INSTALLED_APP_SUCCESS:
    case types.REMOVE_INSTALLED_APP_FAILURE:
    case types.CREATE_DEAL_ROOM_SUCCESS:
    case types.CREATE_DEAL_ROOM_FAILURE:
    case types.GET_MOODY_ACCESS_TOKEN_SUCCESS:
    case types.GET_MOODY_ACCESS_TOKEN_FAILURE:
      return false;

    default:
      return currentState;
  }
}
