export const iconTypes = {
    document: '/images/icons/global-search/document.svg',
    people: '/images/icons/global-search/User.svg',
    task: '/images/icons/global-search/task.svg',
    checklist: '/images/icons/global-search/checklist.svg',
    message: '/images/icons/global-search/message.svg',
    loan: '/images/icons/global-search/loan.svg',
};

export const SEARCH_RESULT = [
    {label: 'All', type: 'all'},
    {label: 'Tasks', type: 'task'},
    {label: 'Loans', type: 'loan'}, 
    {label: 'Messages', type: 'message'}, 
    {label: 'People', type: 'people'},
    {label: 'Checklists', type: 'checklist'}, 
    {label: 'Documents', type: 'document'},
];

export const searchTags = [ 
    {label: 'Tasks', value: 1},
    {label: 'Loans', value: 2},
    {label: 'Messages', value: 3},
    {label: 'People', value: 4},
    {label: 'Checklists', value: 5},
    {label: 'Documents', value: 6}
];


export const options = {
    'All': [{label: 'most 222', val: 1}, {label: 'RRRRRRtesttesttest12234', val: 2}],
    'Tasks': [{label: 'most dadasds', val: 1}, {label: 'vvcvc', val: 2}],
    'Loans': [{label: 'xcxcx relevant', val: 1}, {label: 'WWWWW', val: 2}],
    'Messages': [{label: '777 relevant', val: 1}, {label: 'qqqqqq', val: 2}],
    'People': [{label: '==== relevant', val: 1}, {label: 'uuuuuuQqqqq', val: 2}],
    'Checklists': [{label: '22434 relevant', val: 1}, {label: 'vczxbxcgngikTTT', val: 2}],
    'Documents': [{label: 'ttttt', val: 1}, {label: 'bbcvbuuuuuuu', val: 2}]
};