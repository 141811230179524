import React from 'react';
import { connect } from 'react-redux';
import { userTypeSelector } from 'selectors/userType';
import { getLoanAmortizationChoices } from 'selectors';
import { submitQuoteRequest, editQuoteRequest } from 'containers/ListingWrapper/actions';
import {
  OfferModalWrapper,
  OfferModalHeader,
  OfferModalTitle,
  OfferModalContent,
  OfferModalTabs,
  OfferModalTab,
  OfferModalAdd,
} from 'styles/offer';
import { listingQuotesSelector } from 'containers/ListingWrapper/selectors';

import { QuoteItem } from './components';

class Quote extends React.Component {
  constructor(props) {
    super(props);
    const { quotes } = props;

    this.state = {
      currentTab: quotes.length ? quotes.length - 1 : 0,
      quotes: quotes.length ? quotes : [false],
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.quotes !== this.props.quotes) {
      this.setState({
        quotes: nextProps.quotes,
      });
    }
  }

  setCurrentTab = (e) => {
    const { index } = e.target.dataset;
    this.setState({ currentTab: Number(index) });
  };

  addQuote = () => {
    const { quotes } = this.state;

    if (quotes.length < 3) {
      this.setState((prevState) => ({
        quotes: [...prevState.quotes, false],
        currentTab: prevState.quotes.length,
      }));
    }
  };

  submitQuote = (request) => {
    const { dispatch, listing } = this.props;
    dispatch(submitQuoteRequest({ request, listingPk: listing.pk }));
  };

  editQuote = ({ request, quoteId }) => {
    const { dispatch, listing } = this.props;
    dispatch(editQuoteRequest({ request, listingPk: listing.pk, quoteId }));
  };

  render() {
    const { listing, userType, amortizationOptions, hideModal } = this.props;
    const { currentTab, quotes } = this.state;
    const canAddQuote = userType.lender && quotes.length < 3;
    return (
      <OfferModalWrapper>
        <OfferModalHeader>
          <OfferModalTitle>Quote</OfferModalTitle>
        </OfferModalHeader>
        <OfferModalContent>
          <OfferModalTabs>
            {quotes.map((_, i) => (
              <OfferModalTab
                onClick={this.setCurrentTab}
                active={currentTab === i}
                key={i}
                data-index={i}
              >
                Quote {i + 1}
              </OfferModalTab>
            ))}
            {canAddQuote && <OfferModalAdd onClick={this.addQuote} />}
          </OfferModalTabs>
          <QuoteItem
            key={currentTab}
            listing={listing}
            quote={quotes[currentTab] || {}}
            userType={userType}
            amortizationOptions={amortizationOptions}
            isSoftQuote={userType.lender && !quotes[currentTab]}
            hideModal={hideModal}
            submitQuote={this.submitQuote}
            editQuote={this.editQuote}
          />
        </OfferModalContent>
      </OfferModalWrapper>
    );
  }
}

function mapStateToProps(state, props) {
  const {
    listing: { listingData },
  } = state;
  const amortizationOptions = getLoanAmortizationChoices(state);
  const userType = userTypeSelector(state);
  return {
    amortizationOptions,
    userType,
    quotes: listingQuotesSelector(state),
    listing: listingData,
  };
}

export default connect(mapStateToProps)(Quote);
