import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { Tag } from './Tag';

/* Tag will new feature */
export const Recent = ({text, handleChooseRecentText}) => (
    <Wrapper onClick = {handleChooseRecentText}>
        <Label>{ text }</Label>
        {/* {word.type === "People" && <Tag isNotClickable name = {word.type}/>} */}
    </Wrapper>
);


const Wrapper = styled.div`
    display: flex;
    align-items: center;

    cursor: pointer;
    margin-bottom: 16px;
    &:last-child{
        margin-bottom: 0;
    }
    &::before{
        content: '';
        position: absolute;
        height: 13px;
        width: 13px;
        background: url(/images/icons/ico_search_white.svg) no-repeat center;
    }
`;

const Label = styled.span`
    padding-left: 22px;
    margin-right: 8px;

    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #1C3353;

`


