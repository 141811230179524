import React from 'react';
import styled from 'styled-components/macro';
import { Field } from 'react-final-form';
import { Input, Condition } from 'components/Form';

const Wrapper = styled.div`
  width: 30%;
  margin-right: 5%;
  margin-top: 16px;

  @media (max-width: ${(p) => p.theme.breakpoints[1]}) {
    width: 45%;
  }

  @media (max-width: ${(p) => p.theme.breakpoints[0]}) {
    width: 100%;
    margin-right: 0;
  }
`;

// todo move to antoher select, it's works with object cause we are referenced to the same imported array
export const SourceOther = ({ source }) => (
  <Condition when="source" is={source[6]}>
    <Wrapper>
      <Field
        component={Input}
        placeholder="a-z"
        label="Other source"
        name="source_other"
        fullWidth
        mb={3}
      />
    </Wrapper>
  </Condition>
);
