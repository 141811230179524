import React from 'react';
import { connect } from 'react-redux';
import ReactModal from 'react-modal';
import { modals } from 'constants/modalTypes';
import { OfferModal } from 'components/OfferModal';
import types from 'constants/actionTypes';

import { ModalClose } from './styles';

import {
  LoginRequest,
  LoanQuestion,
  CancelOffer,
  FinalOffer,
  HideLoanForm,
  RequestDocumentsForm,
  RequestNotifications,
  AnswerModal,
  AnswerForm,
  RejectTermsheetForm,
  EmailAlreadyConfirmed,
  LoanActions,
  TermsheetReject,
  LenderDirectoryAddForm,
  LenderDirectoryAddSuccess,
  InfoModal,
  LoanSectionChange,
  InviteBorrowerTeamMember,
  OfferShareModal,
  QuestionForm,
  ChatDocumentModal,
  RejectOfferModal,
  Quote,
  ShareListing,
  CreateGroupChatModal,
  LenderStatusModal,
  LoanSendToLendersModal,
  LoanCloseSelect,
  LoanDealLostForm,
  LoanDealWonForm,
  AssignDealModal,
  AddContact,
  ConfidentialityAgreementModal,
  SwitchTo,
  ManualOfferModal,
  ResetPasswordRequestSuccess,
  FieldHistory,
  DealRoomDocumentRejectModal,
  DealRoomRequestSignatureModal,
  DealRoomChecklistModal,
  DealRoomDocumentRejectionReasonModal,
  DealRoomAttachFilesModal,
  DuplicateLoan,
  AddClient,
  ForcePasswordChange,
  LocationsSelectionList,
  AskPassword,
  LoanAdditionAddresses,
  ManualTermsheetModal,
  LenderCompanyTeamEditing,
  LenderCompanyTeamInvite,
  AssignLoanModal,
  LenderLoanReminder,
  OpenInviteColleagues,
  CurrentTask,
  ReviewTask,
  RemoveTask,
  GlobalSearch,
  SyncTask,
  StopSyncing,
} from 'components/Modals';

const MODAL_COMPONENTS = {
  [modals.OFFER]: OfferModal,
  [modals.LOGIN_REQUEST]: LoginRequest,
  [modals.LOAN_QUESTION]: LoanQuestion,
  [modals.CANCEL_OFFER]: CancelOffer,
  [modals.FINAL_OFFER]: FinalOffer,
  [modals.HIDE_LOAN_FORM]: HideLoanForm,
  [modals.REQUEST_DOCUMENTS_FORM]: RequestDocumentsForm,
  [modals.REQUEST_NOTIFICATIONS]: RequestNotifications,
  [modals.ANSWER_MODAL]: AnswerModal,
  [modals.ANSWER_FORM]: AnswerForm,
  [modals.REJECT_TERMSHEET_FORM]: RejectTermsheetForm,
  [modals.EMAIL_ALREADY_CONFIRMED]: EmailAlreadyConfirmed,
  [modals.LOAN_ACTIONS]: LoanActions,
  [modals.TERMSHEET_REJECT]: TermsheetReject,
  [modals.LENDER_DIRECTORY_ADD_FORM]: LenderDirectoryAddForm,
  [modals.LENDER_DIRECTORY_ADD_SUCCESS]: LenderDirectoryAddSuccess,
  [modals.INFO_MODAL]: InfoModal,
  [modals.LOAN_SECTION_CHANGE]: LoanSectionChange,
  [modals.INVITE_BORROWER_TEAM_MEMBER]: InviteBorrowerTeamMember,
  [modals.OFFER_SHARE_MODAL]: OfferShareModal,
  [modals.QUESTION_FORM]: QuestionForm,
  [modals.CHAT_DOCUMENT_MODAL]: ChatDocumentModal,
  [modals.REJECT_OFFER_MODAL]: RejectOfferModal,
  [modals.QUOTE_MODAL]: Quote,
  [modals.SHARE_LISTING]: ShareListing,
  [modals.CREATE_GROUP_CHAT_MODAL]: CreateGroupChatModal,
  [modals.LENDER_STATUS_MODAL]: LenderStatusModal,
  [modals.LOAN_SEND_TO_LENDERS_MODAL]: LoanSendToLendersModal,
  [modals.LOAN_CLOSE_SELECT]: LoanCloseSelect,
  [modals.LOAN_DEAL_LOST_FORM]: LoanDealLostForm,
  [modals.LOAN_DEAL_WON_FORM]: LoanDealWonForm,
  [modals.ASSIGN_DEAL_MODAL]: AssignDealModal,
  [modals.ADD_CONTACT]: AddContact,
  [modals.CONFIDENTIALITY_AGREEMENT_MODAL]: ConfidentialityAgreementModal,
  [modals.SWITCH_TO]: SwitchTo,
  [modals.MANUAL_OFFER_MODAL]: ManualOfferModal,
  [modals.RESET_PASSWORD_REQUEST_SUCCESS]: ResetPasswordRequestSuccess,
  [modals.FIELD_HISTORY]: FieldHistory,
  [modals.DEAL_ROOM_DOCUMENT_REJECT_MODAL]: DealRoomDocumentRejectModal,
  [modals.DEAL_ROOM_REQUEST_SIGNATURE_MODAL]: DealRoomRequestSignatureModal,
  [modals.DEAL_ROOM_CHECKLIST_MODAL]: DealRoomChecklistModal,
  [modals.DEAL_ROOM_DOCUMENT_REJECTION_REASON_MODAL]: DealRoomDocumentRejectionReasonModal,
  [modals.DEAL_ROOM_ATTACH_FILES_MODAL]: DealRoomAttachFilesModal,
  [modals.DUPLICATE_LOAN]: DuplicateLoan,
  [modals.ADD_CLIENT]: AddClient,
  [modals.FORCE_PASSWORD_CHANGE]: ForcePasswordChange,
  [modals.LOCATIONS_SELECTION_LIST]: LocationsSelectionList,
  [modals.ASK_PASSWORD]: AskPassword,
  [modals.LOAN_ADDITION_ADDRESSES]: LoanAdditionAddresses,
  [modals.MANUAL_TERMSHEET_MODAL]: ManualTermsheetModal,
  [modals.LENDER_COMPANY_TEAM_EDITING]: LenderCompanyTeamEditing,
  [modals.LENDER_COMPANY_TEAM_INVITE]: LenderCompanyTeamInvite,
  [modals.ASSIGN_LOAN_MODAL]: AssignLoanModal,
  [modals.LENDER_LOAN_REMINDER]: LenderLoanReminder,
  [modals.OPEN_INVITE_COLLEAGUES]: OpenInviteColleagues,
  [modals.CURRENT_TASK_MODAL]: CurrentTask,
  [modals.REVIEW_TASK_MODAL]: ReviewTask,
  [modals.REMOVE_TASK_MODAL]: RemoveTask,
  [modals.GLOBAL_SEARCH]: GlobalSearch,
  [modals.SYNC_TASK_MODAL]: SyncTask,
  [modals.STOP_SYNCING_MODAL]: StopSyncing
};

ReactModal.setAppElement('#root');

const ModalRoot = ({ modalType, modalProps }, hideModal, dispatch, customClose) => {
  if (!modalType) {
    return null;
  }
  const SpecificModal = MODAL_COMPONENTS[modalType];
  if (!SpecificModal) {
    return null;
  }
  return (
    <SpecificModal
      {...modalProps}
      hideModal={customClose || hideModal}
      dispatch={dispatch}
    />
  );
};

export class Modal extends React.Component {
  hideModal = () => {
    const {
      dispatch,
      modalProps: { onModalHide },
    } = this.props;

    if (onModalHide && typeof onModalHide === 'function') {
      onModalHide();
    }

    dispatch({ type: types.HIDE_MODAL });
  };

  render() {
    const { modalType, modalProps, dispatch } = this.props;
    return (
      <ReactModal
        isOpen={!!modalType || false}
        onRequestClose={
          modalProps.customClose || (!modalProps.disableClose && this.hideModal)
        }
        contentLabel="Example Modal"
        className={`Modal withExpand
          ${modalProps.size || ''} 
          ${modalProps.overflow || ''}
          ${modalProps.topPosition || ''}`
        }
        overlayClassName="Overlay"
        style={
          modalProps.width ? { content: { width: `${modalProps.width}px` } } : {}
      }
      >
        <div data-testid="modal">
          {!(modalProps.hideClose || modalProps.disableClose) && (
            <ModalClose data-testid="closeModal" type="button" onClick={this.hideModal} />
          )}
          {ModalRoot(
            { modalType, modalProps },
            this.hideModal,
            dispatch,
            modalProps.customClose
          )}
        </div>
      </ReactModal>
    );
  }
}

function mapStateToProps(state) {
  return {
    ...state.modal,
  };
}

export default connect(mapStateToProps)(Modal);
