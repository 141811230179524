import types from 'constants/actionTypes';

export const setFilterInput = (payload) => ({ type: types.SET_FILTER_INPUT, payload });
export const setFilters = (payload) => ({ type: types.SET_FILTERS, payload });
export const updateFilter = (payload) => ({ type: types.UPDATE_FILTER, payload });
export const setMarketplaceOrdering = (payload) => ({
  type: types.SET_MARKETPLACE_ORDERING,
  payload,
});
export const resetLoans = () => ({ type: types.RESET_LOANS });

export const marketplaceDataRequest = (payload = {}) => ({
  type: types.MARKETPLACE_DATA_REQUEST,
  payload,
});
export const marketplaceDataSuccess = (payload = {}) => ({
  type: types.MARKETPLACE_DATA_SUCCESS,
  payload,
});
export const marketplaceDataFailure = (payload = {}) => ({
  type: types.MARKETPLACE_DATA_FAILURE,
  payload,
});

export const updateMarketplaceDataRequest = (payload = {}) => ({
  type: types.UPDATE_MARKETPLACE_DATA_REQUEST,
  payload,
});
export const updateMarketplaceDataSuccess = (payload = {}) => ({
  type: types.UPDATE_MARKETPLACE_DATA_SUCCESS,
  payload,
});
export const updateMarketplaceDataFailure = (payload = {}) => ({
  type: types.UPDATE_MARKETPLACE_DATA_FAILURE,
  payload,
});
