export const actionItemsFilterOptions = [
  { value: '', label: 'All' },
  { value: 'new', label: 'New' },
  { value: 'offer', label: 'Quote' },
  { value: 'termsheet', label: 'Term sheet' },
  { value: 'document', label: 'Document Request' },
  { value: 'hidden', label: 'Hidden' },
  { value: 'passed', label: 'Passed' },
  { value: 'dealroom', label: 'Deal room' },
];

export const USER_FEED_TYPE = {
  MY_TASKS: 'my_tasks',
  BB_TEAM_ACTIVITIES: 'team_activities',
  LENDER_TEAM_ACTIVITIES: 'company_activities',
};
