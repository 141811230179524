export const takeOfferData = (negotiation, userId, accountType) => {
  if (!negotiation || negotiation.offers.length === 0) {
    return false;
  }
  if (negotiation.offers.length === 1 && accountType !== 3) {
    return false;
  }
  const { offers } = negotiation;
  const filteredOffers = offers.filter((offer) => offer.isMy);
  return filteredOffers[filteredOffers.length - 1];
};
