import types from 'constants/actionTypes';

export const listingsRequest = (payload = {}) => ({
  type: types.LISTINGS_REQUEST,
  payload,
});
export const listingsSuccess = (payload = {}) => ({
  type: types.LISTINGS_SUCCESS,
  payload,
});
export const listingsFailure = (payload = {}) => ({
  type: types.LISTINGS_FAILURE,
  payload,
});

export const additionalListingsRequest = (payload = {}) => ({
  type: types.ADDITIONAL_LISTINGS_REQUEST,
  payload,
});
export const additionalListingsSuccess = (payload = {}) => ({
  type: types.ADDITIONAL_LISTINGS_SUCCESS,
  payload,
});
export const additionalListingsFailure = (payload = {}) => ({
  type: types.ADDITIONAL_LISTINGS_FAILURE,
  payload,
});
