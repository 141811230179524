import React from 'react';
import styled from 'styled-components/macro';
import {
  ModalBody,
  ModalHeading,
  ModalWithContentWrapper,
  ModalActions,
} from 'ui/Modal/styles';
import { BORROWER } from 'constants/appRelated';
import { UserAvatar } from 'components/UserAvatar';
import { Text } from 'ui/Text';
import { Radio } from 'ui/Radio';
import { Button, ButtonGhost } from 'ui/Button';
import { assignLoanRequest } from 'features/MyLoans/actions';

export class AssignDealModal extends React.Component {
  state = { selectedId: this.props?.currentOwner?.id || null };

  selectMember = (id) => {
    if (this.state.selectedId !== id) {
      this.setState({ selectedId: id });
    }
  };

  submit = () => {
    const { loanId, dispatch, hideModal } = this.props;
    const { selectedId } = this.state;
    hideModal();
    dispatch(assignLoanRequest({ teamId: selectedId, loanId }));
  };

  render() {
    const { team, hideModal } = this.props;
    const { selectedId } = this.state;
    return (
      <ModalWithContentWrapper>
        <ModalHeading>
          Select a team member to assign <br /> this deal
        </ModalHeading>
        <ModalBody>
          {team.map((member) => (
            <TeamMember
              key={member.userId}
              onClick={() => {
                this.selectMember(member.userId);
              }}
            >
              <UserAvatar author={{ initials: member.initials, accountType: BORROWER }} />
              <MemberInfo>
                <Text>
                  {member.firstName} {member.lastName}
                </Text>
                {member.title && <MemberTitle>{member.title}</MemberTitle>}
              </MemberInfo>
              <Radio checked={selectedId === member.userId} />
            </TeamMember>
          ))}
        </ModalBody>
        <Controls>
          <ButtonGhost type="button" onClick={hideModal}>
            Cancel
          </ButtonGhost>
          <Button secondary disabled={!selectedId} onClick={this.submit}>
            Assign
          </Button>
        </Controls>
      </ModalWithContentWrapper>
    );
  }
}

const TeamMember = styled.div`
  display: flex;
  align-items: center;
  padding: 16px 0;
  cursor: pointer;
  border-top: 1px solid #e8e9ed;

  &:last-child {
    border-bottom: 1px solid #e8e9ed;
  }

  &:hover {
    background-color: #f6fafe;
  }
`;

const MemberInfo = styled.div`
  margin-left: 8px;
  margin-right: auto;
`;

const MemberTitle = styled(Text)`
  font-size: 12px;
  color: #778598;
`;

const Controls = styled(ModalActions)`
  ${Button} {
    margin-left: 16px;
  }
`;
