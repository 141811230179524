import types from 'constants/actionTypes';

const initialState = { prefilledValues: {}, isFetched: false };

export default function reducer(state, action = {}) {
  const currentState = state || initialState;

  const { type, payload } = action;

  switch (type) {
    case types.GET_PROFILE_INFO_SUCCESS:
      return {
        ...currentState,
        prefilledValues: payload,
        isFetched: true,
      };

    case types.FINISH_REGISTRATION_REQUEST:
      return initialState;

    default:
      return currentState;
  }
}
