import types from 'constants/actionTypes';
import immutable from 'object-path-immutable';
import { paginationTmp } from 'config/paginationTmp';

const initialState = {
  listings: [],
  ...paginationTmp,
};

export default function reducer(state, action = {}) {
  const currentState = state || initialState;

  const { type, payload } = action;

  switch (type) {
    case types.LISTINGS_SUCCESS:
      return immutable(currentState)
        .set('listings', payload.results)
        .set('next', payload.next)
        .set('previous', payload.previous)
        .set('pageSize', payload.pageSize)
        .set('pageNumber', payload.pageNumber)
        .set('count', payload.count)
        .value();

    case types.ADDITIONAL_LISTINGS_SUCCESS:
      return immutable(currentState)
        .set('listings', [...currentState.loans, ...payload.results])
        .set('next', payload.next)
        .value();

    default:
      return currentState;
  }
}
