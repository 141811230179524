export const asyncActions = [
  'GET_REALATOM_LENDERS_DATA',
  'SEND_LOAN_TO_LENDERS',
  'GET_RELATIONSHIP_LENDERS',
  'UPDATE_RELATIONSHIP_LENDERS',
];
export const syncActions = [
  'SET_SEND_LOAN_TO_LENDER_SEARCH_STRING',
  'SELECT_RELATIONSHIP_LENDER',
  'SELECT_ALL_RELATIONSHIP_LENDERS',
  'SELECT_PAGE_RELATIONSHIP_LENDERS',
  'SELECT_NONE_RELATIONSHIP_LENDERS',
  'CHANGE_RELATIONSHIP_LENDER_SORT',
  'SELECT_REAL_ATOM_LENDER',
  'EXCLUDE_COMPANY_ADD',
  'EXCLUDE_COMPANY_REMOVE',
  'EXCLUDE_COMPANY_UPDATE',
  'SEND_TO_LENDERS_RESET',
  'EXCLUDE_ALL_LENDERS',
];
