const config = {
  development: {
    API_HOST: 'https://api.dev0.realatom.com/api',
    API_HOST_RAW: 'https://api.dev0.realatom.com',
    WS_HOST: 'wss://api.dev0.realatom.com',
    CLIENT_HOST: 'https://dev0.realatom.com', // same domain
    STRIPE_KEY: 'pk_test_lgpQhFoelrIfs67CLNxbsrEu',
    LOGROCKET_NAME: 'realatom/localhost-xyjrk',
    // FCM
    FIREBASE_DATA: {
      apiKey: 'AIzaSyBQusfM-GayvaLXIfDNvx9g-VlavybadKs',
      authDomain: 'realatom-qa.firebaseapp.com',
      databaseURL: 'https://realatom-qa.firebaseio.com',
      projectId: 'realatom-qa',
      storageBucket: 'realatom-qa.appspot.com',
      messagingSenderId: '559280711249',
    },
    SENTRY_DSN: 'https://2c6dca712ccc480880de4893be0acdb7@sentry.io/156030',
  },
  test: {
    API_HOST: '',
    CLIENT_HOST: 'http://localhost:8080',
  },
  production: {
    API_HOST: 'https://api.app.realatom.com/api',
    API_HOST_RAW: 'https://api.app.realatom.com',
    WS_HOST: 'wss://api.app.realatom.com',
    CLIENT_HOST: 'https://app.realatom.com', // same domain
    STRIPE_KEY: 'pk_live_VHX46ffFuTXwlm4w5I8ykTKu',
    // FCM
    FIREBASE_DATA: {
      apiKey: 'AIzaSyAtFjwTJdnwUFiiZKS9LtYmgaNfV_AZGZE',
      authDomain: 'realatom-5ff5b.firebaseapp.com',
      databaseURL: 'https://realatom-5ff5b.firebaseio.com',
      projectId: 'realatom-5ff5b',
      storageBucket: 'realatom-5ff5b.appspot.com',
      messagingSenderId: '890274323638',
    },
    SENTRY_DSN: 'https://bd9eda0813c3452b92f5531bf4ca5d51@sentry.io/156296',
  },
  staging: {
    API_HOST: 'https://api.dev0.realatom.com/api',
    API_HOST_RAW: 'https://api.dev0.realatom.com',
    WS_HOST: 'wss://api.dev0.realatom.com',
    CLIENT_HOST: 'https://dev0.realatom.com', // same domain
    STRIPE_KEY: 'pk_test_lgpQhFoelrIfs67CLNxbsrEu',
    LOGROCKET_NAME: 'realatom/dev-et7jr',
    // FCM
    FIREBASE_DATA: {
      apiKey: 'AIzaSyBQusfM-GayvaLXIfDNvx9g-VlavybadKs',
      authDomain: 'realatom-qa.firebaseapp.com',
      databaseURL: 'https://realatom-qa.firebaseio.com',
      projectId: 'realatom-qa',
      storageBucket: 'realatom-qa.appspot.com',
      messagingSenderId: '559280711249',
    },
    SENTRY_DSN: 'https://2c6dca712ccc480880de4893be0acdb7@sentry.io/156030',
  },
  demo: {
    API_HOST: 'https://api.demo.realatom.com/api',
    API_HOST_RAW: 'https://api.demo.realatom.com',
    WS_HOST: 'wss://api.demo.realatom.com',
    CLIENT_HOST: 'https://demo.realatom.com', // same domain
    STRIPE_KEY: 'pk_test_lgpQhFoelrIfs67CLNxbsrEu',
    // FCM
    FIREBASE_DATA: {
      apiKey: 'AIzaSyBQtsvJHTLJgeRTTsESlYGkY2oc6poj5Pw',
      authDomain: 'realaromdemo.firebaseapp.com',
      databaseURL: 'https://realaromdemo.firebaseio.com',
      projectId: 'realaromdemo',
      storageBucket: 'realaromdemo.appspot.com',
      messagingSenderId: '462539153654',
    },
  },

  release: {
    // currently release same as qua
    API_HOST: 'https://api.release.realatom.com/api',
    API_HOST_RAW: 'https://api.release.realatom.com',
    WS_HOST: 'wss://api.release.realatom.com',
    CLIENT_HOST: 'https://release.realatom.com', // same domain
    STRIPE_KEY: 'pk_test_lgpQhFoelrIfs67CLNxbsrEu',
    // FCM
    FIREBASE_DATA: {
      apiKey: 'AIzaSyB3cZehJ1s5k1KUHftc8zl271Z-Jyj6tcs',
      authDomain: 'realatom-release.firebaseapp.com',
      databaseURL: 'https://realatom-release.firebaseio.com',
      projectId: 'realatom-release',
      storageBucket: 'realatom-release.appspot.com',
      messagingSenderId: '564582694822',
    },
    SENTRY_DSN: 'https://2c6dca712ccc480880de4893be0acdb7@sentry.io/156030',
  },
};

export default config[process.env.REACT_APP_ENV];
