import { put, takeLatest, call, select } from 'redux-saga/effects';
import types from 'constants/actionTypes';
import * as API from 'api';
import { getError } from 'sagas/utils';
import { getPaginationPage } from 'lib/strings';
import { userTypeSelector } from 'selectors/userType';
import { normalize } from 'normalizr';
import { createNormalizeSchema } from 'lib/utils';
import { openDocumentInNewWindow } from 'lib/browser';

import { USER_FEED_TYPE } from './config';
import { NOTIFICATION_TYPES } from './constants';

import {
  dashboardNotificationsSuccess,
  dashboardNotificationsFailure,
  notificationAnswerQuestionSuccess,
  notificationAnswerQuestionFailure,
  notificationUploadDocumentSuccess,
  notificationUploadDocumentFailure,
  removeNotificationSuccess,
  removeNotificationFailure,
  uploadLenderDocumentSuccess,
  uploadLenderDocumentFailure,
  answerLenderCommentSuccess,
  answerLenderCommentFailure,
  getMoreDashboardActionItemsSuccess,
  getMoreDashboardActionItemsFailure,
  updateUserDashboardFeedTypeSuccess,
  updateUserDashboardFeedTypeFailure,
  signCaAndOpenDaiDocSuccess,
  signCaAndOpenDaiDocFailure,
  notificationUpdateDocumentProtectionSuccess,
  notificationUpdateDocumentProtectionFailure,
} from './actions';

const getFeedAPIPath = (feedType, isLender) => {
  if (feedType === USER_FEED_TYPE.MY_TASKS) {
    return '/user_dashboard/action-items/';
  } else if (isLender) {
    return '/events/company';
  } else {
    return '/events/team';
  }
};

function* getDashboardNotifications() {
  try {
    const state = yield select();
    const userType = userTypeSelector(state);
    let feedType =
      (window.localStorage &&
        localStorage.getItem(`${state.currentUser.email}.feedType`)) ||
      USER_FEED_TYPE.MY_TASKS;

    const {
      data: { response },
    } = yield call(
      API.axiosApi.get,
      `${getFeedAPIPath(feedType, userType.lender)}?page=1`
    );

    yield put(
      dashboardNotificationsSuccess({
        notifications: response.results,
        next: response.next,
        feedType,
      })
    );
  } catch (e) {
    console.error(e);
    yield put(dashboardNotificationsFailure({ message: getError(e) }));
  }
}

function* answerQuestion(action) {
  try {
    const { answer, loanId, id, notificationId } = action.payload;

    yield call(API.axiosApi.patch, `loans/${loanId}/questions/${id}`, {
      answer,
      is_public: false,
    });

    yield call(API.axiosApi.patch, `loan-questions/${id}/mark_read`, {
      question_read: true,
    });

    yield put(notificationAnswerQuestionSuccess({ notificationId }));
  } catch (e) {
    console.error(e);
    yield put(notificationAnswerQuestionFailure({ message: getError(e) }));
  }
}

function* uploadDocument(action) {
  const { form, notificationId, id, folderId } = action.payload;
  try {
    yield call(API.axiosApi.post, `/loans/${id}/folders/${folderId}/documents`, form);

    yield put(
      notificationUploadDocumentSuccess({
        notificationId,
        folderId,
      })
    );
  } catch (e) {
    console.error(e);
    yield put(notificationUploadDocumentFailure({ message: getError(e) }));
  }
}

function* removeNotification(action) {
  try {
    const { id } = action.payload;

    yield call(API.axiosApi.patch, `/user_dashboard/action-items/${id}`, {
      hidden: true,
    });

    yield put(
      removeNotificationSuccess({
        id,
      })
    );
  } catch (e) {
    console.error(e);
    yield put(removeNotificationFailure({ message: getError(e) }));
  }
}

function* uploadLenderDocument(action) {
  try {
    const { file, id } = action.payload;
    yield call(API.axiosApi.patch, `lender-document/${id}`, file);

    yield put(uploadLenderDocumentSuccess(id));
  } catch (e) {
    console.error(e);
    yield put(uploadLenderDocumentFailure({ message: getError(e) }));
  }
}

function* answerLenderComment(action) {
  try {
    const { answer, id } = action.payload;
    yield call(API.axiosApi.patch, `lender-verification-comment/${id}`, { answer });

    yield put(answerLenderCommentSuccess({ id, answer }));
  } catch (e) {
    console.error(e);
    yield put(answerLenderCommentFailure({ message: getError(e) }));
  }
}

function* getMoreDashboardActionItems() {
  try {
    const state = yield select();
    const {
      dashboardActionsItems: { actionItemsFilter, nextActionItems, userFeedType },
    } = state;
    const page = getPaginationPage(nextActionItems);
    const userType = userTypeSelector(state);
    const route = getFeedAPIPath(userFeedType, userType.lender);

    const {
      data: { response },
    } = yield call(
      API.axiosApi.get,
      `${route}?page=${page || 1}${(actionItemsFilter &&
        `&filter=${actionItemsFilter}`) ||
        ''}`
    );

    yield put(
      getMoreDashboardActionItemsSuccess({
        notifications: response.results,
        next: response.next,
        page,
      })
    );
  } catch (e) {
    console.error(e);
    yield put(getMoreDashboardActionItemsFailure({ message: getError(e) }));
  }
}

function* filterDashboardActionItems() {
  try {
    const state = yield select();
    const {
      dashboardActionsItems: { actionItemsFilter, userFeedType, bbTeammateFilter },
    } = state;
    const userType = userTypeSelector(state);
    const route = getFeedAPIPath(userFeedType, userType.lender);

    let filterParam = userType.lender ? '&lender=' : '&bb=';
    const {
      data: { response },
    } = yield call(
      API.axiosApi.get,
      `${route}?page=1${(actionItemsFilter && `&filter=${actionItemsFilter}`) ||
        ''}${(bbTeammateFilter && `${filterParam}${bbTeammateFilter}`) || ''}`
    );

    yield put(
      getMoreDashboardActionItemsSuccess({
        notifications: response.results,
        next: response.next,
      })
    );
  } catch (e) {
    console.error(e);
    yield put(getMoreDashboardActionItemsFailure({ message: getError(e) }));
  }
}

function* updateUserDashboardFeedType(action) {
  try {
    const { type } = action.payload;
    const state = yield select();
    const {
      dashboardActionsItems: { actionItemsFilter },
      currentUser,
    } = yield select();

    const userType = userTypeSelector(state);

    const route = getFeedAPIPath(type, userType.lender);

    if (window.localStorage) {
      localStorage.setItem(`${currentUser.email}.feedType`, type);
    }

    const {
      data: { response },
    } = yield call(
      API.axiosApi.get,
      `${route}?page=1${(actionItemsFilter && `&filter=${actionItemsFilter}`) || ''}`
    );

    yield put(
      updateUserDashboardFeedTypeSuccess({
        notifications: response.results,
        next: response.next,
        feedType: type,
      })
    );
  } catch (e) {
    console.error(e);
    yield put(updateUserDashboardFeedTypeFailure({ message: getError(e) }));
  }
}

function* signCaAndOpenDaiDoc(action) {
  try {
    const { daiId, loanId } = action.payload;
    const {
      dashboardActionsItems: { actionItems },
    } = yield select();

    yield call(API.axiosApi.post, `loans/${loanId}/agreements`, {});

    const privateDocDais = actionItems
      .filter(
        ({ type, body }) =>
          body?.loan?.pk === loanId &&
          (type === NOTIFICATION_TYPES.REQUESTED_DOCUMENT_UPLOADED ||
            type === NOTIFICATION_TYPES.BORROWER_UPLOADED_NEW_DOCUMENT) &&
          !body.link
      )
      .map(({ pk }) => pk);

    const {
      data: {
        response: { results },
      },
    } = yield call(
      API.axiosApi.get,
      `/user_dashboard/action-items/?ids=${privateDocDais.join(',')}`
    );

    const {
      entities: { actionItems: normalizedActionItems },
    } = normalize(
      results,
      createNormalizeSchema({ name: 'actionItems', idAttribute: 'pk' })
    );

    openDocumentInNewWindow(normalizedActionItems[daiId].body.link);

    yield put(signCaAndOpenDaiDocSuccess({ updatedActionsItems: normalizedActionItems }));
  } catch (e) {
    console.log(e);
    yield put(signCaAndOpenDaiDocFailure({ message: getError(e) }));
  }
}

function* notificationUpdateDocumentProtection(action) {
  try {
    const { folderId, loanId, protectionState, notificationId } = action.payload;
    yield call(API.axiosApi.patch, `loans/${loanId}/folders/${folderId}`, {
      protected: protectionState,
    });
    yield put(
      notificationUpdateDocumentProtectionSuccess({
        notificationId,
        folderId,
        protected: protectionState,
      })
    );
  } catch (e) {
    yield put(notificationUpdateDocumentProtectionFailure({ message: getError(e) }));
  }
}

export default [
  takeLatest(types.DASHBOARD_NOTIFICATIONS_REQUEST, getDashboardNotifications),
  takeLatest(types.NOTIFICATION_ANSWER_QUESTION_REQUEST, answerQuestion),
  takeLatest(types.NOTIFICATION_UPLOAD_DOCUMENT_REQUEST, uploadDocument),
  takeLatest(types.REMOVE_NOTIFICATION_REQUEST, removeNotification),
  takeLatest(types.UPLOAD_LENDER_DOCUMENT_REQUEST, uploadLenderDocument),
  takeLatest(types.ANSWER_LENDER_COMMENT_REQUEST, answerLenderComment),
  takeLatest(types.GET_MORE_DASHBOARD_ACTION_ITEMS_REQUEST, getMoreDashboardActionItems),
  takeLatest(types.FILTER_DASHBOARD_ACTION_ITEMS_REQUEST, filterDashboardActionItems),
  takeLatest(types.UPDATE_USER_DASHBOARD_FEED_TYPE_REQUEST, updateUserDashboardFeedType),
  takeLatest(types.SIGN_CA_AND_OPEN_DAI_DOC_REQUEST, signCaAndOpenDaiDoc),
  takeLatest(
    types.NOTIFICATION_UPDATE_DOCUMENT_PROTECTION_REQUEST,
    notificationUpdateDocumentProtection
  ),
];
