import React, { Component } from 'react';
import styled from 'styled-components/macro';

const Controls = styled.div`
  cursor: pointer;
  margin-bottom: 10px;
  font-size: 14px;
  color: #778598;
`;

export default class Toggle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toggled: false,
    };
  }

  render() {
    const { children } = this.props;
    const { toggled } = this.state;
    return (
      <div>
        <Controls
          onClick={() => {
            this.setState((prevState) => ({ toggled: !prevState.toggled }));
          }}
        >
          {toggled ? '- Hide additional info' : '+ Show additional info'}
        </Controls>
        {toggled && children}
      </div>
    );
  }
}
