import types from 'constants/actionTypes';

const initialState = {
  error: '',
};

export function confirmAuth(state, action = {}) {
  const currentState = state || initialState;

  const { type, payload } = action;
  switch (type) {
    case types.CONFIRM_AUTH_FAILURE:
      return { ...currentState, error: payload.error };
    case types.RESET_CONFIRM_AUTH:
      return initialState;
    default:
      return currentState;
  }
}
