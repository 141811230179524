import types from 'constants/actionTypes';
import * as API from 'api';
import { getLoanActivityItemsSuccess, getLoanActivityItemsFailure } from './actions';
import { put, takeLatest, call, select } from 'redux-saga/effects';
import { getError } from 'sagas/utils';
import { userTypeSelector } from 'selectors/userType';
import { USER_FEED_TYPE } from '../../features/DashboardActionItems/config';


const getFeedAPIPath = (feedType, isLender) => {
    if (feedType === USER_FEED_TYPE.MY_TASKS) {
      return '/user_dashboard/action-items/';
    } else if (isLender) {
      return '/events/company';
    } else {
      return '/events/team';
    }
  };

function* getLoanActivityItems(action) {
    try {
      const { uuid, filter, nextPage, feedType } = action.payload;
      const state = yield select();
  
      const userType = userTypeSelector(state);
      const route = getFeedAPIPath(feedType, userType.lender);
      const actionFilter = filter ? userType.lender ? `&lender=${filter}` : `&bb=${filter}` : '';
  
      const request = `${route}?page=${nextPage || 1}` + 
                      `${actionFilter ? `${actionFilter}`: ''}` + 
                      `${uuid ? `&loan=${uuid}` : ''}`
    
      const {
        data: { response },
      } = yield call(
        API.axiosApi.get, request);
  
      yield put(
        getLoanActivityItemsSuccess({
          notifications: response.results,
          next: response.next,
          page: nextPage,
        })
      );
    } catch (e) {
      console.error(e);
      yield put(getLoanActivityItemsFailure({ message: getError(e) }));
    }
  }

export default [
    takeLatest(types.GET_LOAN_ACTIVITY_ITEMS_REQUEST, getLoanActivityItems),
];
