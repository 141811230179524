import styled from 'styled-components/macro';

export const WorkPhoneWrapper = styled.div`
  display: flex;
`;

export const WorkPhone = styled.div`
  flex: 2;
`;

export const WorkPhoneExt = styled.div`
  flex: 1;
  margin-left: 16px;
  margin-top: 29px;
`;
