import React, { useState } from 'react';
import styled, { css } from 'styled-components/macro';
import { Text } from 'ui/Text';
import {
  CheckIcon,
  AttachVerticalIcon,
  BucketIcon,
  PlusIconBoldSmall,
} from 'components/SvgIcons';

import { DueToDate } from './';

export const DealDocumentRequest = ({
  children,
  userType,
  openAttachFileModal,
  markDealDocumentRequestComplete,
  checklistId,
  deleteDealDocument,
  handleDDRIncomplete,
  request,
}) => {
  const [attachesVisible, toggleAttachesVisibility] = useState(
    request.hasRejectedDocuments || request.documents.length === 1
  );

  let requestAction;

  const handleMarkComplete = () => {
    markDealDocumentRequestComplete({
      requestId: request.id,
      checklistId,
      isEmpty: !request.documents.length,
      hasNewDocuments: request.hasNewDocuments,
      name: request.name,
    });
  };

  /*
    Condition to defining current actual actions for deal document request
  */

  if (request.completed) {
    requestAction = userType.lender ? (
      <CompletedBtn
        data-request={request.id}
        data-checklist={checklistId}
        onClick={handleDDRIncomplete}
      >
        COMPLETED
        <CheckIcon color="#fff" />
      </CompletedBtn>
    ) : (
      <CompleteLabel>
        COMPLETED
        <CheckIcon color="#fff" />
      </CompleteLabel>
    );
  } else if (userType.lender) {
    requestAction = (
      <SectionBtn onClick={handleMarkComplete}>
        MARK COMPLETE
        <CheckIcon color="#778598" />
      </SectionBtn>
    );
  } else {
    requestAction = (
      <AttacheFileBtn
        data-request={request.id}
        data-checklist={checklistId}
        onClick={openAttachFileModal}
      >
        <PlusIconBoldSmall />
        ATTACH FILES
      </AttacheFileBtn>
    );
  }

  return (
    <Wrapper completed={request.completed} data-testid="dealDocumentItem">
      <Head>
        <div>
          <Text>{request.name}</Text>
          {request.closingDate && <DueToDate closingDate={request.closingDate} />}
        </div>
        {userType.lender && (
          <DeleteBtn
            data-request={request.id}
            data-checklist={checklistId}
            data-testid="dealDocumentDltBtn"
            onClick={deleteDealDocument}
          >
            <BucketIcon width="11" height="12" color="#778598" />
          </DeleteBtn>
        )}
      </Head>
      <Actions>{requestAction}</Actions>
      {!!request.documents.length && (
        <AttachesControls opened={attachesVisible}>
          <AttachesToggle
            onClick={() => {
              toggleAttachesVisibility(!attachesVisible);
            }}
          >
            <AttachVerticalIcon />
            {request.documents.length} ATTACHMENTS
          </AttachesToggle>
        </AttachesControls>
      )}

      {attachesVisible && <div>{children}</div>}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 24px;

  &:not(:last-child) {
    border-bottom: 1px dashed #cfd0d7;
  }
`;

const Head = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 12px;
`;

const SectionBtn = styled.button`
  display: flex;
  align-items: center;
  padding: 6px 8px;
  outline: none;
  border: 1px solid #e8e9ed;
  border-radius: 4px;
  background-color: #fff;
  font-size: 10px;
  color: #778598;
  cursor: pointer;
  z-index: 2;

  &:hover {
    border-color: #4394ea;
  }

  svg {
    position: relative;
    margin-left: 8px;
    bottom: 1px;
  }

  & > * {
    z-index: -1;
  }
`;

const CompletedBtn = styled(SectionBtn)`
  background-color: #44af69;
  color: #fff;
  outline: none;

  svg {
    position: relative;
    margin-left: 8px;
    bottom: 1px;
  }

  &:hover {
    box-shadow: inset 0 4px 10px rgba(194, 198, 210, 0.5);
  }
`;

const CompleteLabel = styled.div`
  display: inline-block;
  padding: 6px 8px;
  outline: none;
  border: 1px solid #e8e9ed;
  border-radius: 4px;
  font-size: 10px;
  background-color: #44af69;
  color: #fff;

  svg {
    position: relative;
    margin-left: 8px;
    bottom: 1px;
  }
`;

const AttachesToggle = styled.button`
  display: flex;
  align-items: center;
  position: relative;
  margin: 0;
  padding: 0 12px 0 0;
  outline: none;
  border: none;
  background-color: #fff;
  font-size: 10px;
  cursor: pointer;

  svg {
    margin-right: 8px;
  }

  &:hover {
    text-decoration: underline;
  }

  &::after {
    content: url(/images/icons/ico_chevron_small_down.svg);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
  }
`;

const AttachesControls = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;

  ${(p) =>
    p.opened &&
    css`
      padding-bottom: 16px;
      border-bottom: 1px solid #e8e9ed;
      margin-bottom: 16px;

      ${AttachesToggle} {
        &::after {
          content: url(/images/icons/ico_chevron_small_up.svg);
        }
      }
    `}
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
`;

const DeleteBtn = styled.button`
  margin: 0 0 0 6px;
  padding: 0;
  border: none;
  outline: none;
  background-color: #fff;
  cursor: pointer;
  z-index: 2;

  svg {
    position: relative;
    z-index: -1;
  }
`;

const AttacheFileBtn = styled.button`
  display: flex;
  align-items: center;
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
  background-color: #fff;
  cursor: pointer;
  color: #4394ea;
  font-size: 10px;

  &:hover {
    text-decoration: underline;
  }

  svg {
    margin-right: 8px;
  }
`;
