import React from 'react';
import { LOAN_STATUS } from 'constants/appRelated';
import { LoanNotification } from 'styles/loan';

const DraftPanel = ({ status }) => {
  if (status === LOAN_STATUS.DRAFT) {
    return <LoanNotification variant="draft">Your loan in draft</LoanNotification>;
  }
  if (status === LOAN_STATUS.SUBMITTED_FOR_VERIFICATION) {
    return (
      <LoanNotification variant="warn">Your loan under verification</LoanNotification>
    );
  }
  if (status === LOAN_STATUS.VERIFICATION_FAILED) {
    return <LoanNotification variant="failed">Verification Failed</LoanNotification>;
  }
  return null;
};

export { DraftPanel };
