import { limitDigits } from './utils';
import { normalizeInteger } from './normalizeInteger';

export const normalizeIntegerLimit = (value) => {
  if (!value) {
    return value;
  }

  return limitDigits(normalizeInteger(value));
};
