import React from 'react';
import styled from 'styled-components/macro';
import { keys, map, filter } from 'ramda';
import { LOAN_STATUS, OFFER_STATE } from 'constants/appRelated';
import { Button } from 'ui/Button';
import { Link } from 'react-router-dom';

const ControlsWrapper = styled.div`
  display: flex;
`;

export const BorowerPanel = ({
  loan,
  accountType,
  openOfferModal,
  openCompareOffersModal,
  uploadedTermsheets,
}) => {
  const { status, dealRooms } = loan;
  const hasDealRooms = dealRooms && dealRooms.length;
  // Filter negotiations with cancelled offers
  const negotiations = filter(
    (item) => !!item.negotiations.length,
    map(
      (negotiation) => ({
        ...negotiation,
        negotiations: negotiation.negotiations.filter(
          ({ state }) => state !== OFFER_STATE.CANCELLED
        ),
      }),
      loan.negotiations
    )
  );

  if (status === LOAN_STATUS.POSTED_TO_MARKETPLACE) {
    const negotationsKeys = keys(negotiations);
    const authorId = negotationsKeys[0];

    if (negotationsKeys.length || hasDealRooms) {
      if (hasDealRooms) {
        return (
          <ControlsWrapper>
            {dealRooms.length === 1 ? (
              <Link
                to={`/dashboard/property-details/${loan.id}/deal-room/${dealRooms[0].id}/all`}
              >
                <Button key="Deal Room">Go to Deal room</Button>
              </Link>
            ) : (
              <Link to={`/dashboard/property-details/${loan.id}/deal-rooms`}>
                <Button key="Deal Room">Go to Deal rooms</Button>
              </Link>
            )}
          </ControlsWrapper>
        );
      }

      if (uploadedTermsheets?.length > 0) {
        return (
          <ControlsWrapper>
            <Link to={`/dashboard/property-details/${loan.id}/termsheets`}>
              <Button key="Deal Room">View term sheets</Button>
            </Link>
          </ControlsWrapper>
        );
      }

      if (negotationsKeys.length > 1) {
        return (
          <ControlsWrapper>
            <Button key="Compare Quotes" onClick={openCompareOffersModal}>
              Compare Quotes
            </Button>
          </ControlsWrapper>
        );
      }

      return (
        <ControlsWrapper>
          <Button
            key="View quote"
            onClick={() =>
              openOfferModal(
                loan,
                authorId,
                negotiations[authorId].negotiations[0].pk,
                accountType
              )
            }
          >
            View quote
          </Button>
        </ControlsWrapper>
      );
    }

    return (
      <ControlsWrapper>
        <Link to={`/dashboard/loan-creation/${loan.id}/send-to-lenders/more`}>
          <Button>Send to lenders</Button>
        </Link>
      </ControlsWrapper>
    );
  }

  if (status === LOAN_STATUS.PENDING_CLOSING) {
    const negotationsKeys = keys(negotiations);

    if (negotationsKeys.length) {
      if (hasDealRooms) {
        return dealRooms.length === 1 ? (
          <Link
            to={`/dashboard/property-details/${loan.id}/deal-room/${dealRooms[0].id}/all`}
          >
            <Button key="Deal Room">Go to Deal room</Button>
          </Link>
        ) : (
          <Link to={`/dashboard/property-details/${loan.id}/deal-rooms`}>
            <Button key="Deal Room">Go to Deal rooms</Button>
          </Link>
        );
      }
    }
  }

  return null;
};
