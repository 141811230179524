import types from 'constants/actionTypes';
import immutable from 'object-path-immutable';
import { clone } from 'ramda';

const initialState = {
  lenders: {},
  lendersArray: [],
  activity: [],
  postedLoans: [],
  matchedLenders: null,
  searchString: '',
  isFetched: false,
  loan: {},
  tags: [],
};

export default function lenderDirectory(state, action = {}) {
  const currentState = state || clone(initialState);

  const { type, payload } = action;

  switch (type) {
    case types.LENDER_DIRECTORY_INVITE_SUCCESS:
      return immutable(currentState)
        .update('lenders', (lenders) => ({ ...payload.lenders, ...lenders }))
        .update('lendersArray', (lendersArray) => [
          ...payload.lendersArray,
          ...lendersArray,
        ])
        .value();

    case types.SET_LENDERS_SEARCH_STRING:
      return immutable.set(currentState, 'searchString', payload);

    case types.GET_LENDERS_DIRECTORY_DATA_SUCCESS:
      return immutable(currentState)
        .set('lenders', payload.lenders || {})
        .set('lendersArray', payload.lendersArray)
        .set('pageNumber', payload.pageNumber)
        .set('count', payload.count)
        .set('tags', payload.tags || [])
        .set('isFetched', true)
        .value();

    case types.UPDATE_LENDERS_DIRECTORY_DATA_SUCCESS:
      return immutable(currentState)
        .set('lenders', payload.lenders || {})
        .set('lendersArray', payload.lendersArray)
        .set('count', payload.count)
        .set('pageNumber', payload.pageNumber)
        .value();

    case types.GET_LENDERS_ACTIVITY_DATA_SUCCESS:
      return immutable.set(currentState, 'activity', payload.response || []);

    case types.RESET_LENDER_DIRECTORY_DATA: {
      return initialState;
    }

    case types.ARCHIVE_LENDER_SUCCESS: {
      return {
        ...state,
        lendersArray: state.lendersArray.filter((id) => id !== Number(payload.lenderId)),
      };
    }
    case types.GET_MORE_LENDERS_SUCCESS: {
      return immutable(currentState)
        .set('lenders', Object.assign(state.lenders, payload.newLenders) || {})
        .set('lendersArray', [...state.lendersArray, ...payload.newLendersArray])
        .set('pageNumber', payload.pageNumber)
        .set('count', payload.count)
        .set('tags', [...state.tags, ...payload.newTags] || [])
        .set('isFetched', true)
        .value();
    }
    case types.GET_RELATIONSHIP_LENDERS_SUCCESS: {
      return {
        ...state,
        tags: payload.tags,
      };
    }
    default:
      return currentState;
  }
}
