export const asyncActions = [
    'GET_TASKS_DATA', 
    'ORDERING_UPDATE_TASKS_DATA',
    'EDIT_TASK', 
    'ADD_NEW_TASK',
    'GET_TASKS_MATCHING_LOANS',
    'REMOVE_TASK',
    'COMPLETE_TASK',
    'SEEN_TASK',
    'GET_NUMBER_TASKS',
    'GET_TASK_ID',
    'SET_CHOSEN_CALENDARS',
    'GET_CALENDARS',
    'GET_AUTH_CALENDARS',
    'HIDE_CALENDAR_BANNER',
];

export const syncActions = [
    'UPDATE_WEBSOCKET_TASKS',
]