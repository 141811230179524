import styled, { css } from 'styled-components/macro';

import { ReactComponent as Icon } from 'icons/ico_bucket.svg';

export const BucketIcon = styled(Icon)`
  ${(p) =>
    p.hoverColor &&
    css`
      &:hover {
        g {
          stroke: ${p.hoverColor};
        }
      }
    `}

  ${(p) =>
    p.color &&
    css`
      g {
        stroke: ${p.color};
      }
    `}

  ${(p) =>
    p.width &&
    css`
      svg {
        width: ${p.width}px;
      }
    `}

  ${(p) =>
    p.height &&
    css`
      svg {
        height: ${p.height}px;
      }
    `}
`;
