import { put, takeLatest, call, select } from 'redux-saga/effects';
import types from 'constants/actionTypes';
import * as API from 'api';
import { marketAnalyticsFiltersConfig } from 'features/Analytics/config';
import { makeUrlStringFromData, makeObjFromUrlString } from 'lib/url';
import { setCurrentUri } from 'actions';
import { history } from 'store';
import { downloadBlobFile } from 'lib/browser';
import { featuresUrlParamsMap } from 'config/featuresUrlParamsMap';
import { userTypeSelector } from 'selectors/userType';

import {
  getMarketAnalyticsLoansSuccess,
  getMarketAnalyticsLoansFailure,
  updateMarketAnalyticsLoansSuccess,
  updateMarketAnalyticsLoansFailure,
  exportAnalyticsLoanDataSuccess,
  exportAnalyticsLoanDataFailure,
} from './actions';

function* getMarketAnalyticsLoans() {
  try {
    const { search } = history.location;

    const state = yield select();
    const userType = userTypeSelector(state);
    const companyTeamPart = userType.lender ? 'loans-lender' : 'loans';

    // Setup initial value for store from uri string

    const { orderingType } = makeObjFromUrlString({
      uri: search,
      config: { ordering: featuresUrlParamsMap.ordering },
    });

    const {
      data: { haveOffersBefore, haveOffersAfter, ...rest },
    } = makeObjFromUrlString({
      uri: search,
      config: marketAnalyticsFiltersConfig,
    });

    const {
      data: { response },
    } = yield call(API.axiosApi.get, `analytics/market/${companyTeamPart}${search || ''}`);

    yield put(
      getMarketAnalyticsLoansSuccess({
        response,
        haveOffersBefore: haveOffersBefore ? new Date(haveOffersBefore) : null,
        haveOffersAfter: haveOffersAfter ? new Date(haveOffersAfter) : null,
        ordering: orderingType,
        tipData: rest,
      })
    );
  } catch (e) {
    yield put(getMarketAnalyticsLoansFailure(e));
  }
}

function* updateMarketAnalyticsLoans(action) {
  try {
    const { page } = action.payload;
    const state = yield select();
    const userType = userTypeSelector(state);
    const companyTeamPart = userType.lender ? 'loans-lender' : 'loans';
    const {
      filter,
      marketAnalyticsLoans: { haveOffersBefore, haveOffersAfter, ordering, tipData },
    } = state;

    const marketAnalyticsFiltersUri = makeUrlStringFromData({
      data: { haveOffersAfter, haveOffersBefore, ...tipData },
      config: marketAnalyticsFiltersConfig,
    }).replace('?', '');

    const requestUri = [
      filter.uri,
      marketAnalyticsFiltersUri,
      page ? `page=${page}` : '',
      ordering ? `ordering=${ordering}` : '',
    ]
      .filter(Boolean)
      .join('&');

    const {
      data: { response },
    } = yield call(
      API.axiosApi.get,
      `analytics/market/${companyTeamPart}${requestUri ? `?${requestUri}` : ''}`
    );

    history.push(`?${requestUri}`);
    yield put(setCurrentUri(requestUri));
    yield put(updateMarketAnalyticsLoansSuccess(response));
  } catch (e) {
    yield put(updateMarketAnalyticsLoansFailure(e));
  }
}

function* exportAnalyticsLoanData() {
  try {
    const {
      marketAnalyticsLoans: { haveOffersBefore, haveOffersAfter, ordering, page },
      filter,
    } = yield select();

    const marketAnalyticsFiltersUri = makeUrlStringFromData({
      data: { haveOffersAfter, haveOffersBefore },
      config: marketAnalyticsFiltersConfig,
    }).replace('?', '');

    const requestUri = [
      filter.uri,
      marketAnalyticsFiltersUri,
      page ? `page=${page}` : '',
      ordering ? `ordering=${ordering}` : '',
    ]
      .filter(Boolean)
      .join('&');

    const { data, headers } = yield call(
      API.axiosApiRaw.get,
      `/loans/my/export?${requestUri}`,
      {
        responseType: 'blob',
      }
    );

    downloadBlobFile(data, headers['content-disposition'].split('=')[1]);

    yield put(exportAnalyticsLoanDataSuccess());
  } catch (e) {
    yield put(exportAnalyticsLoanDataFailure());
  }
}

export default [
  takeLatest(types.GET_MARKET_ANALYTICS_LOANS_REQUEST, getMarketAnalyticsLoans),
  takeLatest(types.UPDATE_MARKET_ANALYTICS_LOANS_REQUEST, updateMarketAnalyticsLoans),
  takeLatest(types.EXPORT_ANALYTICS_LOAN_DATA_REQUEST, exportAnalyticsLoanData),
];
