import types from 'constants/actionTypes';

export const closeLoanOnThePageSuccess = (payload = {}) => ({
  type: types.CLOSE_LOAN_ON_THE_PAGE_SUCCESS,
  payload,
});

export const myLoansDataRequest = (payload = {}) => ({
  type: types.MY_LOANS_DATA_REQUEST,
  payload,
});
export const myLoansDataSuccess = (payload = {}) => ({
  type: types.MY_LOANS_DATA_SUCCESS,
  payload,
});
export const myLoansDataFailure = (payload = {}) => ({
  type: types.MY_LOANS_DATA_FAILURE,
  payload,
});

export const myLoansUpdateRequest = (payload = {}) => ({
  type: types.MY_LOANS_UPDATE_REQUEST,
  payload,
});
export const myLoansUpdateSuccess = (payload = {}) => ({
  type: types.MY_LOANS_UPDATE_SUCCESS,
  payload,
});
export const myLoansUpdateFailure = (payload = {}) => ({
  type: types.MY_LOANS_UPDATE_FAILURE,
  payload,
});

export const exportMyLoansRequest = (payload = {}) => ({
  type: types.EXPORT_MY_LOANS_REQUEST,
  payload,
});
export const exportMyLoansSuccess = (payload = {}) => ({
  type: types.EXPORT_MY_LOANS_SUCCESS,
  payload,
});
export const exportMyLoansFailure = (payload = {}) => ({
  type: types.EXPORT_MY_LOANS_FAILURE,
  payload,
});

export const assignLoanRequest = ({ teamId, loanId }) => ({
  type: types.ASSIGN_LOAN_REQUEST,
  payload: { teamId, loanId },
});
export const assignLoanSuccess = (payload = {}) => ({
  type: types.ASSIGN_LOAN_SUCCESS,
  payload,
});
export const assignLoanFailure = (payload = {}) => ({
  type: types.ASSIGN_LOAN_FAILURE,
  payload,
});

export const myLoansOrderingUpdate = (payload = {}) => ({
  type: types.MY_LOANS_ORDERING_UPDATE,
  payload,
});

export const closeLoanRequest = ({ loanId, closeType, data, loan }) => ({
  type: types.CLOSE_LOAN_REQUEST,
  payload: {
    loanId,
    closeType,
    data,
    loan,
  },
});

export const closeLoanSuccess = ({ loanId, closeType, status, closedOn }) => ({
  type: types.CLOSE_LOAN_SUCCESS,
  payload: {
    loanId,
    closeType,
    status,
    closedOn,
  },
});

export const closeMatchingLoanSuccess = ({ loanId, closeType, status, closedOn }) => ({
  type: types.CLOSE_MATCHING_LOAN_SUCCESS,
  payload: {
    loanId,
    closeType,
    status,
    closedOn,
  },
});

export const closeLoanFailure = (payload = {}) => ({
  type: types.CLOSE_LOAN_FAILURE,
  payload,
});

export const duplicateLoanRequest = (payload = {}) => ({
  type: types.DUPLICATE_LOAN_REQUEST,
  payload,
});

export const duplicateLoanSuccess = (payload = {}) => ({
  type: types.DUPLICATE_LOAN_SUCCESS,
  payload,
});
export const duplicateLoanFailure = (payload = {}) => ({
  type: types.DUPLICATE_LOAN_FAILURE,
  payload,
});
export const setMyLoansDisplayVariantRequest = (payload = {}) => ({
  type: types.SET_MY_LOANS_DISPLAY_VARIANT_REQUEST,
  payload,
});

export const setMyLoansDisplayVariantSuccess = (payload = {}) => ({
  type: types.SET_MY_LOANS_DISPLAY_VARIANT_SUCCESS,
  payload,
});
export const setMyLoansDisplayVariantFailure = (payload = {}) => ({
  type: types.SET_MY_LOANS_DISPLAY_VARIANT_FAILURE,
  payload,
});
