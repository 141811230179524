import React from 'react';
import { OffersListItem } from './OffersListItem';

import {
  Table,
  TableRow,
  TableSectionTitle,
  TableHeading,
  TableSectionInfo,
  TableCellWide,
  TableCell,
} from './styles';

export const OffersListTable = ({ offers, isOwner }) => (
  <Table>
    <TableRow>
      <TableSectionTitle>
        <TableHeading>Author</TableHeading>
      </TableSectionTitle>
      <TableSectionInfo>
        <TableCellWide>
          <TableHeading>Loan amount</TableHeading>
        </TableCellWide>
        <TableCellWide>
          <TableHeading>Amortization</TableHeading>
        </TableCellWide>
        <TableCell>
          <TableHeading>Rate</TableHeading>
        </TableCell>
        <TableCell>
          <TableHeading>Recourse</TableHeading>
        </TableCell>
      </TableSectionInfo>
    </TableRow>
    {offers.map(({ pk, lastOffer, profile }) => (
      <OffersListItem
        key={pk}
        lastOffer={lastOffer}
        profile={profile}
        isOwner={isOwner}
      />
    ))}
  </Table>
);
