import { put, takeLatest, call, takeEvery, select } from 'redux-saga/effects';
import types from 'constants/actionTypes';
import * as API from 'api';
import { decamelizeKeys } from 'humps';
import { getError } from 'sagas/utils';
import { normalize } from 'normalizr';
import { contactsDirectoryListSchema } from 'utils/schemas';

import {
  contactDirectoryInviteSuccess,
  contactDirectoryInviteFailure,
  getContactsDirectoryDataFailure,
  getContactsDirectoryDataSuccess,
  addTagToContactSuccess,
  addTagToContactFailure,
  removeTagFromContactSuccess,
  removeTagFromContactFailure,
  addNoteToContactSuccess,
  addNoteToContactFailure,
  checkContactDirectoryEmailSuccess,
  checkContactDirectoryEmailFailure,
  archiveContactSuccess,
  archiveContactFailure,
  showContactSuccess,
  showContactFailure,
  updateContactsDirectoryDataSuccess,
  updateContactsDirectoryDataFailure,
} from './actions';

function* getContactsDirectoryData(action) {
  try {
    let nextPage = action.payload;
    const {
      router: { location },
    } = yield select();
    const data = {};

    const requestQuery = `${location.search.replace('?', '') || ''}`;

    const {
      data: { response },
    } = yield call(API.axiosApi.get, 
      `contacts${requestQuery ? `?${requestQuery}` : ''}${nextPage ? `&page=${nextPage}` : ''}`);

    const { entities, result } = normalize(response.results, contactsDirectoryListSchema);
    data.contacts = entities.contacts;
    data.contactsArray = result || [];
    data.pageNumber = response.next ? new URL(response.next).searchParams.get("page") : null;
    data.count = response.count;

    const tags = yield call(API.axiosApi.get, '/contacts/tags');
    const contactTypes = yield call(API.axiosApi.get, '/contacts/types');

    const updatedTags = tags.data.response.tags.map((tag) => ({
      label: tag,
      value: tag,
    }));
    data.tags = updatedTags;
    data.contactTypes = contactTypes?.data?.response?.contactTypes || [];

    yield put(getContactsDirectoryDataSuccess(data));
  } catch (e) {
    console.log(e);
    yield put(getContactsDirectoryDataFailure({ message: getError(e) }));
  }
}

function* updateContactsDirectoryData() {
  try {
    const {
      filter,
      contactsDirectory: { searchString },
    } = yield select();

    const data = {};
    const requestUri = [filter.uri, searchString ? `search=${searchString}` : '']
      .filter(Boolean)
      .join('&');

    const {
      data: { response },
    } = yield call(API.axiosApi.get, `contacts${requestUri ? `?${requestUri}` : ''}`);

    const { entities, result } = normalize(response.results, contactsDirectoryListSchema);
    data.contacts = entities.contacts;
    data.contactsArray = result || [];
    data.pageNumber = response.next ? new URL(response.next).searchParams.get("page") : null;
    data.count = response.count;

    yield put(updateContactsDirectoryDataSuccess(data));
  } catch (e) {
    console.log(e);
    yield put(updateContactsDirectoryDataFailure({ message: getError(e) }));
  }
}

function* inviteContacts(action) {
  // TODO: Need to figure out how to updated store after adding contact from different pages
  const { request, IsAddContactLoan, IsAddContactContacts } = action.payload;
  try {
    const data = {};
    const {
      data: { response },
    } = yield call(API.axiosApi.post, 'contacts', decamelizeKeys(request));
    const { entities, result } = normalize([response], contactsDirectoryListSchema);

    data.contacts = { ...entities.contacts };
    data.contactsArray = result;
    data.pageNumber = response.next ? new URL(response.next).searchParams.get("page") : null;
    data.count = response.count;
    
    const tags = yield call(API.axiosApi.get, '/contacts/tags');

    const updatedTags = tags.data.response.tags.map((tag) => ({
      label: tag,
      value: tag,
    }));

    data.tags = updatedTags;

    yield put(
      contactDirectoryInviteSuccess({ ...data, IsAddContactLoan, IsAddContactContacts })
    );
  } catch (e) {
    console.log(e);
    yield put(contactDirectoryInviteFailure({ message: getError(e) }));
  }
}

function* addTagToContact(action) {
  const { tagName, contactId } = action.payload;
  try {
    yield call(API.axiosApi.post, `contacts/${contactId}/tags`, { text: tagName });
    yield put(addTagToContactSuccess({ tagName, contactId }));
  } catch (e) {
    console.log(e);
    yield put(addTagToContactFailure({ message: getError(e) }));
  }
}

function* removeTagFromContact(action) {
  const { tagName, contactId } = action.payload;
  try {
    yield call(API.axiosApi.delete, `contacts/${contactId}/tags`, {
      data: { text: tagName },
    });
    yield put(removeTagFromContactSuccess({ tagName, contactId }));
  } catch (e) {
    console.log(e);
    yield put(removeTagFromContactFailure({ message: getError(e) }));
  }
}

function* addNoteToContact(action) {
  const { note, contactId } = action.payload;
  try {
    const { data } = yield call(API.axiosApi.post, `contacts/${contactId}/notes`, {
      text: note.note,
    });

    yield put(addNoteToContactSuccess({ response: data }));
  } catch (e) {
    console.log(e);
    yield put(addNoteToContactFailure({ message: getError(e) }));
  }
}

function* checkContactEmail(action) {
  const { email, reject, resolve } = action.payload;
  try {
    const {
      data: { response },
    } = yield call(API.axiosApi.get, `contacts/checkemail?email=${email}`);

    const data = { result: response && response.result };
    yield put(checkContactDirectoryEmailSuccess(data));
    if (response.exists) {
      reject('This e-mail is already used');
    } else {
      resolve();
    }
  } catch (e) {
    console.error(e);
    yield put(checkContactDirectoryEmailFailure());
    reject();
  }
}

function* archiveContact(action) {
  try {
    const { contactId } = action.payload;
    yield call(API.axiosApi.delete, `/contacts/${contactId}`);
    yield put(archiveContactSuccess({ contactId }));
  } catch (e) {
    yield put(archiveContactFailure({ message: getError(e) }));
  }
}
function* showContact(action) {
  try {
    const { contactId } = action.payload;
    const {
      data: { response },
    } = yield call(API.axiosApi.get, `/contacts/${contactId}`);

    yield put(showContactSuccess(response));
  } catch (e) {
    yield put(showContactFailure({ message: getError(e) }));
  }
}

export default [
  takeLatest(types.GET_MORE_CONTACTS_REQUEST, getContactsDirectoryData),
  takeLatest(types.CONTACT_DIRECTORY_INVITE_REQUEST, inviteContacts),
  takeLatest(types.GET_CONTACTS_DIRECTORY_DATA_REQUEST, getContactsDirectoryData),
  takeLatest(types.UPDATE_CONTACTS_DIRECTORY_DATA_REQUEST, updateContactsDirectoryData),
  takeLatest(types.ADD_TAG_TO_CONTACT_REQUEST, addTagToContact),
  takeLatest(types.REMOVE_TAG_FROM_CONTACT_REQUEST, removeTagFromContact),
  takeLatest(types.ADD_NOTE_TO_CONTACT_REQUEST, addNoteToContact),
  takeEvery(types.CHECK_CONTACT_DIRECTORY_EMAIL_REQUEST, checkContactEmail),
  takeLatest(types.ARCHIVE_CONTACT_REQUEST, archiveContact),
  takeLatest(types.SHOW_CONTACT_CARD_REQUEST, showContact),
];
