import { wordsRemove } from 'lib/strings';
import { FIELD_TYPES } from 'constants/appRelated';
import { normalizeDollars, normalizeNumbersLimit } from 'lib/normalizers';

export const normalizeOfferData = (fields, values) => {
  const request = {};

  fields.forEach((field) => {
    const value = values[field.field];
    switch (field.type) {
      case FIELD_TYPES.PERCENT:
        if (value) {
          request[field.field] =
            typeof value !== 'string' ? value : value.replace(/%/g, '');
        }
        break;
      case FIELD_TYPES.DOLLARS:
        if (value) {
          request[field.field] = wordsRemove(value);
        }
        break;
      case FIELD_TYPES.DURATION:
        if (value !== null && value !== undefined) {
          request[`${field.field}Unit`] = values?.[`${field.field}Unit`]?.value ? 1 : 0;
          request[field.field] = value;
        }
        break;
      case FIELD_TYPES.TOGGLE:
        request[field.field] = value ? 1 : 0;
        break;
      case FIELD_TYPES.CHOICE:
        if (value) {
          request[field.field] = value && value.value;
        }
        break;
      case 'indexSpread':
        break;

      default:
        if (value !== null && value !== undefined) {
          request[field.field] = value;
        }
        break;
    }
  });

  return request;
};

export const parseOfferInitialData = (data, fields, options) => {
  const result = {};

  fields.forEach((field) => {
    const value = data[field.field];

    switch (field.type) {
      case FIELD_TYPES.PERCENT:
        result[field.field] = value ? normalizeNumbersLimit(value) : null;
        break;

      case FIELD_TYPES.DOLLARS:
        result[field.field] = value ? normalizeDollars(value) : null;
        break;

      case FIELD_TYPES.DURATION:
        result[`${field.field}Unit`] = data[`${field.field}Unit`]
          ? options.unitOptions[1]
          : options.unitOptions[0];
        result[field.field] = value || null;
        break;

      case FIELD_TYPES.TOGGLE:
        result[field.field] = !!value;
        break;

      case FIELD_TYPES.CHOICE:
        result[field.field] = value ? options[`${field.field}Options`][value] : null;
        break;

      case 'indexSpread':
        break;

      default:
        result[field.field] = value;
        break;
    }
  });

  return result;
};
