import React from 'react';
import GoogleMap from 'google-map-react';

const markerStyle = {
  position: 'absolute',
  width: 80,
  height: 80,
  left: -40,
  top: -40,
  border: '2px solid #FF9B12',
  borderRadius: 80,
  backgroundColor: '#FF9B12',
  opacity: '.5',
};

const keys = {
  key: 'AIzaSyDHUg5zvrIGFOvTifpXO6pOP37e792CyQc',
};

const styles = [
  {
    featureType: 'administrative.country',
    elementType: 'geometry',
    stylers: [
      {
        visibility: 'simplified',
      },
      {
        hue: '#ff0000',
      },
    ],
  },
];
/* eslint-disable  */

const options = {
  maxZoom: 14,
  fullscreenControl: false,
  zoomControl: true,
  zoomControlOptions: {
    position: window?.google?.maps?.ControlPosition?.RIGHT_TOP,
  },
  styles,
};

const AreaMarker = ({ text }) => <div style={markerStyle}>{text}</div>;
const MapMarker = () => (
  <div>
    <img src="/images/icons/ico_map_marker.svg" />
  </div>
);

export const PropMap = ({ center, isAccessFull }) => (
  <GoogleMap defaultCenter={center} bootstrapURLKeys={keys} zoom={11} options={options}>
    {isAccessFull ? (
      <MapMarker lat={center.lat} lng={center.lng} />
    ) : (
      <AreaMarker lat={center.lat} lng={center.lng} />
    )}
  </GoogleMap>
);
