import types from 'constants/actionTypes';
import immutable from 'object-path-immutable';
import { clone } from 'ramda';

const initialState = {
  newClient: {value: null, label: ''},
  clients: {},
  clientsArray: [],
  searchString: '',
  isFetched: false,
};

export function clientsDirectory(state, action = {}) {
  const currentState = state || clone(initialState);

  const { type, payload } = action;

  switch (type) {
    case types.NEW_CLIENT_INVITE_SUCCESS:
      // let company = payload.response.companyName ? `<span>company:<span>${payload.response.companyName}` : ''
      // return {
      //   ...state, 
      //   newClient: {
      //     value: payload.response.id, 
      //     label: `${payload.response.firstName} ${payload.response.lastName} ${company}`
      //   }
      // };
      return {
        ...state, newClient: payload.response
      };
    case types.CLIENT_DIRECTORY_INVITE_SUCCESS:
      return immutable(currentState)
        .update('clients', (clients) => ({ ...payload.clients, ...clients }))
        .update('clientsArray', (clientsArray) => [
          ...payload.clientsArray,
          ...clientsArray,
        ])
        .value();

    case types.GET_CLIENTS_DIRECTORY_DATA_SUCCESS:
      return immutable(currentState)
        .set('clients', payload.clients || {})
        .set('clientsArray', payload.clientsArray)
        .set('pageNumber', payload.pageNumber)
        .set('count', payload.count)
        .set('isFetched', true)
        .value();

    case types.UPDATE_CLIENT_DIRECTORY_DATA_SUCCESS:
      return immutable(currentState)
        .set('clients', payload.clients || {})
        .set('clientsArray', payload.clientsArray)
        .set('pageNumber', payload.pageNumber)
        .set('count', payload.count)
        .set('searchString', payload.searchString)
        .value();
    case types.GET_MORE_CLIENTS_SUCCESS:
      return immutable(currentState)
        .set('clients', Object.assign(state.clients, payload.newClients) || {})
        .set('clientsArray', [...state.clientsArray, ...payload.newClientsArray])
        .set('pageNumber', payload.pageNumber)
        .set('count', payload.count)
        .set('isFetched', true)
        .value();
    case types.RESET_CLIENT_DIRECTORY_DATA: {
      return initialState;
    }

    case types.ARCHIVE_CLIENT_SUCCESS: {
      return {
        ...state,
        clientsArray: state.clientsArray.filter(
          (id) => state.clients[id].id !== Number(payload.contactId)
        ),
      };
    }

    default:
      return currentState;
  }
}
