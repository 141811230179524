import types from 'constants/actionTypes';
import { findIndex } from 'ramda';
import immutable from 'object-path-immutable';
import { DOCUMENT_STATE } from 'features/DealRoom/constants';

import { FILTERS_TYPES } from './constants';

const initialState = {
  files: [],
  roomStatistic: null,
  room: {},
  filterType: FILTERS_TYPES.ALL,
  newRoomCreated: false,
};

export default function dealRoom(state, action = {}) {
  const currentState = state || initialState;

  const { type, payload } = action;

  let checklistIndex;
  let requestIndex;
  let documentIndex;

  if (payload) {
    if (payload.checklistId !== undefined) {
      checklistIndex = findIndex(
        (checklist) => checklist.id === Number(payload.checklistId),
        currentState.room.checklists
      );
    }

    if (
      payload.requestId !== undefined &&
      checklistIndex !== undefined &&
      checklistIndex !== -1
    ) {
      requestIndex = findIndex(
        (request) => request.id === Number(payload.requestId),
        currentState.room.checklists[checklistIndex].requests
      );
    }

    if (
      payload.docId !== undefined &&
      requestIndex !== undefined &&
      requestIndex !== -1
    ) {
      documentIndex = findIndex(
        (doc) => doc.id === Number(payload.docId),
        currentState.room.checklists[checklistIndex].requests[requestIndex].documents
      );
    }
  }

  switch (type) {
    case types.GET_DEAL_ROOM_SUCCESS:
    case types.CREATE_DEAL_ROOM_CHECKLIST_SUCCESS:
    case types.EDIT_DEAL_ROOM_CHECKLIST_SUCCESS:
      return { ...currentState, room: payload };

    case types.CREATE_DEAL_ROOM_SUCCESS:
      return { ...currentState, newRoomCreated: payload };

    case types.MARK_DEAL_DOCUMENT_COMPLETE_SUCCESS:
      return immutable.update(
        {...currentState,  roomStatistic: payload.dealRoomData.dueDiligence},
        `room.checklists.${checklistIndex}`,
        (checklist) =>
          immutable.update(checklist, `requests.${requestIndex}`, (request) => ({
            ...request,
            documents: request.documents.map((item) =>
              item.state === DOCUMENT_STATE.NEW
                ? { ...item, state: DOCUMENT_STATE.ACCEPTED }
                : item
            ),
            completed: true,
          }))
      );

    case types.MARK_DEAL_DOCUMENT_INCOMPLETE_SUCCESS:
      return immutable.update(
        {...currentState, roomStatistic: payload.dealRoomData.dueDiligence},
        `room.checklists.${checklistIndex}`,
        (checklist) =>
          immutable.update(checklist, `requests.${requestIndex}`, (request) => ({
            ...request,
            completed: false,
          }))
      );

    case types.SET_DEALROOM_CLOSING_DATE_SUCCESS: {
      return {
        ...currentState,
        room: {
          ...currentState.room,
          daysToClosing: payload.daysToClosing,
          dueDate: payload.dueDate,
        },
      };
    }

    case types.ACCEPT_DOCS_CONFIDENTIALITY_AGREEMENT_SUCCESS:
      return {
        ...currentState, room: {...currentState.room, caSigned: payload.response.caSigned}
      };

    case types.ATTACH_DEAL_ROOM_FILES_SUCCESS:
      return immutable.update(
        currentState,
        `room.checklists.${checklistIndex}`,
        (checklist) =>
          immutable.update(checklist, `requests.${requestIndex}`, (request) => ({
            ...request,
            documents: [...payload.documents, ...request.documents],
          }))
      );

    case types.UPDATE_DEAL_ROOM_DOCUMENT_STATE_SUCCESS:
      return immutable.update(
        currentState,
        `room.checklists.${checklistIndex}`,
        (checklist) =>
          immutable.update(checklist, `requests.${requestIndex}`, (request) =>
            immutable.update(request, `documents.${documentIndex}`, (document) => ({
              ...document,
              state: payload.state,
              rejectedReason: payload.rejectionReason || null,
            }))
          )
      );

    case types.RE_UPLOAD_DEAL_ROOM_FILE_SUCCESS:
    case types.DELETE_DEAL_ROOM_FILE_SUCCESS:
    case types.RESTORE_DEAL_ROOM_FILE_SUCCESS:
      return immutable.update(
        currentState,
        `room.checklists.${checklistIndex}`,
        (checklist) =>
          immutable.update(checklist, `requests.${requestIndex}`, (request) =>
            immutable.set(request, `documents.${documentIndex}`, payload.document)
          )
      );

    case types.DELETE_DEAL_DOCUMENT_SUCCESS:
    case types.RESTORE_DEAL_DOCUMENT_SUCCESS:
      return immutable.update(
        currentState,
        `room.checklists.${checklistIndex}`,
        (checklist) =>
          immutable.update(checklist, `requests.${requestIndex}`, (request) =>
            immutable.set(request, 'deleted', payload.deleted)
          )
      );
    case types.SAVE_FILES_SUCCESS:
      return { ...currentState, files: [...payload.files, ...currentState.files]};
    case types.GET_FILES_SUCCESS:
      return { ...currentState, files: payload.files};
    case types.SET_UPDATE_FILE_SUCCESS: 
      return {
        ...currentState,
        files: [...currentState.files.map(file => file.id === payload.id ? ({...file, name: payload.name}) : (file))] 
      }

    case types.SET_DELETE_FILE_SUCCESS: 
      return {
        ...currentState,
        files: [...currentState.files.filter(file => (payload.fileId !== file.id))] 
      }

    case types.SET_UPLOAD_SIGNED_FILE_SUCCESS:
      return { 
        ...currentState,
        files: [...currentState.files.map(file => file.id === payload.parentFileId ? ({...file, signedDocument: payload.response}) : (file))] 
      }
    case types.SET_REVIEW_FILE_SUCCESS:
   
      return {
        ...currentState,
        files: [...currentState.files.map(file => file.id === payload.fileId ? 
          ({...file, 
            signedDocument: 
              {...file.signedDocument, 
                state: payload.response.state, 
                rejectedReason: payload.response.rejectedReason }})
        : (file))] 
      }
       
    default:
      return currentState;
  }
}
