import React from 'react';

export const Arrow = ({ fill }) => (
  <svg
    width="6px"
    height="13px"
    viewBox="0 0 6 13"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      id="design-system"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
    >
      <g id="Atoms" transform="translate(-189.000000, -2761.000000)" stroke={fill}>
        <g id="Group-6" transform="translate(171.000000, 2748.000000)">
          <g id="add">
            <polyline id="Path-7" points="18 14 24 19.4146028 18 25" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
