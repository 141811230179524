import React from 'react';
import { Text } from 'ui/Text';
import { ButtonGhost } from 'ui/Button';
import { format } from 'date-fns';
import { values } from 'ramda';
import { BucketIcon } from 'components/SvgIcons';
import { cutStringByLength } from 'lib/strings';
import { FormattedRelative } from 'react-intl';
import { ErrorMessage } from 'styles/global';
import {
  NotificationWrapper,
  NotificationImageWrapper,
  NotificationHeadline,
  NotificationDate,
  HoverContent,
  NotificationTitle,
  NotificationBottomLine,
  LoanTitle,
  LinkButton,
  RemoveNotification,
} from 'features/Dashboard/styles';
import { DocumentIcon } from 'components/SvgIcons';
import { NOTIFICATION_TYPES } from 'features/DashboardActionItems/constants';
import User from './User';
import { ContentOffer } from './ContentOffer';
import { AnswerForm } from './AnswerForm';
import { DDRItem } from './DDRItem';
import { File } from './File';
import { RequestedDocuments } from './RequestedDocuments';
import styled from 'styled-components/macro';

const NotificationValues = values(NOTIFICATION_TYPES);

const getTitle = (type, description, body) => {
  const borrower = body?.borrower;

  switch (type) {
    case NOTIFICATION_TYPES.YOUR_TASK_DUE_SOON:
      return 'Your assigned task is due soon';
    case NOTIFICATION_TYPES.YOUR_TASK_COMPLETED:
      return 'Your task’s been marked as completed';
    case NOTIFICATION_TYPES.USER_ASSIGNED_TO_TASK:
      return 'You’ve been assigned a new task';
    case NOTIFICATION_TYPES.LENDER_SENT_LOAN: 
      return 'Lender prepared loan request for you';
    case NOTIFICATION_TYPES.NEW_DOCUMENT_REQUEST:
      return 'New Document Request';
    case NOTIFICATION_TYPES.LOAN_REQUEST_WAS_DECLINED:
      return 'Loan Request was declined';
    case NOTIFICATION_TYPES.CONCIERGE_READY_FOR_APPROVAL:
      return 'Concierge request is ready for your approval';
    case NOTIFICATION_TYPES.CONCIERGE_POSTED:
      return 'Concierge Request was Posted';
    case NOTIFICATION_TYPES.YOU_HAVE_OFFER:
      return 'You have received a quote';
    case NOTIFICATION_TYPES.YOU_HAVE_COUNTEROFFER:
      return 'You have got a counteroffer';
    case NOTIFICATION_TYPES.COUNTEROFFER_REJECTED:
      return 'Lender has passed on your quote';
    case NOTIFICATION_TYPES.COUNTEROFFER_ACCEPTED:
      return 'Your counteroffer was Accepted';
    case NOTIFICATION_TYPES.YOU_HAVE_NEW_QUESTION:
      return "You've got a new question";

    case NOTIFICATION_TYPES.DEAL_DOCUMENT_SIGN_REQUEST_UPLOADED:
      return "Lender uploaded documents to sign";
    case NOTIFICATION_TYPES.DEAL_DOCUMENT_BB_SIGNED_REQUEST:
      return "Client uploaded signed document";
    case NOTIFICATION_TYPES.DEAL_DOCUMENT_SIGN_REQUEST_ACCEPTED:
      return "Lender approved your documents";
    case NOTIFICATION_TYPES.DEAL_DOCUMENT_SIGN_REQUEST_REJECTED:
      return "Lender rejected document you signed";

    case NOTIFICATION_TYPES.YOU_HAVE_NEW_TERMSHEET:
      return 'You have gotten a new Term sheet';
    case NOTIFICATION_TYPES.LENDER_ACCEPT_INVITATION:
      return 'Relationship lender accepted your invitation';
    case NOTIFICATION_TYPES.YOUR_ACCOUNT_WAS_APPROVED:
      return 'Your account was approved';
    case NOTIFICATION_TYPES.BORROWER_MADE_COUNTEROFFER:
      return 'Client made counteroffer';
    case NOTIFICATION_TYPES.BORROWER_ACCEPTED_OFFER:
      return 'Client accepted your quote';
    case NOTIFICATION_TYPES.BORROWER_ACCEPTED_COUNTEROFFER:
      return 'Client accepted your counteroffer';
    case NOTIFICATION_TYPES.BORROWER_REJECTED_OFFER:
      return 'Client has passed on your quote';
    case NOTIFICATION_TYPES.BORROWER_REJECTED_COUNTEROFFER:
      return 'Client has passed on your quote negotiation';
    case NOTIFICATION_TYPES.BORROWER_ANSWERED_QUESTION:
      return 'Client answered your question';
    case NOTIFICATION_TYPES.BORROWER_REJECTED_TERMSHEET:
      return 'Client returned your Term Sheet';
    case NOTIFICATION_TYPES.BORROWER_SIGNED_TERMSHEET:
      return 'Client signed your Term Sheet';
    case NOTIFICATION_TYPES.REQUESTED_DOCUMENT_UPLOADED:
      return 'Document you requested was uploaded';
    case NOTIFICATION_TYPES.LENDER_ACCEPTS_DEAL_DOCUMENT:
      return 'The document you uploaded into the deal room was accepted';
    case NOTIFICATION_TYPES.DEAL_DOCUMENT_REQUEST_COMPLETED:
      return 'You completed the task in the deal room';

    case NOTIFICATION_TYPES.YOU_HAVE_BEEN_MATCHED:
      return 'You’ve been matched';

    case NOTIFICATION_TYPES.LENDER_REJECTED_DEAL_DOCUMENT:
      return 'Your deal document was rejected';

    // team actions

    case NOTIFICATION_TYPES.NEW_TEAM_MEMBER_JOINED:
      return 'New team member joined';

    case NOTIFICATION_TYPES.LENDER_MADE_PREQUALIFICATION:
      return 'Lender makes pre qualification';

    case NOTIFICATION_TYPES.BORROWER_ADD_LENDER_TO_LD:
      return `${borrower.firstName} ${borrower.lastName}${
        borrower.businessName ? ` from ${borrower.businessName}` : ''
      } has added you to their list of preferred lenders`;

    case NOTIFICATION_TYPES.BORROWER_UPLOADED_NEW_DOCUMENT:
      return `Client uploaded documents`;

    case NOTIFICATION_TYPES.BORROWER_AWAITS_TERMSHEET:
      return `${borrower.firstName} ${borrower.lastName}${
        borrower.businessName ? ` from ${borrower.businessName}` : ''
      } is waiting for your Term Sheet`;

    case NOTIFICATION_TYPES.BORROWER_ADD_LENDER_TO_LOAN:
      return 'You were invited to a loan';

    case NOTIFICATION_TYPES.BORROWER_UPLOAD_NEW_DEAL_DOCUMENT:
      return 'New deal documents were uploaded';

    case NOTIFICATION_TYPES.BB_INVITED_BY_LENDER:
      return 'You were invited to share your loan requests';

    case NOTIFICATION_TYPES.LENDER_INVITED_TO_COMPANY:
      return `You were invited to join ${body?.company?.name}`;

    case NOTIFICATION_TYPES.NEW_LOAN_ASSIGNED:
      return `${body?.author?.firstName} ${body?.author?.lastName} assigned the deal to you`;

    case NOTIFICATION_TYPES.LOAN_REASSIGNED_TO_DIFFERENT_LENDER:
      return `
        ${body?.author?.firstName} ${body?.author?.lastName} 
        from 
        ${body?.author?.businessName} 
        assigned your deal to a colleague${body?.newLenders.length > 1 ? 's' : ''}`;

    case NOTIFICATION_TYPES.OFFER_CANCELLED:
      return 'Lender canceled their quote';

    default:
      return description || 'Notification';
  }
};

const getImageName = (type) => {
  switch (type) {
    case NOTIFICATION_TYPES.LOAN_REQUEST_WAS_DECLINED:
    case NOTIFICATION_TYPES.COUNTEROFFER_REJECTED:
    case NOTIFICATION_TYPES.BORROWER_REJECTED_OFFER:
    case NOTIFICATION_TYPES.BORROWER_REJECTED_TERMSHEET:
    case NOTIFICATION_TYPES.BORROWER_REJECTED_COUNTEROFFER:
    case NOTIFICATION_TYPES.LENDER_REJECTED_DEAL_DOCUMENT:
    case NOTIFICATION_TYPES.OFFER_CANCELLED:
      return 'ico_x';

    case NOTIFICATION_TYPES.BORROWER_SIGNED_TERMSHEET:
    case NOTIFICATION_TYPES.YOUR_ACCOUNT_WAS_APPROVED:
    case NOTIFICATION_TYPES.COUNTEROFFER_ACCEPTED:
    case NOTIFICATION_TYPES.BORROWER_ACCEPTED_OFFER:
    case NOTIFICATION_TYPES.BORROWER_ACCEPTED_COUNTEROFFER:
    case NOTIFICATION_TYPES.BORROWER_ADD_LENDER_TO_LD:
      return 'ico_success';

    case NOTIFICATION_TYPES.LENDER_ACCEPT_INVITATION:
    case NOTIFICATION_TYPES.NEW_TEAM_MEMBER_JOINED:
    case NOTIFICATION_TYPES.BB_INVITED_BY_LENDER:
    case NOTIFICATION_TYPES.LENDER_INVITED_TO_COMPANY:
      return 'ico_user';

    case NOTIFICATION_TYPES.YOU_HAVE_OFFER:
    case NOTIFICATION_TYPES.YOU_HAVE_COUNTEROFFER:
    case NOTIFICATION_TYPES.BORROWER_MADE_COUNTEROFFER:
      return 'ico_offer';

    case NOTIFICATION_TYPES.CONCIERGE_READY_FOR_APPROVAL:
    case NOTIFICATION_TYPES.CONCIERGE_POSTED:
      return 'ico_cook';

    case NOTIFICATION_TYPES.YOU_HAVE_NEW_QUESTION:
    case NOTIFICATION_TYPES.BORROWER_ANSWERED_QUESTION:
      return 'ico_question';

    case NOTIFICATION_TYPES.YOU_HAVE_NEW_TERMSHEET:
    case NOTIFICATION_TYPES.DEAL_DOCUMENT_SIGN_REQUEST_UPLOADED:
    case NOTIFICATION_TYPES.DEAL_DOCUMENT_BB_SIGNED_REQUEST:
    case NOTIFICATION_TYPES.DEAL_DOCUMENT_SIGN_REQUEST_ACCEPTED:
    case NOTIFICATION_TYPES.DEAL_DOCUMENT_SIGN_REQUEST_REJECTED:
      return 'ico_termsheet';
    case NOTIFICATION_TYPES.YOUR_TASK_COMPLETED:
    case NOTIFICATION_TYPES.USER_ASSIGNED_TO_TASK:
    case NOTIFICATION_TYPES.YOUR_TASK_DUE_SOON:
      return 'ico_task';
    default:
      return 'ico_document';
  }
};

const NotificationCTA = ({
  type,
  loan,
  termsheet,
  submitAnswer,
  question,
  pk,
  listing,
  offer,
  getLenderDetails,
  lender,
  dealroom,
  task,
}) => {
  let cta;
  switch (type) {
    case NOTIFICATION_TYPES.YOUR_TASK_DUE_SOON:
    case NOTIFICATION_TYPES.YOUR_TASK_COMPLETED:
    case NOTIFICATION_TYPES.USER_ASSIGNED_TO_TASK:
      cta = (
        <LinkButton
          to={`/dashboard/tasks/${task.id}`}
        >
          <ButtonGhost>View task</ButtonGhost>
        </LinkButton>
      );
      break;
    case NOTIFICATION_TYPES.DEAL_DOCUMENT_SIGN_REQUEST_UPLOADED:
    case NOTIFICATION_TYPES.DEAL_DOCUMENT_BB_SIGNED_REQUEST:
    case NOTIFICATION_TYPES.DEAL_DOCUMENT_SIGN_REQUEST_ACCEPTED:
      cta = (
        <LinkButton
          to={`/dashboard/property-details/${loan.uuid}/deal-room/${dealroom?.pk}/all`}
        >
          <ButtonGhost>Go to Deal Room</ButtonGhost>
        </LinkButton>
      );
      break;

    case NOTIFICATION_TYPES.DEAL_DOCUMENT_SIGN_REQUEST_REJECTED:
      cta = (
        <LinkButton
          to={`/dashboard/property-details/${loan.uuid}/deal-room/${dealroom?.pk}/all`}
        >
          <ButtonGhost>Upload new</ButtonGhost>
        </LinkButton>
      );
      break;

    case NOTIFICATION_TYPES.BORROWER_ANSWERED_QUESTION:
    case NOTIFICATION_TYPES.CONCIERGE_POSTED:
    case NOTIFICATION_TYPES.CONCIERGE_READY_FOR_APPROVAL:
    case NOTIFICATION_TYPES.LOAN_REQUEST_WAS_DECLINED:
    case NOTIFICATION_TYPES.BORROWER_AWAITS_TERMSHEET:
    case NOTIFICATION_TYPES.YOU_HAVE_BEEN_MATCHED:
    case NOTIFICATION_TYPES.LENDER_PASSED_LOAN:
    case NOTIFICATION_TYPES.BORROWER_ADD_LENDER_TO_LOAN:
      cta = loan && (
        <LinkButton to={`/dashboard/property-details/${loan.uuid}`}>
          <ButtonGhost>Go to Loan</ButtonGhost>
        </LinkButton>
      );
      break;
    case NOTIFICATION_TYPES.LENDER_SENT_LOAN: 
      cta = loan && (
        <LinkButton to={`/dashboard/property-details/${loan.uuid}`}>
          <ButtonGhost>Finish loan request</ButtonGhost>
        </LinkButton>
      );
      break;
    case NOTIFICATION_TYPES.NEW_LOAN_ASSIGNED:
      cta = loan && (
        <LinkButton to={`/dashboard/property-details/${loan.uuid}`}>
          <ButtonGhost>Review Loan</ButtonGhost>
        </LinkButton>
      );
      break;
    case NOTIFICATION_TYPES.YOU_HAVE_COUNTEROFFER:
    case NOTIFICATION_TYPES.COUNTEROFFER_ACCEPTED:
    case NOTIFICATION_TYPES.COUNTEROFFER_REJECTED:
    case NOTIFICATION_TYPES.YOU_HAVE_OFFER:
    case NOTIFICATION_TYPES.OFFER_CANCELLED:
      cta = loan && offer && (
        <LinkButton to={`/dashboard/compare-offers/${loan.uuid}/${offer.pk}`}>
          <ButtonGhost>Go to Quote</ButtonGhost>
        </LinkButton>
      );
      break;
    case NOTIFICATION_TYPES.BORROWER_MADE_COUNTEROFFER:
    case NOTIFICATION_TYPES.BORROWER_ACCEPTED_OFFER:
    case NOTIFICATION_TYPES.BORROWER_ACCEPTED_COUNTEROFFER:
    case NOTIFICATION_TYPES.BORROWER_REJECTED_OFFER:
    case NOTIFICATION_TYPES.BORROWER_REJECTED_COUNTEROFFER:
      cta = (
        <LinkButton to={`/dashboard/property-details/${loan.uuid}/offers`}>
          <ButtonGhost>Go to Quote</ButtonGhost>
        </LinkButton>
      );
      break;
    case NOTIFICATION_TYPES.YOUR_ACCOUNT_WAS_APPROVED:
      cta = (
        <LinkButton to="/dashboard/marketplace">
          <ButtonGhost>Go to Marketplace</ButtonGhost>
        </LinkButton>
      );
      break;
    case NOTIFICATION_TYPES.YOU_HAVE_NEW_TERMSHEET:
      cta = (
        <LinkButton to={`/dashboard/property-details/${loan.uuid}/termsheets`}>
          <ButtonGhost>View Term Sheet</ButtonGhost>
        </LinkButton>
      );
      break;
    case NOTIFICATION_TYPES.BORROWER_SIGNED_TERMSHEET:
      cta = (
        <LinkButton
          to={`/dashboard/property-details/${loan.uuid}/deal-room/${dealroom.pk}/all`}
        >
          <ButtonGhost>Add a checklist</ButtonGhost>
        </LinkButton>
      );
      break;
    case NOTIFICATION_TYPES.BORROWER_REJECTED_TERMSHEET:
      cta = (
        <LinkButton to={`/dashboard/property-details/${loan.uuid}`}>
          <ButtonGhost>Go to Term Sheets</ButtonGhost>
        </LinkButton>
      );
      break;
    case NOTIFICATION_TYPES.LENDER_REJECTED_DEAL_DOCUMENT:
      cta = (
        <LinkButton
          to={`/dashboard/property-details/${loan.uuid}/deal-room/${dealroom?.pk}/rejected`}
        >
          <ButtonGhost>Upload new document</ButtonGhost>
        </LinkButton>
      );
      break;
    case NOTIFICATION_TYPES.LENDER_ACCEPT_INVITATION:
      cta = (
        <ButtonGhost onClick={() => getLenderDetails(lender.pk)}>View Lender</ButtonGhost>
      );
      break;
    case NOTIFICATION_TYPES.YOU_HAVE_NEW_QUESTION:
      cta = (
        <AnswerForm
          submitAnswer={(answer) =>
            submitAnswer({
              answer,
              loanId: loan.uuid,
              id: question.pk,
              notificationId: pk,
            })
          }
        />
      );
      break;

    case NOTIFICATION_TYPES.LENDER_MADE_PREQUALIFICATION:
      cta = (
        <LinkButton to={`/dashboard/listing/${listing.uuid}`}>
          <ButtonGhost>Go to Listing</ButtonGhost>
        </LinkButton>
      );
      break;
    case NOTIFICATION_TYPES.BORROWER_UPLOAD_NEW_DEAL_DOCUMENT:
    case NOTIFICATION_TYPES.DDR_CREATED:
    case NOTIFICATION_TYPES.LENDER_ACCEPTS_DEAL_DOCUMENT:
    case NOTIFICATION_TYPES.DEAL_DOCUMENT_REQUEST_COMPLETED:
      cta = (
        <LinkButton
          to={`/dashboard/property-details/${loan.uuid}/deal-room/${dealroom?.pk}/all`}
        >
          <ButtonGhost>Go to Deal Room</ButtonGhost>
        </LinkButton>
      );
      break;
    case NOTIFICATION_TYPES.BB_INVITED_BY_LENDER:
      cta = (
        <LinkButton to={`/dashboard/loan-creation`}>
          <ButtonGhost>Create New Loan</ButtonGhost>
        </LinkButton>
      );
      break;
    case NOTIFICATION_TYPES.LENDER_INVITED_TO_COMPANY:
      cta = (
        <LinkButton to={`/dashboard/clients-directory`}>
          <ButtonGhost>Invite clients</ButtonGhost>
        </LinkButton>
      );
      break;
    default:
      cta = null;
  }

  return !!cta && <NotificationBottomLine data-testid="daiBtn">{cta}</NotificationBottomLine>;
};

const NotificationContent = ({
  type,
  newBody,
  body: {
    loan,
    offer,
    lender,
    lenders,
    question,
    documentType,
    link,
    document: doc,
    documents = [],
    borrower = {},
    newPermissionLevel,
    rejectedReason,
    preQualification,
    requests,
    reason,
    termsheet,
    newDdrs,
    newLenders,
    ddrName,
    bb = {},
    dealSignRequests,
    task,
  },
  pk,
  uploadDocument,
  openConfidentialityAgreementModal,
  openDealRoomConfidentialityAgreementModal,
  updateDocumentProtectionState,
}) => {  
  switch (type) {
    case NOTIFICATION_TYPES.YOUR_TASK_DUE_SOON:
    case NOTIFICATION_TYPES.YOUR_TASK_COMPLETED:
    case NOTIFICATION_TYPES.USER_ASSIGNED_TO_TASK:
      return (
        <div>
          <TaskDescription>{task.name}</TaskDescription>

          {task.loan && 
            <LoanTitle
              to={`/dashboard/property-details/${task.loan.uuid}`}
              title={task.loan.propertyName}
              data-testid="loanTitle"
            >
              {cutStringByLength(task.loan.propertyName, 86) || 'Loan'}
            </LoanTitle>
          }
          {task.owner && <User user={task.owner}/>}
        </div>
      );    
    case NOTIFICATION_TYPES.LENDER_ACCEPT_INVITATION:
      return (
        <div>
          <User user={lender} />
          <Text>
            You can now view lender activity and communicate with the invited lender
          </Text>
        </div>
      );
    case NOTIFICATION_TYPES.BORROWER_ANSWERED_QUESTION:
      return (
        <div>
          <User user={borrower} />
          <Text>
            <p>
              <i>q: {question.question}</i>
            </p>
            a: {question.answer}
          </Text>
        </div>
      );
    case NOTIFICATION_TYPES.NEW_DOCUMENT_REQUEST:
      return (
        <RequestedDocuments
          lenders={lenders}
          lender={lender}
          requests={requests}
          uploadDocument={uploadDocument}
          updateDocumentProtectionState={updateDocumentProtectionState}
          notificationId={pk}
          loanId={loan.uuid}
        />
      );

    case NOTIFICATION_TYPES.BORROWER_AWAITS_TERMSHEET:
    case NOTIFICATION_TYPES.BORROWER_ADD_LENDER_TO_LD:
    case NOTIFICATION_TYPES.YOU_HAVE_BEEN_MATCHED:
    case NOTIFICATION_TYPES.BORROWER_SIGNED_TERMSHEET:
    case NOTIFICATION_TYPES.BORROWER_UPLOAD_NEW_DEAL_DOCUMENT:
      return <User user={borrower} />;
    case NOTIFICATION_TYPES.YOU_HAVE_OFFER:
    case NOTIFICATION_TYPES.YOU_HAVE_COUNTEROFFER:
      return <ContentOffer user={offer.author} offer={offer} />;
    case NOTIFICATION_TYPES.COUNTEROFFER_ACCEPTED:
    case NOTIFICATION_TYPES.BORROWER_MADE_COUNTEROFFER:
    case NOTIFICATION_TYPES.BORROWER_ACCEPTED_OFFER:
    case NOTIFICATION_TYPES.BORROWER_ACCEPTED_COUNTEROFFER:
    case NOTIFICATION_TYPES.COUNTEROFFER_REJECTED:
    case NOTIFICATION_TYPES.BORROWER_REJECTED_OFFER:
    case NOTIFICATION_TYPES.BORROWER_REJECTED_COUNTEROFFER:
      return <ContentOffer offer={offer} user={borrower} type={type} />;
    case NOTIFICATION_TYPES.YOU_HAVE_NEW_TERMSHEET:
      return (
        <div>
          <User user={lender} />
          <p>Review the term sheet and upload a signed version to continue</p>
        </div>
      );
    case NOTIFICATION_TYPES.LENDER_PASSED_LOAN:
    case NOTIFICATION_TYPES.LENDER_SENT_LOAN: 
      return (
        <div>
          <User user={lender} />
          {reason}
        </div>
      );
    case NOTIFICATION_TYPES.LENDER_REJECTED_DEAL_DOCUMENT:
      return (
        <div>
          <User user={lender} />
          <ErrorMessage>
            <b>Reason:</b> {rejectedReason}
          </ErrorMessage>
        </div>
      );

    case NOTIFICATION_TYPES.YOU_HAVE_NEW_QUESTION:
      return (
        <>
          <User user={question?.lender} />
          <Text>{question?.question}</Text>
        </>
      ); 

    case NOTIFICATION_TYPES.DEAL_DOCUMENT_BB_SIGNED_REQUEST:
      return (
        <div>
          <User user={bb} />

          <Text>
            {dealSignRequests.map((doc) => (
              <Text key={doc.id}>
                <Document>
                  <DocumentIcon /> {doc.name}
                </Document>
              </Text>
            ))}
          </Text>
        </div>
      );
    case NOTIFICATION_TYPES.DEAL_DOCUMENT_SIGN_REQUEST_ACCEPTED:
    case NOTIFICATION_TYPES.DEAL_DOCUMENT_SIGN_REQUEST_UPLOADED:
    case NOTIFICATION_TYPES.DEAL_DOCUMENT_SIGN_REQUEST_REJECTED:
      return (
        <div>
          <User user={lender} />
          <Text>
            {dealSignRequests.map((doc) => (
              <Text>
                <Document key={doc.id}>
                  <DocumentIcon /> {doc.name}
                </Document>
              </Text>
            ))}
          </Text>

          {rejectedReason && 
          <ErrorMessage>
              <b>Reason:</b> {rejectedReason}
          </ErrorMessage>}

        </div>
      );
    case NOTIFICATION_TYPES.REQUESTED_DOCUMENT_UPLOADED:
    case NOTIFICATION_TYPES.BORROWER_UPLOADED_NEW_DOCUMENT:
      return (
        <div>
          <User user={borrower} />
          {documents
            .concat(doc)
            .map((item) =>
              item ? (
                <File
                  key={item.id}
                  link={item.file || link}
                  type={item.label || item.label || 'Other'}
                  daiId={pk}
                  loanId={loan.uuid}
                  caSignedDealRoom = {loan.caSigned}
                  openDealRoomConfidentialityAgreementModal={openDealRoomConfidentialityAgreementModal}
                  openConfidentialityAgreementModal={openConfidentialityAgreementModal}
                />
              ) : null
            )}
        </div>
      );

    case NOTIFICATION_TYPES.NEW_TEAM_MEMBER_JOINED: {
      // Backend can't properly handle clients request for borrower
      return (
        <div>
          <User user={{ ...borrower, pk: null }} />
          <Text>
            Joined the team as a <b>user</b>
          </Text>
        </div>
      );
    }
    case NOTIFICATION_TYPES.LENDER_MADE_PREQUALIFICATION:
      return <ContentOffer user={preQualification.author} offer={preQualification} />;

    case NOTIFICATION_TYPES.BORROWER_REJECTED_TERMSHEET:
      return (
        <div>
          <User user={borrower} />
          <Text> Returning reason: {termsheet?.rejectionReason}</Text>
        </div>
      );

    case NOTIFICATION_TYPES.BB_INVITED_BY_LENDER:
      return (
        <div>
          <User user={lender} />
        </div>
      );

    case NOTIFICATION_TYPES.DDR_CREATED:
      return (
        <div>
          {newDdrs.map(({ name, closingDate }) => (
            <DDRItem name={name} closingDate={closingDate} />
          ))}
        </div>
      );

    case NOTIFICATION_TYPES.LENDER_ACCEPTS_DEAL_DOCUMENT:
    case NOTIFICATION_TYPES.DEAL_DOCUMENT_REQUEST_COMPLETED:
      return (
        <div>
          <div>
            <DDRItem name={ddrName} closingDate={null} withoutIcon={true} />
          </div>
          <div>
            <User user={lender} />
          </div>
        </div>
      );

    case NOTIFICATION_TYPES.LENDER_INVITED_TO_COMPANY:
      return (
        <div>
          <Text>
            You were invited to manage loans via RealAtom platform. Start by inviting your
            clients.{' '}
          </Text>
        </div>
      );

    case NOTIFICATION_TYPES.LOAN_REASSIGNED_TO_DIFFERENT_LENDER:
      return (
        <div>
          {newLenders.map((lender) => (
            <User user={lender} />
          ))}
        </div>
      );

    case NOTIFICATION_TYPES.OFFER_CANCELLED:
      return (
        <div>
          <ContentOffer user={offer.author} offer={offer} />
          <Text data-testid="offerCancelReason">Comment: {offer.cancelReason}</Text>
        </div>
      );
    default:
      return '';
  }
};

export const ActionItem = React.memo(
  ({
    type,
    body,
    body: { loan, termsheet, question, borrower, listing, lender, dealroom, task },
    submitAnswer,
    timestamp,
    uploadDocument,
    pk,
    removeNotification,
    description,
    toggleFolderProtectedState,
    getLenderDetails,
    openConfidentialityAgreementModal,
    openDealRoomConfidentialityAgreementModal,
    updateDocumentProtectionState,
  }) =>
    NotificationValues.includes(type) && (
      <NotificationWrapper data-testid="daiItem">
        <NotificationHeadline>
          <NotificationImageWrapper>
            <img
              data-testid="offerTitleIcon"
              src={`/images/icons/notifications/${getImageName(type)}.svg`}
              alt=""
            />
          </NotificationImageWrapper>
          <NotificationTitle data-testid="offerTitle">
            {getTitle(type, description, body)}
          </NotificationTitle>
          <NotificationDate>
            <HoverContent>
              {format(new Date(timestamp), 'MMMM dd, yyyy hh:mm a')}
            </HoverContent>
            <FormattedRelative value={new Date(timestamp)} />
          </NotificationDate>
        </NotificationHeadline>
        {!!loan && (
          <LoanTitle
            to={`/dashboard/property-details/${loan.uuid}`}
            title={loan.propertyName}
            data-testid="loanTitle"
          >
            {cutStringByLength(loan.propertyName, 86) || 'Loan'}
          </LoanTitle>
        )}
        <NotificationContent
          type={type}
          newBody={body}
          body={body}
          uploadDocument={uploadDocument}
          toggleFolderProtectedState={toggleFolderProtectedState}
          pk={pk}
          openConfidentialityAgreementModal={openConfidentialityAgreementModal}
          openDealRoomConfidentialityAgreementModal={openDealRoomConfidentialityAgreementModal}
          updateDocumentProtectionState={updateDocumentProtectionState}
        />
        <NotificationCTA
          type={type}
          loan={loan}
          offer={body.offer}
          termsheet={termsheet}
          question={question}
          submitAnswer={submitAnswer}
          pk={pk}
          listing={listing}
          getLenderDetails={getLenderDetails}
          lender={lender}
          dealroom={dealroom}
          task={task}
        />
        <RemoveNotification
          onClick={removeNotification}
          data-testid="trashIcon"
          data-id={pk}
        >
          <BucketIcon />
          <HoverContent data-id={pk}>Hide from dashboard</HoverContent>
        </RemoveNotification>
      </NotificationWrapper>
    )
);

ActionItem.displayName = 'ActionItem';

const TaskDescription = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: #1C3353;
  margin: 18px 0;
`;
const Document = styled.a`
  display: flex;
  align-items: center;
  margin-right: auto;
  color: #1c3353;
  font-size: 12px;
  line-height: 16px;
  text-decoration: none;
  margin-bottom: 16px;

  svg {
    margin-right: 8px;
  }
`;