import types from 'constants/actionTypes';
import { normalizeOptions } from 'lib/utils';

const initialState = { teams: [], invitedLenders: [], isNotInvitedLenders: [] };

export function lenderCompany(state = initialState, action = {}) {
  const { type, payload } = action;
  let isJoinedTeams;

  switch (type) {
    case types.GET_LENDER_COMPANY_DATA_SUCCESS:
    case types.UPDATE_LENDER_COMPANY_SETTINGS_SUCCESS:
      return payload;

    case types.ASSIGN_LOAN_TO_LENDER_SUCCESS: 
           
      // let data;
      // if(payload.chosenLenders.length !== state.isNotInvitedLenders.length){
      //   data = state.isNotInvitedLenders.filter((lender) =>
      //     payload.chosenLenders.find((chosenLender) =>
      //       lender.userId !== chosenLender.userId
      //       )
      //     )
      // }

      isJoinedTeams = payload.filter((lender) => lender.joined === true);
      return {
        ...state,
       // invitedLenders: [...state.invitedLenders, ...payload.chosenLenders],
       //isNotInvitedLenders: data || [],
        invitedLenders: isJoinedTeams.filter((lender) => lender.isInvited !== false),
        lenders: isJoinedTeams,
      };
    case types.GET_INVITED_LENDERS_SUCCESS:
      isJoinedTeams = payload.results.filter((lender) => lender.joined === true);
      return {
        ...state,
        invitedLenders: isJoinedTeams.filter((lender) => lender.isInvited !== false),
        isNotInvitedLenders: isJoinedTeams.filter((lender) => lender.isInvited === false),
        lenders: isJoinedTeams,
      };

    case types.CREATE_LENDER_COMPANY_TEAM_SUCCESS:
      return {
        ...state,
        teams: [...state.teams, payload.team],
      };

    case types.UPDATE_LENDER_COMPANY_TEAM_SUCCESS: {
      const teamId = Number(payload.teamId);
      return {
        ...state,
        teams: state.teams.map((team) =>
          teamId === team.id ? { ...team, name: payload.name } : team
        ),
      };
    }

    case types.ADD_LENDER_COMPANY_MEMBER_SUCCESS: {
      const teamId = Number(payload.teamId);

      return {
        ...state,
        teams: state.teams.map((team) =>
          teamId === team.id
            ? { ...team, members: [...team.members, payload.user] }
            : team
        ),
      };
    }

    case types.UPDATE_LENDER_COMPANY_MEMBER_SUCCESS: {
      const teamId = Number(payload.teamId);
      const userId = Number(payload.userId);

      return {
        ...state,
        teams: state.teams.map((team) =>
          teamId === team.id
            ? {
                ...team,
                members: [
                  ...team.members.map((user) =>
                    userId === user.userId
                      ? {
                          ...user,
                          permissionsLevel: payload.permissionsLevel,
                        }
                      : user
                  ),
                ],
              }
            : team
        ),
      };
    }

    case types.REMOVE_LENDER_COMPANY_MEMBER_SUCCESS: {
      const teamId = Number(payload.teamId);
      const membershipId = Number(payload.membershipId);

      return {
        ...state,
        teams: state.teams.map((team) =>
          teamId === team.id
            ? {
                ...team,
                members: team.members.filter((user) => user.id !== membershipId),
              }
            : team
        ),
      };
    }
    case types.SEARCH_LENDER_COMPANIES_SUCCESS: {
      return {
        ...state,
        teams: payload
      }
    }

    case types.SWITCH_TO_SEARCH_SUCCESS: {
      return {
        ...state,
        filteredTeam: payload
      }
    }

    default:
      return state;
  }
}
