import types from 'constants/actionTypes';

const initialState = {
  codes: [],
  dateGenerated: null,
};

export function recoveryCodes(state, action = {}) {
  const currentState = state || initialState;

  const { type, payload } = action;

  switch (type) {
    case types.GET_RECOVERY_CODES_SUCCESS:
    case types.GET_NEW_RECOVERY_CODES_SUCCESS:
      return {
        ...currentState,
        codes: payload.codes,
        dateGenerated: payload.dateGenerated,
      };

    default:
      return currentState;
  }
}
