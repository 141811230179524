import createDecorator from 'final-form-focus';
import { keys, findIndex } from 'ramda';

export const formDecorator = () =>
  createDecorator(null, (nodes, errors) => {
    const firstErrorFieldName =
      keys(errors)[0] === 'additionalAddresses'
        ? `additionalAddresses${findIndex((error) => !!error)(
            errors['additionalAddresses']
          )}` // TODO: hotfix, case with loan addressess focus, find better soluition for fields array
        : keys(errors)[0];

    const node = nodes.filter(
      (input) => input.name === firstErrorFieldName || input.id === firstErrorFieldName
    )[0];

    if (node) {
      node.focus();
    }
  });
