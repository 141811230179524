import { put, takeLatest, call, select } from 'redux-saga/effects';
import types from 'constants/actionTypes';
import * as API from 'api';
import {
  getConfigurationSuccess,
  getConfigurationFailure,
  getProfileSuccess,
  getProfileFailure,
  stopWebsocket,
  deleteCredentialsSuccess,
  deleteCredentialsFailure,
  deleteCredentialsRequest,
  shareListingSuccess,
  shareListingFailure,
  backToTeamViewSuccess,
  backToTeamViewFailure,
  forcePasswordChangeSuccess,
  forcePasswordChangeFailure,
  setCookieFailure,
  setCookieSuccess,
  checkPasswordNeedFailure,
  checkPasswordNeedSuccess,
  updatePasswordNeedFailure,
  updatePasswordNeedSuccess,
  settingDataSuccess,
  settingDataFailure
} from 'actions';

import { showModal, hideModal } from 'actions/modalActions';
import { modals, INFO_MODAL_TYPE } from 'constants/modalTypes';

import { analytics, intercomIdentify, setTokenSentToServer } from 'lib/analytics';
import config from 'constants/config';
import firebase from 'firebase/app';
import 'firebase/messaging';
import { RESET, LENDER, BORROWER, BROKER } from 'constants/appRelated';
import { getIntercomSettings, getError } from 'sagas/utils';
import { push } from 'connected-react-router';
import { getLenderCompanyDataRequest, getLenderTeamMembersRequest } from 'features/LenderCompany/actions';
import { removeCookie } from '../utils/cookie';
import { INVITATION_UUID } from '../features/Registration/Registration';

function* getConfiguration() {
  try {
    const {
      data: { response },
    } = yield call(API.axiosApi.get, '/choices');

    yield put(getConfigurationSuccess({ ...response }));
  } catch (e) {
    console.error(e);
    yield put(getConfigurationFailure({ message: getError(e) }));
  }
}

function* getProfile() {
  firebase.initializeApp(config.FIREBASE_DATA);

  try {
    const {
      data: { response },
    } = yield call(API.axiosApi.get, '/auth/profile');

    if (response.user) {
      const {
        user,
        draftLoansCount,
        submittedLoansCount,
        postedLoansCount,
        invitedByLender,
      } = response;
      const userExpand = {
        ...user,
        draftLoansCount,
        submittedLoansCount,
        postedLoansCount,
        invitedByLender,
      };

      if (user.tfa?.secondStepRequired) {
        yield put(push('/confirm-auth'));
      }

      let teamData = {};
      let companyData = [];

      if (
        !user.tfa?.secondStepRequired &&
        (user.accountType === BROKER || user.accountType === BORROWER)
      ) {
        const teamDataRequest = yield call(API.axiosApi.get, '/auth/team-settings');
        teamData = teamDataRequest.data.response;
      }

      if (user.accountType === LENDER) {
        yield put(getLenderTeamMembersRequest());
        yield put(getLenderCompanyDataRequest());

        const teamDataRequest = yield call(API.axiosApi.get, '/auth/team-members');
        teamData = teamDataRequest.data.response;
        const companyDataRequest = yield call(API.axiosApi.get, '/auth/company');
        companyData = companyDataRequest.data.response;
      }

      yield put(
        getProfileSuccess({
          ...userExpand,
          address1: response.address1,
          country: response.country,
          state: response.state,
          zip: response.zip,
          title: response.title,
          city: response.city,
          isNeedUpdateCriteria: response.isNeedUpdateCriteria,
          criteriaUpdatedAt: response.criteriaUpdatedAt,
          teamData,
          companyData: companyData.teams,
          team: teamData.members,
        })
      );

      intercomIdentify(getIntercomSettings(userExpand));

      if (!user.profileFilled && user.accountType === LENDER) {
        yield put(push('/finish-registration'));
      }
      if (user.isPasswordTemporary) {
        yield put(
          showModal(modals.FORCE_PASSWORD_CHANGE, {
            disableClose: true,
            size: 'sm',
          })
        );
      }
    } else {
      yield put(getProfileFailure({ message: 'something went wrong' }));
    }
  } catch (e) {
    console.error(e);
    yield put(deleteCredentialsRequest());
    yield put(getProfileFailure({ message: getError(e) }));
  }
}

function* deleteCredentials() {
  try {
    const fcmToken = window.localStorage.getItem('sentFirebaseMessagingToken');
    localStorage.removeItem('currentUser');
    localStorage.removeItem('sentFirebaseMessagingToken');
    removeCookie(INVITATION_UUID)

    yield put(stopWebsocket());

    analytics(RESET);

    if (firebase.messaging.isSupported()) {
      const messaging = firebase.messaging();

      if (fcmToken) {
        API.axiosApi.delete(`devices/${fcmToken}`);
        messaging
          .getToken()
          .then((currentToken) => {
            messaging
              .deleteToken(currentToken)
              .then(() => {
                console.log('Token deleted.');
                setTokenSentToServer(false);
                // Once token is deleted update UI.
              })
              .catch((error) => {
                console.error('Unable to delete token.', error);
              });
          })
          .catch((error) => {
            console.error('Error retrieving Instance ID token.', error);
          });
      }
    }

    intercomIdentify();

    yield put(deleteCredentialsSuccess());
  } catch (e) {
    console.log(e);
    yield put(deleteCredentialsFailure({ message: getError(e) }));
  }
}

function* shareListing(action) {
  const { id, emails } = action.payload;
  try {
    const invites = emails.map((item) => item.email);
    yield call(API.axiosApi.post, `/listings/${id}/invite`, { invitees: invites });
    yield put(shareListingSuccess());

    yield put(
      showModal(modals.INFO_MODAL, {
        title: 'Success',
        text: 'You have successfully shared your listing with your buyers',
        size: 'sm',
        infoType: INFO_MODAL_TYPE.SUCCESS,
      })
    );
  } catch (e) {
    console.error(e);
    yield put(shareListingFailure());
  }
}

function* backToTeamView() {

  // const state = yield select();
  // const { id, viewedAs, accountType } = state.currentUser;

  try {
    yield call(API.axiosApi.patch, '/auth/customer-profile', { viewed_as: null, viewed_as_team: null });

    yield put(backToTeamViewSuccess());
    window.location.reload();
  } catch (e) {
    console.error(e);
    yield put(backToTeamViewFailure());
  }
}

function* forcePasswordChange(action) {
  const { data } = action.payload;

  try {
    yield call(API.axiosApi.patch, '/auth/set-password', { password: data.password });

    yield put(forcePasswordChangeSuccess());
  } catch (e) {
    console.error(e);
    yield put(forcePasswordChangeFailure());
  }
}

function* setCookie(action) {
  const { cookie } = action.payload;
  try {
    document.cookie = `${cookie}; domain=${window.location.host}; path=/`;

    yield put(setCookieSuccess());
  } catch (e) {
    console.error(e);
    yield put(setCookieFailure());
  }
}

function* checkPasswordNeed(action) {
  const { path } = action.payload;

  try {
    const {
      data: { response },
    } = yield call(API.axiosApi.get, '/auth/tfa/password-check');

    if (response === 'request-password') {
      yield put(
        showModal(modals.ASK_PASSWORD, {
          path,
          size: 'sm',
        })
      );
    } else if (response === 'ok') {
      yield put(push(path));
    }
    yield put(checkPasswordNeedSuccess());
  } catch (e) {
    console.error(e);
    yield put(checkPasswordNeedFailure());
  }
}

function* updatePasswordNeed(action) {
  const { path, password } = action.payload;

  try {
    yield call(API.axiosApi.post, '/auth/tfa/password-check', { password });

    yield put(hideModal());

    yield put(push(path));
    yield put(updatePasswordNeedSuccess());
  } catch (e) {
    console.error(e);
    yield put(updatePasswordNeedFailure({ error: e?.response?.data?.error?.message }));
  }
}

function* settingData() {
  try {
    const {
      data: { response },
    } = yield call( API.axiosApi.get, '/settings');

    yield put(settingDataSuccess({ searchBarEnable: response.sEARCHBARENABLED }));
  } catch (e) {
    console.error(e);
    yield put(settingDataFailure({ message: getError(e) }));
  }
}


export default [
  takeLatest(types.SETTING_DATA_REQUEST, settingData),
  takeLatest(types.GET_CONFIGURATION_REQUEST, getConfiguration),
  takeLatest(types.GET_PROFILE_REQUEST, getProfile),
  takeLatest(types.DELETE_CREDENTIALS_REQUEST, deleteCredentials),
  takeLatest(types.SHARE_LISTING_REQUEST, shareListing),
  takeLatest(types.BACK_TO_TEAM_VIEW_REQUEST, backToTeamView),
  takeLatest(types.FORCE_PASSWORD_CHANGE_REQUEST, forcePasswordChange),
  takeLatest(types.SET_COOKIE_REQUEST, setCookie),
  takeLatest(types.CHECK_PASSWORD_NEED_REQUEST, checkPasswordNeed),
  takeLatest(types.UPDATE_PASSWORD_NEED_REQUEST, updatePasswordNeed),
];
