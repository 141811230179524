import React from 'react';
import styled from 'styled-components/macro';
import { Button, ButtonGhost } from 'ui/Button';
import { Form, Field } from 'react-final-form';
import { Input } from 'components/Form';
import Yup from 'yup';
import { validate } from 'utils/validate';

const schema = Yup.object().shape({
  propertyName: Yup.string().required('Loan Title is required!'),
});

export const EditTitleForm = ({ onFormSubmit, toggleForm, initialValues = {} }) => (
  <Form
    onSubmit={(values) => {
      toggleForm();
      onFormSubmit(values);
    }}
    validate={(values) => validate(values, schema)}
    initialValues={initialValues}
    render={({ handleSubmit }) => (
      <WrappedForm onSubmit={handleSubmit}>
        <InputRow>
          <InputWrapper>
            <Field
              name="propertyName"
              component={Input}
              label="Loan Title"
              autoFocus
            />
          </InputWrapper>
        </InputRow>
        <ButtonGhost type="button" onClick={toggleForm}>
          Cancel
        </ButtonGhost>
        <Button secondary type="submit">
          Save
        </Button>
      </WrappedForm>
    )}
  />
);

const InputWrapper = styled.div`
  width: 100%;
`;
const InputRow = styled.div`
  display: flex;
`;

const WrappedForm = styled.form`
  width: 100%;
  max-width: 400px;
  margin-bottom: 24px;

  ${Button} {
    padding-left: 32px;
    padding-right: 32px;
  }

  ${ButtonGhost} {
    padding-left: 32px;
    padding-right: 32px;
    margin-right: 16px;
  }
`;
