import types from 'constants/actionTypes';
import { merge, values, findIndex } from 'ramda';
import immutable from 'object-path-immutable';
import {
  LOAN_SECTIONS,
  DOCUMENTS_SECTIONS_IDS,
  CONTACTS_TYPES,
} from 'constants/appRelated';
import { getDocumentsUpdatePath } from './helpers';
import uuidv4 from 'uuid/v4';

const initialState = {
  loanData: {},
  isFetched: false,
  contacts: [],
  contactsTags: [],
  contactsTypes: [],
  sections: {
    [LOAN_SECTIONS.OVERVIEW]: {
      hasChanges: false,
      visited: false,
    },
    [LOAN_SECTIONS.TERMS]: {
      hasChanges: false,
      visited: false,
    },
    [LOAN_SECTIONS.PROFILE]: {
      hasChanges: false,
      visited: false,
    },
    [LOAN_SECTIONS.SPONSOR]: {
      hasChanges: false,
      visited: false,
    },
    [LOAN_SECTIONS.SU]: {
      hasChanges: false,
      visited: false,
    },
    [LOAN_SECTIONS.MARKET]: {
      hasChanges: false,
      visited: false,
    },
    [LOAN_SECTIONS.FILES]: {
      hasChanges: false,
      visited: false,
    },
    [LOAN_SECTIONS.SUMMARY]: {
      hasChanges: false,
      visited: false,
    },
    [LOAN_SECTIONS.DOCUMENTS]: {
      hasChanges: false,
      visited: false,
    },
  },
  addedBorrowerContact: null,
};

export default function loanCreation(state, action = {}) {
  const currentState = state || initialState;

  const { type, payload } = action;

  switch (type) {
    case types.LOAN_DATA_SUCCESS:
      const { stateOptions, loan } = payload;

      const newItem = stateOptions.filter((option) => {
        if(option.value === loan.state) return option;
      })

      return merge(currentState, {
        loanData: {...loan, state: newItem[0]},
        isFetched: true,
      });

    case types.SUBMIT_FOR_VERIFICATION_SUCCESS:
      return merge(currentState, {
        loanData: payload,
        isFetched: true,
      });

    case types.RESET_LOAN_INFO: {
      return initialState;
    }
    case types.SUBMIT_LOAN_FORM_SUCCESS: {
      
      const { item, form, stateOptions } = payload;

      const newItem = stateOptions.filter((option) => {
        if(option.value === item.state) return option;
      })

      return immutable(currentState)
        .set('loanData', {...item, state: newItem[0]})
        .set(`sections.${form}.hasChanges`, false)
        .set('isFetched', true)
        .value();
    }

    case types.SUBMIT_LOAN_FILE_REQUEST:
      return immutable.update(
        currentState,
        getDocumentsUpdatePath({
          currentState,
          folderId: payload.folderId,
          sectionId: payload.sectionId,
        }),
        (documents) => [...documents, payload.temporaryFile]
      );

    case types.SUBMIT_LOAN_FILE_SUCCESS:
      return immutable(currentState)
        .update(
          getDocumentsUpdatePath({
            currentState,
            folderId: payload.folderId,
            sectionId: payload.sectionId,
            documentId: payload.documentId,
          }),
          () => payload.document
        )
        .update('loanData', (loanData) => ({
          ...loanData,
          fullness: payload.fullness,
          canSubmitForVerification: payload.canSubmitForVerification,
        }))
        .value();

    case types.SUBMIT_LOAN_FILE_FAILURE:
      return immutable.del(
        currentState,
        getDocumentsUpdatePath({
          currentState,
          folderId: payload.folderId,
          sectionId: payload.sectionId,
          documentId: payload.documentId,
        })
      );

    case types.TOGGLE_DOCUMENT_PROTECTION_STATE_SUCCESS:
      return immutable.update(
        currentState,
        getDocumentsUpdatePath({
          currentState,
          folderId: payload.folderId,
          sectionId: payload.sectionId,
          documentId: payload.documentId,
        }),
        () => payload.document
      );

    case types.TOGGLE_FOLDER_PROTECTION_STATE_SUCCESS:
      return immutable(currentState)
        .set('loanData.documentsAll', payload.documentsAll)
        .value();

    case types.SUBMIT_LOAN_PHOTO_SUCCESS:
      return immutable(currentState)
        .set(
          `loanData.photos.${findIndex(
            ({ previewId }) => previewId === payload.previewId,
            currentState.loanData.photos
          )}`,
          payload.photo
        )
        .update('loanData', (loanData) => ({
          ...loanData,
          fullness: payload.fullness,
          canSubmitForVerification: payload.canSubmitForVerification,
        }))
        .value();

    case types.SUBMIT_LOAN_PHOTO_REQUEST:
      return immutable.update(currentState, 'loanData.photos', (photos = []) => [
        ...photos,
        { thumbnail: payload.thumbnail, previewId: payload.previewId, isUploading: true },
      ]);

    case types.SUBMIT_LOAN_PHOTOS_MULTIPART_REQUEST:
      let newPhotos = [];
      payload.photos.forEach((photo) => {
        newPhotos.push({
          thumbnail: photo.thumbnail,
          previewId: photo.previewId,
          isUploading: true,
        });
      });
      return immutable.update(currentState, 'loanData.photos', (photos = []) => [
        ...photos,
        ...newPhotos,
      ]);

    case types.SUBMIT_LOAN_PHOTO_FAILURE:
      return immutable.update(currentState, 'loanData.photos', (photos = []) =>
        photos.filter(({ previewId }) => previewId !== payload.previewId)
      );

    case types.SET_LOAN_FORM_CHANGE_STATUS:
      return immutable.set(
        currentState,
        `sections.${payload.type}.hasChanges`,
        payload.status
      );

    case types.SET_VISITED_LOAN_SECTION:
      return immutable.set(currentState, `sections.${payload.type}.visited`, true);

    case types.UPDATE_CUSTOM_FIELDS_SUCCESS:
      return immutable.set(currentState, 'loanData.customFields', payload.fields);

    case types.CREATE_CUSTOM_FOLDER_SUCCESS: {
      const { documentsAll } = payload;
      return immutable.update(currentState, 'loanData.documentsAll', () => documentsAll);
    }

    case types.DELETE_CUSTOM_FOLDER_SUCCESS: {
      const { folderId } = payload;
      return immutable.update(
        currentState,
        'loanData.documentsAll.sections',
        (sections) =>
          sections.map((section) =>
            section.sectionId === DOCUMENTS_SECTIONS_IDS.CUSTOM
              ? {
                  ...section,
                  folders: section.folders.filter((folder) => folder.id !== folderId),
                }
              : section
          )
      );
    }

    case types.UPDATE_CUSTOM_FOLDER_SUCCESS: {
      const { folderId, name } = payload;
      return immutable.update(
        currentState,
        'loanData.documentsAll.sections',
        (sections) =>
          sections.map((section) =>
            section.sectionId === DOCUMENTS_SECTIONS_IDS.CUSTOM
              ? {
                  ...section,
                  folders: section.folders.map((folder) =>
                    folder.id === folderId ? { ...folder, name } : folder
                  ),
                }
              : section
          )
      );
    }

    case types.GET_CONTACTS_SUCCESS:
      return {
        ...currentState,
        contacts: payload
          .filter((contact) => contact.types.indexOf(CONTACTS_TYPES.BORROWER) !== -1)
          .map(({ firstName, lastName, id }) => ({
            label: `${firstName} ${lastName}`,
            value: id,
          })),
      };

    case types.GET_CONTACTS_TAGS_SUCCESS:
      return {
        ...currentState,
        contactsTags: payload.map((value) => ({ label: value, value })),
      };

    case types.GET_CONTACTS_TYPES_SUCCESS:
      return {
        ...currentState,
        contactsTypes: payload.filter((type) => type.value === CONTACTS_TYPES.BORROWER),
      };

    case types.CONTACT_DIRECTORY_INVITE_SUCCESS:
      return payload.IsAddContactLoan
        ? {
            ...currentState,
            addedBorrowerContact: payload.contacts?.[payload.contactsArray[0]]?.id,
            contactsTags: payload.tags,
            contacts: [
              ...currentState.contacts,
              ...values(payload.contacts).map(({ firstName, lastName, id }) => ({
                label: `${firstName} ${lastName}`,
                value: id,
              })),
            ],
          }
        : currentState;

    case types.SET_AS_COVER_SUCCESS:
      const { photoId } = payload;

      return immutable.update(currentState, 'loanData.photos', (photos) =>
        photos.map((photo) =>
          photo.id === photoId
            ? { ...photo, isCover: true }
            : { ...photo, isCover: false }
        )
      );

    default:
      return currentState;
  }
}
