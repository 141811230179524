import React from 'react';
import styled from 'styled-components/macro';
import { CURRENCY, PERCENT } from 'constants/appRelated';
import { FormattedNumber } from 'react-intl';

import { SourcesRow, SourcesCell, SourcesCellWide, ValueCell } from './styles';

const RowTitle = styled(SourcesCellWide)`
  @media (max-width: ${(p) => p.theme.breakpoints[0]}px) {
    margin-bottom: 8px;
  }
`;

export const Row = ({ value, percent, title, EditedField }) =>
  value ? (
    <SourcesRow data-testid="suRow">
      <RowTitle>
        {title}
        {EditedField}
      </RowTitle>
      <ValueCell>
        <SourcesCell>
          <FormattedNumber
            style={CURRENCY}
            currency="USD"
            maximumFractionDigits={0}
            minimumFractionDigits={0}
            value={value}
          />
        </SourcesCell>
        <SourcesCell>
          <FormattedNumber
            style={PERCENT}
            maximumFractionDigits={2}
            minimumFractionDigits={0}
            value={percent}
          />
        </SourcesCell>
      </ValueCell>
    </SourcesRow>
  ) : null;
