import React from 'react';
import styled from 'styled-components/macro';
import { ModalHeader, ModalBody, ModalTitle } from 'ui/Modal/styles';
import { CheckCircle } from 'ui/CheckCircle';
import { Text } from 'ui/Text';
import { ButtonGhost, Button } from 'ui/Button';

export const LoanSendToLendersModal = ({
  realAtomLendersCount,
  relationshipLendersFormCount,
  hideModal,
  sendLoanToLenders,
  isPrivateBB,
}) => (
  <div>
    <Header>
      <WarningIcon />
      <Title>You’re about to send your loan to selected lenders</Title>
    </Header>
    <Body>
      <LendersItem>
        <CheckCircle variant="green" />
        <CountTextTitle>Relationship lenders</CountTextTitle>
        <CountText>{relationshipLendersFormCount || 0}</CountText>
      </LendersItem>
      {!isPrivateBB && (
        <LendersItem>
          <CheckCircle variant="green" />
          <CountTextTitle>RealAtom lenders</CountTextTitle>
          <CountText>{realAtomLendersCount || 0}</CountText>
        </LendersItem>
      )}

      <Controls>
        <ButtonGhost onClick={hideModal}>Go back</ButtonGhost>
        <Button
          secondary
          onClick={() => {
            hideModal();
            sendLoanToLenders();
          }}
        >
          Send to lenders
        </Button>
      </Controls>
    </Body>
  </div>
);

const WarningIcon = styled.div`
  margin-bottom: 24px;
  text-align: center;

  &::before {
    content: url('/images/icons/ico_info_warning.svg');
  }
`;

const Title = styled(ModalTitle)`
  text-align: center;
  font-size: 24px;
  line-height: 28px;
`;

const CountText = styled(Text)`
  color: #778598;
`;

const CountTextTitle = styled.div`
  font-size: 16px;
  line-height: 24px;
`;

const LendersItem = styled.div`
  width: 227px;
  margin-bottom: 12px;
  display: flex;
  align-items: center;

  ${CountTextTitle} {
    margin: 0 auto 0 16px;
  }
`;

const Header = styled(ModalHeader)`
  padding-bottom: 0;
  padding-top: 24px;
  box-shadow: none;
`;

const Body = styled(ModalBody)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 24px;

  background-color: #fff;
`;

const Controls = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 24px;
  padding-top: 24px;
  border-top: 1px solid #e8e9ed;

  ${Button} {
    margin-left: 16px;
  }
`;
