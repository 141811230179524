import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

export const ChartStub = ({ title, titleSymbol }) => (
  <Wrapper>
    <Title>
      {title}
      {!!titleSymbol && `, ${titleSymbol}`}
    </Title>
    <Stub>
      <StubImg src="/images/icons/ico_no_result.svg" alt="icon" />
      Sorry there is not enough data to construct the report.
      <br /> Please select another date range or disable some of the filters.
    </Stub>
  </Wrapper>
);

ChartStub.propTypes = {
  title: PropTypes.string.isRequired,
  titleSymbol: PropTypes.string,
};

const Wrapper = styled.div`
  background: #fff;
  padding: 24px;
  margin-top: 32px;
  border-radius: 16px;
  overflow-x: scroll;
`;

const Title = styled.div`
  font-size: 20px;
  font-size: 24px;
  margin-bottom: 24px;
  text-transform: capitalize;
`;

const Stub = styled.div`
  text-align: center;
  color: #778598;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 350px;
`;

const StubImg = styled.img`
  margin-bottom: 32px;
`;
