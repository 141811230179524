import { TERMSHEET_STATUSES } from 'constants/appRelated';

export const makeTermSheetLabel = (termsheets) => {
  let string = 'You have ';
  const newTermsheets = termsheets[TERMSHEET_STATUSES.UPLOADED];

  if (newTermsheets) {
    string += newTermsheets > 1 ? `${newTermsheets} new Term Sheets` : 'a new Term Sheet';
  }

  return string;
};
