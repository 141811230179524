import { LENDER, BORROWER, BROKER } from 'constants/appRelated';

export const accountTypeToNum = (type) => {
  switch (type) {
    case 'lender':
      return LENDER;
    case 'broker':
      return BROKER;
    case 'borrower':
      return BORROWER;
    default:
      return LENDER;
  }
};
