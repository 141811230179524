import React from 'react';
import { LenderReminder } from 'features/LoanNew/components';
import styled from 'styled-components/macro';

const MoodyLogo = ( { reminderSetOn } ) => {
    const scrollToTop = () => window.scrollTo(0, document.body.scrollHeight);

    return (
        <MoodyLogoWrapper>
            <div className={'logoWrap'} onClick={scrollToTop}/>
            <div className={'logo'}>
                {reminderSetOn && <LenderReminder timestamp={reminderSetOn} />}
                <div id='cls-badge-el2' />
            </div>
        </MoodyLogoWrapper>
    ) 
}

const MoodyLogoWrapper = styled.div`
    position: relative; 
    cursor: pointer;
    .logoWrap{
        width: 100%; 
        height: 100%;
        position: absolute;
    }
    .logo{
        display: flex;
        flexDirection: column;
    }
`;

export default MoodyLogo;