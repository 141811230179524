import React from 'react';
import PropTypes from 'prop-types';

export const ChartTemplate = ({ boxPlotData, stubSlot, chartSlot }) => (
  <>{boxPlotData.flat().length ? chartSlot : stubSlot} </>
);

ChartTemplate.propTypes = {
  boxPlotData: PropTypes.arrayOf(PropTypes.array).isRequired,
  stubSlot: PropTypes.node.isRequired,
  chartSlot: PropTypes.node.isRequired,
};
