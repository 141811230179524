import { put, takeLatest, call, select } from 'redux-saga/effects';
import types from 'constants/actionTypes';
import { push } from 'connected-react-router';
import * as API from 'api';
import { getError } from 'sagas/utils';
import { myOffersSuccess, myOffersFailure } from './actions';

function* myOffers(action) {
  try {
    const {
      currentUser: { accountType },
    } = yield select();

    const { payload: path } = action;

    const {
      data: { response },
    } = yield call(API.axiosApi.get, 'loans/offers');

    const { results } = response;

    if (
      results.length === 1 &&
      accountType !== 3 &&
      (path && path.path === '/dashboard/my-offers')
    ) {
      yield put(push(`/dashboard/property-details/${results[0].uuid}/offers`));
    }

    yield put(myOffersSuccess(response));
  } catch (e) {
    console.error(e);
    yield put(myOffersFailure({ message: getError(e) }));
  }
}

export default [takeLatest(types.MY_OFFERS_REQUEST, myOffers)];
