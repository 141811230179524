export const syncActions = ['RESET_CLIENT_DIRECTORY_DATA'];

export const asyncActions = [
  'NEW_CLIENT_INVITE',
  'CLIENT_DIRECTORY_INVITE',
  'GET_CLIENTS_DIRECTORY_DATA',
  'CHECK_CLIENT_DIRECTORY_EMAIL',
  'UPDATE_CLIENT_DIRECTORY_DATA',
  'GET_MORE_CLIENTS',
];
