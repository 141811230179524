import React from 'react';
import Dropzone from 'react-dropzone';
import { Button } from 'ui/Button';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

const StyledDropZone = styled(Dropzone)`
  width: auto;
  height: auto;
  border: none;
  display: inline-block;
`;

export const FileUpload = ({
  onDrop,
  label,
  children,
  accept,
  multiple,
  onDropRejected,
  maxSize = 5e7,
  onDragEnter,
  ...dropzoneProps
}) => {
  const handleOnDrop = (files, ...rest) => {
    if (files.length) {
      onDrop(files, ...rest);
    }
  };

  const handleDropReject = (files) => {
    onDropRejected(files, maxSize);
  };

  return (
    <StyledDropZone
      data-testid="fileUpload"
      onDrop={handleOnDrop}
      multiple={multiple || false}
      accept={
        accept ||
        '.doc, .docm, .docx, .odt, .pdf, .xlsx, .xlsm, .xls, .ods, .ppt, .pptx, .txt, .jpg, .png, .zip,'
      }
      onDropRejected={handleDropReject}
      maxSize={maxSize}
      {...dropzoneProps}
    >
      {children || (
        <Button type="button" bg="#FF9B12">
          {label || '+ ATTACH FILE'}
        </Button>
      )}
    </StyledDropZone>
  );
};

FileUpload.propTypes = {
  onDrop: PropTypes.func.isRequired,
  onDropRejected: PropTypes.func,
  label: PropTypes.string,
  accept: PropTypes.string,
  multiple: PropTypes.bool,
};
