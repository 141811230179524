import styled, { css } from 'styled-components/macro';

import { ReactComponent as Icon } from 'icons/ico_consultant_in_search.svg';

export const ConsultantInSearchIcon = styled(Icon)`
  ${(p) =>
  p.color &&
  css`
      path {
        stroke: ${p.color};
      }
    `}
`;
