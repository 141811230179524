import React from 'react';
import {
  ModalBody,
  ModalHeading,
  ModalWithContentWrapper,
  ModalActions,
} from 'ui/Modal/styles';
import { ButtonGhost } from 'ui/Button';
import { showModal, loanDealWonModalRequest } from 'actions/modalActions';
import { modals } from 'constants/modalTypes';
import styled from 'styled-components/macro';

export class LoanCloseSelect extends React.Component {
  openClosedLostModal = () => {
    const { loanId, dispatch, userType, loan } = this.props;
    dispatch(showModal(modals.LOAN_DEAL_LOST_FORM, { loanId, size: 'sm', userType, loan }));
  };
  openClosedWonModal = () => {
    const { loanId, dispatch, userType, loan } = this.props;
    dispatch(loanDealWonModalRequest({ userType, loanId, size: 'sm', loan }));
  };
  render() {
    const { hideModal, userType } = this.props;
    return (
      <ModalWithContentWrapper>
        <ModalHeading>Add deal closing notes</ModalHeading>
        <ModalBody>
          <CloseSelectItemLost onClick={this.openClosedLostModal}>
            Deal is closed lost
          </CloseSelectItemLost>
          <CloseSelectItemWon onClick={this.openClosedWonModal}>
            Deal is closed {userType.lender ? 'funded' : 'won'}
          </CloseSelectItemWon>
        </ModalBody>
        <ModalActions>
          <ButtonGhost transparent onClick={hideModal}>
            Cancel
          </ButtonGhost>
        </ModalActions>
      </ModalWithContentWrapper>
    );
  }
}

const CloseSelectItem = styled.div`
  border-bottom: 1px solid #e8e9ed;
  padding: 16px 0 16px 30px;
  display: flex;
  cursor: pointer;
  align-items: center;

  &:first-child {
    border-top: 1px solid #e8e9ed;
  }
`;

const CloseSelectItemWon = styled(CloseSelectItem)`
  background: url('/images/icons/ico_deal_close_won.svg') no-repeat left center;
`;
const CloseSelectItemLost = styled(CloseSelectItem)`
  background: url('/images/icons/ico_deal_close_lost.svg') no-repeat left center;
`;
