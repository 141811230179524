import types from 'constants/actionTypes';

const initialState = {
  modalType: null,
  modalProps: { invitedLenderId: null },
};

export default function(state = initialState, action = {}) {
  const { type } = action;

  switch (type) {
    case types.POSITION_MODAL:      
      return { ...state, modalProps: { ...state.modalProps, topPosition: action.payload } };
    case types.SHOW_MODAL:
      return {
        modalType: action.modalType,
        modalProps: action.modalProps,
      };

    case types.UPDATE_OFFER_MODAL:
      return { ...state, modalProps: { ...state.modalProps, item: action.payload } };

    case types.UPDATE_PASSWORD_NEED_FAILURE:
      return {
        ...state,
        modalProps: { ...state.modalProps, error: action.payload.error },
      };

    case types.CHECK_LENDER_DIRECTORY_EMAIL_SUCCESS:
      if (!action.payload.lenderId) return state;
      return {
        ...state,
        modalProps: { ...state.modalProps, invitedLenderId: action.payload.lenderId },
      };

    case types.HIDE_MODAL:
      return initialState;
    default:
      return state;
  }
}
