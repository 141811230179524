import types from 'constants/actionTypes';
import { normalize } from 'normalizr';
import { documentsTypesSchema } from 'utils/schemas';
import { normalizeOptions } from 'lib/utils';
import {
  indexOptions,
  yearBuildOptions,
  purchaseProcessOptions,
  recourseOptions,
  fixedOrVariableOptions,
  guarantorOptions,
  flagFranchiseOptions,
  propertiesPurchased,
  avgPropertyPrice,
  investmentOwn,
  ficoScore,
  states,
  creOptions,
} from 'utils/fields';

const unitOptions = [{ value: 0, label: 'Months' }, { value: 1, label: 'Years' }];
const isJoinedOptions = [
  { value: 'all', label: 'All' },
  { value: 'true', label: 'Joined' },
  { value: 'false', label: 'Not joined' },
];

const initialState = {
  loanTypes: [],
  normalizedLoanTypes: {},
  propertyTypes: [],
  normalizedPropertyTypes: {},
  loanAmortizationChoice: [],
  planUseProperty: [],
  normalizedDocumentsTypes: {},
  paymentsPlanChoice: [],
  isFetched: false,
  schema: {},
};

export default function configuration(state = initialState, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case types.GET_CONFIGURATION_SUCCESS: {
      const {
        loanType,
        propertyType,
        planUseProperty,
        documentType,
        interestRate,
        loanAmortizationChoice,
        leaseType,
        lenderType,
        loanAmountChoice,
        capitalSourceStrategy,
        typeOfCapitalSource,
        ownerStructure,
        investorType,
        ...rest
      } = payload.values;
      return {
        ...state,
        loanTypes: loanType,
        propertyTypes: propertyType,
        planUseProperty,
        documentsTypes: documentType,
        interestOnlyOptions: interestRate,
        loanAmortizationChoice,
        loanAmountChoice,
        normalizedDocumentsTypes: normalize(documentType || [], documentsTypesSchema)
          .entities.documents,
        ...rest,
        isFetched: true,
        schema: payload.schema,
        normalizedOptions: {
          loanTypeOptions: normalizeOptions(loanType),
          planUsePropertyOptions: normalizeOptions(planUseProperty),
          propertyTypeOptions: normalizeOptions(propertyType),
          amortizationOptions: normalizeOptions(loanAmortizationChoice),
          interestOnlyOptions: normalizeOptions(interestRate),
          indexOptions: normalizeOptions(indexOptions),
          yearBuildOptions: normalizeOptions(yearBuildOptions),
          yearRenovatedOptions: normalizeOptions(yearBuildOptions),
          purchaseProcessOptions: normalizeOptions(purchaseProcessOptions),
          recourseOptions: normalizeOptions(recourseOptions),
          fixedOrVariableOptions: normalizeOptions(fixedOrVariableOptions),
          guarantorOptions: normalizeOptions(guarantorOptions),
          flagFranchiseOptions: normalizeOptions(flagFranchiseOptions),
          propertiesPurchasedOptions: normalizeOptions(propertiesPurchased),
          avgPropertyPriceOptions: normalizeOptions(avgPropertyPrice),
          investmentOwnOptions: normalizeOptions(investmentOwn),
          ficoScoreOptions: normalizeOptions(ficoScore),
          stateOptions: normalizeOptions(states),
          leaseTypeOptions: normalizeOptions(leaseType),
          lenderTypeOptions: normalizeOptions(lenderType),
          loanAmountOptions: normalizeOptions(loanAmountChoice),
          unitOptions: normalizeOptions(unitOptions),
          creOptions: normalizeOptions(creOptions),
          capitalSourceStrategy: normalizeOptions(capitalSourceStrategy),
          typeOfCapitalSource: normalizeOptions(typeOfCapitalSource),
          ownerStructure: normalizeOptions(ownerStructure),
          investorType: normalizeOptions(investorType),
          isJoinedOptions: normalizeOptions(isJoinedOptions),
        },
        options: {
          loanTypeOptions: loanType,
          planUsePropertyOptions: planUseProperty,
          propertyTypeOptions: propertyType,
          amortizationOptions: loanAmortizationChoice,
          interestOnlyOptions: interestRate,
          indexOptions,
          yearBuildOptions,
          yearRenovatedOptions: yearBuildOptions,
          purchaseProcessOptions,
          recourseOptions,
          fixedOrVariableOptions,
          guarantorOptions,
          flagFranchiseOptions,
          propertiesPurchased,
          avgPropertyPrice,
          investmentOwn,
          ficoScore,
          lenderType: leaseType,
          lenderTypeOptions: lenderType,
          loanAmountOptions: loanAmountChoice,
          stateOptions: states,
          unitOptions,
          creOptions,
          capitalSourceStrategy,
          typeOfCapitalSource,
          ownerStructure,
          investorType,
          isJoinedOptions,
        },
      };
    }

    case types.GET_LENDER_TEAM_MEMBERS_SUCCESS: {

      const { members } = payload;

      const modifiedTeam =
      members &&
      members.reduce(
          (acc, member) =>
            member.joined
              ? [
                  ...acc,
                  {
                    value: member.userId,
                    label: `${member.firstName} ${member.lastName}`,
                    ...member,
                  },
                ]
              : acc,
          []
        );

      return {
        ...state,
        normalizedOptions: {
          ...state.normalizedOptions,
          teamOptions: modifiedTeam ? normalizeOptions(modifiedTeam) : {},
        },
        options: {
          ...state.options,
          teamOptions: modifiedTeam || [],
        },
      };

    }

    case types.GET_PROFILE_SUCCESS:
    case types.REGISTRATION_SUCCESS:
    case types.TEAM_MEMBER_REGISTRATION_SUCCESS:
    case types.LOGIN_SUCCESS: {
      const { team } = payload;
      const modifiedTeam =
        team &&
        team.reduce(
          (acc, member) =>
            member.joined
              ? [
                  ...acc,
                  {
                    value: member.userId,
                    label: `${member.firstName} ${member.lastName}`,
                    ...member,
                  },
                ]
              : acc,
          []
        );

      return {
        ...state,
        normalizedOptions: {
          ...state.normalizedOptions,
          teamOptions: modifiedTeam ? normalizeOptions(modifiedTeam) : {},
        },
        options: {
          ...state.options,
          teamOptions: modifiedTeam || [],
        },
      };
    }

    case types.GET_COMPANY_CLIENTS_SUCCESS:
      const { companies, clients } = payload;

      return {
        ...state,
        normalizedOptions: {
          ...state.normalizedOptions,
          companiesOptions: normalizeOptions(companies),
          clientsOptions: normalizeOptions(clients),
        },
        options: {
          ...state.options,
          companiesOptions: companies,
          clientsOptions: clients,
        },
      };

    default:
      return state;
  }
}
