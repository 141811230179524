import React from 'react';
import styled from 'styled-components/macro';
import { Input } from 'components/Form';
import { Button } from 'ui/Button';
import { Form, Field } from 'react-final-form';

const FormWrapper = styled.form`
  display: flex;
  padding-right: 20px;

  > :first-child {
    width: 100%;
  }

  input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: none;
  }

  ${Button} {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    padding-top: 5px;
    padding-bottom: 7px;
  }
`;

export class AnswerForm extends React.Component {
  formSubmit = ({ message }) => {
    if (message) {
      const { submitAnswer } = this.props;
      submitAnswer(message);
    }
  };

  render() {
    return (
      <Form
        onSubmit={this.formSubmit}
        render={({ handleSubmit, form }) => (
          <FormWrapper
            onSubmit={(event) => {
              handleSubmit(event);
              form.reset();
            }}
          >
            <Field
              name="message"
              component={Input}
              error=""
              mb={0}
              placeholder="Write your answer"
            />
            <Button secondary type="submit">
              Send
            </Button>
          </FormWrapper>
        )}
      />
    );
  }
}
