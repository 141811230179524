import React from 'react';
import styled from 'styled-components/macro';
import { Field } from 'react-final-form';
import { Input } from 'components/Form';
import { Section } from 'components/Modals/ManualOfferModal/styles';

const FieldWrap = styled.div`
  flex: 1 0;
`;

const FieldsWrapper = styled.div`
  display: flex;

  ${FieldWrap} {
    &:first-child {
      margin-right: 24px;
    }
  }

  &:first-child {
    margin-bottom: 24px;
  }
`;

export const AddLenderForm = ({ validateEmail, disabled }) => (
  <Section>
    <FieldsWrapper>
      <FieldWrap>
        <Field
          name="customLenderFirstName"
          component={Input}
          label="First name"
          required={!disabled}
          mb={0}
          disabled={disabled}
        />
      </FieldWrap>
      <FieldWrap>
        <Field
          name="customLenderLastName"
          component={Input}
          label="Last name"
          required={!disabled}
          mb={0}
          disabled={disabled}
        />
      </FieldWrap>
    </FieldsWrapper>
    <FieldsWrapper>
      <FieldWrap>
        <Field
          name="customLenderEmail"
          component={Input}
          label="Email"
          required={!disabled}
          mb={0}
          disabled={disabled}
          validate={validateEmail}
        />
      </FieldWrap>
      <FieldWrap>
        <Field
          name="customLenderCompany"
          component={Input}
          label="Company"
          required={!disabled}
          mb={0}
          disabled={disabled}
        />
      </FieldWrap>
    </FieldsWrapper>
  </Section>
);
