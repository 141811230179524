import React from 'react';
import styled, { css } from 'styled-components/macro';
import { Link } from 'react-router-dom';
import { FormattedNumber } from 'react-intl';
import { getLoanTerm } from 'lib/loan';
import { cutStringByLength } from 'lib/strings';
import { CURRENCY, LOAN_STATUS } from 'constants/appRelated';
import { Text } from 'ui/Text';

const WrapperCommon = styled.div`
  width: 48%;
  margin-bottom: 30px;
  border-radius: 9px;
  overflow: hidden;
  background: linear-gradient(90deg, #fff 68%, #eff0f0 0);
  box-shadow: 0 0 50px 6px rgba(0, 0, 0, 0.22);
  position: relative;

  &:nth-child(2n) {
    margin-left: 4%;
  }

  &::after {
    top: 25px;
    left: -50px;
    transform: rotate(-45deg);
    width: 200px;
    position: absolute;
    text-align: center;
    line-height: 50px;
    letter-spacing: 1px;
    color: #f0f0f0;
    text-transform: capitalize;
    font-size: 14px;
  }

  @media (max-width: 1200px) {
    width: 100%;

    &:nth-child(2n) {
      margin-left: 0;
    }
  }
`;

const Wrapper = styled(WrapperCommon)`
  ${(p) => {
    switch (p.status) {
      case LOAN_STATUS.PENDING_CLOSING:
        return css`
          &::after {
            content: 'pending';
            background: #2095f3;
          }
        `;
      case LOAN_STATUS.CLOSED_LOST:
      case LOAN_STATUS.CLOSED_WON:
      case LOAN_STATUS.DRAFT_WITHDRAWN:
        return css`
          &::after {
            content: 'closed';
            background: #ff9b12;
          }
        `;

      default:
        return '';
    }
  }}
`;

const Details = styled.div`
  display: flex;
`;

const Img = styled.div`
  height: 325px;
  width: 76%;
  background-size: cover;
  background-position: 50% 50%;
  box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.5);
`;

const LoanData = styled.div`
  padding: 12px;
  width: 24%;
`;

const LoanDataItem = styled.div`
  margin-bottom: 20px;
`;

const LoanDataLabel = styled(Text)`
  color: #9b9b9b;
`;

const LoanContent = styled.div`
  width: 76%;
  padding: 12px 20px;
`;

const LoanTitle = styled(Text)`
  font-size: 20px;
  min-height: 40px;
  margin-bottom: 6px;
  color: #333;
`;

const DetailsBtnCommon = styled.button`
  cursor: pointer;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 32%;
  padding: 31px 12px 27px;
  color: #fff;
  appearance: none;
  border: none;
  background: #ff9b12;
  text-align: left;
  outline: none;
  text-transform: uppercase;
  font-size: 18px;
`;

const DetailsBtn = styled(DetailsBtnCommon)`
  ${(p) =>
    p.disabled &&
    css`
      pointer-events: none;
      background-color: #555;
      cursor: not-allowed;
    `}
`;

export const LoansItemProps = ({
  data,
  data: { status },
  user,
  click,
  page,
  callback,
}) => {
  const isLogged = user && user.id;
  let propertyImage;

  const isClosed =
    status === LOAN_STATUS.CLOSED_LOST ||
    status === LOAN_STATUS.CLOSED_WON ||
    status === LOAN_STATUS.DRAFT_WITHDRAWN;
  if (data.photos.length) {
    propertyImage = `url(${data.photos[0].preview})`;
  } else {
    propertyImage = "url('/images/no_photo.svg')";
  }

  return (
    <Wrapper status={status}>
      <Details>
        <Img style={{ backgroundImage: propertyImage }} />
        <LoanData>
          {data.loanAmount && (
            <LoanDataItem>
              <Text>
                <FormattedNumber
                  style={CURRENCY}
                  currency="USD"
                  maximumFractionDigits={0}
                  minimumFractionDigits={0}
                  value={data.loanAmount}
                />
              </Text>
              <LoanDataLabel>loan request</LoanDataLabel>
            </LoanDataItem>
          )}
          {data.loanTerm && (
            <LoanDataItem>
              <Text>{getLoanTerm(data.loanTerm, data.loanTermUnit)}</Text>
              <LoanDataLabel>loan term</LoanDataLabel>
            </LoanDataItem>
          )}

          {data.planUsePropertyShortLabel && (
            <LoanDataItem>
              <Text>{data.planUsePropertyShortLabel}</Text>
              <LoanDataLabel>purpose</LoanDataLabel>
            </LoanDataItem>
          )}

          {data.loanTypeShortLabel && (
            <LoanDataItem>
              <Text>{data.loanTypeShortLabel}</Text>
              <LoanDataLabel>loan type</LoanDataLabel>
            </LoanDataItem>
          )}
        </LoanData>
      </Details>
      <LoanContent>
        <LoanTitle title={data.propertyName}>
          {cutStringByLength(data.propertyName, 40)}
        </LoanTitle>
        <LoanTitle>
          {data.propertyTypeLabel}
          {data.propertyTypeLabel === 'Land' &&
            data.lotSize &&
            `, ${data.lotSize} ${data.lotSize === 1 ? 'acre' : 'acres'}`}
          {data.unitsExactNumber ? `, ${data.unitsExactNumber} Units` : ''}
        </LoanTitle>
        <Text>{data.shortAddress}</Text>
      </LoanContent>
      {page && page === 'home' && isLogged ? (
        <Link to={`/dashboard/property-details/${data.id}`}>
          <DetailsBtn disabled={status === LOAN_STATUS.CLOSED_LOST}>
            See Details
          </DetailsBtn>
        </Link>
      ) : (
        <DetailsBtn onClick={click} disabled={status === LOAN_STATUS.CLOSED_LOST}>
          See Details
        </DetailsBtn>
      )}
      {!page &&
        (isClosed ? (
          <DetailsBtn disabled={status === LOAN_STATUS.CLOSED_LOST}>
            See Details
          </DetailsBtn>
        ) : (
          <Link to={`/dashboard/property-details/${data.id}`} onClick={callback}>
            <DetailsBtn>See Details</DetailsBtn>
          </Link>
        ))}
    </Wrapper>
  );
};
