import types from 'constants/actionTypes';

const initialState = {
  lenders: [],
  postedLoans: [],
  currentLoanId: null,
};

export function marketAnalyticsLenders(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case types.GET_ANALYTICS_LENDERS_DATA_SUCCESS:
      return {
        ...state,
        lenders: payload.lenders,
        postedLoans: payload.postedLoans,
      };

    case types.UPDATE_ANALYTICS_LENDERS_DATA_REQUEST:
      return {
        ...state,
        currentLoanId: payload.loanId || initialState.currentLoanId,
      };

    case types.CLEAR_MARKET_ANALYTICS_LENDERS:
      return initialState;

    default:
      return state;
  }
}
