import types from 'constants/actionTypes';

const initialState = { filters: {}, uri: null, filtersVisible: false };

export default function filter(state, action = {}) {
  const currentState = state || initialState;

  const { type, payload } = action;

  switch (type) {
    case types.OPEN_FILTERS:
      return {
        ...currentState,
        filtersVisible: true,
      };

    case types.CLOSE_FILTERS:
      return {
        ...currentState,
        filtersVisible: false,
      };

    case types.UPDATE_FILTER:
      return {
        ...currentState,
        filters: payload.filters,
        uri: payload.uri,
      };

    case types.CLEAR_FILTERS:
      return {
        ...currentState,
        filtersVisible: false,
        filters: initialState.filters,
        uri: initialState.uri,
      };

    default:
      return currentState;
  }
}
