import React from 'react';
import styled from 'styled-components/macro';
import { Text } from 'ui/Text';
import { Heading } from 'ui/Heading';
import { Button } from 'ui/Button';
import { compose } from 'ramda';
import { push } from 'connected-react-router';
import { setLoanFormChangeStatus } from 'features/LoanCreation/actions';

const Wrapper = styled.div`
  max-width: 530px;
  width: 100%;
  margin: 0 auto;
  padding: 40px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const InfoIcon = styled.div`
  width: 40px;
  height: 38px;
  margin-bottom: 24px;
  background: url('/images/icons/ico_info_warning.svg') no-repeat center center;
`;

const Controls = styled.div`
  display: flex;
  justify-content: center;
`;

export const LoanSectionChange = ({ hideModal, infoType, dispatch, section, type }) => (
  <Wrapper>
    <InfoIcon type={infoType} />
    <Heading mb={3}>Warning</Heading>
    <Text mb={5}>
      You are about to lose the changes you have made to this section.
      <br />
      Are you sure you want to skip to the next step?
    </Text>
    <Controls>
      <Button secondary mr={3} onClick={hideModal}>
        No
      </Button>
      <Button
        onClick={compose(
          hideModal,
          () => {
            dispatch(setLoanFormChangeStatus({ type, status: false }));
            dispatch(push(section));
          }
        )}
      >
        Yes
      </Button>
    </Controls>
  </Wrapper>
);
