import React, { useState, useCallback } from 'react';
import { UserAvatar } from 'components/UserAvatar';
import styled, { css } from 'styled-components';
import { Tooltip } from 'ui/Tooltip';
import { LENDER_TEAM_MEMBER_TYPES, LENDER_TEAM_MEMBER_LABELS } from 'constants/appRelated';

export function TeamMembers({userTeam, isPersonalView, setMemberId, currentTeam, memberId, currentUserId}) {
    const [isFormVisible, toggleFormVisibility] = useState(false);
    const isUserTeam = currentTeam.members.filter(subMember => subMember.userId === userTeam)[0];

    const handleFormVisibilityClick = useCallback(({target}) => {      
      if (target.tagName === 'svg' || 
          target.tagName === 'path' || 
          target.getAttribute('data-select')) return;

        toggleFormVisibility(!isFormVisible);
      }, [isFormVisible, toggleFormVisibility]);
    return (
      <TeamMembersWrapper>
        <TeamMembersTitle 
            isFormVisible={isFormVisible} 
            onClick={(event) => handleFormVisibilityClick(event)}
            active={isFormVisible}
        >
          {currentTeam.name}

          <span active={isFormVisible ? 1 : 0}>

            {/* {isPersonalView ? '(My team)' : ''} */}

            {isFormVisible && currentTeam.members.map((subMember) => (
              subMember.permissionsLevel === LENDER_TEAM_MEMBER_TYPES.COMPANY_ADMIN ? 
                subMember.userId === currentUserId ? '(My team)' :
                  <SwitchEntityName>
                    {`${subMember.firstName} ${subMember.lastName}'s team`}
                  </SwitchEntityName> : '' 
            ))}
          </span>
          <DropdownIndicator active={isFormVisible}>
            <svg
              width="12px"
              height="6px"
              viewBox="0 0 12 6"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              >
              <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g
                id="elements/input/inactive-dropdown-menu"
                transform="translate(-157.000000, -13.000000)"
                fill="#4394EA"
                fillRule="nonzero"
                >
                  <g id="Group-9">
                      <path
                      d="M162.920327,17.7735282 L168.134027,13.1320347 C168.322181,12.9645309 168.63856,12.9551478 168.840681,13.1110771 C169.042801,13.2670063 169.054124,13.5292006 168.86597,13.6967045 L162.908881,19 L157.128806,13.6919794 C156.943802,13.5220836 156.960016,13.2600658 157.165023,13.1067462 C157.370029,12.9534265 157.686196,12.9668641 157.871201,13.1367598 L162.920327,17.7735282 Z"
                      id="Path-7"
                      />
                  </g>
                </g>
              </g>
            </svg>
          </DropdownIndicator>

          <SwitchEntityEye
              data-select="selectTeam"
              onClick={ () => setMemberId(
                isUserTeam ?
                {myTeamView: true, id: currentTeam.id, type: 'teamId'} :
                {id: currentTeam.id, type: 'teamId'}
              ) }
              >
              <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0.5 6C0.5 6 3.5 0.5 8 0.5C12.5 0.5 15.5 6 15.5 6C15.5 6 12.5 11.5 8 11.5C3.5 11.5 0.5 6 0.5 6Z"
                        stroke={currentTeam.id === memberId ? "#4394ea" : "#778598"} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M8 8.5C9.38071 8.5 10.5 7.38071 10.5 6C10.5 4.61929 9.38071 3.5 8 3.5C6.61929 3.5 5.5 4.61929 5.5 6C5.5 7.38071 6.61929 8.5 8 8.5Z"
                        stroke={currentTeam.id=== memberId ? "#4394ea" : "#778598"} fill={currentTeam.id === memberId ? "#4394ea" : ''} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
          </SwitchEntityEye>
        </TeamMembersTitle>

        {/* innerArray team members */}
        {isFormVisible && currentTeam.members.map((subMember) => (
        <SwitchEntityWrapper key={subMember.id} active={subMember.userId === memberId}>
            <SwitchEntity
                className={'SwitchEntity'}
                key={subMember.id}
                >
                <UserAvatar author={subMember} />
                <SwitchEntityName>{`${subMember.firstName} ${subMember.lastName}`}</SwitchEntityName>
                {subMember.userId === currentUserId && (
                    <Tooltip
                    text={'Choose this option to view only your loans and analytics'}
                    position="bottom"
                    />                      
                )}  
                <span style={{ color:'#778598', marginLeft:'5px'}}>
                  {Object.keys(LENDER_TEAM_MEMBER_TYPES).map((key, index) => {
                    if(LENDER_TEAM_MEMBER_TYPES[key] === subMember.permissionsLevel)
                      return LENDER_TEAM_MEMBER_LABELS[LENDER_TEAM_MEMBER_TYPES[key]];
                  })}
                </span>
    
                <SwitchEntityEye
                  onClick={ () => setMemberId({id: subMember.userId, type: 'memberId'}) }
                >
                    <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.5 6C0.5 6 3.5 0.5 8 0.5C12.5 0.5 15.5 6 15.5 6C15.5 6 12.5 11.5 8 11.5C3.5 11.5 0.5 6 0.5 6Z" 
                              stroke={subMember.userId === memberId ? "#4394ea" : "#778598"} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M8 8.5C9.38071 8.5 10.5 7.38071 10.5 6C10.5 4.61929 9.38071 3.5 8 3.5C6.61929 3.5 5.5 4.61929 5.5 6C5.5 7.38071 6.61929 8.5 8 8.5Z" 
                              stroke={subMember.userId === memberId ? "#4394ea" : "#778598"} fill={subMember.userId === memberId ? "#4394ea" : ''} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </SwitchEntityEye>
            </SwitchEntity>
        </SwitchEntityWrapper>
        ))}
      </TeamMembersWrapper>
    )
}
const DropdownIndicator = styled.div`
    display: ${(p) => p.active ? 'none' : 'flex'}; 
    margin-left: 8px;
`;

const TeamMembersWrapper = styled.div`
  margin-bottom: 12px;
  background: #FFFFFF;
  box-shadow: 0px 4px 10px rgba(194, 198, 210, 0.2);
  border-radius: 2px;
`;

const TeamMembersTitle = styled.h1`
  border-bottom: ${(p) => p.active ? '1px solid #E8E9ED;' : 'none;' };
  margin: 0 20px;
  padding: ${(p) => p.active ? '19px 0 8px;' : '19px 0 18px;'};
  cursor: pointer;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: ${(p) => p.active ? '#1C3353;' : '#4394EA;'}; 

  span{
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #778598;
    margin-left: 5px;
    display: ${(p) => p.active ? 'inline-block' : 'none' }; 
  }
`;

const SwitchEntityEye = styled.div`
  margin-top: -5px;
  margin-left: auto;
  cursor: pointer;
`;

const SwitchEntity = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  padding: 10px 0;
  border-radius: 4px;
  background: #fff;
  margin: 0 20px;
 // margin: ${(p) => p.active ? `0px` : `0 20px` }
  border-bottom: 1px solid #E8E9ED;
`;

const SwitchEntityName = styled.span`
  margin-left: 8px;
  margin-right: 8px;
`;

const SwitchEntityWrapper = styled.div`
  &:last-child{
    padding-bottom: 10px;
    .SwitchEntity{
        border-bottom: none; 
    }
  }
`;