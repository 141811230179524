export const asyncActions = [
  'SUBMIT_LOAN_FILE',
  'SUBMIT_LOAN_PHOTO',
  'SUBMIT_LOAN_PHOTOS_MULTIPART',
  'DELETE_LOAN_FILE',
  'SUBMIT_LOAN_FORM',
  'SUBMIT_NEW_LOAN',
  'NOTIFY_UNCOMMON_LOAN',
  'UPDATE_CUSTOM_FIELDS',
  'CREATE_CUSTOM_FOLDER',
  'UPDATE_CUSTOM_FOLDER',
  'DELETE_CUSTOM_FOLDER',
  'TOGGLE_DOCUMENT_PROTECTION_STATE',
  'TOGGLE_FOLDER_PROTECTION_STATE',
  'SET_AS_COVER',
];

export const syncActions = ['SET_LOAN_FORM_CHANGE_STATUS', 'SET_VISITED_LOAN_SECTION'];
