import React from 'react';
import { LENDER, BROKER, BORROWER, AM, SALES_BROKER } from 'constants/appRelated';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components/macro';

const PreviewCommon = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #cccccc;
  color: #fff;
  text-align: center;
  line-height: 32px;
`;

const DefaultUserPreview = styled(PreviewCommon)`
  ${(p) => {
    switch (p.variant) {
      case 'small':
        return css`
          width: 24px;
          height: 24px;
          line-height: 24px;
          font-size: 10px;
          font-weight: normal;
        `;
      case 'big':
        return css`
          height: 48px;
          width: 48px;
          line-height: 48px;
          font-size: 16px;
        `;

      case 'biggest':
        return css`
          height: 80px;
          width: 80px;
          line-height: 80px;
          font-size: 24px;
        `;

      default:
        break;
    }
  }};
`;

const BorrowerPreview = styled(DefaultUserPreview)`
  background: #ffc15e;
`;

const LenderPreview = styled(DefaultUserPreview)`
  position: relative;
  background: ${(p) => p.lenderTypeBg || '#0267C1'};

  ${(p) =>
    p.isRealatomLender &&
    css`
      &::before {
        content: url(/images/icons/ico_realatom_small.svg);
        position: absolute;
        top: 25px;
        right: -5px
          ${p.variant === 'biggest' &&
            css`
              top: 35px;
              right: 5px;
            `};
      }
    `}
`;

const AmPreview = styled(DefaultUserPreview)`
  background: #0267c1 url('/images/icons/ico_chat_am.svg') no-repeat center;
`;

const SalesBrokerPreview = styled(DefaultUserPreview)`
  background: #ffc15e;
`;

const NotRegisteredPreview = styled(DefaultUserPreview)`
  background: #cfd0d7;
`;

export const UserAvatar = ({ author, size }) => {
  /*
    If firstName or lasName in profile not null/undefined, take first letter from these strings,
    else, return empty string.
    If firstName or lasName is null, ramda returns undefined,
    without crashing on taking property of null
  */
  const authorName =
    author?.initials || `${author?.firstName?.[0] || ''}${author?.lastName?.[0] || ''}`;

  if (typeof author?.registered !== 'undefined' && !author.registered) {
    return <NotRegisteredPreview variant={size}>{authorName}</NotRegisteredPreview>;
  }

  switch (author?.accountType) {
    case BROKER:
    case BORROWER: {
      return (
        <BorrowerPreview variant={size} title={author?.userRepresentation}>
          {authorName}
        </BorrowerPreview>
      );
    }
    case LENDER:
      return (
        <LenderPreview
          lenderTypeBg={author?.lenderTypeColor}
          variant={size}
          isRealatomLender={author?.isRealatomLender}
          title={author?.userRepresentation}
        >
          {authorName}
        </LenderPreview>
      );
    case AM:
      return <AmPreview variant={size} />;
    case SALES_BROKER:
      return <SalesBrokerPreview variant={size}>{authorName}</SalesBrokerPreview>;
    default:
      return <DefaultUserPreview variant={size} />;
  }
};

UserAvatar.propTypes = {
  size: PropTypes.oneOf(['biggest', 'big', 'small', undefined]),
};
