import types from 'constants/actionTypes';
import immutable from 'object-path-immutable';
import { findIndex } from 'ramda';
import { OFFER_STATE } from 'constants/appRelated';

const initialState = {
  loans: [],
  next: null,
};

export default function offers(state, action = {}) {
  const currentState = state || initialState;

  const { type } = action;

  switch (type) {
    case types.MY_OFFERS_SUCCESS:
      return {
        ...currentState,
        loans: action.payload.results.map(result => ({...result, id: result.uuid})),

        next: action.payload.next,
      };

    case types.CANCEL_OFFER_SUCCESS:
      return {
        ...currentState,
        loans: currentState.loans.map((loan) => {
          const negotiation = loan.negotiations[action.payload.id];
          if (loan.id !== action.payload.loanId || !negotiation) {
            return loan;
          }

          const offerIndex = findIndex(
            ({ pk }) => pk === action.payload.negotiation_pk,
            negotiation.negotiations
          );

          return immutable.update(
            loan,
            `negotiations.${action.payload.id}.negotiations.${offerIndex}`,
            (offer) => ({ ...offer, state: OFFER_STATE.CANCELLED })
          );
        }),
      };

    default:
      return currentState;
  }
}
