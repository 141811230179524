import types from 'constants/actionTypes';

const initialState = { teamMembers: [], getExport: 0 };

export function teamAnalytics(state, action = {}) {
  const currentState = state || initialState;

  const { type, payload } = action;

  switch (type) {
    case types.TEAM_ANALYTICS_REQUEST:
      return { ...currentState, isFetching: true };
    case types.TEAM_ANALYTICS_SUCCESS:
      return { ...currentState, teamMembers: payload.data, isFetching: false };
    case types.TEAM_ANALYTICS_FAILURE:
      return { ...currentState, error: payload, isFetching: false };
    case types.EXPORT_TEAM_ANALYTICS_SUCCESS:
      return { ...currentState };
    case types.EXPORT_TEAM_ANALYTICS_FAILURE:
      return { ...currentState, error: payload };
    case types.GET_EXPORT_TEAM_ANALYTICS:
      return { ...currentState, getExport: (currentState.getExport + 1) };
    default:
      return currentState;
  }
}