import types from 'constants/actionTypes';

export const getMarketAnalyticsLoansRequest = (payload = {}) => ({
  type: types.GET_MARKET_ANALYTICS_LOANS_REQUEST,
  payload,
});
export const getMarketAnalyticsLoansSuccess = (payload = {}) => ({
  type: types.GET_MARKET_ANALYTICS_LOANS_SUCCESS,
  payload,
});
export const getMarketAnalyticsLoansFailure = (payload = {}) => ({
  type: types.GET_MARKET_ANALYTICS_LOANS_FAILURE,
  payload,
});

export const updateMarketAnalyticsLoansRequest = (payload = {}) => ({
  type: types.UPDATE_MARKET_ANALYTICS_LOANS_REQUEST,
  payload,
});
export const updateMarketAnalyticsLoansSuccess = (payload = {}) => ({
  type: types.UPDATE_MARKET_ANALYTICS_LOANS_SUCCESS,
  payload,
});
export const updateMarketAnalyticsLoansFailure = (payload = {}) => ({
  type: types.UPDATE_MARKET_ANALYTICS_LOANS_FAILURE,
  payload,
});

export const exportAnalyticsLoanDataRequest = (payload = {}) => ({
  type: types.EXPORT_ANALYTICS_LOAN_DATA_REQUEST,
  payload,
});
export const exportAnalyticsLoanDataSuccess = (payload = {}) => ({
  type: types.EXPORT_ANALYTICS_LOAN_DATA_SUCCESS,
  payload,
});
export const exportAnalyticsLoanDataFailure = (payload = {}) => ({
  type: types.EXPORT_ANALYTICS_LOAN_DATA_FAILURE,
  payload,
});

export const updateMarketAnalyticsLoansOrdering = (payload = {}) => ({
  type: types.UPDATE_MARKET_ANALYTICS_LOANS_ORDERING,
  payload,
});

export const clearMarketAnalyticsFilters = (payload = {}) => ({
  type: types.CLEAR_MARKET_ANALYTICS_FILTERS,
  payload,
});
