import types from 'constants/actionTypes';

export const submitLoanFormRequest = (payload = {}) => ({
  type: types.SUBMIT_LOAN_FORM_REQUEST,
  payload,
});
export const submitLoanFormSuccess = (payload = {}) => ({
  type: types.SUBMIT_LOAN_FORM_SUCCESS,
  payload,
});
export const submitLoanFormFailure = (payload = {}) => ({
  type: types.SUBMIT_LOAN_FORM_FAILURE,
  payload,
});

export const submitNewLoanRequest = (payload = {}) => ({
  type: types.SUBMIT_NEW_LOAN_REQUEST,
  payload,
});
export const submitNewLoanSuccess = (payload = {}) => ({
  type: types.SUBMIT_NEW_LOAN_SUCCESS,
  payload,
});
export const submitNewLoanFailure = (payload = {}) => ({
  type: types.SUBMIT_LOAN_FORM_FAILURE,
  payload,
});

export const submitLoanFileRequest = (payload = {}) => ({
  type: types.SUBMIT_LOAN_FILE_REQUEST,
  payload,
});
export const submitLoanFileSuccess = (payload = {}) => ({
  type: types.SUBMIT_LOAN_FILE_SUCCESS,
  payload,
});
export const submitLoanFileFailure = (payload = {}) => ({
  type: types.SUBMIT_LOAN_FILE_FAILURE,
  payload,
});

export const submitLoanPhotoRequest = (payload = {}) => ({
  type: types.SUBMIT_LOAN_PHOTO_REQUEST,
  payload,
});

export const submitLoanPhotosMultiPartRequest = (payload = {}) => ({
  type: types.SUBMIT_LOAN_PHOTOS_MULTIPART_REQUEST,
  payload,
});

export const submitLoanPhotoSuccess = (payload = {}) => ({
  type: types.SUBMIT_LOAN_PHOTO_SUCCESS,
  payload,
});
export const submitLoanPhotoFailure = (payload = {}) => ({
  type: types.SUBMIT_LOAN_PHOTO_FAILURE,
  payload,
});

export const deleteLoanFileRequest = (payload = {}) => ({
  type: types.DELETE_LOAN_FILE_REQUEST,
  payload,
});
export const deleteLoanFileSuccess = (payload = {}) => ({
  type: types.DELETE_LOAN_FILE_SUCCESS,
  payload,
});
export const deleteLoanFileFailure = (payload = {}) => ({
  type: types.DELETE_LOAN_FILE_FAILURE,
  payload,
});

export const setLoanFormChangeStatus = ({ type, status }) => ({
  type: types.SET_LOAN_FORM_CHANGE_STATUS,
  payload: { type, status },
});

export const setVisitedLoanSection = ({ type }) => ({
  type: types.SET_VISITED_LOAN_SECTION,
  payload: { type },
});

export const notifyUncommonLoanRequest = (payload = {}) => ({
  type: types.NOTIFY_UNCOMMON_LOAN_REQUEST,
  payload,
});
export const notifyUncommonLoanSuccess = (payload = {}) => ({
  type: types.NOTIFY_UNCOMMON_LOAN_SUCCESS,
  payload,
});
export const notifyUncommonLoanFailure = (payload = {}) => ({
  type: types.NOTIFY_UNCOMMON_LOAN_FAILURE,
  payload,
});

export const updateCustomFieldsRequest = ({
  actionType,
  sectionName,
  label,
  value,
  name,
  loanId,
  fields,
}) => ({
  type: types.UPDATE_CUSTOM_FIELDS_REQUEST,
  payload: {
    actionType,
    sectionName,
    label,
    value,
    name,
    loanId,
    fields,
  },
});
export const updateCustomFieldsSuccess = (payload = {}) => ({
  type: types.UPDATE_CUSTOM_FIELDS_SUCCESS,
  payload,
});
export const updateCustomFieldsFailure = (payload = {}) => ({
  type: types.UPDATE_CUSTOM_FIELDS_FAILURE,
  payload,
});

export const createCustomFolderRequest = ({ name, id }) => ({
  type: types.CREATE_CUSTOM_FOLDER_REQUEST,
  payload: { name, id },
});
export const createCustomFolderSuccess = (payload = {}) => ({
  type: types.CREATE_CUSTOM_FOLDER_SUCCESS,
  payload,
});
export const createCustomFolderFailure = (payload = {}) => ({
  type: types.CREATE_CUSTOM_FOLDER_FAILURE,
  payload,
});

export const updateCustomFolderRequest = ({ name, folderId, id }) => ({
  type: types.UPDATE_CUSTOM_FOLDER_REQUEST,
  payload: { name, folderId, id },
});
export const updateCustomFolderSuccess = (payload = {}) => ({
  type: types.UPDATE_CUSTOM_FOLDER_SUCCESS,
  payload,
});
export const updateCustomFolderFailure = (payload = {}) => ({
  type: types.UPDATE_CUSTOM_FOLDER_FAILURE,
  payload,
});

export const deleteCustomFolderRequest = ({ folderId, id }) => ({
  type: types.DELETE_CUSTOM_FOLDER_REQUEST,
  payload: { folderId, id },
});
export const deleteCustomFolderSuccess = (payload = {}) => ({
  type: types.DELETE_CUSTOM_FOLDER_SUCCESS,
  payload,
});
export const deleteCustomFolderFailure = (payload = {}) => ({
  type: types.DELETE_CUSTOM_FOLDER_FAILURE,
  payload,
});
export const setAsCoverRequest = ({ loanId, id }) => ({
  type: types.SET_AS_COVER_REQUEST,
  payload: { loanId, id },
});
export const setAsCoverSuccess = (payload = {}) => ({
  type: types.SET_AS_COVER_SUCCESS,
  payload,
});
export const setAsCoverFailure = (payload = {}) => ({
  type: types.SET_AS_COVER_FAILURE,
  payload,
});

export const toggleDocumentProtectionStateRequest = ({
  folderId,
  documentId,
  loanId,
  formData,
  sectionId,
}) => ({
  type: types.TOGGLE_DOCUMENT_PROTECTION_STATE_REQUEST,
  payload: { folderId, documentId, loanId, formData, sectionId },
});
export const toggleDocumentProtectionStateSuccess = ({
  document,
  documentId,
  folderId,
  sectionId,
}) => ({
  type: types.TOGGLE_DOCUMENT_PROTECTION_STATE_SUCCESS,
  payload: { document, documentId, folderId, sectionId },
});
export const toggleDocumentProtectionStateFailure = (payload = {}) => ({
  type: types.TOGGLE_DOCUMENT_PROTECTION_STATE_FAILURE,
  payload,
});

export const toggleFolderProtectionStateRequest = ({
  folderId,
  loanId,
  protectionState,
}) => ({
  type: types.TOGGLE_FOLDER_PROTECTION_STATE_REQUEST,
  payload: { folderId, loanId, protectionState },
});
export const toggleFolderProtectionStateSuccess = ({ documentsAll }) => ({
  type: types.TOGGLE_FOLDER_PROTECTION_STATE_SUCCESS,
  payload: { documentsAll },
});
export const toggleFolderProtectionStateFailure = (payload = {}) => ({
  type: types.TOGGLE_FOLDER_PROTECTION_STATE_FAILURE,
  payload,
});
