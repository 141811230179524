import React from 'react';
import { InputPassword as Input } from 'ui/InputPassword';

export const InputPassword = ({
  input: { name, onChange, value, ...restInput },
  label,
  withUnit,
  meta: { touched, error },
  className,
  ...rest
}) => (
  <Input
    name={name}
    label={label}
    error={touched && error ? error : ''}
    onChange={onChange}
    value={value}
    className={className}
    {...rest}
    {...restInput}
  />
);
