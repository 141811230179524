import React from 'react';
import {
  ModalBody,
  ModalHeading,
  ModalActions,
  ModalSubHeading,
  modalWithContentWrapperStyles,
} from 'ui/Modal/styles';
import { Button } from 'ui/Button';

import { Form, Field } from 'react-final-form';
import { Input } from 'components/Form';
import { forcePasswordChangeRequest } from 'actions';
import styled from 'styled-components/macro';
import Yup from 'yup';
import { validate } from 'utils/validate';

const schema = Yup.object().shape({
  password: Yup.string()
    .min(8, 'Password must be at least 8 characters')
    .required('Password is required!'),
  passwordConfirmation: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Password confirmation is not equal to password')
    .required('Password confirm is required'),
});

export const ForcePasswordChange = ({ dispatch, hideModal }) => {
  return (
    <Form
      onSubmit={(data) => {
        hideModal();
        dispatch(forcePasswordChangeRequest({ data }));
      }}
      validate={(values) => validate(values, schema)}
      render={({ handleSubmit }) => (
        <FormWrapper onSubmit={handleSubmit}>
          <ModalHeading>Change Password</ModalHeading>
          <ModalSubHeading>
            In order to protect your account please change your password.
          </ModalSubHeading>
          <ModalBody>
            <Field name="password" type="password" component={Input} label="Password:" />
            <Field
              name="passwordConfirmation"
              component={Input}
              label="Confirm Password:"
              type="password"
            />
          </ModalBody>
          <ModalActions>
            <Button secondary type="submit">
              Change Password
            </Button>
          </ModalActions>
        </FormWrapper>
      )}
    />
  );
};

const FormWrapper = styled.form`
  ${modalWithContentWrapperStyles}
`;
