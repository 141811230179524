import { values, isEmpty } from 'ramda';
import { differenceInDays } from 'date-fns';

export const getTermsheetsAmount = (negotiations) => {
  const offers = values(negotiations).reduce(
    (acc, { negotiations }) => [...acc, ...negotiations],
    []
  );
  return offers.reduce((acc, { termsheets }) => acc + termsheets.length, 0);
};

export const getOffersFromLoans = (loans) => {
  const negotitaions = loans.reduce(
    (acc, { negotiations }) => [...acc, ...values(negotiations)],
    []
  );
  const offers = negotitaions.reduce(
    (acc, { negotiations }) => [...acc, ...negotiations],
    []
  );

  return offers;
};

export const getStats = (offers) => {
  const stats = offers.reduce(
    (acc, { state }) => {
      switch (state) {
        case 'new_offer':
          return { ...acc, new: acc.new + 1 };
        case 'negotiation':
          return { ...acc, negotiation: acc.negotiation + 1 };
        case 'accepted':
          return { ...acc, accepted: acc.accepted + 1 };
        case 'rejected':
          return { ...acc, rejected: acc.rejected + 1 };

        default:
          return acc;
      }
    },
    {
      new: 0,
      negotiation: 0,
      accepted: 0,
      rejected: 0,
    }
  );

  return stats;
};

export const getDays = (date) => {
  const days = differenceInDays(new Date(), new Date(date));

  return days > 0 ? days : days * -1;
};

export const requiredActions = (actions) =>
  values(actions).reduce((acc, items) => acc || !!items.length, false);

export const calculateActionsCount = (actions) => {
  if (!actions) return null;

  let amount = 0;

  if (actions.documents && actions.documents.length) {
    amount += 1;
  }

  if (actions.negotiation && actions.negotiation.length) {
    amount += 1;
  }
  if (actions.newOffer && actions.newOffer.length) {
    amount += 1;
  }
  if (actions.questions && actions.questions.length) {
    amount += 1;
  }
  if (!isEmpty(actions.termsheets)) {
    amount += 1;
  }

  return amount;
};
