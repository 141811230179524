import types from 'constants/actionTypes';
import immutable from 'object-path-immutable';
import { ROOMS_TYPES, MESSAGE_TYPE, CHAT_ROOM } from 'constants/appRelated';
import { append, findIndex, update, map } from 'ramda';
import { camelizeKeys } from 'humps';
import { groupMessages, getMessageDate, mergeMessages } from './helpers';

const initialState = {
  direct: {
    isFetched: false,
    rooms: {},
    roomsArray: [],
    next: null,
    chats: {},
    hasMessages: false,
  },
  team: {
    isFetched: false,
    rooms: {},
    roomsArray: [],
    next: null,
    chats: {},
    hasMessages: false,
    teamRoomId: null,
    documents: [],
    offers: [],
  },
  loans: {
    isFetched: false,
    rooms: {},
    roomsArray: [],
    next: null,
    chats: {},
    hasMessages: false,
  },
  search: {
    isFetching: false,
    rooms: [],
    messages: [],
    contacts: [],
  },
  currentRoom: null,
  currentTab: null,
};

export default function chat(state, action = {}) {
  const currentState = state || initialState;

  const { type, payload } = action;
  switch (type) {
    case types.GET_CHAT_ROOMS_SUCCESS: {
      const { rooms, roomsArray, next, roomsType } = payload;

      const newState = immutable(state)
        .set(`${roomsType}.rooms`, { ...rooms })
        .set(`${roomsType}.roomsArray`, roomsArray)
        .set(`${roomsType}.next`, next)
        .set(`${roomsType}.isFetched`, true)
        .set(`${roomsType}.hasMessages`, false)
        .set('currentRoom', null)
        .set('currentTab', roomsType);

      // TODO: Need to reduce business logic here
      if (roomsType === CHAT_ROOM.TEAM) {
        newState
          .set(`${CHAT_ROOM.TEAM}.documents`, payload.teamDocuments)
          .set(`${CHAT_ROOM.TEAM}.offers`, payload.teamOffers);
      }

      return newState.value();
    }

    case types.GET_ADDITIONAL_CHAT_ROOMS_SUCCESS: {
      const { rooms, roomsArray, next, roomsType } = payload;
      return immutable(state)
        .set(`${roomsType}.rooms`, { ...state[roomsType].rooms, ...rooms })
        .set(`${roomsType}.roomsArray`, [...state[roomsType].roomsArray, ...roomsArray])
        .set(`${roomsType}.next`, next)
        .value();
    }

    case types.GET_CHAT_ROOM_SUCCESS:
    case types.SEARCH_MESSAGE_IN_CHAT_SUCCESS: {
      const {
        messages,
        roomId,
        next,
        previous,
        stage,
        borrower,
        lender,
        loan,
        participants,
        roomsType,
        documents,
        negotiations,
        queryMessageUuid,
      } = payload;

      return immutable(state)
        .set(`${roomsType}.chats.${roomId}.messages`, groupMessages(messages.messages))
        .set(`${roomsType}.chats.${roomId}.stage`, stage)
        .set(`${roomsType}.chats.${roomId}.next`, next)
        .set(`${roomsType}.chats.${roomId}.previous`, previous)
        .set(`${roomsType}.chats.${roomId}.borrower`, borrower)
        .set(`${roomsType}.chats.${roomId}.lender`, lender)
        .set(`${roomsType}.chats.${roomId}.loan`, loan)
        .set(`${roomsType}.chats.${roomId}.participants`, participants)
        .set(`${roomsType}.chats.${roomId}.documents`, documents)
        .set(`${roomsType}.chats.${roomId}.negotiations`, negotiations)
        .set(`${roomsType}.chats.${roomId}.queryMessageUuid`, queryMessageUuid)
        .set('currentRoom', roomId)
        .value();
    }

    case types.RESET_CHAT_SEARCH_MESSAGE: {
      return immutable.set(
        state,
        `${payload.roomsType}.chats.${payload.roomId}.queryMessageUuid`,
        null
      );
    }

    case types.GET_ADDITIONAL_CHAT_ROOM_SUCCESS: {
      const { messages, roomId, next, previous, roomsType, direction } = payload;
      return immutable(state)
        .set(
          `${roomsType}.chats.${roomId}.messages`,
          direction === 'previous'
            ? mergeMessages({
                currentGroup: groupMessages(messages.messages),
                prevGroup: state[roomsType].chats[roomId].messages,
              })
            : mergeMessages({
                currentGroup: state[roomsType].chats[roomId].messages,
                prevGroup: groupMessages(messages.messages),
              })
        )
        .set(`${roomsType}.chats.${roomId}.next`, next)
        .set(`${roomsType}.chats.${roomId}.previous`, previous)
        .value();
    }

    case types.SEND_WEBSOCKET_MESSAGE: {
      const { room, uuid, text, author, tabType } = payload.data;
      if (room && payload.type === 'chat.message.out') {
        return immutable.set(
          state,
          `${tabType}.chats.${room}.messages.${getMessageDate()}.${uuid}`,
          { text, type: action.payload.data.type, uuid, outgoing: true, author }
        );
      }
      return state;
    }

    case types.UPDATE_WEBSOCKET_MESSAGE: {
      const { data = [] } = payload;
      // Handle empty data array when some message has been updated
      if (!data[0]) {
        return state;
      }

      const { room, room_type } = data[0];

      if (state[ROOMS_TYPES[room_type]].chats[room]) {
        const newState = immutable(state);
        data.forEach((message) => {
          newState.update(
            `${ROOMS_TYPES[room_type]}.chats.${room}.messages.${getMessageDate(
              message.time
            )}.${message.uuid}`,
            (v) => ({
              ...v,
              ...camelizeKeys(message),
            })
          );

          if (message.type === MESSAGE_TYPE.FILE) {
            newState.update(
              `${ROOMS_TYPES[room_type]}.chats.${room}.documents.${message.uuid}`,
              (v) => ({
                ...v,
                ...message,
              })
            );
          }

          if (message.type === MESSAGE_TYPE.OFFER) {
            newState.update(
              `${ROOMS_TYPES[room_type]}.chats.${message.room}.negotiations.${message.offer.negotiation_id}`,
              (negotiation = {}) => {
                const offers = negotiation.offers || [];
                const offerIndex = findIndex(({ id }) => id === message.offer.id, offers);
                return {
                  ...negotiation,
                  state: message.offer.negotiation_state,
                  pk: message.offer.negotiation_id,
                  updatedTimestamp: message.offer.last_modified_timestamp,
                  offers:
                    offerIndex !== -1
                      ? update(offerIndex, camelizeKeys(message.offer), offers)
                      : append(camelizeKeys(message.offer), offers),
                };
              }
            );
          }
        });

        return newState.value();
      }

      return state;
    }

    case types.HANDLE_WEBSOCKET_MESSAGE: {
      const { data } = payload;
      const newState = immutable(state);
      const { room_type } = data;

      if (state[ROOMS_TYPES[room_type]].chats[data.room]) {
        newState.set(
          `${ROOMS_TYPES[room_type]}.chats.${data.room}.messages.${getMessageDate(
            data.time
          )}.${data.uuid}`,
          camelizeKeys(data)
        );

        if (data.type === MESSAGE_TYPE.FILE) {
          newState.set(
            `${ROOMS_TYPES[room_type]}.chats.${data.room}.documents.${data.uuid}`,
            data
          );
        }

        if (data.type === MESSAGE_TYPE.OFFER) {
          if (data.offer.negotiation_state === 'new_offer') {
            newState.update(
              `${ROOMS_TYPES[room_type]}.chats.${data.room}.negotiations`,
              (negotiations) => ({
                ...negotiations,
                [data.offer.negotiation_id]: {
                  pk: data.offer.negotiation_id,
                  offers: [camelizeKeys(data.offer)],
                  state: 'new_offer',
                },
              })
            );
          } else {
            newState.update(
              `${ROOMS_TYPES[room_type]}.chats.${data.room}.negotiations.${data.offer.negotiation_id}`,
              (negotiation) => ({
                ...negotiation,
                offers: append(camelizeKeys(data.offer), negotiation.offers),
                state: 'negotiation',
              })
            );
          }
        }
      }

      if (state.currentTab !== ROOMS_TYPES[room_type]) {
        newState
          .set(`${ROOMS_TYPES[room_type]}.hasMessages`, true)
          .set(`${ROOMS_TYPES[room_type]}.isFetched`, false);
      }

      if (Number(state.currentRoom) !== data.room) {
        const isLoanRoomPresent =
          ROOMS_TYPES[room_type] === 'loans' &&
          state?.[ROOMS_TYPES[room_type]]?.rooms?.[data.loan];
        const isRoomPresent = state?.[ROOMS_TYPES[room_type]]?.rooms?.[data.room];

        if (isLoanRoomPresent) {
          newState.update([ROOMS_TYPES[room_type], 'rooms', data.loan], (room) => ({
            ...room,
            unreadMessages: room.unreadMessages + 1,
            rooms: room.rooms.map((roomItem) =>
              roomItem.id === data.room
                ? { ...roomItem, unreadMessages: roomItem.unreadMessages + 1 }
                : roomItem
            ),
          }));
        } else if (isRoomPresent) {
          newState.update(
            [ROOMS_TYPES[room_type], 'rooms', data.room, 'unreadMessages'],
            (unreadMessages) => unreadMessages + 1
          );
        }
      }

      return newState.value();
    }

    case types.SET_CURRENT_CHAT_ROOM: {
      return immutable.set(state, 'currentRoom', payload.room);
    }

    case types.SET_CURRENT_CHAT_TAB: {
      return immutable.set(state, 'currentTab', payload.tab);
    }

    case types.RESET_CHAT_ROOM_COUNTER: {
      const { room_type } = payload;

      return immutable.update(state, [ROOMS_TYPES[room_type], 'rooms'], (rooms) =>
        ROOMS_TYPES[room_type] === 'loans'
          ? map((loan) => {
              if (loan.id === payload.loan) {
                const recievedRoom = loan.rooms.filter(
                  (room) => room.id === payload.room
                )[0];
                return {
                  ...loan,
                  unreadMessages: recievedRoom
                    ? loan.unreadMessages - recievedRoom.unreadMessages
                    : loan.unreadMessages,
                  rooms: loan.rooms.map((room) =>
                    room.id === payload.room ? { ...room, unreadMessages: 0 } : room
                  ),
                };
              }

              return loan;
            }, rooms)
          : map((room) => {
              if (room.id === payload.room) {
                return {
                  ...room,
                  unreadMessages: 0,
                };
              }

              return room;
            }, rooms)
      );
    }

    case types.CREATE_CHAT_TEAM_GROUP_SUCCESS: {
      return immutable(state)
        .update(`${CHAT_ROOM.TEAM}.rooms`, (rooms) => ({
          ...rooms,
          [payload.room.id]: payload.room,
        }))
        .update(`${CHAT_ROOM.TEAM}.roomsArray`, (rooms) =>
          rooms.indexOf(payload.room.id) === -1 ? [...rooms, payload.room.id] : rooms
        )
        .value();
    }

    case types.RESET_CHAT_DATA:
    case types.LOGOUT_SUCCESS: {
      return initialState;
    }

    case types.SEARCH_IN_CHAT_REQUEST: {
      return immutable.set(state, 'search.isFetching', true);
    }

    case types.SEARCH_IN_CHAT_SUCCESS: {
      const { messages, contacts } = payload;

      return immutable(state)
        .set('search.messages', messages)
        .set('search.contacts', contacts)
        .set('search.isFetching', false)
        .value();
    }

    case types.RESET_SEARCH_IN_CHAT: {
      return immutable(state)
        .set('search.messages', [])
        .set('search.contacts', [])
        .set('search.isFetching', false)
        .value();
    }

    default:
      return currentState;
  }
}
