import styled from 'styled-components/macro';
import { Text } from 'ui/Text';

export const AttachesTitle = styled(Text)`
  font-size: 18px;
  margin-bottom: 20px;
`;

export const AttachesComments = styled.div`
  padding-bottom: 20px;
  border-bottom: 1px solid #eee;
`;
