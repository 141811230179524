import React from 'react';
import { Field } from 'react-final-form';
import { Input, DropdownList, InputPassword, InputWithErrorComp } from 'components/Form';
import { normalizeMobile, normalizeInteger } from 'lib/normalizers';
import { WorkPhone, WorkPhoneWrapper, WorkPhoneExt } from 'styles/auth';

import { SourceOther } from './SourceOther';
import { MobilePhoneFieldHint } from './MobilePhoneFieldHint';

export const LenderFields = ({ zones, source, validateEmail, showMobilePhoneHint }) => (
  <React.Fragment>
    <Field
      component={Input}
      placeholder="a-z"
      label="Business name"
      name="business_name"
      fullWidth
      required
    />
    <Field
      component={Input}
      placeholder="https://"
      label="Company url"
      name="business_url"
      fullWidth
      required
    />
    <Field
      component={Input}
      placeholder="a-z"
      label="First name"
      name="first_name"
      fullWidth
      required
    />
    <Field
      component={Input}
      placeholder="a-z"
      label="Last name"
      name="last_name"
      fullWidth
      required
    />
    <Field
      //component={Input}
      component={InputWithErrorComp}
      placeholder="your@mail.com"
      label="E-mail"
      name="email"
      type="email"
      validate={validateEmail}
      fullWidth
      required
      parse={(value) => (value ? value.trim() : value)}
    />
    <Field
      component={Input}
      placeholder="+1"
      label="Mobile phone"
      name="mobilePhone"
      parse={normalizeMobile}
      fullWidth
      required
    />
    <MobilePhoneFieldHint visible={showMobilePhoneHint} />
    <WorkPhoneWrapper>
      <WorkPhone>
        <Field
          component={Input}
          placeholder="+1"
          label="Work phone"
          name="phone"
          parse={normalizeMobile}
          fullWidth
          required
        />
      </WorkPhone>
      <WorkPhoneExt>
        <Field
          component={Input}
          name="phone_ext"
          placeholder="ext."
          fullWidth
          parse={normalizeInteger}
          maxLength="4"
        />
      </WorkPhoneExt>
    </WorkPhoneWrapper>

    <Field
      component={InputPassword}
      placeholder="Password"
      label="Password"
      name="password"
      fullWidth
      required
    />
    <Field
      component={DropdownList}
      placeholder="US/Central"
      label="Preferred timezone"
      name="preferred_timezone"
      simpleValue
      options={zones}
      mb={3}
      dropup
    />
    <Field
      component={DropdownList}
      label="How did you know about us?"
      name="source"
      options={source}
      simpleValue
      mb={3}
      dropup
    />
    <SourceOther source={source} />
  </React.Fragment>
);
