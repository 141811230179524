import types from 'constants/actionTypes';
import axios from 'axios';
import * as API from 'api';
import { put, takeLatest, call, select } from 'redux-saga/effects';
import { setCurrentUri } from 'actions';
import { history } from 'store';
import { getError } from 'sagas/utils';
import config from 'constants/config';

import { 
    getTasksDataSuccess, 
    getTasksDataFailure,
    addNewTaskSuccess,
    addNewTaskFailure,
    editTaskSuccess, 
    editTaskFailure,
    removeTaskSuccess,
    removeTaskFailure,
    getTasksMatchingLoansSuccess,
    getTasksMatchingLoansFailure,
    completeTaskSuccess,
    completeTaskFailure,
    seenTaskFailure,
    seenTaskSuccess,
    getTaskIdSuccess,
    getTaskIdFailure,
    setChosenCalendarsSuccess,
    setChosenCalendarsFailure,
    getCalendarsFailure,
    getCalendarsSuccess,
    getAuthCalendarsFailure,
    getAuthCalendarsSuccess,
    hideCalendarBannerSuccess,
    hideCalendarBannerFailure,
} from './actions';

import { getProfileSuccess } from 'actions';

function* getTasksMatchingLoans() {
    try {
        const {
            data: { response },
        } = yield call(
            API.axiosApi.get,
            `tasks/loans-select?no_page=true&ordering=property_name`
        );

        yield put(getTasksMatchingLoansSuccess(response));
    } catch (e) {
        console.error(e);
        yield put(getTasksMatchingLoansFailure({ message: getError(e) }));
    }
}

function* getTasksData(action) {
    try {
        const { currentOrdering, loanId, getTasksByMe } = action.payload;

        const newUri = [currentOrdering && `ordering=${currentOrdering}`]
        .filter(Boolean)
        .join('&');

        history.push(`?${newUri}`);
        yield put(setCurrentUri(newUri));
        
        const params = `${newUri ? `&${newUri}` : ''}${loanId ? `&loan=${loanId}` : ''}${getTasksByMe ? `&created_by_me` : ''}`;
        
        const {
            data: { response },
        } = yield call(
            API.axiosApi.get,
            `tasks?no_page=true${params}`
        );

        yield put(getTasksDataSuccess({tasks: response}));
    } catch (e) {
      console.error(e);
        yield put(getTasksDataFailure({ message: getError(e) }));
    }
}

function* addNewTask(action) {
    try {
        const { payload } = action;
        const {
            data: { response },
        } = yield call(API.axiosApi.post, `/tasks`, {...payload} );

        yield put(addNewTaskSuccess({newTask: response}));
    } catch (e) {
      console.error(e);
        yield put(addNewTaskFailure({ message: getError(e) }));
    }
}

function* editTask(action) {
    try {
        const id = action.payload.taskId;

        const {
            data: { response },
        } = yield call(API.axiosApi.put, `tasks/${id}`, action.payload );
        
        yield put(editTaskSuccess({editedTask: response})); // taskId: id
    } catch (e) {
      console.error(e);
        yield put(editTaskFailure({ message: getError(e) }));
    }
}

function* removeTask(action) {
    try {
        const id = action.payload;

        yield call(API.axiosApi.delete, `tasks/${id}`);
        
        yield put(removeTaskSuccess({removeTask: id}));
    } catch (e) {
      console.error(e);
        yield put(removeTaskFailure({ message: getError(e) }));
    }
}

function* setCompletedTask(action) {
    try {
        yield call(API.axiosApi.post, `tasks/${action.payload}/complete`);

        yield put(completeTaskSuccess({taskId: action.payload}));
    } catch (e) {
      console.error(e);
        yield put(completeTaskFailure({ message: getError(e) }));
    }
}

function* setSeenTask(action) {
    try {
        yield call(API.axiosApi.post, `tasks/${action.payload}/seen`);

        yield put(seenTaskSuccess({taskId: action.payload}));
    } catch (e) {
      console.error(e);
        yield put(seenTaskFailure({ message: getError(e) }));
    }
}

function* getTaskIdData(action) {
    try {
        const {
            data: { response },
        } = yield call(API.axiosApi.get, `tasks/${action.payload.id}`);

        yield put(getTaskIdSuccess({currentTask: response}));
    } catch (e) {
      console.error(e);
        yield put(getTaskIdFailure({ message: getError(e) }));
    }
}

function* getAuthCalendars(action) {
    try {
        const { logout, type, calendarEnabled } = action.payload;
        const state = yield select();
    
        if(logout){
            yield call(API.axiosApi.delete, `auth/${type}-calendar`);
            yield call(API.axiosApi.patch, 'auth/customer-profile', {[`${type}_calendar_enabled`]: calendarEnabled});
            yield put(getProfileSuccess({...state.currentUser, [`${type}CalendarEnabled`]: calendarEnabled}));
        }else{
            const googleForm = `${config.API_HOST}/auth/connect-${type}-calendar`;
            window.open(googleForm, '_blank').focus();
        }
        yield put(getAuthCalendarsSuccess());
    } catch (e) {
      console.error(e);
        yield put(getAuthCalendarsFailure({ message: getError(e) }));
    }
}

function* setChosenCalendars(action) {
    try {
        const { type, calendars: {calendarsUpdated} } = action.payload;
        const {
            data,
        } = yield call(API.axiosApi.post, `/auth/${type}-calendar`, { calendar_ids: calendarsUpdated });

        yield put(setChosenCalendarsSuccess(data));

    } catch (e) {
      console.error(e);
        yield put(setChosenCalendarsFailure({ message: getError(e) }));
    }
}

function* getCalendars(action) {
    try {
        const { type } = action.payload;
        const { currentUser: { googleInfo } } = yield select();
        const {
            data: { calendars },
        } = yield call(API.axiosApi.get, `auth/${type}-calendar`);

        let chosenCalendares = {};
        calendars.forEach((calendarItem) => {
            if(calendarItem.syncEnabled) {
                if(calendarItem.summary === googleInfo.email){
                    chosenCalendares = {...chosenCalendares, myCalendar: true}
                }else{
                    chosenCalendares = {...chosenCalendares, [calendarItem.summary]: true}
                }
            }
        });
    
        yield put(getCalendarsSuccess({calendars, chosenCalendares}));
    } catch (e) {
      console.error(e);
        yield put(getCalendarsFailure({ message: getError(e) }));
    }
}
function* hideCalendarBanner() {
    try {
        yield call(API.axiosApi.delete, `auth/hide-calendar-banner`); // post
        yield put(hideCalendarBannerSuccess());
    } catch (e) {
      console.error(e);
        yield put(hideCalendarBannerFailure());
    }
}

export default [
    takeLatest(types.GET_TASKS_MATCHING_LOANS_REQUEST, getTasksMatchingLoans),
    takeLatest(types.GET_TASKS_DATA_REQUEST, getTasksData),
    takeLatest(types.GET_TASK_ID_REQUEST, getTaskIdData),
    takeLatest(types.ADD_NEW_TASK_REQUEST, addNewTask),
    takeLatest(types.EDIT_TASK_REQUEST, editTask),
    takeLatest(types.REMOVE_TASK_REQUEST, removeTask),
    takeLatest(types.COMPLETE_TASK_REQUEST, setCompletedTask),
    takeLatest(types.SEEN_TASK_REQUEST, setSeenTask),
    takeLatest(types.GET_AUTH_CALENDARS_REQUEST, getAuthCalendars),
    takeLatest(types.GET_CALENDARS_REQUEST, getCalendars),
    takeLatest(types.SET_CHOSEN_CALENDARS_REQUEST, setChosenCalendars),
    takeLatest(types.HIDE_CALENDAR_BANNER_REQUEST, hideCalendarBanner),
];
