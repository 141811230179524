import React from 'react';
import { LOAN_STATUS } from 'constants/appRelated';
import { Button } from 'ui/Button';
import { Link } from 'react-router-dom';

const DraftPanel = ({ canSubmitForVerification, submitForVerification, status, id }) => (
  <div>
    {(status === LOAN_STATUS.VERIFICATION_FAILED || status === LOAN_STATUS.DRAFT) && (
      <div>
        <Link to={`/dashboard/loan-creation/${id}`}>
          <Button secondary>Edit loan</Button>
        </Link>

        {status === LOAN_STATUS.VERIFICATION_FAILED && (
          <Button onClick={submitForVerification}>Re-submit</Button>
        )}

        {status === LOAN_STATUS.DRAFT && (
          <Button
            disabled={!canSubmitForVerification}
            onClick={submitForVerification}
            title="Don’t worry, no one will see it until you select lenders to send to or share the URL. You can make changes to a published loan request at any time"
          >
            Make Active and Share
          </Button>
        )}
      </div>
    )}
  </div>
);

export { DraftPanel };
