import types from 'constants/actionTypes';

const initialState = {
  userData: {},
};

export function teamMemberRegistration(state, action = {}) {
  const currentState = state || initialState;

  const { type, payload } = action;

  switch (type) {
    case types.CHECK_TEAM_MEMBER_TOKEN_SUCCESS:
      return { ...state, userData: payload };

    case type.RESET_TEAM_MEMBER_DATA:
      return initialState;

    default:
      return currentState;
  }
}
