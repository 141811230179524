import React from 'react';
import { Field } from 'react-final-form';
import { NewCheckbox } from 'components/Form';
import { Text } from 'ui/Text';
import styled from 'styled-components/macro';

export const PrivacyCheckbox = () => (
  <Field
    component={NewCheckbox}
    type="checkbox"
    name="privacy"
    mb={3}
    label={
      <Text
        fontSize="12px"
        fontFamily="muli"
        ml="20px"
        textAlign={['left', 'left', 'unset']}
      >
        I accept the RealAtom{' '}
        <ExternalLink
          href="https://realatom.com/privacy-policy/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy Policy
        </ExternalLink>
        ,{' '}
        <ExternalLink
          href="https://realatom.com/terms-of-use/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Terms & Conditions
        </ExternalLink>
      </Text>
    }
  />
);

const ExternalLink = styled.a`
  color: #fd8a42;
  text-decoration: none;
`;
