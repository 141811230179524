import React from 'react';
import { offerFields } from 'components/Modals/Offer/config';
import { Toggle } from 'components/Toggle';
import { Form } from 'react-final-form';
import { filter } from 'ramda';
import { OfferRequest, OfferHeading, OfferHeadingTitle } from 'styles/offer';
import { getQuoteInitialValues } from 'components/Modals/Quote/helpers';
import { normalizeOfferData } from 'components/Modals/Offer/helpers';

import { FieldsSet, Controls } from './';

const formValidator = (values) => {
  const errors = {};
  const message = ' ';

  if (!values.loanAmount) {
    errors.loanAmount = message;
  }

  if (!values.index && !values.spread && !values.interest) {
    errors.index = message;
    errors.spread = message;
    errors.interest = message;
  }

  if (!values.index && values.spread && !values.interest) {
    errors.index = message;
  }

  if (values.index && !values.spread && !values.interest) {
    errors.spread = message;
  }

  return errors;
};

export class QuoteItem extends React.Component {
  state = { edit: this.props.isSoftQuote };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.isSoftQuote !== this.props.isSoftQuote) {
      this.setState({ edit: this.props.isSoftQuote });
    }
  }

  editQuote = () => {
    this.setState({ edit: true });
  };

  submitQuote = (values) => {
    const { submitQuote, editQuote, isSoftQuote, quote } = this.props;

    const request = filter((field) => field !== null && field !== undefined, {
      ...normalizeOfferData(
        offerFields.reduce((acc, arr) => [...acc, ...arr], []),
        values
      ),
    });

    if (isSoftQuote) {
      submitQuote(request);
    } else {
      editQuote({ request, quoteId: quote.id });
    }
    this.setState({ edit: false });
  };

  render() {
    const { quote, listing, userType, isSoftQuote, hideModal } = this.props;
    const initialValues = getQuoteInitialValues({ offerFields, isSoftQuote, quote });
    const { edit } = this.state;

    return (
      <Form
        onSubmit={this.submitQuote}
        validate={(values) => formValidator(values)}
        initialValues={initialValues}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <OfferRequest>
              <OfferHeading>
                {/* <OfferHeadingTitle>Listing</OfferHeadingTitle> */}
                <OfferHeadingTitle>Quote</OfferHeadingTitle>
              </OfferHeading>
              <FieldsSet
                fields={offerFields[0]}
                listing={listing}
                quote={quote}
                userType={userType}
                edit={edit}
                newQuote={isSoftQuote}
              />
              <Toggle>
                <FieldsSet
                  fields={offerFields[1]}
                  listing={listing}
                  quote={quote}
                  userType={userType}
                  edit={edit}
                  newQuote={isSoftQuote}
                />
              </Toggle>
            </OfferRequest>
            <Controls
              userType={userType}
              hideModal={hideModal}
              edit={edit}
              editQuote={this.editQuote}
            />
          </form>
        )}
      />
    );
  }
}
