import React, { PureComponent } from 'react';
import { Section, SectionTitle, EditLink, WysiwygText } from 'styles/loan';
import { bluredContent } from 'features/LoanNew/constants';
import PropTypes from 'prop-types';
import { BlurContent } from 'components/BlurContent';
import { FieldEdited } from './';

export class MarketInfo extends PureComponent {
  static propTypes = {
    marketInformation: PropTypes.string,
    id: PropTypes.number,
    editable: PropTypes.bool,
    access: PropTypes.object,
    fieldsHistory: PropTypes.object,
    componentRef: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({ current: PropTypes.instanceOf(HTMLDivElement) }),
    ]),
  };

  render() {
    const {
      marketInformation,
      editable,
      id,
      componentRef,
      access,
      fieldsHistory = {},
    } = this.props;

    const isBlured = access.preview || access.limited;

    return (
      <Section ref={componentRef}>
        {editable && <EditLink to={`/dashboard/loan-creation/${id}/market`} />}
        <SectionTitle>Market Info</SectionTitle>
        {fieldsHistory.marketInfo && <FieldEdited fieldName="marketInfo" />}
        <BlurContent blured={isBlured} height={80}>
          {isBlured ? (
            bluredContent.marketInfo
          ) : (
            <WysiwygText dangerouslySetInnerHTML={{ __html: marketInformation }} />
          )}
        </BlurContent>
      </Section>
    );
  }
}
