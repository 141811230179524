const OTHER_LOAN_TYPE = 50;
const OTHER_PROPERTY_TYPE = 14;
const OTHER_PURPOSE_TYPE = 7;
const AMORTIZATION_CUSTOM = 0;
const DEFAULT_LOCATION = { lat: 38.39372, lng: -102.526979 };

// Documents for signature
const DOC_SIGNATURE_NEW = 'new';
const DOC_SIGNATURE_REJECTED = 'rejected';
const DOC_SIGNATURE_ACCEPTED = 'accepted';
const DOC_SIGNATURE_REJECTED_REASON = 'rejectedReason'

// Roles
const LENDER = 3;
const BROKER = 2;
const BORROWER = 1;
const SALES_BROKER = 5;
const AM = 0;
const STAFF = 4;

const ROLES_LABELS = {
  [LENDER]: 'Lender',
  [BROKER]: 'Broker',
  [BORROWER]: 'Borrower',
  [SALES_BROKER]: 'Sales Broker',
  [AM]: 'AM',
  [STAFF]: 'Staff',
};

// approve statuses

const APPROVE_STATUSES = {
  DENIED: -1,
  PENDING: 0,
  APPROVED: 1,
  IN_VERIFICATION: 2,
  PRE_REGISTERED: 3,
  CLOSED: 4,
};

const MESSAGE_TYPE = {
  TEXT: 3,
  OFFER: 4,
  OFFER_STATUS: 5,
  FILE: 6,
};

const CHAT_ROOM_STAGE = {
  OFFER: 3,
  TERM_SHEET: 6,
  DEAL_ROOM: 9,
};

const PERCENT = 'percent';
const CURRENCY = 'currency';

// analytics related
const IDENTIFY = 'identify';
const RESET = 'reset';
const TRACK = 'track';
const CLICKED_CREATE_CONCIERGE = 'CLICKED CREATE CONCIREGE';
const SUBMITTED_CONCIERGE = 'SUBMITTED CONCIERGE';
const CLICKED_NEW_LOAN_REQUEST = 'CLICKIED NEW LOAN REQUEST';
const SAVED_LOAN_DRAFT = 'SAVED LOAN DRAFT';
const SUBMITTED_FOR_VERIFICATION = 'SUBMITTED FOR VERIFICATION';
const CLICKED_CONFIRM_EMAIL = 'CLICKED_CONFIRM_EMAIL';
const CLICKED_SING_OUT = 'CLICKED_SING_OUT';
const SCROLLED_MARKETPLACE_PAGE = 'SCROLLED_MARKETPLACE_PAGE';
const SELECTED_FILTERS_MARKETPLACE = 'SELECTED_FILTERS_MARKETPLACE';
const CLICKED_ON_LOAN_MARKETPLACE = 'CLICKED_ON_LOAN_MARKETPLACE';
const CUSTOMIZE_OFFER_TABLE = 'CUSTOMIZE OFFER TABLE';
const SWITCH_TO_EVENTS = {
  VIEWED_TO_PERSONAL: 'VIEWED_TO_PERSONAL',
  VIEWED_TO_TEAM: 'VIEWED_TO_TEAM',
  VIEWED_TO_USER: 'VIEWED_TO_USER',
}
const TERMSHEET_EVENTS = {
  LOAN_TERM_SHEET_ADD_MANUAL: 'loan__term_sheet__add_manual',
  LOAN_TERM_SHEET_ADD_MANUAL_CREATE: 'loan__term_sheet__add_manual__create',
  LOAN_TERM_SHEET_EDIT: 'loan__term_sheet__edit',
  LOAN_TERM_SHEET_EDIT_SAVE: 'loan__term_sheet__edit__save',
};

const ANALYTICS_EVENTS = {
  RATE_CHART_CLICK: ' analytics_rate_chart_click',
  AMOUNT_CHART_CLICK: ' analytics_amount_chart_click',
  LTV_CHART_CLICK: ' analytics_ltv_chart_click',
  DSCR_CHART_CLICK: ' analytics_dscr_chart_click',
  FILTERS_CLICK: ' analytics_filters_click',
  FILTERS_APPLY: ' analytics_filters_apply',
  EXPORT_XLS_CLICK: ' analytics_export_xls_click',
  DATE_RANGE_CHANGE: ' analytics_date_range_change',
  GROUPING_CHANGE: ' analytics_groupping_change',
  SEE_ALL_CLICK: ' analytics_see_all_click',
  SEE_ALL_CHANGE_ORDERING: 'analytics_see_all_change_ordering',
};

const ANALYTICS_LOANS_HEADERS = {
  bb: ['AMOUNT', 'RATE', 'LTV', 'LTC', 'OCCUPANCY'],
  lender: ['AMOUNT', 'RATE', 'LTV', 'LTC', 'DSCR', 'STATE', 'QUOTED RATE'],
}
const SIGN_UP_EVENTS = {
  SIGN_UP_VIEW: 'sign_up__view',
  SIGN_UP_SUBMIT: 'sign_up__submit',
  FINISH_REGISTRATION_VIEW: 'finish_registration__view',
  FINISH_REGISTRATION_SUBMIT: 'finish_registration__submit',
  SET_PASSWORD_VIEW: 'set_password__view',
  SET_PASSWORD_SUBMIT: 'set_password__submit',
};

const STANDALONE_LOAN_EVENTS = {
  STANDALONE_LOAN_VIEW: 'standalone_loan__view',
  STANDALONE_LOAN_INFO_POPUP_CLOSE: 'standalone_loan__info_popup__close',
  STANDALONE_LOAN_CTA_CLICK: 'standalone_loan__cta__click',
};

// Statuses for term sheets
const TERMSHEET_STATUSES = {
  UPLOADED: 1,
  AM_REJECTED_TERMSHEET: 2,
  AM_ACCEPTED_TERMSHEET: 3,
  BORROWER_REJECTED_TERMSHEET: 4,
  BORROWER_SIGNED_TERMSHEET: 5,
  AM_REJECTED_SIGNED_TERMSHEET: 6,
  AM_ACCEPTED_SIGNED_TERMSHEET: 7,
  LENDER_CANCELLED_TERMSHEET: 8,
};

const LOAN_REMINDER_SET = 'loan__reminder__set';
const LOAN_EXIT_POPUP_CLOSE = 'loan__exit_popup__close';

/* eslint-disable  */
// const URL_REGEXP = /^(https?:\/\/)?([\w\.]+)\.([a-z]{2,6}\.?)(\/[\w\.]*)*\/?$/
const URL_REGEXP = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?\s*$/i;
/* eslint-enable  */

const INTERCOM_APP_ID = 'ngc3b4e8';

const LOAN_ACCESS_LEVEL = {
  PREVIEW: 1, // For borrowers
  LIMITED: 2, // For Lenders without subscription
  FULL: 3, // For Lenders with subscription
};

const LOAN_STATUS = {
  DRAFT: 0,
  SUBMITTED_FOR_VERIFICATION: 1,
  POSTED_TO_MARKETPLACE: 2,
  PENDING_CLOSING: 3,
  CLOSED_LOST: 4,
  REJECTED: 5,
  CLOSED_WON: 6,
  DRAFT_WITHDRAWN: 7,
  VERIFICATION_FAILED: 8,
  CLOSED_WITHDRAWN: 9,
  // CLOSED_WON_LENDER: 10,
  // CLOSED_LOST_LENDER: 11,
};

const LOAN_STATUS_TITLE = {
  DRAFTS: 'Drafts',
  CLOSED: 'Closed',
  UNDERWRITING: 'Underwriting',
  ACTIVE: 'Active',
};

const LOAN_STATUS_LABELS = {
  [LOAN_STATUS.DRAFT]: 'Draft',
  [LOAN_STATUS.SUBMITTED_FOR_VERIFICATION]: 'Submit for verification',
  [LOAN_STATUS.POSTED_TO_MARKETPLACE]: 'Posted to marketplace',
  [LOAN_STATUS.PENDING_CLOSING]: 'Pending closing',
  [LOAN_STATUS.CLOSED_LOST]: 'Closed lost',
  [LOAN_STATUS.REJECTED]: 'Rejected',
  [LOAN_STATUS.CLOSED_WON]: 'Closed won',
  [LOAN_STATUS.DRAFT_WITHDRAWN]: 'Draft withdrawn',
  [LOAN_STATUS.VERIFICATION_FAILED]: 'Verification failed',
  [LOAN_STATUS.CLOSED_WITHDRAWN]: 'Closed withdrawn',
  // [LOAN_STATUS.CLOSED_WON_LENDER]: 'Closed won lender',
  // [LOAN_STATUS.CLOSED_LOST_LENDER]: 'Closed lost lender',
};

const LOAN_ACTIVITIES_STATUS_TYPE = {
  NEW: 1,
  QUOTE: 2,
  TERM_SHEET: 3,
  DOCUMENT_REQUEST: 4,
  HIDDEN: 5,
  PASSED: 6,
  DEAL_ROOM: 7,
}

const LOAN_STATUS_TYPE = {
  ACTIVE: 1,
  UNDERWRITING: 2,
  DRAFTS: 3,
  CLOSED: 4,
  UNKNOWN_STATUS: 5,
};

const SUBSCRIPTION_STATUS = {
  FREEMIUM: 1, // can unlock only n loans
  GRANDFATHER: 2, // full access (for old users)
  PAID: 3, // full access (paid)
};

const CHAT_ROOM_TYPE = {
  PERSONAL: 1,
  LOAN: 2,
  TEAM: 3,
};

const ROOMS_TYPES = {
  [CHAT_ROOM_TYPE.PERSONAL]: 'direct',
  [CHAT_ROOM_TYPE.LOAN]: 'loans',
  [CHAT_ROOM_TYPE.TEAM]: 'team',
};

const CHAT_ROOM = {
  TEAM: ROOMS_TYPES[CHAT_ROOM_TYPE.TEAM],
  PERSONAL: ROOMS_TYPES[CHAT_ROOM_TYPE.PERSONAL],
  LOAN: ROOMS_TYPES[CHAT_ROOM_TYPE.LOAN],
};

const BORROWER_TEAM_MEMBER_TYPES = {
  ADMIN: 1,
  CAN_NEGOTIATE: 2,
  USER: 3,
};

const BORROWER_TEAM_MEMBER_LABELS = {
  [BORROWER_TEAM_MEMBER_TYPES.ADMIN]: 'admin',
  [BORROWER_TEAM_MEMBER_TYPES.CAN_NEGOTIATE]: 'can negotiate',
  [BORROWER_TEAM_MEMBER_TYPES.USER]: 'user',
};

const LENDER_TEAM_MEMBER_TYPES = {
  ADMIN: 1,
  CAN_NEGOTIATE: 2,
  USER: 3,
  COMPANY_ADMIN: 4,
};

const LENDER_TEAM_MEMBER_LABELS = {
  [LENDER_TEAM_MEMBER_TYPES.ADMIN]: 'admin',
  [LENDER_TEAM_MEMBER_TYPES.CAN_NEGOTIATE]: 'can negotiate',
  [LENDER_TEAM_MEMBER_TYPES.USER]: 'user',
  [LENDER_TEAM_MEMBER_TYPES.COMPANY_ADMIN]: 'company admin',
};

const getMemberPermissionLabel = (permission) => {
  switch (permission) {
    case LENDER_TEAM_MEMBER_TYPES.USER:
      return 'User';
    case LENDER_TEAM_MEMBER_TYPES.ADMIN:
      return 'Team admin';
    case LENDER_TEAM_MEMBER_TYPES.COMPANY_ADMIN:
      return 'Company admin';

    default:
      return 'User';
  }
};

const LOAN_SECTIONS = {
  OVERVIEW: 'overview',
  TERMS: 'terms',
  PROFILE: 'profile',
  FILES: 'files',
  SPONSOR: 'sponsor',
  SU: 'su',
  MARKET: 'market',
  SUMMARY: 'summary',
  DOCUMENTS: 'documents',
};

const OFFER_STATE = {
  NEGOTIATION: 'negotiation',
  REJECTED: 'rejected',
  ACCEPTED: 'accepted',
  NEW_OFFER: 'new_offer',
  CANCELLED: 'cancelled',
  MANUAL: 'manual',
  WINNING_BID: 'winning_bid',
  MANUAL_QUOTE: 'manual_quote',
};

const FIELD_TYPES = {
  CHOICE: 'choice',
  CHOICE_MULTI: 'choice_multi',
  PERCENT: 'percent',
  DURATION: 'duration',
  DURATION_UNIT: 'durationUnit',
  DOLLARS: 'dollars',
  NUMBER: 'number',
  LIMIT_NUMBER: 'number_limit',
  FLOAT: 'float',
  TOGGLE: 'toggle',
  DATE: 'date',
  TEXT: 'text',
  RANGE: 'range',
  BOOLEAN: 'boolean',
  CHOICE_BOOLEAN: 'choice_boolean',
};

const FLASH_MESSAGES = {
  SUCCESS: 'success',
};

const CHAT_MESSAGE_STATE = {
  SENT: 3,
  DELIVERED: 6,
  READ: 9,
};

const DEAL_ROOM_STATUS = {
  CREATED: 1,
  CHECKLIST_UPLOADED: 2,
  DEAL_IN_PROCESS: 3,
};

const LISTING_STATUS = {
  DRAFT: 1,
  PUBLISHED: 2,
  CLOSED: 3,
};

const FILTERS_TYPE = {
  LOCATION: 'location',
  PROPERTY_TYPE: 'propertyType',
  LOAN_TYPE: 'loanType',
  PURPOSE: 'planUseProperty',
  LOAN_AMOUNT: 'loanAmount',
  LOAN_TERM: 'loanTerm',
  TAGS: 'tags',
  LTC: 'ltcRange',
  LTV: 'ltvRange',
  DSCR: 'dscrRange',
  INTEREST: 'interestRange',
  PORTFOLIO_SIZE: 'portfolioSize',
  NON_RECOURSE: 'nonRecourse',
  TEAM: 'owner',
  CONTACT_TYPE: 'contactType',
  OWNER_STRUCTURE: 'ownerStructure',
  CAPITAL_SOURCE_STRATEGY: 'capitalSourceStrategy',
  INVESTOR_TYPE: 'investorType',
  CHECK_SIZE: 'checkSize',
  HOLD_PERIOD: 'holdPeriod',
  CREATED_DATE: 'createdDate',
  CLOSE_DATE: 'closedDate',
  PUBLISH_DATE: 'publishDate',
  TYPE_OF_CAPITAL_SOURCE: 'typeOfCapitalSource',
  COMPANIES: 'companies',
  OWNERS: 'owners',
  JOINED_RA: 'isJoined',
  LOCATION_ALL_USA: 'assetLocationAllUsa',
  LENDER_TYPES: 'lenderTypes',
  ONLY_WON: 'onlyWon',
  EXCLUDE_LOST: 'excludeLost',
  ONLY_OWNER_OCCUPIED: 'onlyOwnerOccupied',
  MARKETING_OUTREACH: 'marketingOutreach',
};

const LENDER_LOAN_STAGE = {
  NEW: 0,
  VIEWED: 3,
  ACTIVE: 6,
  CLOSED: 9,
};

const UUID_REGEXP = /\b[0-9a-f]{8}\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\b[0-9a-f]{12}\b/;

const PAGES_REGEXP_PATH = {
  TASK_MODAL_REVIEW: new RegExp(/\/dashboard\/tasks/),
  LOAN_SEND_TO_LENDERS: new RegExp(/\/dashboard\/loan-creation\/\b[0-9a-f]{8}\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\b[0-9a-f]{12}\b\/send-to-lenders/g),
  LOAN_CREATION: new RegExp(/\/dashboard\/loan-creation\/\b[0-9a-f]{8}\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\b[0-9a-f]{12}\b/g),
  LOAN_LENDER_MANAGEMENT: new RegExp(
    /\/dashboard\/property-details\/\b[0-9a-f]{8}\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\b[0-9a-f]{12}\b\/lender-management/g
  ),
  MARKETPLACE: new RegExp(/\/dashboard\/marketplace/g),
  MY_LISTINGS: new RegExp(/\/dashboard\/my-listings/g),
  LOAN_DEAL_ROOM: new RegExp(/\/dashboard\/property-details\/\b[0-9a-f]{8}\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\b[0-9a-f]{12}\b\/deal-rooms\/\d+\/\w/g),
  MARKET_ANALYTICS_LOANS: new RegExp(/\/dashboard\/analytics\/market\/loans\b[0-9a-f]{8}\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\b[0-9a-f]{12}\b/g),
  LOAN_TASKS: new RegExp(
    /\/dashboard\/property-details\/\b[0-9a-f]{8}\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\b[0-9a-f]{12}\b\/tasks/g
  ),
};


const DOCUMENTS_SECTIONS_IDS = {
  FINANCIAL: 'financial_statement',
  COLLATERAL: 'collateral_or_re',
  SPONSOR: "sponsor'_info",
  OTHER: 'other',
  REQUESTED: 'requested',
  CUSTOM: 'custom',
};

const HIGHLIGHTER_TAG_REGEXP = /<p>/gi;
const HIGHLIGHTER_NEW_TAG = '<p style="background-color: rgba(255, 158, 1, .25); display: inline;">';
const HIGHLIGHTER_TAG = '<p>';

const SEARCH_TYPES = [
    'document',
    'people',
    'task',
    'loan',
    'message',
    'checklist'
];

/*
  Contacts types map for filtering borrower contact purpose
  in loan creation sponsor profile
*/

const CONTACTS_TYPES = {
  LENDER_LEGAL: 0,
  BORROWER_COUNSEL: 1,
  ENVIRONMENTAL: 2,
  APPRAISAL: 3,
  PROPERTY_CONDITION_ENGINEER: 4,
  LEASING_AGENT: 5,
  PROPERTY_MANAGER: 6,
  ASSET_MANAGER: 7,
  CO_BROKER: 8,
  EQUITY_INVESTOR: 9,
  BORROWER: 10,
  OUTSIDE_EQUITY_INVESTOR: 11,
  INSURANCE: 12,
  ZONING_TAXING_AUTHORITIES: 13,
  PLATFORM_PARTNERS: 14,
};

const LENDER_VISIBILITY_TYPES = {
  PRIVATE: 'invites',
  ALL: 'all',
};

const EMAIL_STATUS = {
  PERSONAL: 'personal',
  EXISTS: 'exists',
  PENDING_INVITE: 'pending_invite',
};

const TFA_DEVICE_TYPES = {
  PHONE: 'phone',
  AUTHENTICATOR: 'TOTP',
};

const DROP_DOWN_FOR_SWITCH_TO = {
  PERSONAL: 'Personal view', 
  TEAM: 'Team view', 
  SWITCH: 'Switch to...'

  // ORGANIZATION: 'Organization view'}, 
};


export {
  HIGHLIGHTER_TAG_REGEXP,
  HIGHLIGHTER_NEW_TAG,
  HIGHLIGHTER_TAG,
  SEARCH_TYPES,
  OTHER_LOAN_TYPE,
  OTHER_PROPERTY_TYPE,
  DEFAULT_LOCATION,
  IDENTIFY,
  RESET,
  TRACK,
  CLICKED_CREATE_CONCIERGE,
  SUBMITTED_CONCIERGE,
  CLICKED_NEW_LOAN_REQUEST,
  SAVED_LOAN_DRAFT,
  SUBMITTED_FOR_VERIFICATION,
  CLICKED_CONFIRM_EMAIL,
  CLICKED_SING_OUT,
  SCROLLED_MARKETPLACE_PAGE,
  SELECTED_FILTERS_MARKETPLACE,
  CLICKED_ON_LOAN_MARKETPLACE,
  URL_REGEXP,
  INTERCOM_APP_ID,
  PERCENT,
  CURRENCY,
  CUSTOMIZE_OFFER_TABLE,
  LENDER,
  BORROWER,
  BROKER,
  APPROVE_STATUSES,
  TERMSHEET_STATUSES,
  CHAT_ROOM_STAGE,
  AM,
  OTHER_PURPOSE_TYPE,
  AMORTIZATION_CUSTOM,
  LOAN_ACCESS_LEVEL,
  LOAN_STATUS,
  SUBSCRIPTION_STATUS,
  ROOMS_TYPES,
  LOAN_STATUS_LABELS,
  CHAT_ROOM_TYPE,
  MESSAGE_TYPE,
  BORROWER_TEAM_MEMBER_TYPES,
  BORROWER_TEAM_MEMBER_LABELS,
  LOAN_SECTIONS,
  OFFER_STATE,
  FIELD_TYPES,
  FLASH_MESSAGES,
  CHAT_MESSAGE_STATE,
  DEAL_ROOM_STATUS,
  SALES_BROKER,
  LISTING_STATUS,
  STAFF,
  CHAT_ROOM,
  FILTERS_TYPE,
  LENDER_LOAN_STAGE,
  PAGES_REGEXP_PATH,
  LOAN_STATUS_TYPE,
  LOAN_ACTIVITIES_STATUS_TYPE,
  LOAN_STATUS_TITLE,
  DOCUMENTS_SECTIONS_IDS,
  CONTACTS_TYPES,
  LENDER_VISIBILITY_TYPES,
  EMAIL_STATUS,
  ROLES_LABELS,
  TFA_DEVICE_TYPES,
  TERMSHEET_EVENTS,
  ANALYTICS_EVENTS,
  SIGN_UP_EVENTS,
  STANDALONE_LOAN_EVENTS,
  LENDER_TEAM_MEMBER_TYPES,
  LENDER_TEAM_MEMBER_LABELS,
  LOAN_REMINDER_SET,
  LOAN_EXIT_POPUP_CLOSE,
  DOC_SIGNATURE_NEW,
  DOC_SIGNATURE_REJECTED,
  DOC_SIGNATURE_ACCEPTED,
  DOC_SIGNATURE_REJECTED_REASON,
  DROP_DOWN_FOR_SWITCH_TO,
  UUID_REGEXP,
  ANALYTICS_LOANS_HEADERS,
  SWITCH_TO_EVENTS,

  getMemberPermissionLabel,
};
