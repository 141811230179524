export const syncActions = [
  'UPDATE_MARKET_ANALYTICS_RANGE_DATE',
  'UPDATE_MARKET_ANALYTICS_GROUPING',
];

export const asyncActions = [
  'UPDATE_MARKET_ANALYTICS',
  'GET_MARKET_ANALYTICS',
  'TRACK_ANALYTICS',
  'EXPORT_ANALYTICS_DATA',
];
