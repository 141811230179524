import React, { useState } from 'react';
import styled, { css } from 'styled-components/macro';
import { FileUpload } from 'components/FileUpload';
import { Text } from 'ui/Text';
import { DocumentIcon, LockOpenIcon, LockedIcon } from 'components/SvgIcons';
import { DocumentRejectionHandler } from 'components/DocumentRejectionHandler';

const DocumentItem = ({
  label,
  onDrop,
  uploaded,
  isDocumentProtected,
  updateDocumentProtectionState,
}) => {
  const [protectedDocument, updatePrivacy] = useState(isDocumentProtected);

  const toggleDocumentProtectedState = () => {
    updatePrivacy(!protectedDocument);

    if (uploaded) {
      updateDocumentProtectionState(!protectedDocument);
    }
  };

  return (
    <DocumentWrapper>
      <DocumentNameWrapper>
        <DocumentName>
          <DocumentIcon />
          <Text>{label}</Text>
        </DocumentName>
        {!uploaded && (
          <DocumentRejectionHandler>
            {({ handleOnDropReject }) => (
              <FileUploadContent
                onDropRejected={handleOnDropReject}
                onDrop={(files) => {
                  onDrop({ files, protectedDocument });
                }}
              >
                <img src="/images/icons/ico_upload_blue.svg" alt="upload" />
                <Text>ADD TO LOAN REQUEST</Text>
              </FileUploadContent>
            )}
          </DocumentRejectionHandler>
        )}
      </DocumentNameWrapper>
      <ProtectBtn onClick={toggleDocumentProtectedState} protected={protectedDocument}>
        {protectedDocument ? <LockedIcon color="#4394ea" /> : <LockOpenIcon />}
        <span>Request CA</span>
      </ProtectBtn>
    </DocumentWrapper>
  );
};

export const DocumentPrivacyForm = ({
  requests,
  uploadDocument,
  notificationId,
  loanId,
  updateDocumentProtectionState,
}) => (
  <Wrapper>
    <Header>
      <Text>DOCUMENT NAME</Text>
      <Text>PRIVACY</Text>
    </Header>
    {!!requests?.length
      ? requests.map((request) => (
          <DocumentItem
            label={request.label}
            uploaded={request.uploaded}
            isDocumentProtected={request.protected}
            updateDocumentProtectionState={(protectedDocument) => {
              updateDocumentProtectionState({
                folderId: request.folder,
                id: loanId,
                notificationId,
                protectedDocument,
              });
            }}
            onDrop={({ files, protectedDocument }) => {
              uploadDocument({
                files,
                folderId: request.folder,
                id: loanId,
                notificationId,
                protectedDocument,
              });
            }}
            key={`${request.type}${request.label}`}
          />
        ))
      : null}
  </Wrapper>
);

const Wrapper = styled.div`
  padding-bottom: 24px;
`;

const DocumentWrapper = styled.div`
  display: flex;
  align-items: flex-start;

  &:not(:last-child) {
    padding-bottom: 16px;
    margin-bottom: 16px;
    border-bottom: 1px solid #e8e9ed;
  }
`;

const DocumentNameWrapper = styled.div`
  flex: 1 auto;
`;

const DocumentName = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;

  ${Text} {
    margin-left: 8px;
  }
`;

const FileUploadContent = styled(FileUpload)`
  display: inline-flex;
  align-items: center;
  outline: none;
  padding: 0;
  margin: 0;
  border: none;
  background-color: #fff;
  cursor: pointer;

  &:hover {
    ${Text} {
      text-decoration: underline;
    }
  }

  ${Text} {
    margin-left: 8px;
    color: #4394ea;
    font-size: 10px;
    flex-shrink: 0;
  }
`;

const Header = styled.div`
  display: flex;
  padding-bottom: 8px;
  margin-bottom: 16px;
  border-bottom: 1px solid #e8e9ed;

  ${Text} {
    color: #778598;
    font-size: 10px;

    &:first-child {
      flex: 1 auto;
    }

    &:last-child {
      width: 110px;
    }
  }
`;

const ProtectBtnCommon = styled.button`
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0 6px 0 0;
  outline: none;
  background-color: #fff;
  border: none;
  cursor: pointer;

  svg {
    margin-right: 6px;
  }

  span {
    position: relative;
    top: 1px;
  }

  &:hover {
    span {
      text-decoration: underline;
    }
  }
`;

const ProtectBtn = styled(ProtectBtnCommon)`
  ${(p) =>
    !p.protected &&
    css`
      &:hover {
        g {
          stroke: #4394ea;
        }
      }
    `}
`;
