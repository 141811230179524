import React from 'react';
import styled from 'styled-components/macro';
import { ModalHeader, ModalBody, ModalTitle } from 'ui/Modal/styles';
import { Form, Field } from 'react-final-form';
import { UserAvatar } from 'components/UserAvatar';
import { NewCheckbox } from 'components/Form';
import { Text } from 'ui/Text';
import { Button } from 'ui/Button';
import { createChatTeamGroupRequest } from 'features/Chat/actions';
import { BORROWER } from 'constants/appRelated';

const UserItem = styled.label`
  display: flex;
  margin-bottom: 16px;
`;

const UserInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  margin-left: 8px;
  border-bottom: 1px solid #e8e9ed;
`;

const ItemControls = styled.div`
  display: flex;
  align-items: center;

  label {
    margin-right: 8px;
  }
`;

const UsersList = styled.div`
  margin-bottom: 24px;
`;

export const CreateGroupChatModal = ({
  hideModal,
  team,
  negotiationId,
  offerId,
  loanId,
  dispatch,
}) => (
  <Form
    onSubmit={({ users }) => {
      if (users && users.length) {
        dispatch(createChatTeamGroupRequest({ users }));
        hideModal();
      }
    }}
    render={({ handleSubmit }) => (
      <form onSubmit={handleSubmit}>
        <ModalHeader>
          <ModalTitle>Add member to new group</ModalTitle>
        </ModalHeader>
        <ModalBody>
          <UsersList>
            {team.map(({ firstName, lastName, userId }) => (
              <UserItem key={userId}>
                <ItemControls>
                  <Field
                    component={NewCheckbox}
                    name="users"
                    value={userId}
                    type="checkbox"
                    mb={0}
                  />
                  <UserAvatar author={{ firstName, lastName, accountType: BORROWER }} />
                </ItemControls>

                <UserInfo>
                  <Text>
                    {firstName} {lastName}
                  </Text>
                </UserInfo>
              </UserItem>
            ))}
          </UsersList>
          <Button type="submit">Create group</Button>
        </ModalBody>
      </form>
    )}
  />
);
