
import React from 'react';
import styled from 'styled-components/macro';
import { Text } from 'ui/Text';
import { ButtonGhost } from 'ui/Button';
import { getMemberPermissionLabel } from 'constants/appRelated';
import { BlurContent } from 'components/BlurContent';

export const InviteColleagues = ({access, invitedLenders, openInviteColleagues}) => {
    return (
        <InviteColleaguesWrapper>
            <TextTitle 
                style={invitedLenders?.length ? {marginBottom: '20px'} : {marginBottom: '5px'}}
            >Sharing</TextTitle>

            <BlurContent blured={!access.full} height={347}>
                {invitedLenders?.length ?
                <LendersList>
                    {invitedLenders.map((lender, index) =>
                        <LenderIconWrapper key={index}>
                            <Icon bg={lender.lenderTypeColor}>
                                <span>{`${lender.firstName[0]}${lender.lastName[0]}`}</span>
                            </Icon>
                            <div className="wrapper">
                                <TextLenderName >
                                    {`${lender.firstName} ${lender.lastName}`}
                                </TextLenderName>
                                <TextLenderRole>{getMemberPermissionLabel(lender.permissionsLevel)}</TextLenderRole>
                            </div>     
                        </LenderIconWrapper>
                    )}
                </LendersList>
                :
                <TextDescription>invite teammates to work together on the loan</TextDescription>}
                <ButtonGhost onClick={openInviteColleagues}>Invite</ButtonGhost>
            </BlurContent>
        </InviteColleaguesWrapper>
    )
}


const InviteColleaguesWrapper = styled.div`
    background: #FFFFFF;
    box-shadow: 0px 4px 10px rgba(194, 198, 210, 0.2);
    border-radius: 4px;
    margin-bottom: 23px;
    padding: 23px 26px;
`;

const TextTitle = styled(Text)`
    font-weight: 600; 
    font-size: 20px;
`;

const TextDescription = styled(Text)`
    color: #778598;
    margin-bottom: 24px;
`;

const LendersList = styled.div`
    margin-bottom: 24px;
`;

const LenderIconWrapper = styled.div`
    margin: 0 auto;
    position: relative;
   // margin-right: 22px;
   margin-bottom: 12px;
   display: flex;
   align-items: center;
   span{
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    display: block;
    text-align: center;
    margin-top: 25%;
   }
`;

const Icon = styled.div`
    width: 30px;
    height: 30px;
    background:  ${(p) => p.bg || 'grey'};
    border-radius: 50%;
    // margin-top: 10px;
    margin-right: 8px;
    span{
        font-size: 9px;
        line-height: 14px; 
        color: #FFFFFF;
    }
`
const TextLenderName = styled(Text)`
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 2px;
`;
const TextLenderRole = styled(Text)`
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #778598;
`;