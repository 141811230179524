import React, { Component } from 'react';
import { Form, Field } from 'react-final-form';
import Yup from 'yup';
import { validate } from 'utils/validate';
import { Textarea } from 'components/Form';
import { hideLoanRequest } from 'features/MyMatchingLoans/actions';
import { ModalHeader, ModalBody, ModalControls, ModalTitle } from 'ui/Modal/styles';
import { Button } from 'ui/Button';

const schema = Yup.object().shape({
  reason: Yup.string().required('Reason is required!'),
});

export class HideLoanForm extends Component {
  formSubmit = (values) => {
    const { dispatch, pk, hideModal } = this.props;

    hideModal();
    dispatch(
      hideLoanRequest({
        pk,
        message: values.reason,
      })
    );
  };

  render() {
    const { hideModal } = this.props;
    return (
      <Form
        onSubmit={this.formSubmit}
        validate={(values) => validate(values, schema)}
        render={({ handleSubmit, submitting, validating }) => (
          <form onSubmit={handleSubmit}>
            <ModalHeader>
              <ModalTitle>Please tell us the reason</ModalTitle>
            </ModalHeader>
            <ModalBody>
              <Field
                component={Textarea}
                name="reason"
                rows={3}
                placeholder="Type the reason"
              />
              <ModalControls>
                <Button type="submit" mr={2}>
                  Submit
                </Button>
                <Button secondary onClick={hideModal}>
                  Cancel
                </Button>
              </ModalControls>
            </ModalBody>
          </form>
        )}
      />
    );
  }
}
