import types from 'constants/actionTypes';

export const listingRegistrationRequest = (payload = {}) => ({
  type: types.LISTING_REGISTRATION_REQUEST,
  payload,
});
export const listingRegistrationSuccess = (payload = {}) => ({
  type: types.LISTING_REGISTRATION_SUCCESS,
  payload,
});
export const listingRegistrationFailure = (payload = {}) => ({
  type: types.LISTING_REGISTRATION_FAILURE,
  payload,
});
