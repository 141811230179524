import { normalizeThousands } from './normalizeThousands';

export const normalizeDollars = (value) => {
  if (!value && value !== 0) {
    return value;
  } else if (value === 'null' || value === '$') {
    return '';
  }

  return `$${normalizeThousands(String(value))}`;
};
