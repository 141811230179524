import React from 'react';
import styled from 'styled-components/macro';
import { Text } from 'ui/Text';
import { FIELD_TYPES, PERCENT, CURRENCY } from 'constants/appRelated';
import { FormattedNumber } from 'react-intl';
import { makeLoanTerm } from 'lib/loan';
import { choiceBooleanOptions } from 'utils/fields';

export const Field = ({ loan, item, normalizedOptions, FieldEdited }) => {
  if (!item) return null;

  const { fieldName, type } = item;
  const value = loan[fieldName];
  let displayValue;

  if (value === null || value === undefined || type === FIELD_TYPES.DURATION_UNIT) {
    return null;
  }

  switch (type) {
    case FIELD_TYPES.CHOICE:
      displayValue = normalizedOptions?.[value]?.label;
      break;

    case FIELD_TYPES.CHOICE_BOOLEAN:
      displayValue = choiceBooleanOptions.find((item) => item.value === value)?.label;
      break;
    case FIELD_TYPES.PERCENT:
      displayValue = (
        <FormattedNumber
          style={PERCENT}
          maximumFractionDigits={5}
          minimumFractionDigits={0}
          value={value / 100}
        />
      );
      break;
    case FIELD_TYPES.DURATION:
      displayValue = !loan[`${fieldName}Unit`]
        ? makeLoanTerm(value)
        : `${value} year${value > 1 ? 's' : ''}`;
      break;
    case FIELD_TYPES.DOLLARS:
      displayValue = (
        <FormattedNumber
          style={CURRENCY}
          currency="USD"
          minimumFractionDigits={0}
          value={value}
        />
      );
      break;
    case FIELD_TYPES.NUMBER:
      displayValue = <FormattedNumber value={value} />;
      break;
    case FIELD_TYPES.TOGGLE:
      displayValue = value ? 'Yes' : 'No';
      break;
    case FIELD_TYPES.DATE:
      const date = value.split('-');
      displayValue = `${date[1]}/${date[2]}/${date[0]}`;
      break;
    case FIELD_TYPES.TEXT:
      displayValue = value;
      break;
    default:
      displayValue = null;
      break;
  }

  return (
    <ShouldRender type={type} value={value}>
      <Wrapper data-testid="loanFieldItem">
        <Label>
          {item.label}
          {FieldEdited}
        </Label>
        <TextCommon>{displayValue || value}</TextCommon>
      </Wrapper>
    </ShouldRender>
  );
};

const WRONG_RENDER_CONDITIONS = {
  [FIELD_TYPES.CHOICE_BOOLEAN]: null,
};

const ShouldRender = ({ children, value, type }) => {
  if (
    WRONG_RENDER_CONDITIONS.hasOwnProperty(type) &&
    WRONG_RENDER_CONDITIONS[type] === value
  ) {
    return null;
  }
  return children;
};

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  padding-bottom: 32px;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 16px;
    width: 100%;
    height: 1px;
    background-color: #e8e9ed;
  }

  &:last-child {
    border-bottom: none;
    margin-bottom: 0;
  }
`;

const TextCommon = styled(Text)`
  font-size: 14px;
  line-height: 16px;
  text-align: right;
`;

const Label = styled(Text)`
  font-weight: 500;
`;
