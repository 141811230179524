import types from 'constants/actionTypes';
import immutable from 'object-path-immutable';
import { findIndex, propEq } from 'ramda';

const initialState = {
  rooms: [],
};

export default function dealRooms(state, action = {}) {
  const currentState = state || initialState;

  const { type } = action;

  switch (type) {
    case types.GET_DEAL_ROOMS_SUCCESS:
      return { ...currentState, rooms: action.payload.map(room => ({...room, id: room.uuid}))};

    case types.UPLOAD_CHECK_LIST_SUCCESS: {
      const { id, status } = action.payload;
      const index = findIndex(propEq('id', id))(currentState.rooms);
      return immutable.set(currentState, `rooms.${index}`, {
        ...currentState.rooms[index],
        status,
      });
    }
    default:
      return currentState;
  }
}
