import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components/macro';
import { SpinnerItem } from './SpinnerItem';

const Wrapper = styled.div.attrs((p) => ({
  style: { 
    display: p.show ? 'flex' : 'none',
    position: p.defStyles?.postiton && 'initial',
    width: p.defStyles?.width && 'auto',
    height: p.defStyles?.height && 'auto'
  }
}))`
  position: fixed;
  height: 100vh;
  width: 100vw;
  background-color: hsla(0, 0%, 100%, 0.3);
  top: 0;
  left: 0;
  color: #ff9b12;
  z-index: 1511;
`;

const Spinner = ({ spinner, show, style, defStyles }) => (
  <Wrapper style={style} show={spinner || show} defStyles={defStyles}>
    <SpinnerItem />
  </Wrapper>
);

function modal(state) {
  return {
    spinner: state.spinner,
  };
}

export default connect(modal)(Spinner);
