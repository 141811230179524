const reduceFolder = (folder, searchString) => {
  if (
    folder.documents.length &&
    folder.name.toUpperCase().indexOf(searchString.toUpperCase()) !== -1
  ) {
    return folder;
  }

  const filteredDocuments = folder.documents.filter(
    (document) =>
      document.filename.toUpperCase().indexOf(searchString.toUpperCase()) !== -1
  );

  if (filteredDocuments.length) {
    return {
      ...folder,
      documents: filteredDocuments,
    };
  }

  return null;
};

export const filterDocuments = (documents, searchString) => {
  if (!searchString) {
    return documents;
  }

  const { recommended, sections } = documents;
  const result = {};

  result.recommended = recommended
    .map((folder) => reduceFolder(folder, searchString))
    .filter(Boolean);

  result.sections = sections
    .map((section) => {
      if (
        section.folders.filter((folder) => folder.documents.length).length &&
        section.sectionName.toUpperCase().indexOf(searchString.toUpperCase()) !== -1
      ) {
        return {
          ...section,
          folders: section.folders.filter((folder) => folder.documents.length),
        };
      }

      const sectionFolders = section.folders
        .map((folder) => reduceFolder(folder, searchString))
        .filter(Boolean);

      if (sectionFolders.length) {
        return {
          ...section,
          folders: sectionFolders,
        };
      }

      return null;
    })
    .filter(Boolean);

  return result;
};
