import React from 'react';
import styled from 'styled-components/macro';

const RejectReasonWrapper = styled.div`
  width: 100%;
  border-radius: 4px;
  background-color: rgba(251, 251, 252, 0.9);
  flex: 1;
  padding: 8px 16px;
`;

const RejectReasonTitle = styled.p`
  display: flex;
  align-items: center;
  margin: 0 0 8px;
  padding: 0;
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;

  &::before {
    content: url('/images/icons/ico_info.svg');
    margin-right: 8px;
    width: 14px;
    height: 14px;
  }
`;

const RejectReasonText = styled.p`
  margin: 0;
  padding: 0;
  font-size: 12px;
  line-height: 20px;
`;

const ViewMoreButton = styled.button`
  padding: 0;
  margin: 0;
  outline: none;
  border: 0;
  background-color: transparent;
  color: #1fbbfd;
  cursor: pointer;
`;

export class OfferRejectReason extends React.Component {
  state = { expanded: false };

  expandText = () => {
    this.setState({ expanded: true });
  };

  render() {
    const { rejectReason, title = 'Comments:' } = this.props;
    const { expanded } = this.state;
    const rejectReasonWords = rejectReason.split(' ');
    return (
      <RejectReasonWrapper>
        <RejectReasonTitle>{title}</RejectReasonTitle>
        <RejectReasonText>
          {rejectReasonWords.slice(0, expanded ? undefined : 10).join(' ')}{' '}
          {rejectReasonWords.length > 10 && !expanded && (
            <ViewMoreButton onClick={this.expandText}>view more...</ViewMoreButton>
          )}
        </RejectReasonText>
      </RejectReasonWrapper>
    );
  }
}
