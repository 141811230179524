import { put, takeLatest, call } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import types from 'constants/actionTypes';
import { modals, INFO_MODAL_TYPE } from 'constants/modalTypes';
import { showModal } from 'actions/modalActions';
import * as API from 'api';
import { lendersDirectoryListSchema } from 'utils/schemas';
import { normalize } from 'normalizr';
import { getError } from 'sagas/utils';

import {
  getRealatomLendersDataSuccess,
  getRealatomLendersDataFailure,
  sendLoanToLendersSuccess,
  sendLoanToLendersFailure,
  getRelationshipLendersSuccess,
  getRelationshipLendersFailure,
  updateRelationshipLendersSuccess,
  updateRelationshipLendersFailure,
} from './actions';

function* getRealAtomLendersData(action) {
  try {
    const { loanId } = action.payload;
    const {
      data: { response },
    } = yield call(API.axiosApi.get, `loans/${loanId}/lender-preferences`);

    const realAtomLenderTypes = response.realatomLenders.lenderTypes;
    const realAtomLendersBlackList = response.realatomLenders.blacklist;
    const realationshipLendersSelected = response?.relationshipLenders?.matchedLenders;
    const interactedLendersCount = response?.relationshipLenders?.interactedLendersCount;

    const invitation = response.relationshipLenders.invitation;
    yield put(
      getRealatomLendersDataSuccess({
        realAtomLenderTypes,
        realAtomLendersBlackList,
        realationshipLendersSelected,
        invitation,
        interactedLendersCount,
      })
    );
  } catch (e) {
    console.log(e);
    yield put(getRealatomLendersDataFailure({ message: getError(e) }));
  }
}

function* sendLoanToLenders(action) {
  const {
    loanId,
    relationshipLenders,
    excludeCompanies,
    realAtomLenders,
    relationshipLendersAllSelected,
    invitation,
    relationshipLendersExclude,
    excludeAllLenders
  } = action.payload;

  try {
    yield call(API.axiosApi.patch, `loans/${loanId}/lender-preferences`, {

      realatom_lenders: {
        lender_types: realAtomLenders,
        blacklist: excludeCompanies,
        ignore_relation_lenders: excludeAllLenders
      },
      relationship_lenders: {
        invitation,
        selected: relationshipLenders,
        excluded: relationshipLendersExclude,
        select_all: relationshipLendersAllSelected,
      },
    });

    yield put(push(`/dashboard/property-details/${loanId}`));

    yield put(
      showModal(modals.INFO_MODAL, {
        size: 'sm',
        infoType: INFO_MODAL_TYPE.SUCCESS,
        title: 'You have successfully sent your loan to lenders',
        buttonText: 'OK',
      })
    );

    yield put(sendLoanToLendersSuccess());
  } catch (e) {
    yield put(sendLoanToLendersFailure({ message: getError(e) }));
  }
}

function* getRelationshipData(action) {
  try {
    const { query, loanId, search } = action.payload;
    const requestQuery = [];

    if (loanId) {
      requestQuery.push(`loan=${loanId}`);
    }

    if (query) {
      requestQuery.push(query.replace('?', ''));
    }

    if (search) {
      requestQuery.push(`search=${search.replace('?', '')}`);
    }

    const lenders = yield call(
      API.axiosApi.get,
      `lenders/invite${requestQuery.length ? `?${requestQuery.join('&')}` : ''}`
    );
    const tags = yield call(API.axiosApi.get, '/lender-tags/');
    const {
      count,
      next,
      pageNumber,
      pageSize,
      previous,
      results,
    } = lenders.data.response;

    const updatedTags = tags.data.response.map((tag) => ({ label: tag, value: tag }));

    const { entities, result } = normalize(results, lendersDirectoryListSchema);

    yield put(
      getRelationshipLendersSuccess({
        relationshipLendersIds: result,
        relationshipLendersNormalized: { ...entities.lenders },
        tags: updatedTags,
        count,
        next,
        pageNumber,
        pageSize,
        previous,
      })
    );
  } catch (e) {
    console.log(e);
    yield put(getRelationshipLendersFailure({ message: getError(e) }));
  }
}

function* updateRelationshipLendersData(action) {
  try {
    const { query, loanId, search } = action.payload;
    const requestQuery = [];

    if (loanId) {
      requestQuery.push(`loan=${loanId}`);
    }

    if (query) {
      requestQuery.push(query.replace('?', ''));
    }

    if (search) {
      requestQuery.push(`search=${search.replace('?', '')}`);
    }

    const {
      data: { response },
    } = yield call(
      API.axiosApi.get,
      `lenders/invite${requestQuery.length ? `?${requestQuery.join('&')}` : ''}`
    );
    const { count, next, pageNumber, pageSize, previous } = response;
    const { entities, result } = normalize(response.results, lendersDirectoryListSchema);

    yield put(
      updateRelationshipLendersSuccess({
        relationshipLendersIds: result,
        relationshipLendersNormalized: { ...entities.lenders },
        count,
        next,
        pageNumber,
        pageSize,
        previous,
      })
    );
  } catch (e) {
    console.log(e);
    yield put(updateRelationshipLendersFailure({ message: getError(e) }));
  }
}

export default [
  takeLatest(types.GET_REALATOM_LENDERS_DATA_REQUEST, getRealAtomLendersData),
  takeLatest(types.SEND_LOAN_TO_LENDERS_REQUEST, sendLoanToLenders),
  takeLatest(types.GET_RELATIONSHIP_LENDERS_REQUEST, getRelationshipData),
  takeLatest(types.UPDATE_RELATIONSHIP_LENDERS_REQUEST, updateRelationshipLendersData),
];
