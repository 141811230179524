import React, { useRef, useEffect } from 'react';
import styled from 'styled-components/macro';

export const ActionsWrapper = ({data, setShowActions, children }) => {
    const ref = useRef(null);

    useEffect(() => {
      function handleClickOutside(event) {
        ref.current && !ref.current.contains(event.target) && setShowActions(null);
      }
      
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
          document.removeEventListener("mousedown", handleClickOutside);
      };
  }, [ref]);

  return (
    <Wrapper data={data} ref={ref}>
        {children}    
    </Wrapper>
)}




const Wrapper = styled.div`
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  right: 0;
  top: 10px;
  width: 220px;
  height: 120px;

  // ${(p) => (p.data ? 'height: 120px' : 'height: 85px' )};

  background: #FFFFFF;
  box-shadow: 0px 4px 10px rgba(194, 198, 210, 0.2);
  border-radius: 4px;
`;