import React from 'react';
import { Button } from 'ui/Button';
import { Link } from 'react-router-dom';
import { analytics } from 'lib/analytics';
import { STANDALONE_LOAN_EVENTS, TRACK } from 'constants/appRelated';

export const StandAloneActions = ({ lenderStatus, loanId, uuid }) => {
  if (lenderStatus === true) {
    return (
      <Link
        to={`/login?afterLogin=toLoan&loanId=${loanId}`}
        onClick={() => analytics(TRACK, STANDALONE_LOAN_EVENTS.STANDALONE_LOAN_CTA_CLICK)}
      >
        <Button>Login</Button>
      </Link>
    );
  }

  if (lenderStatus === false) {
    return (
      <Link
        to={`/set-lender-password?&uuid=${uuid}&loanId=${loanId}`}
        onClick={() => analytics(TRACK, STANDALONE_LOAN_EVENTS.STANDALONE_LOAN_CTA_CLICK)}
      >
        <Button>Sign Up</Button>
      </Link>
    );
  }

  if (lenderStatus === null) {
    return (
      <>
        <Link
          to={`/login?afterLogin=toLoan&loanId=${loanId}`}
          onClick={() =>
            analytics(TRACK, STANDALONE_LOAN_EVENTS.STANDALONE_LOAN_CTA_CLICK)
          }
        >
          <Button>Login</Button>
        </Link>
        <Link
          to={`/registration/lender?loanId=${loanId}`}
          onClick={() =>
            analytics(TRACK, STANDALONE_LOAN_EVENTS.STANDALONE_LOAN_CTA_CLICK)
          }
        >
          <Button>Sign Up</Button>
        </Link>
      </>
    );
  }

  return null;
};
