import React from 'react';
import styled from 'styled-components';
import { DropDown } from 'ui/DropDown';
import { rangeOptions, groupOptions } from 'features/Analytics/config';

import { DateRange } from './DateRange';
import { normalizedGroupOptions } from '../config';

export const DateSelection = ({
  rangeDateState,
  rangeDate,
  onDateChange,
  setDateRange,
  groupingState,
  onGroupingChange,
}) => (
  <DateSelectionWrapper>
    <DateRangeWrapper>
      Date Range
      <RangeDropdownWrapper>
        <DropDown options={rangeOptions} value={rangeDateState} onChange={setDateRange} />
      </RangeDropdownWrapper>
      <DateRange fromTo={rangeDate} onDateChange={onDateChange} />
    </DateRangeWrapper>
    <GroupByWrapper>
      <DropDown
        options={groupOptions}
        variant="blue"
        value={normalizedGroupOptions[groupingState]}
        onChange={onGroupingChange}
      />
    </GroupByWrapper>
  </DateSelectionWrapper>
);

const DateRangeWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const DateSelectionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const GroupByWrapper = styled.div`
  width: 170px;
`;

const RangeDropdownWrapper = styled.div`
  width: 140px;
  margin-left: 12px;
  margin-right: 16px;
`;
