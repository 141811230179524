import types from 'constants/actionTypes';

export const getListingRequest = (payload = {}) => ({
  type: types.GET_LISTING_REQUEST,
  payload,
});
export const getListingSuccess = (payload = {}) => ({
  type: types.GET_LISTING_SUCCESS,
  payload,
});
export const getListingFailure = (payload = {}) => ({
  type: types.GET_LISTING_FAILURE,
  payload,
});

export const submitQuoteRequest = (payload = {}) => ({
  type: types.SUBMIT_QUOTE_REQUEST,
  payload,
});
export const submitQuoteSuccess = (payload = {}) => ({
  type: types.SUBMIT_QUOTE_SUCCESS,
  payload,
});
export const submitQuoteFailure = (payload = {}) => ({
  type: types.SUBMIT_QUOTE_FAILURE,
  payload,
});

export const editQuoteRequest = (payload = {}) => ({
  type: types.EDIT_QUOTE_REQUEST,
  payload,
});
export const editQuoteSuccess = (payload = {}) => ({
  type: types.EDIT_QUOTE_SUCCESS,
  payload,
});
export const editQuoteFailure = (payload = {}) => ({
  type: types.EDIT_QUOTE_FAILURE,
  payload,
});

export const applyForFinancingRequest = (payload = {}) => ({
  type: types.APPLY_FOR_FINANCING_REQUEST,
  payload,
});
export const applyForFinancingSuccess = (payload = {}) => ({
  type: types.APPLY_FOR_FINANCING_SUCCESS,
  payload,
});
export const applyForFinancingFailure = (payload = {}) => ({
  type: types.APPLY_FOR_FINANCING_FAILURE,
  payload,
});

export const exportQuotesRequest = (payload = {}) => ({
  type: types.EXPORT_QUOTES_REQUEST,
  payload,
});
export const exportQuotesSuccess = (payload = {}) => ({
  type: types.EXPORT_QUOTES_SUCCESS,
  payload,
});
export const exportQuotesFailure = (payload = {}) => ({
  type: types.EXPORT_QUOTES_FAILURE,
  payload,
});
