import types from 'constants/actionTypes';

export const getSearchHistoryRequest = (payload = {}) => ({
  type: types.GET_SEARCH_HISTORY_REQUEST,
  payload,
});

export const getSearchHistorySuccess = (payload = {}) => ({
  type: types.GET_SEARCH_HISTORY_SUCCESS,
  payload,
});

export const getSearchHistoryFailure = (payload = {}) => ({
  type: types.GET_SEARCH_HISTORY_FAILURE,
  payload,
});

export const setTags = (payload = {}) => ({
  type: types.SET_TAGS,
  payload,
});

export const setSearchBar = (payload = {}) => ({
  type: types.SET_SEARCH_BAR,
  payload,
});

export const setShowSearchBarInResultPage = (payload = {}) => ({
  type: types.SET_SHOW_SEARCH_BAR_IN_RESULT_PAGE,
  payload,
});

export const clearSearchResults = () => ({
  type: types.CLEAR_SEARCH_RESULTS
});

export const getSearchResultsRequest = (payload = {}) => ({
  type: types.GET_SEARCH_RESULTS_REQUEST,
  payload,
});

export const getSearchResultsSuccess = (payload = {}) => ({
  type: types.GET_SEARCH_RESULTS_SUCCESS,
  payload,
});

export const getSearchResultsFailure = (payload = {}) => ({
  type: types.GET_SEARCH_RESULTS_FAILURE,
  payload,
});

export const moreScrollingSearchResults = (payload = {}) => ({
  type: types.MORE_SCROLL_SEARCH_RESULTS,
  payload,
});

export const getSearchCountRequest = (payload = {}) => ({
  type: types.GET_SEARCH_COUNT_REQUEST,
  payload,
});

export const getSearchCountSuccess = (payload = {}) => ({
  type: types.GET_SEARCH_COUNT_SUCCESS,
  payload,
});

export const getSearchCountFailure = (payload = {}) => ({
  type: types.GET_SEARCH_COUNT_FAILURE,
  payload,
});