import { put, takeLatest, call } from 'redux-saga/effects';
import { modals } from 'constants/modalTypes';
import { showModal } from 'actions/modalActions';
import types from 'constants/actionTypes';
import * as API from 'api';
import { getError } from 'sagas/utils';

import {
  inboxSuccess,
  inboxFailure,
  inboxAdditionalDataSuccess,
  inboxAdditionalDataFailure,
  readQuestionSuccess,
  readQuestionFailure,
  readQuestionRequest,
} from './actions';

function* inboxRequest(action) {
  try {
    const { inbox, questionPk } = action.payload;

    const {
      data: { response },
    } = yield call(API.axiosApi.get, `loans/questions?state=${inbox}`);

    if (questionPk) {
      const question = response.results.filter(({ id }) => Number(questionPk) === id)[0];

      if (question) {
        yield put(showModal(modals.ANSWER_FORM, { question, inbox }));
        yield put(readQuestionRequest({ questionPk: question.id }));
      }
    }

    yield put(inboxSuccess(response));
  } catch (e) {
    console.error(e);
    yield put(inboxFailure({ message: getError(e) }));
  }
}

function* readQuestion(action) {
  try {
    const { questionPk } = action.payload;

    const {
      data: { response },
    } = yield call(API.axiosApi.patch, `loan-questions/${questionPk}/mark_read`, {
      question_read: true,
    });

    yield put(readQuestionSuccess({ notifications: response.unreadNotifications }));
  } catch (e) {
    console.log(e);
    yield put(readQuestionFailure());
  }
}

function* loadAdditionalInbox(action) {
  const { page, inbox } = action.payload;
  try {
    const {
      data: { response },
    } = yield call(API.axiosApi.get, `loans/questions?state=${inbox}&page=${page}`);

    yield put(inboxAdditionalDataSuccess(response));
  } catch (e) {
    console.error(e);
    yield put(inboxAdditionalDataFailure({ message: getError(e) }));
  }
}

export default [
  takeLatest(types.INBOX_REQUEST, inboxRequest),
  takeLatest(types.READ_QUESTION_REQUEST, readQuestion),
  takeLatest(types.INBOX_ADDITIONAL_DATA_REQUEST, loadAdditionalInbox),
];
