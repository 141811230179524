import React from 'react';
import { Section, SectionTitle, SectionHead, EditLink } from 'styles/loan';
import { FieldEdited } from 'features/LoanNew/components';
import { fieldsConfig } from 'config/fieldsConfig';
import { Text } from 'ui/Text';
import styled from 'styled-components/macro';

import { FieldSet } from './FieldSet';
import { Field } from './Field';

export const LoanFieldsSet = ({
  loan,
  fields,
  normalizedOptions,
  editable,
  title,
  editLink,
  customFields,
  fieldsHistory = {},
  fieldModifier,
}) => (
  <Section>
    {editable && <EditLink to={editLink} />}
    <SectionHead>
      <SectionTitle>{title}</SectionTitle>
    </SectionHead>
    <FieldSet>
      {fields.map((field) => {
        const fieldData =
          fieldModifier && typeof fieldModifier === 'function'
            ? fieldModifier(fieldsConfig[field])
            : fieldsConfig[field];

        return fieldData ? (
          <Field
            key={field}
            item={fieldData}
            loan={loan}
            normalizedOptions={normalizedOptions[`${field}Options`]}
            FieldEdited={fieldsHistory[field] && <FieldEdited fieldName={field} />}
          />
        ) : null;
      })}
      {customFields &&
        customFields.map((field) => (
          <Wrapper key={field.name}>
            <Label>
              {field.label}
              {fieldsHistory[field] && <FieldEdited fieldName={field} />}
            </Label>
            <TextCommon>{field.value}</TextCommon>
          </Wrapper>
        ))}
    </FieldSet>
  </Section>
);

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  padding-bottom: 32px;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 16px;
    width: 100%;
    height: 1px;
    background-color: #e8e9ed;
  }

  &:last-child {
    border-bottom: none;
    margin-bottom: 0;
  }
`;

const TextCommon = styled(Text)`
  font-size: 14px;
  line-height: 16px;
  text-align: right;
`;

const Label = styled(Text)`
  font-weight: 500;
`;
