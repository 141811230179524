import React from 'react';
import styled from 'styled-components/macro';
import { Text } from 'ui/Text';
import { Button } from 'ui/Button';

const Controls = styled.div`
  width: 120px;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
`;

function getButtonLabel(type) {
  switch (type) {
    case 'questions':
      return 'Answer';
    case 'counteroffer':
      return 'View quote';
    case 'quote':
      return 'View quote';
    case 'document':
    case 'dealroom':
      return 'Upload';
    case 'termsheet':
      return 'Review';
    default:
      return '';
  }
}

export const ActionRow = ({ type, description, action }) => (
  <Wrapper>
    <Text fontWeight={0}>{description}</Text>
    <Controls>
      <Button
        fullWidth
        data-testid='redirectTo'
        onClick={action}
      >
        {getButtonLabel(type)}
      </Button>
    </Controls>
  </Wrapper>
);
