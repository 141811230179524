import React from 'react';
import styled from 'styled-components/macro';
import { connect } from 'react-redux';
import {
  ModalBody,
  ModalHeading,
  ModalWithContentWrapper,
  ModalActions,
} from 'ui/Modal/styles';
import { Button } from 'ui/Button';
import { Condition } from 'components/Form';
import { Form } from 'react-final-form';
import { submitManualQuoteRequest, editOfferRequest } from 'features/LoanNew/actions';
import { offerFields } from 'components/Modals/Offer/config';
import {
  normalizeOfferData,
  parseOfferInitialData,
} from 'components/Modals/Offer/helpers';
import { flatten } from 'ramda';
import { delay, simpleMemoize } from 'lib/utils';
import { validateEmail } from 'utils/validate';
import { hideModal } from 'actions/modalActions';
import { checkLenderDirectoryEmailRequest } from 'features/Directory/pages/LenderDirectory/actions';
import { formDecorator } from 'lib/form';
import { Section } from 'components/Modals/ManualOfferModal/styles';

import { LENDER_SOURCE } from './constants';
import {
  AddLenderForm,
  LenderSelect,
  OfferFields,
  LenderSourceTypeSwitch,
  LenderInfo,
} from './components';

const offerFieldsMap = flatten(offerFields);
const focusOnErrors = formDecorator();

const ManualOfferModal = ({
  loan,
  options,
  offer = {},
  normalizedOptions,
  lender,
  isOfferEdit,
  invitedLenders,
  editOffer,
  createOffer,
  invitedLenderId,
  asyncEmailValidation,
  lenderManagmentUserId
}) => {
  const initialValues = parseOfferInitialData(offer, offerFieldsMap, {
    indexOptions: normalizedOptions.indexOptions,
    fixedOrVariableOptions: normalizedOptions.fixedOrVariableOptions,
    unitOptions: options.unitOptions,
  });

  if (!isOfferEdit) {
    initialValues.lenderSourceType = LENDER_SOURCE.NEW;
  }

  const submissionFunction = (values) => {
    if (isOfferEdit) {
      editOffer({
        values,
        loanId: loan.uuid,
        negotiationPk: offer.negotiation,
        offerId: offer.id,
      });
    } else {
      createOffer({values, loanId: loan.uuid, invitedLenderId: lenderManagmentUserId || invitedLenderId });
    }
  };

  return (
    <Form
      initialValues={initialValues}
      validate={(values) => formValidation(values, lenderManagmentUserId)}
      onSubmit={submissionFunction}
      decorators={[focusOnErrors]}
    >
      {({ handleSubmit }) => (
        <FormWrapper onSubmit={handleSubmit}>
          <ModalWithContentWrapper>
            <ModalHeading>{isOfferEdit ? 'Edit Quote' : 'Add Quote'}</ModalHeading>
            <ModalBody>
              {isOfferEdit ? (
                <LenderInfo lender={lender} />
              ) : (
                !lenderManagmentUserId &&
                <>
                  <LenderSourceTypeSwitch />
                  <Condition when="lenderSourceType" is={LENDER_SOURCE.NEW}>
                    <AddLenderForm
                      disabled={isOfferEdit}
                      validateEmail={asyncEmailValidation}
                    />
                  </Condition>
                  <Condition
                    when="lenderSourceType"
                    is={LENDER_SOURCE.INVESTMENT_SOURCES}
                  >
                    <Section>
                      <LenderSelect lendersList={invitedLenders} />
                    </Section>
                  </Condition>
                </>
              )}
              <OfferFields fields={offerFieldsMap} loan={loan} />
            </ModalBody>
            <ModalActions>
              <Button secondary type="submit">
                {isOfferEdit ? 'Save' : 'Add'} quote
              </Button>
            </ModalActions>
          </ModalWithContentWrapper>
        </FormWrapper>
      )}
    </Form>
  );
};

function mapStateToProps(state) {
  const { lenderDirectory, configuration } = state;

  return {
    lendersArray: lenderDirectory.lendersArray,
    lenders: lenderDirectory.lenders,
    normalizedOptions: configuration.normalizedOptions,
    options: configuration.options,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    asyncEmailValidation: simpleMemoize(async (email) => {
      if (!email) return 'Email is required';

      if (!validateEmail(email)) return 'Invalid email address';

      if (email) {
        const res = await delay(1000).then(() =>
          new Promise((resolve, reject) => {
            dispatch(
              checkLenderDirectoryEmailRequest({
                email,
                resolve,
                reject,
                checkLenderId: true,
              })
            );
          }).then(
            (v) => v,
            ({ error, data }) => {
              // If email validation returns existent lender id, we intercept it
              // and store in modal state. In this particular case email existence is not
              // an error
              if (data?.result !== 'exists') {
                return error;
              }

              return undefined;
            }
          )
        );
        return res;
      }
    }),

    editOffer: ({ values, loanId, negotiationPk, offerId }) => {
      const offerData = {
        ...normalizeOfferData(offerFieldsMap, values),
        message: values.message,
      };

      dispatch(
        editOfferRequest({
          id: loanId,
          negotiation_pk: negotiationPk,
          offer_id: offerId,
          offer: offerData,
        })
      );
      dispatch(hideModal());
    },

    createOffer: ({ values, loanId, invitedLenderId }) => {
      const isLenderNew =
        values.lenderSourceType === LENDER_SOURCE.NEW && !invitedLenderId;

      const requestedLenderId = invitedLenderId || values.selectLender?.lender?.lender;

      const offerData = {
        ...normalizeOfferData(offerFieldsMap, values),
        message: values.message,
      };

      const request = isLenderNew
        ? {
            ...offerData,
            customLenderFirstName: values.customLenderFirstName,
            customLenderLastName: values.customLenderLastName,
            customLenderCompany: values.customLenderCompany,
            customLenderEmail: values.customLenderEmail,
          }
        : {
            ...offerData,
            custom_lender_id: requestedLenderId,
          };

      if (isLenderNew) {
        dispatch(
          submitManualQuoteRequest({
            request,
            loanId,
          })
        );
        dispatch(hideModal());
      } else {
        dispatch(submitManualQuoteRequest({ request, loanId }));
        dispatch(hideModal());
      }
    },
  };
}

const formValidation = (values, lenderManagmentUserId) => {
  const { loanAmount, index, spread, interest, lenderSourceType, selectLender } = values;
  const errors = {};
  const message = "can't be blank";

  if (!loanAmount) {
    errors.loanAmount = message;
  }

  if (!index && !spread && !interest) {
    errors.index = message;
    errors.spread = message;
    errors.interest = message;
  }

  if (!index && spread && !interest) {
    errors.index = message;
  }

  if (index && !spread && !interest) {
    errors.spread = message;
  }

  if(!lenderManagmentUserId){
    if (lenderSourceType === LENDER_SOURCE.NEW) {
      errors.customLenderFirstName = values.customLenderFirstName
        ? undefined
        : 'First name is required';
      errors.customLenderLastName = values.customLenderLastName
        ? undefined
        : 'Last name is required';
      errors.customLenderCompany = values.customLenderCompany
        ? undefined
        : 'Company name is required';
    } else if (lenderSourceType === LENDER_SOURCE.INVESTMENT_SOURCES) {
      errors.selectLender = selectLender?.lender
        ? undefined
        : 'Please choose investment source';
    }
  }

  return errors;
};

export default connect(mapStateToProps, mapDispatchToProps)(ManualOfferModal);

const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
`;
