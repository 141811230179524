import React from 'react';
import { ModalHeader, ModalBody, ModalControls, ModalTitle } from 'ui/Modal/styles';
import { Text } from 'ui/Text';
import { Button } from 'ui/Button';
import styled from 'styled-components/macro';
import { showModal } from 'actions/modalActions';
import { modals } from 'constants/modalTypes';

const BtnReUpload = styled.button`
  border: none;
  padding: 0;
  background-color: transparent;
  outline: none;
  color: #4394ea;
  cursor: pointer;
`;

export const LenderDirectoryAddSuccess = ({ hideModal, fileName, dispatch }) => (
  <div>
    <ModalHeader>
      <ModalTitle>Success</ModalTitle>
    </ModalHeader>
    <ModalBody>
      <Text>
        Your file "{fileName}" was successfully uploaded. Our account manager will contact
        you shortly.
      </Text>
      <Text>
        Not sure you uploaded a right file? You may{' '}
        <BtnReUpload
          onClick={() => {
            dispatch(showModal(modals.LENDER_DIRECTORY_ADD_FORM));
          }}
        >
          re-upload
        </BtnReUpload>{' '}
        your file here.
      </Text>
      <ModalControls>
        <Button secondary onClick={hideModal} ml={2}>
          OK
        </Button>
      </ModalControls>
    </ModalBody>
  </div>
);
