import React from 'react';
import styled from 'styled-components/macro';
import { Text } from 'ui/Text';

const Title = styled(Text)`
  font-weight: bold;
`;

const DisplayTitle = ({ title, required }) => {
  const titleText = typeof title === 'object' ? `${title[0]}+${title[1]}` : title;
  return (
    <Title>
      {titleText} {required && <span title="Required">*</span>}
    </Title>
  );
};

export { DisplayTitle };
