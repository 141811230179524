import {
  indexOptions,
  yearBuildOptions,
  purchaseProcessOptions,
  recourseOptions,
  guarantorOptions,
} from 'utils/fields';

export {
  indexOptions,
  yearBuildOptions,
  purchaseProcessOptions,
  recourseOptions,
  guarantorOptions,
};

export const MAX_FIELD_COUNT = 10;

const stub = new Array(MAX_FIELD_COUNT).fill(null);

export const additionalSourcesStub = stub.map((i, index) => `sourceCustom${index + 1}`);

export const additionalUsesStub = stub.map((i, index) => `useCustom${index + 1}`);

export const sourcesAndUsesFields = {
  loanAmount: {
    field: 'loanAmount',
    type: 'thousand',
    title: 'Loan Amount',
    edit: false,
  },
  currentMortgageBalance: {
    field: 'currentMortgageBalance',
    type: 'thousand',
    title: 'Current Mortgage Balance',
  },
  cashoutToBorrower: {
    field: 'cashoutToBorrower',
    type: 'thousand',
    title: 'Cash-Out to Borrower',
  },
  closingCost: { field: 'closingCost', type: 'thousand', title: 'Closing Cost' },
  other: { field: 'other', type: 'thousand', title: 'Other' },
  equityAmount: { field: 'equityAmount', type: 'thousand', title: 'Borrower Equity' },
  purchasePrice: {
    field: 'purchasePrice',
    type: 'thousand',
    title: 'Property Purchase Price',
  },
  softCost: { field: 'softCost', type: 'thousand', title: 'Soft Cost' },
  hardCost: { field: 'hardCost', type: 'thousand', title: 'Hard Cost' },
  renovationCosts: {
    field: 'renovationCosts',
    type: 'thousand',
    title: 'Renovation Cost',
  },
  sourcesOther: { field: 'sourcesOther', type: 'thousand', title: 'Other' },
  usesOther: { field: 'usesOther', type: 'thousand', title: 'Other' },
};

export const reminderOptions = [
  { value: 60, label: '1 hour' },
  { value: 1440, label: '1 day' },
  { value: 10080, label: '1 week' },
];
