import React from 'react';
import { FormattedNumber } from 'react-intl';
import { PERCENT, CURRENCY } from 'constants/appRelated';

const DisplayNumber = ({ value, percents }) =>
  percents ? (
    <FormattedNumber
      style={PERCENT}
      value={value / 100}
      maximumFractionDigits={5}
      minimumFractionDigits={0}
    />
  ) : (
    <FormattedNumber
      style={CURRENCY}
      currency="USD"
      maximumFractionDigits={5}
      minimumFractionDigits={0}
      value={value}
    />
  );

export { DisplayNumber };
