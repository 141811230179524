import styled, { css } from 'styled-components/macro';

const HtmlInputCommon = styled.input`
  position: relative;
  min-height: 32px;
  width: 100%;
  border-radius: ${(p) => p.theme.radii[1]}px;

  font-size: ${(p) => p.theme.fontSizes[0]}px;
  line-height: ${(p) => p.theme.lineHeights.solid};
  padding: 6px 16px 6px 8px;
  border: 1px solid #cfd0d7;
  color: black;
  outline: none;
  box-sizing: border-box;
  z-index: 1;

  &:focus {
    border-color: ${(p) => p.theme.colors.blues[6]};
  }

  &[disabled] {
    border-color: rgba(28, 51, 83, 0.2);
  }
`;

export const HtmlInput = styled(HtmlInputCommon)`
  ${(p) => {
    switch (p.variant) {
      case 'error':
        return css`
          border-color: ${(p) => p.theme.colors.error};
        `;

      case 'semiRequired':
        return css`
          border-color: #ff9e01;
        `;

      default:
        return '';
    }
  }};
`;

HtmlInput.displayName = 'HtmlInput';
