import types from 'constants/actionTypes';
import immutable from 'object-path-immutable';
import { merge } from 'ramda';

const roomsTypeKeys = {
  loans: 'loan',
  direct: 'personal',
  team: 'team',
};

const initialState = {
  unreadMessages: {},
  isFetched: false,
  isBrowserBad: document && typeof document.currentScript === 'undefined',
  isBadBrowserPageShowed: false,
  notificationsCount: 0,
  loans: [],
  listings: [],
  matchedLoans: [],
  mostPopularLoans: [],
  almostMatchedLoans: [],
  notifications: [],
  unreadMessagesCount: { loan: 0, personal: 0, team: 0 },
  isConfirmSent: false,
  lenderRequirements: {
    requiredDocuments: [],
    verificationComments: [],
  },
};

export default function dashboard(state, action = {}) {
  const currentState = state || initialState;

  const { type, payload } = action;
  switch (type) {
    case types.SETTING_DATA_SUCCESS:
      return merge(currentState, {
        ...payload,
        isFetched: true,
        searchBarEnable: payload.searchBarEnable
      });
    case types.DASHBOARD_DATA_SUCCESS:
      return merge(currentState, {
        ...payload,
        isFetched: true,
        notificationsCount: payload.unreadNotifications,
      });

    case types.GET_CHAT_ROOMS_SUCCESS:
      return merge(currentState, {
        unreadMessages: {
          ...currentState.unreadMessages,
          [roomsTypeKeys[payload.roomsType]]: false,
        },
      });

    case types.EMAIL_CONFIRMATION_SUCCESS: {
      return merge(currentState, { isConfirmSent: true });
    }

    case types.READ_ANSWER_SUCCESS:
    case types.POST_INBOX_ANSWER_SUCCESS:
      return merge(currentState, { unreadNotifications: action.payload.notifications });

    case types.ANSWER_LENDER_COMMENT_SUCCESS:
      return immutable.update(
        currentState,
        'lenderRequirements.verificationComments',
        (verificationComments) =>
          verificationComments.filter(({ id }) => id !== action.payload.id)
      );

    case types.UPLOAD_LENDER_DOCUMENT_SUCCESS:
      return immutable.set(
        state,
        'lenderRequirements.requiredDocuments',
        state.lenderRequirements.requiredDocuments.filter(({ id }) => id !== payload)
      );

    case types.SUBMIT_LOAN_FORM_SUCCESS:
      return immutable.update(currentState, 'pendingLoanRequestsCount', (current) =>
        payload.isLoanNew ? current + 1 : current
      );

    case types.SET_NOTIFICATION_COUNT: {
      return { ...state, notificationsCount: action.payload.count };
    }

    case types.LOGOUT_SUCCESS: {
      return initialState;
    }

    case types.RESET_NOTIFICATIONS: {
      return {
        ...state,
        notifications: [],
        nextNotifications: null,
      };
    }

    case types.GET_LOANS_DASHBOARD_SUCCESS:
      return { ...state, ...action.payload };
    case types.SET_BADBROWSER_PAGE_SHOWED: {
      return { ...state, isBadBrowserPageShowed: true };
    }
    default:
      return currentState;
  }
}
