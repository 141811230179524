import { put, takeLatest, call } from 'redux-saga/effects';
import types from 'constants/actionTypes';
import * as API from 'api';
import { getError } from 'sagas/utils';
import { decamelizeKeys } from 'humps';
import { push } from 'connected-react-router';

import { loanDataRequest } from 'features/LoanNew/actions';

import {
  getDealRoomSuccess,
  getDealRoomFailure,
  updateDealRoomDocumentStateSuccess,
  updateDealRoomDocumentStateFailure,
  createDealRoomChecklistSuccess,
  createDealRoomChecklistFailure,
  editDealRoomChecklistSuccess,
  editDealRoomChecklistFailure,
  attachDealRoomFilesSuccess,
  attachDealRoomFilesFailure,
  setDealroomClosingDateSuccess,
  setDealroomClosingDateFailure,
  reUploadDealRoomFileSuccess,
  reUploadDealRoomFileFailure,
  markDealDocumentCompleteSuccess,
  markDealDocumentCompleteFailure,
  dealDocumentsDownloadSuccess,
  dealDocumentsDownloadFailure,
  deleteDealRoomFileSuccess,
  deleteDealRoomFileFailure,
  restoreDealRoomFileSuccess,
  restoreDealRoomFileFailure,
  deleteDealDocumentSuccess,
  deleteDealDocumentFailure,
  restoreDealDocumentSuccess,
  restoreDealDocumentFailure,
  markDealDocumentIncompleteSuccess,
  markDealDocumentIncompleteFailure,
  createDealRoomWithDocumentsSuccess,
  createDealRoomWithDocumentsFailure,
  getFilesFailure,
  getFilesSuccess,
  addFilesSuccess,
  addFilesFailure,
  setUpdateFileSuccess,
  setUpdateFileFailure,
  setDeleteFileSuccess,
  setDeleteFileFailure,
  setUploadSignedFileSuccess,
  setUploadSignedFileFailure,
  setReviewFileSuccess,
  setReviewFileFailure,
  acceptDocsConfidentialityAgreementSuccess,
  acceptDocsConfidentialityAgreementFailure
} from './actions';

function* getRoom(action) {
  try {
    const { roomId, type } = action.payload;

    const {
      data: { response },
    } = yield call(API.axiosApi.get, `/deal-rooms/${roomId}?state=${type}`);

    yield put(getDealRoomSuccess(response));
  } catch (e) {
    console.error(e);
    yield put(getDealRoomFailure({ message: getError(e) }));
  }
}

function* updateDealRoomDocumentState(action) {
  try {
    const { state, rejectionReason, docId, requestId, checklistId } = action.payload;

    yield call(API.axiosApi.patch, `/deal-rooms/checklists/requests/documents/${docId}`, {
      state,
      rejected_reason: rejectionReason,
    });

    yield put(
      updateDealRoomDocumentStateSuccess({
        state,
        rejectionReason,
        docId,
        requestId,
        checklistId,
      })
    );
  } catch (e) {
    yield put(updateDealRoomDocumentStateFailure({ message: getError(e) }));
  }
}

function* createDealRoomChecklistRequest(action) {
  try {
    const { name, requests, loanId, roomId } = action.payload;
    const {
      data: { response },
    } = yield call(API.axiosApi.post, `/deal-rooms/${roomId}/checklists`, {
      name,
      requests: requests.map((item) => decamelizeKeys(item)),
      loanId,
    });

    yield put(createDealRoomChecklistSuccess(response));
  } catch (e) {
    yield put(createDealRoomChecklistFailure({ message: getError(e) }));
  }
}

function* editDealRoomChecklist(action) {
  try {
    const { name, requests, checklistId, deletedIds } = action.payload;
    const {
      data: { response },
    } = yield call(
      API.axiosApi.patch,
      `/deal-rooms/checklists/${checklistId}`,
      decamelizeKeys({
        name,
        requests,
        deletedIds,
      })
    );
    yield put(editDealRoomChecklistSuccess(response));
  } catch (e) {
    yield put(editDealRoomChecklistFailure({ message: getError(e) }));
  }
}

function* attachDealRoomFiles(action) {
  try {
    const { formData, requestId, checklistId } = action.payload;
    const {
      data: { response },
    } = yield call(
      API.axiosApi.post,
      `/deal-rooms/checklists/requests/${requestId}/documents`,
      formData
    );
    yield put(
      attachDealRoomFilesSuccess({
        documents: response.documents,
        requestId,
        checklistId,
      })
    );
  } catch (e) {
    yield put(attachDealRoomFilesFailure({ message: getError(e) }));
  }
}

function* reUploadDealRoomFile(action) {
  try {
    const { formData, docId, requestId, checklistId } = action.payload;
    const {
      data: { response },
    } = yield call(
      API.axiosApi.post,
      `/deal-rooms/checklists/requests/documents/${docId}`,
      formData
    );
    yield put(
      reUploadDealRoomFileSuccess({
        document: response,
        docId,
        requestId,
        checklistId,
      })
    );
  } catch (e) {
    yield put(reUploadDealRoomFileFailure({ message: getError(e) }));
  }
}

function* setClosingDate(action) {
  try {
    const { date, roomId } = action.payload;
    const {
      data: { response },
    } = yield call(API.axiosApi.patch, `/deal-rooms/${roomId}`, { closing_date: date });

    yield put(setDealroomClosingDateSuccess(response));
  } catch (e) {
    yield put(setDealroomClosingDateFailure({ message: getError(e) }));
  }
}

function* markDealDocumentRequestComplete(action) {
  try {
    const { requestId, checklistId } = action.payload;
    const responseCompleted = yield call(
      API.axiosApi.patch,
      `/deal-rooms/checklists/requests/${requestId}/completed`,
      {}
    );

    yield put(markDealDocumentCompleteSuccess({ requestId, checklistId,  dealRoomData: responseCompleted.data.response}));
  } catch (e) {
    yield put(markDealDocumentCompleteFailure({ message: getError(e) }));
  }
}

function* dealDocumentsDownloadRequest(action) {
  try {
    const { roomId } = action.payload;
    yield call(API.axiosApi.post, `/deal-rooms/${roomId}/send_documents`, {});
    yield put(dealDocumentsDownloadSuccess());
  } catch (e) {
    yield put(dealDocumentsDownloadFailure({ message: getError(e) }));
  }
}

function* deleteDealRoomFileRequest(action) {
  try {
    const { docId, requestId, checklistId } = action.payload;
    const {
      data: { response },
    } = yield call(
      API.axiosApi.delete,
      `/deal-rooms/checklists/requests/documents/${docId}`
    );
    yield put(
      deleteDealRoomFileSuccess({ document: response, docId, requestId, checklistId })
    );
  } catch (e) {
    yield put(deleteDealRoomFileFailure({ message: getError(e) }));
  }
}

function* restoreDealRoomFileRequest(action) {
  try {
    const { docId, requestId, checklistId } = action.payload;
    const {
      data: { response },
    } = yield call(
      API.axiosApi.patch,
      `/deal-rooms/checklists/requests/documents/${docId}/restore`
    );
    yield put(
      restoreDealRoomFileSuccess({ document: response, docId, requestId, checklistId })
    );
  } catch (e) {
    yield put(restoreDealRoomFileFailure({ message: getError(e) }));
  }
}

function* deleteDealDocumentRequest(action) {
  try {
    const { requestId, checklistId } = action.payload;
    const {
      data: { response },
    } = yield call(API.axiosApi.delete, `/deal-rooms/checklists/requests/${requestId}`);
    yield put(
      deleteDealDocumentSuccess({ requestId, checklistId, deleted: response.deleted })
    );
  } catch (e) {
    yield put(deleteDealDocumentFailure({ message: getError(e) }));
  }
}

function* restoreDealDocumentRequest(action) {
  try {
    const { requestId, checklistId } = action.payload;
    const {
      data: { response },
    } = yield call(
      API.axiosApi.patch,
      `/deal-rooms/checklists/requests/${requestId}/restore`
    );
    yield put(
      restoreDealDocumentSuccess({ requestId, checklistId, deleted: response.deleted })
    );
  } catch (e) {
    yield put(restoreDealDocumentFailure({ message: getError(e) }));
  }
}

function* markDealDocumentIncompleteRequest(action) {
  try {
    const { requestId, checklistId } = action.payload;
    const responseCompleted = yield call(
      API.axiosApi.patch,
      `/deal-rooms/checklists/requests/${requestId}/incomplete`,
      {}
    );

    yield put(markDealDocumentIncompleteSuccess({ requestId, checklistId,  dealRoomData: responseCompleted.data.response}));
  } catch (e) {
    yield put(markDealDocumentIncompleteFailure({ message: getError(e) }));
  }
}

function* createDealRoomWithDocuments(action) {
  try {
    const { documents, withLoanUpdate } = action.payload;
    const {
      data: { response },
    } = yield call(API.axiosApi.post, '/deal-rooms', { doc_requests: documents });
    yield put(
      push(`/dashboard/property-details/${response.loanId}/deal-room/${response.id}/all`)
    );
    yield put(createDealRoomWithDocumentsSuccess());

    if (withLoanUpdate) {
      yield put(loanDataRequest({ id: response.loanId }));
    }
  } catch (e) {
    yield put(createDealRoomWithDocumentsFailure({ message: getError(e) }));
  }
}


function* getDocumentsSignature(action){
  try {
    const { formData, roomId } = action.payload;
    const {
      data: { response },
    } = yield call( API.axiosApi.get, `deal-rooms/${roomId}/sign-requests`);

    yield put(
      getFilesSuccess({
        files: response.results
      })
    );
  } catch (e) {
    console.error(e);
    yield put(getFilesFailure({ message: getError(e) }));
  }
}

function* uploadSignedDocumentsSignature(action){
  try {
    const { formData, roomId, updatedFile } = action.payload;
    const {
      data: { response },
    } = yield call(
      API.axiosApi.post,
      `deal-rooms/${roomId}/sign-requests/${updatedFile.id}/sign`,
      formData
    );

    yield put(setUploadSignedFileSuccess({response, parentFileId: updatedFile.id}));

  } catch (e) {
    console.error(e);
    yield put(setUploadSignedFileFailure({ message: getError(e) }));
  }
}

function* createNewDocumentsSignature(action){
  try {
    const {formData, roomId } = action.payload;
    const {
      data: { response },
    } = yield call(
      API.axiosApi.post,
      `deal-rooms/${roomId}/sign-requests`,
      formData
    );

    yield put(
      addFilesSuccess({
        files: response.requests
      })
    );
  } catch (e) {
    console.error(e);
    yield put(addFilesFailure({ message: getError(e) }));
  }
}

function* setUpdateDocumentsSignature(action){
  try {
    const { updatedFile, roomId } = action.payload;

    const {
      data: { response },
    } = yield call(
      API.axiosApi.patch,
      `deal-rooms/${roomId}/sign-requests/${updatedFile.id}`,
      {name: updatedFile.name}
    );

    yield put(setUpdateFileSuccess(response));

  } catch (e) {
    console.error(e);
    yield put(setUpdateFileFailure({ message: getError(e) }));
  }
}

function* setDeleteDocumentsSignature(action){
  try {
    const { deletedFile, roomId } = action.payload;

    yield call(
      API.axiosApi.delete, 
      `deal-rooms/${roomId}/sign-requests/${deletedFile[0].id}`
    );
    yield put(setDeleteFileSuccess({fileId: deletedFile[0].id})
    
    );
  } catch (e) {
    console.error(e);
    yield put(setDeleteFileFailure({ message: getError(e) }));
  }
}

function* setReviewDocumentsSignature(action){
  try {
    const { updatedFile, roomId, state, rejected_reason } = action.payload;
    const {
      data: { response },
    } = yield call(
      API.axiosApi.patch, 
      `deal-rooms/${roomId}/sign-requests/${updatedFile.id}/review`,
      rejected_reason ? {state, rejected_reason} : {state}
    );
  
    yield put(setReviewFileSuccess({response, fileId: updatedFile.id}));
    
  } catch (e) {
    console.error(e);
    yield put(setReviewFileFailure({ message: getError(e) }));
  }
}

function* acceptDocsConfidentialityAgreement(action) {
  try {
    const {
      loanId,
      documentUrl,
      isDownloadAll,
      roomId,
    } = action.payload;
    
    const {
      data: { response },
    } = yield call(API.axiosApi.post, `loans/${loanId}/agreements`, {});
    yield put(acceptDocsConfidentialityAgreementSuccess({response}));

    if(isDownloadAll){
      yield call(API.axiosApi.post, `/deal-rooms/${roomId}/send_documents`, {});
      yield put(dealDocumentsDownloadSuccess());
    }else{
      window.open(documentUrl, "_blank");
    }

  } catch (e) {
    yield put(acceptDocsConfidentialityAgreementFailure({ message: getError(e) }));
  }
}

export default [
  takeLatest(types.GET_DEAL_ROOM_REQUEST, getRoom),
  takeLatest(types.UPDATE_DEAL_ROOM_DOCUMENT_STATE_REQUEST, updateDealRoomDocumentState),
  takeLatest(types.CREATE_DEAL_ROOM_CHECKLIST_REQUEST, createDealRoomChecklistRequest),
  takeLatest(types.EDIT_DEAL_ROOM_CHECKLIST_REQUEST, editDealRoomChecklist),
  takeLatest(types.ATTACH_DEAL_ROOM_FILES_REQUEST, attachDealRoomFiles),
  takeLatest(types.RE_UPLOAD_DEAL_ROOM_FILE_REQUEST, reUploadDealRoomFile),
  takeLatest(types.SET_DEALROOM_CLOSING_DATE_REQUEST, setClosingDate),
  takeLatest(types.MARK_DEAL_DOCUMENT_COMPLETE_REQUEST, markDealDocumentRequestComplete),
  takeLatest(types.DEAL_DOCUMENTS_DOWNLOAD_REQUEST, dealDocumentsDownloadRequest),
  takeLatest(types.DELETE_DEAL_ROOM_FILE_REQUEST, deleteDealRoomFileRequest),
  takeLatest(types.RESTORE_DEAL_ROOM_FILE_REQUEST, restoreDealRoomFileRequest),
  takeLatest(types.DELETE_DEAL_DOCUMENT_REQUEST, deleteDealDocumentRequest),
  takeLatest(types.RESTORE_DEAL_DOCUMENT_REQUEST, restoreDealDocumentRequest),
  takeLatest(types.SAVE_FILES_REQUEST, createNewDocumentsSignature),
  takeLatest(types.GET_FILES_REQUEST, getDocumentsSignature),
  takeLatest(types.SET_UPDATE_FILE_REQUEST, setUpdateDocumentsSignature),
  takeLatest(types.SET_DELETE_FILE_REQUEST, setDeleteDocumentsSignature),
  takeLatest(types.SET_UPLOAD_SIGNED_FILE_REQUEST, uploadSignedDocumentsSignature),
  takeLatest(types.SET_REVIEW_FILE_REQUEST, setReviewDocumentsSignature),

  takeLatest(
    types.MARK_DEAL_DOCUMENT_INCOMPLETE_REQUEST,
    markDealDocumentIncompleteRequest
  ),
  takeLatest(types.CREATE_DEAL_ROOM_WITH_DOCUMENTS_REQUEST, createDealRoomWithDocuments),
  takeLatest(types.ACCEPT_DOCS_CONFIDENTIALITY_AGREEMENT_REQUEST, acceptDocsConfidentialityAgreement),
];
