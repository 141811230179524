import { put, takeLatest, call } from 'redux-saga/effects';
import types from 'constants/actionTypes';
import * as API from 'api';
import { decamelizeKeys } from 'humps';
import { push } from 'connected-react-router';
import { downloadBlobFile } from 'lib/browser';
import { modals, INFO_MODAL_TYPE } from 'constants/modalTypes';
import { showModal } from 'actions/modalActions';
import { getError } from 'sagas/utils';

import {
  getListingSuccess,
  getListingFailure,
  submitQuoteSuccess,
  submitQuoteFailure,
  editQuoteSuccess,
  editQuoteFailure,
  applyForFinancingSuccess,
  applyForFinancingFailure,
  exportQuotesSuccess,
  exportQuotesFailure,
} from './actions';

function* getListing(action) {
  const { payload } = action;
  try {
    const {
      data: { response },
    } = yield call(API.axiosApi.get, `listings/${payload.id}`);
    yield put(getListingSuccess(response));
  } catch (e) {
    console.log(e);
    yield put(getListingFailure({ message: getError(e) }));
  }
}

function* submitQuot(action) {
  const {
    payload: { listingPk, request },
  } = action;
  try {
    const {
      data: { response },
    } = yield call(
      API.axiosApi.post,
      `listings/${listingPk}/prequalifications`,
      decamelizeKeys(request)
    );

    yield put(submitQuoteSuccess(response));
  } catch (e) {
    console.log(e);
    yield put(submitQuoteFailure());
  }
}

function* editQuot(action) {
  const {
    payload: { listingPk, request, quoteId },
  } = action;
  try {
    const {
      data: { response },
    } = yield call(
      API.axiosApi.patch,
      `listings/${listingPk}/prequalifications/${quoteId}`,
      decamelizeKeys(request)
    );

    yield put(editQuoteSuccess(response));
  } catch (e) {
    console.log(e);
    yield put(editQuoteFailure());
  }
}

function* applyForFinancing(action) {
  const {
    payload: { listingPk },
  } = action;

  try {
    const {
      data: { response },
    } = yield call(API.axiosApi.post, `/listings/${listingPk}/create_loan`);
    yield put(push(`/dashboard/loan-creation/${response.loanPk}`));
    yield put(applyForFinancingSuccess());
  } catch (e) {
    const loanId = e?.response?.data?.error?.loanId;
    if (loanId) {
      yield put(
        showModal(modals.INFO_MODAL, {
          infoType: INFO_MODAL_TYPE.WARNING,
          title: 'You have already created Loan request from this listing.',
          buttonText: 'View Loan',
          size: 'sm',
          onButtonClick: (dispatch) => {
            dispatch(push(`/dashboard/property-details/${loanId}`));
          },
        })
      );
    }
    yield put(applyForFinancingFailure());
  }
}

function* exportQuotes(action) {
  try {
    const { id } = action.payload;

    const { data, headers } = yield call(
      API.axiosApiRaw.get,
      `/listings/${id}/export_pre_qualifications`,
      {
        responseType: 'blob',
      }
    );

    downloadBlobFile(data, headers['content-disposition'].split('=')[1]);
    yield put(exportQuotesSuccess());
  } catch (e) {
    yield put(exportQuotesFailure({ message: getError(e) }));
  }
}

export default [
  takeLatest(types.GET_LISTING_REQUEST, getListing),
  takeLatest(types.SUBMIT_QUOTE_REQUEST, submitQuot),
  takeLatest(types.EDIT_QUOTE_REQUEST, editQuot),
  takeLatest(types.APPLY_FOR_FINANCING_REQUEST, applyForFinancing),
  takeLatest(types.EXPORT_QUOTES_REQUEST, exportQuotes),
];
