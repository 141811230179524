import React from 'react';
import styled from 'styled-components/macro';

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;

  & > div {
    width: calc(50% - 50px);

    &:first-child,
    &:nth-child(odd) {
      margin-right: 50px;

      &::after {
        content: '';
        position: absolute;
        width: 1px;
        top: 0;
        bottom: 0;
        right: -50px;
        background-color: #e8e9ed;
      }
    }

    &:nth-child(even) {
      margin-left: 50px;
    }
  }

  @media (max-width: ${(p) => p.theme.breakpoints[1]}px) {
    flex-direction: column;

    & > div {
      width: 100%;

      &:first-child,
      &:nth-child(odd),
      &:nth-child(even) {
        margin: 0;
      }

      &:first-child,
      &:nth-child(odd) {
        &::after {
          content: none;
        }
      }
    }
  }
`;

export const FieldSet = ({ children, variant }) => (
  <Wrapper variant={variant}>{children}</Wrapper>
);
