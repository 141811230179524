import React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components/macro';
import { FormattedNumber } from 'react-intl';
import { makeLoanTerm, getLoanTerm, getOptionLabel } from 'lib/loan';
import { merge } from 'ramda';
import * as options from 'utils/fields';
import { ModalHeader, ModalBody, ModalTitle, ModalControls } from 'ui/Modal/styles';
import { Text } from 'ui/Text';
import { Button } from 'ui/Button';
import { modals } from 'constants/modalTypes';
import { showModal } from 'actions/modalActions';

const fields = [
  { field: 'loanAmount', type: 2, title: 'Loan amount' },
  { field: 'loanTerm', type: 4, title: 'Term' },
  {
    field: 'interest',
    type: 2,
    title: 'Rate',
    percents: true,
  },
  {
    field: ['index', 'spread'],
    type: 7,
    title: ['Index', 'Spread'],
    options: { index: options.indexOptions },
  },
  {
    field: 'ltv',
    type: 2,
    title: 'LTV',
    percents: true,
  },
  {
    field: 'ltc',
    type: 2,
    title: 'LTC',
    percents: true,
  },
  {
    field: 'debtYield',
    type: 2,
    title: 'Debt Yield',
    percents: true,
  },
  { field: 'interestOnly', type: 1, title: 'Interest Only' },
  { field: 'amortization', type: 1, title: 'Amortization' },
  { field: 'recourse', type: 3, title: 'Recourse' },
  {
    field: 'lenderFee',
    type: 2,
    title: 'Lender Fee',
    percents: true,
  },
  {
    field: 'exitFee',
    type: 2,
    title: 'Exit Fee',
    percents: true,
  },
  {
    field: 'spread',
    type: 5,
    title: 'Spread',
    percents: true,
    hidden: true,
  },
  {
    field: 'index',
    type: 6,
    title: 'Index',
    options: options.indexOptions,
    hidden: true,
  },
];

export const FinalOffer = ({ loan, negotiation, negotiation: { offers }, hideModal }) => {
  const dispatch = useDispatch();
  const offer = merge(negotiation.offers[offers.length - 1], {
    exitFee: loan.exitFee,
    interestOnly: loan.interestOnly,
    interestOnlyUnit: loan.interestOnlyUnit,
  });

  const handleCancelOfferClick = () => {
    dispatch(
      showModal(modals.CANCEL_OFFER, {
        id: loan.id,
        negotiation_pk: negotiation.pk,
        size: 'sm',
      })
    );
  };

  return (
    <div>
      <ModalHeader>
        <ModalTitle>Final quote</ModalTitle>
      </ModalHeader>
      <ModalBody>
        <Wrapper>
          {fields.map((field, i) => (
            <OfferField key={field.field} field={field} offer={offer} i={i} />
          ))}
        </Wrapper>
        <ModalControls alignCenter>
          <Button onClick={handleCancelOfferClick}>Cancel Quote</Button>
        </ModalControls>
      </ModalBody>
    </div>
  );
};

const OfferField = ({ field, i, offer }) => {
  let cell;

  if (field.hidden) {
    return null;
  }

  switch (field.type) {
    case 1:
      cell = (
        <Cell key={i}>
          <Text>{field.title}</Text>
          <Data>
            {offer[field.field]
              ? getLoanTerm(offer[field.field], offer[`${field.field}Unit`])
              : 'N/A'}
          </Data>
        </Cell>
      );
      return cell;
    case 2: {
      const displayValue = offer[field.field];
      cell = (
        <Cell key={i}>
          <Text>{field.title}</Text>
          <Data>
            {displayValue ? (
              <FormattedNumber
                style={field.percents ? 'percent' : 'currency'}
                currency="USD"
                maximumFractionDigits={field.percents ? 5 : 0}
                minimumFractionDigits={0}
                value={field.percents ? displayValue / 100 : displayValue}
              />
            ) : (
              'N/A'
            )}
          </Data>
        </Cell>
      );
      return cell;
    }
    case 3:
      cell = (
        <Cell key={i}>
          <Text>{field.title}</Text>
          <Data>{offer[field.field] ? 'Yes' : 'No'}</Data>
        </Cell>
      );
      return cell;
    case 4:
      cell = (
        <Cell key={i}>
          <Text>{field.title}</Text>
          <Data>
            {offer[field.field]
              ? offer.loanTermUnit === 0
                ? makeLoanTerm(offer[field.field])
                : `${offer[field.field]} years`
              : 'N/A'}
          </Data>
        </Cell>
      );
      return cell;
    case 5:
      return (
        <Cell key={i}>
          <Text>{field.title}</Text>
          <Data>{offer[field.field] || 'N/A'}</Data>
        </Cell>
      );
    case 6:
      const value = offer[field.field];
      return (
        <Cell key={i}>
          <Text>{field.title}</Text>
          <Data>{value ? getOptionLabel(field.options, value) : 'N/A'}</Data>
        </Cell>
      );

    case 7:
      const [field1, field2] = field.field;
      const [title1, title2] = field.title;
      const value1 = offer[field1];
      const value2 = offer[field2];
      return (
        <Cell key={`${field1}-${field2}`}>
          <Text>
            {title1}, {title2}
          </Text>
          <Data>
            {value1 ? getOptionLabel(field.options[field1], value1) : 'N/A'},{' '}
            {value2 || 'N/A'}
          </Data>
        </Cell>
      );
    default:
      return null;
  }
};

const Wrapper = styled.div`
  color: #000;
  text-align: center;
`;

const Cell = styled.div`
  display: flex;
  padding: 10px 0;

  > div,
  p {
    width: 50%;
  }
`;

const Data = styled(Text)`
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
