import React, { useState } from 'react';
import styled from 'styled-components';
import { Text } from 'ui/Text';
import { BucketIcon } from 'components/SvgIcons';

export const DeletedSections = ({
  requests,
  checklistId,
  restoreDeletedDealDocument,
  userType,
}) => {
  const [sectionOpened, toggleSection] = useState(false);

  return (
    <Wrapper>
      <TitleBtn
        opened={sectionOpened}
        onClick={() => {
          toggleSection(!sectionOpened);
        }}
      >
        + {requests.length} deleted folder{requests.length === 1 ? '' : 's'}
      </TitleBtn>
      {sectionOpened && (
        <Requests>
          {requests.map((request) => (
            <RequestItem key={request.id}>
              <BucketIcon width="10" height="11" />
              {request.name}{' '}
              {userType.lender && (
                <>
                  (
                  <button
                    type="button"
                    onClick={() => {
                      restoreDeletedDealDocument({ requestId: request.id, checklistId });
                    }}
                  >
                    RESTORE
                  </button>
                  )
                </>
              )}
            </RequestItem>
          ))}
        </Requests>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin-bottom: 24px;
`;

const TitleBtn = styled.button`
  display: flex;
  align-items: center;
  color: #979797;
  font-size: 14px;
  line-height: 16px;
  border: none;
  padding: 0;
  margin: 0;
  background-color: #f8f8fa;
  outline: none;
  cursor: pointer;

  &::after {
    content: ${(p) =>
      `url(/images/icons/ico_chevron_small_${p.opened ? 'up' : 'down'}.svg)`};
    margin-left: 6px;
  }
`;

const Requests = styled.div`
  margin-top: 16px;
  padding-left: 24px;
`;

const RequestItem = styled(Text)`
  display: flex;
  align-items: center;
  color: #979797;
  margin-bottom: 8px;

  button {
    font-size: 12px;
    color: #4394ea;
    border: none;
    padding: 0;
    margin: 0;
    background-color: #f8f8fa;
    outline: none;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  svg {
    margin-right: 6px;
  }
`;
