import { all } from 'redux-saga/effects';
import user from 'sagas/user';
import dashboard from 'features/Dashboard/saga';
import socket from 'sagas/socket';
import inbox from 'features/Inbox/saga';
import profile from 'features/Profile/saga';
import dealRooms from 'features/DealRooms/saga';
import dealRoom from 'features/DealRoom/saga';
import chatWidget from 'containers/ChatWidget/saga';
import loanActivityWidget from 'containers/LoanActivityWidget/saga';
import marketplace from 'features/Marketplace/saga';
import finishRegistration from 'features/FinishRegistration/saga';
import global from 'sagas/global';
import offers from 'features/Offers/saga';
import myLoans from 'features/MyLoans/saga';
import myMatchingLoans from 'features/MyMatchingLoans/saga';
import standaloneLoan from 'features/StandaloneLoan/saga';
import chat from 'features/Chat/saga';
import lenderDirectory from 'features/Directory/pages/LenderDirectory/saga';
import contactDirectory from 'features/Directory/pages/ContactsDirectory/saga';
import clientsDirectory from 'features/Directory/pages/ClientsDirectory/saga';
import loanCreation from 'features/LoanCreation/saga';
import loanNew from 'features/LoanNew/saga';
import listings from 'features/Listings/saga';
import listing from 'containers/ListingWrapper/saga';
import sendToLenders from 'features/LoanSendToLenders/saga';
import loanLenderManagement from 'features/LoanLenderManagement/saga';
import teamMemberRegistration from 'features/TeamMemberRegistration/saga';
import userDetails from 'containers/UserDetails/saga';
import recoveryCodes from 'features/RecoveryCodes/saga';
import twoFactorAuthentication from 'features/TwoFactorAuthentication/saga';
import confirmAuth from 'features/ConfirmAuth/saga';
import recoveryLogin from 'features/RecoveryLogin/saga';
import dashboardActionsItems from 'features/DashboardActionItems/saga';
import loanTermsheets from 'features/LoanTermsheets/saga';
import marketAnalytics from 'features/Analytics/pages/MarketAnalytics/saga';
import marketAnalyticsLoans from 'features/Analytics/pages/MarketAnalyticsLoans/saga';
import marketAnalyticsLenders from 'features/Analytics/pages/MarketAnalyticsLenders/saga';
import teamAnalytics from 'features/Analytics/pages/TeamAnalytics/saga';
import lenderCompany from 'features/LenderCompany/saga';
import oAuth from 'features/OAuth/saga';
import myTasks from 'containers/MyTasksWrapper/saga';
import globalSearch from 'features/GlobalSearch/saga';

import shared from './shared';
import modal from './modal';

export default function* root() {
  yield all([
    ...user,
    ...dashboard,
    ...socket,
    ...inbox,
    ...profile,
    ...dealRooms,
    ...dealRoom,
    ...marketplace,
    ...finishRegistration,
    ...global,
    ...offers,
    ...myLoans,
    ...myMatchingLoans,
    ...shared,
    ...standaloneLoan,
    ...chat,
    ...lenderDirectory,
    ...contactDirectory,
    ...loanCreation,
    ...loanNew,
    ...listings,
    ...listing,
    ...sendToLenders,
    ...loanLenderManagement,
    ...teamMemberRegistration,
    ...userDetails,
    ...modal,
    ...clientsDirectory,
    ...recoveryCodes,
    ...twoFactorAuthentication,
    ...confirmAuth,
    ...recoveryLogin,
    ...dashboardActionsItems,
    ...loanTermsheets,
    ...marketAnalytics,
    ...marketAnalyticsLoans,
    ...chatWidget,
    ...marketAnalyticsLenders,
    ...teamAnalytics,
    ...lenderCompany,
    ...oAuth,
    ...loanActivityWidget,
    ...myTasks,
    ...globalSearch,
  ]);
}
