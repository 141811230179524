import React from 'react';
import styled from 'styled-components/macro';
import { PencilIcon } from 'components/SvgIcons';
import { Text } from 'ui/Text';

export const ChecklistRequestHeader = ({
  name,
  editChecklist,
  userType,
  checklistId,
  highlightsName,
}) => {
  return(
    <Wrapper>
      {highlightsName?.name === name ? 
        <Title dangerouslySetInnerHTML={{__html: highlightsName.highlight}}/>
        :
        <Title>{name}</Title>
      }
      {userType.lender && (
        <EditBtn
          onClick={() => {
            editChecklist(checklistId);
          }}
        >
          <PencilIcon color="#778598" />
          EDIT CHECKLIST
        </EditBtn>
      )}
    </Wrapper>
  )
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;

const Title = styled(Text)`
  font-size: 16px;
  line-height: 24px;
`;

const EditBtn = styled.button`
  display: flex;
  align-items: center;
  margin: 0 0 0 auto;
  padding: 0;
  outline: none;
  border: none;
  background-color: #fafafb;
  color: #778598;
  font-size: 10px;
  line-height: 16px;
  cursor: pointer;

  &:hover {
    color: #4394ea;

    svg path {
      stroke: #4394ea;
    }
  }

  svg {
    margin-right: 8px;
  }
`;
