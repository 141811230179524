import React from 'react';
import { Section, SectionTitle } from 'styles/loan';
import styled from 'styled-components/macro';
import { LOAN_STATUS } from 'constants/appRelated';

const Wrapper = styled(Section)`
  border-radius: 4px;
  border: 2px solid #ff9e01;
`;

const Text = styled.p`
  font-size: 14px;
  line-height: 21px;
  color: #2f243a;
  margin: 0;
`;

export const RejectReason = ({
  verificationFailedReason,
  rejectedTermsheets,
  status,
  userType,
}) => {
  if (status === LOAN_STATUS.VERIFICATION_FAILED && verificationFailedReason) {
    return (
      <Wrapper>
        <SectionTitle>Loan verification failed reason</SectionTitle>
        <Text dangerouslySetInnerHTML={{ __html: verificationFailedReason }} />
      </Wrapper>
    );
  }

  if (userType.lender && rejectedTermsheets.length) {
    return (
      <Wrapper>
        <SectionTitle>Term Sheet Return Reason</SectionTitle>
        <Text
          dangerouslySetInnerHTML={{ __html: rejectedTermsheets[0].rejectionReason }}
        />
      </Wrapper>
    );
  }

  return null;
};
