export const FILTERS_TYPES = {
  ALL: 'all',
  NEW: 'new',
  APPROVED: 'approved',
  COMPLETED: 'completed',
  NOTCOMPLETED: 'notcompleted',
  REJECTED: 'rejected',
};

export const DOCUMENT_STATE = {
  NEW: 'new',
  ACCEPTED: 'accepted',
  REJECTED: 'rejected',
};

export const MOBILE_SECTION_VIEW = {
  INFO: 'info',
  CHECKLISTS: 'checklists',
};
