import React from 'react';
import styled, { css } from 'styled-components';
import { format, isPast } from 'date-fns';
import { Text } from 'ui/Text';

export const DueToDate = ({ closingDate }) => (
  <Wrapper isPast={isPast(new Date(closingDate))}>
    DUE BY <span>{format(new Date(closingDate), 'dd MMM')}</span>
  </Wrapper>
);

const Wrapper = styled(Text)`
  display: flex;
  align-items: center;
  margin-left: auto;
  color: #778598;
  font-size: 10px;
  line-height: 10px;

  span {
    color: #44af69;
    font-size: 12px;
    margin-left: 5px;
    line-height: 12px;
  }

  ${(p) =>
    p.isPast &&
    css`
      span {
        color: #e32019;
      }
    `}
`;
