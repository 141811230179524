import React from 'react';
import { Textarea as FormTextarea } from 'ui/Textarea';

export const Textarea = ({
  input: { name, onChange, value, ...restInput },
  label,
  withUnit,
  meta: { touched, error },
  semiRequired,
  ...rest
}) => (
  <FormTextarea
    name={name}
    label={label}
    error={touched && error}
    onChange={onChange}
    value={value}
    semiRequired={semiRequired}
    {...rest}
    {...restInput}
  />
);
