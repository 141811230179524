import React from 'react';
import styled, { keyframes } from 'styled-components/macro';

const settings = {
  normal: {
    width: '65px',
    height: '65px',
    viewBox: '0 0 66 66',
    strokeWidth: '6',
    cx: '33',
    cy: '33',
    r: 30,
  },
  small: {
    width: '20px',
    height: '20px',
    viewBox: '0 0 20 20',
    strokeWidth: '2',
    cx: '10',
    cy: '10',
    r: 8,
  },
};

export const SpinnerItem = ({ variant }) => {
  const config = settings[variant] || settings.normal;
  return (
    <Item>
      <Loader circleLength={2 * Math.PI * config.r}>
        <svg
          className="spinnerItem"
          width={config.width}
          height={config.height}
          viewBox={config.viewBox}
        >
          <circle
            className="path"
            fill="none"
            strokeWidth={config.strokeWidth}
            strokeLinecap="round"
            stroke="#ff9b12"
            cx={config.cx}
            cy={config.cy}
            r={config.r}
          />
        </svg>
      </Loader>
    </Item>
  );
};

const rotator = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(270deg); }
`;

const dash = (circleLength) => keyframes`
  0% { stroke-dashoffset: ${circleLength}; }
  50% {
    stroke-dashoffset: ${circleLength / 4};
    transform:rotate(135deg);
  }
  100% {
    stroke-dashoffset: ${circleLength};
    transform:rotate(450deg);
  }
`;

const Item = styled.div`
  margin: auto;
`;

const Loader = styled.div`
  .spinnerItem {
    animation: ${rotator} 2s linear infinite;
  }

  .path {
    stroke-dasharray: ${(p) => p.circleLength};
    stroke-dashoffset: 0;
    transform-origin: center;
    animation: ${(p) => dash(p.circleLength)} 2s ease-in-out infinite;
  }
`;
