import { asyncActions as lenderDirectoryActions } from 'features/Directory/pages/LenderDirectory/actionTypes';
import { asyncActions as contactsDirectoryActions } from 'features/Directory/pages/ContactsDirectory/actionTypes';
import { asyncActions as userDetails } from 'containers/UserDetails/actionTypes';
import { asyncActions as modalActions } from 'constants/modalActionTypes';
import { asyncActions as myMatchingLoans } from 'features/MyMatchingLoans/actionTypes';
import { asyncActions as profile } from 'features/Profile/actionTypes';
import { asyncActions as chatWidget } from 'containers/ChatWidget/actionTypes';
import { asyncActions as dealRoom } from 'features/DealRoom/actionTypes';
import { asyncActions as registration } from 'features/Registration/actionTypes';
import { asyncActions as login } from 'features/Login/actionTypes';
import { asyncActions as passwordReset } from 'features/PasswordReset/actionTypes';
import { asyncActions as finishRegistration } from 'features/FinishRegistration/actionTypes';
import { asyncActions as dashboard } from 'features/Dashboard/actionTypes';
import { asyncActions as loanCreation } from 'features/LoanCreation/actionTypes';
import { asyncActions as myLoans } from 'features/MyLoans/actionTypes';
import { asyncActions as loanNew } from 'features/LoanNew/actionTypes';
import { asyncActions as marketplace } from 'features/Marketplace/actionTypes';
import { asyncActions as offers } from 'features/Offers/actionTypes';
import { asyncActions as inbox } from 'features/Inbox/actionTypes';
import { asyncActions as setPassword } from 'features/SetPassword/actionTypes';
import { asyncActions as dealRooms } from 'features/DealRooms/actionTypes';
import { asyncActions as standaloneLoan } from 'features/StandaloneLoan/actionTypes';
import { asyncActions as chat } from 'features/Chat/actionTypes';
import { asyncActions as teamMemberRegistration } from 'features/TeamMemberRegistration/actionTypes';
import { asyncActions as listings } from 'features/Listings/actionTypes';
import { asyncActions as listingWrapper } from 'containers/ListingWrapper/actionTypes';
import { asyncActions as registrationListing } from 'features/RegistrationListing/actionTypes';
import { asyncActions as loanSendToLenders } from 'features/LoanSendToLenders/actionTypes';
import { asyncActions as loanLenderManagement } from 'features/LoanLenderManagement/actionTypes';
import { asyncActions as clientsDirectory } from 'features/Directory/pages/ClientsDirectory/actionTypes';
import { asyncActions as recoveryCodes } from 'features/RecoveryCodes/actionTypes';
import { asyncActions as twoFactorAuthentication } from 'features/TwoFactorAuthentication/actionTypes';
import { asyncActions as confirmAuth } from 'features/ConfirmAuth/actionTypes';
import { asyncActions as recoveryLogin } from 'features/RecoveryLogin/actionTypes';
import { asyncActions as dashboardActionsItems } from 'features/DashboardActionItems/actionTypes';
import { asyncActions as loanTermsheets } from 'features/LoanTermsheets/actionTypes';
import { asyncActions as marketAnalytics } from 'features/Analytics/pages/MarketAnalytics/actionTypes';
import { asyncActions as marketAnalyticsLoans } from 'features/Analytics/pages/MarketAnalyticsLoans/actionTypes';
import { asyncActions as marketAnalyticsLenders } from 'features/Analytics/pages/MarketAnalyticsLenders/actionTypes';
import { asyncActions as lenderCompany } from 'features/LenderCompany/actionTypes';
import { asyncActions as oAuth } from 'features/OAuth/actionTypes';
import { asyncActions as teamAnalytics } from 'features/Analytics/pages/TeamAnalytics/actionTypes';
import { asyncActions as loanActivityWidget } from 'containers/LoanActivityWidget/actionTypes';
import { asyncActions as myTasks } from 'containers/MyTasksWrapper/actionTypes';
import { asyncActions as globalSearch } from 'features/GlobalSearch/actionTypes';

const asyncActions = [
  'SETTING_DATA',
  'LOGOUT',
  'EMAIL_EXISTS',
  'POST_INBOX_ANSWER',
  'SOCKET',
  'CONFIRM_EMAIL',
  'NOTIFICATIONS',
  'GET_CONFIGURATION',
  'CHECK_RESET_PASSWORD',
  'ENTERPRISE_LENDER_REGISTRATION',
  'LOAN_ACTIONS',
  'UPLOAD_ACTION_DOCUMENT',
  'DELETE_ACTION_DOCUMENT',
  'GET_PROFILE',
  'DELETE_CREDENTIALS',
  'REQUEST_PERMISSION',
  'SHARE_OFFER',
  'SHARE_LISTING',
  'SET_PASSWORD',
  'LISTING_LOGIN',
  'GET_CONTACTS',
  'GET_CONTACTS_TAGS',
  'GET_CONTACTS_TYPES',
  'BACK_TO_TEAM_VIEW',
  'FORCE_PASSWORD_CHANGE',
  'SET_COOKIE',
  'CHECK_PASSWORD_NEED',
  'UPDATE_PASSWORD_NEED',
  'BACK_TO_LOGIN',
  ...dealRoom,
  ...profile,
  ...myMatchingLoans,
  ...lenderDirectoryActions,
  ...contactsDirectoryActions,
  ...userDetails,
  ...modalActions,
  ...chatWidget,
  ...registration,
  ...login,
  ...passwordReset,
  ...finishRegistration,
  ...dashboard,
  ...loanCreation,
  ...myLoans,
  ...loanNew,
  ...marketplace,
  ...offers,
  ...inbox,
  ...setPassword,
  ...dealRooms,
  ...standaloneLoan,
  ...chat,
  ...teamMemberRegistration,
  ...listings,
  ...listingWrapper,
  ...registrationListing,
  ...loanSendToLenders,
  ...loanLenderManagement,
  ...clientsDirectory,
  ...recoveryCodes,
  ...twoFactorAuthentication,
  ...confirmAuth,
  ...recoveryLogin,
  ...dashboardActionsItems,
  ...loanTermsheets,
  ...marketAnalytics,
  ...marketAnalyticsLoans,
  ...marketAnalyticsLenders,
  ...lenderCompany,
  ...oAuth,
  ...teamAnalytics,
  ...loanActivityWidget,
  ...myTasks,
  ...globalSearch,
];

export default asyncActions;
