import { put, takeLatest, call } from 'redux-saga/effects';
import types from 'constants/actionTypes';
import * as API from 'api';
import { push } from 'connected-react-router';
import { getError } from 'sagas/utils';
import { checkPasswordNeedRequest } from 'actions';

import {
  getRecoveryCodesSuccess,
  getRecoveryCodesFailure,
  getNewRecoveryCodesSuccess,
  getNewRecoveryCodesFailure,
} from './actions';

function* getRecoveryCodes() {
  try {
    const {
      data: { response },
    } = yield call(API.axiosApi.get, '/auth/tfa/recovery-codes');

    yield put(
      getRecoveryCodesSuccess({
        codes: response.tokens,
        dateGenerated: response.generatedAt,
      })
    );
  } catch (e) {
    yield put(getRecoveryCodesFailure());
    yield put(push('/dashboard/profile'));

    yield put(
      checkPasswordNeedRequest({
        path: '/dashboard/recovery-codes',
      })
    );
  }
}

function* getNewRecoveryCodes() {
  try {
    const {
      data: { response },
    } = yield call(API.axiosApi.post, '/auth/tfa/recovery-codes');

    yield put(
      getNewRecoveryCodesSuccess({
        codes: response.tokens,
        dateGenerated: response.generatedAt,
      })
    );
  } catch (e) {
    yield put(getNewRecoveryCodesFailure({ message: getError(e) }));
  }
}

export default [
  takeLatest(types.GET_RECOVERY_CODES_REQUEST, getRecoveryCodes),
  takeLatest(types.GET_NEW_RECOVERY_CODES_REQUEST, getNewRecoveryCodes),
];
