import { take, put, call, race, takeLatest, select, all } from 'redux-saga/effects';
import ReconnectingWebSocket from 'reconnecting-websocket';
import types from 'constants/actionTypes';
import {
  addNotification,
  setNotificationCount,
  verifyLender,
  updateWebsocketMessage,
  handleWebsocketMessage,
  sendWebsocketMessage,
  showAlert,
  resetChatRoomCounter,
  updateWebsocketTasks,
} from 'actions';
import { eventChannel } from 'redux-saga';
import config from 'constants/config';

// const ReconnectingWebSocket = require('reconnecting-websocket').default;

function initWebsocket(ws) {
  return eventChannel((emitter) => {
    ws.onopen = () => {
      console.log('WebSocket is connected.');
    };

    ws.onerror = (error) => {
      console.log(`WebSocket error ${error}`);
      console.dir(error);
    };

    ws.onclose = function(e) {
      console.log('Socket is closed. Reconnect will be attempted ');
    };

    ws.onmessage = (e) => {
      let msg = null;
      try {
        msg = JSON.parse(e.data);
      } catch (error) {
        console.error(`Error parsing : ${error.data}`);
      }
      if (msg) {
        
        //console.log(msg.type, msg);

        const { data } = msg;
        if (msg.type === 'task.unseen') {
          emitter(updateWebsocketTasks(data));
        }
        else if (msg.type === 'notification.dashboard') {
          const defaultOptions = { message: data.title, level: 'info', data };
          switch (data.label) {
            case 'loan_approved':
              return emitter(
                addNotification({
                  ...defaultOptions,
                  title: 'Loan Request Approved',
                })
              );

            case 'loan_rejected':
              return emitter(
                addNotification({
                  ...defaultOptions,
                  title: 'Loan Request Rejected',
                })
              );

            case 'negotiation_started': {
              const offersCount = data.meta.offers_total;
              return emitter(
                addNotification({
                  ...defaultOptions,
                  title:
                    offersCount > 1
                      ? `You have ${offersCount} quotes on your loan request`
                      : 'New Quote',
                  message: !offersCount && data.title,
                })
              );
            }

            case 'offer_accepted_by_borrower':
              return emitter(
                addNotification({
                  ...defaultOptions,
                  title: 'Quote Accepted',
                })
              );

            case 'offer_accepted_by_lender':
              return emitter(
                addNotification({
                  ...defaultOptions,
                  title: 'Quote Accepted',
                })
              );

            case 'offer_rejected_by_borrower':
              return emitter(
                addNotification({
                  ...defaultOptions,
                  title: 'Quote Passed',
                })
              );

            case 'offer_rejected_by_lender':
              return emitter(
                addNotification({
                  ...defaultOptions,
                  title: 'Quote Passed',
                })
              );

            case 'lender_cancelled_offer_to_borrower':
              return emitter(
                addNotification({
                  ...defaultOptions,
                  title: 'Quote was withdrawn',
                })
              );

            case 'question_created':
              return emitter(
                addNotification({
                  ...defaultOptions,
                  title: 'New Question',
                })
              );
            case 'question_answered':
              return emitter(
                addNotification({
                  ...defaultOptions,
                  title: 'New Answer',
                })
              );

            case 'borrower_declined_to_answer':
              return emitter(
                addNotification({
                  ...defaultOptions,
                  title: 'Answer declined',
                })
              );

            case 'lender_account_approved':
              emitter(verifyLender());

              return emitter(
                addNotification({
                  ...defaultOptions,
                  title: 'Account Verification',
                })
              );

            case 'counter_offer_created_by_borrower':
              return emitter(
                addNotification({
                  ...defaultOptions,
                  title: 'New Quote',
                })
              );

            case 'counter_offer_created_by_lender': {
              const offersCount = data.meta.offers_total;
              return emitter(
                addNotification({
                  ...defaultOptions,
                  title:
                    offersCount > 1
                      ? `You have ${offersCount} quotes on your loan request`
                      : 'New Quote',
                  message: !offersCount && data.title,
                })
              );
            }

            case 'concierge_received':
              return emitter(
                addNotification({
                  ...defaultOptions,
                  title: 'Concierge Request',
                })
              );

            case 'document_request_created':
              return emitter(
                addNotification({
                  ...defaultOptions,
                  title: 'Document Request',
                })
              );

            case 'inbox_count':
              return emitter(setNotificationCount({ count: data.count }));
            default:
              return false;
          }
        } else if (msg.type.indexOf('chat.message') !== -1) {
          if (msg.type === 'chat.message.update') {
            emitter(updateWebsocketMessage({ data }));
          }

          if (msg.type === 'chat.message.in') {
            if (data.state) {
              emitter(
                sendWebsocketMessage({
                  type: 'chat.message.delivered',
                  data: { uuid: data.uuid, room: data.room },
                })
              );
            }

            emitter(handleWebsocketMessage({ data }));
          }
        } else if (msg.type === 'generic.error') {
          emitter(showAlert({ message: msg.error_description }));
        } else if (msg.type === 'chat.room.update') {
          if (data.state === 9) {
            emitter(resetChatRoomCounter(data));
          }
        }
      }
    };
    // unsubscribe function
    return () => {
      console.log('closing');
      ws.close();
    };
  });
}

function* internalListener(socket) {
  while (true) {
    const { payload } = yield take('SEND_WEBSOCKET_MESSAGE');

    if (!socket.readyState) {
      const interval = setInterval(() => {
        if (socket.readyState) {
          socket.send(JSON.stringify(payload));
          clearInterval(interval);
        }
      }, 500);
    } else {
      try {
        socket.send(JSON.stringify(payload));

        if (payload.type === 'chat.message.delivered') {
          const state = yield select();
          const { currentRoom } = state.chat;
          const { room, uuid } = payload.data;

          if (Number(currentRoom) === room) {
            socket.send(
              JSON.stringify({
                type: 'chat.message.read',
                data: { uuid },
              })
            );
          }
        }
      } catch (e) {
        console.error(e, 'websocket close sad');
      }
    }
  }
}

function* externalListener(socketChannel) {
  while (true) {
    const action = yield take(socketChannel);
    yield put(action);
  }
}

function* init() {
  const ws = new ReconnectingWebSocket(`${config.WS_HOST}/ws/chat`);

  const channel = yield call(initWebsocket, ws);

  yield put({ type: types.SOCKET_SUCCESS });

  while (true) {
    const { cancel } = yield race({
      action: all([call(externalListener, channel), call(internalListener, ws)]),
      cancel: take(types.STOP_WEBSOCKET),
    });

    if (cancel) {
      channel.close();
    }
  }
}

export default [takeLatest(types.SOCKET_REQUEST, init)];
