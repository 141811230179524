import styled, { css } from 'styled-components/macro';
import { Link } from 'react-router-dom';

export const SectionTitle = styled.h3`
  margin: 0 0 24px;
  font-size: 20px;
  line-height: 24px;
  font-weight: 600;

  @media (max-width: ${(p) => p.theme.breakpoints[0]}px) {
    font-size: 16px;
  }
`;

export const Section = styled.div`
  position: relative;
  margin-bottom: 24px;
  padding: 24px 24px 16px;
  box-shadow: ${(p) => p.theme.shadow};
  background-color: #fff;
  border-radius: 4px;
`;

export const SectionText = styled.p`
  color: #2f243a;
  line-height: 21px;
  &:last-of-type {
    margin: 0;
  }
`;

export const WysiwygText = styled.p`
  color: #2f243a;
  line-height: 21px;
  &:last-of-type {
    margin: 0;
  }

  p,
  ul,
  ol {
    margin: 0;
  }
`;

export const EditIco = styled.button`
  width: 16px;
  height: 15px;
  outline: none;
  border: none;
  appearance: none;
  background: url('/images/icons/ico_pen.svg') no-repeat;
  cursor: pointer;
  margin-left: 12px;
`;

export const EditLink = styled(Link)`
  width: 16px;
  height: 15px;
  background: url('/images/icons/ico_pen.svg');
  position: absolute;
  right: 24px;
  top: 24px;
  cursor: pointer;
  z-index: 2;
`;

export const SectionHead = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
`;

export const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: ${(p) => p.theme.breakpoints[2]}px) {
    flex-direction: column;
  }
`;

export const Content = styled.div`
  width: 65%;

  @media (max-width: ${(p) => p.theme.breakpoints[2]}px) {
    order: 2;
    width: 100%;
  }
`;

export const Aside = styled.div`
  width: 31%;
  margin-bottom: 30px;

  @media (max-width: ${(p) => p.theme.breakpoints[2]}px) {
    order: 1;
    width: 100%;
  }
`;

export const AsideContent = styled.div`
  position: sticky;
  top: 120px;
  max-height: calc(100vh - 120px);
  overflow-y: auto;

  @media (max-width: ${(p) => p.theme.breakpoints[2]}px) {
    position: static;
    max-height: auto;
  }
`;

export const LoanNotification = styled.div`
  padding: 24px 32px;
  color: #fff;
  background: rgba(25, 51, 83, 0.8);
  border-radius: 8px;
  position: absolute;
  bottom: 0;
  right: 0;

  &:before {
    content: '';
    display: inline-block;
    vertical-align: bottom;
    margin-right: 8px;
  }

  ${(p) => {
    switch (p.variant) {
      case 'warn':
        return css`
          &:before {
            background: url('/images/icons/loan-notifications/ico_warn.svg');
            width: 16px;
            height: 16px;
            background-size: 16px;
          }
        `;
      case 'draft':
        return css`
          &:before {
            background: url('/images/icons/loan-notifications/ico_draft.svg');
            width: 16px;
            height: 16px;
          }
        `;
      case 'success':
        return css`
          &:before {
            background: url('/images/icons/loan-notifications/ico_success.svg');
            width: 15px;
            height: 14px;
            background-size: 16px;
          }
        `;
      case 'reject':
        return css`
          &:before {
            background: url('/images/icons/loan-notifications/ico_reject.svg');
            width: 14px;
            height: 14px;
          }
        `;
      case 'fail':
        return css`
          &:before {
            background: url('/images/icons/loan-notifications/ico_fail.svg');
            width: 17px;
            height: 16px;
            background-size: 17px;
          }
        `;
      case 'concierge':
        return css`
          &:before {
            background: url('/images/icons/loan-notifications/ico_concierge.svg');
            width: 20px;
            height: 16px;
          }
        `;
      case 'offers':
        return css`
          &:before {
            background: url('/images/icons/loan-notifications/ico_offers.svg');
            width: 22px;
            height: 23px;
          }
        `;
      default:
        return '';
    }
  }};
`;

export const BlurCommon = styled.div`
  position: relative;

  &::before {
    content: '';
    position: absolute;
    display: none;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
`;

export const Blur = styled(BlurCommon)`
  ${(p) =>
    p.blured &&
    css`
      pointer-events: none;
      user-select: none;

      & > div {
        filter: url(blur.svg#blur);
        -webkit-filter: blur(3px);
        filter: blur(3px);
      }

      &::before {
        display: block;
      }

      .blur-ie {
        position: absolute;
        z-index: 10;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
      }
    `}
`;

export const MobileActionsWrapper = styled.div`
  display: none;
  width: 100%;
  position: fixed;
  justify-content: center;
  padding: 8px;
  background-color: #fff;
  bottom: 0;
  left: 0;
  box-shadow: ${(p) => p.theme.shadow};

  @media (max-width: ${(p) => p.theme.breakpoints[1]}px) {
    display: flex;
  }
`;

export const OffersSection = styled(Section)`
  position: relative;

  @media (max-width: ${(p) => p.theme.breakpoints[0]}px) {
    padding-bottom: 88px;
  }
`;

export const OffersListSection = styled.div`
  margin-bottom: 32px;
  border-bottom: 1px solid #e8e9ed;

  &:last-child {
    margin-bottom: 0;
    border-bottom: none;
  }

  @media (max-width: ${(p) => p.theme.breakpoints[1]}px) {
    border: none;
  }
`;

export const OffersButtonIco = styled.img`
  margin-left: 8px;
`;

export const OffersSectionLink = styled(Link)`
  @media (max-width: ${(p) => p.theme.breakpoints[0]}px) {
    position: absolute;
    bottom: 24px;
  }
`;
