import React from 'react';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { delay } from 'lib/utils';
import Yup from 'yup';
import { validate, validateEmail } from 'utils/validate';
import {
  lenderDirectoryInviteRequest,
  lenderDirectoryInviteDocumentRequest,
  checkLenderDirectoryEmailRequest,
} from 'features/Directory/pages/LenderDirectory/actions';

import { FormBlock } from './components';

const shape = Yup.object().shape({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  companyName: Yup.string().required('Company name is required'),
});

const schema = Yup.object().shape({
  lenders: Yup.array().of(shape),
  lender: shape,
});

export class LenderDirectoryAddForm extends React.Component {
  emails = {};
  emailValidationAnswers = {};

  onDrop = (files) => {
    const { dispatch } = this.props;

    if (files.length) {
      const formData = new FormData();
      formData.append('file', files[0], files[0].name || 'file');
      formData.append('filename', files[0].name || 'file');

      dispatch(lenderDirectoryInviteDocumentRequest(formData));
    }
  };

  asyncValidate = async (email, index) => {
    const { dispatch } = this.props;

    if (!email) {
      return 'Email is required';
    }

    if (this.emails[index] && this.emails[index] === email) {
      return this.emailValidationAnswers[index];
    }

    this.emails[index] = email;

    if (!validateEmail(email)) {
      this.emailValidationAnswers[index] = 'Invalid email address';
      return this.emailValidationAnswers[index];
    }

    if (email) {
      const res = await delay(1000).then(() =>
        new Promise((resolve, reject) => {
          dispatch(checkLenderDirectoryEmailRequest({ email, resolve, reject }));
        }).then(
          (v) => v,
          (data) => data.error
        )
      );
      this.emailValidationAnswers[index] = res;
      return this.emailValidationAnswers[index];
    }
  };

  submitForm = ({ lenders = [], lender }) => {
    const { dispatch, hideModal, setPageLoaded } = this.props;
    const request = [...lenders, { ...lender }].map((item) => {
      const updatedItem = { ...item };

      if (updatedItem.mobilePhone) {
        updatedItem.mobilePhone = updatedItem.mobilePhone.replace(/[^0-9]/g, '');
      }

      if (updatedItem.phone) {
        updatedItem.phone = updatedItem.phone.replace(/[^0-9]/g, '');
      }

      return updatedItem;
    });
    hideModal();
    dispatch(lenderDirectoryInviteRequest(request));
  };

  render() {
    return (
      <Form
        onSubmit={this.submitForm}
        validate={(values) => validate(values, schema)}
        mutators={{
          ...arrayMutators,
        }}
      >
        {({
          handleSubmit,
          submiting,
          validating,
          form: {
            mutators: { push },
          },
          values: formValues,
        }) => (
          <FormBlock
            handleSubmit={handleSubmit}
            onDrop={this.onDrop}
            asyncValidate={this.asyncValidate}
            formValues={formValues}
            push={push}
            submiting={submiting}
            validating={validating}
          />
        )}
      </Form>
    );
  }
}
