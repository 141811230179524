import types from 'constants/actionTypes';
import immutable from 'object-path-immutable';

const initialState = {
  questions: [],
  next: null,
};

export default function inbox(state, action = {}) {
  const currentState = state || initialState;

  const { type } = action;

  switch (type) {
    case types.INBOX_SUCCESS:
      return immutable(currentState)
        .set('questions', action.payload.results)
        .set('next', action.payload.next)
        .value();

    case types.INBOX_ADDITIONAL_DATA_SUCCESS:
      return immutable(currentState)
        .set('questions', [...currentState.questions, ...action.payload.results])
        .set('next', action.payload.next)
        .value();

    case types.DECLINE_QUESTION_SUCCESS: {
      const { id } = action.payload;
      return immutable.set(
        currentState,
        'questions',
        currentState.questions.filter(item => item.id !== id)
      );
    }
    default:
      return currentState;
  }
}
