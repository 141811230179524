import React from 'react';
import styled from 'styled-components/macro';
import { DocumentIcon } from 'components/SvgIcons';

export const File = ({
  item,
  folderId,
  sectionId,
  openConfidentialityAgreementModal,
}) => (
  <Wrapper>
    <FileLink
      href={item.url || null}
      target="_blank"
      rel="noopener noreferrer"
      onClick={
        !item.url && item.protected
          ? () => {
              openConfidentialityAgreementModal({
                documentId: item.id,
                folderId,
                sectionId,
              });
            }
          : null
      }
    >
      <DocumentIcon />
      {item.filename}
    </FileLink>
  </Wrapper>
);

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 16px;
  margin-bottom: 16px;
  padding-left: 24px;
`;

const FileLink = styled.a`
  display: flex;
  align-items: center;
  width: 100%;
  margin-right: auto;
  color: #1c3353;
  font-size: 12px;
  line-height: 16px;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }

  svg {
    margin-right: 8px;
  }

  &::after {
    content: url('/images/icons/ico_view_gray.svg');
    margin-left: auto;
  }
`;
