import axios from 'axios';
import { camelizeKeys } from 'humps';
import config from 'constants/config';
import { push } from 'connected-react-router';
import store from 'store';
import { deleteCredentialsRequest } from 'actions';
import { notAuthCredentialsPathAllowed } from 'config/notAuthCredentialsPathAllowed';
import { any } from 'ramda';
const REGISTRATION = 'registration';

function IsJsonString(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

const axiosApi = axios.create({
  baseURL: config.API_HOST,
  withCredentials: true,
  transformResponse: (res) => {
    return IsJsonString(res) ? camelizeKeys(JSON.parse(res)) : res;
  },
});

const axiosApiNoBase = axios.create({
  baseURL: config.API_HOST_RAW,
  withCredentials: true,
  transformResponse: (res) => {
    return IsJsonString(res) ? camelizeKeys(JSON.parse(res)) : res;
  },
});

function apiErrorHandler(error) {
  const errorStatus = error?.response?.status;
  const { pathname, search } = window.location;
  let loginUriParams = '';

  const isStandAloneLoanPath =
    (pathname.indexOf('property-details') !== -1 &&
      pathname.split('/')[1] === 'property-details') ||
    (pathname.indexOf('listing') !== -1 && pathname.split('/')[1] === 'listing');
    const splittedURL = pathname.split('/').filter(it => it);
    const isInvitationUUIDExists = splittedURL[0] === REGISTRATION && splittedURL.length === 3;

  if (pathname === '/authorize') {
    loginUriParams = `afterLogin=toOauth&${search.replace('?', '')}`;
  }

  if (
    errorStatus === 401 &&
    (any((path) => pathname === path, notAuthCredentialsPathAllowed) ||
      isStandAloneLoanPath || isInvitationUUIDExists)
  ) {
    return Promise.reject({ response: { status: 'ignore' } });
  } else if (errorStatus === 401) {
    store.dispatch(deleteCredentialsRequest());
    store.dispatch(push(`/login?${loginUriParams}`));
    return Promise.reject(error);
  } else {
    return Promise.reject(error);
  }
}


// Add a response interceptor
axiosApi.interceptors.response.use(function (response) {
  // Do something with response data
  return response;
}, function (error) {
  // Do something with response error
  return Promise.reject(error);
});


axiosApi.interceptors.response.use((response) => response, apiErrorHandler);
axiosApiNoBase.interceptors.response.use((response) => response, apiErrorHandler);

export { axiosApiNoBase };
export default axiosApi;
