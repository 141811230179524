import { createSelector } from 'reselect';
import { getCurrentUserId } from 'selectors';
import { find } from 'ramda';

const getLenderCompanyTeams = (state) => state.lenderCompany.teams;

export const getCurrentLenderTeam = createSelector(
  [getCurrentUserId, getLenderCompanyTeams],
  (lenderId, teams) => {
    const team = teams.filter((team) =>
      find(({ userId }) => userId === lenderId, team.members)
    )?.[0];

    return team
      ? { ...team, members: team.members.filter(({ joined }) => joined) }
      : team;
  }
);
