import { put, takeLatest, call, select } from 'redux-saga/effects';
import types from 'constants/actionTypes';
import * as API from 'api';
import { getError } from 'sagas/utils';
import { downloadBlobFile } from 'lib/browser';

import {
  getAnalyticsLendersDataSuccess,
  getAnalyticsLendersDataFailure,
  updateAnalyticsLendersDataSuccess,
  updateAnalyticsLendersDataFailure,
  getLendersActivitySuccess,
  getLendersActivityFailure,
} from './actions';

function* getAnalyticsLendersData() {
  try {
    const {
      data: { response },
    } = yield call(API.axiosApi.get, '/lenders/activity/');

    const loans = yield call(
      API.axiosApi.get,
      '/loans/my?show_matched_count=1&status=2&status=3&status=4&status=6&no_page="true"'
    );
    yield put(
      getAnalyticsLendersDataSuccess({
        lenders: response,
        postedLoans: loans.data.response,
      })
    );
  } catch (e) {
    console.log(e);
    yield put(getAnalyticsLendersDataFailure({ message: getError(e) }));
  }
}

function* updateAnalyticsLendersData(action) {
  const { loanId } = action.payload;
  try {
    const {
      data: { response },
    } = yield call(
      API.axiosApi.get,
      loanId ? `/lenders/activity?loan=${loanId}` : '/lenders/activity/'
    );
    yield put(updateAnalyticsLendersDataSuccess({ lenders: response }));
  } catch (e) {
    console.log(e);
    yield put(updateAnalyticsLendersDataFailure({ message: getError(e) }));
  }
}

function* exportLendersActivity() {
  try {
    const {
      marketAnalyticsLenders: { currentLoanId },
    } = yield select();
    const { data, headers } = yield call(
      API.axiosApiRaw.get,
      currentLoanId ? `/lenders/export?loan=${currentLoanId}` : '/lenders/export/',
      {
        responseType: 'blob',
      }
    );

    downloadBlobFile(data, headers['content-disposition'].split('=')[1]);
    yield put(getLendersActivitySuccess());
  } catch (e) {
    console.log(e);
    yield put(getLendersActivityFailure());
  }
}

export default [
  takeLatest(types.GET_ANALYTICS_LENDERS_DATA_REQUEST, getAnalyticsLendersData),
  takeLatest(types.UPDATE_ANALYTICS_LENDERS_DATA_REQUEST, updateAnalyticsLendersData),
  takeLatest(types.GET_LENDERS_ACTIVITY_REQUEST, exportLendersActivity),
];
