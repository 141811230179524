import types from 'constants/actionTypes';

export const finishRegistrationRequest = (payload = {}) => ({
  type: types.FINISH_REGISTRATION_REQUEST,
  payload,
});
export const finishRegistrationSuccess = (payload = {}) => ({
  type: types.FINISH_REGISTRATION_SUCCESS,
  payload,
});
export const finishRegistrationFailure = (payload = {}) => ({
  type: types.FINISH_REGISTRATION_FAILURE,
  payload,
});

export const getProfileInfoRequest = (payload = {}) => ({
  type: types.GET_PROFILE_INFO_REQUEST,
  payload,
});
export const getProfileInfoSuccess = (payload = {}) => ({
  type: types.GET_PROFILE_INFO_SUCCESS,
  payload,
});
export const getProfileInfoFailure = (payload = {}) => ({
  type: types.GET_PROFILE_INFO_FAILURE,
  payload,
});
