import { put, takeLatest, call, select } from 'redux-saga/effects';
import types from 'constants/actionTypes';
import * as API from 'api';
import { getError, callPermissionModal } from 'sagas/utils';
import {
  TRACK,
  SUBMITTED_FOR_VERIFICATION,
  LOAN_STATUS,
  LOAN_ACCESS_LEVEL,
  LOAN_REMINDER_SET,
} from 'constants/appRelated';
import { analytics } from 'lib/analytics';
import { openDocumentInNewWindow, downloadBlobFile } from 'lib/browser';
import { makeOfferFormData, doesUserCanNegotiate, getDataByFieldsConfig } from 'lib/loan';
import { declineQuestionSuccess, declineQuestionFailure } from 'features/Inbox/actions';

import { showModal, updatedOfferModal } from 'actions/modalActions';

import { push } from 'connected-react-router';
import { modals, INFO_MODAL_TYPE } from 'constants/modalTypes';
import {
  uploadSignedTermsheetFailure,
  uploadSignedTermsheetSuccess,
} from 'features/DealRooms/actions';
import { myOffersRequest } from 'features/Offers/actions';
import { decamelizeKeys, camelize } from 'humps';
import { sampleData } from 'features/SampleLoan/mock';
import { getLoanTermsheetsRequest } from 'features/LoanTermsheets/actions';
import { keys } from 'ramda';
import { fieldsConfig } from 'config/fieldsConfig';
import { history } from 'store';

import {
  loanDataRequest,
  uploadRequestedDocumentSuccess,
  uploadRequestedDocumentFailure,
  loanDataSuccess,
  loanDataFailure,
  submitForVerificationSuccess,
  submitForVerificationFailure,
  inviteLenderSuccess,
  inviteLenderFailure,
  exportOffersSuccess,
  exportOffersFailure,
  requestDocumentsSuccess,
  requestDocumentsFailure,
  downloadAllDocumentsSuccess,
  downloadAllDocumentsFailure,
  submitTermsheetSuccess,
  submitTermsheetFailure,
  postAnswerSuccess,
  postAnswerFailure,
  cancelOfferSuccess,
  cancelOfferFailure,
  rejectOfferSuccess,
  rejectOfferFailure,
  acceptOfferSuccess,
  acceptOfferFailure,
  editOfferSuccess,
  editOfferFailure,
  negotiateOfferSuccess,
  negotiateOfferFailure,
  submitOfferSuccess,
  submitOfferFailure,
  postLoanNoteSuccess,
  postLoanNoteFailure,
  getBrokerLoanRoomSuccess,
  getBrokerLoanRoomFailure,
  acceptConfidentialityAgreementSuccess,
  acceptConfidentialityAgreementFailure,
  submitManualQuoteSuccess,
  submitManualQuoteFailure,
  deleteNegotiationSuccess,
  deleteNegotiationFailure,
  exportStatusReportSuccess,
  exportStatusReportFailure,
  changePropertyNameSuccess,
  changePropertyNameFailure,
  updateRequestedDocumentProtectionSuccess,
  updateRequestedDocumentProtectionFailure,
  setLoanReminderSuccess,
  setLoanReminderFailure,
  createNewDealRoomFailure,
  createNewDealRoomSuccess,
  getMoodyAccessTokenSuccess,
  getMoodyAccessTokenFailure,
} from './actions';

import { getDesiredTermsFields, getPropertyProfileFields } from './selectors';
import { getDealRoomRequest } from '../DealRoom/actions';

function* uploadRequestedDocument(action) {
  const { form, folderId, sectionId, id, lenderPk } = action.payload;
  try {
    yield call(API.axiosApi.post, `/loans/${id}/folders/${folderId}/documents`, form);

    yield put(uploadRequestedDocumentSuccess({ folderId, sectionId, lenderPk }));
  } catch (e) {
    console.error(e);
    yield put(uploadRequestedDocumentFailure({ message: getError(e) }));
  }
}

function* updateRequestedDocumentProtection(action) {
  try {
    const { folderId, loanId, protectionState, sectionId, lenderPk } = action.payload;
    yield call(API.axiosApi.patch, `loans/${loanId}/folders/${folderId}`, {
      protected: protectionState,
    });
    yield put(
      updateRequestedDocumentProtectionSuccess({
        folderId,
        sectionId,
        protectionState,
        lenderPk,
      })
    );
  } catch (e) {
    yield put(updateRequestedDocumentProtectionFailure({ message: getError(e) }));
  }
}

function* submitForVerification(action) {
  const { id } = action.payload;
  try {
    const { router } = yield select();
    const {
      data: { response },
    } = yield call(API.axiosApi.get, `/loans/${id}/complete`, {});

    analytics(TRACK, SUBMITTED_FOR_VERIFICATION);

    yield put(submitForVerificationSuccess(response));

    if (router.location.pathname.includes('/dashboard/loan-creation')) {
      yield put(push(`/dashboard/property-details/${id}`));
    }
  } catch (e) {
    console.error(e);
    yield put(submitForVerificationFailure({ message: getError(e) }));
  }
}

function* getLoan(action) {
  const { id, withOffer, showCompare } = action.payload;
  let loan;

  try {
    if (id === 'sample') {
      loan = sampleData;
    } else {
      const {
        data: { response },
      } = yield call(API.axiosApi.get, `/loans/${id}`);

      let fieldsHistory = {};

      if (response.accessLevel && response.accessLevel === LOAN_ACCESS_LEVEL.FULL) {
        const historyRequest = yield call(API.axiosApi.get, `/loans/${id}/field-history`);
        let sponsorProfileHistoryFields = [];
        if (response?.sponsorProfile?.id) {
          const sponsorProfileHistoryRequest = yield call(
            API.axiosApi.get,
            `/sponsor-history/${response.sponsorProfile.id}`
          );

          sponsorProfileHistoryFields = sponsorProfileHistoryRequest.data.response;
        }

        [...historyRequest.data.response, ...sponsorProfileHistoryFields].forEach(
          ({ fieldName }) => (fieldsHistory[camelize(fieldName)] = {})
        );
      }

      loan = { ...response, fieldsHistory };

      if (showCompare) {
        yield put(push(`/dashboard/compare-offers/${id}`));
      }

      if (withOffer) {
        yield put(updatedOfferModal(response));
      }
    }

    const state = yield select();
    
    const {
      configuration: { options },
    } = state;

    const loanDisplayingFields = [
      ...getDesiredTermsFields(loan?.fieldsScheme?.desiredTerms),
      ...getPropertyProfileFields(loan?.fieldsScheme?.propertyProfile),
    ];

    const loanDisplayingData = {
      ...getDataByFieldsConfig({
        data: loan,
        fields: loanDisplayingFields,
        options: state.configuration.normalizedOptions,
        config: fieldsConfig,
        extraRule: ({ data, field, config }) => {
          if (field === 'amortization' && data.amortizationCustom) {
            return [null, null];
          }

          if (field === 'amortizationCustom' && data.amortizationCustom) {
            return [data[field], { ...config[field], label: 'Amortization' }];
          }

          return [data[field], config[field]];
        },
      }),
      sponsorProfile: {
        ...getDataByFieldsConfig({
          data: loan.sponsorProfile,
          fields: keys(loan.sponsorProfile),
          options: state.configuration.normalizedOptions,
          config: fieldsConfig.sponsorProfile,
        }),
      },
    };
    
    yield put(loanDataSuccess({ loan, loanDisplayingData, stateOptions: options.stateOptions }));
  } catch (e) {
    console.error(e);
    const is404Error = e?.response?.status === 404;

    yield put(
      loanDataFailure({
        message: is404Error
          ? 'Sorry, you no longer have access to this loan package.'
          : getError(e),
      })
    );
    yield put(push('/dashboard'));
  }
}

function* inviteLender(action) {
  const {
    lenders,
    loans,
    loanStatus,
    sendMailToMatched,
    invitationText,
  } = action.payload;

  const request = {
    loans,
    send_mail_to_matched: !!sendMailToMatched,
  };

  if (invitationText) {
    request.invitation_text = invitationText;
  }

  if (lenders.length) {
    request.lenders = lenders.map(Number);
  }

  try {
    if (loans.length === 1) {
      yield put(push(`/dashboard/property-details/${loans[0]}`));
    }
    yield call(API.axiosApi.post, '/lenders/invite/send', request);
    yield put(inviteLenderSuccess());

    yield put(
      showModal(modals.INFO_MODAL, {
        infoType: INFO_MODAL_TYPE.SUCCESS,
        title: 'Success!',
        text:
          lenders.length && loanStatus && loanStatus < LOAN_STATUS.POSTED_TO_MARKETPLACE
            ? 'Congratulations! Your loan was submitted to RealAtom for verification. Please allow about 48 hours for us to complete your loan request, prepare it to be posted on the RealAtom Marketplace, and notify all your selected lenders.'
            : 'Congratulations! You have just created your loan request and it will be sent to selected lenders. You should start receiving soft quotes in next 24-78 hours.',
        buttonText: 'Go to dashboard',
        size: 'sm',
        onButtonClick: (dispatch) => {
          dispatch(push('/dashboard'));
        },
      })
    );
  } catch (e) {
    yield put(inviteLenderFailure());
  }
}

function* exportOffers(action) {
  const { id, offers } = action.payload;

  try {
    const { data, headers } = yield call(
      API.axiosApiRaw.get,
      offers
        ? `/loans/${id}/export_offers?negotiations=${offers.join(',')}`
        : `/loans/${id}/export_offers`,
      {
        responseType: 'blob',
      }
    );

    downloadBlobFile(data, headers['content-disposition'].split('=')[1]);
    yield put(exportOffersSuccess());
  } catch (e) {
    console.log(e);
    yield put(exportOffersFailure());
  }
}

function* uploadSignedTermsheet(action) {
  try {
    const { form, loanId, negotiationId, termsheetId } = action.payload;
    const { currentUser } = yield select();

    if (!doesUserCanNegotiate(currentUser)) {
      yield* callPermissionModal(currentUser.permissionAsked);
    } else {
      yield call(
        API.axiosApi.post,
        `loans/${loanId}/negotiations/${negotiationId}/termsheets/${termsheetId}/upload_signed`,
        form
      );

      yield put(getLoanTermsheetsRequest({ loanId: loanId }));
      yield put(uploadSignedTermsheetSuccess());
    }
  } catch (e) {
    console.error(e);
    yield put(uploadSignedTermsheetFailure({ message: getError(e) }));
  }
}

function* rejectTermsheet(action) {
  try {
    const {
      negotiationId,
      reason: rejection_reason,
      loanId,
      termsheetId,
    } = action.payload;

    const { currentUser } = yield select();

    if (!doesUserCanNegotiate(currentUser)) {
      yield* callPermissionModal(currentUser.permissionAsked);
    } else {
      yield call(
        API.axiosApi.post,
        `loans/${loanId}/negotiations/${negotiationId}/termsheets/${termsheetId}/reject`,
        { rejection_reason }
      );

      yield put(getLoanTermsheetsRequest({ loanId }));
    }
  } catch (e) {
    yield put({
      type: types.REJECT_TERMSHEET_FAILURE,
      payload: { message: getError(e) },
    });
  }
}

function* declineQuestion(action) {
  try {
    const { pk, fromLoan } = action.payload;

    yield call(API.axiosApi.put, `loan-questions/${pk}/decline_answer`, {
      answer_declined: true,
    });

    yield put(
      declineQuestionSuccess({
        message: 'The question has been successfully declined',
        id: pk,
        fromLoan,
      })
    );
  } catch (e) {
    console.log(e);
    yield put(declineQuestionFailure({ message: getError(e) }));
  }
}

function* requestDocuments(action) {
  try {
    const { documentsRequest, pk, isLender } = action.payload;
    const requestPath = isLender ? 'document-request-deal-room' : 'document-request';

    const {
      data: { response },
    } = yield call(API.axiosApi.post, `loans/${pk}/${requestPath}`, [
      ...documentsRequest,
    ]);

    yield put(requestDocumentsSuccess(response));
    if(isLender){
      yield put(push(`/dashboard/matching-loans`));
    }
  } catch (e) {
    console.log(e);
    yield put(requestDocumentsFailure());
  }
}

function* downloadAllDocuments(action) {
  try {
    const { uid } = action.payload;
    yield call(API.axiosApi.post, `loan/${uid}/documents/all/get`, {});

    yield put(
      showModal(modals.INFO_MODAL, {
        size: 'sm',
        infoType: INFO_MODAL_TYPE.PROCESS,
        title: 'Your download is being prepared and will be emailed to you shortly',
        text:
          'Not getting our emails? Make sure that you’ve added info@realatom.com to your whitelist.',
      })
    );

    yield put(downloadAllDocumentsSuccess());
  } catch (e) {
    console.log(e);
    yield put(downloadAllDocumentsFailure({ message: getError(e) }));
  }
}

function* submitTermsheet(action) {
  try {
    const { uuid, negotiationPk, form } = action.payload;
    const {
      data: { response },
    } = yield call(
      API.axiosApi.post,
      `loans/${uuid}/negotiations/${negotiationPk}/termsheets`,
      form
    );

    yield put(submitTermsheetSuccess(response));
    yield put(loanDataRequest({ id: uuid }));
  } catch (e) {
    console.error(e);
    yield put(submitTermsheetFailure({ message: getError(e) }));
  }
}

function* postAnswer(action) {
  const { answer, loanId, id, isPublic } = action.payload;
  try {
    const {
      data: { response },
    } = yield call(API.axiosApi.patch, `loans/${loanId}/questions/${id}`, {
      answer,
      is_public: isPublic,
    });

    yield call(API.axiosApi.patch, `loan-questions/${id}/mark_read`, {
      question_read: true,
    });

    yield put(postAnswerSuccess(response));
  } catch (e) {
    console.error(e);
    yield put(postAnswerFailure({ message: getError(e) }));
  }
}

function* cancelOffer(action) {
  try {
    const { loanId, negotiation_pk, cancelReason } = action.payload;

    const {
      currentUser: { id },
    } = yield select();

    yield call(
      API.axiosApi.post,
      `loans/${loanId}/negotiations/${negotiation_pk}/cancel`,
      { cancel_reason: cancelReason }
    );

    yield put(cancelOfferSuccess({ id, negotiation_pk, loanId }));
  } catch (e) {
    console.error(e);
    yield put(cancelOfferFailure({ message: getError(e) }));
  }
}

function* rejectOffer(action) {
  try {
    const { id, negotiation_pk, afterSubmit, reason } = action.payload;
    const { currentUser } = yield select();

    if (!doesUserCanNegotiate(currentUser)) {
      yield* callPermissionModal(currentUser.permissionAsked);
    } else {
      const {
        data: { response },
      } = yield call(
        API.axiosApi.post,
        `loans/${id}/negotiations/${negotiation_pk}/reject`,
        { reject_reason: reason }
      );

      yield put(rejectOfferSuccess(response));

      switch (afterSubmit) {
        case 'fromOffers':
          yield put(myOffersRequest());
          break;

        default:
          yield put(loanDataRequest({ id }));
          break;
      }
    }
  } catch (e) {
    console.error(e);
    yield put(rejectOfferFailure({ message: getError(e) }));
  }
}

function* acceptOffer(action) {
  try {
    const { id, negotiation_pk, afterSubmit } = action.payload;
    const { currentUser } = yield select();

    if (!doesUserCanNegotiate(currentUser)) {
      yield* callPermissionModal(currentUser.permissionAsked);
    } else {
      const {
        data: { response },
      } = yield call(
        API.axiosApi.post,
        `loans/${id}/negotiations/${negotiation_pk}/accept`,
        {}
      );

      yield put(acceptOfferSuccess(response));

      switch (afterSubmit) {
        case 'fromOffers':
          yield put(myOffersRequest());
          break;

        default:
          yield put(loanDataRequest({ id }));
          break;
      }
    }
  } catch (e) {
    console.error(e);
    yield put(acceptOfferFailure({ message: getError(e) }));
  }
}

function* editOffer(action) {
  try {
    const {
      id,
      offer,
      documents,
      negotiation_pk,
      offer_id,
      chatRoomId,
      chatTabType,
    } = action.payload;

    const { currentUser } = yield select();

    if (!doesUserCanNegotiate(currentUser)) {
      yield* callPermissionModal(currentUser.permissionAsked);
    } else {
      const {
        data: { response },
      } = yield call(
        API.axiosApi.patch,
        `loans/${id}/negotiations/${negotiation_pk}/offers/${offer_id}`,
        makeOfferFormData(offer, documents)
      );

      yield put(
        editOfferSuccess({
          offer: response,
          negotiationPk: negotiation_pk,
          offerId: offer_id,
          chatRoomId,
          chatTabType,
        })
      );
      yield put(loanDataRequest({ id }));
      yield put(myOffersRequest());
    }
  } catch (e) {
    console.error(e);
    yield put(editOfferFailure({ message: getError(e) }));
  }
}

function* negotiateOffer(action) {
  try {
    const { id, offer, documents, negotiation_pk, afterSubmit } = action.payload;
    const { currentUser } = yield select();

    if (!doesUserCanNegotiate(currentUser)) {
      yield* callPermissionModal(currentUser.permissionAsked);
    } else {
      const {
        data: { response },
      } = yield call(
        API.axiosApi.post,
        `loans/${id}/negotiations/${negotiation_pk}/offers`,
        makeOfferFormData(offer, documents)
      );

      yield put(negotiateOfferSuccess(response));
      switch (afterSubmit) {
        case 'fromOffers':
          yield put(myOffersRequest());
          break;

        default:
          yield put(loanDataRequest({ id }));
          break;
      }
    }
  } catch (e) {
    console.error(e);
    yield put(negotiateOfferFailure({ message: getError(e) }));
  }
}

function* submitOffer(action) {
  try {
    const { id, offer, documents, dealRooms, userId } = action.payload;
    const {
      data: { response },
    } = yield call(
      API.axiosApi.post,
      `loans/${id}/negotiations`,
      makeOfferFormData(offer, documents)
    );

    const alreadyExistsDealRoomForLender = dealRooms?.length &&
      dealRooms.some(dr => dr.lender.lenderId === userId);

    if (response.isFirstOffer && !alreadyExistsDealRoomForLender) {
      yield put(
        showModal(modals.INFO_MODAL, {
          infoType: INFO_MODAL_TYPE.PROCESS,
          title: 'Do you want to open a deal room?',
          text:
            'In the deal room, create checklists to have all loan documents organized at any stage of the transaction.',
          buttonText: 'Yes',
          size: 'sm',
          buttonRejectText: 'No',
          onButtonClick: (dispatch) => {
            API.axiosApi.post('/deal-rooms', { offer: response.id }).then(({ data }) => {
              dispatch(loanDataRequest({ id }));
              dispatch(
                push(
                  `/dashboard/property-details/${id}/deal-room/${data.response.id}/all`
                )
              );
            });
          },
        })
      );
    }

    yield put(
      submitOfferSuccess({
        message:
          'Your quote has been submitted to the platform. We will notify you when borrower replies',
      })
    );

    yield put(loanDataRequest({ id, withOffer: true }));
  } catch (e) {
    console.error(e);
    yield put(submitOfferFailure({ message: getError(e) }));
  }
}

function* submitNote(action) {
  try {
    const { loanId, text } = action.payload;
    const {
      data: { response },
    } = yield call(API.axiosApi.post, `loans/${loanId}/notes`, { text });
    yield put(postLoanNoteSuccess(response));
  } catch (e) {
    yield put(postLoanNoteFailure({ message: getError(e) }));
  }
}

function* getBrokerLoanRoom(action) {
  try {
    const { loanId } = action.payload;
    const {
      data: { response },
    } = yield call(API.axiosApi.get, `chat/rooms?loan=${loanId}`);
    const roomId = response?.results?.[0]?.rooms?.[0]?.id;
    yield put(getBrokerLoanRoomSuccess(response));
    if (roomId) {
      yield put(push(`/dashboard/chat/loans/${roomId}`));
    }
  } catch (e) {
    yield put(getBrokerLoanRoomFailure({ message: getError(e) }));
  }
}

function* acceptConfidentialityAgreement(action) {
  try {
    const {
      loanId,
      documentId,
      folderId,
      sectionId,
      allDocuments,
      loanUid,
    } = action.payload;

    const {
      data: { response },
    } = yield call(API.axiosApi.post, `loans/${loanId}/agreements`, {});

    yield put(acceptConfidentialityAgreementSuccess(response));

    if (allDocuments) {
      yield call(API.axiosApi.post, `loan/${loanUid}/documents/all/get`, {});

      yield put(
        showModal(modals.INFO_MODAL, {
          size: 'sm',
          infoType: INFO_MODAL_TYPE.PROCESS,
          title: 'Your download is being prepared and will be emailed to you shortly',
          text:
            'Not getting our emails? Make sure that you’ve added info@realatom.com to your whitelist.',
        })
      );
    } else {
      const { recommended, sections } = response.documentsAll;
      const folder = sectionId
        ? sections
            .filter((section) => section.sectionId === sectionId)[0]
            .folders.filter(({ id }) => id === folderId)[0]
        : recommended.filter(({ id }) => id === folderId)[0];
      const document = folder.documents.filter(({ id }) => id === documentId)[0];
      openDocumentInNewWindow(document.url);
    }
  } catch (e) {
    yield put(acceptConfidentialityAgreementFailure({ message: getError(e) }));
  }
}

function* submitManualQuote(action) {
  try {
    const { request, loanId } = action.payload;
    yield call(
      API.axiosApi.post,
      `loans/${loanId}/negotiations`,
      decamelizeKeys(request)
    );
    yield put(loanDataRequest({ id: loanId }));
    yield put(submitManualQuoteSuccess());
  } catch (e) {
    yield put(submitManualQuoteFailure({ message: getError(e) }));
  }
}

function* deleteNegotiation(action) {
  try {
    const { negotiationId, loanId } = action.payload;
    yield call(API.axiosApi.delete, `loans/${loanId}/negotiations/${negotiationId}`);
    yield put(deleteNegotiationSuccess());
    yield put(loanDataRequest({ id: loanId }));
  } catch (e) {
    yield put(deleteNegotiationFailure({ message: getError(e) }));
  }
}

function* exportStatusReportRequest(action) {
  try {
    const { loanId } = action.payload;
    yield call(API.axiosApi.get, `loans/${loanId}/export_status`);
    yield put(exportStatusReportSuccess());
  } catch (e) {
    yield put(exportStatusReportFailure({ message: getError(e) }));
  }
}

function* changePropertyName(action) {
  try {
    const { propertyName, loanId } = action.payload;
    yield call(
      API.axiosApi.patch,
      `loans/${loanId}`,
      decamelizeKeys({
        propertyName,
        usePropertyName: true,
      })
    );
    yield put(changePropertyNameSuccess({ propertyName }));
  } catch (e) {
    yield put(changePropertyNameFailure({ message: getError(e) }));
  }
}

function* setLoanReminder(action) {
  try {
    const { loanId, time } = action.payload;
    yield call(API.axiosApi.post, `/loans/${loanId}/set_reminder`, { remind_in: time });
    analytics(TRACK, LOAN_REMINDER_SET);
    yield put(setLoanReminderSuccess());
  } catch (e) {
    yield put(setLoanReminderFailure({ message: getError(e) }));
  }
}

function* createDealRoom(action) {
  const loanId = action.payload;
  try {
    const {
      data: { response },
    } = yield call(API.axiosApi.post, `/loans/${loanId}/open_dealroom`);
    const roomId = response?.id;
    yield put(createNewDealRoomSuccess(true))
    yield put(getDealRoomRequest({ roomId, type: 'all' }));
    history.push(`/dashboard/property-details/${loanId}/deal-room/${roomId}/all`);
  } catch (e) {
    yield put(createNewDealRoomFailure({ message: getError(e) }));
  }
}

function* getMoodyAccessToken() {
  try {
    const {
      data: { response },
    } = yield call(API.axiosApi.get, '/get_moody_access_token/');
    yield put(getMoodyAccessTokenSuccess(response))
  } catch(e) {
    yield put(getMoodyAccessTokenFailure({ message: getError(e) }));
  }
}

export default [
  takeLatest(types.UPLOAD_REQUESTED_DOCUMENT_REQUEST, uploadRequestedDocument),
  takeLatest(
    types.UPDATE_REQUESTED_DOCUMENT_PROTECTION_REQUEST,
    updateRequestedDocumentProtection
  ),
  takeLatest(types.LOAN_DATA_REQUEST, getLoan),
  takeLatest(types.SUBMIT_FOR_VERIFICATION_REQUEST, submitForVerification),
  takeLatest(types.INVITE_LENDER_REQUEST, inviteLender),
  takeLatest(types.EXPORT_OFFERS_REQUEST, exportOffers),
  takeLatest(types.UPLOAD_SIGNED_TERMSHEET_REQUEST, uploadSignedTermsheet),
  takeLatest(types.REJECT_TERMSHEET_REQUEST, rejectTermsheet),
  takeLatest(types.DECLINE_QUESTION_REQUEST, declineQuestion),
  takeLatest(types.REQUEST_DOCUMENTS_REQUEST, requestDocuments),
  takeLatest(types.DOWNLOAD_ALL_DOCUMENTS_REQUEST, downloadAllDocuments),
  takeLatest(types.SUBMIT_TERMSHEET_REQUEST, submitTermsheet),
  takeLatest(types.POST_ANSWER_REQUEST, postAnswer),
  takeLatest(types.CANCEL_OFFER_REQUEST, cancelOffer),
  takeLatest(types.REJECT_OFFER_REQUEST, rejectOffer),
  takeLatest(types.ACCEPT_OFFER_REQUEST, acceptOffer),
  takeLatest(types.EDIT_OFFER_REQUEST, editOffer),
  takeLatest(types.NEGOTIATE_OFFER_REQUEST, negotiateOffer),
  takeLatest(types.SUBMIT_OFFER_REQUEST, submitOffer),
  takeLatest(types.POST_LOAN_NOTE_REQUEST, submitNote),
  takeLatest(types.GET_BROKER_LOAN_ROOM_REQUEST, getBrokerLoanRoom),
  takeLatest(types.CREATE_DEAL_ROOM_REQUEST, createDealRoom),
  takeLatest(
    types.ACCEPT_CONFIDENTIALITY_AGREEMENT_REQUEST,
    acceptConfidentialityAgreement
  ),
  takeLatest(types.SUBMIT_MANUAL_QUOTE_REQUEST, submitManualQuote),
  takeLatest(types.DELETE_NEGOTIATION_REQUEST, deleteNegotiation),
  takeLatest(types.EXPORT_STATUS_REPORT_REQUEST, exportStatusReportRequest),
  takeLatest(types.CHANGE_PROPERTY_NAME_REQUEST, changePropertyName),
  takeLatest(types.SET_LOAN_REMINDER_REQUEST, setLoanReminder),
  takeLatest(types.GET_MOODY_ACCESS_TOKEN_REQUEST, getMoodyAccessToken),
];
