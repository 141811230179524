import React from 'react';
import styled from 'styled-components/macro';
import { Input } from 'components/Form';
import { Field } from 'react-final-form';
import { BucketIcon } from 'components/SvgIcons';

import { DueDatePicker } from 'components/DueDatePicker';

const StyledInput = styled(Input)`
  width: 100%;
  margin-bottom: 0;

  input {
    border: none;
    background-color: #fafafb;
    padding-left: 0;

    & :focus,
    & :active {
      background-color: #fafafb;
    }
  }
`;

export const DealDocumentRequest = ({
  name,
  values,
  changeFieldValue,
  index,
  remove,
}) => {
  if (!values) return null;

  return (
    <Wrapper>
      <Field
        name={`${name}.name`}
        placeholder="Write task or document name"
        component={StyledInput}
      />
      <DueDatePicker
        currentDate={values.closingDate}
        onDayChange={(date) => {
          changeFieldValue(`${name}.closingDate`, date);
        }}
      />
      {index !== 0 && (
        <DeleteIcon
          type="button"
          onClick={() => {
            remove(index);
          }}
        >
          <BucketIcon hoverColor="#4394ea" />
        </DeleteIcon>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: 7px 24px 7px 0;
  border-top: 1px solid #e8e9ed;

  &:last-child {
    border-bottom: 1px solid #e8e9ed;
    margin-bottom: 16px;
  }
`;

const DeleteIcon = styled.button`
  position: absolute;
  right: 0;
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
  background-color: #fafafb;
  cursor: pointer;
`;
