import types from 'constants/actionTypes';
import immutable from 'object-path-immutable';
import { clone } from 'ramda';

const initialState = {
  listingData: {
    documents: [],
  },
  isFetched: false,
};

export default function listing(state, action = {}) {
  const currentState = state || clone(initialState);

  const { type, payload } = action;

  switch (type) {
    case types.GET_LISTING_SUCCESS:
      return { ...currentState, listingData: payload, isFetched: true };

    case types.SUBMIT_QUOTE_SUCCESS:
      return immutable.update(
        currentState,
        'listingData.preQualifications',
        (preQualifications) => [...preQualifications, payload]
      );

    case types.EDIT_QUOTE_SUCCESS:
      return immutable.update(
        currentState,
        'listingData.preQualifications',
        (preQualifications) =>
          preQualifications.map((quot) => (quot.id === payload.id ? payload : quot))
      );

    default:
      return currentState;
  }
}
