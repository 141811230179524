import types from 'constants/actionTypes';
import { merge } from 'ramda';
import { FLASH_MESSAGES } from 'constants/appRelated';

const initialState = {
  message: '',
  type: '',
  opened: false,
};

export default function alert(state = initialState, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case types.SHOW_ALERT: {
      return merge(state, {
        message: action.payload.message,
        type: action.payload.type,
        opened: !!action.payload.message,
      });
    }

    case types.SUPPORT_SUCCESS: {
      return merge(state, {
        message: 'Your request has been sent',
        type: FLASH_MESSAGES.SUCCESS,
        opened: true,
      });
    }
    case types.SUBMIT_LOAN_FORM_SUCCESS:
    case types.SUBMIT_NEW_LOAN_SUCCESS:
      return merge(state, {
        message: 'Your changes successfully saved',
        type: FLASH_MESSAGES.SUCCESS,
        opened: true,
      });

    case types.CLIENT_DIRECTORY_INVITE_SUCCESS:
      return merge(state, {
        message: 'Client successfully added',
        type: FLASH_MESSAGES.SUCCESS,
        opened: true,
      });

    case types.PASSWORD_RESET_SUCCESS: {
      return merge(state, {
        message: 'Password has been successfully reset',
        type: FLASH_MESSAGES.SUCCESS,
        opened: true,
      });
    }
    case types.CHANGE_USER_INFO_SUCCESS: {
      return merge(state, {
        message: 'Profile information has been successfully changed',
        type: FLASH_MESSAGES.SUCCESS,
        opened: true,
      });
    }
    case types.CHANGE_USER_PROFILE_SUCCESS: {
      return merge(state, {
        message: 'Sponsor profile information has been successfully changed',
        type: FLASH_MESSAGES.SUCCESS,
        opened: true,
      });
    }
    case types.CHANGE_LENDING_CRITERIA_SUCCESS: {
      return merge(state, {
        message: 'Lending criteria has been successfully changed',
        type: FLASH_MESSAGES.SUCCESS,
        opened: true,
      });
    }
    case types.REQUEST_DOCUMENTS_SUCCESS: {
      return merge(state, {
        message: 'Request has been successfully submitted',
        type: FLASH_MESSAGES.SUCCESS,
        opened: true,
      });
    }
    case types.UPLOAD_CHECK_LIST_SUCCESS: {
      return merge(state, {
        message: 'Check list has been successfully submitted',
        type: FLASH_MESSAGES.SUCCESS,
        opened: true,
      });
    }
    case types.ANSWER_LENDER_COMMENT_SUCCESS: {
      return {
        message: 'Your answer has been successfully submitted',
        type: FLASH_MESSAGES.SUCCESS,
        opened: true,
      };
    }
    case types.CONTACT_DIRECTORY_INVITE_SUCCESS: {
      return {
        message: 'Contact has been added',
        type: FLASH_MESSAGES.SUCCESS,
        opened: true,
      };
    }
    case types.RESEND_INVITE_SUCCESS: {
      return {
        message: 'Invitation has been send',
        type: FLASH_MESSAGES.SUCCESS,
        opened: true,
      };
    }
    case types.DEAL_DOCUMENTS_DOWNLOAD_SUCCESS: {
      return {
        message: 'Your download is being prepared and will be emailed to you shortly',
        type: FLASH_MESSAGES.SUCCESS,
        opened: true,
      };
    }
    case types.CHANGE_TEAM_DATA_SUCCESS: {
      return payload.logoUpdate
        ? {
            message: 'Your company logo was updated',
            type: FLASH_MESSAGES.SUCCESS,
            opened: true,
          }
        : state;
    }

    case types.DISMISS_ALERT: {
      return { message: null, type: null, opened: false };
    }
    case types.CHANGE_PASSWORD_SUCCESS: {
      return {
        message: 'Your password has been successfully changed',
        type: FLASH_MESSAGES.SUCCESS,
        opened: true,
      };
    }

    case types.REVOKE_ACCESS_SUCCESS: {
      return {
        message: 'You have successfully revoked access for lender',
        type: FLASH_MESSAGES.SUCCESS,
        opened: true,
      };
    }

    case types.LOG_PASS_SUCCESS: {
      return {
        message: 'You have successfully pass a Loan for lender',
        type: FLASH_MESSAGES.SUCCESS,
        opened: true,
      };
    }

    default:
      if (type.indexOf('_FAILURE') !== -1 && action.payload.message) {
        return merge(state, {
          message: action.payload.message,
          opened: !!action.payload.message,
        });
      }
      return state;
  }
}
