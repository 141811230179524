import { put, call, takeLatest, select } from 'redux-saga/effects';
import types from 'constants/actionTypes';
import { analytics, intercomIdentify } from 'lib/analytics';
import * as API from 'api';
import { push } from 'connected-react-router';
import { decamelizeKeys } from 'humps';
import 'firebase/messaging';
import { IDENTIFY } from 'constants/appRelated';
import { getIntercomSettings } from 'sagas/utils';

import {
  checkTeamMemberTokenSuccess,
  checkTeamMemberTokenFailure,
  teamMemberRegistrationSuccess,
  teamMemberRegistrationFailure,
} from './actions';

function* checkTeamMemberToken(action) {
  try {
    const { uuid } = action.payload;
    const {
      data: { response },
    } = yield call(API.axiosApi.post, '/membership/check', { uuid });

    yield put(checkTeamMemberTokenSuccess(response));
  } catch (error) {
    yield put(
      checkTeamMemberTokenFailure({ message: 'Wrong uuid or user already registered' })
    );
    yield put(push('/login'));
  }
}

function* registerTeamMember(action) {
  try {
    const { data } = action.payload;
    const {
      data: { response },
    } = yield call(API.axiosApi.post, '/membership/register', decamelizeKeys(data));
    yield put(push('/dashboard'));

    const state = yield select();
    const teamDataRequest = yield call(API.axiosApi.get, '/auth/team-settings');

    const { profile } = response;
    yield put(
      teamMemberRegistrationSuccess({
        profile: {
          ...profile,
          teamData: teamDataRequest.data.response,
          team: teamDataRequest.data.response.members,
        },
      })
    );

    analytics(IDENTIFY, profile.id, { name: profile.firstName, email: profile.email });
    intercomIdentify(
      getIntercomSettings({ ...profile, accountType: state.currentUser.accountType })
    );
  } catch (e) {
    yield put(teamMemberRegistrationFailure(e));
  }
}

export default [
  takeLatest(types.CHECK_TEAM_MEMBER_TOKEN_REQUEST, checkTeamMemberToken),
  takeLatest(types.TEAM_MEMBER_REGISTRATION_REQUEST, registerTeamMember),
];
