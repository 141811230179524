import React, { Component } from 'react';
import { Form, Field } from 'react-final-form';
import Yup from 'yup';
import { validate } from 'utils/validate';
import { Textarea } from 'components/Form';
import { postQuestionRequest } from 'actions';
import { Text } from 'ui/Text';
import { Button } from 'ui/Button';
import { ModalHeader, ModalBody, ModalControls } from 'ui/Modal/styles';

const schema = Yup.object().shape({
  question: Yup.string().required('Quesition is required!'),
});

class LoanQuestion extends Component {
  save = ({ question }) => {
    const { dispatch, id } = this.props;
    dispatch(
      postQuestionRequest({
        question,
        loanId: id,
      })
    );
  };

  render() {
    const { hideModal } = this.props;
    return (
      <Form
        onSubmit={this.save}
        validate={(values) => validate(values, schema)}
        render={({ handleSubmit, validating }) => (
          <form onSubmit={handleSubmit}>
            <ModalHeader />
            <ModalBody>
              <Text mb={3}>
                We welcome you to send any questions here. If you want your question to
                remain private, you can just mention it here. Also note, that the attached
                documents may contain the information you are looking for. Please review.
              </Text>
              <Field
                component={Textarea}
                placeholder="Question text"
                rows={6}
                name="question"
              />
              <ModalControls>
                <Button type="submit" mr={2}>
                  SAVE
                </Button>
                <Button secondary onClick={hideModal}>
                  CANCEL
                </Button>
              </ModalControls>
            </ModalBody>
          </form>
        )}
      />
    );
  }
}

export default LoanQuestion;
