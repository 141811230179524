import types from 'constants/actionTypes';

export const getMoreContactsRequest = (payload) => ({
  type: types.GET_MORE_CONTACTS_REQUEST,
  payload,
});

export const setContactsSearchString = (payload) => ({
  type: types.SET_CONTACTS_SEARCH_STRING,
  payload,
});

export const resetDirectory = () => ({
  type: types.RESET_CONTACT_DIRECTORY_DATA,
  payload: {},
});

export const hideContactCard = () => ({
  type: types.CLEAR_CONTACT_CARD,
  payload: {},
});

export const contactDirectoryInviteRequest = (payload = {}) => ({
  type: types.CONTACT_DIRECTORY_INVITE_REQUEST,
  payload,
});

export const contactDirectoryInviteSuccess = (payload = {}) => ({
  type: types.CONTACT_DIRECTORY_INVITE_SUCCESS,
  payload,
});

export const contactDirectoryInviteFailure = (payload = {}) => ({
  type: types.CONTACT_DIRECTORY_INVITE_FAILURE,
  payload,
});

export const getContactsDirectoryDataRequest = (payload) => ({
  type: types.GET_CONTACTS_DIRECTORY_DATA_REQUEST,
  payload,
});
export const getContactsDirectoryDataSuccess = (payload = {}) => ({
  type: types.GET_CONTACTS_DIRECTORY_DATA_SUCCESS,
  payload,
});
export const getContactsDirectoryDataFailure = (payload = {}) => ({
  type: types.GET_CONTACTS_DIRECTORY_DATA_FAILURE,
  payload,
});

export const updateContactsDirectoryDataRequest = (payload = {}) => ({
  type: types.UPDATE_CONTACTS_DIRECTORY_DATA_REQUEST,
  payload,
});
export const updateContactsDirectoryDataSuccess = (payload = {}) => ({
  type: types.UPDATE_CONTACTS_DIRECTORY_DATA_SUCCESS,
  payload,
});
export const updateContactsDirectoryDataFailure = (payload = {}) => ({
  type: types.UPDATE_CONTACTS_DIRECTORY_DATA_FAILURE,
  payload,
});

export const addTagToContactRequest = (payload = {}) => ({
  type: types.ADD_TAG_TO_CONTACT_REQUEST,
  payload,
});
export const addTagToContactSuccess = (payload = {}) => ({
  type: types.ADD_TAG_TO_CONTACT_SUCCESS,
  payload,
});
export const addTagToContactFailure = (payload = {}) => ({
  type: types.ADD_TAG_TO_CONTACT_FAILURE,
  payload,
});

export const removeTagFromContactRequest = (payload = {}) => ({
  type: types.REMOVE_TAG_FROM_CONTACT_REQUEST,
  payload,
});
export const removeTagFromContactSuccess = (payload = {}) => ({
  type: types.REMOVE_TAG_FROM_CONTACT_SUCCESS,
  payload,
});
export const removeTagFromContactFailure = (payload = {}) => ({
  type: types.REMOVE_TAG_FROM_CONTACT_FAILURE,
  payload,
});

export const addNoteToContactRequest = (payload = {}) => ({
  type: types.ADD_NOTE_TO_CONTACT_REQUEST,
  payload,
});
export const addNoteToContactSuccess = (payload = {}) => ({
  type: types.ADD_NOTE_TO_CONTACT_SUCCESS,
  payload,
});
export const addNoteToContactFailure = (payload = {}) => ({
  type: types.ADD_NOTE_TO_CONTACT_FAILURE,
  payload,
});

export const checkContactDirectoryEmailRequest = ({ email, resolve, reject }) => ({
  type: types.CHECK_CONTACT_DIRECTORY_EMAIL_REQUEST,
  payload: { email, resolve, reject },
});
export const checkContactDirectoryEmailSuccess = (payload = {}) => ({
  type: types.CHECK_CONTACT_DIRECTORY_EMAIL_SUCCESS,
  payload,
});
export const checkContactDirectoryEmailFailure = (payload = {}) => ({
  type: types.CHECK_CONTACT_DIRECTORY_EMAIL_FAILURE,
  payload,
});

export const archiveContactRequest = ({ contactId }) => ({
  type: types.ARCHIVE_CONTACT_REQUEST,
  payload: { contactId },
});
export const archiveContactSuccess = ({ contactId }) => ({
  type: types.ARCHIVE_CONTACT_SUCCESS,
  payload: { contactId },
});
export const archiveContactFailure = (payload = {}) => ({
  type: types.ARCHIVE_CONTACT_FAILURE,
  payload,
});

export const showContactRequest = ({ contactId }) => ({
  type: types.SHOW_CONTACT_CARD_REQUEST,
  payload: { contactId },
});

export const showContactSuccess = (payload) => ({
  type: types.SHOW_CONTACT_CARD_SUCCESS,
  payload,
});
export const showContactFailure = (payload = {}) => ({
  type: types.SHOW_CONTACT_CARD_FAILURE,
  payload,
});
