import styled from 'styled-components/macro';
import { ButtonGhost } from 'ui/Button';
import { Text } from 'ui/Text';

export const Wrapper = styled.div`
  box-shadow: 0 4px 10px rgba(194, 198, 210, 0.2);
  border-radius: 8px;

  @media (max-width: ${(p) => p.theme.breakpoints[2]}px) {
    box-shadow: none;
  }
`;

export const Content = styled.div``;

export const Line = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e4e7ea;
  background-color: #fff;
`;

export const LenderLine = styled(Line)`
  @media (max-width: ${(p) => p.theme.breakpoints[2]}px) {
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: 24px;
    padding-right: 10px;
    border: none;
    box-shadow: 0 4px 10px rgba(194, 198, 210, 0.2);
    border-radius: 8px;
  }
`;

export const AnalyticsLine = styled(Line)`
  @media (max-width: ${(p) => p.theme.breakpoints[2]}px) {
    flex-direction: column;
    padding: 16px;
    margin-bottom: 16px;
    box-shadow: 0 4px 10px rgba(194, 198, 210, 0.2);
    border-radius: 8px;
  }
`;

export const LineDetails = styled.div`
  display: flex;
  align-items: center;
  flex: 3 0;

  @media (max-width: ${(p) => p.theme.breakpoints[2]}px) {
    display: ${(p) => (p.visible ? 'block' : 'none')};
    width: 100%;
    padding: 0 16px;
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const LineItem = styled.div`
  padding-top: 24px;
  padding-bottom: 20px;
  align-self: flex-start;
`;

export const LineLenderItem = styled.div`
  flex: 1 0;
  min-width: 256px;

  @media (max-width: ${(p) => p.theme.breakpoints[2]}px) {
    max-width: 100%;
    overflow: hidden;
    min-width: unset;
  }
`;

export const LineWideItem = styled(LineItem)`
  flex: 2 0;
  /* margin-right: 8px; */

  @media (max-width: ${(p) => p.theme.breakpoints[2]}px) {
    width: 100%;
    margin-bottom: 24px;
    border-bottom: 1px solid #e4e7ea;
  }
`;

export const LineWideItemAnalytics = styled(LineItem)`
  flex: 3 0;
  padding-left: 32px;

  @media (max-width: ${(p) => p.theme.breakpoints[2]}px) {
    margin-bottom: 32px;
    padding: 0;
  }
`;

export const LineAnalyticsLenders = styled(LineWideItemAnalytics)`
  @media (max-width: ${(p) => p.theme.breakpoints[2]}px) {
    margin-bottom: 30px;
    border-bottom: none;
  }
`;

export const LineItemAnalytics = styled(LineItem)`
  flex: 1 0;
  align-self: center;
  text-align: center;

  @media (max-width: ${(p) => p.theme.breakpoints[2]}px) {
    display: flex;
    justify-content: space-between;
    width: 100%;
    border-bottom: 1px solid #e4e7ea;
    padding: 8px;
    color: #778598;

    &::before {
      content: attr(data-title);
    }
  }
`;

export const LineItemLocation = styled(LineItem)`
  flex: 1 0;
`;

export const Headline = styled.div`
  border-bottom: 1px solid #e4e7ea;
  display: flex;
  align-items: center;
  color: #95999e;
  font-weight: bold;
  font-size: 16px;

  background: #fff;
  position: sticky;
  top: 68px;
  z-index: 10;
  @media (max-width: ${(p) => p.theme.breakpoints[2]}px) {
    display: none;
  }
`;

export const LineItemActions = styled(LineItem)`
  width: 72px;
  display: flex;
  justify-content: center;
`;

export const LineControlsHead = styled.div`
  display: flex;
  align-items: center;
  flex: 1 0;
  margin-right: 25px;
`;

export const LineControls = styled(LineItem)`
  display: flex;
  align-items: center;
  flex: 1 0;
  margin-right: 25px;

  @media (max-width: ${(p) => p.theme.breakpoints[2]}px) {
    width: 100%;
    margin-right: 0;
  }
`;

export const ItemsTitle = styled.p`
  margin: 0;
  font-size: 16px;
  color: #95999e;
  line-height: 20px;

  @media (min-width: ${(p) => p.theme.breakpoints[2]}px) {
    display: none;
  }
`;

export const ResendIcon = styled.div`
  cursor: pointer;
  width: 15px;
  height: 16px;
  background: url('/images/icons/ico_resend.svg') no-repeat;
`;

export const ChatIcon = styled.div`
  position: relative;
  top: 2px;
  display: block;
  width: 16px;
  height: 16px;
  background: url(/images/icons/ico_chat_blue.svg) no-repeat center;
`;

export const UserIcon = styled.div`
  display: block;
  width: 16px;
  height: 16px;
  background: url(/images/icons/ico_usercard_blue.svg) no-repeat center;
`;

export const EntityWrapper = styled.div`
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  margin-bottom: 16px;
  box-shadow: 0px 4px 10px rgba(194, 198, 210, 0.2);
  border-radius: 4px;
  border: 2px solid ${(p) => (p.active ? '#4394EA' : '#fff')};

  @media screen and (max-width: ${(p) => p.theme.breakpoints[0]}px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const EntityActions = styled.div`
  ${ButtonGhost} {
    margin-left: 16px;
  }

  @media screen and (max-width: ${(p) => p.theme.breakpoints[0]}px) {
    margin-top: 12px;
  }
`;

export const MainSection = styled.div`
  width: 57%;

  @media screen and (max-width: ${(p) => p.theme.breakpoints[2]}px) {
    width: 100%;
  }
`;

export const ItemsCountLabel = styled(Text)`
  color: #778598;
  margin-bottom: 16px;
`;
