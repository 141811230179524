import React from 'react';
import styled from 'styled-components/macro';
import {connect} from 'react-redux'
import { AddClient } from '../../../../components/Modals';

export const CLIENT_SUCCESSFULLY_ADDED = 'Client successfully added';

const AddNewClient = (props) => {
  const {lenderLoanCreation, closeModal} = props;
  return (
    <Modal>
      <FormWrapper>
        <IcoClose
          onClick={() => closeModal(false)}
        />
        <AddClient
          lenderLoanCreation={lenderLoanCreation}
          closeModal={closeModal}
          {...props}
        />
      </FormWrapper>
    </Modal>
  );
};

const Modal = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 9999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(125, 125, 125, 0.5);
`;

const FormWrapper = styled.div`
  max-width: 460px;
  min-height: 600px;
  padding: 20px;
  background-color: #FAFAFB;
  border-radius: 5px;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const IcoClose = styled.div`
  position: absolute;
  top: 17px;
  right: 17px;
  width: 14px;
  height: 14px;
  background: url('/images/nav/ico_close.svg') no-repeat;
  cursor: pointer;
  z-index: 99999;
`;

export default connect()(AddNewClient);
