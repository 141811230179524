import { put, takeLatest, call, select } from 'redux-saga/effects';
import types from 'constants/actionTypes';
import * as API from 'api';
import { marketAnalyticsFiltersConfig } from 'features/Analytics/config';
import { makeUrlStringFromData, makeObjFromUrlString } from 'lib/url';
import { setCurrentUri } from 'actions';
import { history } from 'store';
import { groupOptions, RANGE_DAYS } from 'features/Analytics/config';
import { subDays } from 'date-fns';
import { analytics } from 'lib/analytics';
import { TRACK } from 'constants/appRelated';
import { downloadBlobFile } from 'lib/browser';
import { userTypeSelector } from 'selectors/userType';

import {
  getMarketAnalyticsSuccess,
  getMarketAnalyticsFailure,
  updateMarketAnalyticsSuccess,
  updateMarketAnalyticsFailure,
  trackAnalyticsSuccess,
  trackAnalyticsFailure,
  exportAnalyticsDataSuccess,
  exportAnalyticsDataFailure,
} from './actions';

function* getMarketAnalytics() {
  try {
    const { search } = history.location;
    const { filter } = yield select();

    // Setup initial value for store from uri string

    const {
      data: { grouping, timestampBefore, timestampAfter, showForUser },
    } = makeObjFromUrlString({
      uri: search,
      config: marketAnalyticsFiltersConfig,
    });

    // Setup default time options for state and initial data request
    const initialData = {
      grouping: grouping || groupOptions[0]?.value,
      timestampBefore: timestampBefore ? new Date(timestampBefore) : new Date(),
      timestampAfter: timestampAfter
        ? new Date(timestampAfter)
        : subDays(new Date(), RANGE_DAYS.QUARTER),
      showForUser,
    };

    const marketAnalyticsFiltersUri = makeUrlStringFromData({
      data: { ...initialData },
      config: marketAnalyticsFiltersConfig,
    }).replace('?', '');

    const requestUri = [filter.uri, marketAnalyticsFiltersUri].filter(Boolean).join('&');
    history.push(`?${requestUri}`);
    yield put(setCurrentUri(requestUri));

    const {
      data: { response },
    } = yield call(
      API.axiosApi.get,
      `/analytics/market/${requestUri ? `?${requestUri}` : ''}`
    );

    yield put(getMarketAnalyticsSuccess({ response, ...initialData }));
  } catch (e) {
    yield put(getMarketAnalyticsFailure(e));
  }
}

function* updateMarketAnalytics() {
  try {
    const {
      filter,
      marketAnalytics: { grouping, timestampAfter, timestampBefore, showForUser },
    } = yield select();

    const marketAnalyticsFiltersUri = makeUrlStringFromData({
      data: { grouping, timestampAfter, timestampBefore, showForUser },
      config: marketAnalyticsFiltersConfig,
    }).replace('?', '');

    const requestUri = [filter.uri, marketAnalyticsFiltersUri].filter(Boolean).join('&');

    history.push(`?${requestUri}`);
    yield put(setCurrentUri(requestUri));

    const {
      data: { response },
    } = yield call(
      API.axiosApi.get,
      `/analytics/market/${requestUri ? `?${requestUri}` : ''}`
    );

    yield put(updateMarketAnalyticsSuccess({ response }));
  } catch (e) {
    yield put(updateMarketAnalyticsFailure(e));
  }
}

function* trackAnalytics(action) {
  try {
    const { event } = action.payload;
    // TODO implement proper logic with yield call
    analytics(TRACK, event);
    yield put(trackAnalyticsSuccess());
  } catch (e) {
    yield put(trackAnalyticsFailure());
  }
}

function* exportAnalyticsData() {
  try {
    const state = yield select();
    const userType = userTypeSelector(state);
    const isUriForLenders = userType.lender ? 'loans-lender' : 'loans';
    const {
      marketAnalytics: { grouping, timestampAfter, timestampBefore },
      filter,
    } = state;

    const marketAnalyticsFiltersUri = makeUrlStringFromData({
      data: { grouping, timestampAfter, timestampBefore },
      config: marketAnalyticsFiltersConfig,
    }).replace('?', '');

    const requestUri = [filter.uri, marketAnalyticsFiltersUri].filter(Boolean).join('&');
    const { data, headers } = yield call(
      API.axiosApiRaw.get,
      `/analytics/market/${isUriForLenders}/export?${requestUri}`,
      {
        responseType: 'blob',
      }
    );

    downloadBlobFile(data, headers['content-disposition'].split('=')[1]);
    yield put(exportAnalyticsDataSuccess());
  } catch (e) {
    yield put(exportAnalyticsDataFailure(e));
  }
}

export default [
  takeLatest(types.UPDATE_MARKET_ANALYTICS_REQUEST, updateMarketAnalytics),
  takeLatest(types.GET_MARKET_ANALYTICS_REQUEST, getMarketAnalytics),
  takeLatest(types.TRACK_ANALYTICS_REQUEST, trackAnalytics),
  takeLatest(types.EXPORT_ANALYTICS_DATA_REQUEST, exportAnalyticsData),
];
