import React from 'react';
import MyCalendars from 'containers/MyTasksWrapper/components/SyncTasks/MyCalendars'
import styled from 'styled-components/macro';

import {
    ModalBody,
    ModalHeading,
    modalWithContentWrapperStyles,
    ModalSubHeading,
  } from 'ui/Modal/styles';


const SyncTask = ({hideModal, initConnected}) => {

    return (
        <Wrapper>
            <ModalHeadingExtends>
                Calendar syncing
            </ModalHeadingExtends>
            <ModalSubHeadingExtends>
                Sync your Tasks with your Google Calendar or Outlook account.
            </ModalSubHeadingExtends>
            <ModalBody>
                <MyCalendars initConnected={initConnected} hideModal={hideModal}/>
            </ModalBody>
        </Wrapper>
    );
}

export default SyncTask;

const Wrapper = styled.div`
    ${modalWithContentWrapperStyles};
`;

const ModalHeadingExtends = styled(ModalHeading)`
    text-align: start;
    margin-top: 10px;
    padding: 20px 0 10px 25px !important;

`;

const ModalSubHeadingExtends = styled(ModalSubHeading)`
    text-align: start;
    margin-left: 25px;
    margin-bottom: 0px;
`;