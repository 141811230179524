import React from 'react';
import styled from 'styled-components/macro';
import { Field } from 'react-final-form';
import { Input, DropdownList, Condition, Switch } from 'components/Form';
import {
  normalizeDollars,
  normalizeNumbers,
  normalizeNumbersLimit,
  normalizeDigitsLimit,
} from 'lib/normalizers';
import { InputSelect } from 'ui/InputSelect';
import { unitOptions } from 'utils/fields';
import { FIELD_TYPES } from 'constants/appRelated';

const PercentField = styled.div`
  width: 70px;
  position: relative;

  &::after {
    content: '%';
    position: absolute;
    right: -15px;
    top: 50%;
    transform: translateY(-50%);
  }
`;

const IndexSpreadField = styled.div`
  display: flex;
  align-items: center;

  & > div {
    flex: 1;
  }

  span {
    margin: 0 3px;
  }
`;

const EditableValue = ({ row, isLender }) => {
  const { field, type, options, onSelectedTrue, title1, title2, placeholder } = row;

  switch (type) {
    case FIELD_TYPES.TEXT:
      return <Field component={Input} name={field} fullWidth mb={0} />;
    case FIELD_TYPES.PERCENT:
      return (
        <PercentField>
          <Field
            component={Input}
            name={field}
            parse={normalizeNumbersLimit}
            fullWidth
            mb={0}
          />
        </PercentField>
      );
    case FIELD_TYPES.DOLLARS:
      return (
        <Field component={Input} name={field} parse={normalizeDollars} fullWidth mb={0} />
      );
    case FIELD_TYPES.DURATION:
      return (
        <InputSelect
          options={unitOptions}
          inputPlaceholder="0"
          parse={normalizeNumbers}
          inputName={field}
          selectName={`${field}Unit`}
        />
      );
    case FIELD_TYPES.TOGGLE:
      return (
        <div>
          <Field
            component={Switch}
            name={field}
            value2={title2 || 'Yes'}
            value1={title1 || 'No'}
            type="checkbox"
          />
          {onSelectedTrue && (
            <Condition when={field} is>
              <small>{onSelectedTrue}</small>
            </Condition>
          )}
        </div>
      );
    case FIELD_TYPES.CHOICE:
      return (
        <div>
          {field === 'index' && !isLender ? null : (
            <Field
              component={DropdownList}
              options={options}
              name={field}
              placeholder={placeholder}
            />
          )}
        </div>
      );
    case 'indexSpread': {
      const [field1, field2] = field;
      return (
        <IndexSpreadField>
          <Field
            component={DropdownList}
            options={options[field1]}
            name={field1}
            placeholder={placeholder}
          />
          <span>+</span>
          <Field component={Input} name={field2} fullWidth mb={0} />
        </IndexSpreadField>
      );
    }
    case FIELD_TYPES.LIMIT_NUMBER: {
      return (
        <Field
          component={Input}
          name={field}
          parse={normalizeNumbersLimit}
          fullWidth
          mb={0}
        />
      );
    }

    case FIELD_TYPES.NUMBER:
      return (
        <Field component={Input} name={field} parse={normalizeNumbers} fullWidth mb={0} />
      );

    case FIELD_TYPES.FLOAT:
      return (
        <Field
          component={Input}
          name={field}
          parse={normalizeDigitsLimit}
          fullWidth
          mb={0}
        />
      );

    default:
      return 'N/A';
  }
};

export { EditableValue };
