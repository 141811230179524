import React, { Fragment, Component } from 'react';
import { Form, FormSpy } from 'react-final-form';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import Yup from 'yup';
import * as API from 'api';
import { validate, validateEmail } from 'utils/validate';
import { delay, simpleMemoize } from 'lib/utils';
import {
  URL_REGEXP,
  LENDER,
  BORROWER,
  BROKER,
  EMAIL_STATUS,
  TRACK,
  SIGN_UP_EVENTS,
} from 'constants/appRelated';
import { Condition } from 'components/Form';
import { zones, source } from 'utils/fields';
import { emailExistsRequest, setCookieRequest } from 'actions';
import { showModal } from 'actions/modalActions';
import { modals, INFO_MODAL_TYPE } from 'constants/modalTypes';
import { LockFilledIcon } from 'components/SvgIcons';
import qs from 'query-string';
import { analytics } from 'lib/analytics';
import { NavLink } from 'react-router-dom';

import {
  LenderFields,
  AccountTypes,
  BorrowerFields,
  BrokerFields,
  PrivacyCheckbox,
} from './components';

import {
  WrapperForm,
  SubmitButton,
  RelatedLinksGroup,
  RelatedLink,
  Heading,
  SslText,
} from './styles';

import { resendInviteRequest, registrationRequest } from './actions';
import { accountTypeToNum } from './helpers';
import { setCookie } from '../../utils/cookie';

const borrowerTypes = [
  { label: 'Entity', value: 1 },
  { label: 'Individual', value: 2 },
];

export const INVITATION_UUID = 'invitation_uuid';
export const AJS_USER_ID = 'ajs_user_id1';

class RegistrationToWrap extends Component {
  state = {
    accountType: null,
    invitedUserModalHasBeenOpened: false,
    invitedByLender: null,
  };

  componentDidMount() {
    analytics(TRACK, SIGN_UP_EVENTS.SIGN_UP_VIEW);
    if (this.props?.match?.params?.accountType) {
      const accountType = accountTypeToNum(this.props?.match?.params?.accountType);
      this.setState({ accountType: accountType || null });
    }

    const { match, dispatch, currentUser } = this.props;
    if (match?.params && match?.params.lenderName && match?.params?.uniqueKey) {
      const { uniqueKey } = match?.params;
      this.setState({ invitedByLender: uniqueKey });
      setCookie(INVITATION_UUID, uniqueKey, 86400);
      dispatch(setCookieRequest({ cookie: `${INVITATION_UUID}=${uniqueKey}` }));
      if (currentUser.id) {
        dispatch(push('/dashboard/loan-creation'));
        // It doesn't feel right
        API.axiosApi.post('/auth/check-invite-to-client-directory/');
      }
    }
  }

  onFormChange = ({ values }) => {
    const { accountType } = this.state;

    if (values.accountType && accountType !== values.accountType) {
      this.setState({ accountType: values.accountType });
    }
  };

  asyncValidate = simpleMemoize(async (email) => {
    const { dispatch } = this.props;
    const { accountType } = this.state;

    if (!email) {
      return 'Email is required';
    }

    if (!validateEmail(email)) {
      return 'Invalid email address';
    }

    /*
      Async email validation with api request calling
    */
    if (email) {
      const res = await delay(1000).then(() =>
        new Promise((resolve, reject) => {
          dispatch(
            emailExistsRequest({
              email,
              resolve,
              reject,
              accountType,
            })
          );
        }).then(
          (v) => v,
          ({ error, status }) => {
            /*
              Condition to handle attempt to using email
              that already has been added to platform
            */
            if (
              status === EMAIL_STATUS.PENDING_INVITE
            ) {
              this.openInvitedUserModal(email);
            }
            if(status === EMAIL_STATUS.EXISTS){
              return { error, status };
            }
          }
        )
      );

      if (res?.status === 'exists') {
        return (
          <>
            This email is already used, please{' '}
            <NavLink activeClassName="active" to={{
              pathname:`/login`,
              search: `?email=${email}`,
            }}>login</NavLink>{' '}
            instead.
          </>
        );
      }
      return res;
    }
  });

  formSubmit = (values) => {
    const { dispatch, location } = this.props;
    const { loanId } = (location && qs.parse(location.search)) || {};
    analytics(TRACK, SIGN_UP_EVENTS.SIGN_UP_SUBMIT);
    dispatch(registrationRequest({ values, loanId }));
  };

  openInvitedUserModal = (email) => {
    const { dispatch } = this.props;

    dispatch(
      showModal(modals.INFO_MODAL, {
        size: 'sm',
        infoType: INFO_MODAL_TYPE.PROCESS,
        title: 'It seems you were invited to RealAtom already.',
        text: 'Enter a different email or sign up using the link from invitation email',
        buttonText: 'Resend invitation',
        buttonRejectText: 'Use different email',
        onButtonClick: (dispatch) => {
          dispatch(resendInviteRequest({ email }));
        },
      })
    );

    this.setState({ invitedUserModalHasBeenOpened: true });
  };

  render() {
    const isDirectRoleRegistration = this.props?.match?.params?.accountType;
    const { invitedByLender } = this.state;
    const { loanId } =
      (this.props.location && qs.parse(this.props.location.search)) || {};
    return (
      <Form
        onSubmit={this.formSubmit}
        initialValues={{ accountType: this.state.accountType }}
        validate={(values) =>
          validate(values, schema, { accountType: this.state.accountType })
        }
        render={({
          handleSubmit,
          validating,
          values: { entityType, llc, privacy },
          errors,
          touched,
        }) => (
          <WrapperForm onSubmit={handleSubmit}>
            <FormSpy onChange={this.onFormChange} />

            <RelatedLinksGroup>
              <RelatedLink
                activeClassName="active"
                to={`/login${loanId ? '?loanId=' : ''}${loanId || ''}`}
              >
                Log In
              </RelatedLink>
              <RelatedLink
                activeClassName="active"
                to={`/registration${loanId ? '?loanId=' : ''}${loanId || ''}`}
              >
                Register
              </RelatedLink>
            </RelatedLinksGroup>
            <Heading>Welcome to RealAtom registration </Heading>
            <AccountTypes invitedByLender={invitedByLender} />

            {/*
              Set of fields for registration a different types of user
            */}
            <Condition when="accountType" is={LENDER}>
              <LenderFields
                showMobilePhoneHint={!touched.mobilePhone || !errors.mobilePhone}
                zones={zones}
                source={source}
                validateEmail={this.asyncValidate}
              />
            </Condition>
            <Condition when="accountType" is={BORROWER}>
              <BorrowerFields
                showMobilePhoneHint={!touched.mobilePhone || !errors.mobilePhone}
                borrowerTypes={borrowerTypes}
                zones={zones}
                source={source}
                formValues={{ entityType, llc }}
                validateEmail={this.asyncValidate}
              />
            </Condition>
            <Condition when="accountType" is={BROKER}>
              <BrokerFields
                showMobilePhoneHint={!touched.mobilePhone || !errors.mobilePhone}
                borrowerTypes={borrowerTypes}
                zones={zones}
                source={source}
                formValues={{ entityType, llc }}
                validateEmail={this.asyncValidate}
              />
            </Condition>
            {this.state?.accountType && (
              <Fragment>
                <PrivacyCheckbox />
                <SubmitButton type="submit" secondary disabled={!privacy}>
                  Finish registration
                </SubmitButton>
              </Fragment>
            )}
            <SslText>
              <LockFilledIcon color="#44AF69" />
              Any information provided by users is transmitted using the Secure Socket
              Layer (SSL) protocol, and stored on hardened servers with strict access
              control policies.
            </SslText>
          </WrapperForm>
        )}
      />
    );
  }
}

const schema = Yup.object().shape({
  password: Yup.string()
    .min(8, 'Password must be at least 8 characters')
    .required('Password is required'),
  first_name: Yup.string().required('First name is required'),
  last_name: Yup.string().required('Last name is required'),
  accountType: Yup.number(),
  llc: Yup.bool(),
  privacy: Yup.boolean()
    .oneOf([true], 'Please accept our terms and conditions')
    .required('Please accept our terms and conditions'),
  business_url: Yup.string()
    .matches(URL_REGEXP, 'Please enter valid url')
    .max(200, 'Please enter shorter URL')
    .when('accountType', (accountType, schema) =>
      accountType === LENDER ? schema.required('Company url is required') : schema
    ),
  entityType: Yup.object()
    .nullable()
    .when('accountType', (accountType, schema) =>
      accountType === BORROWER ? schema.required('Borrower type is required') : schema
    ),
  title: Yup.string().when(
    ['accountType', 'entityType'],
    (accountType, entityType, schema) =>
      (accountType === BORROWER &&
        entityType &&
        entityType.value === borrowerTypes[0].value) || // DropdownList component save current option only in object in form state
        accountType === BROKER
        ? schema.required('Title is required')
        : schema
  ),
  business_name: Yup.string().when(
    ['accountType', 'llc', 'entityType'],
    (accountType, llc, entityType, schema) =>
      accountType === LENDER ||
        accountType === BROKER ||
        (accountType === BORROWER &&
          !llc &&
          entityType &&
          entityType.value === borrowerTypes[0].value)
        ? schema.required(
          accountType === BORROWER
            ? 'Entity name is required'
            : 'Business name is required'
        )
        : schema
  ),
  mobilePhone: Yup.string().required('Mobile phone is required'),
  phone: Yup.string().when('accountType', (accountType, schema) =>
    accountType === LENDER ? schema.required('Work phone is required') : schema
  ),
});

function mapStateToProps(state) {
  return {
    currentUser: state.currentUser,
  };
}

export let Registration = connect(mapStateToProps)(RegistrationToWrap);
