import types from 'constants/actionTypes';
import { findIndex } from 'ramda';
import { OFFER_STATE } from 'constants/appRelated';

import immutable from 'object-path-immutable';

const initialState = {
  data: {
    negotiations: {},
    questions: [],
    documents: [],
    sponsorProfile: {},
    requestedDocuments: [],
    photos: [],
    sectionVisibility: {},
    notes: [],
  },
  id: null,
  options: {},
  isFetched: false,
  invitedLenders: [],
  loanDisplayingData: {},
  redirectionPathOnLoanUpdate: null, // The path is need for implement redirection after action on modal from task RAD-6355
};

export default function loan(state, action = {}) {
  const currentState = state || initialState;

  const { type, payload } = action;

  switch (type) {
    case types.LOAN_DATA_SUCCESS:
      return {
        ...currentState,
        data: {
          ...currentState.data,
          ...payload.loan,
          loanId: payload.loan.id,
          id: payload.loan.uuid,
        },
        id: payload.loan.id,
        isFetched: true,
        loanDisplayingData: payload.loanDisplayingData,
      };

    case types.STANDALONE_LOAN_DATA_SUCCESS:
    case types.SUBMIT_FOR_VERIFICATION_SUCCESS:
    case types.REQUEST_DOCUMENTS_SUCCESS:
      return {
        ...currentState,
        data: {
          ...currentState.data,
          ...payload,
        },
        id: payload.id,
        isFetched: true,
      };

    case types.ACCEPT_CONFIDENTIALITY_AGREEMENT_SUCCESS:
      return {
        ...currentState,
        data: {
          ...currentState.data,
          ...payload,
        },
      };

    case types.POST_ANSWER_SUCCESS:
      return immutable.update(state, 'data.questions', (questions) =>
        questions.map((item) => (item.id === action.payload.id ? action.payload : item))
      );

    case types.POST_QUESTION_SUCCESS: {
      return currentState.id === null
        ? currentState
        : immutable(state)
            .update('data.questions', (questions) => [...questions, action.payload])
            .set('data.previouslyInteractedWithLoan', true)
            .value();
    }

    case types.UPLOAD_REQUESTED_DOCUMENT_SUCCESS:
      return immutable.update(state, 'data.documentsPending', (documentsPending) =>
        documentsPending.map((item) =>
          item.lender.pk === payload.lenderPk
            ? immutable.update(
                item,
                payload.sectionId ? 'sections' : 'recommended',
                (documents) => {
                  if (payload.sectionId) {
                    return documents.map((section) =>
                      section.sectionId === payload.sectionId
                        ? immutable.update(section, 'folders', (folders) =>
                            folders.map((folder) =>
                              folder.id === payload.folderId
                                ? { ...folder, uploaded: true }
                                : folder
                            )
                          )
                        : section
                    );
                  } else {
                    return documents.map((folder) =>
                      folder.id === payload.folderId
                        ? { ...folder, uploaded: true }
                        : folder
                    );
                  }
                }
              )
            : item
        )
      );

    case types.UPDATE_REQUESTED_DOCUMENT_PROTECTION_SUCCESS:
      return immutable.update(state, 'data.documentsPending', (documentsPending) =>
        documentsPending.map((item) =>
          item.lender.pk === payload.lenderPk
            ? immutable.update(
                item,
                payload.sectionId ? 'sections' : 'recommended',
                (documents) => {
                  if (payload.sectionId) {
                    return documents.map((section) =>
                      section.sectionId === payload.sectionId
                        ? immutable.update(section, 'folders', (folders) =>
                            folders.map((folder) =>
                              folder.id === payload.folderId
                                ? { ...folder, protected: payload.protectionState }
                                : folder
                            )
                          )
                        : section
                    );
                  } else {
                    return documents.map((folder) =>
                      folder.id === payload.folderId
                        ? { ...folder, protected: payload.protectionState }
                        : folder
                    );
                  }
                }
              )
            : item
        )
      );

    case types.RESET_LOAN_INFO: {
      return initialState;
    }

    case types.CANCEL_OFFER_SUCCESS:
      const { id, negotiation_pk } = action.payload;
      const negotiations = currentState?.data?.negotiations?.[id]?.negotiations;

      if (!negotiations) return currentState;

      const offerIndex = findIndex(({ pk }) => pk === negotiation_pk, negotiations);

      return immutable.update(
        currentState,
        `data.negotiations.${id}.negotiations.${offerIndex}`,
        (offer) => ({ ...offer, state: OFFER_STATE.CANCELLED })
      );

    case types.POST_LOAN_NOTE_SUCCESS:
      return {
        ...currentState,
        data: {
          ...currentState.data,
          notes: [...currentState.data.notes, action.payload],
        },
      };

    case types.CHANGE_PROPERTY_NAME_SUCCESS:
      const { propertyName } = action.payload;

      return {
        ...currentState,
        data: {
          ...currentState.data,
          propertyName: propertyName,
        },
      };

    case types.CLOSE_LOAN_SUCCESS:
      const { status, closedOn } = action.payload;

      return {
        ...currentState,
        data: {
          ...currentState.data,
          isClosed: true,
          status,
          closedOn,
        },
      };

    case types.DOWNLOAD_ALL_DOCUMENTS_SUCCESS:
    case types.SET_LOAN_REMINDER_SUCCESS:
    case types.ASSIGN_LOAN_TO_LENDER_REQUEST:
      return {
        ...currentState,
        data: {
          ...currentState.data,
          previouslyInteractedWithLoan: true,
        },
      };

    case types.HIDE_LOAN_SUCCESS:
      return currentState.isFetched
        ? {
            ...currentState,
            data: {
              ...currentState.data,
              previouslyInteractedWithLoan: true,
            },
          }
        : currentState;

    case types.SET_REDIRECTION_PATH_ON_LOAN_UPDATE:
      return {
        ...currentState,
        redirectionPathOnLoanUpdate: action.payload,
      };


    case types.GET_MOODY_ACCESS_TOKEN_SUCCESS:
      const {accessToken, expiresIn, created} = action.payload;

      return {
        ...currentState,
        data: {
          ...currentState.data,
          moody: {
            accessToken,
            expiresIn,
            created
          },
        },
    }

    default:
      return currentState;
  }
}
