import React, { useState } from 'react';
import styled, { css } from 'styled-components/macro';
import { format } from 'date-fns';
import PropTypes from 'prop-types';

import DayPickerInput from 'react-day-picker/DayPickerInput';
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';

const currentDate = new Date();
const currentYear = currentDate.getFullYear();
const fromMonth = new Date(currentYear - 61, 1);
const toMonth = new Date(currentYear + 15, 1);

export const DatePicker = ({
  onChange,
  value,
  error,
  minDate,
  placeholder,
  variant,
  withoutInput,
  dropDown,
  semiRequired,
  ...rest
}) => {
  const [month, setMonth] = useState(currentDate);

  const dayPickerProps = {
    disabledDays: { before: minDate || null },
    month,
    captionElement: ({ date, localeUtils }) => (
      <YearMonthForm date={date} localeUtils={localeUtils} onChange={setMonth} />
    ),
  };

  const restProps = {
    value,
    placeholder: placeholder || 'MM\/dd\/yyyy',
    formatDate: (date) => format(new Date(date), 'MM\/dd\/yyyy'),
  };

  return (
    <Wrapper
      error={error}
      variant={variant}
      withoutInput={withoutInput}
      dropDown={dropDown}
      semiRequired={semiRequired}
    >
      {withoutInput ? (
        <DayPicker {...rest} {...dayPickerProps} {...restProps} onDayClick={onChange} />
      ) : (
        <DayPickerInput
          {...rest}
          {...restProps}
          onDayChange={onChange}
          dayPickerProps={dayPickerProps}
        />
      )}
    </Wrapper>
  );
};

DatePicker.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  minDate: PropTypes.string,
  placeholder: PropTypes.string,
  variant: PropTypes.string,
  withoutInput: PropTypes.bool,
};

function YearMonthForm({ date, localeUtils, onChange }) {
  const months = localeUtils.getMonths();

  const years = [];
  for (let i = fromMonth.getFullYear(); i <= toMonth.getFullYear(); i += 1) {
    years.push(i);
  }

  const handleChange = function handleChange(e) {
    const { year, month } = e.target.form;
    onChange(new Date(year.value, month.value));
  };

  return (
    <form className="DayPicker-Caption">
      <select name="month" onChange={handleChange} value={date.getMonth()}>
        {months.map((month, i) => (
          <option key={month} value={i}>
            {month}
          </option>
        ))}
      </select>
      <select name="year" onChange={handleChange} value={date.getFullYear()}>
        {years.map((year) => (
          <option key={year} value={year}>
            {year}
          </option>
        ))}
      </select>
    </form>
  );
}

const WrapperCommon = styled.div`
  position: relative;
  background: #fff;

  &::after {
    content: url('/images/icons/ico_calendar.svg');
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
  }

  .DayPickerInput {
    display: block;
    z-index: 10;

    input {
      width: 100%;
      border-radius: 4px;
      padding: 7px 16px;
      border: 1px solid ${(p) => (p.semiRequired ? '#ff9e01' : '#cfd0d7')};
      font-size: ${(p) => p.theme.fontSizes[0]}px;
      outline: none;
      background-color: transparent;
      box-sizing: border-box;
      cursor: pointer;

      &:focus {
        border-color: ${(p) => p.theme.colors.blues[6]};
      }

      ::-ms-clear {
        display: none;
      }
    }
  }

  .DayPicker-wrapper {
    background-color: #fff;
    box-shadow: ${(p) => p.theme.shadow};
    border-radius: 4px;
  }

  .DayPickerInput-Overlay {
    //bottom: ${(p) => (p.dropDown ? 'unset' : 'calc(100% + 45px)')};
    min-width: 100%;
    z-index: 19;
  }

  .DayPicker {
    min-width: 100%;
    font-size: 14px;
  }

  .DayPicker--interactionDisabled .DayPicker-Day {
    cursor: pointer;

    &:hover {
      background-color: #f0f8ff;
    }
  }

  .DayPicker-Day--disabled {
    pointer-events: none;
  }
`;

const Wrapper = styled(WrapperCommon)`
  ${(p) =>
    p.error &&
    css`
      .DayPickerInput {
        input {
          border-color: ${(p) => p.theme.colors.error};
        }
      }
    `};

  ${(p) =>
    p.withoutInput &&
    css`
      &::after {
        content: none;
      }
      .DayPickerInput-Overlay {
        bottom: 0;
      }
    `}

  ${(p) => {
    switch (p.variant) {
      case 'bottom-left':
        return css`
          .DayPickerInput {
            .DayPickerInput-Overlay {
              top: 10px;
              bottom: unset;
              left: 0;
            }
          }
        `;
      case 'bottom-right':
        return css`
          .DayPickerInput {
            .DayPickerInput-Overlay {
              top: 10px;
              bottom: unset;
              left: unset;
              right: 0;
            }
          }
        `;

      default:
        return '';
    }
  }}
`;
