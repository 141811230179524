import styled from 'styled-components/macro';
import { ButtonExtended } from './Button';

export const ButtonGhost = styled(ButtonExtended)`
  /* min-width: 95px; */
  width: auto;
  color: #1c3353;
  transition: color, border-color, background 0.2s ease-in-out;
  border: 1px solid #cfd0d7;
  background-color: #fff;

  &:hover {
    border-color: #4394ea;
  }
  &:active {
    background: #4394ea;
    color: #fff;
  }

  &:disabled {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.5;
  }
`;

ButtonGhost.displayName = 'ButtonGhost';
