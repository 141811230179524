import types from 'constants/actionTypes';

export const updateMarketAnalyticsRangeDate = (payload = {}) => ({
  type: types.UPDATE_MARKET_ANALYTICS_RANGE_DATE,
  payload,
});

export const updateMarketAnalyticsGrouping = (payload = {}) => ({
  type: types.UPDATE_MARKET_ANALYTICS_GROUPING,
  payload,
});

export const updateMarketAnalyticsRequest = (payload = {}) => ({
  type: types.UPDATE_MARKET_ANALYTICS_REQUEST,
  payload,
});
export const updateMarketAnalyticsSuccess = (payload = {}) => ({
  type: types.UPDATE_MARKET_ANALYTICS_SUCCESS,
  payload,
});
export const updateMarketAnalyticsFailure = (payload = {}) => ({
  type: types.UPDATE_MARKET_ANALYTICS_FAILURE,
  payload,
});

export const getMarketAnalyticsRequest = (payload = {}) => ({
  type: types.GET_MARKET_ANALYTICS_REQUEST,
  payload,
});
export const getMarketAnalyticsSuccess = (payload = {}) => ({
  type: types.GET_MARKET_ANALYTICS_SUCCESS,
  payload,
});
export const getMarketAnalyticsFailure = (payload = {}) => ({
  type: types.GET_MARKET_ANALYTICS_FAILURE,
  payload,
});

export const trackAnalyticsRequest = (payload = {}) => ({
  type: types.TRACK_ANALYTICS_REQUEST,
  payload,
});
export const trackAnalyticsSuccess = (payload = {}) => ({
  type: types.TRACK_ANALYTICS_SUCCESS,
  payload,
});
export const trackAnalyticsFailure = (payload = {}) => ({
  type: types.TRACK_ANALYTICS_FAILURE,
  payload,
});

export const exportAnalyticsDataRequest = (payload = {}) => ({
  type: types.EXPORT_ANALYTICS_DATA_REQUEST,
  payload,
});
export const exportAnalyticsDataSuccess = (payload = {}) => ({
  type: types.EXPORT_ANALYTICS_DATA_SUCCESS,
  payload,
});
export const exportAnalyticsDataFailure = (payload = {}) => ({
  type: types.EXPORT_ANALYTICS_DATA_FAILURE,
  payload,
});
