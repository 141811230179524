import types from 'constants/actionTypes';
import immutable from 'object-path-immutable';
import { clone } from 'ramda';
import { normalizeOptions } from 'lib/utils';

const initialState = {
  contacts: {},
  contactsArray: [],
  contactsTypes: [],
  normalizedContactsTypes: {},
  detailedContact: {},
  searchString: '',
  isFetched: false,
  possibleTags: [],
};

export default function lenderDirectory(state, action = {}) {
  const currentState = state || clone(initialState);

  const { type, payload } = action;

  switch (type) {
    case types.CONTACT_DIRECTORY_INVITE_SUCCESS:
      return payload.IsAddContactContacts
        ? immutable(currentState)
            .update('pageNumber', payload.pageNumber)
            .update('count', payload.count)
            .update('possibleTags', () => payload.tags)
            .update('contacts', (contacts) => ({ ...payload.contacts, ...contacts }))
            .update('contactsArray', (contactsArray) => [
              ...payload.contactsArray,
              ...contactsArray,
            ])
            .value()
        : currentState;

    case types.SET_CONTACTS_SEARCH_STRING:
      return immutable.set(currentState, 'searchString', payload);

    case types.GET_CONTACTS_DIRECTORY_DATA_SUCCESS:
      return immutable(currentState)
        .set('contacts', payload.contacts || {})
        .set('contactsArray', payload.contactsArray)
        .set('possibleTags', payload.tags || [])
        .set('normalizedPossibleTags', normalizeOptions(payload.tags) || [])
        .set('contactsTypes', payload.contactTypes || [])
        .set('normalizedContactsTypes', normalizeOptions(payload.contactTypes) || {})
        .set('pageNumber', payload.pageNumber)
        .set('count', payload.count)
        .set('isFetched', true)
        .value();

    case types.UPDATE_CONTACTS_DIRECTORY_DATA_SUCCESS:
      return immutable(currentState)
        .set('contacts', payload.contacts || {})
        .set('contactsArray', payload.contactsArray)
        .set('pageNumber', payload.pageNumber)
        .set('count', payload.count)
        .value();

    case types.ADD_TAG_TO_CONTACT_SUCCESS: {
      const { tagName } = payload;

      return immutable(currentState)
        .update('possibleTags', (v) =>
          v.filter((tag) => tag.label === tagName).length
            ? v
            : [...v, { label: tagName, value: tagName }]
        )
        .value();
    }

    case types.ADD_NOTE_TO_CONTACT_SUCCESS: {
      const { response } = payload;
      return immutable(currentState)
        .update(['detailedContact', 'notes'], (v) => [response, ...v])
        .value();
    }

    case types.RESET_CONTACT_DIRECTORY_DATA: {
      return initialState;
    }

    case types.ARCHIVE_CONTACT_SUCCESS: {
      return {
        ...state,
        contactsArray: state.contactsArray.filter(
          (id) => state.contacts[id].id !== Number(payload.contactId)
        ),
      };
    }

    case types.SHOW_CONTACT_CARD_SUCCESS: {
      return {
        ...state,
        detailedContact: {
          ...payload,
          tags: payload.tags
            ? payload.tags.map((tag) => ({ label: tag, value: tag }))
            : [],
          loans: action.payload.loans.map(loan => ({...loan, id: loan.uuid}))
        },
      };
    }
    case types.CLEAR_CONTACT_CARD: {
      return {
        ...state,
        detailedContact: {},
      };
    }

    default:
      return currentState;
  }
}
