import React from 'react';
import { Form, Field } from 'react-final-form';
import styled from 'styled-components/macro';
import { Input, DropdownList } from 'components/Form';
import {
  ModalBody,
  ModalHeading,
  ModalActions,
  modalWithContentWrapperStyles,
} from 'ui/Modal/styles';
import { normalizeMobile, normalizeInteger } from 'lib/normalizers';
import { Button } from 'ui/Button';
import { delay, simpleMemoize } from 'lib/utils';
import Yup from 'yup';
import { validate, validateEmail } from 'utils/validate';
import {
  clientDirectoryInviteRequest,
  checkClientDirectoryEmailRequest,
} from 'features/Directory/pages/ClientsDirectory/actions';
import { BORROWER, BROKER } from 'constants/appRelated';
import { WorkPhone, WorkPhoneWrapper, WorkPhoneExt } from 'styles/auth';

const schema = Yup.object().shape({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  phone: Yup.string().required('Phone is required!'),
  accountType: Yup.object()
    .nullable()
    .required('Required field'),
});

const clientTypes = [
  { label: 'Borrower', value: BORROWER },
  { label: 'Broker', value: BROKER },
];

export class AddClient extends React.Component {
  asyncValidate = simpleMemoize(async (email) => {
    const { dispatch, lenderLoanCreation } = this.props;

    if (!email) {
      return 'Email is required';
    }

    if (!validateEmail(email)) {
      return 'Invalid email address';
    }

    if (email && !lenderLoanCreation) {
      const res = await delay(1000).then(() =>
        new Promise((resolve, reject) => {
          dispatch(checkClientDirectoryEmailRequest({ email, resolve, reject }));
        }).then(
          (v) => v,
          (error) => error
        )
      );
      return res;
    }
  });

  submitForm = (values) => {
    const { dispatch, hideModal, lenderLoanCreation, closeModal } = this.props;
    let request = { ...values };

    if (request.phone) {
      request.phone = request.phone.replace(/[^0-9]/g, '');
    }
    if (request.mobilePhone) {
      request.mobilePhone = request.mobilePhone.replace(/[^0-9]/g, '');
    }
    request.accountType = request.accountType.value;
    if(!lenderLoanCreation) {
      hideModal();
    }

    dispatch(clientDirectoryInviteRequest({ request }));

    if(lenderLoanCreation) {
      closeModal(false)
    }
  };

render() {
    const { lenderLoanCreation} = this.props;
    return (
      <Form
        onSubmit={this.submitForm}
        validate={(values) => validate(values, schema)}
        initialValues={{ businessName: '', ext: '', mobilePhone: '' }}
        render={({ handleSubmit, submiting, validating }) => (
          <FormWrapper onSubmit={handleSubmit}>
            <ModalHeading>Add a new client</ModalHeading>
            <ModalBody>
              <Field
                name="accountType"
                component={DropdownList}
                options={clientTypes}
                label={`${lenderLoanCreation ? 'Type of user' : 'Please select type of contact'}`}
                mb={3}
                required
              />
              <FieldRow>
                <FieldCell>
                  <Field
                    name="firstName"
                    component={Input}
                    label="First Name"
                    placeholder={`${lenderLoanCreation ? 'John' : ''}`}
                    required />
                </FieldCell>
                <FieldCell>
                  <Field
                    name="lastName"
                    component={Input}
                    label="Last Name"
                    placeholder={`${lenderLoanCreation ? 'Smith' : ''}`}
                    required />
                </FieldCell>
              </FieldRow>

              <Field
                name="email"
                component={Input}
                validate={this.asyncValidate}
                label="Email"
                placeholder={`${lenderLoanCreation ? 'johnsmith@company.com' : ''}`}
                required
                parse={(value) => value.trim()}
              />
              <WorkPhoneWrapper>
                <WorkPhone>
                  <Field
                    component={Input}
                    placeholder={`${lenderLoanCreation ? '+1-234-567-890' : '+1'}`}
                    label={`${lenderLoanCreation ? 'Phone' : 'Work Phone'}`}
                    name={`phone`}
                    required
                    parse={normalizeMobile}
                  />
                </WorkPhone>
                {!lenderLoanCreation && (
                  <WorkPhoneExt>
                    <Field
                      component={Input}
                      placeholder="ext."
                      name={`phoneExt`}
                      parse={normalizeInteger}
                      maxLength="4"
                    />
                  </WorkPhoneExt>
                )}
              </WorkPhoneWrapper>

              {lenderLoanCreation ? renderMobilePhoneInputComponent(lenderLoanCreation) : renderCompanyInputComponent(lenderLoanCreation)}
              {lenderLoanCreation ? renderCompanyInputComponent(lenderLoanCreation) : renderMobilePhoneInputComponent(lenderLoanCreation)}

            </ModalBody>
            <ModalActions>
              <Button
                type="submit"
                secondary
                disabled={submiting || validating}
              >
                {lenderLoanCreation ? 'Add client' : 'Add contact'}
              </Button>
            </ModalActions>
          </FormWrapper>
        )}
      />
    );
  }
}

const renderCompanyInputComponent = (lenderLoanCreation) => {
  return (
    <Field
      name={'businessName'}
      component={Input}
      label="Company name"
      placeholder={`${lenderLoanCreation ? 'Real Estate inc.' : ''}`}
    />
  )
}

const renderMobilePhoneInputComponent = (lenderLoanCreation) => {
  return (
    <Field
      name={'mobilePhone'}
      component={Input}
      label="Mobile phone"
      placeholder={`${lenderLoanCreation ? '+1-234-567-890' : ''}`}
      parse={normalizeMobile}
    />
  )
}

const FormWrapper = styled.form`
  ${modalWithContentWrapperStyles}
`;

const FieldRow = styled.div`
  display: flex;
  margin: 0 -10px;
`;

const FieldCell = styled.div`
  flex: 1;
  margin: 0 10px;
`;
