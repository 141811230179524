import types from 'constants/actionTypes';

export const getMoreLendersSuccess = (payload={}) => ({
  type: types.GET_MORE_LENDERS_SUCCESS,
  payload,
});

export const getMoreLendersRequest = (payload) => ({
  type: types.GET_MORE_LENDERS_REQUEST,
  payload,
});

export const setLendersSearchString = (payload) => ({
  type: types.SET_LENDERS_SEARCH_STRING,
  payload,
});

export const lenderDirectoryInviteRequest = (payload = {}) => ({
  type: types.LENDER_DIRECTORY_INVITE_REQUEST,
  payload,
});
export const lenderDirectoryInviteSuccess = (payload = {}) => ({
  type: types.LENDER_DIRECTORY_INVITE_SUCCESS,
  payload,
});
export const lenderDirectoryInviteFailure = (payload = {}) => ({
  type: types.LENDER_DIRECTORY_INVITE_FAILURE,
  payload,
});

export const lenderDirectoryInviteDocumentRequest = (payload = {}) => ({
  type: types.LENDER_DIRECTORY_INVITE_DOCUMENT_REQUEST,
  payload,
});
export const lenderDirectoryInviteDocumentSuccess = (payload = {}) => ({
  type: types.LENDER_DIRECTORY_INVITE_DOCUMENT_SUCCESS,
  payload,
});
export const lenderDirectoryInviteDocumentFailure = (payload = {}) => ({
  type: types.LENDER_DIRECTORY_INVITE_DOCUMENT_FAILURE,
  payload,
});

export const getLendersDirectoryDataRequest = (payload = {}) => ({
  type: types.GET_LENDERS_DIRECTORY_DATA_REQUEST,
  payload,
});
export const getLendersDirectoryDataSuccess = (payload = {}) => ({
  type: types.GET_LENDERS_DIRECTORY_DATA_SUCCESS,
  payload,
});
export const getLendersDirectoryDataFailure = (payload = {}) => ({
  type: types.GET_LENDERS_DIRECTORY_DATA_FAILURE,
  payload,
});

export const updateLendersDirectoryDataRequest = (payload = {}) => ({
  type: types.UPDATE_LENDERS_DIRECTORY_DATA_REQUEST,
  payload,
});
export const updateLendersDirectoryDataSuccess = (payload = {}) => ({
  type: types.UPDATE_LENDERS_DIRECTORY_DATA_SUCCESS,
  payload,
});
export const updateLendersDirectoryDataFailure = (payload = {}) => ({
  type: types.UPDATE_LENDERS_DIRECTORY_DATA_FAILURE,
  payload,
});

export const getLendersActivityDataRequest = (payload = {}) => ({
  type: types.GET_LENDERS_ACTIVITY_DATA_REQUEST,
  payload,
});
export const getLendersActivityDataSuccess = (payload = {}) => ({
  type: types.GET_LENDERS_ACTIVITY_DATA_SUCCESS,
  payload,
});
export const getLendersActivityDataFailure = (payload = {}) => ({
  type: types.GET_LENDERS_ACTIVITY_DATA_FAILURE,
  payload,
});

export const checkLenderDirectoryEmailRequest = ({
  email,
  resolve,
  reject,
  checkLenderId,
}) => ({
  type: types.CHECK_LENDER_DIRECTORY_EMAIL_REQUEST,
  payload: { email, resolve, reject, checkLenderId },
});
export const checkLenderDirectoryEmailSuccess = (payload = {}) => ({
  type: types.CHECK_LENDER_DIRECTORY_EMAIL_SUCCESS,
  payload,
});
export const checkLenderDirectoryEmailFailure = (payload = {}) => ({
  type: types.CHECK_LENDER_DIRECTORY_EMAIL_FAILURE,
  payload,
});

export const exportLendersListRequest = (payload = {}) => ({
  type: types.EXPORT_LENDERS_LIST_REQUEST,
  payload,
});
export const exportLendersListSuccess = () => ({
  type: types.EXPORT_LENDERS_LIST_SUCCESS,
});
export const exportLendersListFailure = (error) => ({
  type: types.EXPORT_LENDERS_LIST_FAILURE,
  payload: error,
});

export const resetDirectory = () => ({
  type: types.RESET_LENDER_DIRECTORY_DATA,
  payload: {},
});
