import types from 'constants/actionTypes';

export const getRecoveryCodesRequest = (payload = {}) => ({
  type: types.GET_RECOVERY_CODES_REQUEST,
  payload,
});
export const getRecoveryCodesSuccess = (payload = {}) => ({
  type: types.GET_RECOVERY_CODES_SUCCESS,
  payload,
});
export const getRecoveryCodesFailure = (payload = {}) => ({
  type: types.GET_RECOVERY_CODES_FAILURE,
  payload,
});

export const getNewRecoveryCodesRequest = (payload = {}) => ({
  type: types.GET_NEW_RECOVERY_CODES_REQUEST,
  payload,
});
export const getNewRecoveryCodesSuccess = (payload = {}) => ({
  type: types.GET_NEW_RECOVERY_CODES_SUCCESS,
  payload,
});
export const getNewRecoveryCodesFailure = (payload = {}) => ({
  type: types.GET_NEW_RECOVERY_CODES_FAILURE,
  payload,
});
