import styled, { css } from 'styled-components/macro';

export const ButtonCommon = styled.button`
  border: none;
  margin: 0;
  padding: 11px 29px;
  border-radius: 4px;
  font-size: 14px;

  font-weight: 600;
  line-height: normal;
  cursor: pointer;
  overflow: visible;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;

  &:focus {
    outline: none;
  }
`;

export const ButtonExtended = styled(ButtonCommon)`
 &:disabled {
    cursor: not-allowed;
    color: #8890a2;
  }

  ${(p) =>
    p.bg &&
    css`
      background-color: ${p.bg};
    `}

  ${(p) =>
    (p.mr || typeof p.mr === 'number') &&
    css`
      margin-right: ${p.theme.space[p.mr] ? `${p.theme.space[p.mr]}px` : p.mr};
    `}

  ${(p) =>
    (p.ml || typeof p.ml === 'number') &&
    css`
      margin-left: ${p.theme.space[p.ml] ? `${p.theme.space[p.ml]}px` : p.ml};
    `}

  ${(p) =>
    (p.mt || typeof p.mt === 'number') &&
    css`
      margin-top: ${p.theme.space[p.mt] ? `${p.theme.space[p.mt]}px` : p.mt};
    `}

  ${(p) =>
    (p.mb || typeof p.mb === 'number') &&
    css`
      margin-bottom: ${p.theme.space[p.mb] ? `${p.theme.space[p.mb]}px` : p.mb};
    `}

  ${(p) =>
    (p.mx || typeof p.mx === 'number') &&
    css`
      margin: 0 ${p.theme.space[p.mx] ? `${p.theme.space[p.mx]}px` : p.mx};
    `}
`;

export const Button = styled(ButtonExtended)`
  width: ${(p) => (p.fullWidth ? '100%' : 'auto')};
  color: white;
  transition: ${(p) => p.theme.transition};
  background-color: ${(p) =>
    p.secondary ? p.theme.colors.blues[1] : p.theme.colors.oranges[0]};

  &:hover {
    opacity: 0.8;
  }

  &:hover:disabled {
    opacity: 1;
  }

  &:disabled {
    cursor: not-allowed;
    background-color: #ecedee;
    color: #8890a2;
  }

  &:active {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.06), 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  ${(p) =>
    p.bordered &&
    css`
      border: 1px solid ${p.color || p.theme.colors.oranges[0]};
      color: ${p.color || p.theme.colors.oranges[0]};
      color: ${p.textColor || p.color || p.theme.colors.oranges[0]};
      background-color: #fff;

      &:hover {
        background-color: ${p.colorBG || p.color || p.theme.colors.oranges[0]};
        color: ${p.textHoverColor || '#fff'}; 
      }
    `}
`;

Button.displayName = 'Button';
