export const syncActions = ['SET_LENDERS_SEARCH_STRING', 'RESET_LENDER_DIRECTORY_DATA'];

export const asyncActions = [
  'LENDER_DIRECTORY_INVITE',
  'LENDER_DIRECTORY_INVITE_DOCUMENT',
  'GET_LENDERS_DIRECTORY_DATA',
  'UPDATE_LENDERS_DIRECTORY_DATA',
  'GET_LENDERS_ACTIVITY_DATA',
  'CHECK_LENDER_DIRECTORY_EMAIL',
  'EXPORT_LENDERS_LIST',
  'GET_MORE_LENDERS'
];
