import types from 'constants/actionTypes';

export const getDealRoomsRequest = (payload = {}) => ({
  type: types.GET_DEAL_ROOMS_REQUEST,
  payload,
});
export const getDealRoomsSuccess = (payload = {}) => ({
  type: types.GET_DEAL_ROOMS_SUCCESS,
  payload,
});
export const getDealRoomsFailure = (payload = {}) => ({
  type: types.GET_DEAL_ROOMS_FAILURE,
  payload,
});

export const uploadSignedTermsheetRequest = (payload = {}) => ({
  type: types.UPLOAD_SIGNED_TERMSHEET_REQUEST,
  payload,
});
export const uploadSignedTermsheetSuccess = (payload = {}) => ({
  type: types.UPLOAD_SIGNED_TERMSHEET_SUCCESS,
  payload,
});
export const uploadSignedTermsheetFailure = (payload = {}) => ({
  type: types.UPLOAD_SIGNED_TERMSHEET_FAILURE,
  payload,
});
