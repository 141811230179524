import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import dashboard from 'features/Dashboard/reducer';
import inbox from 'features/Inbox/reducer';
import marketplace from 'features/Marketplace/reducer';
import alert from 'reducers/alert';
import spinner from 'reducers/spinner';
import notification from 'reducers/notification';
import modal from 'reducers/modal';
import header from 'reducers/header';
import dealRooms from 'features/DealRooms/reducer';
import dealRoom from 'features/DealRoom/reducer';
import offers from 'features/Offers/reducer';
import myLoans from 'features/MyLoans/reducer';
import myMatchingLoans from 'features/MyMatchingLoans/reducer';
import configuration from 'reducers/configuration';
import finishRegistration from 'features/FinishRegistration/reducer';
import chat from 'features/Chat/reducer';
import lenderDirectory from 'features/Directory/pages/LenderDirectory/reducer';
import contactsDirectory from 'features/Directory/pages/ContactsDirectory/reducer';
import { clientsDirectory } from 'features/Directory/pages/ClientsDirectory/reducer';
import loanCreation from 'features/LoanCreation/reducer';
import loanNew from 'features/LoanNew/reducer';
import listings from 'features/Listings/reducer';
import listing from 'containers/ListingWrapper/reducer';
import loanSendToLenders from 'features/LoanSendToLenders/reducer';
import loanLenderManagement from 'features/LoanLenderManagement/reducer';
import { loadingReducer } from 'reducers/loading';
import { teamMemberRegistration } from 'features/TeamMemberRegistration/reducer';
import { userDetails } from 'containers/UserDetails/reducer';
import { compareOffers } from 'features/CompareOffers/reducer';
import { profile } from 'features/Profile/reducer';
import { chatWidget } from 'containers/ChatWidget/reducer';
import { quotesWidget } from 'containers/QuotesWidget/reducer';
import { loanActivityWidget } from 'containers/LoanActivityWidget/reducer';
import { recoveryCodes } from 'features/RecoveryCodes/reducer';
import { twoFactorAuthentication } from 'features/TwoFactorAuthentication/reducer';
import { confirmAuth } from 'features/ConfirmAuth/reducer';
import { recoveryLogin } from 'features/RecoveryLogin/reducer';
import { dashboardActionsItems } from 'features/DashboardActionItems/reducer';
import loanTermsheets from 'features/LoanTermsheets/reducer';
import filter from 'features/Filter/reducer';
import oAuth from 'features/OAuth/reducer';
import myTasks from 'containers/MyTasksWrapper/reducer';
import globalSearch from 'features/GlobalSearch/reducer';

import { marketAnalytics } from 'features/Analytics/pages/MarketAnalytics/reducer';
import { marketAnalyticsLoans } from 'features/Analytics/pages/MarketAnalyticsLoans/reducer';
import { marketAnalyticsLenders } from 'features/Analytics/pages/MarketAnalyticsLenders/reducer';
import { teamAnalytics } from 'features/Analytics/pages/TeamAnalytics/reducer';
import { lenderCompany } from 'features/LenderCompany/reducer';

import { currentUser, loanActions, filters } from './shared';

const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    currentUser,
    chat,
    alert,
    dashboard,
    inbox,
    spinner,
    notification,
    modal,
    dealRooms,
    dealRoom,
    marketplace,
    offers,
    myLoans,
    myMatchingLoans,
    configuration,
    loanActions,
    finishRegistration,
    lenderDirectory,
    contactsDirectory,
    loanCreation,
    header,
    loanNew,
    listings,
    listing,
    filters,
    loanSendToLenders,
    loanLenderManagement,
    loading: loadingReducer,
    teamMemberRegistration,
    userDetails,
    compareOffers,
    profile,
    chatWidget,
    quotesWidget,
    loanActivityWidget,
    clientsDirectory,
    recoveryCodes,
    twoFactorAuthentication,
    confirmAuth,
    recoveryLogin,
    dashboardActionsItems,
    loanTermsheets,
    marketAnalytics,
    marketAnalyticsLoans,
    filter,
    marketAnalyticsLenders,
    teamAnalytics,
    lenderCompany,
    oAuth,
    myTasks,
    globalSearch,
  });

export default createRootReducer;
