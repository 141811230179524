export const syncActions = [
  'SET_CONTACTS_SEARCH_STRING',
  'RESET_CONTACT_DIRECTORY_DATA',
  'CLEAR_CONTACT_CARD',
];

export const asyncActions = [
  'CONTACT_DIRECTORY_INVITE',
  'GET_CONTACTS_DIRECTORY_DATA',
  'UPDATE_CONTACTS_DIRECTORY_DATA',
  'ADD_TAG_TO_CONTACT',
  'REMOVE_TAG_FROM_CONTACT',
  'ADD_NOTE_TO_CONTACT',
  'CHECK_CONTACT_DIRECTORY_EMAIL',
  'ARCHIVE_CONTACT',
  'SHOW_CONTACT_CARD',
  'GET_MORE_CONTACTS',
];
