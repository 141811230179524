import React from 'react';
import styled, { css } from 'styled-components/macro';

const Wrapper = styled.button`
  border: none;
  padding: 7px 56px 7px 24px;
  position: relative;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #cfd0d7;

  font-size: 16px;
  line-height: 24px;
  cursor: pointer;

  &:hover {
    border-color: #4394ea;
  }

  &::after {
    content: '';
    position: absolute;
    right: 24px;
    top: 50%;
    transform: translateY(-50%);
    display: inline-block;
    width: 16px;
    height: 16px;
    background-size: 16px 16px;
  }

  svg {
    position: absolute;
    right: 24px;
    top: 50%;
    transform: translateY(-50%);
  }
`;

const Button = styled(Wrapper)`
  &::after {
    background-image: url(${(p) => p.iconUrl});
  }

  ${(p) => {
    switch (p.variant) {
      case 'blue':
        return css`
          background-color: #4394ea;
          color: #fff;
          border: none;
        `;
      case 'active':
        return css`
          color: #4394ea;
          border: none;
          box-shadow: ${(p) => p.theme.shadow};
        `;

      default:
        return null;
    }
  }}
`;

export const ButtonIcon = ({ children, Icon, variant, ...rest }) => (
  <Button variant={variant} {...rest}>
    {Icon}
    {children}
  </Button>
);
