import React, { memo, useState, useEffect } from 'react';
import styled, { css } from 'styled-components/macro';
import Select, { components } from 'react-select';
import PropTypes from 'prop-types';
import { Text } from 'ui/Text';
import { Tooltip } from 'ui/Tooltip';
import { Portal } from 'features/Analytics/components';
import { UserAvatar } from 'components/UserAvatar';

import { DropdownIndicator, MultiValueRemove } from './components';
import { CLIENT_SUCCESSFULLY_ADDED } from '../../features/LoanCreation/components/Overview/addNewClient';

const MIN_BOTTOM_OFFSET = 300;

const customStyles = {
  container: (base, state) => ({
    ...base,
    zIndex: state.isFocused ? '99 !important' : '5',
  }),
};

const PortalSelectMenu = memo(
  ({ top, left, width, children, ...rest }) => (
    <Portal>
      <PortalSelectMenuWrapper top={top} left={left} width={width}>
        <components.Menu {...rest}>{children}</components.Menu>
      </PortalSelectMenuWrapper>
    </Portal>
  ),
  (prevProps, nextProps) =>
    prevProps.top === nextProps.top &&
    prevProps.left === nextProps.left &&
    prevProps.width === nextProps.width
);

PortalSelectMenu.displayName = 'PortalSelectMenu';

const DropDown = ({
  newClient,
  error,
  isMulti,
  options,
  label,
  simpleValue,
  dropup,
  mb,
  // components,
  hint,
  onChange,
  required,
  name,
  disabled,
  variant,
  align,
  semiRequired,
  isPortal,
  portalMenuConfig,
  alertMessage,
  ...rest
}) => {
  const [elemCoordinates, setElemCoordinates] = useState({ top: 0, left: 0 });
  const [loanTypeSelectDropUp, setLoanTypeSelectDropUp] = useState(false);
  const selectComponents = {
    ...rest.components,
    DropdownIndicator,
    MultiValueRemove,
  };

  useEffect( () => {
    if(label === 'Client') {
      newClient?.value && onChange(newClient);
    }
  }, [alertMessage && alertMessage === CLIENT_SUCCESSFULLY_ADDED]);

  if (portalMenuConfig) {
    selectComponents.Menu = (props) =>
      elemCoordinates.top && elemCoordinates.left ? (
        <PortalSelectMenu
          {...props}
          top={elemCoordinates.top + portalMenuConfig.offsetY}
          left={elemCoordinates.left + portalMenuConfig.offsetX}
          width={portalMenuConfig.menuWidth}
        />
      ) : null;
  }

  return (
    <WrapperContainer mb={mb} disabled={disabled}>
      {(label || hint) && (
        <LabelContainer>
          {label && (
            <Text fontSize="0" mr={2} color={error ? 'error' : ''} fontFamily="muli">
              {label}
              {required && <Asterix> *</Asterix>}
            </Text>
          )}
          {hint && <Tooltip text={hint} />}
        </LabelContainer>
      )}
      <Wrapper
        dropup={loanTypeSelectDropUp}
        variant={(error && 'error') || variant || ''}
        align={align}
        data-testid="dropDownContainer"
        semiRequired={semiRequired}
        onClick={(e) => {
          const { left, top } = e.currentTarget.getBoundingClientRect();
          setElemCoordinates({ top: top + window.scrollY, left });
          const toUp = window.innerHeight - top < MIN_BOTTOM_OFFSET;
          setLoanTypeSelectDropUp(toUp);
        }}
        {...rest}
      >
        <Select
          isMulti={isMulti}
          options={options}
          simpleValue={simpleValue}
          className="Select"
          classNamePrefix="Select"
          styles={customStyles}
          onChange={(value) => {
            if (!value && isMulti) {
              onChange([]);
            } else {
              onChange(value);
            }
          }}
          components={selectComponents}
          name={name}
          inputId={name}
          isDisabled={disabled}
          {...rest}
        />
      </Wrapper>
      {error && (
        <Text fontSize="12px" color="error">
          {error}
        </Text>
      )}
    </WrapperContainer>
  );
};
DropDown.propTypes = {
  error: PropTypes.string,
  isMulti: PropTypes.bool,
  isClearable: PropTypes.bool,
  dropup: PropTypes.bool,
  placeholder: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([
        PropTypes.string.isRequired,
        PropTypes.number.isRequired,
      ]),
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
};

DropDown.defaultProps = {
  error: '',
  isMulti: false,
  isClearable: false,
  dropup: false,
  placeholder: 'Select',
};

export { DropDown };

const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;

const WrapperCommon = styled.div`
  font-size: ${(p) => p.theme.fontSizes[0]}px;

  .Select {
    z-index: 5;
  }

  .Select__control {
    position: relative;
    min-height: 32px;
    background-color: #fff;
    border-radius: ${(p) => p.theme.radii[1]}px;
    border: 1px solid ${(p) => (p.semiRequired ? '#ff9e01' : '#cfd0d7')};
    flex-wrap: nowrap;
  }

  .Select__control--is-focused {
    box-shadow: none;
    border-color: ${(p) => p.theme.colors.blues[6]};
  }

  .Select__indicators {
    position: relative;
  }

  .Select__dropdown-indicator {
    padding: 0;
    padding-right: 10px;
    margin-left: auto;
  }

  .Select__indicator-separator {
    display: none;
  }

  .Select__menu {
    border-radius: 4px;
    border: none;
    box-shadow: 0 2px 14px 0 rgba(194, 198, 210, 0.5);
    z-index: 10;
    margin-bottom: 4px;
    margin-top: 4px;

    &-list {
      padding: 0;
    }

    ${(p) =>
      p.dropup &&
      css`
        bottom: 100%;
        top: unset;
      `};
  }

  .Select__menu-list {
    max-height: 250px;
    z-index: 10;
  }

  .Select__option {
    position: relative;
    color: ${(p) => p.theme.colors.black};
    padding: 15px 20px;

    // &:before{
    //   content: '/'
    //   width: 24px;
    //   height: 24px;
    //   background: red;
    // }
    &:hover {
      background: linear-gradient(45deg, #f0f3ff, #effaff);
    }
  }

  .Select__option.Select__option--is-selected {
    background: linear-gradient(45deg, #f0f3ff, #effaff);
  }

  .Select__multi-value {
    position: relative;
    display: inline-flex;
    align-items: center;
    padding: 0 4px;
    border-radius: 15px;
    border: none;
    font-size: ${(p) => p.theme.fontSizes[0]}px;
    background-color: rgba(67, 148, 234, 0.1);
  }

  .Select__multi-value__label {
    font-size: 14px;
    color: ${(p) => p.theme.colors.black};
  }

  .Select__multi-value__remove {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 8px;
    border: none;
    width: 18px;
    height: 18px;
    background-color: #fff;
    border-radius: 50%;
    color: transparent;
    cursor: pointer;

    &:hover {
      background-color: #fff;
    }
  }

  .Select__clear-indicator {
    width: 8px;
    height: 8px;
    padding: 0;
    cursor: pointer;

    &:after {
      content: '';
      display: inline-block;
      background-image: url('/images/icons/ico_modal_close.svg');
      background-size: 8px 8px;
      width: 8px;
      height: 8px;
      left: 0;
      font-size: 0;
    }

    svg {
      display: none;
    }

    .Select-clear {
      display: none;
    }
  }

  .Select__value-container--has-value {
    flex: 1 1 auto;
  }
`;

const Wrapper = styled(WrapperCommon)`
  ${(p) => {
    switch (p.variant) {
      case 'error':
        return css`
          .Select__control {
            border-color: ${(p) => p.theme.colors.error};
          }
        `;

      case 'transparent':
        return css`
          .Select__control {
            border-color: transparent;
            &:hover {
              border-color: transparent;
            }
          }
        `;

      case 'blue':
        return css`
          .Select__control {
            border-color: transparent;
            &:hover {
              border-color: transparent;
            }
          }

          .Select__placeholder,
          .Select__single-value {
            color: #4394ea;
          }

          .Select__dropdown-indicator {
            svg g g {
              fill: #4394ea;
            }
          }

          .Select__control {
            background-color: transparent;
          }
        `;

      default:
        return '';
    }
  }};

  ${(p) => {
    switch (p.align) {
      case 'right':
        return css`
          .Select__value-container {
            justify-content: flex-end;
          }
        `;

      case 'left':
        return css`
          .Select__value-container {
            justify-content: flex-start;
          }
        `;

      default:
        return '';
    }
  }};
`;

const Asterix = styled.span`
  color: #e32019;
`;

const WrapperContainer = styled.div`
  ${(p) =>
    (p.mb || typeof p.mb === 'number') &&
    css`
      margin-bottom: ${p.theme.space[p.mb] ? `${p.theme.space[p.mb]}px` : p.mb};
    `};

  ${(p) =>
    p.disabled &&
    css`
      opacity: 0.5;
    `}
`;

const PortalSelectMenuWrapper = styled.div`
  position: absolute;
  width: ${(p) => p.width}px;
  left: ${(p) => p.left}px;
  top: ${(p) => p.top}px;
  z-index: 1000;

  .Select__option {
    position: relative;
    color: ${(p) => p.theme.colors.black};
    padding: 15px 20px;

    &:hover {
      background: linear-gradient(45deg, #f0f3ff, #effaff);
    }
  }

  .Select__option.Select__option--is-selected {
    background: linear-gradient(45deg, #f0f3ff, #effaff);
  }
`;
