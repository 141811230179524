export const asyncActions = [
  'REQUEST_QR_CODE',
  'REQUEST_SMS_AUTH',
  'CONFIRM_AUTHENTICATOR_DEVICE',
  'DISABLE_TFA',
  'RESEND_SMS',
  'CONFIRM_BY_SMS',
];

export const syncActions = ['RESET_AUTH_DATA'];
