import React, { Component } from 'react';
import styled from 'styled-components/macro';
import { Form, Field } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import {
  ModalBody,
  ModalHeading,
  ModalWithContentWrapper,
  ModalActions,
} from 'ui/Modal/styles';
import { BucketIcon } from 'components/SvgIcons';

import { shareListingRequest } from 'actions';
import { Text } from 'ui/Text';
import { Button } from 'ui/Button';
import { Input } from 'components/Form';
import { validateEmail } from 'utils/validate';

const PureButton = styled.button`
  padding: 0;
  border: 0;
  background-color: transparent;
  outline: none;
  cursor: pointer;
`;

const ButtonAdd = styled(PureButton)`
  position: relative;
  ${Text} {
    cursor: pointer;
  }
`;

const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
`;

const ShareModalBody = styled(ModalBody)`
  padding-top: 0;
`;

const TrashButton = styled.button`
  margin-top: 8px;
  width: 15px;
  display: block;
  margin-left: auto;
  border: none;
  background-color: transparent;
  padding: 0;
  cursor: pointer;
  outline: none;
`;

const AdditionalField = styled.div`
  display: flex;
  align-items: flex-start;
`;

const InputWrapper = styled.div`
  flex: 1;
  margin-right: 15px;
`;

export class ShareListing extends Component {
  validateEmail = (email) => {
    if (!email) {
      return 'Email is required';
    }

    if (!validateEmail(email)) {
      return 'Invalid email address';
    }
  };

  submitForm = ({ users = [], ...user }) => {
    const { dispatch, hideModal, id } = this.props;
    hideModal();
    dispatch(shareListingRequest({ id, emails: [...users, user] }));
  };

  render() {
    return (
      <Form
        onSubmit={this.submitForm}
        mutators={{
          ...arrayMutators,
        }}
        render={({
          handleSubmit,
          submitting,
          form: {
            mutators: { push },
          },
        }) => (
          <FormWrapper onSubmit={handleSubmit}>
            <ModalWithContentWrapper>
              <ModalHeading>Share listing</ModalHeading>
              <ShareModalBody>
                <Text textAlign="center" color="#2B4C78" mb={3}>
                  Please enter email address of a person you want to share your listing
                  with.
                </Text>
                <Field
                  name="email"
                  component={Input}
                  validate={this.validateEmail}
                  placeholder="Share by email address"
                  required
                />
                <FieldArray name="users">
                  {({ fields }) =>
                    fields.map((name, index) => (
                      <AdditionalField key={`${name}.email`}>
                        <InputWrapper>
                          <Field
                            name={`${name}.email`}
                            component={Input}
                            placeholder="Share by email address"
                            validate={this.validateEmail}
                            remove={() => fields.remove(index)}
                            required
                          />
                        </InputWrapper>
                        {name && (
                          <TrashButton onClick={() => fields.remove(index)} type="button">
                            <BucketIcon />
                          </TrashButton>
                        )}
                      </AdditionalField>
                    ))
                  }
                </FieldArray>
                <ButtonAdd type="button" onClick={() => push('users', {})}>
                  <Text color="#4394EA">+ Add another recepient</Text>
                </ButtonAdd>
              </ShareModalBody>
              <ModalActions>
                <Button type="submit" disabled={submitting}>
                  Share
                </Button>
              </ModalActions>
            </ModalWithContentWrapper>
          </FormWrapper>
        )}
      />
    );
  }
}
