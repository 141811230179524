import React from 'react';
import styled from 'styled-components/macro';

import { Button, ButtonGhost } from 'ui/Button';

import {
    ModalBody,
    ModalHeading,
    modalWithContentWrapperStyles,
    ModalSubHeading,
  } from 'ui/Modal/styles';


const StopSyncing = ({onSubmit , hideModal, email}) => {

    const handleDisconnectCalendar = () => {
        onSubmit(); 
        hideModal();
    }
    return (
        <Wrapper>
            <ModalHeadingExtends>
                Stop syncing for
                <a href={`mailto:${email}`}>{email}</a>
            </ModalHeadingExtends>
            <ModalSubHeadingExtends>
                All RealAtom tasks will be deleted from external calendar and vice versa. 
            </ModalSubHeadingExtends>
            <ModalBodyExtends>
                <Button onClick={hideModal}>No</Button>
                <ButtonGhost onClick={handleDisconnectCalendar}>Yes</ButtonGhost>
            </ModalBodyExtends>
        </Wrapper>
    );
}

export default StopSyncing;

const Wrapper = styled.div`
    ${modalWithContentWrapperStyles};

    a{
        display: block;
        font-weight: 500;
        margin-top: 2px;
    }
`;

const ModalBodyExtends = styled(ModalBody)`
    display: flex;
    justify-content: space-between;
    width: 50%;
    margin: 0 auto;

    button{
        width: 99px; 
    }
`;

const ModalHeadingExtends = styled(ModalHeading)`
    margin-top: 15px;
`;

const ModalSubHeadingExtends = styled(ModalSubHeading)`
    margin-left: auto;
    margin-right: auto;
    width: 70%;
    line-height: 21px;
`;