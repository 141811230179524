import { values } from 'ramda';
import { FIELD_TYPES } from 'constants/appRelated';
import { format } from 'date-fns';

export const makeUrlStringFromData = ({
  data,
  config = {},
  page,
  ordering,
  flags: { noDateFormatting } = {},
}) => {
  const query = [];

  const sections = values(config);

  if (data) {
    sections.forEach((section) => {
      const { name, type, urlName } = section;

      switch (type) {
        case FIELD_TYPES.CHOICE_MULTI:
          if (data[name] && data[name].length) {
            query.push(`${urlName}=${data[name].join(',')}`);
          }
          break;

        case FIELD_TYPES.CHOICE:
          if (data[name]) {
            query.push(`${urlName}=${data[name]}`);
          }
          break;

        case FIELD_TYPES.RANGE:
          if (data[`${name}Min`]) {
            query.push(`${urlName}_min=${data[`${name}Min`]}`);
          }
          if (data[`${name}Max`]) {
            query.push(`${urlName}_max=${data[`${name}Max`]}`);
          }
          break;

        case FIELD_TYPES.DURATION_UNIT:
          if (data[`${name}Min`]) {
            query.push(
              `${urlName}_min=${Number(data[`${name}Min`]) *
                (data[`${name}MinUnit`] ? 12 : 1)}`
            );
          }
          if (data[`${name}Max`]) {
            query.push(
              `${urlName}_max=${Number(data[`${name}Max`]) *
                (data[`${name}MaxUnit`] ? 12 : 1)}`
            );
          }
          break;

        case FIELD_TYPES.TOGGLE:
        case FIELD_TYPES.BOOLEAN:
          if (data[name]) {
            query.push(`${urlName}=${data[name]}`);
          }
          break;

        case FIELD_TYPES.CHOICE_BOOLEAN:
          if (typeof data[name] === 'boolean') {
            query.push(`${urlName}=${data[name]}`);
          }
          break;

        case FIELD_TYPES.DATE:
          if (data[`${name}Before`]) {
            const dateValue = noDateFormatting
              ? data[`${name}Before`]
              : format(new Date(data[`${name}Before`]), 'yyyy-MM-dd');

            query.push(`${urlName}_before=${dateValue}`);
          }
          if (data[`${name}After`]) {
            const dateValue = noDateFormatting
              ? data[`${name}After`]
              : format(new Date(data[`${name}After`]), 'yyyy-MM-dd');

            query.push(`${urlName}_after=${dateValue}`);
          }
          break;

        case FIELD_TYPES.TEXT:
          if (data[name]) {
            query.push(`${urlName}=${encodeURIComponent(data[name])}`);
          }
          break;

        case FIELD_TYPES.NUMBER:
          if (data[name] !== null && data[name] !== undefined) {
            query.push(`${urlName}=${data[name]}`);
          }
          break;

        default:
          break;
      }
    });
  }

  if (page) {
    query.push(`page=${page}`);
  }

  if (ordering) {
    query.push(`ordering=${ordering}`);
  }

  return `?${query.join('&')}`;
};
