import React, { useState } from 'react';
import { FormattedNumber } from 'react-intl';
import { CURRENCY } from 'constants/appRelated';
import { recourseOptions } from 'utils/fields';
import { getOptionLabel } from 'lib/loan';

import { OffersListLender } from './OffersListLender';
import {
  TableCellWide,
  TableCell,
  ItemRow,
  Title,
  InfoSection,
  ExpandIcon,
} from './styles';

const getBorrowerLabel = (isMy) => (isMy ? 'You' : 'Borrower');

export const OffersListItem = ({
  lastOffer: { isMy, amortization, loanAmount, interest, recourse },
  profile,
  isOwner,
}) => {
  const [expanded, toggleExpand] = useState(false);

  return (
    <ItemRow>
      <Title
        onClick={() => {
          toggleExpand(!expanded);
        }}
      >
        {isOwner ? (
          <OffersListLender
            companyName={profile.companyName}
            firstName={profile?.user?.firstName}
            lastName={profile?.user?.lastName}
            id={profile?.user?.id}
          />
        ) : (
          getBorrowerLabel(isMy)
        )}
        <ExpandIcon expanded={expanded} />
      </Title>
      <InfoSection visible={expanded}>
        <TableCellWide data-title="LOAN AMOUNT:">
          <FormattedNumber
            style={CURRENCY}
            currency="USD"
            maximumFractionDigits={0}
            minimumFractionDigits={0}
            value={loanAmount}
          />
        </TableCellWide>
        <TableCellWide data-title="AMORTIZATION:">
          {amortization ? `${amortization} Yrs.` : 'N/A'}
        </TableCellWide>
        <TableCell data-title="RATE:">{interest ? `${interest}%` : 'N/A'}</TableCell>
        <TableCell data-title="RECOURSE:">
          {getOptionLabel(recourseOptions, recourse) || 'N/A'}
        </TableCell>
      </InfoSection>
    </ItemRow>
  );
};
