import types from 'constants/actionTypes';
import { paginationTmp } from 'config/paginationTmp';

const initialState = {
  loans: [],
  haveOffersBefore: null,
  haveOffersAfter: null,
  ordering: '',
  tipData: null,
  ...paginationTmp,
};

export function marketAnalyticsLoans(state, action = {}) {
  const currentState = state || initialState;

  const { type, payload } = action;

  switch (type) {
    case types.GET_MARKET_ANALYTICS_LOANS_SUCCESS:
      return {
        ...currentState,
        loans: payload.response.results,
        next: payload.response.next,
        previous: payload.response.previous,
        pageSize: payload.response.pageSize,
        pageNumber: payload.response.pageNumber,
        count: payload.response.count,
        haveOffersBefore: payload.haveOffersBefore,
        haveOffersAfter: payload.haveOffersAfter,
        ordering: payload.ordering,
        tipData: payload.tipData,
      };

    case types.UPDATE_MARKET_ANALYTICS_LOANS_SUCCESS:
      return {
        ...currentState,
        loans: payload.results,
        next: payload.next,
        previous: payload.previous,
        pageSize: payload.pageSize,
        pageNumber: payload.pageNumber,
        count: payload.count,
      };

    case types.UPDATE_MARKET_ANALYTICS_LOANS_ORDERING:
      return {
        ...currentState,
        ordering: currentState.ordering === payload ? `-${payload}` : payload,
      };

    default:
      return currentState;
  }
}
