import React, { useEffect, useState, useRef } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { modals } from 'constants/modalTypes';
import { showModal } from 'actions/modalActions';

import { 
  DOC_SIGNATURE_REJECTED, 
  DOC_SIGNATURE_ACCEPTED, 
  DOC_SIGNATURE_REJECTED_REASON } from 'constants/appRelated';

import { 
  setUpdateFileRequest, 
  setDeleteFileRequest, 
  setUploadSignedFileRequest, 
  setReviewFileRequest } from '../../actions';

import styled from 'styled-components/macro';
import PENCIL_GREY from '../../../../icons/ico_pencil_grey.svg';
import TRASH_GREY from '../../../../icons/ico_trash_grey.svg';
import APPROVE from '../../../../icons/ico_approve_grey.svg';
import CANCEL from '../../../../icons/ico_cancel_grey.svg';


import IcoFileContent from '../../../../icons/ico_file_content.svg';

import DocumentState from './components/DocumentState';
import UploadFiles from './components/UploadFiles';

import DocumentItemActions from './components/DocumentItemActions';
import { acceptDocsConfidentialityAgreementRequest } from '../../../DealRoom/actions'

import { Action } from 'components/Actions/Action';
import { Actions } from 'components/Actions/index';

import PENCIL from '../../../../icons/ico_pencil.svg';
import TRASH from '../../../../icons/ico_trash.svg';
import DOWNLOAD from '../../../../icons/ico_download.svg';

const RequestsSignatureDocs = ({
    setUploadSignedFileRequest, setReviewFileRequest,
    setDeleteFileRequest, setUpdateFileRequest, 
    roomId, showModal, lender, isLender, 
    modalWindow, files, setFiles, room, loan,
    acceptDocsConfidentialityAgreementRequest}) => {

    const [uploadedFile, setUploadedFile] = useState({id: null, file: null});
    const [editFile, setEditFile] = useState(false);
    const [currentFileName, setCurrentFileName] = useState('');
    const [showDocActions, setShowDocActions] = useState(null);

    const handlerShowActions = (item) => {
      setShowDocActions(item);
      setCurrentFileName('');
      setEditFile(false);
    }

    const openRejectModal = (updatedFile, state ) => {
      showModal(modals.DEAL_ROOM_DOCUMENT_REJECT_MODAL, {
        size: 'sm',
        submit: (reason) => {
          setReviewFileRequest({ 
            updatedFile, 
            roomId, 
            state, 
            rejected_reason: reason 
          });
        },
      })
    }

    
    const openRejectReasonModal = (reason) => {
      // const { dispatch, lender } = props;
      showModal(modals.DEAL_ROOM_DOCUMENT_REJECTION_REASON_MODAL, {
        size: 'sm',
        reason,
        lender,
      })
    };

    const handlerStateFile = (state, updatedFile) => {
      switch(state){
        case DOC_SIGNATURE_ACCEPTED:
          setReviewFileRequest({ updatedFile, roomId, state });
        break;
        case DOC_SIGNATURE_REJECTED:
          openRejectModal(updatedFile, state);
        break;
        case DOC_SIGNATURE_REJECTED_REASON:
          openRejectReasonModal(updatedFile.signedDocument.rejectedReason);
        break;
      }
    }

    const openDocument = (documentUrl) => {
      window.open(documentUrl, "_blank")
    }

    const handlerCAForDocuments = (documentUrl) => {      
      showModal(modals.CONFIDENTIALITY_AGREEMENT_MODAL, {
        size: 'sm',
        onButtonClick: () => {
          acceptDocsConfidentialityAgreementRequest({
            loanId: loan.uuid,              
            documentUrl,
            roomId,
          })
        },
      })
    }

    const handlerActionFile = (action, item, event=null) => {
      let updatedFile, deletedFile;

        setCurrentFileName('');
        setShowDocActions(null);
        switch(action){
          case 'edit':            
            files.map((file, index) => {
              if(item === index) setCurrentFileName(file.name.substr(0, file.name.lastIndexOf('.')))
            });

            setEditFile(item);
          break;
          case 'delete':
            if(modalWindow){
              setFiles(files.filter((file, index) => (item !== index)));
            }else{
              deletedFile = files.filter((file, index) => (item === index))
              setDeleteFileRequest({deletedFile, roomId});
            }
          break;
          case 'approve':
            if(currentFileName){
              if(modalWindow){
                setFiles(
                  files.map((file, index) => {
                    let name = currentFileName.trim() + file.name.substr(file.name.lastIndexOf('.'));
                    return item === index ?
                      new File([file], name, { type: file.type }) : file;
                  })
                );
              }else{
                files.map((file, index) => {
                  let name = currentFileName + file.name.substr(file.name.lastIndexOf('.'));
                  if(item === index) updatedFile = {...file, name};
                })
                setUpdateFileRequest({updatedFile, roomId});
              }
            }
            setEditFile(false);
          break;
          case 'cancel':
            setEditFile(false);
          case 'dowload':
            setEditFile(false);
          case 'upload':
            const formData = new FormData();
            const file = event?.target?.files[0];

            if(file){
              formData.append(`file`, file);
              
              files.map((val, index) => {
                if(item === index) updatedFile = val;
              })
              setUploadSignedFileRequest({formData, roomId, updatedFile});
            }
            
            setEditFile(false);
          break;
        }
    }

    return (
      files.map((file, item) => 
        <DocumentItem active={editFile === item}>
          {editFile === item ? 
            <input
              onChange={({target}) => 
                setCurrentFileName(target.value)}
              style={{width: '80%'}} 
              type={'text'} 
             //value={currentFileName || file.name.substr(0, file.name.lastIndexOf('.'))}
              value={currentFileName}

            /> : 
            <div className={'DocumentItemName'}>
              <img alt='' src={IcoFileContent}/>
              <span title={file.name} className={'name'}>{file.name}</span>
              <span className={'createdAt'}>
                {file.created && `(added ${moment(file.created).format("MMMM DD")})`}
              </span>
            </div>}
          {editFile === item ? (
            <DocumentItemActions 
              item={item} 
              actions={['approve', 'cancel']} 
              handlerActionFile={handlerActionFile} 
              srces={[APPROVE, CANCEL]}
            />
          ) : (
            modalWindow ? (
              <DocumentItemActions
                item={item}
                actions={['edit', 'delete']} 
                handlerActionFile={handlerActionFile} 
                srces={[PENCIL_GREY, TRASH_GREY]}
              />
            ) : (
              isLender ? (
                <WrapperActions>
                  {file.signedDocument &&
                    <DocumentState handlerStateFile={handlerStateFile} file={file}/>
                  }

                  <Actions
                    active={showDocActions === item}
                    setShowActions={setShowDocActions}
                    data={file.signedDocument}
                    handlerShowActions={() => handlerShowActions(item)}
                    children={
                      <>
                        {file.signedDocument && 
                        <Action
                          icon={DOWNLOAD} 
                          label={<a>Show the signed file</a>} 
                          click={() => {
                            handlerActionFile('dowload', item); 
                            handlerCAForDocuments(file.signedDocument.url);
                          }}/>}
                        <Action 
                          icon={PENCIL} 
                          label={'Edit'} 
                          click={() => handlerActionFile('edit', item)}
                        />
                        <Action 
                          icon={TRASH} 
                          label={'Delete'} 
                          click={() => handlerActionFile('delete', item)}
                        />  
                      </>
                    }
                  />
                </WrapperActions>
              ) : (
                <UploadFiles 
                  file={file} 
                  handlerStateFile={handlerStateFile} 
                  handlerActionFile={handlerActionFile} 
                  uploadedFile={uploadedFile} 
                  item={item}/>
              )
            )
          )}
        </DocumentItem>      
      )
      
    )
}

function mapStateToProps(store) {
  return {
    loan: store.loanNew.data,
    roomId: store.dealRoom.room.id,
    room: store.dealRoom.room,
  }
}

const mapDispatchToProps = {
  showModal,
  setUpdateFileRequest,
  setDeleteFileRequest,
  setUploadSignedFileRequest,
  setReviewFileRequest,
  acceptDocsConfidentialityAgreementRequest,
}

export default connect(mapStateToProps, mapDispatchToProps)(RequestsSignatureDocs);

const WrapperActions = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const DocumentItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  list-style-image: url("./ico_file_content.svg");
  margin-bottom: 18px;
  &:last-child{
    margin-bottom: 0;
  }

  ${(p) => (p.active &&
    `
    background: #FFFFFF;
    border: 1px solid #CFD0D7;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 8px;
    `
  )}
  
  .DocumentItemName{
    display: flex;
    align-items: center;
    img{ padding-right: 6px};

    

    .createdAt{
      font-weight: 600;
      font-size: 10px;
      line-height: 16px;
      text-transform: uppercase;
      color: #778598;
    }
    .name{
      padding-right: 5px;
    }
  }

  input{
    border: none;
    background: none;
    outline: 0px;
  }

  div{
    display: flex;
    img{
      cursor: pointer;
    }
    //img:first-child{ margin-right: 13px }
  }`;
