import React from 'react';

export const Plus = ({ fill }) => (
  <svg
    width="14px"
    height="14px"
    viewBox="0 0 14 14"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="design-system" stroke="none" strokeWidth="1" fill={fill} fillRule="evenodd">
      <g id="Atoms" transform="translate(-910.000000, -2761.000000)" fill={fill}>
        <g id="Group-4" transform="translate(897.000000, 2748.000000)">
          <g id="add">
            <path
              d="M20.5,19.5 L26,19.5 C26.2761424,19.5 26.5,19.7238576 26.5,20 C26.5,20.2761424 26.2761424,20.5 26,20.5 L20.5,20.5 L20.5,26 C20.5,26.2761424 20.2761424,26.5 20,26.5 C19.7238576,26.5 19.5,26.2761424 19.5,26 L19.5,20.5 L14,20.5 C13.7238576,20.5 13.5,20.2761424 13.5,20 C13.5,19.7238576 13.7238576,19.5 14,19.5 L19.5,19.5 L19.5,14 C19.5,13.7238576 19.7238576,13.5 20,13.5 C20.2761424,13.5 20.5,13.7238576 20.5,14 L20.5,19.5 Z"
              id="Combined-Shape"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
