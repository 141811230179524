import React from 'react';
import styled from 'styled-components/macro';
import { ArrowLeftIcon } from 'components/SvgIcons';

const Wrapper = styled.button`
  display: flex;
  align-items: center;
  border: none;
  outline: none;
  font-size: 16px;
  line-height: 24px;

  color: ${(p) => p.theme.colors.black};
  background-color: #fff;
  cursor: pointer;

  svg {
    margin-right: ${(p) => (p.hasContent ? '8px' : 0)};
  }
`;

export const ButtonBack = ({ children, type, ...rest }) => (
  <Wrapper type={type || 'button'} hasContent={!!children} {...rest}>
    <ArrowLeftIcon />
    {children || null}
  </Wrapper>
);
