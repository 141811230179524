import { put, takeLatest, call } from 'redux-saga/effects';
import types from 'constants/actionTypes';
import {
  postQuestionSuccess,
  postQuestionFailure,
  conciergeActionSuccess,
  conciergeActionFailure,
  loanActionsSuccess,
  loanActionsFailure,
  uploadActionDocumentSuccess,
  uploadActionDocumentFailure,
  deleteActionDocumentSuccess,
  deleteActionDocumentFailure,
} from 'actions';
import { hideModal } from 'actions/modalActions';

import { hideLoanSuccess, hideLoanFailure } from 'features/MyMatchingLoans/actions';
import * as API from 'api';

import { getError } from 'sagas/utils';

import { push } from 'connected-react-router';

function* hideLoan(action) {
  try {
    const { pk, message, withoutRedirect } = action.payload;

    yield call(API.axiosApi.post, `loans/${pk}/hide`, { reason: message });

    yield put(
      hideLoanSuccess({ id: pk, message: 'The loan has been successfully hidden' })
    );

    if (!withoutRedirect) {
      yield put(push('/dashboard/matching-loans'));
    }
  } catch (e) {
    console.log(e);
    yield put(hideLoanFailure());
  }
}

function* submitConciergeAction(action) {
  try {
    const { loanId, reason } = action.payload;

    if (reason) {
      yield call(API.axiosApi.post, `loans/${loanId}/concierge_decline`, {
        user_comment: reason,
      });
    } else {
      yield call(API.axiosApi.post, `loans/${loanId}/concierge_approve`, {});
    }

    yield put(conciergeActionSuccess({ message: 'Successfully submitted' }));
    yield put(push('/dashboard'));
  } catch (e) {
    console.log(e);
    yield put(conciergeActionFailure({ message: getError(e) }));
  }
}

function* postQuestion(action) {
  const { question, isPublic, loanId } = action.payload;

  try {
    const {
      data: { response },
    } = yield call(API.axiosApi.post, `loans/${loanId}/questions`, {
      question,
      is_public: isPublic,
    });

    yield put(postQuestionSuccess(response));
    yield put(hideModal());
  } catch (e) {
    console.error(e, 'exeption');
    yield put(postQuestionFailure({ message: getError(e) }));
  }
}

function* getLoanActions(action) {
  const { id } = action.payload;

  try {
    const {
      data: { response },
    } = yield call(API.axiosApi.get, `/loans/${id}`);

    yield put(loanActionsSuccess(response));
  } catch (e) {
    console.error(e);
    yield put(loanActionsFailure({ message: getError(e) }));
  }
}

function* uploadActionLoanDocument(action) {
  const { id, form } = action.payload;
  try {
    const {
      data: { response },
    } = yield call(API.axiosApi.patch, `loans/${id}`, form);

    yield put(uploadActionDocumentSuccess(response));
  } catch (e) {
    console.error(e);
    yield put(uploadActionDocumentFailure({ message: getError(e) }));
  }
}

function* deleteActionLoanDocument(action) {
  const { id } = action.payload;
  try {
    yield call(API.axiosApi.delete, `documents/${id}`);

    yield put(deleteActionDocumentSuccess({ id }));
  } catch (e) {
    console.error(e);
    yield put(deleteActionDocumentFailure({ message: getError(e) }));
  }
}

export default [
  takeLatest(types.HIDE_LOAN_REQUEST, hideLoan),
  takeLatest(types.CONCIERGE_ACTION_REQUEST, submitConciergeAction),
  takeLatest(types.POST_QUESTION_REQUEST, postQuestion),
  takeLatest(types.LOAN_ACTIONS_REQUEST, getLoanActions),
  takeLatest(types.UPLOAD_ACTION_DOCUMENT_REQUEST, uploadActionLoanDocument),
  takeLatest(types.DELETE_ACTION_DOCUMENT_REQUEST, deleteActionLoanDocument),
];
