import types from 'constants/actionTypes';
import * as API from 'api';
import { getError } from 'sagas/utils';
import { put, takeLatest, call, select } from 'redux-saga/effects';

import {
    getSearchResultsSuccess,
    getSearchResultsFailure,
    getSearchHistoryFailure,
    getSearchHistorySuccess,
    getSearchCountSuccess,
    getSearchCountFailure,
} from './actions';


function* getSearchResults(action) {
  const state = yield select();
  const { keyword, tags, type, filter, nextPageNumber } = action.payload;
  
  const { globalSearch } = state;
  const data = {
    keyword: keyword || globalSearch.searchBar || '',
    filter: filter || globalSearch.filter || '',
    type: type === 'all' ? '' : type || globalSearch.type || '',
    tags: tags?.map((tag) => tag.value) || globalSearch.tags || '',
    page : nextPageNumber || 1
  };

  if(!data.keyword){
    return;
  }

  try {
    const {
      data: { response },
    } = yield call(API.axiosApi.get, 
      `search?types=${data.type}&query=${data.keyword}&page=${data.page}`
    );
    yield put(getSearchResultsSuccess(nextPageNumber ? {response, nextPageNumber} : response));

  } catch (e) {
    console.error(e);
    yield put(getSearchResultsFailure({ message: getError(e) }));
  }
}


function* getSearchHistory() {
  try {
    const {
      data: { response },
    } = yield call(API.axiosApi.get, `search-history`); 

    yield put(getSearchHistorySuccess(response));
  } catch (e) {
    console.error(e);
    yield put(getSearchHistoryFailure({ message: getError(e) }));
  }
}

function* getSearchCount(action) {
  const { keyword } = action.payload;
  try {
    const {
      data: { response },
    } = yield call(API.axiosApi.get, `search-count?query=${keyword}`); 

    const getCountAll = (counterObj) => {
      let summ = 0;
      for (const [key, value] of Object.entries(counterObj)) {
          summ += value;
      }
      return summ;
    }

    yield put(getSearchCountSuccess( {...response, all: getCountAll(response) }));
  } catch (e) {
    console.error(e);
    yield put(getSearchCountFailure({ message: getError(e) }));
  }
}

export default [
    takeLatest(types.GET_SEARCH_RESULTS_REQUEST, getSearchResults),
    takeLatest(types.GET_SEARCH_HISTORY_REQUEST, getSearchHistory),
    takeLatest(types.GET_SEARCH_COUNT_REQUEST, getSearchCount),
]