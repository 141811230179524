import { createSelector } from 'reselect';
import {
  LENDER,
  BORROWER,
  BROKER,
  SALES_BROKER,
  AM,
  LENDER_VISIBILITY_TYPES,
  STAFF,
  LENDER_TEAM_MEMBER_TYPES,
} from 'constants/appRelated';

const getAccountType = (state) => state.currentUser.accountType;
const getMemebrship = (state) => state.currentUser.membership;
const getLoansVisibility = (state) => state.currentUser.loanVisibility;
const getBrokerLendersVisibility = (state) => state.currentUser?.lenderVisibility;

export const userTypeSelector = createSelector(
  getAccountType,
  getLoansVisibility,
  getBrokerLendersVisibility,
  getMemebrship,
  (accountType, loanVisibility, brokerLendersVisibility, userMembership) => ({
    borrower: accountType === BORROWER,
    broker: accountType === BROKER,
    lender: accountType === LENDER,
    am: accountType === AM,
    staff: accountType === STAFF,
    salesBroker: accountType === SALES_BROKER,
    privateLender:
      accountType === LENDER && loanVisibility === LENDER_VISIBILITY_TYPES.PRIVATE,
    lenderAdmin: Boolean(
      accountType === LENDER &&
        userMembership &&
        (userMembership.permissionsLevel === LENDER_TEAM_MEMBER_TYPES.ADMIN ||
          userMembership.permissionsLevel === LENDER_TEAM_MEMBER_TYPES.COMPANY_ADMIN)
    ),
    accountType,
    privateBroker:
      (accountType === BROKER || accountType === BORROWER) &&
      brokerLendersVisibility === 'no',
  })
);
