import React, { Component } from 'react';
import { connect } from 'react-redux';
import { LENDER } from 'constants/appRelated';

import {
  loanAccessSelector,
  offersFlatSelector,
  acceptedNegotiationsSelector,
} from 'features/LoanNew/selectors';

import {
  DraftPanel,
  LenderPanel,
  BorowerPanel,
  PanelsOrder,
} from 'features/LoanNew/components/LoanNotifications';

class LoanActions extends Component {
  render() {
    const {
      loan,
      loan: { verificationFailedReason, status },
      isOwner,
      isLender,
      currentUser: { accountType },
      access,
      offersFlat,
      acceptedOffers,
    } = this.props;
    return (
      <PanelsOrder
        accessPreview={access.preview}
        status={status}
        isOwner={isOwner}
        isLender={isLender}
        draftPanel={
          <DraftPanel
            status={status}
            verificationFailedReason={verificationFailedReason}
            showFailedReason={this.showFailedReason}
          />
        }
        lenderPanel={
          <LenderPanel
            loanBlocked={!access.full}
            negotiations={offersFlat}
            acceptedOffer={acceptedOffers[0]}
            dealRooms={loan.dealRooms}
          />
        }
        borowerPanel={
          <BorowerPanel loan={loan} accountType={accountType} negotiations={offersFlat} />
        }
      />
    );
  }
}

function mapStateToProps(state) {
  const {
    loanNew: { data },
    currentUser,
  } = state;
  return {
    loan: data,
    id: data.id,
    access: loanAccessSelector(state),
    currentUser,
    accountType: currentUser.accountType,
    isOwner: data.isMy,
    isLender: currentUser.accountType === LENDER,
    offersFlat: offersFlatSelector(state),
    acceptedOffers: acceptedNegotiationsSelector(state),
  };
}

export default connect(mapStateToProps)(LoanActions);
