import React, { useRef, useEffect } from 'react';
import styled from 'styled-components/macro';
import { ActionsWrapper } from './ActionsWrapper';

export const Actions = ({active, setShowActions, data, children, handlerShowActions }) => {
  return (
    <>
        <ShowActions active={active} onClick={handlerShowActions}>
            <span/>
            <span/>
            <span/>
        </ShowActions>

        {active ? 
            <ActionsWrapper
                data={data}
                setShowActions={setShowActions}
                children={children}
            />
            : <></>
        }

    </>
)}


const ShowActions = styled.div`
  display: flex;
  cursor: pointer;
  width: 16px;
  height: 10px;
  justify-content: space-between;
  &:hover{
    span{background: #4394EA;}
  }
  span{
    display: block;
    background: ${(p) => (p.active ? '#4394EA' : '#778598')}; 
    width: 4px;
    height: 4px;
    border-radius: 50%;
  }
`;