import types from 'constants/actionTypes';

export const teamAnalyticsRequest = (payload = {}) => ({
  type: types.TEAM_ANALYTICS_REQUEST,
  payload,
});
export const teamAnalyticsSuccess = (payload = {}) => ({
  type: types.TEAM_ANALYTICS_SUCCESS,
  payload,
});
export const teamAnalyticsFailure = (payload = {}) => ({
  type: types.TEAM_ANALYTICS_FAILURE,
  payload,
});



export const getExportTeamAnalytics = (payload = {}) => ({
  type: types.GET_EXPORT_TEAM_ANALYTICS,
  payload,
});

export const exportTeamAnalyticsRequest = (payload = {}) => ({
  type: types.EXPORT_TEAM_ANALYTICS_REQUEST,
  payload,
});
export const exportTeamAnalyticsSuccess = (payload = {}) => ({
  type: types.EXPORT_TEAM_ANALYTICS_SUCCESS,
  payload,
});
export const exportTeamAnalyticsFailure = (payload = {}) => ({
  type: types.EXPORT_TEAM_ANALYTICS_FAILURE,
  payload,
});
