import React, { useState } from 'react';
import styled, { css } from 'styled-components/macro';
import { FileUpload } from 'components/FileUpload';
import { Text } from 'ui/Text';
import { DocumentIcon, LockOpenIcon, LockedIcon } from 'components/SvgIcons';
import { allowedDocTypes } from 'config/allowedDocTypes';
import { DocumentRejectionHandler } from 'components/DocumentRejectionHandler';

export const RequestedDocument = ({
  onDrop,
  label,
  uploaded,
  updateRequestedDocumentProtection,
  isDocumentProtected,
}) => {
  const [protectedDocument, updatePrivacy] = useState(!!isDocumentProtected);

  const toggleDocumentProtectedState = () => {
    updatePrivacy(!protectedDocument);

    if (uploaded) {
      updateRequestedDocumentProtection(!protectedDocument);
    }
  };

  return (
    <UploadWrapper data-testid="requestedDocumentItem">
      <DocumentName>
        <DocumentIcon />
        <Text>{label}</Text>
      </DocumentName>
      <CheckboxWrapper>
        <ProtectBtn onClick={toggleDocumentProtectedState} protected={protectedDocument}>
          {protectedDocument ? <LockedIcon color="#4394ea" /> : <LockOpenIcon />}
          <span>Request CA</span>
        </ProtectBtn>
      </CheckboxWrapper>
      {!uploaded && (
        <DocumentRejectionHandler>
          {({ handleOnDropReject }) => (
            <FileUploadContent
              accept={allowedDocTypes.loanDocs}
              onDropRejected={handleOnDropReject}
              onDrop={(files) => {
                onDrop({ files, protectedDocument });
              }}
            >
              <img src="/images/icons/ico_upload_blue.svg" alt="upload" />
              <Text>ADD TO LOAN REQUEST</Text>
            </FileUploadContent>
          )}
        </DocumentRejectionHandler>
      )}
    </UploadWrapper>
  );
};

const UploadWrapper = styled.div`
  display: flex;
  padding: 16px 0;
  border-bottom: 1px solid #e8e9ed;
  &:last-child {
    border-bottom: none;
  }

  @media (max-width: ${(p) => p.theme.breakpoints[1]}px) {
    flex-wrap: wrap;
  }
`;

const FileUploadContent = styled(FileUpload)`
  display: flex;
  align-items: center;
  outline: none;
  padding: 0;
  margin: 0;
  border: none;
  background-color: #fff;
  cursor: pointer;

  &:hover {
    ${Text} {
      text-decoration: underline;
    }
  }

  ${Text} {
    margin-left: 8px;
    color: #4394ea;
    font-size: 10px;
    flex-shrink: 0;
  }

  @media (max-width: ${(p) => p.theme.breakpoints[1]}px) {
    margin-top: 8px;
  }
`;

const DocumentName = styled.div`
  display: flex;
  align-items: center;
  width: 50%;

  ${Text} {
    margin-left: 8px;
    line-height: 16px;
  }

  svg {
    flex-shrink: 0;
  }

  @media (max-width: ${(p) => p.theme.breakpoints[1]}px) {
    flex: 1 auto;
  }
`;

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: auto;
`;

const ProtectBtnCommon = styled.button`
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0 6px 0 0;
  outline: none;
  background-color: #fff;
  border: none;
  cursor: pointer;

  svg {
    margin-right: 6px;
  }

  span {
    position: relative;
    top: 1px;
  }

  &:hover {
    span {
      text-decoration: underline;
    }
  }
`;

const ProtectBtn = styled(ProtectBtnCommon)`
  ${(p) =>
    !p.protected &&
    css`
      &:hover {
        g {
          stroke: #4394ea;
        }
      }
    `}
`;
