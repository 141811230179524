import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { CHARTS_TYPES, marketAnalyticsFiltersConfig } from 'features/Analytics/config';
import { makeUrlStringFromData } from 'lib/url';
import { values, find } from 'ramda';
import { CHART_BOXPLOT_CONFIGURATION } from 'features/Analytics/config';

export const BoxplotTooltip = ({
  data,
  onMouseLeave,
  boxPlotData,
  outliers = [],
  title,
  titleSymbol,
  boxColor,
  loansCount,
  ranges,
  minMax = [],
  trackSeeAll,
  tooltipPositionRight,
}) => {
  const uriDataString = makeUrlStringFromData({
    data: {
      haveOffersBefore: ranges[1],
      haveOffersAfter: ranges[0],
      min: minMax[0],
      q1: boxPlotData[1],
      q2: boxPlotData[2],
      q3: boxPlotData[3],
      max: minMax[1],
      outliers,
      loansCount,
      titleSymbol,
      boxColor,
      type: title,
    },
    config: marketAnalyticsFiltersConfig,
    flags: {
      noDateFormatting: true,
    },
  });

  return (
    <Tooltip
      left={data.left}
      tooltipPositionRight={tooltipPositionRight}
      top={data.top}
      onMouseLeave={onMouseLeave}
    >
      <TooltipTriangle tooltipPositionRight={tooltipPositionRight} />
      <TooltipContent>
        <BoxplotTooltipItem
          data={boxPlotData}
          title={title}
          titleSymbol={titleSymbol}
          loansCount={loansCount}
          ranges={ranges}
          outliers={outliers}
          boxColor={boxColor}
          minMax={minMax}
        />
        <TooltipLink
          to={`/dashboard/analytics/market/loans${uriDataString}`}
          onClick={trackSeeAll}
        >
          See All loans
        </TooltipLink>
      </TooltipContent>
    </Tooltip>
  );
};

function formatRangeData(date) {
  const splittedDate = date.split('-');
  return `${splittedDate[1]}-${splittedDate[2]}-${splittedDate[0]}`;
}

BoxplotTooltip.propTypes = {
  data: PropTypes.shape({
    left: PropTypes.number.isRequired,
    top: PropTypes.number.isRequired,
  }).isRequired,
  onMouseLeave: PropTypes.func.isRequired,
  boxPlotData: PropTypes.arrayOf(PropTypes.number).isRequired,
  outliers: PropTypes.arrayOf(PropTypes.number),
  ranges: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string,
  titleSymbol: PropTypes.string,
  loansCount: PropTypes.number,
  boxColor: PropTypes.string.isRequired,
  minMax: PropTypes.arrayOf(PropTypes.number),
  trackSeeAll: PropTypes.func,
};

export const BoxplotTooltipItem = ({
  data,
  title = '',
  titleSymbol = '',
  loansCount,
  ranges,
  boxColor,
  outliers = [],
  minMax,
}) => {
  const min = minMax[0];
  const q1 = data[1];
  const q2 = data[2];
  const q3 = data[3];
  const max = minMax[1];

  const pattern = find((v) => v.title === title)(values(CHARTS_TYPES))?.pattern;

  return (
    <div>
      <TooltipTitle>
        <b>{loansCount}</b> matching loans
        <br />
        from <b>{formatRangeData(ranges[0])}</b> to <b>{formatRangeData(ranges[1])}</b>
      </TooltipTitle>
      <BoxplotWrapper>
        <ItemData>
          <ItemRow>
            <ItemTitle>Max {title}: </ItemTitle>
            {pattern ? getDistributionValue(max, pattern) : `${max} ${titleSymbol}`}
          </ItemRow>
          <ItemRow>
            <ItemTitle>Q3: </ItemTitle>
            {pattern ? getDistributionValue(q3, pattern) : `${q3} ${titleSymbol}`}
          </ItemRow>
          <ItemRow>
            <ItemTitle>Q2:</ItemTitle>
            {pattern ? getDistributionValue(q2, pattern) : `${q2} ${titleSymbol}`}
          </ItemRow>
          <ItemRow>
            <ItemTitle>Q1: </ItemTitle>
            {pattern ? getDistributionValue(q1, pattern) : `${q1} ${titleSymbol}`}
          </ItemRow>
          <ItemRow>
            <ItemTitle>Min {title}: </ItemTitle>
            {pattern ? getDistributionValue(min, pattern) : `${min} ${titleSymbol}`}
          </ItemRow>
        </ItemData>
        {!!outliers.length && (
          <OutliersWrapper>
            Outliers:
            <OutliersData>
              {outliers.map((item, i) => (
                <OutliersItem bgColor={boxColor} key={`${item}${i}`}>
                  {pattern
                    ? getDistributionValue(item, pattern)
                    : `${item} ${titleSymbol}`}
                </OutliersItem>
              ))}
            </OutliersData>
          </OutliersWrapper>
        )}
      </BoxplotWrapper>
    </div>
  );
};

const getDistributionValue = (value, pattern) => value?.toString().replace(/.+/, pattern);

const Tooltip = styled.div`
  position: absolute;
  left: ${(p) => p.left}px;
  top: ${(p) => p.top}px;
  transform: translate(${(p) => (p.tooltipPositionRight ? '-104%' : '0')}, -50%);
  z-index: 5;
  box-shadow: ${(p) => p.theme.shadow};
  border: 1px solid #fafafa;
  width: ${CHART_BOXPLOT_CONFIGURATION.TOOLTIP_WIDTH}px;
  background: #fff;

  .line,
  .median {
    stroke: ${(p) => p.lineColor};
  }

  .box {
    fill: ${(p) => p.boxHoverColor};
  }

  .circle {
    fill: ${(p) => p.boxColor};
  }

  text {
    font-size: 12px;
    color: #1c3353;
  }
`;

const TooltipContent = styled.div`
  background: #fff;
  position: relative;
  z-index: 2;
  padding: 16px;
`;

const TooltipTitle = styled.div`
  font-size: 14px;
  margin-bottom: 8px;
`;

const TooltipLink = styled(Link)`
  font-size: 10px;
  text-transform: uppercase;
  line-height: 16px;
`;

const TooltipTriangle = styled.div`
  width: 12px;
  height: 12px;
  background: #fff;
  box-shadow: ${(p) => p.theme.shadow};
  position: absolute;
  left: ${(p) => (!p.tooltipPositionRight ? '-6px' : 'auto')};
  right: ${(p) => (p.tooltipPositionRight ? '-6px' : 'auto')};
  top: 50%;
  margin-top: -6px;
  transform: rotate(${(p) => (p.left ? '45deg' : '-45deg')});
  z-index: 1;
`;

const BoxplotWrapper = styled.div`
  font-size: 12px;
`;

const OutliersWrapper = styled.div`
  margin-bottom: 8px;
`;

const OutliersData = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const OutliersItem = styled.div`
  margin-top: 4px;
  width: 50%;

  &:before {
    content: '';
    height: 10px;
    width: 10px;
    border-radius: 50%;
    display: inline-block;
    background: ${(p) => p.bgColor};
    margin-right: 6px;
  }
`;

const ItemTitle = styled.div`
  width: 75px;
  font-weight: bold;
  display: inline-block;
  margin-right: 2px;
`;
const ItemRow = styled.div`
  margin-bottom: 2px;
`;

const ItemData = styled.div`
  margin-bottom: 8px;
`;
