import types from 'constants/actionTypes';

export const confirmAuthRequest = (payload = {}) => ({
  type: types.CONFIRM_AUTH_REQUEST,
  payload,
});
export const confirmAuthSuccess = (payload = {}) => ({
  type: types.CONFIRM_AUTH_SUCCESS,
  payload,
});
export const confirmAuthFailure = (payload = {}) => ({
  type: types.CONFIRM_AUTH_FAILURE,
  payload,
});

export const resendSmsCodeRequest = (payload = {}) => ({
  type: types.RESEND_SMS_CODE_REQUEST,
  payload,
});
export const resendSmsCodeSuccess = (payload = {}) => ({
  type: types.RESEND_SMS_CODE_SUCCESS,
  payload,
});
export const resendSmsCodeFailure = (payload = {}) => ({
  type: types.RESEND_SMS_CODE_FAILURE,
  payload,
});

export const resetConfirmAuth = (payload = {}) => ({
  type: types.RESET_CONFIRM_AUTH,
  payload,
});
