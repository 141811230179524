import types from 'constants/actionTypes';

const initialState = { filtersVisible: false };

export default function filters(state = initialState, action = {}) {
  const { type } = action;

  switch (type) {
    case types.OPEN_FILTERS:
      return {
        filtersVisible: true,
      };

    case types.CLOSE_FILTERS:
      return {
        filtersVisible: false,
      };

    default:
      return state;
  }
}
