import React from 'react';
import { Field } from 'react-final-form';
import {
  Input,
  DropdownList,
  Condition,
  NewCheckbox,
  InputPassword,
  InputWithErrorComp,
} from 'components/Form';
import { normalizeMobile, normalizeInteger } from 'lib/normalizers';
import { WorkPhone, WorkPhoneWrapper, WorkPhoneExt } from 'styles/auth';

import { SourceOther } from './SourceOther';
import { MobilePhoneFieldHint } from './MobilePhoneFieldHint';

export const BorrowerFields = ({
  borrowerTypes,
  zones,
  source,
  formValues: { entityType, llc },
  validateEmail,
  showMobilePhoneHint,
}) => {
  return (
    <React.Fragment>
      <Field
        component={DropdownList}
        placeholder="Borrower type"
        label="Choose borrower type"
        name="entityType"
        options={borrowerTypes}
        mb={3}
        required
      />
      {entityType && (
        <React.Fragment>
          <Condition when="entityType" is={borrowerTypes[0]}>
            {/* DropdownList component save current option only in object in form state*/}
            <Field
              component={Input}
              placeholder="a-z"
              label="Entity name"
              name="business_name"
              fullWidth
              required={!llc}
            />
            <Field
              component={NewCheckbox}
              label="LLC to be formed"
              name="llc"
              type="checkbox"
            />
          </Condition>
          <Field
            component={Input}
            placeholder="a-z"
            label={
              entityType === borrowerTypes[0] ? 'Authorized Signer Name' : 'First name'
            }
            name="first_name"
            fullWidth
            required
          />
          <Field
            component={Input}
            placeholder="a-z"
            label={
              entityType === borrowerTypes[0]
                ? 'Authorized Signer Last Name'
                : 'Last name'
            }
            name="last_name"
            fullWidth
            required
          />
          <Condition when="entityType" is={borrowerTypes[0]}>
            <Field
              component={Input}
              placeholder="a-z"
              label="Authorized Signer Title"
              name="title"
              fullWidth
              required
            />
          </Condition>
        </React.Fragment>
      )}
      <Field
        //component={Input}
        component={InputWithErrorComp}
        placeholder="your@mail.com"
        label="E-mail"
        name="email"
        validate={validateEmail}
        type="email"
        fullWidth
        required
        parse={(value) => (value ? value.trim() : value)}
      />
      <Field
        component={InputPassword}
        placeholder="Password"
        label="Password"
        name="password"
        fullWidth
        required
      />
      <Field
        component={Input}
        placeholder="+1"
        label="Mobile phone"
        name="mobilePhone"
        parse={normalizeMobile}
        fullWidth
        required
      />
      <MobilePhoneFieldHint visible={showMobilePhoneHint} />
      <Field
        component={Input}
        placeholder="https://"
        label="Company url"
        name="business_url"
        fullWidth
      />

      <WorkPhoneWrapper>
        <WorkPhone>
          <Field
            component={Input}
            placeholder="+1"
            label="Phone"
            name="phone"
            parse={normalizeMobile}
            fullWidth
          />
        </WorkPhone>
        <WorkPhoneExt>
          <Field
            component={Input}
            name="phone_ext"
            placeholder="ext."
            fullWidth
            parse={normalizeInteger}
            maxLength="4"
          />
        </WorkPhoneExt>
      </WorkPhoneWrapper>
      <Field
        component={DropdownList}
        placeholder="US/Central"
        label="Preferred timezone"
        name="preferred_timezone"
        options={zones}
        dropup
        mb={3}
      />
      <Field
        component={DropdownList}
        label="How did you know about us?"
        name="source"
        options={source}
        mb={3}
        dropup
      />
      <SourceOther source={source} />
    </React.Fragment>
  );
};
