import React from 'react';
import { OFFER_STATE, TERMSHEET_STATUSES } from 'constants/appRelated';
import { LoanNotification } from 'styles/loan';
import { last } from 'ramda';

export const LenderPanel = ({ negotiations, loanBlocked, dealRooms }) => {
  const acceptedOffer = negotiations.filter(
    (negotiation) => negotiation.state === OFFER_STATE.ACCEPTED
  )[0];
  const hasDealRooms = dealRooms && dealRooms.length;

  /*
    LOAN BLOCKED
  */

  if (loanBlocked) {
    return <LoanNotification variant="warn">loan is locked</LoanNotification>;
  }

  /*
    DEAL ROOM IS ACTIVE
  */

  if (hasDealRooms) {
    return <LoanNotification variant="success">Deal room was activated</LoanNotification>;
  }

  /*
    LENDER HAS ACCEPTED OFFER
  */

  if (acceptedOffer) {
    const { termsheets } = acceptedOffer;
    const lastTermsheet = last(termsheets);

    // todo: check

    if (TERMSHEET_STATUSES.BORROWER_SIGNED_TERMSHEET === lastTermsheet?.status) {
      return (
        <LoanNotification variant="warn">
          term sheet accepted, upload checklist{' '}
        </LoanNotification>
      );
    }

    if (TERMSHEET_STATUSES.BORROWER_REJECTED_TERMSHEET === lastTermsheet?.status) {
      return <LoanNotification variant="reject">termsheet returned</LoanNotification>;
    }

    if (termsheets.length) {
      return <LoanNotification variant="warn">awaiting client response</LoanNotification>;
    }

    return (
      <LoanNotification variant="success">
        quote was accepted, awaiting termsheet
      </LoanNotification>
    );
  }

  /*
    LENDER HAS OFFER
  */

  if (negotiations.length === 1) {
    if (negotiations[0].state === OFFER_STATE.NEW_OFFER) {
      return <LoanNotification variant="offers">you made a quote</LoanNotification>;
    }

    if (negotiations[0].state === OFFER_STATE.REJECTED) {
      return <LoanNotification variant="reject"> Quote passed</LoanNotification>;
    }

    if (!negotiations[0].lastOfferIsMy) {
      return <LoanNotification variant="warn">You have counteroffer</LoanNotification>;
    }
  }

  /*
    LENDER HAS OFFER ACTIVITY
  */

  // todo handle activity?
  if (negotiations.length > 1) {
    const hasActivity = negotiations.filter(
      (negotiation) =>
        negotiation.state === OFFER_STATE.REJECTED || !negotiation.lastOfferIsMy
    ).length;

    if (hasActivity) {
      return (
        <LoanNotification variant="warn">you have new loan activity</LoanNotification>
      );
    }
    return null;
  }

  /*
    LENDER DID NOT MAKE ANY OFFER
  */

  return null;
};
