export const syncActions = ['RESET_PROFILE'];

export const asyncActions = [
  'SUPPORT',
  'GET_LENDING_CRITERIA',
  'CHANGE_LENDING_CRITERIA',
  'REMOVE_LENDING_CRITERIA_BROCHURE',
  'GET_USER_PROFILE',
  'GET_USER_INFO',
  'CHANGE_USER_INFO',
  'BORROWER_ADD_NEW_MEMBER',
  'BORROWER_REMOVE_MEMBER',
  'CHANGE_BORROWER_MEMBER',
  'RESEND_TEAM_MEMBER_INVITATION',
  'CHANGE_TEAM_DATA',
  'REMOVE_TEAM_LOGO',
  'CHANGE_USER_PROFILE',
  'CHANGE_PASSWORD',
  'GET_INSTALLED_APPS',
  'REMOVE_INSTALLED_APP',
];
