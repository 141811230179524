import { createSelector } from 'reselect';
import { LENDER } from 'constants/appRelated';
import { forEach, prop, indexBy } from 'ramda';

export const getAccountType = (state) => state.currentUser.accountType;
const getAllLoanTypes = (state) => state.configuration.loanTypes;

export const getCurrentUser = (state) => state.currentUser;
export const getCurrentUserId = (state) => state.currentUser.id;

export const getPlanUsePropertyItems = (state) => state.configuration.planUseProperty;
export const getPropertyTypesItems = (state) => state.configuration.propertyTypes;
export const getDocumentsTypes = (state) => state.configuration.documentsTypes;
export const getInterestOnlyOptions = (state) => state.configuration.interestOnlyOptions;
export const getNewClientItem  = (state) => state.clientsDirectory.newClient;

export const getClientItems = (state) => {
  const clientOptions = [];
  for (const [key, value] of Object.entries(state.clientsDirectory.clients)) {
    clientOptions.push(value)
  }
  return clientOptions
};

export const getDashboardData = (state) => state.dashboard;

export const getNormalizedDocumentsTypes = (state) =>
  state.configuration.normalizedDocumentsTypes;

export const getNormalizedLoanTypes = createSelector([getAllLoanTypes], (loanTypes) =>
  indexBy(prop('value'), loanTypes)
);

export const getNormalizedPropertyTypes = createSelector(
  [getPropertyTypesItems],
  (propertyTypes) => indexBy(prop('value'), propertyTypes)
);

export const getNormalizedPlanUseProperty = createSelector(
  [getPlanUsePropertyItems],
  (planUseProperty) => indexBy(prop('value'), planUseProperty)
);

export const getLoanAmortizationChoices = (state) =>
  state.configuration.loanAmortizationChoice;

export const getLoanTypes = createSelector(
  [getAccountType, getAllLoanTypes],
  (accountType, loanTypes) =>
    loanTypes
      .filter(({ lenderOnly }) =>
        lenderOnly && accountType ? accountType === LENDER : true
      )
      .map(({ value, label }) => ({ value, label }))
);

export const getPlanUseProperty = createSelector(
  [getPlanUsePropertyItems],
  (planUsePropertyItems) =>
    planUsePropertyItems.map(({ value, label }) => ({ value, label }))
);

export const getPlanUsePropertySchema = (state) =>
  state.configuration.schema.planUseProperty;

export const getPropertyTypes = createSelector(
  [getPropertyTypesItems],
  (propertyTypesItems) => propertyTypesItems.map(({ value, label }) => ({ value, label }))
);

export const getNewClient = createSelector(
  [getNewClientItem],
  (clientItem) => {
    const {firstName, lastName, companyName, id} = clientItem;
    let company = companyName ? `- ${companyName}` : ''

    return {
      value: id,
      label: `${firstName} ${lastName}  ${company}`
    }
  }
)

export const getClients = createSelector(
  [getClientItems],
  (clientItems) => clientItems.map(({ firstName, lastName, id, companyName }) => {
    let company = companyName ? `- ${companyName}` : ''

    return {
      value: id,
      label: `${firstName} ${lastName}  ${company}`
    }
  })
)

const getChatParticipiantLender = (state, props) =>
  state.chat[props.tabType].chats[props.roomId].lender;
const getChatParticipiantBorrower = (state, props) =>
  state.chat[props.tabType].chats[props.roomId].borrower;

const getChatParticipiantAdmin = (state, props) =>
  state.chat?.[props.tabType]?.chats?.[props.roomId]?.participants;

export const getChatParticipiants = createSelector(
  getChatParticipiantLender,
  getChatParticipiantBorrower,
  getChatParticipiantAdmin,
  (lender, borrower, participants) => {
    const obj = {};

    forEach((item) => {
      obj[item.id] = item;
    }, participants || []);

    if (lender) {
      return {
        [lender.id]: lender,
        [borrower.id]: borrower,
        ...obj,
      };
    } else {
      return {
        [borrower.id]: borrower,
        ...obj,
      };
    }
  }
);

export const getChatUnreadMessagesCount = (state) =>
  state.header.unreadMessagesCount.loan + state.header.unreadMessagesCount.personal;

export const getChatMentionList = createSelector(
  [getChatParticipiantAdmin],
  (participants = []) => {
    const list = participants.filter(({ mentionable }) => mentionable);
    return list.length > 1 ? list : [];
  }
);

export const getTeamOptions = (state) => state?.configuration?.options?.teamOptions || [];

export const getLenderTypeOptions = (state) =>
  state.configuration.options.lenderTypeOptions;
