import types from 'constants/actionTypes';

export const showQuotesWidgetSuccess = (payload = {}) => ({
  type: types.SHOW_QUOTES_WIDGET_SUCCESS,
  payload,
});

export const showQuotesWidgetFailure = (payload = {}) => ({
  type: types.SHOW_QUOTES_WIDGET_FAILURE,
  payload,
});


export const hideQuotesWidget = (payload = {}) => ({
  type: types.HIDE_QUOTES_WIDGET,
  payload,
});


export const showQuotesWidget = (payload = {}) => ({
  type: types.SHOW_QUOTES_WIDGET,
  payload,
});