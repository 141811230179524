import React from 'react';
import * as Sentry from '@sentry/react';

const styles = {
  container: {
    padding: '40px',
  },
  link: {
    color: '#ff9b12',
    textDecoration: 'none',
  },
};

const chunkErrorFragment = 'Loading chunk';
const cssChunkErrorFragment = 'Loading CSS chunk';

const chunkErrorsArr = [chunkErrorFragment, cssChunkErrorFragment];

const FallbackComponent = () => (
  <div style={styles.container}>
    <p>Oops, looks like something has gone wrong</p>
    <a href="/dashboard" style={styles.link}>
      Go back to Dashboard
    </a>
  </div>
);

export const ErrorBoundary = ({ children }) => (
  <Sentry.ErrorBoundary
    fallback={FallbackComponent}
    onError={(error) => {
      const isChunkError = chunkErrorsArr.filter(
        (v) => error.name.indexOf(v) !== -1 || error.message.indexOf(v) !== -1
      ).length;

      if (isChunkError) {
        if (
          navigator &&
          (navigator.userAgent.indexOf('MSIE') !== -1 ||
            navigator.appVersion.indexOf('Trident/') > -1)
        ) {
          /* Microsoft Internet Explorer detected in. */
          /* eslint-disable-next-line */
          window.location.href = window.location.href;
        } else {
          window.location.reload(true);
        }

        alert('newer version of application available, page was reloaded');
      }
    }}
  >
    {children}
  </Sentry.ErrorBoundary>
);
