import { put, takeLatest, call } from 'redux-saga/effects';
import { getError } from 'sagas/utils';
import types from 'constants/actionTypes';
import { CHAT_ROOM, ROOMS_TYPES } from 'constants/appRelated';
import * as API from 'api';
import { showChatWidgetSuccess, showChatWidgetFailure } from './actions';

function* showChatWidget(action) {
  const { loanId, lenderId, roomType, roomId, bbId, messageUuid, highlights, messageId } = action.payload;
  
  if (roomId) {
    yield put(showChatWidgetSuccess({ roomId, roomType, messageUuid, highlights, messageId }));
  } else {
    try {
      const loansCustomQuery = `chat/rooms?${!!loanId ? `loan=${loanId}` : ''}${
        !!lenderId ? `&lender=${lenderId}` : ''
      }${!!bbId ? `&bb=${bbId}` : ''}`;

      const directCustomQuery = `chat/rooms?personal=1&${
        !!lenderId ? `lender=${lenderId}` : ''
      }${!!bbId ? `bb=${bbId}` : ''}`;

      const type = ROOMS_TYPES[roomType];

      const {
        data: { response },
      } = yield call(
        API.axiosApi.get,
        type === CHAT_ROOM.LOAN ? loansCustomQuery : directCustomQuery
      );

      if (!response.results || !response.results.length) {
        yield put(showChatWidgetFailure({ message: 'Chat not found' }));
      }

      yield put(
        showChatWidgetSuccess({
          roomType,
          roomId:
            type === CHAT_ROOM.LOAN
              ? response.results[0]?.rooms[0]?.id
              : response.results[0]?.id,
        })
      );
    } catch (e) {
      console.error(e);
      yield put(showChatWidgetFailure({ message: getError(e) }));
    }
  }
}

export default [takeLatest(types.SHOW_CHAT_WIDGET_REQUEST, showChatWidget)];
