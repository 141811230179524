import React from 'react';
import styled from 'styled-components/macro';

const FileTitle = styled.div`
  margin-left: 16px;
  font-weight: 600;
`;

export const File = ({
  type,
  link,
  openConfidentialityAgreementModal,
  daiId,
  loanId,
  caSignedDealRoom,
  openDealRoomConfidentialityAgreementModal,
}) => {
  return (
  <Wrapper
    target={caSignedDealRoom && "_blank"}
    rel="noopener noreferrer"
    href={caSignedDealRoom && link}
    onClick={
      link && !caSignedDealRoom ? () => openDealRoomConfidentialityAgreementModal(link, loanId) : 
      link ? null : () => openConfidentialityAgreementModal({ daiId, loanId })}
  >
    <img src="/images/icons/ico_file.svg" alt="" />
    <FileTitle>{type}</FileTitle>
  </Wrapper>
  )
};

const Wrapper = styled.a`
  display: flex;
  text-decoration: none;
  align-items: center;
  color: #1c3353;
  cursor: pointer;

  & > img {
    margin: 2px;
    max-width: 32px;
  }
`;
