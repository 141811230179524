import React from 'react';
import styled from 'styled-components/macro';
import { Text } from 'ui/Text';

function getBgImage(type) {
  switch (type) {
    case 'questions':
      return 'linear-gradient(to right top, #E485FF, #A55EFF)';
    case 'counteroffer':
      return 'linear-gradient(to right bottom, #FFE269, #FD8440)';
    case 'offer':
      return 'linear-gradient(to right top, #24B8FE, #6987FF)';
    case 'document':
      return 'linear-gradient(to top, #F06F96, #EE7C61)';
    case 'termsheet':
      return 'linear-gradient(to top, #6CE1E9, #3ED6AF)';
    case 'dealroom':
      return 'linear-gradient(to right, #3878D3, #A497FD)';
    default:
      return 'linear-gradient(to right top, #E485FF, #A55EFF)';
  }
}

function getIconImg(type) {
  switch (type) {
    case 'questions':
      return '/images/icons/ico_question_white.svg';
    case 'counteroffer':
      return '/images/icons/ico_conversation_white.svg';
    case 'offer':
      return '/images/icons/ico_sheet_white.svg';
    case 'document':
      return '/images/icons/ico_folder_white.svg';
    case 'termsheet':
      return '/images/icons/ico_document_white.svg';
    case 'dealroom':
      return '/images/icons/ico_folder_white.svg';
    default:
      return null;
  }
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  border-bottom: 1px solid #ecedf1;
`;

const Label = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 24px;
  width: 54px;
  height: 54px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
`;

export const ActionHeader = ({ type, label }) => (
  <Wrapper>
    <Label type={type} style={{ backgroundImage: getBgImage(type) }}>
      <img src={getIconImg(type)} alt="icon" />
    </Label>
    <Text fontSize="20px" color="#2B4C78">
      {label}
    </Text>
  </Wrapper>
);
