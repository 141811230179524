import { put, takeLatest, call, takeEvery, select } from 'redux-saga/effects';
import types from 'constants/actionTypes';
import * as API from 'api';
import { decamelizeKeys } from 'humps';
import { showModal } from 'actions/modalActions';
import { modals } from 'constants/modalTypes';
import { downloadBlobFile } from 'lib/browser';
import { getError } from 'sagas/utils';
import { normalize } from 'normalizr';
import { lendersDirectoryListSchema } from 'utils/schemas';

import {
  lenderDirectoryInviteDocumentSuccess,
  lenderDirectoryInviteDocumentFailure,
  lenderDirectoryInviteSuccess,
  lenderDirectoryInviteFailure,
  getLendersDirectoryDataFailure,
  getLendersDirectoryDataSuccess,
  getLendersActivityDataSuccess,
  getLendersActivityDataFailure,
  checkLenderDirectoryEmailSuccess,
  checkLenderDirectoryEmailFailure,
  exportLendersListFailure,
  exportLendersListSuccess,
  updateLendersDirectoryDataFailure,
  updateLendersDirectoryDataSuccess,
  getMoreLendersSuccess
} from './actions';

function* getData(action) {
  try {
    const {
      router: { location },
    } = yield select();
    const { loanId } = action.payload;
    const data = {};

    const requestUri = [
      location.search.replace('?', ''),
      loanId ? `&matched_to_loan=${loanId}` : '',
    ]
      .filter(Boolean)
      .join('&');

    const {
      data: { response },
    } = yield call(
      API.axiosApi.get,
      `lenders/invite?${requestUri ? `&${requestUri}` : ''}`
    );

    const { entities, result } = normalize(response.results, lendersDirectoryListSchema);
    data.lenders = { ...entities.lenders };
    data.lendersArray = result;
    data.pageNumber = response.next ? new URL(response.next).searchParams.get("page") : null;
    data.count = response.count;

    const tags = yield call(API.axiosApi.get, '/lender-tags/');

    const updatedTags = tags.data.response.map((tag) => ({ label: tag, value: tag }));
    data.tags = updatedTags;

    yield put(getLendersDirectoryDataSuccess(data));
  } catch (e) {
    console.log(e);
    yield put(getLendersDirectoryDataFailure({ message: getError(e) }));
  }
}

function* updateData() {
  try {
    const {
      filter,
      lenderDirectory: { searchString },
    } = yield select();

    const requestUri = [filter.uri, searchString ? `search=${searchString}` : '']
      .filter(Boolean)
      .join('&');

    const {
      data: { response },
    } = yield call(
      API.axiosApi.get,
      `lenders/invite?${requestUri ? `&${requestUri}` : ''}`
    );

    const { entities, result } = normalize(response.results, lendersDirectoryListSchema);

    let pageNumber = response.next ? new URL(response.next).searchParams.get("page") : null;
    let count = response.count;
    
    yield put(
      updateLendersDirectoryDataSuccess({
        lenders: entities.lenders,
        lendersArray: result,
        count,
        pageNumber,
      })
    );
  } catch (e) {
    console.log(e);
    yield put(updateLendersDirectoryDataFailure({ message: getError(e) }));
  }
}

function* inviteLenders(action) {
  const { payload } = action;
  try {
    const data = {};
    const {
      data: { response },
    } = yield call(
      API.axiosApi.post,
      'lenders/invite',
      payload.map((item) => decamelizeKeys(item))
    );

    const { entities, result } = normalize(response, lendersDirectoryListSchema);
    data.lenders = { ...entities.lenders };
    data.lendersArray = result;
    data.pageNumber = response.next ? new URL(response.next).searchParams.get("page") : null;
    data.count = response.count;

    yield put(lenderDirectoryInviteSuccess(data));
  } catch (e) {
    console.log(e);
    yield put(lenderDirectoryInviteFailure({ message: getError(e) }));
  }
}

function* inviteLendersDocument(action) {
  const { payload } = action;
  try {
    const {
      data: { response },
    } = yield call(API.axiosApi.post, 'lenders/invite_file', payload);
    yield put(lenderDirectoryInviteDocumentSuccess());
    yield put(
      showModal(modals.LENDER_DIRECTORY_ADD_SUCCESS, { fileName: response.filename })
    );
  } catch (e) {
    console.log(e);
    yield put(lenderDirectoryInviteDocumentFailure({ message: getError(e) }));
  }
}

function* getLenderActivityData(action) {
  const { id } = action.payload;
  try {
    const {
      data: { response },
    } = yield call(
      API.axiosApi.get,
      id ? `/lenders/activity?loan=${id}` : '/lenders/activity/'
    );
    yield put(getLendersActivityDataSuccess({ response }));
  } catch (e) {
    console.log(e);
    yield put(getLendersActivityDataFailure({ message: getError(e) }));
  }
}

function* checkLenderEmail(action) {
  const { email, reject, resolve, checkLenderId } = action.payload;
  try {
    const {
      data: { response },
    } = yield call(API.axiosApi.post, 'lenders/invite/check-email', {
      email,
    });

    if (response?.result === 'exists') {
      reject({ error: 'This e-mail is already used', data: response });
    } else if (response?.result === 'personal') {
      reject({ error: 'Please use work email address', data: response });
    } else {
      resolve();
    }
    yield put(
      checkLenderDirectoryEmailSuccess({ lenderId: checkLenderId && response?.lenderId })
    );
  } catch (e) {
    console.error(e);
    yield put(checkLenderDirectoryEmailFailure());
    reject();
  }
}

function* exportLendersList() {
  try {
    const { filter } = yield select();
    const { data, headers } = yield call(
      API.axiosApiRaw.get,
      `/lenders/invite/export?${filter.uri || ''}`,
      {
        responseType: 'blob',
      }
    );

    downloadBlobFile(data, headers['content-disposition'].split('=')[1]);
    yield put(exportLendersListSuccess());
  } catch (e) {
    console.log(e);
    yield put(exportLendersListFailure({ message: getError(e) }));
  }
}
function* getMoreLenders(action) {
  try {
    let currentPage = action.payload;

    const {
      filter,
      lenderDirectory: { searchString },
    } = yield select();

    const requestUri = [filter.uri, searchString ? `search=${searchString}` : '']
    .filter(Boolean)
    .join('&');

    const {
      data: { response },
    } = yield call(
      API.axiosApi.get,
      `lenders/invite?page=${currentPage}${requestUri ? `&${requestUri}` : ''}`
    );
    const tags = yield call(API.axiosApi.get, '/lender-tags/');

    const updatedTags = tags.data.response.map((tag) => ({ label: tag, value: tag }));
    
    const { entities, result } = normalize(response.results, lendersDirectoryListSchema);
    let newLenders = { ...entities.lenders };
    let newLendersArray = result;
    let pageNumber = response.next ? new URL(response.next).searchParams.get("page") : null;
    let count = response.count;
    let newTags = updatedTags;

    yield put(getMoreLendersSuccess({ newLenders, newLendersArray, pageNumber, count, newTags }));

  } catch (e) {
    
  }
}

export default [
  takeLatest(types.LENDER_DIRECTORY_INVITE_REQUEST, inviteLenders),
  takeLatest(types.LENDER_DIRECTORY_INVITE_DOCUMENT_REQUEST, inviteLendersDocument),
  takeLatest(types.GET_LENDERS_DIRECTORY_DATA_REQUEST, getData),
  takeLatest(types.UPDATE_LENDERS_DIRECTORY_DATA_REQUEST, updateData),
  takeLatest(types.GET_LENDERS_ACTIVITY_DATA_REQUEST, getLenderActivityData),
  takeEvery(types.CHECK_LENDER_DIRECTORY_EMAIL_REQUEST, checkLenderEmail),
  takeLatest(types.EXPORT_LENDERS_LIST_REQUEST, exportLendersList),
  takeLatest(types.GET_MORE_LENDERS_REQUEST, getMoreLenders),
];
