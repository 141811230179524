import { put } from 'redux-saga/effects';
import { modals, INFO_MODAL_TYPE } from 'constants/modalTypes';
import { hideSpinner, requestPermissionRequest } from 'actions';
import { showModal } from 'actions/modalActions';
import {
  INTERCOM_APP_ID,
  SUBSCRIPTION_STATUS,
  LENDER,
  BROKER,
  BORROWER,
  APPROVE_STATUSES,
} from 'constants/appRelated';

export function* callPermissionModal(permissionAsked) {
  yield put(hideSpinner());
  if (permissionAsked) {
    yield put(
      showModal(modals.INFO_MODAL, {
        title: 'Your permission request has been sent',
        infoType: INFO_MODAL_TYPE.WARNING,
        buttonText: 'OK',
        size: 'sm',
      })
    );
  } else {
    yield put(
      showModal(modals.INFO_MODAL, {
        title: "You don't have enough permissions",
        infoType: INFO_MODAL_TYPE.WARNING,
        buttonText: 'Request permissions',
        size: 'sm',
        onButtonClick: (dispatch) => {
          dispatch(requestPermissionRequest());
        },
      })
    );
  }
}

const paidPlan = (num) => {
  switch (num) {
    case SUBSCRIPTION_STATUS.FREEMIUM:
      return 'Free';
    default:
      return 'Grandfather';
  }
};

const approveStatus = (status) => {
  switch (status) {
    case APPROVE_STATUSES.DENIED:
      return 'Denied';
    case APPROVE_STATUSES.PENDING:
      return 'Pending';
    case APPROVE_STATUSES.APPROVED:
      return 'Approved';
    case APPROVE_STATUSES.IN_VERIFICATION:
      return 'In-verification';
    default:
      return 'Pre-registered';
  }
};

export const getIntercomSettings = (user) => {
  switch (user.accountType) {
    case BORROWER:
    case BROKER:
      return {
        app_id: INTERCOM_APP_ID,
        email: user.email,
        user_id: user.id,
        name: `${user.firstName} ${user.lastName}`,
        accountType: user.accountType,
        draftLoansCount: user.draftLoansCount,
        submittedLoansCount: user.submittedLoansCount,
        postedLoansCount: user.postedLoansCount,
        invitedByLender: user.invitedByLender,
        user_hash: user.userHash,
      };
    case LENDER:
      return {
        app_id: INTERCOM_APP_ID,
        email: user.email,
        user_id: user.id,
        name: `${user.firstName} ${user.lastName}`,
        accountType: user.accountType,
        paidPlan: paidPlan(user.subscription),
        approveStatus: approveStatus(user.approveStatus),
        user_hash: user.userHash,
      };
    default:
      return {
        app_id: INTERCOM_APP_ID,
        email: user.email,
        user_id: user.id,
        name: `${user.firstName} ${user.lastName}`,
        accountType: user.accountType,
        user_hash: user.userHash,
      };
  }
};

export const getError = (e) => {
  const errorStatus = e?.response?.status;
  const isNotAuthCredentialsError = errorStatus !== 401 && errorStatus !== 403;

  if (errorStatus === 'ignore') {
    return;
  }

  if (isNotAuthCredentialsError) {
    return (
      e?.response?.data?.error?.message || 'Oops, looks like something has gone wrong'
    );
  } else {
    return 'Looks like your session has expired. Please, log in again.';
  }
};
