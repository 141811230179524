import { INTERCOM_APP_ID } from 'constants/appRelated';

export const intercomIdentify = (data) => {
  if (window.Intercom) {
    if (data) {
      window.Intercom('boot', data);
    } else {
      window.Intercom('boot', {
        app_id: INTERCOM_APP_ID,
      });
    }
  }
};
