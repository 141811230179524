import { normalizeDollars } from 'lib/normalizers';
import { FIELD_TYPES } from 'constants/appRelated';
import { format } from 'date-fns';

export const getDisplayDataByFilterConfig = ({
  data,
  preset,
  options,
  filterSections,
}) => {
  const results = {};
  if (!preset) {
    return results;
  }

  preset.forEach((field) => {
    const fieldConfig = filterSections[field];

    switch (fieldConfig.type) {
      case FIELD_TYPES.CHOICE:
        if (data[field]) {
          results[field] = options[`${field}Options`]?.[data[field]]?.label;
        }
        break;

      case FIELD_TYPES.CHOICE_MULTI:
        if (data[field] && data[field].length) {
          const labels = data[field].map(
            (item) => options[`${field}Options`]?.[item]?.label
          );

          if (field === 'loanAmount') {
            const dataOptions = data[field].map(Number);
            const lastAmountOption = 7;
            if (dataOptions.length === 1 && dataOptions[0] === lastAmountOption) {
              results[field] = labels[0];
            } else {
              const loanAmountOptions = labels
                .filter((label) => label !== options.loanAmountOptions[7].label)
                .reduce(
                  (acc, string) => [...acc, ...string.replace(/[\s$,]/g, '').split('-')],
                  []
                );
              const loanAmountMin = Math.min.apply(null, loanAmountOptions);
              const loanAmountMax = Math.max.apply(null, loanAmountOptions);

              results[field] = `from: ${normalizeDollars(loanAmountMin)}, to: ${
                dataOptions.indexOf(lastAmountOption) !== -1
                  ? options.loanAmountOptions[lastAmountOption].label
                  : normalizeDollars(loanAmountMax)
              }`;
            }
          } else {
            results[field] = labels.join(', ');
          }
        }
        break;

      case FIELD_TYPES.DURATION_UNIT:
        const valueMin = data[`${field}Min`];
        const valueMax = data[`${field}Max`];
        const valueMinUnit = data[`${field}MinUnit`];
        const valueMaxUnit = data[`${field}MaxUnit`];

        if (
          (valueMin !== null && valueMin !== undefined) ||
          (valueMax !== null && valueMax !== undefined)
        ) {
          results[field] = {};

          const fromValue =
            valueMin &&
            `from: ${valueMin} ${Number(valueMinUnit) ? 'month' : 'year'}${
              valueMin > 1 ? 's' : ''
            }`;

          const toValue =
            valueMax &&
            `to: ${valueMax} ${Number(valueMaxUnit) ? 'month' : 'year'}${
              valueMax > 1 ? 's' : ''
            }`;

          results[field] = `${fromValue || ''}${
            fromValue && toValue ? ', ' : ''
          }${toValue || ''}`;
        }

        break;

      case FIELD_TYPES.DATE:
        if (data[`${field}Before`] || data[`${field}After`]) {
          const before = format(new Date(data[`${field}Before`]), 'yyyy-MM-dd');
          const after = format(new Date(data[`${field}After`]), 'yyyy-MM-dd');
          const beforeValue = before && `to: ${before}`;
          const afterValue = after && `from: ${after}`;

          results[field] = `${afterValue || ''}${
            afterValue && beforeValue ? ', ' : ''
          }${beforeValue || ''}`;
        }

        break;
      case FIELD_TYPES.BOOLEAN:
        if (data[field]) {
          results[field] = 'yes';
        }
        break;
      default:
    }
  });
  return results;
};
