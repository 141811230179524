import types from 'constants/actionTypes';

export const getAnalyticsLendersDataRequest = (payload = {}) => ({
  type: types.GET_ANALYTICS_LENDERS_DATA_REQUEST,
  payload,
});
export const getAnalyticsLendersDataSuccess = (payload = {}) => ({
  type: types.GET_ANALYTICS_LENDERS_DATA_SUCCESS,
  payload,
});
export const getAnalyticsLendersDataFailure = (payload = {}) => ({
  type: types.GET_ANALYTICS_LENDERS_DATA_FAILURE,
  payload,
});

export const updateAnalyticsLendersDataRequest = (payload = {}) => ({
  type: types.UPDATE_ANALYTICS_LENDERS_DATA_REQUEST,
  payload,
});
export const updateAnalyticsLendersDataSuccess = (payload = {}) => ({
  type: types.UPDATE_ANALYTICS_LENDERS_DATA_SUCCESS,
  payload,
});
export const updateAnalyticsLendersDataFailure = (payload = {}) => ({
  type: types.UPDATE_ANALYTICS_LENDERS_DATA_FAILURE,
  payload,
});

export const getLendersActivityRequest = (payload = {}) => ({
  type: types.GET_LENDERS_ACTIVITY_REQUEST,
  payload,
});
export const getLendersActivitySuccess = (payload = {}) => ({
  type: types.GET_LENDERS_ACTIVITY_SUCCESS,
  payload,
});
export const getLendersActivityFailure = (payload = {}) => ({
  type: types.GET_LENDERS_ACTIVITY_FAILURE,
  payload,
});

export const clearMarketAnalyticsLenders = (payload = {}) => ({
  type: types.CLEAR_MARKET_ANALYTICS_LENDERS,
  payload,
});
