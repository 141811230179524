import React from 'react';
import styled from 'styled-components/macro';
import { Text } from 'ui/Text';
import { Section, FileLink } from 'features/DealRoom/styles';
import { FolderIcon, DocumentIcon } from 'components/SvgIcons';

export const LoanDocuments = ({ documents, handlerCAForDocuments }) => {
  const { recommended, sections } = documents;

  const filteredRecommendedDocuments = recommended.filter(
    (folder) => !!folder.documents.length
  );

  const filteredSectionsDocuments = sections
    .map((section) => ({
      ...section,
      folders: section.folders.filter((folder) => !!folder.documents.length),
    }))
    .filter((section) => !!section.folders.length);

  if (!filteredRecommendedDocuments.length && !filteredSectionsDocuments.length) {
    return null;
  }

  return (
    <div>
      <Title>Loan request documents</Title>
      <Section>
        {filteredRecommendedDocuments.map((folder) => (
          <div key={folder.id}>
            <FolderTitle>
              <FolderIcon />
              {folder.name}
            </FolderTitle>
            <DocumentsList>
              {folder.documents.map((docItem) => (
                <StyledFileLink
                  onClick={() => 
                    handlerCAForDocuments(false, docItem.documentId, folder.id, docItem.documents, docItem.url)}
                    key={docItem.id}
                    rel="noopener noreferrer"
                >
                  <DocumentIcon />
                  {docItem.filename}
                </StyledFileLink>
              ))}
            </DocumentsList>
          </div>
        ))}

        {filteredSectionsDocuments.map((section) => (
          <div key={section.sectionId}>
            <SectionName>{section.sectionName}</SectionName>
            {section.folders.map((folder) => (
              <div key={folder.id}>
                <FolderTitle>
                  <FolderIcon />
                  {folder.name}
                </FolderTitle>
                <DocumentsList>
                  {folder.documents.map((docItem) => (
                    <StyledFileLink
                      onClick={() => handlerCAForDocuments(false, docItem.documentId, folder.id, docItem.documents, docItem.url)}
                      key={docItem.id}
                      rel="noopener noreferrer"
                    >
                      <DocumentIcon />
                      {docItem.filename}
                    </StyledFileLink>
                  ))}
                </DocumentsList>
              </div>
            ))}
          </div>
        ))}
        
      </Section>

    </div>
  );
};

const Title = styled(Text)`
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 16px;
`;

const SectionName = styled(Text)`
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 16px;
`;

const FolderTitle = styled(Text)`
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  svg {
    margin-right: 8px;
  }
`;

const DocumentsList = styled.div`
  padding-left: 24px;
  cursor: pointer;
`;

const StyledFileLink = styled(FileLink)`
  margin-bottom: 16px;
`;
