import types from 'constants/actionTypes';

export const sendLoanToLendersRequest = (payload = {}) => ({
  type: types.SEND_LOAN_TO_LENDERS_REQUEST,
  payload,
});
export const sendLoanToLendersSuccess = (payload = {}) => ({
  type: types.SEND_LOAN_TO_LENDERS_SUCCESS,
  payload,
});
export const sendLoanToLendersFailure = (payload = {}) => ({
  type: types.SEND_LOAN_TO_LENDERS_FAILURE,
  payload,
});

export const getRealatomLendersDataRequest = (payload = {}) => ({
  type: types.GET_REALATOM_LENDERS_DATA_REQUEST,
  payload,
});
export const getRealatomLendersDataSuccess = (payload = {}) => ({
  type: types.GET_REALATOM_LENDERS_DATA_SUCCESS,
  payload,
});
export const getRealatomLendersDataFailure = (payload = {}) => ({
  type: types.GET_REALATOM_LENDERS_DATA_FAILURE,
  payload,
});

export const getRelationshipLendersRequest = (payload = {}) => ({
  type: types.GET_RELATIONSHIP_LENDERS_REQUEST,
  payload,
});
export const getRelationshipLendersSuccess = (payload = {}) => ({
  type: types.GET_RELATIONSHIP_LENDERS_SUCCESS,
  payload,
});
export const getRelationshipLendersFailure = (payload = {}) => ({
  type: types.GET_RELATIONSHIP_LENDERS_FAILURE,
  payload,
});

export const updateRelationshipLendersRequest = (payload = {}) => ({
  type: types.UPDATE_RELATIONSHIP_LENDERS_REQUEST,
  payload,
});
export const updateRelationshipLendersSuccess = (payload = {}) => ({
  type: types.UPDATE_RELATIONSHIP_LENDERS_SUCCESS,
  payload,
});
export const updateRelationshipLendersFailure = (payload = {}) => ({
  type: types.UPDATE_RELATIONSHIP_LENDERS_FAILURE,
  payload,
});

export const setSendLoanToLenderSearchString = (string) => ({
  type: types.SET_SEND_LOAN_TO_LENDER_SEARCH_STRING,
  payload: string,
});

export const selectRelationshipLender = (string) => ({
  type: types.SELECT_RELATIONSHIP_LENDER,
  payload: string,
});

export const changeRelationshipLenderSort = (payload = {}) => ({
  type: types.CHANGE_RELATIONSHIP_LENDER_SORT,
  payload,
});

export const selectPageRelationshipLenders = (payload = {}) => ({
  type: types.SELECT_PAGE_RELATIONSHIP_LENDERS,
  payload,
});

export const selectAllRelationshipLenders = (payload = {}) => ({
  type: types.SELECT_ALL_RELATIONSHIP_LENDERS,
  payload,
});

export const selectNoneRelationshipLenders = (payload = {}) => ({
  type: types.SELECT_NONE_RELATIONSHIP_LENDERS,
  payload,
});

export const selectRealAtomLender = (payload = {}) => ({
  type: types.SELECT_REAL_ATOM_LENDER,
  payload,
});

export const excludeCompanyAdd = (payload = {}) => ({
  type: types.EXCLUDE_COMPANY_ADD,
  payload,
});

export const excludeCompanyRemove = (payload = {}) => ({
  type: types.EXCLUDE_COMPANY_REMOVE,
  payload,
});

export const excludeCompanyUpdate = (payload = {}) => ({
  type: types.EXCLUDE_COMPANY_UPDATE,
  payload,
});

export const excludeAllLenders = (payload = {}) => ({
  type: types.EXCLUDE_ALL_LENDERS,
  payload,
});

export const sendToLendersReset = (payload = {}) => ({
  type: types.SEND_TO_LENDERS_RESET,
  payload,
});
