import types from 'constants/actionTypes';

const initialState = {
  roomType: null,
  roomId: null,
  item: null,
  author: null,
  id: null,
  accountType: null,
};

export function quotesWidget(state = initialState, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case types.SHOW_QUOTES_WIDGET: 
      return {
        ...state,
        roomType: payload.roomType,
        roomId: payload.roomId,
        item: payload.item,
        author: payload.author,
        id: payload.id,
        accountType: payload.accountType,

      };

    case types.HIDE_QUOTES_WIDGET:
      return initialState;

    default:
      return state;
  }
}
