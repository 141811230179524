import { put, takeLatest, call, select } from 'redux-saga/effects';
import types from 'constants/actionTypes';
import * as API from 'api';
import { getError } from 'sagas/utils';
import { createNormalizeSchema } from 'lib/utils';
import { normalize } from 'normalizr';
import { TERMSHEET_EVENTS, TRACK } from 'constants/appRelated';
import { analytics } from 'lib/analytics';

import {
  submitManualTermsheetSuccess,
  submitManualTermsheetFailure,
  getLoanTermsheetsSuccess,
  getLoanTermsheetsFailure,
  editManualTermsheetSuccess,
  editManualTermsheetFailure,
  deleteManualTermsheetSuccess,
  deleteManualTermsheetFailure,
  restoreManualTermsheetSuccess,
  restoreManualTermsheetFailure,
  getLenderLoanTermsheetsSuccess,
  getLenderLoanTermsheetsFailure,
  uploadLenderTermsheetSuccess,
  uploadLenderTermsheetFailure,
  getLenderLoanTermsheetsRequest,
} from './actions';

function* submitManualTermsheet(action) {
  try {
    const { request, lender } = action.payload;
    const { loanNew } = yield select();
    const {
      data: { response },
    } = yield call(
      API.axiosApi.post,
      `/loans/${loanNew.data.id}/offline-termsheets`,
      request
    );
    analytics(TRACK, TERMSHEET_EVENTS.LOAN_TERM_SHEET_ADD_MANUAL_CREATE, {
      lender,
    });
    yield put(submitManualTermsheetSuccess({ termsheet: response }));
  } catch (e) {
    yield put(submitManualTermsheetFailure({ message: getError(e) }));
  }
}

function* getLoanTermsheets(action) {
  try {
    const { loanId } = action.payload;
    const {
      data: { response },
    } = yield call(API.axiosApi.get, `/loans/${loanId}/loan-termsheets`);
    const {
      entities: { termsheets },
      result,
    } = normalize(
      response,
      createNormalizeSchema({ name: 'termsheets', idAttribute: 'id' })
    );
    yield put(
      getLoanTermsheetsSuccess({ termsheetsMap: termsheets, termsheetsArray: result })
    );
  } catch (e) {
    yield put(getLoanTermsheetsFailure({ message: getError(e) }));
  }
}

function* getLenderLoanTermsheets(action) {
  try {
    const { negotiationPk, loanId } = action.payload;
    const {
      data: { response },
    } = yield call(
      API.axiosApi.get,
      `/loans/${loanId}/negotiations/${negotiationPk}/termsheets`
    );
    const {
      entities: { termsheets },
      result,
    } = normalize(
      response.results,
      createNormalizeSchema({ name: 'termsheets', idAttribute: 'id' })
    );
    yield put(
      getLenderLoanTermsheetsSuccess({
        termsheetsMap: termsheets,
        termsheetsArray: result,
      })
    );
  } catch (e) {
    yield put(getLenderLoanTermsheetsFailure({ message: getError(e) }));
  }
}

function* editManualTermsheet(action) {
  try {
    const { request, termsheetId } = action.payload;
    const { loanNew } = yield select();
    const {
      data: { response },
    } = yield call(
      API.axiosApi.patch,
      `/loans/${loanNew.data.id}/offline-termsheets/${termsheetId}`,
      request
    );
    analytics(TRACK, TERMSHEET_EVENTS.LOAN_TERM_SHEET_EDIT_SAVE);
    yield put(editManualTermsheetSuccess({ termsheet: response }));
  } catch (e) {
    yield put(editManualTermsheetFailure({ message: getError(e) }));
  }
}

function* deleteManualTermsheet(action) {
  try {
    const { termsheetId } = action.payload;
    const { loanNew } = yield select();
    yield call(
      API.axiosApi.delete,
      `/loans/${loanNew.data.id}/offline-termsheets/${termsheetId}`
    );
    yield put(deleteManualTermsheetSuccess({ termsheetId }));
  } catch (e) {
    yield put(deleteManualTermsheetFailure({ message: getError(e) }));
  }
}

function* restoreManualTermsheet(action) {
  try {
    const { termsheetId } = action.payload;
    const { loanNew } = yield select();
    yield call(
      API.axiosApi.patch,
      `/loans/${loanNew.data.id}/offline-termsheets/${termsheetId}/restore`
    );
    yield put(restoreManualTermsheetSuccess({ termsheetId }));
  } catch (e) {
    yield put(restoreManualTermsheetFailure({ message: getError(e) }));
  }
}

function* uploadLenderTermsheet(action) {
  try {
    const { loanId, negotiationPk, form } = action.payload;
    yield call(
      API.axiosApi.post,
      `loans/${loanId}/negotiations/${negotiationPk}/termsheets`,
      form
    );
    yield put(uploadLenderTermsheetSuccess());
    yield put(getLenderLoanTermsheetsRequest({ loanId, negotiationPk }));
  } catch (e) {
    yield put(uploadLenderTermsheetFailure({ message: getError(e) }));
  }
}

export default [
  takeLatest(types.SUBMIT_MANUAL_TERMSHEET_REQUEST, submitManualTermsheet),
  takeLatest(types.GET_LOAN_TERMSHEETS_REQUEST, getLoanTermsheets),
  takeLatest(types.EDIT_MANUAL_TERMSHEET_REQUEST, editManualTermsheet),
  takeLatest(types.DELETE_MANUAL_TERMSHEET_REQUEST, deleteManualTermsheet),
  takeLatest(types.RESTORE_MANUAL_TERMSHEET_REQUEST, restoreManualTermsheet),
  takeLatest(types.GET_LENDER_LOAN_TERMSHEETS_REQUEST, getLenderLoanTermsheets),
  takeLatest(types.UPLOAD_LENDER_TERMSHEET_REQUEST, uploadLenderTermsheet),
];
