import React, { PureComponent } from 'react';
import styled from 'styled-components/macro';

import { DisplayTitle } from './DisplayTitle';
import { DisplayValue } from './DisplayValue';
import { EditableValue } from './EditableValue';

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
  align-items: center;
  padding: 5px 0;
`;

const Item = styled.div`
  width: 25%;
  max-width: 25%;

  &:nth-child(2){
    width: 32%;
    max-width: 32%;
  }

  &:last-child{
    width: 41%;
    max-width: 41%;
  }
`;

export class OfferRowSingle extends PureComponent {
  render() {
    const { row, displayValues, editableValues, edit, isLender } = this.props;
    return (
      <Wrapper>
        <Item>
          <DisplayTitle title={row.title} required={row.required} />
        </Item>
        <Item>
          {edit ? (
            <EditableValue
              row={row}
              values={editableValues}
              edit={edit}
              isLender={isLender}
            />
          ) : (
            <DisplayValue
              row={row}
              values={editableValues}
              displayValues={displayValues}
            />
          )}
        </Item>
      </Wrapper>
    );
  }
}
