import React from 'react';
import styled from 'styled-components/macro';
import { Text } from 'ui/Text';

export const DocumentsSection = ({ documentsSection, children }) => {
  const hasDocuments =
    documentsSection.folders.length &&
    documentsSection.folders.reduce(
      (acc, { documents }) => acc || !!documents.length,
      false
    );

  if (!hasDocuments) return null;

  return (
    <Wrapper>
      <DocumentHeading>{documentsSection.sectionName}</DocumentHeading>
      {children}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin-bottom: 24px;
`;

const DocumentHeading = styled(Text)`
  margin-bottom: 16px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
`;
