import types from 'constants/actionTypes';

export const setRedirectionPathOnLoanUpdate = (payload = {}) => ({
  type: types.SET_REDIRECTION_PATH_ON_LOAN_UPDATE,
  payload,
});

export const uploadRequestedDocumentRequest = (payload = {}) => ({
  type: types.UPLOAD_REQUESTED_DOCUMENT_REQUEST,
  payload,
});

export const uploadRequestedDocumentSuccess = (payload = {}) => ({
  type: types.UPLOAD_REQUESTED_DOCUMENT_SUCCESS,
  payload,
});

export const uploadRequestedDocumentFailure = (payload = {}) => ({
  type: types.UPLOAD_REQUESTED_DOCUMENT_FAILURE,
  payload,
});

export const loanDataRequest = (payload = {}) => ({
  type: types.LOAN_DATA_REQUEST,
  payload,
});
export const loanDataSuccess = (payload = {}) => ({
  type: types.LOAN_DATA_SUCCESS,
  payload,
});
export const loanDataFailure = (payload = {}) => ({
  type: types.LOAN_DATA_FAILURE,
  payload,
});

export const getMoodyAccessTokenRequest = (payload) => ({
  type: types.GET_MOODY_ACCESS_TOKEN_REQUEST,
  payload,
});

export const getMoodyAccessTokenSuccess = (payload) => ({
  type: types.GET_MOODY_ACCESS_TOKEN_SUCCESS,
  payload,
});

export const getMoodyAccessTokenFailure = (payload) => ({
  type: types.GET_MOODY_ACCESS_TOKEN_FAILURE,
  payload,
});

export const resetLoanInfo = () => ({ type: types.RESET_LOAN_INFO });

export const submitForVerificationRequest = (payload = {}) => ({
  type: types.SUBMIT_FOR_VERIFICATION_REQUEST,
  payload,
});
export const submitForVerificationSuccess = (payload = {}) => ({
  type: types.SUBMIT_FOR_VERIFICATION_SUCCESS,
  payload,
});
export const submitForVerificationFailure = (payload = {}) => ({
  type: types.SUBMIT_FOR_VERIFICATION_FAILURE,
  payload,
});

export const submitOfferRequest = (payload = {}) => ({
  type: types.SUBMIT_OFFER_REQUEST,
  payload,
});
export const submitOfferSuccess = (payload = {}) => ({
  type: types.SUBMIT_OFFER_SUCCESS,
  payload,
});
export const submitOfferFailure = (payload = {}) => ({
  type: types.SUBMIT_OFFER_FAILURE,
  payload,
});

export const negotiateOfferRequest = (payload = {}) => ({
  type: types.NEGOTIATE_OFFER_REQUEST,
  payload,
});
export const negotiateOfferSuccess = (payload = {}) => ({
  type: types.NEGOTIATE_OFFER_SUCCESS,
  payload,
});
export const negotiateOfferFailure = (payload = {}) => ({
  type: types.NEGOTIATE_OFFER_FAILURE,
  payload,
});

export const editOfferRequest = (payload = {}) => ({
  type: types.EDIT_OFFER_REQUEST,
  payload,
});
export const editOfferSuccess = (payload = {}) => ({
  type: types.EDIT_OFFER_SUCCESS,
  payload,
});
export const editOfferFailure = (payload = {}) => ({
  type: types.EDIT_OFFER_FAILURE,
  payload,
});

export const acceptOfferRequest = (payload = {}) => ({
  type: types.ACCEPT_OFFER_REQUEST,
  payload,
});
export const acceptOfferSuccess = (payload = {}) => ({
  type: types.ACCEPT_OFFER_SUCCESS,
  payload,
});
export const acceptOfferFailure = (payload = {}) => ({
  type: types.ACCEPT_OFFER_FAILURE,
  payload,
});

export const rejectOfferRequest = (payload = {}) => ({
  type: types.REJECT_OFFER_REQUEST,
  payload,
});
export const rejectOfferSuccess = (payload = {}) => ({
  type: types.REJECT_OFFER_SUCCESS,
  payload,
});
export const rejectOfferFailure = (payload = {}) => ({
  type: types.REJECT_OFFER_FAILURE,
  payload,
});

export const postAnswerRequest = (payload = {}) => ({
  type: types.POST_ANSWER_REQUEST,
  payload,
});
export const postAnswerSuccess = (payload = {}) => ({
  type: types.POST_ANSWER_SUCCESS,
  payload,
});
export const postAnswerFailure = (payload = {}) => ({
  type: types.POST_ANSWER_FAILURE,
  payload,
});

export const submitTermsheetRequest = (payload = {}) => ({
  type: types.SUBMIT_TERMSHEET_REQUEST,
  payload,
});
export const submitTermsheetSuccess = (payload = {}) => ({
  type: types.SUBMIT_TERMSHEET_SUCCESS,
  payload,
});
export const submitTermsheetFailure = (payload = {}) => ({
  type: types.SUBMIT_TERMSHEET_FAILURE,
  payload,
});

export const downloadAllDocumentsRequest = (payload = {}) => ({
  type: types.DOWNLOAD_ALL_DOCUMENTS_REQUEST,
  payload,
});
export const downloadAllDocumentsSuccess = (payload = {}) => ({
  type: types.DOWNLOAD_ALL_DOCUMENTS_SUCCESS,
  payload,
});
export const downloadAllDocumentsFailure = (payload = {}) => ({
  type: types.DOWNLOAD_ALL_DOCUMENTS_FAILURE,
  payload,
});

export const requestDocumentsRequest = (payload = {}) => ({
  type: types.REQUEST_DOCUMENTS_REQUEST,
  payload,
});
export const requestDocumentsSuccess = (payload = {}) => ({
  type: types.REQUEST_DOCUMENTS_SUCCESS,
  payload,
});
export const requestDocumentsFailure = (payload = {}) => ({
  type: types.REQUEST_DOCUMENTS_FAILURE,
  payload,
});

export const cancelOfferRequest = (payload = {}) => ({
  type: types.CANCEL_OFFER_REQUEST,
  payload,
});
export const cancelOfferSuccess = (payload = {}) => ({
  type: types.CANCEL_OFFER_SUCCESS,
  payload,
});
export const cancelOfferFailure = (payload = {}) => ({
  type: types.CANCEL_OFFER_FAILURE,
  payload,
});

export const rejectTermsheetRequest = (payload = {}) => ({
  type: types.REJECT_TERMSHEET_REQUEST,
  payload,
});
export const rejectTermsheetSuccess = (payload = {}) => ({
  type: types.REJECT_TERMSHEET_SUCCESS,
  payload,
});
export const rejectTermsheetFailure = (payload = {}) => ({
  type: types.REJECT_TERMSHEET_FAILURE,
  payload,
});

export const exportOffersRequest = (payload = {}) => ({
  type: types.EXPORT_OFFERS_REQUEST,
  payload,
});
export const exportOffersSuccess = (payload = {}) => ({
  type: types.EXPORT_OFFERS_SUCCESS,
  payload,
});
export const exportOffersFailure = (payload = {}) => ({
  type: types.EXPORT_OFFERS_FAILURE,
  payload,
});

export const inviteLenderRequest = (payload = {}) => ({
  type: types.INVITE_LENDER_REQUEST,
  payload,
});
export const inviteLenderSuccess = (payload = {}) => ({
  type: types.INVITE_LENDER_SUCCESS,
  payload,
});
export const inviteLenderFailure = (payload = {}) => ({
  type: types.INVITE_LENDER_FAILURE,
  payload,
});

export const postLoanNoteRequest = ({ text, loanId }) => ({
  type: types.POST_LOAN_NOTE_REQUEST,
  payload: { text, loanId },
});
export const postLoanNoteSuccess = ({
  createdTimestamp,
  id,
  loan,
  owner,
  team,
  text,
  author,
}) => ({
  type: types.POST_LOAN_NOTE_SUCCESS,
  payload: { createdTimestamp, id, loan, owner, team, text, author },
});
export const postLoanNoteFailure = (payload = {}) => ({
  type: types.POST_LOAN_NOTE_FAILURE,
  payload,
});

export const getBrokerLoanRoomRequest = ({ loanId }) => ({
  type: types.GET_BROKER_LOAN_ROOM_REQUEST,
  payload: { loanId },
});
export const getBrokerLoanRoomSuccess = (payload = {}) => ({
  type: types.GET_BROKER_LOAN_ROOM_SUCCESS,
  payload,
});
export const getBrokerLoanRoomFailure = (payload = {}) => ({
  type: types.GET_BROKER_LOAN_ROOM_FAILURE,
  payload,
});

export const acceptConfidentialityAgreementRequest = ({
  loanId,
  documentId,
  folderId,
  sectionId,
  loan,
  allDocuments,
  loanUid,
}) => ({
  type: types.ACCEPT_CONFIDENTIALITY_AGREEMENT_REQUEST,
  payload: { loanId, documentId, folderId, sectionId, loan, loanUid, allDocuments },
});
export const acceptConfidentialityAgreementSuccess = (payload = {}) => ({
  type: types.ACCEPT_CONFIDENTIALITY_AGREEMENT_SUCCESS,
  payload,
});
export const acceptConfidentialityAgreementFailure = (payload = {}) => ({
  type: types.ACCEPT_CONFIDENTIALITY_AGREEMENT_FAILURE,
  payload,
});

export const submitManualQuoteRequest = (payload = {}) => ({
  type: types.SUBMIT_MANUAL_QUOTE_REQUEST,
  payload,
});
export const submitManualQuoteSuccess = (payload = {}) => ({
  type: types.SUBMIT_MANUAL_QUOTE_SUCCESS,
  payload,
});
export const submitManualQuoteFailure = (payload = {}) => ({
  type: types.SUBMIT_MANUAL_QUOTE_FAILURE,
  payload,
});

export const deleteNegotiationRequest = (payload = {}) => ({
  type: types.DELETE_NEGOTIATION_REQUEST,
  payload,
});
export const deleteNegotiationSuccess = (payload = {}) => ({
  type: types.DELETE_NEGOTIATION_SUCCESS,
  payload,
});
export const deleteNegotiationFailure = (payload = {}) => ({
  type: types.DELETE_NEGOTIATION_FAILURE,
  payload,
});

export const exportStatusReportRequest = (payload = {}) => ({
  type: types.EXPORT_STATUS_REPORT_REQUEST,
  payload,
});
export const exportStatusReportSuccess = (payload = {}) => ({
  type: types.EXPORT_STATUS_REPORT_SUCCESS,
  payload,
});
export const exportStatusReportFailure = (payload = {}) => ({
  type: types.EXPORT_STATUS_REPORT_FAILURE,
  payload,
});

export const changePropertyNameRequest = (payload = {}) => ({
  type: types.CHANGE_PROPERTY_NAME_REQUEST,
  payload,
});
export const changePropertyNameSuccess = (payload = {}) => ({
  type: types.CHANGE_PROPERTY_NAME_SUCCESS,
  payload,
});
export const changePropertyNameFailure = (payload = {}) => ({
  type: types.CHANGE_PROPERTY_NAME_FAILURE,
  payload,
});

export const updateRequestedDocumentProtectionRequest = (payload = {}) => ({
  type: types.UPDATE_REQUESTED_DOCUMENT_PROTECTION_REQUEST,
  payload,
});
export const updateRequestedDocumentProtectionSuccess = (payload = {}) => ({
  type: types.UPDATE_REQUESTED_DOCUMENT_PROTECTION_SUCCESS,
  payload,
});
export const updateRequestedDocumentProtectionFailure = (payload = {}) => ({
  type: types.UPDATE_REQUESTED_DOCUMENT_PROTECTION_FAILURE,
  payload,
});

export const setLoanReminderRequest = (payload = {}) => ({
  type: types.SET_LOAN_REMINDER_REQUEST,
  payload,
});
export const setLoanReminderSuccess = (payload = {}) => ({
  type: types.SET_LOAN_REMINDER_SUCCESS,
  payload,
});
export const setLoanReminderFailure = (payload = {}) => ({
  type: types.SET_LOAN_REMINDER_FAILURE,
  payload,
});

export const createNewDealRoomRequest = (payload= {}) => ({
  type: types.CREATE_DEAL_ROOM_REQUEST,
  payload,
});

export const createNewDealRoomSuccess = (payload= {}) => ({
  type: types.CREATE_DEAL_ROOM_SUCCESS,
  payload,
});

export const createNewDealRoomFailure = (payload = {}) => ({
  type: types.CREATE_DEAL_ROOM_FAILURE,
  payload
});