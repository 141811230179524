import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Section, SectionTitle } from 'styles/loan';
import styled from 'styled-components/macro';
import { Text } from 'ui/Text';
import { User } from 'features/DashboardActionItems/components/ActionItem';
import { LENDER } from 'constants/appRelated';
import { showModal } from 'actions/modalActions';
import { modals } from 'constants/modalTypes';
import { createDealRoomWithDocumentsRequest } from 'features/DealRoom/actions';
import { getLenderRequestedDocumentsState } from 'features/LoanNew/selectors';

import { RequestedDocument } from './RequestedDocument';

export const RequestedDocuments = ({
  requestedDocuments,
  uploadRequestedDocument,
  updateRequestedDocumentProtection,
}) => {
  const dispatch = useDispatch();
  const abilityToUploadDocumentsState = useSelector(getLenderRequestedDocumentsState);

  const openCreatDealRoomModal = ({ lender, documents }) => {
    dispatch(
      showModal(modals.INFO_MODAL, {
        size: 'sm',
        title: `Do you want to create a deal room for ${lender.firstName} ${lender.lastName} from ${lender.company}`,
        text:
          'When you create a deal room with a lender you can send some documents to them privately',
        buttonRejectText: 'Cancel',
        buttonText: 'Create deal room',
        onButtonClick: () => {
          dispatch(
            createDealRoomWithDocumentsRequest({
              documents,
              withLoanUpdate: true,
            })
          );
        },
      })
    );
  };

  return (
    <div>
      {requestedDocuments.map(({ recommended, sections, lender, requestsId }) => (
        <Section data-testid="requestedDocumentsContainer">
          <SectionTitle>New Documents Request</SectionTitle>
          <User key={lender} user={{ ...lender, accountType: LENDER }} />
          <HelperText>
            Please upload documents in the following formats: doc, docx, html, htm, odt,
            pdf, xls, xlsx, ods, ppt, pptx, txt, jpg, zip
          </HelperText>
          <Header>
            <Text>DOCUMENT NAME</Text>
            <Text>PRIVACY</Text>
          </Header>
          {!!recommended?.length && (
            <DocumentsSection>
              {recommended.map(
                ({ name, id, uploaded, protected: isDocumentProtected }) => (
                  <RequestedDocument
                    key={id}
                    label={name}
                    uploaded={uploaded}
                    isDocumentProtected={isDocumentProtected}
                    updateRequestedDocumentProtection={(protectedDocument) => {
                      updateRequestedDocumentProtection({
                        protectedDocument,
                        folderId: id,
                        lenderPk: lender.pk,
                      });
                    }}
                    onDrop={({ files, protectedDocument }) => {
                      uploadRequestedDocument({
                        files,
                        folderId: id,
                        protectedDocument,
                        lenderPk: lender.pk,
                      });
                    }}
                  />
                )
              )}
            </DocumentsSection>
          )}
          {!!sections?.length &&
            sections.map(
              ({ sectionName, sectionId, folders }) =>
                !!folders.length && (
                  <DocumentsSection key={sectionName}>
                    <SubTitle>{sectionName}</SubTitle>
                    {folders.map(
                      ({ name, id, uploaded, protected: isDocumentProtected }) => (
                        <RequestedDocument
                          key={id}
                          label={name}
                          uploaded={uploaded}
                          isDocumentProtected={isDocumentProtected}
                          updateRequestedDocumentProtection={(protectedDocument) => {
                            updateRequestedDocumentProtection({
                              protectedDocument,
                              sectionId,
                              folderId: id,
                              lenderPk: lender.pk,
                            });
                          }}
                          onDrop={({ files, protectedDocument }) => {
                            uploadRequestedDocument({
                              files,
                              folderId: id,
                              sectionId,
                              protectedDocument,
                              lenderPk: lender.pk,
                            });
                          }}
                        />
                      )
                    )}
                  </DocumentsSection>
                )
            )}
          {abilityToUploadDocumentsState[lender.pk] && (
            <ShareDocumentsBtn
              onClick={() => {
                openCreatDealRoomModal({ lender, documents: requestsId });
              }}
            >
              SHARE DOCUMENTS PRIVATELY
            </ShareDocumentsBtn>
          )}
        </Section>
      ))}
    </div>
  );
};

const SubTitle = styled.p`
  margin: 0 0 16px 0;
  padding: 0;
  font-size: 18px;
  line-height: 20px;
`;

const DocumentsSection = styled.div`
  margin-bottom: 24px;
`;

// TODO: Need to implement common helper text wrapper

export const HelperText = styled.p`
  margin: 0 0 24px;
  padding: 0;
  color: #9ba4b2;
  font-size: 14px;
  line-height: 16px;
`;

const Header = styled.div`
  display: flex;
  padding-bottom: 8px;
  margin-bottom: 16px;
  border-bottom: 1px solid #e8e9ed;

  ${Text} {
    color: #778598;
    font-size: 10px;

    &:first-child {
      width: 50%;
    }
  }

  @media (max-width: ${(p) => p.theme.breakpoints[1]}px) {
    ${Text} {
      &:first-child {
        flex: 1 auto;
      }
      &:last-child {
        width: 110px;
      }
    }
  }
`;

const ShareDocumentsBtn = styled.button`
  display: flex;
  align-items: center;
  font-size: 10px;
  color: #4394ea;
  cursor: pointer;
  background-color: #fff;
  border: none;
  outline: none;

  &::before {
    content: url('/images/icons/ico_document_protected.svg');
    margin-right: 5px;
  }
`;
