import React from 'react';
import styled from 'styled-components/macro';
import { Section, SectionTitle } from 'styles/loan';
import { Button } from 'ui/Button';
import { bluredContent } from 'features/LoanNew/constants';
import PropTypes from 'prop-types';
import { BlurContent } from 'components/BlurContent';

import { QuestionItem } from './QuestionItem';

export const Questions = ({
  questions,
  openQuestionForm,
  isOwner,
  access,
  showLenderDetails,
  redirectToLoanBrokerChat,
}) => (
  <QuestionsSection>
    <SectionTitle>Questions</SectionTitle>
    <BlurContent blured={!access.full} height={347}>
      <div>
        {!!questions.new.length && (
          <React.Fragment>
            <Title>NEW QUESTIONS</Title>
            {questions.new.map((question) => (
              <QuestionItem
                key={question.id}
                openQuestionForm={openQuestionForm}
                isOwner={isOwner}
                showLenderDetails={showLenderDetails}
                {...question}
              />
            ))}
          </React.Fragment>
        )}

        {!!questions.answered.length && (
          <React.Fragment>
            <Title>ANSWERED QUESTIONS</Title>
            {questions.answered.map((question) => (
              <QuestionItem
                key={question.id}
                {...question}
                showLenderDetails={showLenderDetails}
                isOwner={isOwner}
              />
            ))}
          </React.Fragment>
        )}

        {!access.full && (
          <React.Fragment>
            <Title>SECRET QUESTIONS</Title>
            {bluredContent.questions.map((question) => (
              <QuestionItem key={question.id} {...question} />
            ))}
          </React.Fragment>
        )}

        {!isOwner && (
          <ButtonWrapper>
            <Button secondary onClick={access.full ? redirectToLoanBrokerChat : undefined}>
              Ask a question
            </Button>
          </ButtonWrapper>
        )}
      </div>
    </BlurContent>
  </QuestionsSection>
);

Questions.propTypes = {
  questions: PropTypes.shape({
    answered: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
    new: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  }).isRequired,
  hasQuestions: PropTypes.bool.isRequired,
  isOwner: PropTypes.bool.isRequired,
  access: PropTypes.objectOf(PropTypes.bool),
  openQuestionForm: PropTypes.func,
  openAskQuestionForm: PropTypes.func,
  showLenderDetails: PropTypes.func,
};

const Title = styled.p`
  font-size: 10px;
  line-height: 21px;
  color: #778598;
  margin: 0 0 16px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 24px;
`;

const QuestionsSection = styled(Section)`
  margin-bottom: 0;

  @media (max-width: ${(p) => p.theme.breakpoints[2]}px) {
    margin-bottom: 24px;
  }
`;
