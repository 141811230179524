import { put, takeLatest, takeEvery, call, select } from 'redux-saga/effects';
import types from 'constants/actionTypes';
import { CHAT_ROOM, ROOMS_TYPES } from 'constants/appRelated';
import * as API from 'api';
import { normalize } from 'normalizr';
import { push } from 'connected-react-router';
import { messageListSchema, roomListSchema, negotiationsListSchema } from 'utils/schemas';
import { makeOfferFormData, doesUserCanNegotiate } from 'lib/loan';
import { decamelizeKeys } from 'humps';
import { callPermissionModal, getError } from 'sagas/utils';
import { modals } from 'constants/modalTypes';
import { showModal } from 'actions/modalActions';
import { userTypeSelector } from 'selectors/userType';
import { uniq } from 'ramda';
import {
  getChatRoomsSuccess,
  getChatRoomsFailure,
  getAdditionalChatRoomsSuccess,
  getAdditionalChatRoomsFailure,
  getChatRoomSuccess,
  getChatRoomFailure,
  uploadChatDocumentSuccess,
  uploadChatDocumentFailure,
  getAdditionalChatRoomSuccess,
  getAdditionalChatRoomFailure,
  chatOfferEditSuccess,
  chatOfferEditFailure,
  chatOfferNegotiateSuccess,
  chatOfferNegotiateFailure,
  chatOfferSubmitSuccess,
  chatOfferSubmitFailure,
  chatOfferRejectSuccess,
  chatOfferRejectFailure,
  chatOfferAcceptSuccess,
  chatOfferAcceptFailure,
  chatFileToLoanSuccess,
  chatFileToLoanFailure,
  createChatTeamGroupSuccess,
  createChatTeamGroupFailure,
  sendOfferToChatSuccess,
  sendOfferToChatFailure,
  searchInChatSuccess,
  searchInChatFailure,
  searchMessageInChatSuccess,
  searchMessageInChatFailure,
  getChatRoomRequest,
} from './actions';

const roomsType = {
  [CHAT_ROOM.LOAN]: 'chat/rooms?initiated=1',
  [CHAT_ROOM.TEAM]: 'chat/rooms?team',
  [CHAT_ROOM.PERSONAL]: 'chat/rooms?personal=1&initiated=1',
};

function* getRooms(action) {
  const { loan, lender, type, roomId, bb } = action.payload;
  const userType = userTypeSelector(yield select());

  try {
    const {
      data: { response },
    } = yield call(API.axiosApi.get, roomsType[type]);
    let results = {};

    results.roomsType = type;

    const { entities, result: entitiesIds } = normalize(response.results, roomListSchema);

    // @TODO:
    // Team documents and offers should not be accessible by Leders
    // yield call(API.axiosApi.get, 'chat/rooms/documents') throws an Error 403 (Permissions)
    if (type === CHAT_ROOM.TEAM) {
      if (userType.lender) {
        results.teamDocuments = [];
        results.teamOffers = [];
      } else {
        const teamDocuments = yield call(API.axiosApi.get, 'chat/rooms/documents');
        const teamOffers = yield call(API.axiosApi.get, 'chat/rooms/negotiations');
        results.teamDocuments = teamDocuments.data.response.results;
        results.teamOffers = teamOffers.data.response.results;
      }
    }
    // case when lender opens chat from loan or other places
    let normalizedRoom;

    if (loan || lender || bb) {
      const loansCustomQuery = `chat/rooms?${!!loan ? `loan=${loan}` : ''}${
        !!lender ? `&lender=${lender}` : ''
      }`;

      const directCustomQuery = `chat/rooms?personal=1&${
        !!lender ? `lender=${lender}` : ''
      }${!!bb ? `bb=${bb}` : ''}`;

      const customRoom = yield call(
        API.axiosApi.get,
        type === CHAT_ROOM.LOAN ? loansCustomQuery : directCustomQuery
      );

      const data = customRoom?.data?.response?.results;

      normalizedRoom = normalize(data, roomListSchema);
      if (data.length) {
        yield put(
          push(
            `/dashboard/chat/${type}/${
              type === CHAT_ROOM.LOAN ? data[0].rooms[0].id : data[0].id
            }`
          )
        );
      }
    }

    results.rooms = normalizedRoom
      ? { ...normalizedRoom.entities.rooms, ...entities.rooms }
      : { ...entities.rooms };

    results.roomsArray = normalizedRoom
      ? uniq([...normalizedRoom.result, ...entitiesIds]) // Get uniq rooms ids
      : entitiesIds;

    results.next = response.next;

    yield put(getChatRoomsSuccess(results));
    if (roomId) {
      yield put(
        getChatRoomRequest({
          roomId,
          tabType: type,
        })
      );
    }
  } catch (e) {
    console.error(e);
    yield put(getChatRoomsFailure({ message: getError(e) }));
  }
}

function* getAdditionalRooms(action) {
  const { cursor, tabType } = action.payload;

  try {
    const {
      data: { response },
    } = yield call(API.axiosApi.get, `${roomsType[tabType]}&cursor=${cursor}`);

    const { entities, result: roomsArray } = normalize(response.results, roomListSchema);

    yield put(
      getAdditionalChatRoomsSuccess({
        roomsType: tabType,
        ...entities,
        roomsArray,
        next: response.next,
      })
    );
  } catch (e) {
    console.error(e);
    yield put(getAdditionalChatRoomsFailure({ message: getError(e) }));
  }
}

function* getRoom(action) {
  const { id, tabType } = action.payload;
  try {
    const {
      data: { response },
    } = yield call(API.axiosApi.get, `chat/rooms/${id}/history`);
    const { entities } = normalize(response.results, messageListSchema);

    const documents = yield call(
      API.axiosApi.get,
      `chat/rooms/${id}/history?attachments_only`
    );

    const documentsEntities = normalize(
      documents.data.response.results,
      messageListSchema
    );

    yield put(
      getChatRoomSuccess({
        roomsType: tabType,
        messages: { ...entities },
        roomId: id,
        next: response.next,
        stage: response.stage,
        lender: response.lender,
        borrower: response.borrower,
        loan: response.loan,
        participants: response.participants,
        negotiations:
          normalize(response.negotiations || [], negotiationsListSchema).entities
            .negotiations || {},
        documents: documentsEntities.entities.messages,
      })
    );
  } catch (e) {
    console.error(e);
    yield put(getChatRoomFailure({ message: getError(e) }));
  }
}

function* getAdditionalRoom(action) {
  const { roomId, cursor, tabType, direction } = action.payload;

  try {
    const {
      data: { response },
    } = yield call(API.axiosApi.get, `chat/rooms/${roomId}/history?cursor=${cursor}`);
    const { entities } = normalize(response.results, messageListSchema);
    yield put(
      getAdditionalChatRoomSuccess({
        messages: { ...entities },
        roomId,
        roomsType: tabType,
        next: direction === 'next' ? response.next : null,
        previous: direction === 'previous' ? response.previous : null,
        direction,
      })
    );
  } catch (e) {
    console.error(e);
    yield put(getAdditionalChatRoomFailure({ message: getError(e) }));
  }
}

function* uploadDocument(action) {
  const { room, file, tabType } = action.payload;

  const userType = userTypeSelector(yield select());

  try {
    const {
      data: { response },
    } = yield call(API.axiosApi.post, `chat/rooms/${room}/upload`, file);
    yield put(uploadChatDocumentSuccess());

    if (!userType.lender && tabType === 'loans') {
      yield put(
        showModal(modals.CHAT_DOCUMENT_MODAL, {
          size: 'sm',
          id: response.id,
          fileName: response.name,
        })
      );
    }
  } catch (e) {
    console.error(e);
    yield put(uploadChatDocumentFailure({ message: getError(e) }));
  }
}

function* editOffer(action) {
  try {
    const { loanId, offer, negotiationPk, offerId } = action.payload;
    const { currentUser } = yield select();

    if (!doesUserCanNegotiate(currentUser)) {
      yield* callPermissionModal(currentUser.permissionAsked);
    } else {
      yield call(
        API.axiosApi.patch,
        `loans/${loanId}/negotiations/${negotiationPk}/offers/${offerId}`,
        decamelizeKeys(offer)
      );

      yield put(chatOfferEditSuccess());
    }
  } catch (e) {
    console.error(e);
    yield put(chatOfferEditFailure({ message: getError(e) }));
  }
}

function* negotiateOffer(action) {
  try {
    const { loanId, offer, negotiationPk } = action.payload;
    const { currentUser } = yield select();

    if (!doesUserCanNegotiate(currentUser)) {
      yield* callPermissionModal(currentUser.permissionAsked);
    } else {
      yield call(
        API.axiosApi.post,
        `loans/${loanId}/negotiations/${negotiationPk}/offers`,
        makeOfferFormData(offer)
      );

      yield put(chatOfferNegotiateSuccess());
    }
  } catch (e) {
    console.error(e);
    yield put(chatOfferNegotiateFailure({ message: getError(e) }));
  }
}

function* submitOffer(action) {
  try {
    const { loanId, offer } = action.payload;
    yield call(
      API.axiosApi.post,
      `loans/${loanId}/negotiations`,
      makeOfferFormData(offer)
    );

    yield put(chatOfferSubmitSuccess());
  } catch (e) {
    console.error(e);
    yield put(chatOfferSubmitFailure({ message: getError(e) }));
  }
}

function* rejectOffer(action) {
  try {
    const { loanId, negotiationPk } = action.payload;
    const { currentUser } = yield select();

    if (!doesUserCanNegotiate(currentUser)) {
      yield* callPermissionModal(currentUser.permissionAsked);
    } else {
      yield call(
        API.axiosApi.post,
        `loans/${loanId}/negotiations/${negotiationPk}/reject`,
        {}
      );

      yield put(chatOfferRejectSuccess());
    }
  } catch (e) {
    console.error(e);
    yield put(chatOfferRejectFailure({ message: getError(e) }));
  }
}

function* acceptOffer(action) {
  try {
    const { loanId, negotiationPk } = action.payload;
    const { currentUser } = yield select();

    if (!doesUserCanNegotiate(currentUser)) {
      yield* callPermissionModal(currentUser.permissionAsked);
    } else {
      yield call(
        API.axiosApi.post,
        `loans/${loanId}/negotiations/${negotiationPk}/accept`,
        {}
      );

      yield put(chatOfferAcceptSuccess());
    }
  } catch (e) {
    console.error(e);
    yield put(chatOfferAcceptFailure({ message: getError(e) }));
  }
}

function* loanFilePopulate(action) {
  try {
    const { id } = action.payload;
    yield call(API.axiosApi.post, `/chat/chat-documents/attach-to-loan/${id}`, {});
    yield put(chatFileToLoanSuccess());
  } catch (e) {
    yield put(chatFileToLoanFailure());
  }
}

function* createChatTeamGroup(action) {
  const { users } = action.payload;
  try {
    const {
      data: { response },
    } = yield call(API.axiosApi.post, '/chat/rooms', { users });
    yield put(push(`/dashboard/chat/team/${response.id}`));
    yield put(createChatTeamGroupSuccess({ room: response }));
  } catch (e) {
    yield put(createChatTeamGroupFailure({ message: getError(e) }));
  }
}

function* sendOfferToChat(action) {
  try {
    const { loanId, negotiationId, offerId, chatRoomId } = action.payload;
    yield call(
      API.axiosApi.post,
      `/loans/${loanId}/negotiations/${negotiationId}/offers/${offerId}/share_in_chat`,
      { chat_room: chatRoomId }
    );
    yield put(sendOfferToChatSuccess());
  } catch (e) {
    yield put(sendOfferToChatFailure());
  }
}

function* searchInChat(action) {
  try {
    const { query } = action.payload;
    const messages = yield call(API.axiosApi.get, `/chat/search-messages?q=${query}`);

    const contacts = yield call(API.axiosApi.get, `/chat/search-contacts?q=${query}`);

    yield put(
      searchInChatSuccess({
        messages: messages?.data?.response,
        contacts: contacts?.data?.response,
      })
    );
  } catch (e) {
    yield put(searchInChatFailure());
  }
}

function* searchMessageInChat(action) {
  const { messageId, messageUuid, roomId, textMessage, isWidget } = action.payload;

  try {
    let room;
    if (messageUuid && roomId) {
      room = yield call(API.axiosApi.get, `/chat/rooms/${roomId}/history`);
    } else {
      room = yield call(API.axiosApi.get, `/chat/messages/${messageId}`);
    }

    const prevMessagesRoom = room.data.response.previous
      ? yield call(API.axiosApi.get, room.data.response.previous)
      : null;
    const nextMessagesRoom = room.data.response.next
      ? yield call(API.axiosApi.get, room.data.response.next)
      : null;

    const messages = [
      ...(nextMessagesRoom?.data?.response?.results || []),
      ...room.data.response.results,
      ...(prevMessagesRoom?.data?.response?.results || []),
    ];
    const { entities } = normalize(messages, messageListSchema);
    const documents = yield call(
      API.axiosApi.get,
      `chat/rooms/${room.data.response.id}/history?attachments_only`
    );

    const documentsEntities = normalize(
      documents.data.response.results,
      messageListSchema
    );

    yield put(
      searchMessageInChatSuccess({
        roomsType: ROOMS_TYPES[room.data.response.type],
        messages: { ...entities },
        roomId: room.data.response.id,
        next: nextMessagesRoom ? nextMessagesRoom.data.response.next : null,
        previous: prevMessagesRoom ? prevMessagesRoom.data.response.previous : null,
        stage: room.data.response.stage,
        lender: room.data.response.lender,
        borrower: room.data.response.borrower,
        loan: room.data.response.loan,
        participants: room.data.response.participants,
        queryMessageUuid: messageUuid || room.data.response.results[0].uuid,
        negotiations:
          normalize(room.data.response.negotiations || [], negotiationsListSchema)
            .entities.negotiations || {},
        documents: documentsEntities.entities.messages,
      })
    );
    if(!isWidget){
      yield put(
        push({
         pathname:  `/dashboard/chat/${ROOMS_TYPES[room.data.response.type]}/${room.data.response.id}`,
         state: { messageUuid, textMessage }
        })
      );
    }
  } catch (e) {
    yield put(searchMessageInChatFailure());
  }
}

export default [
  takeLatest(types.GET_CHAT_ROOMS_REQUEST, getRooms),
  takeLatest(types.GET_CHAT_ROOM_REQUEST, getRoom),
  takeLatest(types.GET_ADDITIONAL_CHAT_ROOMS_REQUEST, getAdditionalRooms),
  takeLatest(types.UPLOAD_CHAT_DOCUMENT_REQUEST, uploadDocument),
  takeLatest(types.GET_ADDITIONAL_CHAT_ROOM_REQUEST, getAdditionalRoom),
  takeLatest(types.CHAT_OFFER_EDIT_REQUEST, editOffer),
  takeLatest(types.CHAT_OFFER_NEGOTIATE_REQUEST, negotiateOffer),
  takeLatest(types.CHAT_OFFER_SUBMIT_REQUEST, submitOffer),
  takeLatest(types.CHAT_OFFER_REJECT_REQUEST, rejectOffer),
  takeLatest(types.CHAT_OFFER_ACCEPT_REQUEST, acceptOffer),
  takeLatest(types.CHAT_FILE_TO_LOAN_REQUEST, loanFilePopulate),
  takeLatest(types.CREATE_CHAT_TEAM_GROUP_REQUEST, createChatTeamGroup),
  takeLatest(types.SEND_OFFER_TO_CHAT_REQUEST, sendOfferToChat),
  takeEvery(types.SEARCH_IN_CHAT_REQUEST, searchInChat),
  takeLatest(types.SEARCH_MESSAGE_IN_CHAT_REQUEST, searchMessageInChat),
];
