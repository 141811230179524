import types from 'constants/actionTypes';

export const requestQrCodeRequest = (payload = {}) => ({
  type: types.REQUEST_QR_CODE_REQUEST,
  payload,
});
export const requestQrCodeSuccess = (payload = {}) => ({
  type: types.REQUEST_QR_CODE_SUCCESS,
  payload,
});
export const requestQrCodeFailure = (payload = {}) => ({
  type: types.REQUEST_QR_CODE_FAILURE,
  payload,
});

export const confirmAuthenticatorDeviceRequest = (payload = {}) => ({
  type: types.CONFIRM_AUTHENTICATOR_DEVICE_REQUEST,
  payload,
});
export const confirmAuthenticatorDeviceSuccess = (payload = {}) => ({
  type: types.CONFIRM_AUTHENTICATOR_DEVICE_SUCCESS,
  payload,
});
export const confirmAuthenticatorDeviceFailure = (payload = {}) => ({
  type: types.CONFIRM_AUTHENTICATOR_DEVICE_FAILURE,
  payload,
});

export const requestSmsAuthRequest = (payload = {}) => ({
  type: types.REQUEST_SMS_AUTH_REQUEST,
  payload,
});
export const requestSmsAuthSuccess = (payload = {}) => ({
  type: types.REQUEST_SMS_AUTH_SUCCESS,
  payload,
});
export const requestSmsAuthFailure = (payload = {}) => ({
  type: types.REQUEST_SMS_AUTH_FAILURE,
  payload,
});

export const disableTfaRequest = (payload = {}) => ({
  type: types.DISABLE_TFA_REQUEST,
  payload,
});
export const disableTfaSuccess = (payload = {}) => ({
  type: types.DISABLE_TFA_SUCCESS,
  payload,
});
export const disableTfaFailure = (payload = {}) => ({
  type: types.DISABLE_TFA_FAILURE,
  payload,
});

export const resendSmsRequest = (payload = {}) => ({
  type: types.RESEND_SMS_REQUEST,
  payload,
});
export const resendSmsSuccess = (payload = {}) => ({
  type: types.RESEND_SMS_SUCCESS,
  payload,
});
export const resendSmsFailure = (payload = {}) => ({
  type: types.RESEND_SMS_FAILURE,
  payload,
});

export const confirmBySmsRequest = (payload = {}) => ({
  type: types.CONFIRM_BY_SMS_REQUEST,
  payload,
});
export const confirmBySmsSuccess = (payload = {}) => ({
  type: types.CONFIRM_BY_SMS_SUCCESS,
  payload,
});
export const confirmBySmsFailure = (payload = {}) => ({
  type: types.CONFIRM_BY_SMS_FAILURE,
  payload,
});

export const resetAuthData = (payload = {}) => ({
  type: types.RESET_AUTH_DATA,
  payload,
});
