import {
  LOAN_ACCESS_LEVEL,
  LOAN_STATUS,
  TERMSHEET_STATUSES,
  LENDER,
  OFFER_STATE,
} from 'constants/appRelated';

import { camelize, camelizeKeys } from 'humps';

import { createSelector } from 'reselect';
import { values, groupBy, last } from 'ramda';
import { processFieldsScheme } from 'features/LoanCreation/helpers';
import { getCurrentUserId } from 'selectors';
import { userTypeSelector } from 'selectors/userType';
import {
  sourcesAndUsesFields,
  additionalSourcesStub,
  additionalUsesStub,
} from 'features/LoanNew/config';
import { normalizeDollars, normalizePercent } from 'lib/normalizers';

import { loanRequestFields, propertyDetailsFields } from './fieldsSet';

export const getLoanStatus = (state) => state.loanNew.data.status;
const getLoanOwnerStatus = (state) => state.loanNew.data.isMy;
const getLoanConciergeStatus = (state) => state.loanNew.data.conciergeStatus;
const getLoanPendingDocuments = (state) => state.loanNew.data.documentsPending;

export const loanAccessSelector = (state) => {
  const loan = state.loanNew.data;
  return {
    full: loan.accessLevel === LOAN_ACCESS_LEVEL.FULL,
    preview: !loan.accessLevel || loan.accessLevel === LOAN_ACCESS_LEVEL.PREVIEW,
    limited: loan.accessLevel === LOAN_ACCESS_LEVEL.LIMITED,
  };
};

export const negotiationsSelector = (state) => state.loanNew.data.negotiations;
const userSelector = (state) => state.currentUser;

export const offersStateSelector = createSelector(negotiationsSelector, (negotiations) =>
  groupBy(
    ({ state, manual }) => state,
    values(negotiations).reduce(
      (offers, item) => [
        ...offers,
        ...item.negotiations.map((negotiation) => ({
          ...negotiation,
          lastOffer: negotiation.offers[negotiation.offers.length - 1],
          lenderType: item.profile.type,
          userId: item.profile.user.id,
          profile: item.profile,
        })),
      ],
      []
    )
  )
);

export const offersFlatSelector = createSelector(negotiationsSelector, (negotiations) =>
  values(negotiations).reduce(
    (offers, item) => [
      ...offers,
      ...item.negotiations.map((negotiation) => ({
        ...negotiation,
        lastOffer: negotiation.offers[negotiation.offers.length - 1],
        lastOfferIsMy: negotiation.offers[negotiation.offers.length - 1].isMy,
        lenderType: item.profile.type,
        userId: item.profile.user.id,
        profile: item.profile,
      })),
    ],
    []
  )
);

export const questionsSelector = (state) => state.loanNew.data.questions || [];

export const groupedQuestionsSelector = createSelector(
  [questionsSelector, userSelector],
  (questions, user) =>
    questions
      .filter(
        (question) =>
          user.accountType !== LENDER ||
          question.isPublic ||
          question.lenderId === user.id
      )
      .reduce(
        (acc, item) => {
          if (!item.answer) {
            acc.new.push(item);
          }

          if (item.answer) {
            acc.answered.push(item);
          }
          return acc;
        },
        { new: [], answered: [] }
      )
);

const documentsSelector = (state) => state.loanNew.data.documents || [];

export const groupedDocumentsSelector = createSelector(documentsSelector, (documents) =>
  groupBy(
    (item) => item.type,
    documents.map((document) =>
      document.type === null ? { ...document, type: 7 } : document
    )
  )
);

export const loanClosingSelector = (state) => {
  const { status } = state.loanNew.data;
  return (
    status === LOAN_STATUS.CLOSED_LOST ||
    status === LOAN_STATUS.CLOSED_WON ||
    status === LOAN_STATUS.CLOSED_WITHDRAWN
  );
};

export const loanEditableSelector = createSelector(
  [getLoanStatus, getLoanOwnerStatus, getLoanConciergeStatus, userTypeSelector],
  (status, isOwner, isConcierge, userType) =>
    !userType.am &&
    !userType.staff &&
    !isConcierge &&
    isOwner &&
    (status === LOAN_STATUS.DRAFT ||
      status === LOAN_STATUS.VERIFICATION_FAILED ||
      status === LOAN_STATUS.POSTED_TO_MARKETPLACE)
);

export const loanTitleEditableSelector = createSelector(
  [getLoanStatus, getLoanOwnerStatus, getLoanConciergeStatus, userTypeSelector],
  (status, isOwner, isConcierge, userType) =>
    !userType.am &&
    !userType.staff &&
    !isConcierge &&
    isOwner &&
    (status === LOAN_STATUS.DRAFT ||
      status === LOAN_STATUS.VERIFICATION_FAILED ||
      status === LOAN_STATUS.PENDING_CLOSING ||
      status === LOAN_STATUS.POSTED_TO_MARKETPLACE)
);

export const acceptedNegotiationsSelector = createSelector(
  negotiationsSelector,
  (negotiations) =>
    values(negotiations).reduce(
      (acc, negotiation) => [
        ...acc,
        ...negotiation.negotiations.filter((offer) => offer.state === 'accepted'),
      ],
      []
    )
);

export const loanTermsheetsSelector = createSelector(
  acceptedNegotiationsSelector,
  (negotiations) =>
    negotiations
      .reduce((acc, offer) => [...acc, last(offer.termsheets)], [])
      .filter(Boolean)
);

export const uploadedTermsheetsSelector = createSelector(
  loanTermsheetsSelector,
  (termsheets) =>
    termsheets.filter((termsheet) => termsheet.status === TERMSHEET_STATUSES.UPLOADED)
);

export const rejectedTermsheetsSelector = createSelector(
  loanTermsheetsSelector,
  (termsheets) =>
    termsheets.filter(
      (termsheet) => termsheet.status === TERMSHEET_STATUSES.BORROWER_REJECTED_TERMSHEET
    )
);

export const signedTermsheetsSelector = createSelector(
  loanTermsheetsSelector,
  (termsheets) =>
    termsheets.filter(
      (termsheet) => termsheet.status === TERMSHEET_STATUSES.BORROWER_SIGNED_TERMSHEET
    )
);

export const loanSelector = (state) => state.loanNew.data;

// todo if there no edit in loan view, perform all calculations in selector

export const calculateSourceUsesSum = (loan) => {
  const { projectCostFormula } = loan;

  if (projectCostFormula) {
    const sourcesSum = projectCostFormula.sources.reduce(
      (acc, curr) => acc + loan[camelize(curr)],
      0
    );
    const usesSum = projectCostFormula.uses.reduce(
      (acc, curr) => acc + loan[camelize(curr)],
      0
    );

    return {
      sources: sourcesSum,
      uses: usesSum,
    };
  }
  return {};
};
export const sourcesUsesSelector = createSelector(loanSelector, calculateSourceUsesSum);

export const processSourcesUsesData = (loan) => {
  const { projectCostFormula } = loan;
  const results = { sources: {}, uses: {} };

  const sourcesUsesSum = calculateSourceUsesSum(loan);

  if (projectCostFormula?.sources) {
    results.sources.fields = loan.projectCostFormula.sources.reduce((acc, field) => {
      const camelizedField = camelize(field);
      const value = loan[camelizedField];

      if (!sourcesAndUsesFields[camelizedField] || !value) return acc;

      const title = sourcesAndUsesFields[camelizedField].title;
      const percent = Number(Number(value / sourcesUsesSum.sources).toFixed(4));

      return [
        ...acc,
        {
          value,
          valueLabel: normalizeDollars(value),
          title,
          percent,
          percentLabel: normalizePercent(percent),
          field: camelizedField,
        },
      ];
    }, []);

    results.sources.additionalFields = additionalSourcesStub.reduce((acc, field) => {
      const fieldName = `${field}Field`;
      const valueName = `${field}Value`;

      if (!loan[fieldName]) return acc;

      const value = loan[valueName];
      const title = loan[`${field}Field`];
      const percent = Number(Number(value / sourcesUsesSum.sources).toFixed(4));

      return [
        ...acc,
        {
          value,
          valueLabel: normalizeDollars(value),
          percent: Number(percent.toFixed(4)),
          percentLabel: normalizePercent(percent),
          title,
          fieldName,
          valueName,
        },
      ];
    }, []);

    results.sources.sum = sourcesUsesSum.sources;
    results.sources.sumLabel = normalizeDollars(sourcesUsesSum.sources);

    results.uses.fields = loan.projectCostFormula.uses.reduce((acc, field) => {
      const camelizedField = camelize(field);
      const value = loan[camelizedField];

      if (!sourcesAndUsesFields[camelizedField] || !value) return acc;

      const title = sourcesAndUsesFields[camelizedField].title;
      const percent = Number(Number(value / sourcesUsesSum.uses).toFixed(4));

      return [
        ...acc,
        {
          value,
          valueLabel: normalizeDollars(value),
          title,
          percent,
          percentLabel: normalizePercent(percent),
          field: camelizedField,
        },
      ];
    }, []);

    results.uses.additionalFields = additionalUsesStub.reduce((acc, field) => {
      const fieldName = `${field}Field`;
      const valueName = `${field}Value`;

      if (!loan[fieldName]) return acc;

      const value = loan[valueName];
      const title = loan[`${field}Field`];
      const percent = Number(Number(value / sourcesUsesSum.sources).toFixed(4));

      return [
        ...acc,
        {
          value,
          valueLabel: normalizeDollars(value),
          percent,
          percentLabel: normalizePercent(percent),
          title,
          fieldName,
          valueName,
        },
      ];
    }, []);

    results.uses.sum = sourcesUsesSum.uses;
    results.uses.sumLabel = normalizeDollars(sourcesUsesSum.uses);
  }

  return results;
};

export const sourcesUsesDataSelector = createSelector(
  loanSelector,
  processSourcesUsesData
);

export const loanHasRequestedDocuments = createSelector(
  loanSelector,
  (loan) => !!loan?.documentsPending?.length
);

// Get desired terms fields schema from loan data
export const getDesiredTermsScheme = (state) =>
  state.loanNew?.data?.fieldsScheme?.desiredTerms;

// Get property profile fields schema from loan data
export const getPropertyProfileScheme = (state) =>
  state.loanNew?.data?.fieldsScheme?.propertyProfile;

// Take flat fields list from schema and merge it with required hard coded fields
const mergeFieldsWithScheme = (fields, scheme) => {
  const schemaFields = scheme ? processFieldsScheme(scheme) : [];
  return [...fields, ...schemaFields];
};

export const getDesiredTermsFields = (scheme) =>
  mergeFieldsWithScheme(loanRequestFields, scheme);

export const getPropertyProfileFields = (scheme) =>
  mergeFieldsWithScheme(propertyDetailsFields, scheme);

export const getDesiredTermsSchemeFields = createSelector(
  [getDesiredTermsScheme],
  getDesiredTermsFields
);

export const getPropertyProfileSchemeFields = createSelector(
  [getPropertyProfileScheme],
  getPropertyProfileFields
);

export const gelLoanCustomFields = createSelector([loanSelector], (loan) => {
  if (loan.customFields) {
    return camelizeKeys(groupBy((field) => field.section, loan.customFields));
  }
  return {};
});

export const sourcesUsesVisibilitySelector = createSelector([loanSelector], (loan) => {
  const { projectCostFormula, sectionVisibility } = loan;

  if (sectionVisibility && !sectionVisibility.sourcesAndUses) {
    return false;
  }

  if (projectCostFormula) {
    const excludedFields = ['loanAmount', 'equityAmount'];

    const fieldsArray = [...projectCostFormula.sources, ...projectCostFormula.uses];

    const fields = fieldsArray.reduce((prev, curr) => {
      const currentField = camelize(curr);
      return excludedFields.indexOf(currentField) === -1 && loan[currentField]
        ? [...prev, loan[currentField]]
        : prev;
    }, []);

    return !!fields.length;
  }

  return false;
});

export const getProtectedDocumentsStatus = createSelector(loanSelector, (loan) => {
  const recommendedDocuments = loan?.documentsAll?.recommended || [];
  const sections = loan?.documentsAll?.sections || [];

  const folders = [
    ...recommendedDocuments,
    ...sections.reduce((acc, section) => [...acc, ...section.folders], []),
  ];

  const documentsArray = folders.reduce(
    (acc, folder) => [...acc, ...folder.documents],
    []
  );

  const hasProtectedDocuments = !!documentsArray.filter(
    (item) => item.protected && !item.url
  ).length;

  return hasProtectedDocuments;
});

export const getAddressSelector = createSelector(loanSelector, (loan) => {
  const accessFull = loan.accessLevel === LOAN_ACCESS_LEVEL.FULL;
  const isSuggestAddress = loan.firstAddressLat;

  if (accessFull && isSuggestAddress) {
    return loan.addresses[0];
  } else if (accessFull) {
    return `${loan.addresses?.[0]}${loan.city ? `, ${loan.city}` : ''}${
      loan.state ? `, ${loan.state}` : ''
    }`;
  }

  return loan.shortAddress;
});

export const getLenderAcceptedOffer = createSelector(
  [userTypeSelector, getCurrentUserId, negotiationsSelector],
  (userType, currentUserId, loanNegotiations) => {
    if (!userType.lender) {
      return undefined;
    }

    return loanNegotiations?.[currentUserId]?.negotiations.filter(
      ({ state }) => state === OFFER_STATE.ACCEPTED
    )[0];
  }
);

export const getLenderRequestedDocumentsState = createSelector(
  [getLoanPendingDocuments],
  (pendingDocuments) => {
    const results = {};

    pendingDocuments.forEach((item) => {
      const recommendedDocuments = item?.recommended || [];
      const sections = item?.sections || [];

      const folders = [
        ...recommendedDocuments,
        ...sections.reduce((acc, section) => [...acc, ...section.folders], []),
      ];

      results[item.lender.pk] = !!folders.filter(({ uploaded }) => !uploaded).length;
    });

    return results;
  }
);
