export const asyncActions = [
  'DASHBOARD_DATA',
  'EMAIL_CONFIRMATION',
  'GET_LOANS_DASHBOARD',
  'CONFIRM_LENDING_CRITERIA',
];
export const syncActions = [
  'SET_DASHBOARD_CHAT_MESSAGE_COUNT',
  'RESET_NOTIFICATIONS',
  'SET_BADBROWSER_PAGE_SHOWED',
];
