import types from 'constants/actionTypes';


export const setChosenCalendarsRequest = (payload = {}) => ({
    type: types.SET_CHOSEN_CALENDARS_REQUEST,
    payload,

})

export const setChosenCalendarsSuccess = (payload = {}) => ({
    type: types.SET_CHOSEN_CALENDARS_SUCCESS,
    payload,
})

export const setChosenCalendarsFailure = (payload = {}) => ({
    type: types.SET_CHOSEN_CALENDARS_FAILURE,
    payload,
})

export const getAuthCalendarsRequest = (payload = {}) => ({
    type: types.GET_AUTH_CALENDARS_REQUEST,
    payload,
});

export const getAuthCalendarsSuccess = (payload = {}) => ({
    type: types.GET_AUTH_CALENDARS_SUCCESS,
    payload,
});

export const getAuthCalendarsFailure = (payload = {}) => ({
    type: types.GET_AUTH_CALENDARS_FAILURE,
    payload,
});

export const getCalendarsRequest = (payload = {}) => ({
    type: types.GET_CALENDARS_REQUEST,
    payload,
});

export const getCalendarsSuccess = (payload = {}) => ({
    type: types.GET_CALENDARS_SUCCESS,
    payload,
});

export const getCalendarsFailure = (payload = {}) => ({
    type: types.GET_CALENDARS_FAILURE,
    payload,
});

export const getTasksMatchingLoansRequest = (payload = {}) => ({
    type: types.GET_TASKS_MATCHING_LOANS_REQUEST,
    payload,
});

export const getTasksMatchingLoansSuccess = (payload = {}) => ({
    type: types.GET_TASKS_MATCHING_LOANS_SUCCESS,
    payload,
});

export const getTasksMatchingLoansFailure = (payload = {}) => ({
    type: types.GET_TASKS_MATCHING_LOANS_FAILURE,
    payload,
});

export const getNumberTasksSuccess = (payload = {}) => {
    return {
    type: types.GET_NUMBER_TASKS_SUCCESS,
    payload,
}};

export const getNumberTasksFailure = (payload = {}) => ({
    type: types.GET_NUMBER_TASKS_FAILURE,
    payload,
});

export const myMatchingTasksOrderingUpdate = (payload = {}) => ({
    type: types.ORDERING_UPDATE_TASKS_DATA_REQUEST,
    payload,
});
  
export const editTaskRequest = (payload = {}) => ({
    type: types.EDIT_TASK_REQUEST,
    payload,
});
export const editTaskSuccess = (payload = {}) => ({
    type: types.EDIT_TASK_SUCCESS,
    payload,
});
export const editTaskFailure = (payload = {}) => ({
    type: types.EDIT_TASK_FAILURE,
    payload,
});

export const addNewTask = (payload = {}) => ({
    type: types.ADD_NEW_TASK_REQUEST,
    payload,
});
export const addNewTaskSuccess = (payload = {}) => ({
    type: types.ADD_NEW_TASK_SUCCESS,
    payload,
});
export const addNewTaskFailure = (payload = {}) => ({
    type: types.ADD_NEW_TASK_FAILURE,
    payload,
});
export const getTaskIdRequest = (payload = {}) => ({
    type: types.GET_TASK_ID_REQUEST,
    payload,
});
export const getTaskIdSuccess = (payload = {}) => ({
    type: types.GET_TASK_ID_SUCCESS,
    payload,
});
export const getTaskIdFailure = (payload = {}) => ({
    type: types.GET_TASK_ID_FAILURE,
    payload,
});
export const getTasksData = (payload = {}) => ({
    type: types.GET_TASKS_DATA_REQUEST,
    payload,
});
export const getTasksDataSuccess = (payload = {}) => ({
    type: types.GET_TASKS_DATA_SUCCESS,
    payload,
});
export const getTasksDataFailure = (payload = {}) => ({
    type: types.GET_TASKS_DATA_FAILURE,
    payload,
});

export const removeTaskRequest = (payload = {}) => ({
    type: types.REMOVE_TASK_REQUEST,
    payload,
});
export const removeTaskSuccess = (payload = {}) => ({
    type: types.REMOVE_TASK_SUCCESS,
    payload,
});
export const removeTaskFailure = (payload = {}) => ({
    type: types.REMOVE_TASK_FAILURE,
    payload,
});

export const completeTaskRequest = (payload = {}) => ({
    type: types.COMPLETE_TASK_REQUEST,
    payload,
});

export const completeTaskSuccess = (payload = {}) => ({
    type: types.COMPLETE_TASK_SUCCESS,
    payload,
});

export const completeTaskFailure = (payload = {}) => ({
    type: types.COMPLETE_TASK_FAILURE,
    payload,
});

export const seenTaskRequest = (payload = {}) => ({
    type: types.SEEN_TASK_REQUEST,
    payload,
});

export const seenTaskSuccess = (payload = {}) => ({
    type: types.SEEN_TASK_SUCCESS,
    payload,
});

export const seenTaskFailure = (payload = {}) => ({
    type: types.SEEN_TASK_FAILURE,
    payload,
});


export const hideCalendarBannerRequest = (payload = {}) => ({
    type: types.HIDE_CALENDAR_BANNER_REQUEST,
    payload,
});

export const hideCalendarBannerSuccess = (payload = {}) => ({
    type: types.HIDE_CALENDAR_BANNER_SUCCESS,
    payload,
});

export const hideCalendarBannerFailure = (payload = {}) => ({
    type: types.HIDE_CALENDAR_BANNER_FAILURE,
    payload,
});