export const theme = {
  breakpoints: [480, 769, 1024, 1200],
  space: [0, 4, 8, 16, 24, 32, 64, 128, 256, 512],
  fontSizes: [14, 16, 18, 20, 24, 28, 30, 36, 48],
  fontWeights: [300, 400, 600],
  lineHeights: {
    solid: 1,
    title: 1.25,
    copy: 1.5,
  },
  letterSpacings: {
    normal: 'normal',
    tracked: '0.1em',
    tight: '-0.05em',
    mega: '0.25em',
  },
  fonts: {
    poppins: 'Poppins, Arial, sans-serif',
    muli: 'Muli, sans-serif',
    sans: '"Open Sans", sans-serif',
  },
  borders: [
    0,
    '1px solid',
    '2px solid',
    '4px solid',
    '8px solid',
    '16px solid',
    '32px solid',
  ],
  radii: [0, 4, 20, '100%'],
  width: [16, 32, 64, 128, 256],
  heights: [16, 32, 64, 128, 256],
  maxWidths: [16, 32, 64, 128, 256, 512, 768, 1024, 1536],
  colors: {
    blues: [
      '#1FBBFD',
      '#0091F9',
      '#3386E6',
      'linear-gradient(to right, #24B8FE, #6987FF)',
      'linear-gradient(to right, #B9E8FF, #A8B9FD)',
      'linear-gradient(45deg, #6781FE, #5CFEFE)',
      '#4394EA',
    ],
    oranges: [
      '#FF9E01',
      '#FF8F7C',
      '#FF7F51',
      'linear-gradient(to right, #FFE269, #FD8440)',
    ],
    grays: ['#ECEDEE', '#8890A2', '#6276AA', '#3C659B', '#2B4C78'],
    black: '#1C3353',
    green: '#28BF9D',
    violet: '#9B5AE0',
    white: '#fff',
    error: '#FF6F57',
    positive: '#28BF9D',
    neutral: '#0091F9',
    overlay: 'rgba(43,43,43, .85)',
    inputBorder: 'linear-gradient(to right, #B9E8FF, #A8B9FD)',
  },
  transition: 'all 0.3s ease',
  shadow: '0 4px 10px rgba(194,198,210, .2)',
};
