import React from 'react';
import {
    ModalBody,
    ModalHeading,
    ModalActions,
    modalWithContentWrapperStyles,
  } from 'ui/Modal/styles';
import { Button, ButtonGhost } from 'ui/Button';
import styled from 'styled-components/macro';

export const RemoveTask = ({cancel, remove, }) => {
    return (
        <RemoveTasksWrapper>
            <ModalHeadingExtended>Are you sure you want to delete this task?</ModalHeadingExtended>

            <ModalActionsExtended>    
                <ButtonGhost type="button" onClick={cancel}>
                    {'Cancel'}
                </ButtonGhost>

                <Button type="submit" secondary onClick={remove}>
                    {'Yes'}
                </Button>
            </ModalActionsExtended>

        </RemoveTasksWrapper>
    )
};

const ModalHeadingExtended = styled(ModalHeading)`
    padding: 50px 24px 24px !important;  
`;

const ModalActionsExtended = styled(ModalActions)`
    justify-content: space-around;
`;

const RemoveTasksWrapper = styled.div`
    ${modalWithContentWrapperStyles}
`;