import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components/macro';
import { Text } from 'ui/Text';
import { Tooltip } from 'ui/Tooltip';
import { HtmlInput } from './HtmlInput';

export const Input = ({
  label,
  disabled,
  error,
  noBrd,
  mb,
  required,
  percentage,
  hint,
  fullWidth,
  className,
  isInputSelect,
  passwordVisibilityBtnSlot,
  semiRequired,
  ...rest
}) => (
  <WrapperContainer
    mb={mb}
    fullWidth={fullWidth}
    className={className}
    isInputSelect={isInputSelect}
  >
    {(label || hint) && (
      <LabelContainer>
        {label && (
          <Text fontSize="0" mr={2} color={error && 'error'} fontFamily="muli">
            {label}
            {required && <Asterix> *</Asterix>}
          </Text>
        )}
        {hint && <Tooltip text={hint} />}
      </LabelContainer>
    )}
    <InputWrap percentage={percentage} blacklist={['percentage']}>
      <HtmlInput
        {...rest}
        noBrd={noBrd}
        blacklist={['error', 'noBrd', 'fullWidth', 'variant']}
        variant={(error && 'error') || (semiRequired && 'semiRequired') || ''}
        disabled={disabled}
      />
      {passwordVisibilityBtnSlot}
    </InputWrap>
    {error && <ErrorWrapper>{error}</ErrorWrapper>}
  </WrapperContainer>
);

Input.defaultProps = {
  label: '',
  error: '',
  disabled: false,
  noBrd: false,
};

Input.propTypes = {
  label: PropTypes.string,
  error: PropTypes.string,
  disabled: PropTypes.bool,
  noBrd: PropTypes.bool,
};

const ErrorWrapper = styled(Text)`
  position: absolute;
  font-size: 12px;
  color: ${(p) => p.theme.colors.error};
`;

const InputWrapCommon = styled.div`
  z-index: 2;
  position: relative;
`;

const InputWrap = styled(InputWrapCommon)`
  position: relative;
  ${(p) =>
    p.percentage &&
    css`
      &::after {
        content: '%';

        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 10px;
        z-index: 10;
      }
    `};
`;

const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;

const WrapperContainer = styled.div`
  position: relative;
  margin-bottom: ${(p) =>
    ((p.mb || typeof p.mb === 'number') && `${p.theme.space[p.mb]}px`) || '24px'};

  width: ${(p) => (p.fullWidth ? '100%' : 'auto')};

  ${(p) =>
    p.isInputSelect &&
    css`
      ${ErrorWrapper} {
        margin-right: -80px;
      }
    `}
`;

const Asterix = styled.span`
  color: #e32019;
`;
