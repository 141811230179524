import types from 'constants/actionTypes';

const initialState = {
  roomType: null,
  roomId: null,
};

export function chatWidget(state = initialState, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case types.SHOW_CHAT_WIDGET_SUCCESS:
      return {
        ...state,
        roomType: payload.roomType,
        roomId: payload.roomId,
        messageUuid: payload.messageUuid,
        highlights: payload.highlights,
        messageId: payload.messageId,
      };

    case types.HIDE_CHAT_WIDGET:
      return initialState;

    default:
      return state;
  }
}
