import styled, { css } from 'styled-components/macro';

import { ReactComponent as Icon } from 'icons/ico_eye_password.svg';

export const EyeIcon = styled(Icon)`
  ${(p) =>
    p.color &&
    css`
      g {
        g {
          fill: ${p.color};
        }
      }
    `}
`;
