export const asyncActions = [
  'MY_LOANS_DATA',
  'MY_LOANS_UPDATE',
  'EXPORT_MY_LOANS',
  'EXPORT_MY_LOANS',
  'CLOSE_LOAN',
  'ASSIGN_LOAN',
  'DUPLICATE_LOAN',
  'SET_MY_LOANS_DISPLAY_VARIANT',
];

export const syncActions = ['MY_LOANS_ORDERING_UPDATE'];
