import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { Text } from 'ui/Text';
import { Tooltip } from 'ui/Tooltip';
import { HtmlTextarea } from './HtmlTextarea';

export const Textarea = ({
  label,
  disabled,
  error,
  mb,
  hint,
  rows,
  required,
  expandable,
  value,
  semiRequired,
  ...rest
}) => (
  <WrapperContainer mb={mb}>
    {(label || hint) && (
      <LabelContainer>
        {label && (
          <Text fontSize="0" mr={2} color={error ? 'error' : ''} fontFamily="muli">
            {label}
            {required && <Asterix> *</Asterix>}
          </Text>
        )}
        {hint && <Tooltip text={hint} />}
      </LabelContainer>
    )}
    <AreaWrapper>
      <HtmlTextarea
        {...rest}
        value={value}
        disabled={disabled}
        variant={(error && 'error') || (semiRequired && 'semiRequired') || ''}
        rows={rows}
        expandable={expandable}
      />
      {expandable && <ExpandableWrapper>{value}</ExpandableWrapper>}
    </AreaWrapper>

    {error && (
      <Text fontSize="12px" color="error">
        {error}
      </Text>
    )}
  </WrapperContainer>
);

Textarea.defaultProps = {
  label: '',
  error: '',
  disabled: false,
};

Textarea.propTypes = {
  label: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  disabled: PropTypes.bool,
};

const WrapperContainer = styled.div`
  margin-bottom: ${(p) =>
    ((p.mb || typeof p.mb === 'number') && `${p.theme.space[p.mb]}px`) || '24px'};
`;

const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;

const Asterix = styled.span`
  color: #e32019;
`;

const AreaWrapper = styled.div`
  position: relative;
`;

const ExpandableWrapper = styled.div`
  padding: 6px 8px 6px 8px;
  opacity: 0;
  white-space: pre-wrap;
`;
