import React from 'react';
import styled, { css } from 'styled-components/macro';
import { omit } from 'ramda';

export const WithTag = ({ as: HtmlTagName, children, ...props }) => (
  <HtmlTagName
    {...omit(
      [
        'italic',
        'clickable',
        'decorated',
        'fontFamily',
        'fontWeight',
        'fontSize',
        'color',
        'textAlign',
        'lineHeight',
        'mb',
        'mt',
        'ml',
        'mr',
        'pl',
      ],
      props
    )}
  >
    {children}
  </HtmlTagName>
);

WithTag.defaultProps = {
  as: 'div',
};

export const TextCommon = styled(WithTag)`
  line-height: ${(p) => p.theme.lineHeights.copy};
  font-size: ${(p) => p.theme.fontSizes[0]}px;
  margin: 0;
  color: ${(p) => p.theme.colors.black};

  &:last-child {
    margin-bottom: 0;
  }
`;

export const Text = styled(TextCommon)`
  ${(p) => p.italic && 'font-style: italic'};
  ${(p) => p.clickable && 'cursor: pointer'};
  ${(p) => p.decorated && 'text-decoration: underline'};

  ${(p) =>
    p.fontFamily &&
    css`
      font-family: ${p.theme.fonts[p.fontFamily] || p.fontFamily};
    `}

  ${(p) =>
    p.fontWeight &&
    css`
      font-weight: ${p.theme.fontWeights[p.fontWeight] || p.fontWeight};
    `}

  ${(p) =>
    p.fontSize &&
    css`
      font-size: ${p.theme.fontSizes[p.fontSize]
        ? `${p.theme.fontSizes[p.fontSize]}px`
        : p.fontSize};
    `}

  ${(p) => p.color && `color: ${p.theme.colors?.[p.color.split('.')] || p.color}`};

  ${(p) => p.textAlign && `text-align: ${p.textAlign}`};

  ${(p) =>
    p.lineHeight &&
    css`
      line-height: ${p.theme.lineHeights[p.lineHeight] || p.lineHeight};
    `}

  ${(p) =>
    (p.mb || typeof p.mb === 'number') &&
    css`
      margin-bottom: ${p.theme.space[p.mb] ? `${p.theme.space[p.mb]}px` : p.mb};
    `}

  ${(p) =>
    (p.mt || typeof p.mt === 'number') &&
    css`
      margin-top: ${p.theme.space[p.mt] ? `${p.theme.space[p.mt]}px` : p.mt};
    `}

  ${(p) =>
    (p.ml || typeof p.ml === 'number') &&
    css`
      margin-left: ${p.theme.space[p.ml] ? `${p.theme.space[p.ml]}px` : p.ml};
    `}

  ${(p) =>
    (p.mr || typeof p.mr === 'number') &&
    css`
      margin-right: ${p.theme.space[p.mr] ? `${p.theme.space[p.mr]}px` : p.mr};
    `}

  ${(p) =>
    (p.pl || typeof p.pl === 'number') &&
    css`
      padding-left: ${p.theme.space[p.pl] ? `${p.theme.space[p.pl]}px` : p.pl};
    `}
`;

Text.displayName = 'Text';
