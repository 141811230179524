import React from 'react';
import styled from 'styled-components/macro';
import { Field } from 'react-final-form';
import { Textarea } from 'components/Form';
import { Text } from 'ui/Text';
import { FileUpload } from 'components/FileUpload';
import { BucketIcon } from 'components/SvgIcons';
import { allowedDocTypes } from 'config/allowedDocTypes';
import { DocumentRejectionHandler } from 'components/DocumentRejectionHandler';

const CommentWrapper = styled.div`
  margin-bottom: 25px;
`;

export const OfferAttaches = ({
  onDrop,
  documents,
  removeUploadingDocument,
  isSoftQuote,
}) => (
  <div>
    {isSoftQuote && (
      <CommentWrapper>
        <Text mb={3}>ADD COMMENT</Text>
        <Field component={Textarea} name="message" rows={2} expandable />
      </CommentWrapper>
    )}
    <div>
      <DocumentRejectionHandler>
        {({ handleOnDropReject }) => (
          <FileUpload
            onDrop={onDrop}
            accept={allowedDocTypes.common}
            onDropRejected={handleOnDropReject}
          />
        )}
      </DocumentRejectionHandler>
      <ul>
        {documents.map((file) => (
          <li key={file.id}>
            <span>{file.filename}</span>{' '}
            <span
              onClick={() => {
                removeUploadingDocument(file.id);
              }}
            >
              <BucketIcon />
            </span>
          </li>
        ))}
      </ul>
    </div>
  </div>
);
