import React from 'react';
import styled from 'styled-components/macro';

import ACCEPT from '../../../../../icons/ico_accept.svg';
import REJECT from '../../../../../icons/ico_reject.svg';
import { CrossIcon, CheckIcon } from 'components/SvgIcons';

import { 
    DOC_SIGNATURE_NEW, 
    DOC_SIGNATURE_REJECTED, 
    DOC_SIGNATURE_ACCEPTED, 
    DOC_SIGNATURE_REJECTED_REASON } from 'constants/appRelated';

const DocumentState = ({handlerStateFile, file}) =>  (

    <DocumentStateWrapper>
        {file.signedDocument.state === DOC_SIGNATURE_NEW ? (
            <>
            <div className={'state await'} onClick={() => handlerStateFile(DOC_SIGNATURE_ACCEPTED, file)}>
                <img alt="" src={ACCEPT}/>
                <span>Accept</span>
            </div>
            <div className={'state await'} onClick={() => handlerStateFile(DOC_SIGNATURE_REJECTED, file)}>
                <img alt="" src={REJECT}/>
                <span>Reject</span>
            </div>
            </>
        ):(
            file.signedDocument.state === DOC_SIGNATURE_ACCEPTED? (
            <div className={'state accept'}>
                <span>Accepted</span>
                <CheckIcon color="#44AF69" />
            </div>
            ):(
                file.signedDocument.state === DOC_SIGNATURE_REJECTED  ? (
                <div className={'state reject'} onClick={() => {}}>
                    <div>
                        <span>Rejected</span>
                         (<p onClick={() => handlerStateFile(DOC_SIGNATURE_REJECTED_REASON, file)}>
                            SEE REASON
                        </p>) 
                    </div>
                    <CrossIcon color="#e32019" />
                </div>
            ):(
                <></>
            ))
        )}
    </DocumentStateWrapper>
);

export default DocumentState;

const DocumentStateWrapper = styled.div`
  color: #1C3353;

  .state{
    &.await{
        margin-right: 16px;
    }
    &.reject, &.accept{
        margin-right: 15px;
        display: flex;
        align-items: baseline;
        font-size: 12px;
        line-height: 16px;
        span{margin-right: 5px}
    }
    img{ margin-right: 8px};
    cursor: pointer;
  
    &.accept{
      color: #44AF69;
    }
    
    &.reject{
      span{color: #E32019;}
      div{display: flex; margin-right: 5px}
      p{font-size: 10px; color:#4394ea; margin: 0}
    }
  }

`;