import { put, takeLatest, call, select } from 'redux-saga/effects';
import types from 'constants/actionTypes';
import * as API from 'api';
import { getError } from 'sagas/utils';
import { push } from 'connected-react-router';
import { BORROWER, BROKER, LENDER } from 'constants/appRelated';
import { getLenderCompanyDataRequest, getLenderTeamMembersRequest } from 'features/LenderCompany/actions';

import {
  confirmAuthSuccess,
  confirmAuthFailure,
  resendSmsCodeSuccess,
  resendSmsCodeFailure,
} from './actions';

function* confirmAuth(action) {
  const { token } = action.payload;
  try {
    yield call(API.axiosApi.post, '/auth/tfa/second-step', { token });

    const { currentUser } = yield select();

    let teamData = {};

    if (currentUser.accountType === BROKER || currentUser.accountType === BORROWER) {
      const teamDataRequest = yield call(API.axiosApi.get, '/auth/team-settings');
      teamData = teamDataRequest.data.response;
    }

    if (currentUser.accountType === LENDER) {
      yield put(getLenderTeamMembersRequest());
      yield put(getLenderCompanyDataRequest());
    }

    // cause we getting 403 for team settings without confirmed authorization (2fa)

    yield put(confirmAuthSuccess({ teamData, team: teamData.members }));
    yield put(push('/dashboard'));
  } catch (e) {
    yield put(
      confirmAuthFailure({
        error:
          e?.response?.data?.error?.token ||
          'Invalid one-time password. Please, try again',
      })
    );
  }
}
function* resendSmsCode() {
  try {
    const {
      data: { response },
    } = yield call(API.axiosApi.post, '/auth/tfa/phone/issue-code');

    yield put(resendSmsCodeSuccess(response));
  } catch (e) {
    yield put(resendSmsCodeFailure({ message: getError(e) }));
  }
}

export default [
  takeLatest(types.CONFIRM_AUTH_REQUEST, confirmAuth),
  takeLatest(types.RESEND_SMS_CODE_REQUEST, resendSmsCode),
];
