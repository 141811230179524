import types from 'constants/actionTypes';

export const submitManualQuoteRequest = (payload = {}) => ({
  type: types.SUBMIT_MANUAL_QUOTE_REQUEST,
  payload,
});
export const submitManualQuoteSuccess = (payload = {}) => ({
  type: types.SUBMIT_MANUAL_QUOTE_SUCCESS,
  payload,
});
export const submitManualQuoteFailure = (payload = {}) => ({
  type: types.SUBMIT_MANUAL_QUOTE_FAILURE,
  payload,
});

export const deleteNegotiationRequest = (payload = {}) => ({
  type: types.DELETE_NEGOTIATION_REQUEST,
  payload,
});
export const deleteNegotiationSuccess = (payload = {}) => ({
  type: types.DELETE_NEGOTIATION_SUCCESS,
  payload,
});
export const deleteNegotiationFailure = (payload = {}) => ({
  type: types.DELETE_NEGOTIATION_FAILURE,
  payload,
});

export const exportStatusReportRequest = (payload = {}) => ({
  type: types.EXPORT_STATUS_REPORT_REQUEST,
  payload,
});
export const exportStatusReportSuccess = (payload = {}) => ({
  type: types.EXPORT_STATUS_REPORT_SUCCESS,
  payload,
});
export const exportStatusReportFailure = (payload = {}) => ({
  type: types.EXPORT_STATUS_REPORT_FAILURE,
  payload,
});

export const changePropertyNameRequest = (payload = {}) => ({
  type: types.CHANGE_PROPERTY_NAME_REQUEST,
  payload,
});
export const changePropertyNameSuccess = (payload = {}) => ({
  type: types.CHANGE_PROPERTY_NAME_SUCCESS,
  payload,
});
export const changePropertyNameFailure = (payload = {}) => ({
  type: types.CHANGE_PROPERTY_NAME_FAILURE,
  payload,
});

export const submitManualTermsheetRequest = (payload = {}) => ({
  type: types.SUBMIT_MANUAL_TERMSHEET_REQUEST,
  payload,
});
export const submitManualTermsheetSuccess = (payload = {}) => ({
  type: types.SUBMIT_MANUAL_TERMSHEET_SUCCESS,
  payload,
});
export const submitManualTermsheetFailure = (payload = {}) => ({
  type: types.SUBMIT_MANUAL_TERMSHEET_FAILURE,
  payload,
});

export const getLoanTermsheetsRequest = (payload = {}) => ({
  type: types.GET_LOAN_TERMSHEETS_REQUEST,
  payload,
});
export const getLoanTermsheetsSuccess = (payload = {}) => ({
  type: types.GET_LOAN_TERMSHEETS_SUCCESS,
  payload,
});
export const getLoanTermsheetsFailure = (payload = {}) => ({
  type: types.GET_LOAN_TERMSHEETS_FAILURE,
  payload,
});

export const editManualTermsheetRequest = (payload = {}) => ({
  type: types.EDIT_MANUAL_TERMSHEET_REQUEST,
  payload,
});
export const editManualTermsheetSuccess = (payload = {}) => ({
  type: types.EDIT_MANUAL_TERMSHEET_SUCCESS,
  payload,
});
export const editManualTermsheetFailure = (payload = {}) => ({
  type: types.EDIT_MANUAL_TERMSHEET_FAILURE,
  payload,
});

export const deleteManualTermsheetRequest = (payload = {}) => ({
  type: types.DELETE_MANUAL_TERMSHEET_REQUEST,
  payload,
});
export const deleteManualTermsheetSuccess = (payload = {}) => ({
  type: types.DELETE_MANUAL_TERMSHEET_SUCCESS,
  payload,
});
export const deleteManualTermsheetFailure = (payload = {}) => ({
  type: types.DELETE_MANUAL_TERMSHEET_FAILURE,
  payload,
});

export const restoreManualTermsheetRequest = (payload = {}) => ({
  type: types.RESTORE_MANUAL_TERMSHEET_REQUEST,
  payload,
});
export const restoreManualTermsheetSuccess = (payload = {}) => ({
  type: types.RESTORE_MANUAL_TERMSHEET_SUCCESS,
  payload,
});
export const restoreManualTermsheetFailure = (payload = {}) => ({
  type: types.RESTORE_MANUAL_TERMSHEET_FAILURE,
  payload,
});

export const getLenderLoanTermsheetsRequest = (payload = {}) => ({
  type: types.GET_LENDER_LOAN_TERMSHEETS_REQUEST,
  payload,
});
export const getLenderLoanTermsheetsSuccess = (payload = {}) => ({
  type: types.GET_LENDER_LOAN_TERMSHEETS_SUCCESS,
  payload,
});
export const getLenderLoanTermsheetsFailure = (payload = {}) => ({
  type: types.GET_LENDER_LOAN_TERMSHEETS_FAILURE,
  payload,
});

export const uploadLenderTermsheetRequest = (payload = {}) => ({
  type: types.UPLOAD_LENDER_TERMSHEET_REQUEST,
  payload,
});
export const uploadLenderTermsheetSuccess = (payload = {}) => ({
  type: types.UPLOAD_LENDER_TERMSHEET_SUCCESS,
  payload,
});
export const uploadLenderTermsheetFailure = (payload = {}) => ({
  type: types.UPLOAD_LENDER_TERMSHEET_FAILURE,
  payload,
});

export const resetTermsheets = (payload = {}) => ({
  type: types.RESET_TERMSHEETS,
  payload,
});
