import styled from 'styled-components/macro';

export const UserRepresentation = styled.span`
  color: #1fbbfd;
  cursor: pointer;
`;

export const TextLink = styled.span`
  cursor: pointer;
  color: #4394ea;
`;

export const ErrorMessage = styled.p`
  color: #ff6f57;
  font-size: 12px;
`;
