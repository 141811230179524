import types from 'constants/actionTypes';
import { paginationTmp } from 'config/paginationTmp';

const initialState = {
  stats: {},
  lenders: [],
  ...paginationTmp,
};

export default function lenderManagementReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case types.GET_LOAN_LENDERS_SUCCESS:
    case types.REVOKE_ACCESS_SUCCESS:
      return {
        ...state,
        lenders: payload.results,
        count: payload.count,
        next: payload.next,
        previous: payload.previous,
        pageNumber: payload.pageNumber,
        pageSize: payload.pageSize,
      };

    case types.GET_LOAN_LENDERS_STATS_SUCCESS:
      return {
        ...state,
        stats: payload.stats,
      };
    case types.LOG_PASS_SUCCESS:
      return {
        ...state,
      };
    case types.RESET_LOAN_LENDERS_MANAGEMENT:
      return initialState;

    default:
      return state;
  }
}
