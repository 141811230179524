import styled, { css } from 'styled-components/macro';

export const HeadingCommon = styled.h3`
  font-family: ${(p) => p.theme.fonts.poppins};
  line-height: ${(p) => p.theme.lineHeights.copy};
  font-size: ${(p) => p.theme.fontSizes[5]}px;
  font-weight: ${(p) => p.theme.fontWeights[2]};
  margin: 0;
  margin-bottom: ${(p) => p.theme.space[2]}px;
  color: ${(p) => p.theme.colors.grays[4]};
`;

export const Heading = styled(HeadingCommon)`
  ${(p) =>
    p.fontSize &&
    css`
      font-size: ${p.theme.fontSizes[p.fontSize]
        ? `${p.theme.fontSizes[p.fontSize]}px`
        : p.fontSize};
    `}

  ${(p) =>
    p.fontWeight &&
    css`
      font-size: ${p.theme.fontWeights[p.fontWeight] || p.fontWeight};
    `}

  ${(p) =>
    (p.mb || typeof p.mb === 'number') &&
    css`
      margin-bottom: ${p.theme.space[p.mb] ? `${p.theme.space[p.mb]}px` : p.mb};
    `}


`;

Heading.displayName = 'Heading';
