import React from 'react';
import styled from 'styled-components/macro';
import { Button } from 'ui/Button';
import { Link } from 'react-router-dom';

export const NotFound = () => (
  <Wrapper>
    <NotFoundTitle>404</NotFoundTitle>
    <NotFoundSubTitle>Sorry, page not found</NotFoundSubTitle>
    <Link to="/">
      <Button>Go to homepage</Button>
    </Link>
  </Wrapper>
);

const Wrapper = styled.div`
  color: #fff;
  position: fixed;
  z-index: 101;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #0091f9;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const NotFoundTitle = styled.p`
  font-size: 88px;
  margin-bottom: 10px;
  margin-top: 0;
`;

const NotFoundSubTitle = styled.p`
  font-size: 36px;
  margin-top: 0;
  margin-bottom: 20px;
`;
