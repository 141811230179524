import React, { useState } from 'react';
import styled from 'styled-components';
import { Input } from 'ui/Input';
import { EyeIcon, EyeStrikethroughIcon } from 'components/SvgIcons';

export const InputPassword = ({ ...rest }) => {
  const [visible, toggleVisibility] = useState(false);
  return (
    <Wrapper>
      <StyledInput
        {...rest}
        type={visible ? 'text' : 'password'}
        passwordVisibilityBtnSlot={
          <ToggleBtn onClick={() => toggleVisibility(!visible)} type="button">
            {visible ? <EyeStrikethroughIcon /> : <EyeIcon />}
          </ToggleBtn>
        }
      />
    </Wrapper>
  );
};

const StyledInput = styled(Input)`
  input {
    padding-right: 40px;
  }
`;

const Wrapper = styled.div`
  position: relative;
`;

const ToggleBtn = styled.button`
  position: absolute;
  right: 10px;
  bottom: 5px;
  margin: 0;
  padding: 0;
  outline: none;
  border: none;
  background-color: transparent;
  cursor: pointer;
  z-index: 10;

  &:hover {
    svg {
      g {
        g {
          fill: #4394ea;
        }
      }
    }
  }
`;
