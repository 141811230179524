import React from 'react';
import { LOAN_STATUS, OFFER_STATE } from 'constants/appRelated';
import { Button } from 'ui/Button';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';

import { LenderTermsheet } from './LenderTermsheet';

const TermSheetActions = styled.div`
  white-space: nowrap;

  @media (max-width: ${(p) => p.theme.breakpoints[0]}px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const LenderPanel = ({
  loan,
  userId,
  accountType,
  openFinalOfferModal,
  onTermDrop,
  openOfferModal,
  setNotIntrested,
  loanBlocked,
}) => {
  const { negotiations, status, dealRooms } = loan;
  const filteredOffers =
    negotiations?.[userId] &&
    negotiations[userId].negotiations.filter(
      ({ state }) => state !== OFFER_STATE.CANCELLED
    );
  const offers = filteredOffers && filteredOffers.length ? filteredOffers : null;
  const acceptedOffer =
    offers &&
    offers.filter((negotiation) => negotiation.state === OFFER_STATE.ACCEPTED)[0];
  const hasDealRooms = dealRooms && dealRooms.length;

  if (hasDealRooms && !!offers?.length) {
    return (
      <div>
        {dealRooms.length === 1 ? (
          <TermSheetActions>
            {acceptedOffer && (
              <LenderTermsheet
                uploadTermsheet={onTermDrop}
                termsheets={acceptedOffer?.termsheets || []}
              />
            )}
            <Link
              to={`/dashboard/property-details/${loan.uuid}/deal-room/${dealRooms[0].id}/all`}
            >
              <Button key="Deal Room">Go to Deal room</Button>
            </Link>
          </TermSheetActions>
        ) : (
          <Link to={`/dashboard/property-details/${loan.uuid}/deal-rooms`}>
            <Button key="Deal Room">Go to Deal rooms</Button>
          </Link>
        )}
      </div>
    );
  }

  if (acceptedOffer) {
    return (
      <TermSheetActions>
        <Button
          onClick={() => {
            openFinalOfferModal(acceptedOffer);
          }}
        >
          View final quote
        </Button>
        <LenderTermsheet
          uploadTermsheet={onTermDrop}
          termsheets={acceptedOffer.termsheets}
        />
      </TermSheetActions>
    );
  }
  if (
    status === LOAN_STATUS.POSTED_TO_MARKETPLACE ||
    status === LOAN_STATUS.PENDING_CLOSING
  ) {
    const offersTitle = offers && offers.length > 1 ? 'View Quotes ' : 'View Quote';
    return (
      <div>
        {!loanBlocked && (
          <Button
            onClick={() =>
              openOfferModal(loan, userId, offers && offers[0].pk, accountType)
            }
          >
            {offers && offers.length ? offersTitle : 'Soft Quote'}
          </Button>
        )}
        {!offers && !loan.passed && (
          <Button data-testid="offer-pass" onClick={setNotIntrested}>
            Pass
          </Button>
        )}
      </div>
    );
  }

  return null;
};
