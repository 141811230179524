import { FIELD_TYPES } from 'constants/appRelated';
import { indexOptions, fixedOrVariableOptions, recourseOptions } from 'utils/fields';

export const offerFields = [
  [
    {
      field: 'loanAmount',
      type: FIELD_TYPES.DOLLARS,
      title: 'Loan Amount',
      required: true,
    },
    { field: 'loanTerm', type: FIELD_TYPES.DURATION, title: 'Term' },
    {
      field: 'interest',
      type: FIELD_TYPES.PERCENT,
      title: 'Rate',
      required: true,
    },
    {
      field: ['index', 'spread'],
      type: 'indexSpread',
      title: 'Index+Spread',
      options: { index: indexOptions },
      placeholder: 'Select Index',
      required: true,
      types: [FIELD_TYPES.CHOICE, FIELD_TYPES.TEXT],
    },
    {
      field: 'fixedOrVariable',
      type: FIELD_TYPES.CHOICE,
      title: 'Fixed/Variable',
      placeholder: 'Select Fixed/Variable',
      options: fixedOrVariableOptions,
    },

    { field: 'interestOnly', type: FIELD_TYPES.DURATION, title: 'Interest Only' },
    {
      field: 'amortization',
      type: FIELD_TYPES.NUMBER,
      title: 'Amortization',
    },
    { field: 'termExtension', type: FIELD_TYPES.TEXT, title: 'Extensions' },
    { field: 'lenderFee', type: FIELD_TYPES.PERCENT, title: 'Lender Fee' },
    {
      field: 'recourse',
      type: FIELD_TYPES.CHOICE,
      title: 'Recourse',
      placeholder: 'Select Recourse',
      options: recourseOptions,
    },
    { field: 'ltv', type: FIELD_TYPES.PERCENT, title: 'LTV' },
    { field: 'ltc', type: FIELD_TYPES.PERCENT, title: 'LTC' },
    { field: 'dscr', type: FIELD_TYPES.FLOAT, title: 'Min. DSCR' },
  ],
  [
    { field: 'debtYield', type: FIELD_TYPES.PERCENT, title: 'Debt Yield' },
    { field: 'exitFee', type: FIELD_TYPES.PERCENT, title: 'Exit Fee' },
    { field: 'floor', type: FIELD_TYPES.TEXT, title: 'Floor' },
    { field: 'prepayment', type: FIELD_TYPES.TEXT, title: 'Prepayment' },

    {
      field: 'escrowReserves',
      type: FIELD_TYPES.TOGGLE,
      title: 'Escrow/Reserves',
      onSelectedTrue:
        'If you have any Escrow/Reserves requirements please specify what they are below in the "Add Comment" section.',
    },

    {
      field: 'index',
      type: FIELD_TYPES.CHOICE,
      title: 'Index',
      hidden: true,
      options: indexOptions,
    },
    {
      field: 'spread',
      type: FIELD_TYPES.TEXT,
      title: 'Spread',
      hidden: true,
    },
  ],
];

export const invalidCommentText =
  'Terms of Service reminder: Providing email or phone number or requesting an email or a phone number is not allowed during this phase of the process. All communication must go through RealAtom platform. Contact information will be shared once there is an approved term sheet.';
