export const asyncActions = [
  'LOAN_DATA',
  'SUBMIT_FOR_VERIFICATION',
  'SUBMIT_OFFER',
  'NEGOTIATE_OFFER',
  'EDIT_OFFER',
  'ACCEPT_OFFER',
  'REJECT_OFFER',
  'POST_QUESTION',
  'POST_ANSWER',
  'SUBMIT_TERMSHEET',
  'CONCIERGE_ACTION',
  'DOWNLOAD_ALL_DOCUMENTS',
  'REQUEST_DOCUMENTS',
  'CANCEL_OFFER',
  'REJECT_TERMSHEET',
  'EXPORT_OFFERS',
  'INVITE_LENDER',
  'UPLOAD_REQUESTED_DOCUMENT',
  'POST_LOAN_NOTE',
  'GET_BROKER_LOAN_ROOM',
  'ACCEPT_CONFIDENTIALITY_AGREEMENT',
  'SUBMIT_MANUAL_QUOTE',
  'DELETE_NEGOTIATION',
  'EXPORT_STATUS_REPORT',
  'CHANGE_PROPERTY_NAME',
  'UPDATE_REQUESTED_DOCUMENT_PROTECTION',
  'SET_LOAN_REMINDER',
  'GET_MOODY_ACCESS_TOKEN',
  'CLOSE_LOAN_ON_THE_PAGE',
];

export const syncActions = ['SET_REDIRECTION_PATH_ON_LOAN_UPDATE'];
