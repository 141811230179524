import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Section, SectionTitle, EditIco } from 'styles/loan';
import { bluredContent } from 'features/LoanNew/constants';
import MoodyLogo from './MoodyLogo';
import styled from 'styled-components/macro';
import { Slider } from 'components/Slider';
import { PropMap } from 'components/Loans';
import PropTypes from 'prop-types';
import { checkIsDevice } from 'lib/utils';
import { cutStringByLength } from 'lib/strings';
import { Text } from 'ui/Text';
import { ButtonText } from 'ui/Button';
import { EditTitleForm } from 'components/Loans';
import { BlurContent } from 'components/BlurContent';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import { getDateDuration } from 'lib/loan';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { showAlert } from 'actions';
import { FLASH_MESSAGES, LENDER } from 'constants/appRelated';

const isDevice = checkIsDevice();

const settings = {
  infinite: true,
  speed: 500,
  slidesToScroll: 1,
  dots: isDevice,
};

export const Preview = React.forwardRef(
  (
    {
      access,
      propertyName,
      address,
      addresses,
      photos,
      firstAddressLat,
      firstAddressLng,
      actionsComponent,
      notificationsComponent,
      producer,
      changePropertyName,
      titleEditable,
      showAdditionAddresses,
      id,
      datePosted,
      reminderSetOn,
      createdBy,
      whenApply,
      highlightPropertyName,
      highlightAddress,
    },
    ref
  ) => {
    const dispatch = useDispatch();
    const hasPhotos = !!photos.length;
    const hasMap = firstAddressLat && firstAddressLng;
    let sliderItems = [];

    const [formVisible, toggleFormVisibility] = useState(false);
    const toggleForm = () => toggleFormVisibility(!formVisible);

    const byLender = createdBy?.accountType === LENDER;
    const { firstName, lastName, companyName } = createdBy || {};
    const lenderData = byLender
      ? `${firstName} ${lastName} from ${companyName} Company`
      : '';

    const showSuccessAlert = () => {
      dispatch(
        showAlert({
          message: 'Loan address copied to clipboard.',
          type: FLASH_MESSAGES.SUCCESS,
        })
      );
    };

    const previewTitle = highlightPropertyName ? 
      <PreviewTitle 
        title={propertyName} 
        dangerouslySetInnerHTML={{__html: highlightPropertyName}}
      />
      : 
      <PreviewTitle title={propertyName}>
        {!formVisible && cutStringByLength(propertyName, 80)}
        {titleEditable && !formVisible && <EditIco onClick={toggleForm} />}
        {formVisible && (
          <EditTitleForm
            initialValues={{ propertyName }}
            toggleForm={toggleForm}
            onFormSubmit={changePropertyName}
          />
        )}
      </PreviewTitle>
    

    if (!hasPhotos) {
      sliderItems = [
        <SliderItem key="stub">
          <StubWrapper>
            <Img src="/images/no_photo_available_big.svg" alt="" />
          </StubWrapper>
          <Img src="/images/no_photo_available_big.svg" alt="" />
          {titleEditable && <EditBtnLink to={`/dashboard/loan-creation/${id}/files`} />}
        </SliderItem>,
      ];
    } else {
      if (hasPhotos) {
        sliderItems = photos.map((photo, i) => (
          <SliderItem key={photo.id}>
            <Img src={photo.loanPage} alt="" />
            {titleEditable && i === 0 && (
              <EditBtnLink to={`/dashboard/loan-creation/${id}/files`} />
            )}
          </SliderItem>
        ));
      }
    }

    if (hasMap) {
      sliderItems = [
        ...sliderItems,
        <SliderItem key="map">
          <PropMap
            isAccessFull={access.full}
            center={{
              lat: parseFloat(firstAddressLat),
              lng: parseFloat(firstAddressLng),
            }}
          />
        </SliderItem>,
      ];
    } else {
      sliderItems = [
        ...sliderItems,
        <SliderItem key="map">
          <Img src="/images/no_map_available.svg" alt="" />
        </SliderItem>,
      ];
    }

    return (
      <PreviewSection ref={ref} data-testid="loanPreview">
        <Content>
          <LoanDescription>
            <div>

              {previewTitle}

              <BlurContent blured={!access.full} height={16}>
                {access.full ? (
                  <AddressRow>
                    {highlightAddress ?
                      <span dangerouslySetInnerHTML={{__html: highlightAddress}} />
                      :
                      <span>{address}</span>
                    }
                    <CopyToClipboard text={address} onCopy={showSuccessAlert}>
                      <CopyToClipboardBtn title="Copy to clipboard" />
                    </CopyToClipboard>
                    {!!addresses?.length && addresses.length >= 2 && (
                      <ButtonText
                        onClick={() => {
                          showAdditionAddresses(addresses);
                        }}
                      >
                        See all locations
                      </ButtonText>
                    )}
                  </AddressRow>
                ) : (
                  bluredContent.address
                )}
                {datePosted && (
                  <DatePostedRow title={format(new Date(datePosted), 'MMM dd, yyyy')}>
                    Posted: <b>{getDateDuration(datePosted)} ago</b>
                  </DatePostedRow>
                )}
              </BlurContent>
              {producer && (
                <Text mt={2}>
                  Producer: <b>{producer}</b>
                </Text>
              )}
              {lenderData && (
                <Text>
                  Sent by {lenderData} Company: <b>{getDateDuration(whenApply)} ago</b>
                </Text>
              )}
            </div>
          </LoanDescription>
          <MoodyLogo reminderSetOn={reminderSetOn}/>
        </Content>
        <PreviewWrapper>
          <WrappedSlider>
            <Slider config={settings} slidesToShow={!isDevice ? 2 : 1}>
              {sliderItems}
            </Slider>
          </WrappedSlider>
          {notificationsComponent}
        </PreviewWrapper>
        <ActionsWrapper>{actionsComponent}</ActionsWrapper>
      </PreviewSection>
    );
  }
);

Preview.displayName = 'Preview';

Preview.propTypes = {
  access: PropTypes.objectOf(PropTypes.bool),
  propertyName: PropTypes.string,
  address: PropTypes.string,
  datePosted: PropTypes.string,
  photos: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  firstAddressLat: PropTypes.string,
  firstAddressLng: PropTypes.string,
  actionsComponent: PropTypes.element,
  notificationsComponent: PropTypes.element,
};

const ActionsWrapper = styled.div`
  display:flex;
  order: 3;
  margin-top: 10px;
  @media (min-width: ${(p) => p.theme.breakpoints[2]}px) {
    display:none;
    order: initial;
    margin-top: initial;
  }

`

const PreviewSection = styled(Section)`
  @media (max-width: ${(p) => p.theme.breakpoints[0]}px) {
    display: flex;
    flex-direction: column;
    background-color: transparent;
    padding: 0;
    box-shadow: none;
    margin-bottom: 40px;
  }
`;

const PreviewTitle = styled(SectionTitle)`
  margin-bottom: 16px;
  position: relative;
`;

const Img = styled.img`
  height: 100%;
  width: 100%;
  outline: none;
  object-fit: cover;
  display: inline-block;
  vertical-align: middle;
`;

const SliderItem = styled.div`
  height: 352px;
  position: relative;
  border-radius: 4px;
  overflow: hidden;
  position: relative;

  @media (max-width: ${(p) => p.theme.breakpoints[0]}px) {
    height: 224px;
  }
`;

const WrappedSlider = styled.div`
  user-select: none;
  margin-bottom: 16px;
  .slick-list {
    margin: 0 -3px;
  }
  .slick-slide {
    height: 352px;
    position: relative;
    padding: 0 3px;
    &:focus,
    &:active {
      outline: none;
    }
    /* &:nth-child(2n) {
      padding-left: 6px;
    } */
  }

  .slick-next,
  .slick-prev {
    background: #fff;
    height: 32px;
    width: 32px;
    border-radius: 50%;
    box-shadow: ${(p) => p.theme.shadow};
    z-index: 2;
    outline: none;
    &::before {
      content: none;
    }
  }

  .slick-next {
    right: 16px;
    background: #fff url('/images/icons/ico_chevron_right_blue.svg') no-repeat center
      center;
  }
  .slick-prev {
    left: 16px;
    background: #fff url('/images/icons/ico_chevron_left_blue.svg') no-repeat center
      center;
  }
  .slick-dots {
    display: none !important;

    li {
      width: auto;
      height: auto;

      button {
        width: 8px;
        height: 8px;
        background-color: #d8d8d8;
        border-radius: 50%;

        &::before {
          content: none;
        }
      }

      &.slick-active {
        button {
          background-color: #ff9e01;
        }
      }
    }
  }

  @media (max-width: ${(p) => p.theme.breakpoints[0]}px) {
    margin-bottom: 0;

    .slick-slide {
      height: 224px;
    }

    .slick-dots {
      display: block !important;
    }

    .slick-arrow.slick-next,
    .slick-arrow.slick-prev {
      display: none !important;
    }
  }
`;

const PreviewWrapper = styled.div`
  position: relative;

  @media (max-width: ${(p) => p.theme.breakpoints[0]}px) {
    order: 2;
  }
`;

const Content = styled.div`
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  margin-bottom:12px;
  @media (max-width: ${(p) => p.theme.breakpoints[0]}px) {
    flex-direction: column;
    align-items: normal;
    order: 1;
    margin-bottom: 16px;
  }
`;

const LoanDescription = styled.div`
  display: flex;
  align-items: flex-start;
  margin-right: auto;

  @media (max-width: ${(p) => p.theme.breakpoints[1]}px) {
    flex-direction: column;
    margin-bottom: 16px;
  }
`;

const StubWrapper = styled.div`
  display: flex;
  background-color: #e8e9ed;
  height: 100%;

  ${Img} {
    max-width: 1012px;
    width: 100%;
    margin: auto;
  }
`;

const AddressRow = styled.div`
  display: flex;
  align-items: center;
  span {
    margin-right: 8px;
  }
`;

const DatePostedRow = styled.div`
  margin-top: 8px;
`;

const EditBtnLink = styled(Link)`
  display: flex;
  width: 37px;
  height: 30px;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.8);
  position: absolute;
  top: 5px;
  right: 5px;

  &::before {
    content: url('/images/icons/ico_pen.svg');
    margin: auto;
    height: 16px;
  }
`;

const CopyToClipboardBtn = styled.button`
  margin: 0 8px 0 0;
  padding: 0;
  outline: none;
  background-color: #fff;
  border: none;
  cursor: pointer;

  &::before {
    content: '';
    display: inline-block;
    background: url('/images/icons/ico_content_copy_24px.svg');
    background-size: cover;
    width: 20px;
    height: 20px;
  }
`;
