import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components/macro';
import { ModalHeader, ModalBody, ModalTitle } from 'ui/Modal/styles';
import { readQuestionRequest } from 'features/Inbox/actions';
import { loanActionsRequest } from 'actions';
import { showModal } from 'actions/modalActions';
import { modals } from 'constants/modalTypes';
import { calculateActionsCount } from 'features/Offers/helpers';
import { isEmpty } from 'ramda';
import { push } from 'connected-react-router';

import { ActionRow, ActionHeader } from './components';
import { makeTermSheetLabel } from './helpers';

const Section = styled.div`
  margin-bottom: 32px;
`;

class LoanActions extends React.Component {
  componentDidMount() {
    const { dispatch, loanId } = this.props;
    dispatch(loanActionsRequest({ id: loanId }));
  }

  openOffer = ({ author, offerPk }) => {
    const { dispatch, loanItem, accountType, afterSubmit } = this.props;
    dispatch(
      showModal(modals.OFFER, {
        item: loanItem,
        author,
        id: offerPk,
        accountType,
        afterSubmit,
      })
    );
  };

  openAnswerModal = (question) => {
    const { dispatch, afterSubmit } = this.props;

    if (question) {
      dispatch(showModal(modals.ANSWER_FORM, { question, afterSubmit }));
      dispatch(readQuestionRequest({ questionPk: question.id }));
    }
  };

  redirectTo = (type) => {
    const {
      dispatch,
      hideModal,
      loanItem: { id },
    } = this.props;

    switch (type) {
      case 'termsheets':
        dispatch(push(`/dashboard/property-details/${id}/termsheets`));
        break;
      case 'loan':
        dispatch(push(`/dashboard/property-details/${id}`));
        break;
      case 'offers':
        dispatch(push(`/dashboard/property-details/${id}/offers`));
        break;
      case 'questions':
        dispatch(push(`/dashboard/property-details/${id}?section=questions`));
        break;

      default:
        break;
    }

    hideModal();
  };

  redirectToTermsheetsPage = () => {
    const {
      dispatch,
      hideModal,
      loanItem: { id },
    } = this.props;
    dispatch(push(`/dashboard/property-details/${id}/termsheets`));
    hideModal();
  };

  render() {
    const { actions } = this.props;
    const actionsCount = calculateActionsCount(actions);

    const { documents, negotiation, newOffer, questions, termsheets } = actions;

    return (
      <div>
        <ModalHeader>
          <ModalTitle>ACTION REQUIRED ({actionsCount})</ModalTitle>
        </ModalHeader>
        <ModalBody>
          {!!questions?.length && (
            <Section>
              <ActionHeader type="questions" label="New questions" />
              <ActionRow
                type="questions"
                action={() => {
                  this.redirectTo('questions');
                }}
              />
            </Section>
          )}
          {!!documents?.length && (
            <Section>
              <ActionHeader
                type="document"
                label={
                  documents.length > 1
                    ? 'Documents were requested'
                    : 'Document was requested'
                }
              />
              <ActionRow
                type="document"
                action={() => {
                  this.redirectTo('loan');
                }}
              />
            </Section>
          )}
          {!!newOffer?.length && (
            <Section>
              <ActionHeader type="offer" label="New quote needs a response" />
              <ActionRow
                type="offer"
                action={() => {
                  this.redirectTo('offers');
                }}
              />
            </Section>
          )}
          {!!negotiation?.length && (
            <Section>
              <ActionHeader type="counteroffer" label="You have a new counteroffer" />
              <ActionRow
                type="counteroffer"
                action={() => {
                  this.redirectTo('offers');
                }}
              />
            </Section>
          )}
          {!isEmpty(termsheets) && (
            <Section>
              <ActionHeader type="termsheet" label={makeTermSheetLabel(termsheets)} />
              <ActionRow type="termsheet" action={this.redirectToTermsheetsPage} />
            </Section>
          )}
        </ModalBody>
      </div>
    );
  }
}

function mapStateToProps(state, props) {
  const { currentUser, loanActions } = state;

  return {
    loanItem: loanActions,
    accountType: currentUser.accountType,
  };
}

export default connect(mapStateToProps)(LoanActions);
