import React, { Component } from 'react';
import { Form, Field } from 'react-final-form';
import { Textarea } from 'components/Form';
import { Button } from 'ui/Button';
import Yup from 'yup';
import { validate } from 'utils/validate';
import { cancelOfferRequest } from 'features/LoanNew/actions';
import { ModalHeader, ModalBody, ModalControls, ModalTitle } from 'ui/Modal/styles';

const schema = Yup.object().shape({
  cancelReason: Yup.string().required('Cancel reason is required!'),
});

export class CancelOffer extends Component {
  formSubmit = (values) => {
    const { dispatch, id, negotiation_pk, hideModal } = this.props;
    hideModal();

    dispatch(
      cancelOfferRequest({
        loanId: id,
        negotiation_pk,
        cancelReason: values.cancelReason,
      })
    );
  };

  render() {
    const { hideModal } = this.props;
    return (
      <Form
        onSubmit={this.formSubmit}
        validate={(values) => validate(values, schema)}
        render={({ handleSubmit, submiting, validating }) => (
          <form onSubmit={handleSubmit}>
            <ModalHeader>
              <ModalTitle>Why do you want to cancel your quote?</ModalTitle>
            </ModalHeader>
            <ModalBody>
              <Field
                component={Textarea}
                placeholder="Please enter the reason"
                label="Cancel reason"
                fullWidth
                name="cancelReason"
                rows={3}
              />
              <ModalControls>
                <Button secondary onClick={hideModal} mr={2}>
                  Cancel
                </Button>
                <Button disabled={submiting || validating} type="submit">
                  Submit
                </Button>
              </ModalControls>
            </ModalBody>
          </form>
        )}
      />
    );
  }
}
