import types from 'constants/actionTypes';
import { merge, findIndex, propEq } from 'ramda';
import immutable from 'object-path-immutable';

const initialState = {
  isFetched: false,
  permissionAsked: false,
  team: [],
  teamData: {},
};

export default function currentUser(state, action = {}) {
  const currentState = state || initialState;
  const { type, payload } = action;

  switch (type) {
    case types.GET_PROFILE_SUCCESS:
    case types.LOGIN_SUCCESS:
    case types.LISTING_LOGIN_SUCCESS:
    case types.PASSWORD_RESET_SUCCESS: {
      return { ...currentState, ...payload, isFetched: true };
    }

    case types.UPDATE_LENDER_APPROVE_STATUS: {
      return { ...currentState, approveStatus: payload.status };
    }
    case types.GET_PROFILE_FAILURE: {
      return { ...currentState, isFetched: true };
    }

    case types.REGISTRATION_SUCCESS:
      return merge(currentState, {
        ...payload,
        isFetched: true,
      });

    case types.BORROWER_REMOVE_MEMBER_SUCCESS: {
      return {
        ...state,
        team: currentState.team.filter((user) => user.id !== action.payload.userId),
      };
    }

    case types.CHANGE_BORROWER_MEMBER_SUCCESS: {
      const { userId, userData } = action.payload;
      const index = findIndex(propEq('id', userId))(currentState.team);
      return immutable(currentState)
        .set(`team.${index}`, userData)
        .value();
    }

    case types.BORROWER_ADD_NEW_MEMBER_SUCCESS: {
      return {
        ...state,
        team: [...currentState.team, action.payload.user],
      };
    }

    case types.TEAM_MEMBER_REGISTRATION_SUCCESS:
    case types.LISTING_REGISTRATION_SUCCESS:
      return merge(currentState, {
        ...action.payload.profile,
      });

    case types.FINISH_REGISTRATION:
    case types.SET_PASSWORD_SUCCESS:
      return merge(currentState, {
        ...(action.payload.profile || {}),
      });

    case types.EMAIL_EXISTS_SUCCESS: {
      return merge(currentState, { emailExists: action.payload.result });
    }

    case types.LOGOUT_SUCCESS: {
      return { ...initialState, isFetched: true };
    }

    case types.GET_USER_INFO_SUCCESS:
    case types.CHANGE_USER_INFO_SUCCESS:
    case types.CHANGE_LENDING_CRITERIA_SUCCESS: {
      return merge(currentState, { ...action.payload });
    }

    case types.FINISH_REGISTRATION_SUCCESS: {
      return merge(currentState, { ...action.payload.profile });
    }
    case types.SUBMIT_PAYMENT_SUCCESS: {
      return {
        ...currentState,
        subscription: 3,
      };
    }

    case types.REQUEST_PERMISSION_SUCCESS: {
      return {
        ...currentState,
        permissionAsked: true,
      };
    }

    case types.CHANGE_TEAM_DATA_SUCCESS:
    case types.REMOVE_TEAM_LOGO_SUCCESS: {
      return {
        ...currentState,
        teamData: { ...currentState.teamData, ...payload.response },
      };
    }

    case types.DISABLE_TFA_SUCCESS:
    case types.CONFIRM_AUTHENTICATOR_DEVICE_SUCCESS: {
      return {
        ...currentState,
        ...action.payload.user,
      };
    }

    case types.RECOVERY_LOGIN_SUCCESS:
    case types.CONFIRM_AUTH_SUCCESS:
      return {
        ...currentState,
        ...action.payload,
      };
    default:
      return currentState;
  }
}
