import React, { Component } from 'react';
import { connect } from 'react-redux';
import { notificationsRequest } from 'actions';
import { Button } from 'ui/Button';
import styled from 'styled-components/macro';

const Wrapper = styled.div`
  padding: 28px 24px 24px;
  position: relative;
  text-align: center;
`;

class RequestNotifications extends Component {
  onSubmit = () => {
    const { dispatch, hideModal } = this.props;
    hideModal();
    //  to receive welcome push message
    dispatch(notificationsRequest({ withWelcomeRequest: true }));
  };

  render() {
    const { accountType, hideModal } = this.props;
    return (
      <Wrapper>
        <div>
          <div>
            <h2>
              {accountType === 3
                ? 'RealAtom would like to send you loan matches, important account activity alerts, and updates'
                : 'RealAtom would like to send you  important account activity alerts, and updates'}
            </h2>
            <h5>
              Notifications can be turned off any time from browser or account settings
            </h5>
          </div>
          <div>
            <Button secondary mr={2} onClick={hideModal}>
              DENY
            </Button>
            <Button onClick={this.onSubmit}>ALLOW</Button>
          </div>
        </div>
      </Wrapper>
    );
  }
}

function select(state) {
  return {
    accountType: state.currentUser.accountType,
  };
}

export default connect(select)(RequestNotifications);
