import { LOAN_SECTIONS } from 'constants/appRelated';
import { mergeDeepRight } from 'ramda';
import * as fields from 'config/fieldsConfig';
/*
  Field types:

  1) text - simple text
  2) dollars - digit field with $999,999 template
  3) choice - select option item, represent as object
  4) toggle - toggle field
  5) percent - digit field with percent formation
  6) duration - simple digit value
  6) durationUnit - represent value of duration as months or year
  7) date - date string
  8) mainAddress - main address of the loan (string representation of the first address in overview)
  9) additionalAddresses - array of addition addresses strings for loan overview
*/

export const sectionsIndexes = {
  [LOAN_SECTIONS.OVERVIEW]: 0,
  [LOAN_SECTIONS.TERMS]: 1,
  [LOAN_SECTIONS.PROFILE]: 2,
  [LOAN_SECTIONS.FILES]: 3,
  [LOAN_SECTIONS.SPONSOR]: 4,
  [LOAN_SECTIONS.SU]: 5,
  [LOAN_SECTIONS.MARKET]: 6,
  [LOAN_SECTIONS.SUMMARY]: 7,
};

export const fieldConfig = mergeDeepRight(fields.fieldsConfig, {
  totalProjectCost: {
    renderCondition: (formValues) => {
      if (formValues && !formValues.hasOwnProperty('isConstructionComponent')) {
        return true;
      }

      return formValues?.isConstructionComponent?.value;
    },
  },
  renovationCosts: {
    renderCondition: (formValues) => {
      if (formValues && !formValues.hasOwnProperty('isConstructionComponent')) {
        return true;
      }

      return formValues?.isConstructionComponent?.value;
    },
  },
  amortizationCustom: {
    renderCondition: (formValues) =>
      formValues.amortization && formValues.amortization.value === 0,
  },
  constrCompletionTiming: {
    renderCondition: ({ isConstructionComponent }, sectionFields) =>
      (sectionFields && sectionFields.indexOf('isConstructionComponent') === -1) ||
      (isConstructionComponent && isConstructionComponent.value),
  },
});

export const overviewFields = [
  'desiredClosingDate',
  'propertyType',
  'loanType',
  'planUseProperty',
  'loanTerm',
  'loanTermUnit',
  'loanAmount',
  'address',
  'additionalAddresses',
  'propertyName',
  'zip',
  'state',
  'city',
  'ownerId',
  'propertyName',
];

export const additionalSourcesStub = [
  'sourceCustom1',
  'sourceCustom2',
  'sourceCustom3',
  'sourceCustom4',
  'sourceCustom5',
  'sourceCustom6',
  'sourceCustom7',
  'sourceCustom8',
  'sourceCustom9',
  'sourceCustom10',
];

export const additionalUsesStub = [
  'useCustom1',
  'useCustom2',
  'useCustom3',
  'useCustom4',
  'useCustom5',
  'useCustom6',
  'useCustom7',
  'useCustom8',
  'useCustom9',
  'useCustom10',
];

export const additionalSourcesFields = {
  sourceCustom1Field: { field: 'sourceCustom1Field', type: 'text' },
  sourceCustom2Field: { field: 'sourceCustom2Field', type: 'text' },
  sourceCustom3Field: { field: 'sourceCustom3Field', type: 'text' },
  sourceCustom4Field: { field: 'sourceCustom4Field', type: 'text' },
  sourceCustom5Field: { field: 'sourceCustom5Field', type: 'text' },
  sourceCustom6Field: { field: 'sourceCustom6Field', type: 'text' },
  sourceCustom7Field: { field: 'sourceCustom7Field', type: 'text' },
  sourceCustom8Field: { field: 'sourceCustom8Field', type: 'text' },
  sourceCustom9Field: { field: 'sourceCustom9Field', type: 'text' },
  sourceCustom10Field: { field: 'sourceCustom10Field', type: 'text' },
};

export const additionalSourcesValues = {
  sourceCustom1Value: { field: 'sourceCustom1Value', type: 'thousand' },
  sourceCustom2Value: { field: 'sourceCustom2Value', type: 'thousand' },
  sourceCustom3Value: { field: 'sourceCustom3Value', type: 'thousand' },
  sourceCustom4Value: { field: 'sourceCustom4Value', type: 'thousand' },
  sourceCustom5Value: { field: 'sourceCustom5Value', type: 'thousand' },
  sourceCustom6Value: { field: 'sourceCustom6Value', type: 'thousand' },
  sourceCustom7Value: { field: 'sourceCustom7Value', type: 'thousand' },
  sourceCustom8Value: { field: 'sourceCustom8Value', type: 'thousand' },
  sourceCustom9Value: { field: 'sourceCustom9Value', type: 'thousand' },
  sourceCustom10Value: { field: 'sourceCustom10Value', type: 'thousand' },
};

export const additionalUsesFields = {
  useCustom1Field: { field: 'useCustom1Field', type: 'text' },
  useCustom2Field: { field: 'useCustom2Field', type: 'text' },
  useCustom3Field: { field: 'useCustom3Field', type: 'text' },
  useCustom4Field: { field: 'useCustom4Field', type: 'text' },
  useCustom5Field: { field: 'useCustom5Field', type: 'text' },
  useCustom6Field: { field: 'useCustom6Field', type: 'text' },
  useCustom7Field: { field: 'useCustom7Field', type: 'text' },
  useCustom8Field: { field: 'useCustom8Field', type: 'text' },
  useCustom9Field: { field: 'useCustom9Field', type: 'text' },
  useCustom10Field: { field: 'useCustom10Field', type: 'text' },
};

export const additionalUsesValues = {
  useCustom1Value: { field: 'useCustom1Value', type: 'thousand' },
  useCustom2Value: { field: 'useCustom2Value', type: 'thousand' },
  useCustom3Value: { field: 'useCustom3Value', type: 'thousand' },
  useCustom4Value: { field: 'useCustom4Value', type: 'thousand' },
  useCustom5Value: { field: 'useCustom5Value', type: 'thousand' },
  useCustom6Value: { field: 'useCustom6Value', type: 'thousand' },
  useCustom7Value: { field: 'useCustom7Value', type: 'thousand' },
  useCustom8Value: { field: 'useCustom8Value', type: 'thousand' },
  useCustom9Value: { field: 'useCustom9Value', type: 'thousand' },
  useCustom10Value: { field: 'useCustom10Value', type: 'thousand' },
};

export const CUSTOM_FIELDS_ACTIONS = {
  CREATE: 'create',
  DELETE: 'delete',
  UPDATE: 'update',
};

export const CUSTOM_FIELDS_SECTIONS = {
  TERMS: 'desired_terms',
  PROFILE: 'property_profile',
};
