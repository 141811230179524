import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components/macro';
import { Section, WidgetTitle } from 'features/DealRoom/styles';

export const DealRoomStatistics = ({roomStatistic, dueDiligenceScore, daysToClosing }) => {
  const [statistic, setStatistic] = useState(null);
  
  useEffect(() => {
    if(roomStatistic || roomStatistic === 0) {
      setStatistic(roomStatistic);
    }
  }, [roomStatistic]);

  return (
    <Section>
      <WidgetTitle>Statistics</WidgetTitle>
      <StatisticsContent>
      <div>
        <StatisticItemTitle>Due diligence</StatisticItemTitle>
        <StatisticItemValue>
          {(statistic === 0 || statistic) ? statistic : dueDiligenceScore}%
        </StatisticItemValue>
      </div>
      <div>
        {typeof daysToClosing === 'number' && daysToClosing < 0 ? (
          <>
            <StatisticItemTitle>DAYS PASSED</StatisticItemTitle>
            <StatisticItemValue variant="passed">
              {Math.abs(daysToClosing)}
            </StatisticItemValue>
          </>
        ) : (
          <>
            <StatisticItemTitle>Days to closing</StatisticItemTitle>
            <StatisticItemValue>{daysToClosing}</StatisticItemValue>
          </>
        )}
      </div>
    </StatisticsContent>
    </Section>
  )
};

const StatisticsContent = styled.div`
  display: flex;
  justify-content: space-between;
  text-align: center;
`;

const StatisticItemTitle = styled.div`
  font-size: 10px;
  line-height: 16px;
  text-transform: uppercase;
  color: #778598;
  margin-bottom: 8px;
`;

const StatisticItemValue = styled.span`
  color: #4394ea;
  font-size: 24px;
  line-height: 28px;
  font-weight: bold;

  ${(p) =>
    p.variant === 'passed' &&
    css`
      color: red;
    `}
`;
