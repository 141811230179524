import React from 'react';
import styled, { css } from 'styled-components/macro';
import Scroll from 'react-scroll';
import { values } from 'ramda';
import { Button } from 'ui/Button';
import { cutStringByLength } from 'lib/strings';
import PropTypes from 'prop-types';

import { NAV_TITLES } from '../constants';

export class LoanNav extends React.Component {
  static propTypes = {
    loan: PropTypes.objectOf(PropTypes.any),
    navList: PropTypes.objectOf(
      PropTypes.shape({
        navIndex: PropTypes.number,
        ref: PropTypes.shape({ current: PropTypes.instanceOf(HTMLDivElement) }),
      })
    ),
    standAloneLoan: PropTypes.bool,
    order: PropTypes.arrayOf(PropTypes.number),
    actions: PropTypes.element,
    variant: PropTypes.string,
    openNotes: PropTypes.func,
    isOwner: PropTypes.bool,
  };

  state = { fixedState: false, currentSectionIndex: null };

  componentDidMount() {
    const params = new URLSearchParams(window.location.search);
    const nav = params.get('nav');
    nav && setTimeout(()=>{ this.goToNav(nav) },0)

    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    const { fixedState } = this.state;
    const { navList } = this.props;

    if (window.pageYOffset > 600 && !fixedState) {
      this.setState({ fixedState: true });
    } else if (window.pageYOffset < 600 && fixedState) {
      this.setState({ fixedState: false, currentSectionIndex: null });
    }

    values(navList).forEach(({ ref, navIndex }) => {
      if (ref.current) {
        const nodePosition = ref.current.getBoundingClientRect().top;
        const nodeHeight = ref.current.clientHeight;
        if (nodePosition <= 120 && nodePosition >= (nodeHeight - 120) * -1) {
          this.setState({ currentSectionIndex: navIndex });
        }
      }
    });
  };

  goToNav = (index) => {
    const element = this.props.navList[index].ref?.current;
    const position = element.getBoundingClientRect().top + window.pageYOffset;
    Scroll.animateScroll.scrollTo(position - 120);
  };

  render() {
    const {
      loan,
      navList,
      standAloneLoan,
      order,
      actions,
      variant,
      openNotes,
      isOwner,
    } = this.props;
    const { fixedState, currentSectionIndex } = this.state;

    const loanThumbnail = loan.photos?.[0]?.thumbnail || '/images/no_photo.svg';

    return (
      <Wrapper fixed={fixedState}>
        <FixedWrapper fixed={fixedState} standAloneLoan={standAloneLoan}>
          <Container>
            {fixedState && (
              <LoanContainer>
                <LoanInfo>
                  <LoanImage
                    style={{
                      backgroundImage: `url(${loanThumbnail})`,
                    }}
                  />
                  <div>
                    <LoanTitle title={loan.propertyName}>
                      {cutStringByLength(loan.propertyName, 86)}
                    </LoanTitle>
                  </div>
                </LoanInfo>
                {actions}
              </LoanContainer>
            )}
            <NavContainer variant={variant}>
              <NavLinks>
                {order.map(
                  (navIndex) =>
                    navList?.[navIndex]?.ref?.current && (
                      <React.Fragment key={navIndex}>
                        <NavItem
                          key={navIndex}
                          data-index={navIndex}
                          highlight={currentSectionIndex === navIndex}
                          onClick={() => this.goToNav(navIndex)}
                        >
                          {NAV_TITLES[navIndex]}
                        </NavItem>
                      </React.Fragment>
                    )
                )}
              </NavLinks>
              <ActionsWrapper>{fixedState ? null : actions}</ActionsWrapper>
              {isOwner && <NotesBtn onClick={openNotes}>NOTES</NotesBtn>}
            </NavContainer>
          </Container>
        </FixedWrapper>
      </Wrapper>
    );
  }
}

const ActionsWrapper = styled.div`
  display:none;
  @media (min-width: ${(p) => p.theme.breakpoints[2]}px) {
    margin-right:20px;
    display: flex;
  } 
`
const Wrapper = styled.div`
  margin-bottom: 24px;
  ${(p) => p.fixed && css`height: 48px;`}
    @media (max-width: ${(p) => p.theme.breakpoints[1]}px) {
    display: none;
  }
`;

const FixedWrapperCommon = styled.div`
  box-shadow: ${(p) => p.theme.shadow};
  background-color: #fff;
`;

const Container = styled.div``;

const NavItemCommon = styled.p`
  padding: 16px 0;
  margin: 0 16px;
  font-size: 10px;
  line-height: 16px;
  color: #778598;
  box-sizing: border-box;
  cursor: pointer;

  &:hover {
    border-bottom: 2px solid #4394ea;
    padding-bottom: 14px;
    color: #4394ea;
  }
`;

const NavItem = styled(NavItemCommon)`
  ${(p) =>
    p.highlight &&
    css`
      border-bottom: 2px solid #4394ea;
      padding-bottom: 14px;
      color: #4394ea;
    `}
`;

const NavContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 48px;

  @media (max-width: ${(p) => p.theme.breakpoints[1]}px) {
    justify-content: flex-end;
  }
`;

const NavLinks = styled.div`
  display: flex;
  flex: 1;

  @media (max-width: ${(p) => p.theme.breakpoints[1]}px) {
    display: none;
  }
`;

const FixedWrapper = styled(FixedWrapperCommon)`
  ${(p) =>
    p.fixed &&
    css`
      @media (min-width: ${(p) => p.theme.breakpoints[1]}px) {
        position: fixed;
        left: ${(p) => (p.standAloneLoan ? '0' : '85px')};
        right: 0;
        top: 0;
        z-index: 101;
        background-color: transparent;
        box-shadow: none;

        &::before {
          content: '';
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          height: 112px;
          box-shadow: ${(p) => p.theme.shadow};
          background-color: #fff;
        }

        ${Container} {
          max-width: 1170px;
          width: 100%;
          margin: 0 auto;
          position: relative;
          padding: 0;
          transform: translateX(${(p) => (p.standAloneLoan ? '0' : '-40px')});

          @media (min-width: 1900px) {
            max-width: 1540px;
          }

          @media (max-width: 1280px) {
            transform: translateX(0);
          }

          @media (max-width: 768px) {
            padding-left: 15px;
            padding-right: 15px;
          }
        }
      }
    `}
`;

const LoanContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 16px 16px 0;

  ${Button}:last-child {
    margin-left: 16px;
  }
`;

const LoanInfo = styled.div`
  display: flex;
  align-items: center;
  margin-right: auto;
`;

const LoanTitle = styled.p`
  margin: 0;
  font-size: 16px;
  line-height: 24px;
`;

const LoanImage = styled.div`
  width: 48px;
  height: 48px;
  margin-right: 8px;
  border-radius: 4px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  flex-shrink: 0;
`;

const NotesBtn = styled.button`
  display: flex;
  align-items: center;
  border: none;
  padding: 0;
  margin-right: 16px;
  outline: none;
  background-color: #fff;
  cursor: pointer;
  font-size: 10px;
  line-height: 16px;
  font-weight: 600;
  color: #4394ea;

  &::before {
    content: url('/images/icons/ico_edit.svg');
    margin-right: 8px;
  }
`;