import React from 'react';
import styled, { css } from 'styled-components/macro';
import { MOBILE_SECTION_VIEW } from 'features/DealRoom/constants';

export const Tabs = ({ activeSection, setActiveSection }) => (
  <Wrapper>
    <TabItem
      active={activeSection === MOBILE_SECTION_VIEW.CHECKLISTS}
      onClick={() => {
        setActiveSection(MOBILE_SECTION_VIEW.CHECKLISTS);
      }}
    >
      Checklists
    </TabItem>
    <TabItem
      active={activeSection === MOBILE_SECTION_VIEW.INFO}
      onClick={() => {
        setActiveSection(MOBILE_SECTION_VIEW.INFO);
      }}
    >
      Deal room info
    </TabItem>
  </Wrapper>
);

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 -15px 24px;
  border-bottom: 1px solid #d8d8d8;

  @media (min-width: ${(p) => p.theme.breakpoints[1]}px) {
    display: none;
  }
`;

const TabItemCommon = styled.button`
  margin: 0 12px;
  color: #758396;
  position: relative;
  border: none;
  padding: none;
  outline: none;
  background-color: transparent;
  padding-bottom: 24px;
`;

const TabItem = styled(TabItemCommon)`
  ${(p) =>
    p.active &&
    css`
      color: #4394ea;

      &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 2px;
        bottom: 0;
        left: 0;
        border-radius: 2px;
        background-color: #4394ea;
      }
    `};
`;
