import React from 'react';
import styled, { css } from 'styled-components/macro';
import PropTypes from 'prop-types';
import { Text } from 'ui/Text';
import { Heading } from 'ui/Heading';
import { Button, ButtonGhost } from 'ui/Button';
import { compose } from 'ramda';
import { INFO_MODAL_TYPE } from 'constants/modalTypes';

const Wrapper = styled.div`
  max-width: ${(p) => p.maxWidth || 420}px;
  width: 100%;
  margin: 0 auto;
  padding: 40px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const Title = styled(Heading)`
  font-size: 24px;
  font-weight: 500;
  padding: 0 30px;
  margin-bottom: 24px;
`;

const CustomText = styled(Text)`
  margin-bottom: 40px;
  line-height: 1.5em;
`;

const AcceptButton = styled(Button)`
  width: 100%;
  background-image: linear-gradient(124deg, #ffe269 -90%, #fd8440 100%);
  font-size: 16px;
  ${(p) =>
    p.confirmation &&
    css`
      width: auto;
      background: #e32019;
      min-width: 102px;
      margin: 0 8px;
      padding: 10px 12px;
      font-size: 16px;
      transition: background, border-color, color, 0.2s ease-in-out;
      &:hover {
        border-color: #c91c16;
        background: #c91c16;
      }
    `};
`;

const ButtonReject = styled(ButtonGhost)`
  min-width: 102px;
  margin: 0 8px;
  font-size: 16px;
  color: #1c3353;
  flex-shrink: 0;
  transition: background, border-color, color, 0.2s ease-in-out;
`;

const InfoIcon = styled.div`
  width: 40px;
  height: 38px;
  margin-bottom: 24px;
  background: url(/images/icons/${(p) => {
        switch (p.type) {
          case INFO_MODAL_TYPE.SUCCESS:
            return 'ico_info_success.svg';
          case INFO_MODAL_TYPE.FAIL:
            return 'ico_info_fail.svg';
          case INFO_MODAL_TYPE.PROCESS:
            return 'ico_info_process.svg';
          case INFO_MODAL_TYPE.WARNING:
            return 'ico_info_warning.svg';

          default:
            return 'ico_info_success.svg';
        }
      }})
    no-repeat center center;
`;

const ConfirmButtonsBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const InfoModal = ({
  hideModal,
  title,
  text,
  infoType,
  onButtonClick,
  onButtonRejectClick,
  buttonText,
  buttonRejectText,
  dispatch,
  confirmation,
  withoutControls,
  maxWidth,
  childComponent
}) => (
  <Wrapper maxWidth={maxWidth}>
    {!!infoType && <InfoIcon type={infoType} />}
    <Title>{title}</Title>
    <CustomText dangerouslySetInnerHTML={{ __html: text }} />

    {!!childComponent && childComponent}

    <ConfirmButtonsBox>
      {buttonRejectText && (
        <ButtonReject
          onClick={
            onButtonRejectClick
              ? compose(
                  () => onButtonRejectClick(dispatch),
                  hideModal
                )
              : hideModal
          }
        >
          {buttonRejectText}
        </ButtonReject>
      )}
      {!withoutControls && (
        <AcceptButton
          confirmation={confirmation}
          onClick={
            onButtonClick
              ? compose(
                  () => onButtonClick(dispatch),
                  hideModal
                )
              : hideModal
          }
        >
          {buttonText || 'Done'}{' '}
        </AcceptButton>
      )}
    </ConfirmButtonsBox>
  </Wrapper>
);

InfoModal.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  onButtonClick: PropTypes.func,
  onButtonRejectClick: PropTypes.func,
  buttonText: PropTypes.string,
  buttonRejectText: PropTypes.string,
  infoType: PropTypes.oneOf([
    INFO_MODAL_TYPE.SUCCESS,
    INFO_MODAL_TYPE.FAIL,
    INFO_MODAL_TYPE.PROCESS,
    INFO_MODAL_TYPE.WARNING,
    undefined,
  ]),
  confirmation: PropTypes.bool,
};
