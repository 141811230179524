import React from 'react';
import styled from 'styled-components';
import { ModalBody, ModalHeading, ModalWithContentWrapper } from 'ui/Modal/styles';

export const LoanAdditionAddresses = ({ addresses }) => (
  <StyledWrapper>
    <ModalHeading>Loan addresses ({addresses.length})</ModalHeading>
    <ModalBody>
      <ul>
        {addresses.map((address) => (
          <AddressItem key={address}>{address}</AddressItem>
        ))}
      </ul>
    </ModalBody>
  </StyledWrapper>
);

const StyledWrapper = styled(ModalWithContentWrapper)`
  ${ModalHeading} {
    padding-bottom: 0;
  }
`;

const AddressItem = styled.li`
  margin-bottom: 8px;
`;
