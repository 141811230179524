import { syncActions as loanActivityWidget } from 'containers/LoanActivityWidget/actionTypes';
import { syncActions as lenderDirectoryActions } from 'features/Directory/pages/LenderDirectory/actionTypes';
import { syncActions as contactsDirectoryActions } from 'features/Directory/pages/ContactsDirectory/actionTypes';
import { syncActions as userDetails } from 'containers/UserDetails/actionTypes';
import { syncActions as modalActions } from 'constants/modalActionTypes';
import { syncActions as profile } from 'features/Profile/actionTypes';
import { syncActions as chatWidget } from 'containers/ChatWidget/actionTypes';
import { syncActions as dealRoom } from 'features/DealRoom/actionTypes';
import { syncActions as marketplace } from 'features/Marketplace/actionTypes';
import { syncActions as chat } from 'features/Chat/actionTypes';
import { syncActions as loanCreation } from 'features/LoanCreation/actionTypes';
import { syncActions as dashboard } from 'features/Dashboard/actionTypes';
import { syncActions as myLoans } from 'features/MyLoans/actionTypes';
import { syncActions as loanLenderManagement } from 'features/LoanLenderManagement/actionTypes';
import { syncActions as loanSendToLenders } from 'features/LoanSendToLenders/actionTypes';
import { syncActions as teamMemberRegistration } from 'features/TeamMemberRegistration/actionTypes';
import { syncActions as compareOffers } from 'features/CompareOffers/actionTypes';
import { syncActions as clientsDirectory } from 'features/Directory/pages/ClientsDirectory/actionTypes';
import { syncActions as recoveryCodes } from 'features/RecoveryCodes/actionTypes';
import { syncActions as twoFactorAuthentication } from 'features/TwoFactorAuthentication/actionTypes';
import { syncActions as confirmAuth } from 'features/ConfirmAuth/actionTypes';
import { syncActions as recoveryLogin } from 'features/RecoveryLogin/actionTypes';
import { syncActions as dashboardActionItems } from 'features/DashboardActionItems/actionTypes';
import { syncActions as loanTermsheets } from 'features/LoanTermsheets/actionTypes';
import { syncActions as marketAnalytics } from 'features/Analytics/pages/MarketAnalytics/actionTypes';
import { syncActions as marketAnalyticsLoans } from 'features/Analytics/pages/MarketAnalyticsLoans/actionTypes';
import { syncActions as filter } from 'features/Filter/actionTypes';
import { syncActions as marketAnalyticsLenders } from 'features/Analytics/pages/MarketAnalyticsLenders/actionTypes';
import { syncActions as myMatchingLoans } from 'features/MyMatchingLoans/actionTypes';
import { syncActions as loanNew } from 'features/LoanNew/actionTypes';
import { syncActions as teamAnalytics } from 'features/Analytics/pages/TeamAnalytics/actionTypes';
import { syncActions as quotesWidget } from 'containers/QuotesWidget/actionTypes';
import { syncActions as myTasks } from 'containers/MyTasksWrapper/actionTypes';
import { syncActions as globalSearch } from 'features/GlobalSearch/actionTypes';

const syncActions = [
  'DISMISS_ALERT',
  'SHOW_ALERT',
  'STOP_WEBSOCKET',
  'ADD_NOTIFICATION',
  'REMOVE_NOTIFICATION',
  'VERIFY_LENDER',
  'SET_NOTIFICATION_COUNT',
  'RESET_LOAN_INFO',
  'UPDATE_LENDER_APPROVE_STATUS',
  'HIDE_SPINNER',
  'SET_CURRENT_URI',
  ...dealRoom,
  ...profile,
  ...lenderDirectoryActions,
  ...contactsDirectoryActions,
  ...userDetails,
  ...modalActions,
  ...chatWidget,
  ...quotesWidget,
  ...marketplace,
  ...chat,
  ...loanCreation,
  ...dashboard,
  ...myLoans,
  ...loanLenderManagement,
  ...loanSendToLenders,
  ...teamMemberRegistration,
  ...compareOffers,
  ...clientsDirectory,
  ...recoveryCodes,
  ...twoFactorAuthentication,
  ...confirmAuth,
  ...recoveryLogin,
  ...dashboardActionItems,
  ...loanTermsheets,
  ...marketAnalytics,
  ...teamAnalytics,
  ...marketAnalyticsLoans,
  ...filter,
  ...marketAnalyticsLenders,
  ...myMatchingLoans,
  ...loanNew,
  ...loanActivityWidget,
  ...myTasks,
  ...globalSearch
];

export default syncActions;
