import React from 'react';
import styled from 'styled-components/macro';
import { ModalBody } from 'ui/Modal/styles';
import { Button } from 'ui/Button';
import { chatFileToLoanRequest } from 'features/Chat/actions';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 24px;
  text-align: center;
`;

const Icon = styled.img`
  margin-bottom: 24px;
`;

const Title = styled.p`
  padding: 0;
  font-size: 24px;
  line-height: 28px;
  margin: 0 0 24px;
`;

const Document = styled.p`
  margin: 0 0 24px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;

  &::before {
    content: url('/images/icons/ico_document.svg');
    margin-right: 16px;
  }
`;

const Description = styled.p`
  margin: 0 0 24px;
  padding: 0;
  font-size: 14px;
  line-height: 20px;
  color: #778598;
  width: 430px;
`;

const Controls = styled.div`
  display: flex;
  justify-content: space-between;
  width: 320px;
`;

export const ChatDocumentModal = ({ hideModal, fileName, id, dispatch }) => (
  <div>
    <ModalBody>
      <Content>
        <Icon src="/images/icons/ico_document_yellow.svg" alt="document_icon" />
        <Title>You have successfully uploaded a document</Title>
        <Document>{fileName}</Document>
        <Description>
          We want to help you make your loan process as smooth as possible. Would you like
          to add this document to your loan request and make it visible to all the invited
          lenders?
        </Description>
        <Controls>
          <Button
            onClick={() => {
              dispatch(chatFileToLoanRequest({ id }));
              hideModal();
            }}
          >
            Add to my loan
          </Button>
          <Button onClick={hideModal}>Keep private</Button>
        </Controls>
      </Content>
    </ModalBody>
  </div>
);
