import React from 'react';
import styled from 'styled-components/macro';
import {
  ModalBody,
  ModalHeading,
  ModalWithContentWrapper,
  ModalActions,
} from 'ui/Modal/styles';
import { Link } from 'react-router-dom';
import { Button, ButtonGhost } from 'ui/Button';
import { ConfidentialityAgreementText } from 'components/ConfidentialityAgreementText';

export const ConfidentialityAgreementModal = ({ hideModal, onButtonClick }) => (
  <ModalWithContentWrapper>
    <ModalHeading>Confidentiality agreement</ModalHeading>
    <ModalBodyStyled>
      <AgreementText>
        <ConfidentialityAgreementText />
      </AgreementText>
      <ControlsLink>
        <ControlLink as={Link} to="/confidentiality-agreement" onClick={hideModal}>
          <img src="/images/icons/ico_print.svg" alt="print" />
          PRINT
        </ControlLink>
        <ControlLink
          href="/confidentiality_agreement.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src="/images/icons/ico_download_blue.svg" alt="download" />
          DOWNLOAD
        </ControlLink>
      </ControlsLink>
    </ModalBodyStyled>
    <Controls>
      <ButtonGhost onClick={hideModal}>Decline</ButtonGhost>
      <Button
        secondary
        onClick={() => {
          hideModal();
          onButtonClick();
        }}
      >
        Accept
      </Button>
    </Controls>
  </ModalWithContentWrapper>
);

const AgreementText = styled.div`
  height: 340px;
  overflow: auto;
  padding: 16px;
  border: 1px solid #cfd0d7;
  border-radius: 4px;
  margin-bottom: 16px;
`;

const ControlsLink = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const ControlLink = styled.a`
  display: flex;
  align-items: center;
  color: #4394ea;
  line-height: 16px;
  font-size: 10px;
  margin-left: 24px;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  img {
    margin-right: 8px;
  }
`;

const Controls = styled(ModalActions)`
  border-top: 1px solid #e8e9ed;
  margin: 0 16px;

  ${Button} {
    margin-left: 16px;
  }
`;

const ModalBodyStyled = styled(ModalBody)`
  display: flex;
  flex-direction: column;

  ${ControlsLink} {
    justify-content: flex-end;
    flex-shrink: 0;
  }
`;
