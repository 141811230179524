import React, { Component } from 'react';
import styled from 'styled-components/macro';
import { Form, Field } from 'react-final-form';
import { Textarea, NewCheckbox } from 'components/Form';
import { postInboxAnswerRequest } from 'actions';
import { Text } from 'ui/Text';
import { ModalHeader, ModalBody, ModalControls, ModalTitle } from 'ui/Modal/styles';
import { Button } from 'ui/Button';

const QuestionText = styled(Text)`
  min-height: 100px;
  border-bottom: 1px dotted #ccc;
  margin-bottom: 16px;
`;

const InputWrapper = styled.div`
  margin-bottom: 8px;
`;

class AnswerForm extends Component {
  submit = ({ answer, isPublic }) => {
    const { question, inbox, dispatch, hideModal, afterSubmit } = this.props;
    const {
      id,
      loan: { id: loanId },
    } = question;
    hideModal();
    dispatch(
      postInboxAnswerRequest({
        answer,
        loanId,
        id,
        inbox,
        isPublic: !isPublic,
        afterSubmit,
      })
    );
  };

  render() {
    // TODO : change logic for is private

    const { hideModal, question } = this.props;
    return (
      <Form
        onSubmit={this.submit}
        initialValues={{ isPublic: true }}
        render={({ handleSubmit, validating }) => (
          <form onSubmit={handleSubmit}>
            <ModalHeader>
              <ModalTitle>Respond Question</ModalTitle>
            </ModalHeader>
            <ModalBody>
              <Text fontSize={1} mb={2}>
                Question:
              </Text>
              <QuestionText>{question && question.question}</QuestionText>
              <InputWrapper>
                <Field
                  component={Textarea}
                  label="Your answer:"
                  name="answer"
                  validate={(value) => (value ? undefined : 'Answer is required')}
                  rows={4}
                />
              </InputWrapper>
              <Field
                component={NewCheckbox}
                label="Make private"
                name="isPublic"
                type="checkbox"
                disabled={!question.isPublic}
              />
              <ModalControls>
                <Button secondary onClick={hideModal} mr={2}>
                  Cancel
                </Button>
                <Button disabled={validating} type="submit">
                  Send
                </Button>
              </ModalControls>
            </ModalBody>
          </form>
        )}
      />
    );
  }
}

export { AnswerForm };
