import React from 'react';
import { ModalHeader, ModalBody, ModalControls, ModalTitle } from 'ui/Modal/styles';
import { Text } from 'ui/Text';
import { Button } from 'ui/Button';
import { FileUpload } from 'components/FileUpload';
import { uploadSignedTermsheetRequest } from 'features/DealRooms/actions';
import { allowedDocTypes } from 'config/allowedDocTypes';
import { DocumentRejectionHandler } from 'components/DocumentRejectionHandler';

export const TermsheetReject = ({
  dispatch,
  hideModal,
  message,
  loanId,
  negotiationId,
  termsheetId,
}) => (
  <div>
    <ModalHeader>
      <ModalTitle>Reject reason</ModalTitle>
    </ModalHeader>
    <ModalBody>
      <Text>{message}</Text>
      <ModalControls>
        <DocumentRejectionHandler>
          {({ handleOnDropReject }) => (
            <FileUpload
              accept={allowedDocTypes.loanDocs}
              onDropRejected={handleOnDropReject}
              onDrop={(files) => {
                const form = new FormData();
                form.append('signed_termsheet', files[0], files[0].name || 'file');

                dispatch(
                  uploadSignedTermsheetRequest({
                    form,
                    loanId,
                    negotiationId,
                    termsheetId,
                  })
                );
                hideModal();
              }}
            >
              <Button>Reattach signed term sheet</Button>
            </FileUpload>
          )}
        </DocumentRejectionHandler>
        <Button secondary onClick={hideModal} ml={2}>
          OK
        </Button>
      </ModalControls>
    </ModalBody>
  </div>
);
