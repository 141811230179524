import React from 'react';

export const DropdownIndicator = () => (
  <div className="Select__indicator Select__dropdown-indicator">
    <svg
      width="12px"
      height="6px"
      viewBox="0 0 12 6"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="elements/input/inactive-dropdown-menu"
          transform="translate(-157.000000, -13.000000)"
          fill="#2F243A"
          fillRule="nonzero"
        >
          <g id="Group-9">
            <path
              d="M162.920327,17.7735282 L168.134027,13.1320347 C168.322181,12.9645309 168.63856,12.9551478 168.840681,13.1110771 C169.042801,13.2670063 169.054124,13.5292006 168.86597,13.6967045 L162.908881,19 L157.128806,13.6919794 C156.943802,13.5220836 156.960016,13.2600658 157.165023,13.1067462 C157.370029,12.9534265 157.686196,12.9668641 157.871201,13.1367598 L162.920327,17.7735282 Z"
              id="Path-7"
            />
          </g>
        </g>
      </g>
    </svg>
  </div>
);
