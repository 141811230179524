import types from 'constants/actionTypes';

const initialState = {
    tasks: [],
    displayVariant: null,
    ordering: null,
    isFetched: false,
    currentTask: {},
    newTasksNumber: null,
    googleLoggedIn: false,
    userProfile: {},
    calendarBannerIsHidden: false,
    googleCalendarList: {
      calendars: [],
      chosenCalendares: []
    },
  };

export default function reducer(state, action = {}) {
    const currentState = state || initialState;
    const { type, payload } = action;

    switch (type) {
      case types.GET_TASKS_MATCHING_LOANS_SUCCESS:
        return {
          ...currentState,
          matchingLoans: payload,
        } 
      case types.GET_TASKS_DATA_SUCCESS:
        return {
          ...currentState,
          tasks: payload.tasks,
        }
      case types.ADD_NEW_TASK_SUCCESS:
        return {
          ...currentState,
          newTask: payload.newTask,
        }
      case types.EDIT_TASK_SUCCESS:
        return {
          ...currentState,
          editedTask: payload.editedTask,
        }
      case types.GET_NUMBER_TASKS_SUCCESS:
        return {
          ...currentState,
          newTasksNumber: payload.unseenTasksCount,
        }
      case types.UPDATE_WEBSOCKET_TASKS:
        return {
          ...currentState,
          newTasksNumber: payload.unseen,
        }
      case types.REMOVE_TASK_SUCCESS:
        return {
          ...currentState,
          removeTask: payload.removeTask,
        }
      case types.GET_TASK_ID_SUCCESS:
        return {
          ...currentState,
          currentTask: payload.currentTask,
        }
      case types.COMPLETE_TASK_SUCCESS:
        return {
          ...currentState,
          tasks: currentState.tasks.map((task) => payload.taskId === task.id ? { ...task, completed: true } : task)
        }
      case types.SEEN_TASK_SUCCESS:
        return {
          ...currentState,
          seenTaskId: payload.taskId,
        }
      case types.HIDE_CALENDAR_BANNER_SUCCESS:
        return {
          ...currentState,
          calendarBannerIsHidden: true,
        }
      case types.ORDERING_UPDATE_TASKS_DATA_REQUEST:
        return {
          ...currentState,
          ordering: payload,
        };
      case types.SET_CHOSEN_CALENDARS_SUCCESS:
        return {
          ...currentState,
          calendarsSaved: payload,
        }
      case types.GET_CALENDARS_SUCCESS:
        return {
          ...currentState,
          googleCalendarList: {
            calendars: payload.calendars, 
            chosenCalendares: payload.chosenCalendares
          },
        }
      default:
        return currentState;
    }

}