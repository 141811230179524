import React from 'react';
import { OfferComment } from 'components/OfferComment';
import { AttachesTitle, AttachesComments } from './styles';

export const OfferComments = ({ messages, isLender, userId }) => (
  <div>
    <AttachesTitle>Comment</AttachesTitle>
    <AttachesComments>
      {messages.map((message) => (
        <OfferComment
          message={message}
          userId={userId}
          isLender={isLender}
          key={message.timestamp}
        />
      ))}
    </AttachesComments>
  </div>
);
