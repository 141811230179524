import types from 'constants/actionTypes';

export const getLoanLendersRequest = ({ loanId, query } = {}) => ({
  type: types.GET_LOAN_LENDERS_REQUEST,
  payload: { loanId, query },
});

export const getLoanLendersSuccess = ({
  results,
  count,
  next,
  previous,
  pageNumber,
  pageSize,
} = {}) => ({
  type: types.GET_LOAN_LENDERS_SUCCESS,
  payload: { results, count, next, previous, pageNumber, pageSize },
});

export const getLoanLendersFailure = (payload = {}) => ({
  type: types.GET_LOAN_LENDERS_FAILURE,
  payload,
});

export const getLoanLendersStatsRequest = ({ loanId } = {}) => ({
  type: types.GET_LOAN_LENDERS_STATS_REQUEST,
  payload: { loanId },
});

export const getLoanLendersStatsSuccess = (payload = {}) => ({
  type: types.GET_LOAN_LENDERS_STATS_SUCCESS,
  payload: { stats: payload },
});

export const getLoanLendersStatsFailure = (payload = {}) => ({
  type: types.GET_LOAN_LENDERS_STATS_FAILURE,
  payload,
});

export const resendLenderInvitationRequest = ({ lenderId, loanId } = {}) => ({
  type: types.RESEND_LENDER_INVITATION_REQUEST,
  payload: { lenderId, loanId },
});
export const resendLenderInvitationSuccess = () => ({
  type: types.RESEND_LENDER_INVITATION_SUCCESS,
});
export const resendLenderInvitationFailure = () => ({
  type: types.RESEND_LENDER_INVITATION_FAILURE,
});

export const revokeAccessRequest = (payload = {}) => ({
  type: types.REVOKE_ACCESS_REQUEST,
  payload,
});
export const revokeAccessSuccess = (payload = {}) => ({
  type: types.REVOKE_ACCESS_SUCCESS,
  payload,
});
export const revokeAccessFailure = (payload = {}) => ({
  type: types.REVOKE_ACCESS_FAILURE,
  payload,
});

export const logPassRequest = (payload = {}) => ({
  type: types.LOG_PASS_REQUEST,
  payload,
});

export const logPassSuccess = (payload = {}) => ({
  type: types.LOG_PASS_SUCCESS,
  payload,
});

export const logPassFailure = (payload = {}) => ({
  type: types.LOG_PASS_FAILURE,
  payload,
});

export const resetLoanLendersManagement = () => ({
  type: types.RESET_LOAN_LENDERS_MANAGEMENT,
});
