export const syncActions = ['SHOW_MODAL', 'HIDE_MODAL', 'UPDATE_OFFER_MODAL', 'POSITION_MODAL'];

export const asyncActions = [
  'GET_SWITCH_TO_MODAL',
  'SWITCH_TO_MEMBER_VIEW',
  'MANUAL_OFFER_MODAL',
  'LOAN_DEAL_WON_MODAL',
  'GET_FIELD_HISTORY_MODAL',
  'MANUAL_TERMSHEET_MODAL',
  'SWITCH_TO_SEARCH',
];
