import React from 'react';
import styled, { css } from 'styled-components/macro';
import { DropdownList, Input } from 'components/Form';
import { Text } from 'ui/Text';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';

export const InputSelect = ({
  error,
  errorText,
  inputPlaceholder,
  selectPlaceholder,
  inputName,
  selectName,
  label,
  parse,
  options,
  value,
  mb,
  hint,
  required,
  semiRequired,
  ...rest
}) => (
  <Wrapper mb={mb}>
    <SelectWrapper error={error} blacklist={['error']}>
      <InputWrapper>
        <Field
          component={Input}
          parse={parse}
          placeholder={inputPlaceholder}
          name={inputName}
          label={label}
          hint={hint}
          required={required}
          semiRequired={semiRequired}
          id={inputName}
          isInputSelect
        />
      </InputWrapper>
      <DropDownWrapper>
        <Field
          component={DropdownList}
          options={options}
          simpleValue
          placeholder={selectPlaceholder}
          name={selectName}
          semiRequired={semiRequired}
          {...rest}
        />
      </DropDownWrapper>
    </SelectWrapper>
    {errorText && <Text color="error">{errorText}</Text>}
  </Wrapper>
);

InputSelect.propTypes = {
  error: PropTypes.bool,
  errorText: PropTypes.string,
};

InputSelect.defaultProps = {
  error: false,
  errorText: '',
};

const Wrapper = styled.div`
  ${(p) =>
    (p.mb || typeof p.mb === 'number') &&
    css`
      margin-bottom: ${p.theme.space[p.mb] ? `${p.theme.space[p.mb]}px` : p.mb};
    `};
`;

const SelectWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  padding: 1px;
  border-radius: 4px;
`;

const InputWrapper = styled.div`
  flex-grow: 1;

  & > div {
    margin-bottom: 0;
    margin-right: -2px;
    position: relative;
    z-index: 10;
  }

  input {
    border-radius: 4px 0 0 4px;
  }
`;

const DropDownWrapper = styled.div`
  flex-shrink: 0;
  min-width: 80px;

  .Select-control {
    height: 100%;
  }

  .Select__control {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
`;
