import {
  differenceInSeconds,
  differenceInMinutes,
  differenceInHours,
  differenceInDays,
  differenceInMonths,
  differenceInYears,
} from 'date-fns';

export function getDateDuration(date) {
  let difference = null;
  const currentDate = new Date();
  const prevDate = new Date(date);
  let postfix = null;

  if ((difference = differenceInYears(currentDate, prevDate))) {
    postfix = 'year';
  } else if ((difference = differenceInMonths(currentDate, prevDate))) {
    postfix = 'month';
  } else if ((difference = differenceInDays(currentDate, prevDate))) {
    postfix = 'day';
  } else if ((difference = differenceInHours(currentDate, prevDate))) {
    postfix = 'hour';
  } else if ((difference = differenceInMinutes(currentDate, prevDate))) {
    postfix = 'minute';
  } else if ((difference = differenceInSeconds(currentDate, prevDate))) {
    postfix = 'second';
  }

  return `${difference} ${postfix}${difference > 1 ? 's' : ''}`;
}
