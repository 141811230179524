import types from 'constants/actionTypes';

export const getMoreClientsRequest = (payload = {}) => ({
  type: types.GET_MORE_CLIENTS_REQUEST,
  payload,
});

export const getMoreClientsSuccess = (payload = {}) => ({
  type: types.GET_MORE_CLIENTS_SUCCESS,
  payload,
});

export const getMoreClientsFailure = (payload = {}) => ({
  type: types.GET_MORE_CLIENTS_FAILURE,
  payload,
});

export const resetDirectory = () => ({
  type: types.RESET_CLIENT_DIRECTORY_DATA,
  payload: {},
});

export const clientDirectoryInviteRequest = (payload = {}) => ({
  type: types.CLIENT_DIRECTORY_INVITE_REQUEST,
  payload,
});

export const newClientInviteSuccess = (payload = {}) => ({
  type: types.NEW_CLIENT_INVITE_SUCCESS,
  payload,
});

export const clientDirectoryInviteSuccess = (payload = {}) => ({
  type: types.CLIENT_DIRECTORY_INVITE_SUCCESS,
  payload,
});

export const clientDirectoryInviteFailure = (payload = {}) => ({
  type: types.CLIENT_DIRECTORY_INVITE_FAILURE,
  payload,
});

export const getClientsDirectoryDataRequest = (payload) => ({
  type: types.GET_CLIENTS_DIRECTORY_DATA_REQUEST,
  payload,
});
export const getClientsDirectoryDataSuccess = (payload = {}) => ({
  type: types.GET_CLIENTS_DIRECTORY_DATA_SUCCESS,
  payload,
});
export const getClientsDirectoryDataFailure = (payload = {}) => ({
  type: types.GET_CLIENTS_DIRECTORY_DATA_FAILURE,
  payload,
});

export const checkClientDirectoryEmailRequest = ({ email, resolve, reject }) => ({
  type: types.CHECK_CLIENT_DIRECTORY_EMAIL_REQUEST,
  payload: { email, resolve, reject },
});
export const checkClientDirectoryEmailSuccess = (payload = {}) => ({
  type: types.CHECK_CLIENT_DIRECTORY_EMAIL_SUCCESS,
  payload,
});
export const checkClientDirectoryEmailFailure = (payload = {}) => ({
  type: types.CHECK_CLIENT_DIRECTORY_EMAIL_FAILURE,
  payload,
});

export const updateClientDirectoryDataRequest = (payload) => ({
  type: types.UPDATE_CLIENT_DIRECTORY_DATA_REQUEST,
  payload,
});
export const updateClientDirectoryDataSuccess = (payload = {}) => ({
  type: types.UPDATE_CLIENT_DIRECTORY_DATA_SUCCESS,
  payload,
});
export const updateClientDirectoryDataFailure = (payload = {}) => ({
  type: types.UPDATE_CLIENT_DIRECTORY_DATA_FAILURE,
  payload,
});
