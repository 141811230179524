import types from 'constants/actionTypes';

export const inboxRequest = (payload = {}) => ({
  type: types.INBOX_REQUEST,
  payload,
});
export const inboxSuccess = (payload = {}) => ({
  type: types.INBOX_SUCCESS,
  payload,
});
export const inboxFailure = (payload = {}) => ({
  type: types.INBOX_FAILURE,
  payload,
});

export const inboxAdditionalDataRequest = (payload = {}) => ({
  type: types.INBOX_ADDITIONAL_DATA_REQUEST,
  payload,
});
export const inboxAdditionalDataSuccess = (payload = {}) => ({
  type: types.INBOX_ADDITIONAL_DATA_SUCCESS,
  payload,
});
export const inboxAdditionalDataFailure = (payload = {}) => ({
  type: types.INBOX_ADDITIONAL_DATA_FAILURE,
  payload,
});

export const readQuestionRequest = (payload = {}) => ({
  type: types.READ_QUESTION_REQUEST,
  payload,
});
export const readQuestionSuccess = (payload = {}) => ({
  type: types.READ_QUESTION_SUCCESS,
  payload,
});
export const readQuestionFailure = (payload = {}) => ({
  type: types.READ_QUESTION_FAILURE,
  payload,
});

export const readAnswerRequest = (payload = {}) => ({
  type: types.READ_ANSWER_REQUEST,
  payload,
});
export const readAnswerSuccess = (payload = {}) => ({
  type: types.READ_ANSWER_SUCCESS,
  payload,
});
export const readAnswerFailure = (payload = {}) => ({
  type: types.READ_ANSWER_FAILURE,
  payload,
});

export const declineQuestionRequest = (payload = {}) => ({
  type: types.DECLINE_QUESTION_REQUEST,
  payload,
});
export const declineQuestionSuccess = (payload = {}) => ({
  type: types.DECLINE_QUESTION_SUCCESS,
  payload,
});
export const declineQuestionFailure = (payload = {}) => ({
  type: types.DECLINE_QUESTION_FAILURE,
  payload,
});
