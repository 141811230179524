import types from 'constants/actionTypes';

export const validateAuthParamsRequest = (payload = {}) => ({
  type: types.VALIDATE_AUTH_PARAMS_REQUEST,
  payload,
});
export const validateAuthParamsSuccess = (payload = {}) => ({
  type: types.VALIDATE_AUTH_PARAMS_SUCCESS,
  payload,
});
export const validateAuthParamsFailure = (payload = {}) => ({
  type: types.VALIDATE_AUTH_PARAMS_FAILURE,
  payload,
});

export const allowAuthRequest = (payload = {}) => ({
  type: types.ALLOW_AUTH_REQUEST,
  payload,
});
export const allowAuthSuccess = (payload = {}) => ({
  type: types.ALLOW_AUTH_SUCCESS,
  payload,
});
export const allowAuthFailure = (payload = {}) => ({
  type: types.ALLOW_AUTH_FAILURE,
  payload,
});
