export const modals = {
  // DEAL_ROOM_REJECTION_REASON: 'DEAL_ROOM_REJECTION_REASON',
  OFFER: 'OFFER',
  VERIFICATION_SUCCESS: 'VERIFICATION_SUCCESS',
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOAN_QUESTION: 'LOAN_QUESTION',
  CANCEL_OFFER: 'CANCEL_OFFER',
  FINAL_OFFER: 'FINAL_OFFER',
  ACCOUNT_NOT_APPROVED: 'ACCOUNT_NOT_APPROVED',
  HIDE_LOAN_FORM: 'HIDE_LOAN_FORM',
  REQUEST_DOCUMENTS_FORM: 'REQUEST_DOCUMENTS_FORM',
  REQUEST_NOTIFICATIONS: 'REQUEST_NOTIFICATIONS',
  ANSWER_MODAL: 'ANSWER_MODAL',
  ANSWER_FORM: 'ANSWER_FORM',
  REJECT_TERMSHEET_FORM: 'REJECT_TERMSHEET_FORM',
  EMAIL_REQUEST_SUCCESS: 'EMAIL_REQUEST_SUCCESS',
  FINISH_REGISTRATION: 'FINISH_REGISTRATION',
  LENDER_COMMENT: 'LENDER_COMMENT',
  EMAIL_ALREADY_CONFIRMED: 'EMAIL_ALREADY_CONFIRMED',
  LOAN_ACTIONS: 'LOAN_ACTIONS',
  TERMSHEET_REJECT: 'TERMSHEET_REJECT',
  LENDER_DIRECTORY_ADD_FORM: 'LENDER_DIRECTORY_ADD_FORM',
  LENDER_DIRECTORY_ADD_SUCCESS: 'LENDER_DIRECTORY_ADD_SUCCESS',
  INFO_MODAL: 'INFO_MODAL',
  INVITE_TO_LOAN_FORM: 'INVITE_TO_LOAN_FORM',
  WELCOME_TO_LOAN_CREATION: 'WELCOME_TO_LOAN_CREATION',
  LOAN_SECTION_CHANGE: 'LOAN_SECTION_CHANGE',
  BORROWER_ACCOUNT_TYPE: 'BORROWER_ACCOUNT_TYPE',
  INVITE_BORROWER_TEAM_MEMBER: 'INVITE_BORROWER_TEAM_MEMBER',
  OFFER_SHARE_MODAL: 'OFFER_SHARE_MODAL',
  QUESTION_FORM: 'QUESTION_FORM',
  CHAT_DOCUMENT_MODAL: 'CHAT_DOCUMENT_MODAL',
  REJECT_OFFER_MODAL: 'REJECT_OFFER_MODAL',
  QUOTE_MODAL: 'QUOTE_MODAL',
  SHARE_LISTING: 'SHARE_LISTING',
  CREATE_GROUP_CHAT_MODAL: 'CREATE_GROUP_CHAT_MODAL',
  LENDER_STATUS_MODAL: 'LENDER_STATUS_MODAL',
  LOAN_SEND_TO_LENDERS_MODAL: 'LOAN_SEND_TO_LENDERS_MODAL',
  LOAN_CLOSE_SELECT: 'LOAN_CLOSE_SELECT',
  LOAN_DEAL_LOST_FORM: 'LOAN_DEAL_LOST_FORM',
  LOAN_DEAL_WON_FORM: 'LOAN_DEAL_WON_FORM',
  ASSIGN_DEAL_MODAL: 'ASSIGN_DEAL_MODAL',
  ADD_CONTACT: 'ADD_CONTACT',
  CONFIDENTIALITY_AGREEMENT_MODAL: 'CONFIDENTIALITY_AGREEMENT_MODAL',
  SWITCH_TO: 'SWITCH_TO',
  MANUAL_OFFER_MODAL: 'MANUAL_OFFER_MODAL',
  RESET_PASSWORD_REQUEST_SUCCESS: 'RESET_PASSWORD_REQUEST_SUCCESS',
  FIELD_HISTORY: 'FIELD_HISTORY',
  DEAL_ROOM_DOCUMENT_REJECT_MODAL: 'DEAL_ROOM_DOCUMENT_REJECT_MODAL',
  DEAL_ROOM_CHECKLIST_MODAL: 'DEAL_ROOM_CHECKLIST_MODAL',
  DEAL_ROOM_DOCUMENT_REJECTION_REASON_MODAL: 'DEAL_ROOM_DOCUMENT_REJECTION_REASON_MODAL',
  DEAL_ROOM_ATTACH_FILES_MODAL: 'DEAL_ROOM_ATTACH_FILES_MODAL',
  DUPLICATE_LOAN: 'DUPLICATE_LOAN',
  ADD_CLIENT: 'ADD_CLIENT',
  FORCE_PASSWORD_CHANGE: 'FORCE_PASSWORD_CHANGE',
  LOCATIONS_SELECTION_LIST: 'LOCATIONS_SELECTION_LIST',
  ASK_PASSWORD: 'ASK_PASSWORD',
  SUGGEST_TFA: 'SUGGEST_TFA',
  LOAN_ADDITION_ADDRESSES: 'LOAN_ADDITION_ADDRESSES',
  MANUAL_TERMSHEET_MODAL: 'MANUAL_TERMSHEET_MODAL',
  LENDER_COMPANY_TEAM_EDITING: 'LENDER_COMPANY_TEAM_EDITING',
  LENDER_COMPANY_TEAM_INVITE: 'LENDER_COMPANY_TEAM_INVITE',
  ASSIGN_LOAN_MODAL: 'ASSIGN_LOAN_MODAL',
  LENDER_LOAN_REMINDER: 'LENDER_LOAN_REMINDER',
  DEAL_ROOM_REQUEST_SIGNATURE_MODAL: "DEAL_ROOM_REQUEST_SIGNATURE_MODAL",
  OPEN_INVITE_COLLEAGUES: "OPEN_INVITE_COLLEAGUES",
  CURRENT_TASK_MODAL: "CURRENT_TASK_MODAL",
  REVIEW_TASK_MODAL: "REVIEW_TASK_MODAL",
  REMOVE_TASK_MODAL: "REMOVE_TASK_MODAL",
  GLOBAL_SEARCH: "GLOBAL_SEARCH",
  SYNC_TASK_MODAL: "SYNC_TASK_MODAL",
  STOP_SYNCING_MODAL: "STOP_SYNCING_MODAL",
};

export const INFO_MODAL_TYPE = {
  SUCCESS: 'success',
  FAIL: 'fail',
  PROCESS: 'process',
  WARNING: 'warning',
};
