import React from 'react';
import styled from 'styled-components/macro';
import { FileUpload } from 'components/FileUpload';
import { Text } from 'ui/Text';
import { DocumentRejectionHandler } from 'components/DocumentRejectionHandler';

export const DocumentForm = ({ requests, onDrop }) =>
  !!requests?.length
    ? requests.map((request) => (
        <UploadWrapper key={`${request.type}${request.label}`}>
          <DocumentRejectionHandler>
            {({ handleOnDropReject }) => (
              <FileUpload
                onDropRejected={handleOnDropReject}
                onDrop={(files) => {
                  if (onDrop) {
                    onDrop(files);
                  }
                }}
              >
                <Wrapper>
                  <DocumentStub />
                  <DocumentType>{request.label}</DocumentType>
                </Wrapper>
              </FileUpload>
            )}
          </DocumentRejectionHandler>
        </UploadWrapper>
      ))
    : null;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  z-index: 1;
`;

const UploadWrapper = styled.div`
  margin-bottom: 12px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const DocumentStub = styled.div`
  width: 40px;
  height: 40px;
  border: 1px dashed #4394ea;
  background: url('/images/icons/notifications/ico_plus.svg') no-repeat center;
  margin-right: 16px;
  flex-shrink: 0;
`;

const DocumentType = styled(Text)`
  font-weight: 600;
`;
