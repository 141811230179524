import React, { useEffect, useState } from 'react';
import {
  ModalBody,
  ModalHeading,
  ModalSubHeading,
  ModalWithContentWrapper,
  ModalActions,
} from 'ui/Modal/styles';
import { UserAvatar } from 'components/UserAvatar';
import { Button } from 'ui/Button';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { analytics } from 'lib/analytics';
import { TRACK, SWITCH_TO_EVENTS } from 'constants/appRelated';

import styled, { css } from 'styled-components';
import { backToTeamViewRequest } from 'actions';
import { switchToMemberViewRequest, switchToSearchRequest } from 'actions/modalActions';
import { Tooltip } from 'ui/Tooltip';
import { Input as SearchInput } from 'ui/Input';
import { IconButton } from 'ui/IconButton';
import { LENDER, LENDER_TEAM_MEMBER_TYPES, LENDER_TEAM_MEMBER_LABELS } from 'constants/appRelated';

import { TeamMembers } from './TeamMembers';

const SwitchTo = ({
  dispatch,
  styles,
  hideModal,
  filteredTeam,
  currentUserId,
  viewedAs,
  accountType,
  searchedTeam,
  isMyTeam,
  currentUser,
}) => {

  const timeoutForSearch = 500;
  const initData = {
    member: {myTeamView: false, id: null, type: ''},
    team: [],
    searchBy: {name: '', typingTimeout: 0}, 
  };

  const [memberId, setMemberId] = useState(initData.member);
  const [team, setTeam] = useState(initData.team);
  const [searchBy, setSearchBy] = useState(initData.searchBy); 

  useEffect(() => {
    if(searchedTeam){
      setTeam(searchedTeam);
    }else if(filteredTeam){
      setTeam(filteredTeam);
    }
  }, [searchedTeam, filteredTeam]);

  const handlerSearchLenderBy = ({target: {value}}) => {
    searchBy.typingTimeout && clearTimeout(searchBy.typingTimeout);
    
    setSearchBy({
      name: value,
      typingTimeout: setTimeout(function () {
        dispatch(switchToSearchRequest(value));
        }, timeoutForSearch)
    });
  };

  const handlerSwitchTo = (memberId) => {
      let arg = memberId.type === "teamId" ? SWITCH_TO_EVENTS.VIEWED_TO_TEAM : SWITCH_TO_EVENTS.VIEWED_TO_USER

      if(memberId.id === currentUserId){
        arg = SWITCH_TO_EVENTS.VIEWED_TO_PERSONAL;
      }

      analytics(TRACK, arg);
      hideModal();
      dispatch(switchToMemberViewRequest(
        {myTeamView: memberId.myTeamView, id: memberId.id, type: memberId.type })
      );
  }
  return (
    <ModalWithContentWrapper style={{margin: '24px', maxHeight: '600px'}}>
      <ModalHeading>Switch view</ModalHeading>
      <ModalSubHeading style={{margin: '0px 20px 33px'}}>
        Choosing an account allows you to view RealAtom platform as that user or the entire team.
      </ModalSubHeading>


      <SearchInputWrapper>
        <SearchInput
          style={{marginBottom: '0px', height: '40px' }}
          className={'switchTo-input'}
          onChange={ handlerSearchLenderBy}
          value={searchBy.name}
          placeholder="Type here to search name"
        />
      </SearchInputWrapper>


      <div style={{overflowY: 'auto'}}>
      <ModalBody className={'modalBody-switcher'} style={{padding: '0px 8px 0 0', margin: '12px 0 0'}}>
        {team.length ? (
          // All Teams
          team.map((member) => (

            accountType === LENDER ? (
              <TeamMembers
                key={member.id}
                userTeam={currentUser.id}
                //isPersonalView={member.id === isMyTeam}
                currentTeam={member}
                memberId={memberId.id} 
                currentUserId={currentUserId} 
                filteredTeam={filteredTeam} 
                setMemberId={setMemberId}
            />
            ):(
              <TeamMembersWrapper key={member.id}>
                <SwitchEntity>
                  <UserAvatar author={member} />
                  <SwitchEntityName>{`${member.firstName} ${member.lastName}`}</SwitchEntityName>
                  {member.userId === currentUserId && (
                    <Tooltip
                      text={'Choose this option to view only your loans and analytics'}
                      position="bottom"
                    />
                  )}
                   <span style={{ color:'#778598', marginLeft:'5px'}}>
                    {Object.keys(LENDER_TEAM_MEMBER_TYPES).map((key, index) => {
                      if(LENDER_TEAM_MEMBER_TYPES[key] === member.permissionsLevel)
                        return LENDER_TEAM_MEMBER_LABELS[LENDER_TEAM_MEMBER_TYPES[key]];
                    })}
                  </span>
                  <SwitchEntityEye
                   onClick={ () => setMemberId({id: member.userId, type: 'memberId'}) }
                  >
                    <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.5 6C0.5 6 3.5 0.5 8 0.5C12.5 0.5 15.5 6 15.5 6C15.5 6 12.5 11.5 8 11.5C3.5 11.5 0.5 6 0.5 6Z" 
                              stroke={member.userId === memberId.id ? "#4394ea" : "#778598"} stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M8 8.5C9.38071 8.5 10.5 7.38071 10.5 6C10.5 4.61929 9.38071 3.5 8 3.5C6.61929 3.5 5.5 4.61929 5.5 6C5.5 7.38071 6.61929 8.5 8 8.5Z"
                              stroke={member.userId === memberId.id ? "#4394ea" : "#778598"} fill={member.userId === memberId.id && "#4394ea"} stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  </SwitchEntityEye>
                </SwitchEntity>
              </TeamMembersWrapper>
            )
          ))
        ) : (
          <span>
            There are no team members in this account. Create your team first from{' '}
            <Link to="/dashboard/profile">User Profile page</Link> and wait until team
            members accept your invitation.
          </span>
        )}
      </ModalBody>
      <ModalActions>
          {!!viewedAs &&
        <Button mr={3} type="button" onClick={
          () => {
            analytics(TRACK, SWITCH_TO_EVENTS.VIEWED_TO_TEAM);
            dispatch(backToTeamViewRequest());
          }
        }>
          Back to team view
        </Button>}

        <Button
          type="submit"
          secondary
          disabled={!memberId.id}
          onClick={() => handlerSwitchTo(memberId)}
        >
          Choose
        </Button>
      </ModalActions>
      </div>
    </ModalWithContentWrapper>
  );
};

function mapStateToProps(state, props) {
  return{
    searchedTeam: state.lenderCompany.filteredTeam,
    currentUser: state.currentUser,
  }
}

export default connect(mapStateToProps)(SwitchTo);

const SearchInputWrapper = styled.div`
  position: relative;
  width: 73%;

  &:before {
    content: '';
    z-index: 3;
    position: absolute;
    left: 12px;
    top: 50%;
    transform: translateY(-50%);
    height: 18px;
    width: 18px;
    background: url('/images/icons/ico_search_gray.svg') no-repeat;
  }

  input {
    padding-left: 40px;
  }

  .switchTo-input{
    margin-bottom: 0px;
  }
`;

const SwitchEntityEye = styled.div`
  margin-top: -5px;
  margin-left: auto;
  cursor: pointer;
`;

const SwitchEntity = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  padding: 10px 0;
  border-radius: 4px;
  background: #fff;
  margin: 0 20px;
`;

const SwitchEntityName = styled.span`
  margin-left: 8px;
  margin-right: 8px;
`;

const TeamMembersWrapper = styled.div`
  margin-bottom: 12px;
  background: #FFFFFF;
  box-shadow: 0px 4px 10px rgba(194, 198, 210, 0.2);
  border-radius: 2px;
  border: 2px solid #fff;
`;