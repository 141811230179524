import React from 'react';
import { any, last } from 'ramda';
import { LOAN_STATUS, OFFER_STATE, TERMSHEET_STATUSES } from 'constants/appRelated';
import { LoanNotification } from 'styles/loan';
import { format } from 'date-fns';

export const BorowerPanel = ({ loan, negotiations }) => {
  const { status, dealRooms, isClosed, closedOn } = loan;
  const notClosedNegotiations = negotiations.filter(
    (negotiation) => negotiation.state !== OFFER_STATE.CANCELLED
  );
  const hasDealRooms = dealRooms && dealRooms.length;

  /*
    DEAL ROOM IS ACTIVE
  */

  if (hasDealRooms) {
    return <LoanNotification variant="success">Deal room was activated</LoanNotification>;
  }

  /*
    LOAN POSTED TO MARKETPLACE
  */

  if (status === LOAN_STATUS.POSTED_TO_MARKETPLACE) {
    /*
      LOAN HAS OFFERS
    */

    if (notClosedNegotiations.length) {
      const hasActivity = any(
        ({ state, lastOfferIsMy }) => state === OFFER_STATE.NEW_OFFER || !lastOfferIsMy,
        negotiations
      );

      const acceptedOffers = negotiations.filter(
        (negotiation) => negotiation.state === OFFER_STATE.ACCEPTED
      );

      const termsheets = acceptedOffers
        .reduce((acc, offer) => [...acc, last(offer.termsheets)], [])
        .filter(Boolean);
      // TODO : create flat termsheets list

      const hasApprovedTermsheet = any(
        ({ status }) => status === TERMSHEET_STATUSES.UPLOADED,
        termsheets
      );

      const hasApprovedSignedTermsheet = any(
        ({ status }) => status === TERMSHEET_STATUSES.BORROWER_SIGNED_TERMSHEET,
        termsheets
      );

      if (hasApprovedSignedTermsheet) {
        return (
          <LoanNotification variant="warn">Awaiting lender checklist</LoanNotification>
        );
      }

      if (hasApprovedTermsheet) {
        return <LoanNotification variant="warn">You have a Term sheet</LoanNotification>;
      }

      if (acceptedOffers.length) {
        return <LoanNotification variant="warn">awaiting term sheet</LoanNotification>;
      }

      if (hasActivity) {
        return <LoanNotification variant="warn">you have new activity</LoanNotification>;
      }

      return (
        <LoanNotification>
          You have {notClosedNegotiations.length} quotes
        </LoanNotification>
      );
    }

    /*
      LOAN DOES NOT HAVE OFFERS
    */

    return (
      <LoanNotification variant="success">Loan is ready to be sent</LoanNotification>
    );
  }

  /*
    LOAN CLOSING
  */

  if (status === LOAN_STATUS.PENDING_CLOSING) {
    return <LoanNotification>Pending closing</LoanNotification>;
  }

  /*
    LOAN CLOSED
  */

  if (isClosed) {
    return (
      <LoanNotification>
        Closed {closedOn && <span> on {format(new Date(closedOn), 'MM-dd-yyyy')}</span>}
      </LoanNotification>
    );
  }
  return null;
};
