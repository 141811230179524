import types from 'constants/actionTypes';

const initialState = {
  notifications: [],
};

export default function notification(state = initialState, action = {}) {
  const { type } = action;
  const currentState = state || initialState;

  switch (type) {
    case types.ADD_NOTIFICATION: {
      return {
        ...currentState,
        notifications: [
          ...currentState.notifications,
          {
            ...action.payload,
          },
        ],
      };
    }

    case types.LOGOUT_SUCCESS: {
      return initialState;
    }

    default:
      return state;
  }
}
