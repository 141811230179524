import React from 'react';
import { getOptionLabel } from 'lib/loan';
import { FIELD_TYPES } from 'constants/appRelated';

import { DisplayNumber } from './DisplayNumber';
import { DisplayTerm } from './DisplayTerm';

const defineValue = ({ type, value, field, options, values, title1, title2 }) => {
  let content;

  switch (type) {
    case FIELD_TYPES.TEXT:
    case FIELD_TYPES.NUMBER:
    case FIELD_TYPES.LIMIT_NUMBER:
    case FIELD_TYPES.FLOAT:
      content = value || 'N/A';
      break;
    case FIELD_TYPES.PERCENT:
      content = value ? <DisplayNumber value={value} percents /> : 'N/A';
      break;
    case FIELD_TYPES.DOLLARS:
      content = value ? <DisplayNumber value={value} /> : 'N/A';
      break;
    case FIELD_TYPES.DURATION:
      content = value ? (
        <DisplayTerm value={value} unit={values[`${field}Unit`]} />
      ) : (
        'N/A'
      );
      break;
    case FIELD_TYPES.TOGGLE:
      content = value ? title2 || 'Yes' : title1 || 'No';
      break;
    case FIELD_TYPES.CHOICE:
      content = getOptionLabel(options, value) || 'N/A';
      break;

    default:
      content = null;
      break;
  }

  return content;
};

export const DisplayValue = ({ row, values, displayValues, newOffer }) => {
  const { type, options, field } = row;
  let content;

  if (type === 'indexSpread') {
    const [field1, field2] = field;
    const [type1, type2] = row.types;
    const value1 = defineValue({
      value: values[field1] || (displayValues && displayValues[field1]),
      options: options[field1],
      type: type1,
    });
    const value2 = defineValue({ value: values[field2], type: type2 });

    content = `${value1}+${value2}`;
  } else if (newOffer && field === 'amortization') {
    content = defineValue({
      value: values.amortizationRepr,
      values,
      type: FIELD_TYPES.DURATION,
      field: 'amortizationRepr',
    });
  } else {
    content = defineValue({ value: values[field], values, ...row });
  }

  return <div>{content}</div>;
};
