import types from 'constants/actionTypes';

import { searchTags } from 'features/GlobalSearch/config';

const initialState = { 
    searchBar: '',
    globalSearchTags: {all: searchTags, chosen: []},
    searchResults: {results: []},
    searchHistory: {recent: []},
    searchCountResults: {
      task: 0,
      loan: 0,
      message: 0,
      people: 0,
      checklist: 0,
      document: 0,
    },
    isMoreScrollSearchResults: true,
 };

export default function reducer(state, action = {}) {
  const currentState = state || initialState;

  const { type, payload } = action;

  switch (type) {
    case types.SET_TAGS: 
      return {
        ...currentState,
        globalSearchTags: payload
      }
    case types.SET_SEARCH_BAR:
      return {
        ...currentState,
        searchBar: payload,
      };
    case types.SET_SHOW_SEARCH_BAR_IN_RESULT_PAGE:
      return {
        ...currentState,
        showSearchBarInResultPage: payload,
      };
    case types.GET_SEARCH_COUNT_SUCCESS:
      return {
        ...currentState,
        searchCountResults: payload
      }; 
    case types.GET_SEARCH_RESULTS_SUCCESS:
      if(payload.nextPageNumber){
        return {
          ...currentState,
          searchResults: {
            ...payload.response, 
            results: [...currentState.searchResults?.results,  ...payload?.response.results]
          }
        }
      }else{
        return {
          ...currentState,
          searchResults: payload
        }
      }
    case types.CLEAR_SEARCH_RESULTS:
      return {
        ...currentState,
        searchResults: initialState.searchResults,
      }
    case types.GET_SEARCH_HISTORY_SUCCESS:
      return {
        ...currentState,
        searchHistory: payload,
      }
    case types.MORE_SCROLL_SEARCH_RESULTS:
      return {
        ...currentState,
        isMoreScrollSearchResults: payload,
      }
    default:
      return currentState;
  }
}
