import React from 'react';
import styled from 'styled-components/macro';
import { FieldArray } from 'react-final-form-arrays';
import { Field } from 'react-final-form';
import { Input } from 'components/Form';
import { BucketIcon } from 'components/SvgIcons';

export const FolderRequest = ({ push }) => (
  <React.Fragment>
    <FieldArray name="requested">
      {({ fields }) =>
        fields.map((name, index) => (
          <InputWrapper key={name}>
            <InputArea>
              <Field
                component={Input}
                name={`${name}.label`}
                mb={0}
                placeholder="Type in document name"
                validate={(value) => (value ? undefined : 'Document name can’t be empty')}
              />
            </InputArea>
            <RemoveBtn
              data-testid="trashIcon"
              type="button"
              onClick={() => {
                fields.remove(index);
              }}
            >
              <BucketIcon />
            </RemoveBtn>
          </InputWrapper>
        ))
      }
    </FieldArray>
    <AddButton
      type="button"
      onClick={() => {
        push('requested', {});
      }}
    >
      + Request more documents
    </AddButton>
  </React.Fragment>
);

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 16px;
  margin-bottom: 16px;
  border-bottom: 1px solid #e8e9ed;
`;

const InputArea = styled.div`
  flex: 1;
`;

const RemoveBtn = styled.button`
  padding: 5px;
  margin: 0 0 0 16px;
  border: 0;
  outline: none;
  background-color: transparent;
  cursor: pointer;
`;

const AddButton = styled.button`
  padding: 0;
  margin: 0;
  outline: none;
  border: none;

  color: #4394ea;
  font-size: 14px;
  line-height: 16px;
  background-color: transparent;
  cursor: pointer;
`;
