import styled from 'styled-components/macro';
import { ButtonExtended } from './Button';

const orange = '255,255,1,';
const blue = '0,145,249,';

export const ButtonFlat = styled(ButtonExtended)`
  width: ${(p) => (p.fullWidth ? 'width: 100%' : 'auto')};
  color: white;
  transition: ${(p) => p.theme.transition};
  background-color: #fff;
  color: ${(p) => (p.secondary ? p.theme.colors.blues[1] : p.theme.colors.oranges[0])};

  &:hover {
    background-color: rgba(${(p) => (p.secondary ? blue : orange)} 0.1);
  }

  &:active {
    background-color: rgba(${(p) => (p.secondary ? blue : orange)} 0.2);
  }
`;

ButtonFlat.displayName = 'ButtonFlat';
