import types from 'constants/actionTypes';

const initialState = {
  authenticatorQrCode: null,
  authenticatorDeviceId: null,
  mobileDeviceId: null,
  mobilePhoneRequest: '',
};

export function twoFactorAuthentication(state, action = {}) {
  const currentState = state || initialState;

  const { type, payload } = action;

  switch (type) {
    case types.REQUEST_QR_CODE_SUCCESS:
      return {
        ...currentState,
        authenticatorQrCode: payload.qrCode,
        authenticatorDeviceId: payload.deviceId,
      };

    case types.REQUEST_SMS_AUTH_SUCCESS:
      return {
        ...currentState,
        mobileDeviceId: payload.deviceId,
        mobilePhoneRequest: payload.number,
      };

    case types.CONFIRM_AUTHENTICATOR_DEVICE_SUCCESS:
    case types.RESET_AUTH_DATA:
    case types.CONFIRM_BY_SMS_SUCCESS:
      return initialState;

    default:
      return currentState;
  }
}
