import React from 'react';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';
import { ModalHeading, ModalActions, ModalWrapper } from 'ui/Modal/styles';
import { Text } from 'ui/Text';
import { Button } from 'ui/Button';
import { APPROVE_STATUSES } from 'constants/appRelated';

const Wrapper = styled(ModalWrapper)`
  text-align: center;
`;

const Actions = styled(ModalActions)`
  padding-bottom: 0;
`;

const StyledText = styled(Text)`
  font-size: 16px;
  margin-bottom: 8px;
`;

const Email = styled.a`
  color: #4394ea;
  text-decoration: none;
`;

export const LenderStatusModal = ({ status, hideModal }) => {
  let content;

  switch (status) {
    case APPROVE_STATUSES.PENDING:
      content = (
        <>
          <StyledText>
            <b>Your account has not been approved yet.</b>
          </StyledText>
          <Text>We will send you an email with further instructions</Text>
        </>
      );
      break;
    case APPROVE_STATUSES.IN_VERIFICATION:
      content = (
        <StyledText>
          <b>To fast forward your account approval</b>, click on the button below to
          provide additional information
        </StyledText>
      );
      break;
    case APPROVE_STATUSES.CLOSED:
      content = (
        <>
          <StyledText>
            <b>Your account has been closed.</b>
          </StyledText>
          <Text>
            Please contact{' '}
            <Email href="mailto:customersupport@realatom.com">
              customersupport@realatom.com
            </Email>{' '}
            if you have any questions
          </Text>
        </>
      );
      break;

    default:
      content = null;
      break;
  }

  return (
    <Wrapper>
      <ModalHeading>Hold on</ModalHeading>
      <StyledText>
        In order to protect the integrity of our borrowers, loan request details are only
        visible to verified lenders.
      </StyledText>
      {content}
      {status === APPROVE_STATUSES.IN_VERIFICATION && (
        <Actions>
          <Link to="/dashboard">
            <Button onClick={hideModal}>Go to dashboard</Button>
          </Link>
        </Actions>
      )}
    </Wrapper>
  );
};
