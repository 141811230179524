import types from 'constants/actionTypes';

export const dashboardDataRequest = (payload = {}) => ({
  type: types.DASHBOARD_DATA_REQUEST,
  payload,
});
export const dashboardDataSuccess = (payload = {}) => ({
  type: types.DASHBOARD_DATA_SUCCESS,
  payload,
});
export const dashboardDataFailure = (payload = {}) => ({
  type: types.DASHBOARD_DATA_FAILURE,
  payload,
});

export const emailConfirmationRequest = (payload = {}) => ({
  type: types.EMAIL_CONFIRMATION_REQUEST,
  payload,
});
export const emailConfirmationSuccess = (payload = {}) => ({
  type: types.EMAIL_CONFIRMATION_SUCCESS,
  payload,
});
export const emailConfirmationFailure = (payload = {}) => ({
  type: types.EMAIL_CONFIRMATION_FAILURE,
  payload,
});

export const setDashboardChatMessageCount = (payload = {}) => ({
  type: types.SET_DASHBOARD_CHAT_MESSAGE_COUNT,
  payload,
});

export const resetDashboardNotifications = (payload = {}) => ({
  type: types.RESET_NOTIFICATIONS,
  payload,
});

export const getLoansDashboardRequest = (payload = {}) => ({
  type: types.GET_LOANS_DASHBOARD_REQUEST,
  payload,
});
export const getLoansDashboardSuccess = (payload = {}) => ({
  type: types.GET_LOANS_DASHBOARD_SUCCESS,
  payload,
});
export const getLoansDashboardFailure = (payload = {}) => ({
  type: types.GET_LOANS_DASHBOARD_FAILURE,
  payload,
});

export const confirmLendingCriteriaRequest = (payload = {}) => ({
  type: types.CONFIRM_LENDING_CRITERIA_REQUEST,
  payload,
});
export const confirmLendingCriteriaSuccess = (payload = {}) => ({
  type: types.CONFIRM_LENDING_CRITERIA_SUCCESS,
  payload,
});
export const confirmLendingCriteriaFailure = (payload = {}) => ({
  type: types.CONFIRM_LENDING_CRITERIA_FAILURE,
  payload,
});
export const setBadBrowserPageShowed = (payload = {}) => ({
  type: types.SET_BADBROWSER_PAGE_SHOWED,
  payload,
});
