export const asyncActions = [
  'MY_MATCHING_LOANS_DATA',
  'UPDATE_MY_MATCHING_LOANS_DATA',
  'HIDE_LOAN',
  'UNHIDE_LOAN',
  'GET_COMPANY_CLIENTS',
  'CLOSE_MATCHING_LOAN',
  'SET_MY_MATCHING_LOANS_DISPLAY_VARIANT',
];

export const syncActions = ['MY_MATCHING_LOANS_ORDERING_UPDATE'];
