import React from 'react';
import styled from 'styled-components/macro';
import { Text } from 'ui/Text';

export const EmptyStub = () => (
  <Wrapper>
    <IconWrapper>
      <img src="/images/icons/ico_list.svg" alt="list" />
    </IconWrapper>
    <Label>Wait for lender to upload a checklist</Label>
  </Wrapper>
);

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Label = styled(Text)`
  font-size: 16px;
  line-height: 24px;
  color: #778598;
`;

const IconWrapper = styled.div`
  position: relative;
  width: 144px;
  height: 144px;
  margin-bottom: 16px;
  border-radius: 100%;
  background-color: rgba(232, 233, 237, 0.2);

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;
