import React from 'react';
import styled from 'styled-components/macro';
import { Text } from 'ui/Text';
import {
  DocumentIcon,
  CheckIcon,
  CrossIcon,
  BellIcon,
  BucketIcon,
} from 'components/SvgIcons';
import { DOCUMENT_STATE } from 'features/DealRoom/constants';
import { MenuButton, MenuButtonItem } from 'ui/MenuButton';
import { FileLink } from 'features/DealRoom/styles';

export const Document = ({
  handlerCAForDocuments,
  name,
  url,
  state,
  docId,
  handleDocumentChangeStatus,
  openRejectReasonModal,
  rejectionReason,
  userType,
  openReUploadFileModal,
  requestId,
  checklistId,
  deleteDealRoomFile,
  deleted,
  restoreDeletedDealRoomFile,
  isDocumentSingle,
  isDdrCompleted,
}) => {
  /*
    Common props for all actions with documents
  */
  const actionProps = {
    'data-id': docId,
    'data-request': requestId,
    'data-checklist': checklistId,
  };

  const AcceptBtn = (
    <MenuButtonItem
      key="accept"
      iconUrl="/images/icons/ico_check_circle_line.svg"
      data-type="accept"
      data-single={isDocumentSingle ? 'true' : ''}
      data-isddruncompleted={isDdrCompleted ? '' : 'true'}
      data-isrejected={state === DOCUMENT_STATE.REJECTED ? 'true' : ''}
      onClick={handleDocumentChangeStatus}
      {...actionProps}
    >
      Accept
    </MenuButtonItem>
  );

  const RejectBtn = (
    <MenuButtonItem
      key="reject"
      iconUrl="/images/icons/ico_close_search.svg"
      data-type="reject"
      onClick={handleDocumentChangeStatus}
      {...actionProps}
    >
      Reject
    </MenuButtonItem>
  );

  const ReUploadBtn = (
    <MenuButtonItem
      key="reUpload"
      iconUrl="/images/icons/ico_upload_blue.svg"
      onClick={openReUploadFileModal}
      {...actionProps}
    >
      Re-upload
    </MenuButtonItem>
  );

  const DeleteBtn = (
    <MenuButtonItem
      key="delete"
      Icon={<BucketIcon color="#4394ea" />}
      onClick={deleteDealRoomFile}
      {...actionProps}
    >
      Delete
    </MenuButtonItem>
  );

  let documentActions;

  /*
    Condition for defining the set of actions for the document
  */

  if (userType.lender) {
    if (state === DOCUMENT_STATE.NEW) {
      documentActions = [AcceptBtn, RejectBtn, DeleteBtn];
    } else if (state === DOCUMENT_STATE.ACCEPTED) {
      documentActions = [RejectBtn, DeleteBtn];
    } else if (state === DOCUMENT_STATE.REJECTED) {
      documentActions = [AcceptBtn, DeleteBtn];
    }
  } else {
    if (state === DOCUMENT_STATE.REJECTED) {
      documentActions = [ReUploadBtn, DeleteBtn];
    }

    documentActions = [DeleteBtn];
  }

  const seeRejectionReason = () => {
    openRejectReasonModal(rejectionReason);
  };

  const restoreDocument = () => {
    restoreDeletedDealRoomFile({ docId, requestId, checklistId });
  };

  return (
    <Wrapper>
      <FileLink
        rel="noopener noreferrer"
        variant={deleted && 'deleted'}
        onClick={() => handlerCAForDocuments(false, false, false, false, url)}
      >
        <DocumentIcon color={deleted && '#979797'} />
        {name}
      </FileLink>
      <LabelStatus
        state={state}
        openRejectReasonModal={openRejectReasonModal}
        rejectionReason={rejectionReason}
        userType={userType}
        deleted={deleted}
        seeRejectionReason={seeRejectionReason}
        restoreDocument={restoreDocument}
      />
      <StyledContextMenu variant="transparent" disabled={deleted}>
        {documentActions || []}
      </StyledContextMenu>
    </Wrapper>
  );
};

const LabelStatus = ({
  state,
  userType,
  deleted,
  seeRejectionReason,
  restoreDocument,
}) => {
  if (deleted) {
    return (
      <Label state="deleted">
        Deleted
        <span>
          (
          <button type="button" onClick={restoreDocument}>
            RESTORE
          </button>
          )
        </span>
        <BucketIcon width="10" height="11" />
      </Label>
    );
  }

  switch (state) {
    case DOCUMENT_STATE.ACCEPTED:
      return (
        <Label state={state}>
          Accepted
          <CheckIcon color="#44AF69" />
        </Label>
      );
    case DOCUMENT_STATE.REJECTED:
      return (
        <Label state={state}>
          Rejected
          <span>
            (
            <button type="button" onClick={seeRejectionReason}>
              SEE REASON
            </button>
            )
          </span>
          <CrossIcon color="#E32019" />
        </Label>
      );
    case DOCUMENT_STATE.NEW:
      return userType.lender ? (
        <Label state={state}>
          New
          <BellIcon color="#FF9E01" />
        </Label>
      ) : null;

    default:
      return null;
  }
};

const Label = styled(Text)`
  display: flex;
  align-items: center;
  font-size: 12px;

  svg {
    margin-left: 8px;
  }

  button {
    color: #4394ea;
    background-color: #fff;
    border: none;
    padding: 0;
    margin: 0;
    outline: none;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  span {
    margin-left: 5px;
    color: ${(p) => p.theme.colors.black};
  }

  color: ${(p) => {
    switch (p.state) {
      case DOCUMENT_STATE.ACCEPTED:
        return '#44AF69';
      case DOCUMENT_STATE.REJECTED:
        return '#E32019';
      case DOCUMENT_STATE.NEW:
        return '#FF9E01';
      case 'deleted':
        return '#979797';
      default:
        return '#FF9E01';
    }
  }};
`;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding-right: 24px;
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const StyledContextMenu = styled(MenuButton)`
  position: absolute;
  top: 50%;
  right: -24px;
  transform: translateY(-50%);
  z-index: 10;
`;
