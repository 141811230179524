import types from 'constants/actionTypes';

const initialState = {
  isFetched: false,
  termsheetsMap: {},
  termsheetsArray: [],
};

export default function loanTermsheets(state, action = {}) {
  const currentState = state || initialState;

  const { type, payload } = action;

  switch (type) {
    case types.GET_LOAN_TERMSHEETS_SUCCESS:
    case types.GET_LENDER_LOAN_TERMSHEETS_SUCCESS:
      return {
        ...currentState,
        termsheetsMap: payload.termsheetsMap,
        termsheetsArray: payload.termsheetsArray,
        isFetched: true,
      };

    case types.SUBMIT_MANUAL_TERMSHEET_SUCCESS:
      return {
        ...currentState,
        termsheetsMap: {
          ...currentState.termsheetsMap,
          [payload.termsheet.id]: payload.termsheet,
        },
        termsheetsArray: [payload.termsheet.id, ...currentState.termsheetsArray],
      };

    case types.EDIT_MANUAL_TERMSHEET_SUCCESS:
      return {
        ...currentState,
        termsheetsMap: {
          ...currentState.termsheetsMap,
          [payload.termsheet.id]: payload.termsheet,
        },
      };

    case types.DELETE_MANUAL_TERMSHEET_REQUEST: // Perform optimistic update
    case types.DELETE_MANUAL_TERMSHEET_SUCCESS:
      return {
        ...currentState,
        termsheetsMap: {
          ...currentState.termsheetsMap,
          [payload.termsheetId]: {
            ...currentState.termsheetsMap[payload.termsheetId],
            deleted: true,
          },
        },
      };

    case types.RESTORE_MANUAL_TERMSHEET_REQUEST: // Perform optimistic update
    case types.RESTORE_MANUAL_TERMSHEET_SUCCESS:
      return {
        ...currentState,
        termsheetsMap: {
          ...currentState.termsheetsMap,
          [payload.termsheetId]: {
            ...currentState.termsheetsMap[payload.termsheetId],
            deleted: false,
          },
        },
      };

    case types.RESET_TERMSHEETS:
      return initialState;

    default:
      return currentState;
  }
}
