import styled, { css } from 'styled-components/macro';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 auto;
`;

export const Body = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  flex: 1 auto;
`;

export const Content = styled.div`
  position: relative;
  width: 63%;
  padding-right: 8%;
  border-right: 1px solid #e8e9ed;

  @media (max-width: ${(p) => p.theme.breakpoints[1]}px) {
    display: none;
    width: 100%;
    border: none;
    padding: 0;

    ${(p) =>
      p.visible &&
      css`
        display: block;
      `}
  }
`;

export const SideBar = styled.div`
  width: 28%;
  padding-left: 24px;

  @media (max-width: ${(p) => p.theme.breakpoints[1]}px) {
    display: none;
    width: 100%;
    padding: 0;

    ${(p) =>
      p.visible &&
      css`
        display: block;
      `}
  }
`;

export const Section = styled.div`
  padding: 24px;
  border-radius: 4px;
  box-shadow: ${(p) => p.theme.shadow};
  background-color: #fff;
  margin-bottom: 16px;
`;

export const FileLink = styled.a`
  display: flex;
  align-items: center;
  margin-right: auto;
  color: #1c3353;
  font-size: 12px;
  line-height: 16px;
  text-decoration: none;
  word-break: break-word;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }

  svg {
    margin-right: 8px;
  }

  ${(p) =>
    p.variant === 'deleted' &&
    css`
      color: #979797;
    `}
`;

export const WidgetTitle = styled.div`
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 16px;
`;
