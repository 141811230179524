import types from 'constants/actionTypes';
import immutable from 'object-path-immutable';
import { findIndex, propEq } from 'ramda';

const initialState = {
  actionItems: [],
  nextActionItems: null,
  actionItemsFilter: '',
  userFeedType: '',
  bbTeammateFilter: '',
};

export function dashboardActionsItems(currentState, action = {}) {
  const state = currentState || initialState;
  const { type, payload } = action;

  switch (type) {
    case types.DASHBOARD_NOTIFICATIONS_SUCCESS:
    case types.UPDATE_USER_DASHBOARD_FEED_TYPE_SUCCESS:
     
    let data = payload.notifications.map(notification =>
        (notification.loan ? 
          {...notification, loan: {...notification.loan, pk: notification.loan.uuid }} : 
          {...notification}
        )
      )
    
      return {
        ...state,
        actionItems: payload.page ? [...state.actionItems, ...data] : [...data],

       
        nextActionItems: payload.next,
        userFeedType: payload.feedType || '',
      };

    case types.DASHBOARD_UPDATE_NOTIFICATIONS_SUCCESS:
    case types.GET_MORE_DASHBOARD_ACTION_ITEMS_SUCCESS:
    case types.FILTER_DASHBOARD_ACTION_ITEMS_REQUEST_SUCCESS:
      return {
        ...state,
        actionItems: payload.page
          ? [...state.actionItems, ...payload.notifications]
          : payload.notifications,
        nextActionItems: payload.next,
      };

    case types.NOTIFICATION_ANSWER_QUESTION_SUCCESS:
      return {
        ...state,
        actionItems: state.actionItems.filter(
          (notification) => notification.pk !== payload.notificationId
        ),
      };

    case types.NOTIFICATION_UPLOAD_DOCUMENT_SUCCESS: {
      const { notificationId, folderId } = payload;

      const index = findIndex(propEq('pk', notificationId))(state.actionItems);
      return immutable.update(state, `actionItems.${index}.body.requests`, (requests) =>
        requests.map((request) =>
          folderId === request.folder ? { ...request, uploaded: true } : request
        )
      );
    }

    case types.NOTIFICATION_UPDATE_DOCUMENT_PROTECTION_SUCCESS: {
      const index = findIndex(propEq('pk', payload.notificationId))(state.actionItems);
      return immutable.update(state, `actionItems.${index}.body.requests`, (requests) =>
        requests.map((request) =>
          payload.folderId === request.folder
            ? { ...request, protected: payload.protected }
            : request
        )
      );
    }

    case types.UPDATE_ACTION_ITEMS_FILTERS:
      return {
        ...state,
        actionItemsFilter: payload,
      };

    case types.SET_USER_FEED_TYPE:
      if (payload === state.userFeedType) {
        return state;
      } else {
        return {
          ...state,
          userFeedType: payload,
          actionItemsFilter: '',
          bbTeammateFilter: '',
        };
      }

    case types.SET_TEAMMATE_ACTION_ITEMS:
      return {
        ...state,
        bbTeammateFilter: payload,
      };

    case types.REMOVE_NOTIFICATION_SUCCESS: {
      return {
        ...state,
        actionItems: state.actionItems.filter(
          (notification) => notification.pk !== Number(payload.id)
        ),
      };
    }

    case types.SIGN_CA_AND_OPEN_DAI_DOC_SUCCESS:
      return {
        ...state,
        actionItems: state.actionItems.map(
          (item) => payload.updatedActionsItems[item.pk] || item
        ),
      };

    default:
      return state;
  }
}
