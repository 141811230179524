import React from 'react';
import styled from 'styled-components/macro';
import { Text } from 'ui/Text';

export const DocumentTypesList = ({ name, children }) => (
  <Wrapper>
    <DocumentHeading>{name}</DocumentHeading>
    {children}
  </Wrapper>
);

const Wrapper = styled.div`
  margin-bottom: 24px;
`;

const DocumentHeading = styled(Text)`
  font-weight: 600;
`;
