export const syncActions = [];

export const asyncActions = [
  'UPDATE_DEAL_ROOM_DOCUMENT_STATE',
  'CREATE_DEAL_ROOM_CHECKLIST',
  'EDIT_DEAL_ROOM_CHECKLIST',
  'ATTACH_DEAL_ROOM_FILES',
  'RE_UPLOAD_DEAL_ROOM_FILE',
  'SET_DEALROOM_CLOSING_DATE',
  'MARK_DEAL_DOCUMENT_COMPLETE',
  'MARK_DEAL_DOCUMENT_INCOMPLETE',
  'DEAL_DOCUMENTS_DOWNLOAD',
  'GET_DEAL_ROOM',
  'DELETE_DEAL_ROOM_FILE',
  'RESTORE_DEAL_ROOM_FILE',
  'DELETE_DEAL_DOCUMENT',
  'RESTORE_DEAL_DOCUMENT',
  'CREATE_DEAL_ROOM_WITH_DOCUMENTS',
  'CREATE_DEAL_ROOM',
  'SAVE_FILES',
  'GET_FILES',
  'SET_UPDATE_FILE',
  'SET_DELETE_FILE',
  'SET_UPLOAD_SIGNED_FILE',
  'SET_REVIEW_FILE',
];
