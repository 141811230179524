import React from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import { Arrow, Eye, Plus } from './icons';

const getIcon = ({ select, hide, add, primary }) => {
  const fill = primary ? '#FFF' : '#8890A2';
  if (select) {
    return <Arrow fill={fill} />;
  } else if (hide) {
    return <Eye fill={fill} />;
  } else if (add) {
    return <Plus fill={fill} />;
  }
  return <Arrow fill={fill} />;
};

const ButtonCommon = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: space-around;
  width: 40px;
  height: 40px;
  padding: 0;
  border-radius: 2px;
  border: none;
  cursor: 'pointer';
`;

const Button = styled(ButtonCommon)`
  background-color: ${(p) =>
    p.primary && (p.hide ? p.theme.colors.blues[1] : p.theme.colors.oranges[0])};

  border: ${(p) =>
    (p.secondary || !p.primary) && `1px dashed ${p.theme.colors.grays[1]}`};
`;

const IconButton = (props) => <Button {...props}>{getIcon({ ...props })}</Button>;

IconButton.propTypes = {
  select: PropTypes.bool,
  hide: PropTypes.bool,
  add: PropTypes.bool,
  primary: PropTypes.bool,
  secondary: PropTypes.bool,
};

IconButton.defaultProps = {
  select: false,
  hide: false,
  add: false,
  primary: false,
  secondary: false,
};

export { IconButton };
