import styled, { css } from 'styled-components/macro';

import { ReactComponent as Icon } from 'icons/ico_tiles_view.svg';

export const TileViewIcon = styled(Icon)`
  ${(p) =>
    p.color &&
    css`
      rect {
        fill: ${p.color};
      }
    `}
`;
