import React from 'react';
import styled, { css } from 'styled-components/macro';
import { UserAvatar } from 'components/UserAvatar';
import { LENDER } from 'constants/appRelated';
import { Text } from 'ui/Text';
import { useDispatch } from 'react-redux';
import {
  getLenderDetailsRequest,
  getBbDetailsRequest,
} from 'containers/UserDetails/actions';
import { UserRepresentation } from 'styles/global';

const userIntials = (firstName = '', lastName = '') =>
  `${firstName.slice(0, 1)}${lastName.slice(0, 1)}`.toUpperCase();

export const UserView = ({
  accountType,
  lenderTypeColor,
  lenderTypeDisplay,
  initials,
  isRealatomLender,
  name = '',
  companyName,
  id,
  variant,
  Badge: BbBadge,
  registered,
}) => {
  const dispatch = useDispatch();
  const action = accountType === LENDER ? getLenderDetailsRequest : getBbDetailsRequest;

  return (
    <Wrapper
      variant={variant}
      onClick={id ? () => dispatch(action(id)) : null}
      title={registered === false && 'Not registered'}
    >
      <AvatarWrapper>
        <UserAvatar
          author={{
            accountType,
            lenderTypeColor,
            initials: initials || userIntials.apply(null, name.split(/\s+/)),
            registered,
          }}
          size={variant}
        />
        {accountType === LENDER && isRealatomLender && <RealatomBadge />}
      </AvatarWrapper>
      <div>
        {id ? (
          <UserRepresentation title={name}>{name}</UserRepresentation>
        ) : (
          <UserNameCommon title={name}>{name}</UserNameCommon>
        )}
        <div>{companyName && <UserSubInfo>{companyName}</UserSubInfo>}</div>
        <div>
          {!!lenderTypeDisplay && <Badge bg={lenderTypeColor}>{lenderTypeDisplay}</Badge>}
          {BbBadge && <BadgeWrapper>{BbBadge}</BadgeWrapper>}
        </div>
      </div>
    </Wrapper>
  );
};

const WrapperCommon = styled.div`
  display: flex;
  align-items: center;
`;

const AvatarWrapper = styled.div`
  position: relative;
  margin-right: 16px;
`;

const CommonString = styled(Text)`
  font-weight: ${(p) => p.theme.fontWeights[2]};
  line-height: 16px;
  margin-bottom: 1px;
`;

export const UserNameCommon = styled(CommonString)`
  font-size: 14px;
  max-width: 240px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const UserSubInfo = styled(CommonString)`
  font-size: 12px;
  color: #778598;
`;

const RealatomBadge = styled.div`
  width: 20px;
  height: 21px;
  background: url('/images/icons/ico_realatom_small.svg') no-repeat;
  position: absolute;
  bottom: -8px;
  right: -8px;
`;

const BadgeWrapper = styled.div`
  margin-top: 4px;
`;

export const Badge = styled.div.attrs((props) => ({
  style: {
    background: props.bg || '#0267C1',
  },
}))`
  display: inline-block;
  margin-top: 4px;
  padding: 2px 8px;
  border-radius: 3px;
  font-size: 12px;
  line-height: 14px;
  font-weight: 600;
  color: #fff;
  text-transform: capitalize;
`;

const Wrapper = styled(WrapperCommon)`
  ${(p) =>
    p.variant &&
    p.variant === 'big' &&
    css`
      ${RealatomBadge} {
        bottom: -4px;
        right: -1px;
      }

      ${UserSubInfo} {
        font-size: 14px;
      }
    `}

  ${(p) =>
    p.disabled &&
    css`
      opacity: 0.3;
      cursor: not-allowed;
    `}
`;
