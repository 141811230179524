import React from 'react';
import styled from 'styled-components/macro';
import { Button } from 'ui/Button';

const Wrapper = styled.div`
  text-align: right;

  ${Button} {
    margin-right: 15px;
  }
`;

export const Controls = ({ hideModal, userType, editQuote, edit }) => (
  <Wrapper>
    {userType.lender && (
      <React.Fragment>
        {edit && <Button type="submit">Submit</Button>}
        {!edit && (
          <Button type="button" onClick={editQuote}>
            Edit
          </Button>
        )}
      </React.Fragment>
    )}
    <Button secondary type="button" onClick={hideModal}>
      Close
    </Button>
  </Wrapper>
);
