import React, { useState, useRef } from 'react';
import styled, { css } from 'styled-components/macro';
import { DotsIcon } from 'components/SvgIcons';
import { Portal } from 'features/Analytics/components';
import { useClickOutside } from 'lib/hooks';

const CONTENT_OFFSET_Y = 40;
const CONTENT_OFFSET_X = 48;
const CONTENT_WIDTH = 190;

export const MenuButton = ({ children, disabled, variant }) => {
  const ref = useRef();

  const [elemCoordinates, setElemCoordinates] = useState({});
  const [menuVisible, setMenuVisibility] = useState(false);
  const toggleVisibility = () => setMenuVisibility(!menuVisible);
  const hideMenu = () => setMenuVisibility(false);

  useClickOutside(ref, (e) => {
    if (e?.target?.dataset?.type !== 'MenuButton') hideMenu();
  });

  return (
    <Wrapper disabled={disabled} variant={variant}>
      <ContextMenuBtn
        data-type="MenuButton"
        data-testid="MenuButton"
        onClick={(e) => {
          const { left, y } = e.currentTarget.getBoundingClientRect();
          toggleVisibility();
          setElemCoordinates({ top: y + window.scrollY, left });
        }}
      >
        <DotsIcon
          color={(disabled || (variant === 'transparent' && !menuVisible)) && '#CFD0D7'}
        />
      </ContextMenuBtn>

      {menuVisible && (
        <Portal>
          <MenuButtonContent
            top={elemCoordinates.top}
            left={elemCoordinates.left}
            ref={ref}
            className="menu-button"
          >
            {typeof children === 'function' ? (
              <ItemWrapper onClick={hideMenu}>{children}</ItemWrapper>
            ) : (
              React.Children.map(children, (child) =>
                child ? <ItemWrapper onClick={hideMenu}>{child}</ItemWrapper> : null
              )
            )}
          </MenuButtonContent>
        </Portal>
      )}
    </Wrapper>
  );
};

const ContextMenuBtn = styled.button`
  position: relative;
  width: 48px;
  height: 32px;
  border-radius: 4px;
  border: 1px solid #cfd0d7;
  outline: none;
  background-color: #fff;
  cursor: pointer;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
  }

  svg {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -60%);
  }

  &:hover {
    border-color: #4394ea;
  }
`;

const MenuButtonContent = styled.div`
  position: absolute;
  background: #fff;
  border-radius: 4px;
  padding: 16px;
  z-index: 333;
  box-shadow: ${(p) => p.theme.shadow};
  width: ${CONTENT_WIDTH}px;
  left: ${(p) => p.left - CONTENT_WIDTH + CONTENT_OFFSET_X}px;
  top: ${(p) => p.top + CONTENT_OFFSET_Y}px;
`;

const Wrapper = styled.div`
  ${(p) =>
    p.disabled &&
    css`
      ${ContextMenuBtn} {
        pointer-events: none;
      }
    `}

  ${(p) =>
    p.variant &&
    p.variant === 'transparent' &&
    css`
      ${ContextMenuBtn} {
        border: none;

        &:hover {
          svg path {
            fill: #4394ea;
          }
        }
      }
    `}
`;

const ItemWrapper = styled.div`
  margin-bottom: 16px;
`;
