import React from 'react';
import styled from 'styled-components/macro';
import { PlusIcon } from 'components/SvgIcons';

const ButtonStyled = styled.button`
  display: flex;
  align-items: center;
  padding: 0;
  border: none;
  outline: none;
  color: ${(p) => p.color || p.theme.colors.black};
  font-size: 14px;
  background-color: transparent;
  cursor: pointer;

  svg {
    margin-right: 1em;
  }
`;

export const ButtonAdd = ({ children, color, ...rest }) => (
  <ButtonStyled color={color} {...rest}>
    <PlusIcon color={color} />
    {children}
  </ButtonStyled>
);
