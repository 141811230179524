import { put, takeLatest, call, select } from 'redux-saga/effects';
import types from 'constants/actionTypes';
import * as API from 'api';
import { push } from 'connected-react-router';
import { getError } from 'sagas/utils';
import {
  LENDER,
  LOAN_ACCESS_LEVEL,
  STANDALONE_LOAN_EVENTS,
  TRACK,
} from 'constants/appRelated';
import { modals, INFO_MODAL_TYPE } from 'constants/modalTypes';
import { showModal } from 'actions/modalActions';
import { analytics } from 'lib/analytics';

import { standaloneLoanDataSuccess, standaloneLoanDataFailure } from './actions';

function* getLoan(action) {
  const { id, uuid, uid, attachToUser } = action.payload;

  try {
    const {
      currentUser: { email, accountType },
    } = yield select();

    if (email) {
      if (id) {
        yield put(push(`/dashboard/property-details/${id}`));
      } else if (uuid) {
        let previewLoan = null;

        if (uid) {
          previewLoan = yield call(API.axiosApi.get, `/loans/preview/${uid}`);
        } else if (uuid && id) {
          previewLoan = yield call(API.axiosApi.get, `/loans/preview/${id}?${uuid}`);
        } else if (uuid) {
          previewLoan = yield call(API.axiosApi.get, `/loans/preview/${uuid}`);
        }

        if (previewLoan) {
          if (accountType === LENDER) {
            if (attachToUser) {
              yield call(API.axiosApi.post, `/auth/attach-loan/${uuid || uid}/`);
            }
            yield put(
              push(`/dashboard/property-details/${previewLoan.data.response.uuid}`)
            );
          } else if (previewLoan.data.response.accessLevel === LOAN_ACCESS_LEVEL.FULL) {
            yield put(
              push(`/dashboard/property-details/${previewLoan.data.response.uuid}`)
            );
          } else {
            yield put(push('/dashboard'));
          }
        }
      }
    } else {
      // id - standalone , no id - email campaign

      const requestUrl = id
        ? `/loans/preview/${id}?uuid=${uuid}`
        : `/loans/preview/${uuid || uid}`;

      const {
        data: { response },
      } = yield call(API.axiosApi.get, requestUrl);

      const { owner } = response;
      if (owner) {
        yield put(
          showModal(modals.INFO_MODAL, {
            size: 'sm',
            infoType: INFO_MODAL_TYPE.WARNING,
            title: `Loan from ${owner.firstName} ${owner.lastName}`,
            text: `This loan was shared with you by  <b>${owner.firstName} ${
              owner.lastName
            }</b>${
              owner.businessName ? ` from <b>${owner.businessName}</b>` : ''
            }. Log in/Sign up to view loan details, respond and receive future packages.
              <br/> <br/>
             <i> Note: Info contained in the loan package is private and confidential and is not to be shared without prior approval by the sender.</i>`,
            buttonText: 'View Loan',
            onModalHide: () => {
              analytics(TRACK, STANDALONE_LOAN_EVENTS.STANDALONE_LOAN_INFO_POPUP_CLOSE);
            },
          })
        );
      }

      yield put(standaloneLoanDataSuccess(response));
    }
  } catch (e) {
    console.error(e);
    yield put(standaloneLoanDataFailure({ message: getError(e) }));
    yield put(push('/dashboard'));
  }
}

export default [takeLatest(types.STANDALONE_LOAN_DATA_REQUEST, getLoan)];
