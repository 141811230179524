import React from 'react';
import styled from 'styled-components';
import { differenceInMinutes } from 'date-fns';
import { Text } from 'ui/Text';

export const LenderReminder = ({ timestamp }) => {
  const minutes = differenceInMinutes(new Date(timestamp), new Date());

  if (minutes <= 0) {
    return null;
  }

  let time;

  if (minutes <= 60) {
    time = `${minutes} minutes`;
  } else if (minutes <= 1440) {
    time = `${Math.floor(minutes / 60)} hours`;
  } else if (minutes <= 10080) {
    time = `${Math.floor(Math.floor(minutes / 60) / 24)} days`;
  }
  return <Wrapper>Reminder in {time}</Wrapper>;
};

const Wrapper = styled(Text)`
  color: #44af69;
  font-size: 12px;
`;
