import queryString from 'query-string';
import { camelizeKeys, camelize } from 'humps';
import { values } from 'ramda';
import { FIELD_TYPES } from 'constants/appRelated';

export const makeObjFromUrlString = ({ uri, config }) => {
  const uriObj = camelizeKeys(queryString.parse(uri));

  const data = {};
  let orderingType = null;

  if (config) {
    const sections = values(config);

    sections.forEach((section) => {
      const { name, type, urlName } = section;
      const camelizeUrlName = camelize(urlName);

      switch (type) {
        case FIELD_TYPES.CHOICE_MULTI:
          if (uriObj[camelizeUrlName]) {
            data[name] = uriObj[camelizeUrlName].split(',');
          }
          break;

        case FIELD_TYPES.CHOICE:
        case FIELD_TYPES.TEXT:
          if (uriObj[camelizeUrlName]) {
            data[name] = uriObj[camelizeUrlName];
          }
          break;

        case FIELD_TYPES.NUMBER:
          if (uriObj[camelizeUrlName] !== null && uriObj[camelizeUrlName] !== undefined) {
            data[name] = uriObj[camelizeUrlName];
          }
          break;

        case FIELD_TYPES.RANGE:
        case FIELD_TYPES.DURATION_UNIT:
          if (uriObj[`${camelizeUrlName}Min`]) {
            data[`${camelizeUrlName}Min`] = uriObj[`${camelizeUrlName}Min`];
          }
          if (uriObj[`${camelizeUrlName}Max`]) {
            data[`${camelizeUrlName}Max`] = uriObj[`${camelizeUrlName}Max`];
          }
          break;

        case FIELD_TYPES.TOGGLE:
        case FIELD_TYPES.BOOLEAN:
        case FIELD_TYPES.CHOICE_BOOLEAN:
          if (
            uriObj[camelizeUrlName] &&
            (uriObj[camelizeUrlName] === 'true' || uriObj[camelizeUrlName] === 'false')
          ) {
            data[name] = uriObj[camelizeUrlName] === 'true' || false;
          }
          break;

        case FIELD_TYPES.DATE:
          if (uriObj[`${camelizeUrlName}Before`]) {
            data[`${camelizeUrlName}Before`] = uriObj[`${camelizeUrlName}Before`];
          }
          if (uriObj[`${camelizeUrlName}After`]) {
            data[`${camelizeUrlName}After`] = uriObj[`${camelizeUrlName}After`];
          }
          break;

        default:
          break;
      }
    });
  }

  if (uriObj.ordering) {
    orderingType = uriObj.ordering;
  }

  return { data, orderingType };
};
