export function getCookie(name) {
  const cookieArr = document.cookie.split(";");

  for(let i = 0; i < cookieArr.length; i++) {
    const cookiePair = cookieArr[i].split("=");
    if(name === cookiePair[0].trim()) {
      return decodeURIComponent(cookiePair[1]);
    }
  }
  return null;
}

export function setCookie(key, value, expiresTime = 0, visiblePath = '/') {
  const now = new Date();
  const time = now.getTime();
  const expireTime = time + expiresTime;
  now.setTime(expireTime);
  document.cookie = `${key}=${value}; expires=${now.toUTCString()}; path=${visiblePath}`;
}

export function removeCookie(key) {
  document.cookie = `${key}=; expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
}