import { put, takeLatest, call, select } from 'redux-saga/effects';
import types from 'constants/actionTypes';
import * as API from 'api';
import { downloadBlobFile } from 'lib/browser';
import { decamelizeKeys } from 'humps';
import { showModal } from 'actions/modalActions';
import { modals, INFO_MODAL_TYPE } from 'constants/modalTypes';
import { loanListSchema } from 'utils/schemas';
import { normalize } from 'normalizr';
import { push } from 'connected-react-router';
import { getError } from 'sagas/utils';
import { history } from 'store';
import { makeObjFromUrlString } from 'lib/url';
import { featuresUrlParamsMap } from 'config/featuresUrlParamsMap';
import { setCurrentUri } from 'actions';
import { LENDER } from 'constants/appRelated';
import {
  myLoansDataSuccess,
  myLoansDataFailure,
  exportMyLoansSuccess,
  exportMyLoansFailure,
  closeLoanSuccess,
  closeLoanFailure,
  assignLoanSuccess,
  assignLoanFailure,
  duplicateLoanSuccess,
  duplicateLoanFailure,
  setMyLoansDisplayVariantSuccess,
  setMyLoansDisplayVariantFailure,
  myLoansUpdateSuccess,
  myLoansUpdateFailure,
  closeMatchingLoanSuccess,
  closeLoanOnThePageSuccess,
} from './actions';

function* myLoans() {
  try {
    const {
      router: { location },
      currentUser,
    } = yield select();
    const uriString = location.search.replace('?', '');
    const isStorageURL = localStorage.getItem('url_pipeline_page');

    const {
      data: { ordering = null },
    } = makeObjFromUrlString({
      uri: uriString,
      config: { ordering: featuresUrlParamsMap.ordering },
    });

    const {
      data: { response },
    } = yield call(
      API.axiosApi.get,
      `loans/my?no_page=true${uriString ? `&${uriString}` : `&${isStorageURL}`}`
    );

    let items = response;
    if (currentUser.team?.length > 1 && !currentUser.viewedAs) {
      items = response.map((item) => ({
        ...item,
        producer: `${item.owner?.firstName} ${item.owner?.lastName}`,
      }));
    }

    let displayVariant;
    if (window.localStorage) {
      displayVariant = localStorage.getItem(`${currentUser.email}.myLoansDisplayVariant`);
    }

    const normalizedLoans = normalize(items, loanListSchema).entities.loans;
    yield put(
      myLoansDataSuccess({
        loans: items,
        normalizedLoans,
        displayVariant,
        initialOrdering: ordering,
      })
    );
  } catch (e) {
    console.error(e);
    yield put(myLoansDataFailure({ message: getError(e) }));
  }
}

function* myLoansUpdate() {
  try {
    const {
      myLoans: { ordering },
      filter,
      currentUser,
    } = yield select();

    const newUri = [ordering && `ordering=${ordering}`, filter.uri]
      .filter(Boolean)
      .join('&');

    history.push(`?${newUri}`);
    yield put(setCurrentUri(newUri));

    const {
      data: { response },
    } = yield call(
      API.axiosApi.get,
      `loans/my?no_page=true${newUri ? `&${newUri}` : ''}`
    );

    let items = response;
    if (currentUser.team?.length > 1 && !currentUser.viewedAs) {
      items = response.map((item) => ({
        ...item,
        producer: `${item.owner?.firstName} ${item.owner?.lastName}`,
      }));
    }
    const normalizedLoans = normalize(items, loanListSchema).entities.loans;

    yield put(
      myLoansUpdateSuccess({
        loans: items,
        normalizedLoans,
      })
    );
  } catch (e) {
    yield put(myLoansUpdateFailure({ message: getError(e) }));
  }
}

function* exportLoans() {
  try {
    const {
      myLoans: { ordering },
      filter,
    } = yield select();

    const query = [ordering && `ordering=${ordering}`, filter.uri]
      .filter(Boolean)
      .join('&');

    const { data, headers } = yield call(
      API.axiosApiRaw.get,
      `/loans/my/export?${query}`,
      {
        responseType: 'blob',
      }
    );

    downloadBlobFile(data, headers['content-disposition'].split('=')[1]);
    yield put(exportMyLoansSuccess());
  } catch (e) {
    yield put(exportMyLoansFailure({ message: getError(e) }));
  }
}

function* closeLoan(action) {
  try {
    const { loanId, closeType, data } = action.payload;
    const { currentUser } = yield select();

    const {
      data: { response },
    } = yield call(
      API.axiosApi.post,
      `/loans/${loanId}/close?status=${closeType}`,
      decamelizeKeys(data)
    );

    const closeLoanData = {
      loanId,
      closeType,
      status: response.status,
      closedOn: response.closedOn,
    }
    if(currentUser.accountType === LENDER){ 

      yield put(closeMatchingLoanSuccess(closeLoanData));

      let propertyDetails = window.location.pathname.indexOf('property-details');

      if(propertyDetails !== -1){
        yield put(closeLoanOnThePageSuccess(true));
        history.push('/dashboard/');
      }

    }else{
      yield put(closeLoanSuccess(closeLoanData));
    }

  } catch (e) {
    yield put(closeLoanFailure({ message: getError(e) }));
  }
}

function* assignLoan(action) {
  try {
    const { loanId, teamId } = action.payload;
    const { currentUser } = yield select();

    const { data } = yield call(API.axiosApi.patch, `/loans/${loanId}`, { owner_id: teamId });
    yield put(
      showModal(modals.INFO_MODAL, {
        infoType: INFO_MODAL_TYPE.SUCCESS,
        title: 'Success',
        text: 'You have successfully assigned the deal.',
        size: 'sm',
      })
    );

    const shouldRemoveItem = currentUser.viewedAs;

    yield put(assignLoanSuccess({ loanId:data.response.id, teamId, shouldRemoveItem, data: data.response }));
  } catch (e) {
    yield put(assignLoanFailure({ message: getError(e) }));
  }
}

function* duplicateLoan(action) {
  try {
    const { loanId, data } = action.payload;

    const {
      data: { response },
    } = yield call(API.axiosApi.post, `/loans/${loanId}/duplicate`, data);
    if (response.newLoanId) {
      yield put(push(`/dashboard/loan-creation/${response.newLoanId}`));
    }
    yield put(duplicateLoanSuccess());
  } catch (e) {
    yield put(duplicateLoanFailure({ message: getError(e) }));
  }
}

function* setMyLoansDisplayVariant(action) {
  try {
    const { displayVariant } = action.payload;
    const { currentUser } = yield select();

    if (window.localStorage) {
      localStorage.setItem(`${currentUser.email}.myLoansDisplayVariant`, displayVariant);
    }

    yield put(setMyLoansDisplayVariantSuccess());
  } catch (e) {
    yield put(setMyLoansDisplayVariantFailure({ message: getError(e) }));
  }
}

export default [
  takeLatest(types.MY_LOANS_DATA_REQUEST, myLoans),
  takeLatest(types.EXPORT_MY_LOANS_REQUEST, exportLoans),
  takeLatest(types.CLOSE_LOAN_REQUEST, closeLoan),
  takeLatest(types.ASSIGN_LOAN_REQUEST, assignLoan),
  takeLatest(types.DUPLICATE_LOAN_REQUEST, duplicateLoan),
  takeLatest(types.SET_MY_LOANS_DISPLAY_VARIANT_REQUEST, setMyLoansDisplayVariant),
  takeLatest(types.MY_LOANS_UPDATE_REQUEST, myLoansUpdate),
];
