import React from 'react';
import { Switch as FormSwitch } from 'ui/Switch';

export const Switch = ({
  input: { name, value, checked, ...restInput },
  label,
  style,
  ...custom
}) => (
  <React.Fragment>
    <FormSwitch {...custom} {...restInput} checked={checked} name={name} label={label} />
  </React.Fragment>
);
