import { put, takeLatest, takeEvery, call, select } from 'redux-saga/effects';
import types from 'constants/actionTypes';
import * as API from 'api';
import { decamelizeKeys } from 'humps';
import { push } from 'connected-react-router';
import { keys, filter, values as objectValues } from 'ramda';
import { loanDataRequest } from 'features/LoanNew/actions';
import { LOAN_SECTIONS, LOAN_STATUS } from 'constants/appRelated';
import { getError } from 'sagas/utils';
import { userTypeSelector } from 'selectors/userType';

import { normalizeFormData } from './helpers';
import { fieldConfig, CUSTOM_FIELDS_ACTIONS } from './config';
import mime from 'mime-types';

import {
  submitLoanFileSuccess,
  submitLoanFileFailure,
  deleteLoanFileSuccess,
  deleteLoanFileFailure,
  submitLoanFormSuccess,
  submitLoanFormFailure,
  notifyUncommonLoanSuccess,
  notifyUncommonLoanFailure,
  submitLoanPhotoSuccess,
  submitLoanPhotoFailure,
  updateCustomFieldsSuccess,
  updateCustomFieldsFailure,
  createCustomFolderSuccess,
  createCustomFolderFailure,
  updateCustomFolderSuccess,
  updateCustomFolderFailure,
  deleteCustomFolderSuccess,
  deleteCustomFolderFailure,
  toggleDocumentProtectionStateSuccess,
  toggleDocumentProtectionStateFailure,
  toggleFolderProtectionStateSuccess,
  toggleFolderProtectionStateFailure,
  setAsCoverSuccess,
  setAsCoverFailure,
  submitNewLoanSuccess,
  submitNewLoanFailure,
} from './actions';

// TODO connect to sagas

const sectionsMappings = {
  overview: '',
  terms: LOAN_SECTIONS.TERMS,
  propertyProfile: LOAN_SECTIONS.PROFILE,
  documentsAndMedia: LOAN_SECTIONS.FILES,
  sponsorProfile: LOAN_SECTIONS.SPONSOR,
  sourcesAndUses: LOAN_SECTIONS.SU,
  marketInfo: LOAN_SECTIONS.MARKET,
  summary: LOAN_SECTIONS.SUMMARY,
};

function* submitNewLoan(action) {
  const { values } = action.payload;
  const { isLender } = values;

  try {
    let request;
    let requestPath = '';
    let redirectPath;

    if (!isLender) {
      request = normalizeFormData(values, fieldConfig);
      requestPath = 'loans/new';
      redirectPath = LOAN_SECTIONS.TERMS;
    } else {
      const loanAmount = ~~values.loanAmount.slice(1).replace(/,/g, '');
      const addresses = [values.address];
      if (values.additionalAddresses && values.additionalAddresses.length) {
        values.additionalAddresses.forEach((adr) => addresses.push(adr));
      }

      request = {
        owner: values.client.value,
        property_type: values.propertyType.value,
        loan_type: values.loanType.value,
        plan_use_property: values.planUseProperty.value,
        loan_amount: loanAmount,
        addresses: addresses,
        zip: values.zip,
        state: values.state,
        city: values.city,
        first_address_lng: values.firstAddressLng,
        first_address_lat: values.firstAddressLat,
      };

      requestPath = 'loans/by-lender/create/';
      redirectPath = LOAN_SECTIONS.DOCUMENTS;
    }

    const {
      data: { response },
    } = yield call(API.axiosApi.post, requestPath, { ...decamelizeKeys(request) });

    const createdId = response.uuid;
    yield put(loanDataRequest({ id: createdId }));
    yield put(push(`/dashboard/loan-creation/${createdId}/${redirectPath}`));
    yield put(submitNewLoanSuccess());
  } catch (e) {
    console.error(e);
    yield put(submitNewLoanFailure({ message: getError(e) }));
  }
}

function* submitLoanForm(action) {
  const { id, values, form } = action.payload;
  try {
    const state = yield select();
    const {
      configuration: { options },
    } = state;

    let request;
    if (form === LOAN_SECTIONS.SPONSOR) {
      request = {
        sponsorProfile: normalizeFormData(values, fieldConfig.sponsorProfile),
        borrowerContactId: values?.borrowerContactId?.value,
      };
    } else {
      request = normalizeFormData(values, fieldConfig);
    }

    const {
      data: { response },
    } = yield call(API.axiosApi.patch, `loans/${id}`, {
      ...decamelizeKeys(request),
    });

    yield put(submitLoanFormSuccess({ 
      item: response, 
      form, 
      isLoanNew: !id, 
      stateOptions: options.stateOptions,
    }));

    const userType = userTypeSelector(yield select());
    let targetSection;

    const { fullness, status } = response;
    const unfilledSections = filter((val) => !val, fullness);
    const unfilledSectionsKeys = keys(unfilledSections);
    const targetKey = keys(sectionsMappings).filter(
      (section) => unfilledSectionsKeys.indexOf(section) !== -1
    )[0];

    // redirect to next section if possible or to first unfilled section
    if (userType.broker) {
      const sectionValues = objectValues(LOAN_SECTIONS);
      sectionValues.pop();

      const sectionIndex = sectionValues.indexOf(form) + 1;
      targetSection =
        sectionIndex < sectionValues.length
          ? sectionValues[sectionIndex]
          : sectionsMappings[targetKey];
    } else {
      targetSection = sectionsMappings[targetKey];
    }

    // redirect to next unfilled section
    if (status === LOAN_STATUS.POSTED_TO_MARKETPLACE) {
      yield put(push(`/dashboard/property-details/${id}`));
    } else if (unfilledSectionsKeys.length && targetSection) {
      yield put(push(`/dashboard/loan-creation/${id}/${targetSection}`));
    } else {
      yield put(push(`/dashboard/property-details/${id}`));
    }
  } catch (e) {
    console.error(e);
    yield put(submitLoanFormFailure({ message: getError(e) }));
  }
}

function* submitLoanFile(action) {
  const { form, loanId, folderId, sectionId, temporaryFile } = action.payload;
  try {
    const {
      data: { response },
    } = yield call(
      API.axiosApi.post,
      `/loans/${loanId}/folders/${folderId}/documents`,
      form
    );
    const loanData = yield call(API.axiosApi.get, `/loans/${loanId}`);
    yield put(
      submitLoanFileSuccess({
        document: { ...response, url: response.file },
        folderId,
        sectionId,
        documentId: temporaryFile.id,
        fullness: loanData.data.response.fullness,
        canSubmitForVerification: loanData.data.response.canSubmitForVerification,
      })
    );
  } catch (e) {
    console.error(e);
    yield put(
      submitLoanFileFailure({
        message: getError(e),
        folderId,
        sectionId,
        documentId: temporaryFile.id,
      })
    );
  }
}

function* submitLoanPhoto(action) {
  const { loanId, previewId, photo } = action.payload;
  try {
    const formData = new FormData();

    formData.append('photo', photo, photo.name || 'file');
    const {
      data: { response },
    } = yield call(API.axiosApi.post, `loans/${loanId}/photos/`, formData);
    const loanData = yield call(API.axiosApi.get, `/loans/${loanId}`);

    yield put(
      submitLoanPhotoSuccess({
        photo: response,
        previewId,
        fullness: loanData.data.response.fullness,
        canSubmitForVerification: loanData.data.response.canSubmitForVerification,
      })
    );
  } catch (e) {
    console.error(e);
    yield put(submitLoanPhotoFailure({ message: getError(e), previewId }));
  }
}

function* submitLoanPhotoMultipart(action) {
  const { loanId, photos } = action.payload;
  try {
    const formData = new FormData();
    photos.forEach((item) => {
      let newPhotoName;
      if (mime.extension(item.photo.type))
        newPhotoName = item.previewId + '.' + mime.extension(item.photo.type);
      else newPhotoName = item.previewId + '.' + 'file';
      formData.append('photos', item.photo, newPhotoName);
    });

    const config = { headers: { 'Content-Type': 'multipart/form-data' } };
    const res = yield call(
      API.axiosApi.post,
      `loans/${loanId}/photos/bulk/`,
      formData,
      config
    );
    const loanData = yield call(API.axiosApi.get, `/loans/${loanId}`);

    for (let index = 0; index < res.data.response.photos.length; index++) {
      const photo = res.data.response.photos[index];
      yield put(
        submitLoanPhotoSuccess({
          photo: photo,
          previewId: photo.originalFilename.split('.')[0],
          fullness: loanData.data.response.fullness,
          canSubmitForVerification: loanData.data.response.canSubmitForVerification,
        })
      );
    }
  } catch (e) {
    console.error(e);
    for (let index = 0; index < photos.length; index++) {
      const photo = photos[index];
      yield put(
        submitLoanPhotoFailure({ message: getError(e), previewId: photo.previewId })
      );
    }
  }
}

function* deleteFile(action) {
  const { id, type, loanId, folderId } = action.payload;
  try {
    yield call(
      API.axiosApi.delete,
      `${
        type === 'photos'
          ? `photos/${id}`
          : `/loans/${loanId}/folders/${folderId}/documents/${id}`
      }`
    );

    yield put(deleteLoanFileSuccess({ id, type: type || 'documents' }));
    if (loanId) {
      yield put(loanDataRequest({ id: loanId }));
    }
  } catch (e) {
    console.error(e);
    yield put(deleteLoanFileFailure({ message: getError(e) }));
  }
}

function* toggleDocumentProtectionState(action) {
  try {
    const { folderId, documentId, loanId, formData, sectionId } = action.payload;
    const {
      data: { response },
    } = yield call(
      API.axiosApi.patch,
      `loans/${loanId}/folders/${folderId}/documents/${documentId}`,
      formData
    );
    yield put(
      toggleDocumentProtectionStateSuccess({
        document: response,
        documentId,
        folderId,
        sectionId,
      })
    );
  } catch (e) {
    yield put(toggleDocumentProtectionStateFailure({ message: getError(e) }));
  }
}

function* notifyUncommonLoan() {
  try {
    yield call(API.axiosApi.post, '/letters/notify_uncommon_loan/');
    yield put(notifyUncommonLoanSuccess());
  } catch (e) {
    yield put(notifyUncommonLoanFailure());
  }
}

function* updateCustomFields(action) {
  const { sectionName, label, value, name, actionType, loanId, fields } = action.payload;
  try {
    let customFields = [];

    switch (actionType) {
      case CUSTOM_FIELDS_ACTIONS.CREATE:
        customFields = [...fields, { section: sectionName, label, value, name }];
        break;
      case CUSTOM_FIELDS_ACTIONS.UPDATE:
        customFields = fields.map((field) =>
          field.name === name ? { ...field, value, label } : field
        );
        break;
      case CUSTOM_FIELDS_ACTIONS.DELETE:
        customFields = fields.filter((field) => field.name !== name);
        break;
      default:
        break;
    }

    yield call(API.axiosApi.patch, `loans/${loanId}`, { custom_fields: customFields });
    yield put(updateCustomFieldsSuccess({ fields: customFields }));
  } catch (e) {
    console.error(e);
    yield put(updateCustomFieldsFailure({ message: getError(e) }));
  }
}

function* createCustomFolder(action) {
  const { name, id } = action.payload;
  try {
    const {
      data: { response },
    } = yield call(API.axiosApi.post, `loans/${id}/folders`, { name });
    yield put(createCustomFolderSuccess({ documentsAll: response }));
  } catch (e) {
    console.error(e);
    yield put(createCustomFolderFailure({ message: getError(e) }));
  }
}

function* updateCustomFolder(action) {
  const { id, folderId, name } = action.payload;
  try {
    yield call(API.axiosApi.patch, `loans/${id}/folders/${folderId}`, { name });
    yield put(updateCustomFolderSuccess({ name, folderId }));
  } catch (e) {
    console.error(e);
    yield put(updateCustomFolderFailure({ message: getError(e) }));
  }
}

function* deleteCustomFolder(action) {
  const { id, folderId } = action.payload;
  try {
    yield call(API.axiosApi.delete, `loans/${id}/folders/${folderId}`);
    yield put(deleteCustomFolderSuccess({ folderId }));
  } catch (e) {
    console.error(e);
    yield put(deleteCustomFolderFailure({ message: getError(e) }));
  }
}

function* toggleFolderProtectionState(action) {
  try {
    const { folderId, loanId, protectionState } = action.payload;
    const {
      data: { response },
    } = yield call(API.axiosApi.patch, `loans/${loanId}/folders/${folderId}`, {
      protected: !protectionState,
    });
    yield put(toggleFolderProtectionStateSuccess({ documentsAll: response }));
  } catch (e) {
    yield put(toggleFolderProtectionStateFailure({ message: getError(e) }));
  }
}

function* setAsCover(action) {
  try {
    const { id, loanId } = action.payload;

    yield call(API.axiosApi.patch, `loans/${loanId}/set_cover`, {
      photo_id: id,
    });
    yield put(setAsCoverSuccess({ photoId: id }));
  } catch (e) {
    yield put(setAsCoverFailure({ message: getError(e) }));
  }
}

export default [
  takeLatest(types.SUBMIT_NEW_LOAN_REQUEST, submitNewLoan),
  takeLatest(types.SUBMIT_LOAN_FORM_REQUEST, submitLoanForm),
  takeEvery(types.SUBMIT_LOAN_FILE_REQUEST, submitLoanFile),
  takeEvery(types.SUBMIT_LOAN_PHOTO_REQUEST, submitLoanPhoto),
  takeEvery(types.SUBMIT_LOAN_PHOTOS_MULTIPART_REQUEST, submitLoanPhotoMultipart),
  takeLatest(types.DELETE_LOAN_FILE_REQUEST, deleteFile),
  takeLatest(types.NOTIFY_UNCOMMON_LOAN_REQUEST, notifyUncommonLoan),
  takeLatest(types.UPDATE_CUSTOM_FIELDS_REQUEST, updateCustomFields),
  takeLatest(types.CREATE_CUSTOM_FOLDER_REQUEST, createCustomFolder),
  takeLatest(types.UPDATE_CUSTOM_FOLDER_REQUEST, updateCustomFolder),
  takeLatest(types.DELETE_CUSTOM_FOLDER_REQUEST, deleteCustomFolder),
  takeLatest(
    types.TOGGLE_DOCUMENT_PROTECTION_STATE_REQUEST,
    toggleDocumentProtectionState
  ),
  takeLatest(types.TOGGLE_FOLDER_PROTECTION_STATE_REQUEST, toggleFolderProtectionState),
  takeLatest(types.SET_AS_COVER_REQUEST, setAsCover),
];
