import { normalizeDollars } from 'lib/normalizers';
import { makeSelectItem } from 'lib/loan';
import { unitOptions, indexOptions, fixedOrVariableOptions } from 'utils/fields';
import { FIELD_TYPES } from 'constants/appRelated';

export const getQuoteInitialValues = ({ offerFields, isSoftQuote, quote }) => {
  const initialValues = {};

  offerFields
    .reduce((acc, arr) => [...acc, ...arr], [])
    .forEach((item) => {
      const { type, field } = item;
      const value = !isSoftQuote ? quote[field] || null : null;
      if (type === FIELD_TYPES.PERCENT) {
        initialValues[field] = value;
      } else if (type === FIELD_TYPES.DOLLARS) {
        initialValues[field] = normalizeDollars(String(value));
      } else {
        initialValues[field] = value;
      }
    });

  // if (
  //   initialValues.amortization &&
  //   initialValues.amortization % 12 === 0 &&
  //   initialValues.amortizationUnit === 0
  // ) {
  //   initialValues.amortization /= 12;
  //   initialValues.amortizationUnit = 1;
  // }

  initialValues.index = makeSelectItem(initialValues.index, indexOptions);
  initialValues.fixedOrVariable = makeSelectItem(
    initialValues.fixedOrVariable,
    fixedOrVariableOptions
  );

  initialValues.loanTermUnit = quote.loanTermUnit ? unitOptions[1] : unitOptions[0];
  initialValues.interestOnlyUnit = quote.interestOnlyUnit
    ? unitOptions[1]
    : unitOptions[0];
  // initialValues.amortizationUnit = quote.amortizationUnit
  //   ? unitOptions[1]
  //   : unitOptions[0];
  initialValues.recourse = !!initialValues.recourse;

  return initialValues;
};
