import types from 'constants/actionTypes';


export const getInvitedLendersRequest = (payload = {}) => ({
  type: types.GET_INVITED_LENDERS_REQUEST,
  payload,
});
export const getInvitedLendersSuccess = (payload = {}) => ({
  type: types.GET_INVITED_LENDERS_SUCCESS,
  payload,
});
export const getInvitedLendersFailure = (payload = {}) => ({
  type: types.GET_INVITED_LENDERS_FAILURE,
  payload,
});

export const getLenderTeamMembersRequest = (payload = {}) => ({
  type: types.GET_LENDER_TEAM_MEMBERS_REQUEST,
  payload,
});
export const getLenderTeamMembersSuccess = (payload = {}) => ({
  type: types.GET_LENDER_TEAM_MEMBERS_SUCCESS,
  payload,
});
export const getLenderTeamMembersFailure = (payload = {}) => ({
  type: types.GET_LENDER_TEAM_MEMBERS_FAILURE,
  payload,
});

export const getLenderCompanyDataRequest = (payload = {}) => ({
  type: types.GET_LENDER_COMPANY_DATA_REQUEST,
  payload,
});
export const getLenderCompanyDataSuccess = (payload = {}) => ({
  type: types.GET_LENDER_COMPANY_DATA_SUCCESS,
  payload,
});
export const getLenderCompanyDataFailure = (payload = {}) => ({
  type: types.GET_LENDER_COMPANY_DATA_FAILURE,
  payload,
});

export const updateLenderCompanyDataRequest = (payload = {}) => ({
  type: types.UPDATE_LENDER_COMPANY_DATA_REQUEST,
  payload,
});
export const updateLenderCompanyDataSuccess = (payload = {}) => ({
  type: types.UPDATE_LENDER_COMPANY_DATA_SUCCESS,
  payload,
});
export const updateLenderCompanyDataFailure = (payload = {}) => ({
  type: types.UPDATE_LENDER_COMPANY_DATA_FAILURE,
  payload,
});

export const createLenderCompanyTeamRequest = (payload = {}) => ({
  type: types.CREATE_LENDER_COMPANY_TEAM_REQUEST,
  payload,
});
export const createLenderCompanyTeamSuccess = (payload = {}) => ({
  type: types.CREATE_LENDER_COMPANY_TEAM_SUCCESS,
  payload,
});
export const createLenderCompanyTeamFailure = (payload = {}) => ({
  type: types.CREATE_LENDER_COMPANY_TEAM_FAILURE,
  payload,
});

export const updateLenderCompanyTeamRequest = (payload = {}) => ({
  type: types.UPDATE_LENDER_COMPANY_TEAM_REQUEST,
  payload,
});
export const updateLenderCompanyTeamSuccess = (payload = {}) => ({
  type: types.UPDATE_LENDER_COMPANY_TEAM_SUCCESS,
  payload,
});
export const updateLenderCompanyTeamFailure = (payload = {}) => ({
  type: types.UPDATE_LENDER_COMPANY_TEAM_FAILURE,
  payload,
});

export const addLenderCompanyMemberRequest = (payload = {}) => ({
  type: types.ADD_LENDER_COMPANY_MEMBER_REQUEST,
  payload,
});
export const addLenderCompanyMemberSuccess = (payload = {}) => ({
  type: types.ADD_LENDER_COMPANY_MEMBER_SUCCESS,
  payload,
});
export const addLenderCompanyMemberFailure = (payload = {}) => ({
  type: types.ADD_LENDER_COMPANY_MEMBER_FAILURE,
  payload,
});

export const updateLenderCompanyMemberRequest = (payload = {}) => ({
  type: types.UPDATE_LENDER_COMPANY_MEMBER_REQUEST,
  payload,
});
export const updateLenderCompanyMemberSuccess = (payload = {}) => ({
  type: types.UPDATE_LENDER_COMPANY_MEMBER_SUCCESS,
  payload,
});
export const updateLenderCompanyMemberFailure = (payload = {}) => ({
  type: types.UPDATE_LENDER_COMPANY_MEMBER_FAILURE,
  payload,
});

export const removeLenderCompanyMemberRequest = (payload = {}) => ({
  type: types.REMOVE_LENDER_COMPANY_MEMBER_REQUEST,
  payload,
});
export const removeLenderCompanyMemberSuccess = (payload = {}) => ({
  type: types.REMOVE_LENDER_COMPANY_MEMBER_SUCCESS,
  payload,
});
export const removeLenderCompanyMemberFailure = (payload = {}) => ({
  type: types.REMOVE_LENDER_COMPANY_MEMBER_FAILURE,
  payload,
});

export const resendLenderCompanyMemberInvitationRequest = (payload = {}) => ({
  type: types.RESEND_LENDER_COMPANY_MEMBER_INVITATION_REQUEST,
  payload,
});
export const resendLenderCompanyMemberInvitationSuccess = (payload = {}) => ({
  type: types.RESEND_LENDER_COMPANY_MEMBER_INVITATION_SUCCESS,
  payload,
});
export const resendLenderCompanyMemberInvitationFailure = (payload = {}) => ({
  type: types.RESEND_LENDER_COMPANY_MEMBER_INVITATION_FAILURE,
  payload,
});

export const updateLenderCompanySettingsRequest = (payload = {}) => ({
  type: types.UPDATE_LENDER_COMPANY_SETTINGS_REQUEST,
  payload,
});
export const updateLenderCompanySettingsSuccess = (payload = {}) => ({
  type: types.UPDATE_LENDER_COMPANY_SETTINGS_SUCCESS,
  payload,
});
export const updateLenderCompanySettingsFailure = (payload = {}) => ({
  type: types.UPDATE_LENDER_COMPANY_SETTINGS_FAILURE,
  payload,
});

export const checkLenderCompanyMemeberEmailRequest = (payload = {}) => ({
  type: types.CHECK_LENDER_COMPANY_MEMBER_EMAIL_REQUEST,
  payload,
});
export const checkLenderCompanyMemeberEmailSuccess = (payload = {}) => ({
  type: types.CHECK_LENDER_COMPANY_MEMBER_EMAIL_SUCCESS,
  payload,
});
export const checkLenderCompanyMemeberEmailFailure = (payload = {}) => ({
  type: types.CHECK_LENDER_COMPANY_MEMBER_EMAIL_FAILURE,
  payload,
});

export const assignLoanToLenderRequest = (payload = {}) => ({
  type: types.ASSIGN_LOAN_TO_LENDER_REQUEST,
  payload,
});
export const assignLoanToLenderSuccess = (payload = {}) => ({
  type: types.ASSIGN_LOAN_TO_LENDER_SUCCESS,
  payload,
});
export const assignLoanToLenderFailure = (payload = {}) => ({
  type: types.ASSIGN_LOAN_TO_LENDER_FAILURE,
  payload,
});

export const searchLenderCompaniesBy = (payload = {}) => ({
  type: types.SEARCH_LENDER_COMPANIES_REQUEST,
  payload,
});

export const searchLenderCompanyDataSuccess = (payload = {}) => ({
  type: types.SEARCH_LENDER_COMPANIES_SUCCESS,
  payload,
});

export const searchLenderCompanyDataFailure = (payload = {}) => ({
  type: types.SEARCH_LENDER_COMPANIES_FAILURE,
  payload,
});