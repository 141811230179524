import { LOAN_STATUS } from 'constants/appRelated';

export const LoanActionsPanel = ({
  isOwner,
  isLender,
  status,
  draftPanel,
  lenderPanel,
  borowerPanel,
  accessPreview,
}) => {
  const canAct = !isOwner && isLender;
  if (accessPreview) {
    return null;
  }

  if (
    status <= LOAN_STATUS.SUBMITTED_FOR_VERIFICATION ||
    status === LOAN_STATUS.VERIFICATION_FAILED ||
    !status
  ) {
    return draftPanel;
  }

  if (canAct) {
    return lenderPanel;
  }

  return borowerPanel;
};
