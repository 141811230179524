import types from 'constants/actionTypes';
import immutable from 'object-path-immutable';
import { CHAT_ROOM_TYPE } from 'constants/appRelated';
import { AUTH_ROUTES } from 'containers/Auth/config';
import { values } from 'ramda';

const initialState = {
  unreadMessagesCount: { loan: 0, personal: 0 },
  routesHistory: {
    prev: null,
    current: null,
  },
  pagesHistory: {
    prev: null,
    current: null,
  },
};

export default function header(state = initialState, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case types.DASHBOARD_DATA_SUCCESS:
      return { ...state, unreadMessagesCount: payload.unreadMessagesCount };

    case types.SET_DASHBOARD_CHAT_MESSAGE_COUNT:
      return immutable.update(state, 'unreadMessagesCount', (unreadMessagesCount) => ({
        ...unreadMessagesCount,
        ...payload,
      }));

    case types.HANDLE_WEBSOCKET_MESSAGE:
      const { room_type } = payload.data;
      return immutable.update(state, 'unreadMessagesCount', (unreadMessagesCount) => ({
        ...unreadMessagesCount,
        ...(room_type === CHAT_ROOM_TYPE.PERSONAL
          ? { personal: unreadMessagesCount.personal + 1 }
          : { loan: unreadMessagesCount.loan + 1 }),
      }));

    case '@@router/LOCATION_CHANGE':
      const {
        location: { pathname, search },
      } = payload;

      /*
        Return current history state when
        1) Current path is equal to prev path
        2) If current pathname is '/chat' and uri param is 'lender=...'
        3) If redirection has been happened between auth routes defined in AUTH_ROUTES
      */

      if (
        pathname === state.routesHistory.current ||
        (search &&
          pathname.indexOf('/chat') !== -1 &&
          search.indexOf('?lender=') !== -1) ||
        (values(AUTH_ROUTES).indexOf(pathname) !== -1 &&
          values(AUTH_ROUTES).indexOf(
            (state.pagesHistory.current || '').split('?')[0]
          ) !== -1)
      ) {
        return state;
      }

      const prevPagePath =
        state.pagesHistory.current &&
        state.pagesHistory.current
          .split('/')
          .slice(0, 3)
          .join('/');

      const currentPagePath = pathname
        .split('/')
        .slice(0, 3)
        .join('/');

      return {
        ...state,
        routesHistory: {
          prev: state.routesHistory.current,
          current: `${pathname}${search || ''}`,
        },
        pagesHistory:
          prevPagePath !== currentPagePath
            ? {
                prev: state.routesHistory.current,
                current: `${pathname}${search || ''}`,
              }
            : state.pagesHistory,
      };

    case types.SET_CURRENT_URI:
      return immutable(state)
        .update(
          'pagesHistory.current',
          (current) => `${current.replace(/\?.*$/g, '')}?${action.payload}`
        )
        .update(
          'routesHistory.current',
          (current) => `${current.replace(/\?.*$/g, '')}?${action.payload}`
        )
        .value();

    default:
      return state;
  }
}
