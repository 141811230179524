import React from 'react';
import styled from 'styled-components/macro';
import { FILTERS_TYPES } from 'features/DealRoom/constants';
import { PlusIconBoldSmall } from 'components/SvgIcons';
import { NavLink } from 'react-router-dom';

export const FilterTabs = ({
  userType,
  openCreatChecklistModal,
  loanId,
  roomId,
  downloadDocuments,
}) => (
  <Wrapper>
    {!userType.lender ? (
      <>
        <Type
          to={`/dashboard/property-details/${loanId}/deal-room/${roomId}/${FILTERS_TYPES.ALL}`}
        >
          ALL
        </Type>
        <Type
          to={`/dashboard/property-details/${loanId}/deal-room/${roomId}/${FILTERS_TYPES.NOTCOMPLETED}`}
        >
          INCOMPLETE
        </Type>
        <Type
          to={`/dashboard/property-details/${loanId}/deal-room/${roomId}/${FILTERS_TYPES.REJECTED}`}
        >
          REJECTED
        </Type>
        <Type
          to={`/dashboard/property-details/${loanId}/deal-room/${roomId}/${FILTERS_TYPES.COMPLETED}`}
        >
          COMPLETED
        </Type>
      </>
    ) : (
      <>
        <Type
          to={`/dashboard/property-details/${loanId}/deal-room/${roomId}/${FILTERS_TYPES.ALL}`}
        >
          ALL
        </Type>
        <Type
          to={`/dashboard/property-details/${loanId}/deal-room/${roomId}/${FILTERS_TYPES.NEW}`}
        >
          NEW
        </Type>
        <Type
          to={`/dashboard/property-details/${loanId}/deal-room/${roomId}/${FILTERS_TYPES.APPROVED}`}
        >
          APPROVED
        </Type>
        <Type
          to={`/dashboard/property-details/${loanId}/deal-room/${roomId}/${FILTERS_TYPES.COMPLETED}`}
        >
          COMPLETED
        </Type>
      </>
    )}
    {userType.lender && (
      <Controls>
        <NewChecklistBtn onClick={downloadDocuments}>
          <img
            src="/images/icons/ico_download_blue_thin.svg"
            width="11"
            height="12"
            alt="download"
          />
          DOWNLOAD
        </NewChecklistBtn>
        <NewChecklistBtn onClick={openCreatChecklistModal}>
          <PlusIconBoldSmall color="#4394ea" />
          NEW CHECKLIST
        </NewChecklistBtn>
      </Controls>
    )}
  </Wrapper>
);

const Type = styled(NavLink)`
  font-size: 10px;
  color: #778598;
  padding-bottom: 8px;
  position: relative;
  margin-right: 16px;
  cursor: pointer;

  &.active {
    color: #4394ea;

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      height: 2px;
      width: 100%;
      background-color: #4394ea;
    }
  }

  &:hover {
    color: #4394ea;

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      height: 2px;
      width: 100%;
      background-color: #4394ea;
    }
  }
`;

const Wrapper = styled.div`
  display: flex;
  border-bottom: 1px solid #e8e9ed;
  margin-bottom: 24px;
`;

const NewChecklistBtn = styled.button`
  display: flex;
  align-items: center;
  padding: 0;
  outline: none;
  color: #4394ea;
  font-size: 10px;
  border: none;
  background-color: #fafafb;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }

  svg,
  img {
    margin-right: 6px;
  }
`;

const Controls = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0 0 auto;

  ${NewChecklistBtn} {
    margin-left: 16px;
  }
`;
