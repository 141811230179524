import React from 'react';
import { Form, Field } from 'react-final-form';
import { Input, NewCheckbox } from 'components/Form';
import { Button, ButtonGhost } from 'ui/Button';
import { delay, simpleMemoize } from 'lib/utils';
import Yup from 'yup';
import { validate, validateEmail } from 'utils/validate';
import { borrowerAddNewMemberRequest } from 'features/Profile/actions';
import { ModalWrapper, ModalHeading } from 'ui/Modal/styles';
import styled from 'styled-components/macro';
import { emailExistsRequest } from 'actions';

const schema = Yup.object().shape({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
});

const InitialsContent = styled.div`
  display: flex;
  justify-content: space-between;
`;

const InitialsWrapper = styled.div`
  flex: 1;
  margin-right: 16px;

  &:last-child {
    margin-right: 0;
  }
`;

const ControlsWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const PermissionWrapper = styled.label`
  margin-bottom: 24px;
  display: flex;
`;

const PermissionDescription = styled.div`
  font-size: 12px;
  line-height: 20px;
  color: #778598;
  margin-top: 8px;
`;

const PermissionActionWrapper = styled.div`
  width: 28px;
`;

const PermissionContent = styled.div`
  max-width: 457px;
`;

const PermissionsTitle = styled.div`
  font-weight: 600;
  margin-bottom: 24px;
`;

const FormWrapper = styled.div`
  padding: 30px;
`;

export class InviteBorrowerTeamMember extends React.Component {
  asyncValidate = simpleMemoize(async (email) => {
    const { dispatch } = this.props;

    if (!email) {
      return 'Email is required';
    }

    if (!validateEmail(email)) {
      return 'Invalid email address';
    }

    if (email) {
      const res = await delay(1000).then(() =>
        new Promise((resolve, reject) => {
          dispatch(emailExistsRequest({ email, resolve, reject, isInvited: true }));
        }).then(
          (v) => v,
          ({ error }) => error
        )
      );
      return (
        res &&
        'User with this email address has already been registered. To invite your team member please provide another email address'
      );
    }
  });

  submitForm = (values) => {
    const { dispatch, hideModal } = this.props;
    hideModal();
    dispatch(borrowerAddNewMemberRequest({ form: values }));
  };

  render() {
    const { hideModal, isAdmin } = this.props;
    return (
      <ModalWrapper>
        <ModalHeading>Invite new team member</ModalHeading>
        <Form
          onSubmit={this.submitForm}
          validate={(values) => validate(values, schema)}
          render={({ handleSubmit, submitting, validating }) => (
            <FormWrapper>
              <form onSubmit={handleSubmit}>
                <InitialsContent>
                  <InitialsWrapper>
                    <Field
                      name="firstName"
                      component={Input}
                      data-testid="firstName"
                      label="First Name"
                      parse={(value) => value.trim()}
                      required
                    />
                  </InitialsWrapper>
                  <InitialsWrapper>
                    <Field
                      name="lastName"
                      component={Input}
                      data-testid="lastName"
                      label="Last Name"
                      parse={(value) => value.trim()}
                      required
                    />
                  </InitialsWrapper>
                </InitialsContent>
                <Field
                  name="email"
                  data-testid="email"
                  component={Input}
                  validate={this.asyncValidate}
                  label="Email"
                  required
                />
                <PermissionsTitle> Member Permissions</PermissionsTitle>
                <PermissionWrapper>
                  <PermissionActionWrapper>
                    <img src="/images/icons/ico_check.svg" alt="" />
                  </PermissionActionWrapper>
                  <PermissionContent>
                    User
                    <PermissionDescription>
                      Users can chat, answer questions, chat, and send documents to
                      lenders
                    </PermissionDescription>
                  </PermissionContent>
                </PermissionWrapper>
                {isAdmin && (
                  <PermissionWrapper>
                    <PermissionActionWrapper>
                      <Field
                        name="canNegotiate"
                        component={NewCheckbox}
                        type="checkbox"
                      />
                    </PermissionActionWrapper>
                    <PermissionContent>
                      Can negotiate
                      <PermissionDescription>
                        Check this box to allow your user to negotiate quotes and term
                        sheets
                      </PermissionDescription>
                    </PermissionContent>
                  </PermissionWrapper>
                )}
                <ControlsWrapper>
                  <ButtonGhost mr={3} onClick={hideModal}>
                    Cancel
                  </ButtonGhost>
                  <Button disabled={submitting || validating}>Send Invitation</Button>
                </ControlsWrapper>
              </form>
            </FormWrapper>
          )}
        />
      </ModalWrapper>
    );
  }
}
