import styled, { css } from 'styled-components/macro';
import { OFFER_STATE } from 'constants/appRelated';

export const TableRow = styled.div`
  padding: 8px 0;
  border-bottom: 1px solid #e8e9ed;
  display: flex;
  align-items: center;
  &:last-child {
    border-bottom: none;
  }
`;

export const TableHeading = styled.span`
  font-size: 10px;
  line-height: 16px;
  font-weight: 600;
  color: #778598;
  text-transform: uppercase;
`;

export const TableCell = styled.div`
  width: 14%;
`;

export const TableCellMedium = styled.div`
  width: 19%;
`;

export const TableCellWide = styled.div`
  width: 34%;
`;

export const Table = styled.div``;

export const TableSectionTitle = styled.div`
  width: 34%;
`;

export const TableSectionInfo = styled.div`
  flex: 1;
  display: flex;

  @media (max-width: ${(p) => p.theme.breakpoints[1]}px) {
    display: none;
  }
`;

export const ItemRow = styled(TableRow)`
  @media (max-width: ${(p) => p.theme.breakpoints[1]}px) {
    flex-direction: column;
    align-items: flex-start;
    border-bottom: none;
    padding: 0;
  }
`;

export const Title = styled(TableSectionTitle)`
  @media (max-width: ${(p) => p.theme.breakpoints[1]}px) {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e8e9ed;
    padding: 8px 0;
  }
`;

export const InfoSection = styled(TableSectionInfo)`
  @media (max-width: ${(p) => p.theme.breakpoints[1]}px) {
    flex-direction: column;
    border-bottom: 1px solid #e8e9ed;
    width: 100%;
    padding: 8px 0;

    ${(p) =>
      p.visible &&
      css`
        display: flex;
      `}

    ${TableCellWide}, ${TableCell} {
      margin-bottom: 8px;
      display: flex;
      justify-content: space-between;
      width: 100%;

      &::before {
        content: attr(data-title);
        font-size: 14px;
        color: #778598;
        margin-right: 8px;
      }
    }
  }
`;

export const ExpandIconCommon = styled.span`
  padding: 5px;
  display: none;

  &::before {
    content: url('/images/icons/ico_chevron_black_down.svg');
  }

  @media (max-width: ${(p) => p.theme.breakpoints[1]}px) {
    display: block;
  }
`;

export const ExpandIcon = styled(ExpandIconCommon)`
  ${(p) =>
    p.expanded &&
    css`
      &::before {
        content: url('/images/icons/ico_chevron_black_up.svg');
      }
    `}
`;

export const OffersSectionLabel = styled.h4`
  display: flex;
  align-items: center;
  margin: 0 0 16px;
  font-size: 10px;
  font-weight: 600;
  color: ${(p) => {
    switch (p.state) {
      case OFFER_STATE.NEW_OFFER:
        return '#6A7FDB';
      case OFFER_STATE.CANCELLED:
        return '#778598';
      case OFFER_STATE.ACCEPTED:
        return '#44AF69';
      case OFFER_STATE.NEGOTIATION:
        return '#FF9E01';
      case OFFER_STATE.REJECTED:
        return '#E32019';
      default:
        return '#000';
    }
  }};

  span {
    margin-right: 8px;
  }
`;
