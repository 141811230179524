import React from 'react';
import styled, { css } from 'styled-components';
import {
  ModalBody,
  ModalHeading,
  ModalActions,
  modalWithContentWrapperStyles,
} from 'ui/Modal/styles';
import { Form, Field } from 'react-final-form';
import { splitEvery } from 'ramda';
import { Button } from 'ui/Button';
import { ReactComponent as RemoveIcon } from 'icons/ico_select_item_remove.svg';

const StateItem = ({ label, input: { onChange, checked } }) => (
  <StateWrapper checked={checked}>
    <input type="checkbox" onChange={onChange} checked={checked} />
    <span>{label}</span>
    <CrossCircle>
      <RemoveIcon />
    </CrossCircle>
  </StateWrapper>
);

export const LocationsSelectionList = ({
  states,
  initialValues,
  onSubmit,
  hideModal,
}) => {
  const splittedStates = splitEvery(states.length / 4, states);
  return (
    <Form
      onSubmit={(values) => {
        onSubmit(values.states);
        hideModal();
      }}
      initialValues={initialValues}
    >
      {({ handleSubmit, form: { change }, values }) => (
        <StyledForm
          data-testid='selectLocationForm'
          onSubmit={handleSubmit}>
          <ModalHeading>Select Location</ModalHeading>
          <Body>
            {splittedStates.map((statesColumn, i) => (
              <div key={i}>
                {statesColumn.map((state) => (
                  <Field
                    key={state.value}
                    component={StateItem}
                    label={state.label}
                    value={state.value}
                    type="checkbox"
                    name="states"
                  />
                ))}
              </div>
            ))}
          </Body>
          <Actions>
            <div>
              {values.states.length === states.length ? (
                <SelectAllBtn
                  type="button"
                  onClick={() => {
                    change('states', []);
                  }}
                >
                  Select None
                </SelectAllBtn>
              ) : (
                <SelectAllBtn
                  type="button"
                  onClick={() => {
                    change('states', states.map(({ value }) => value));
                  }}
                >
                  Select All
                </SelectAllBtn>
              )}
            </div>
            <div>
              <Button type="submit">Submit</Button>
            </div>
          </Actions>
        </StyledForm>
      )}
    </Form>
  );
};

const StyledForm = styled.form`
  ${modalWithContentWrapperStyles}
`;

const CrossCircle = styled.div`
  display: none;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
  border: none;
  width: 18px;
  height: 18px;
  background-color: #fff;
  border-radius: 50%;
  flex-shrink: 0;
`;

const StateWrapper = styled.label`
  display: flex;
  align-items: center;
  border-radius: 10px;
  position: relative;
  padding: 4px 26px 4px 6px;
  border-radius: 15px;
  font-size: 14px;
  margin-bottom: 8px;
  min-height: 26px;
  max-width: 190px;
  cursor: pointer;

  input {
    position: absolute;
    opacity: 0;
  }

  &:hover {
    text-decoration: underline;
  }

  ${(p) =>
    p.checked &&
    css`
      background-color: #ecf4fd;
      padding-right: 4px;

      ${CrossCircle} {
        display: flex;
      }

      &:hover {
        text-decoration: none;
        box-shadow: inset ${(p) => p.theme.shadow};
      }
    `}
`;

const Body = styled(ModalBody)`
  display: flex;
  flex-wrap: wrap;

  & > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 25%;
    min-width: 150px;
  }
`;

const Actions = styled(ModalActions)`
  & > div {
    display: flex;
    flex: 1;
    justify-content: flex-start;
  }
`;

const SelectAllBtn = styled.button`
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
  background-color: #fafafb;
  color: #4394ea;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;
