import { put, takeLatest, call, select } from 'redux-saga/effects';
import types from 'constants/actionTypes';
import * as API from 'api';
import { getError } from 'sagas/utils';
import { push } from 'connected-react-router';
import { BORROWER, BROKER, LENDER } from 'constants/appRelated';
import { getLenderCompanyDataRequest, getLenderTeamMembersRequest } from 'features/LenderCompany/actions';

import { recoveryLoginSuccess, recoveryLoginFailure } from './actions';

function* recoveryLogin(action) {
  const { token } = action.payload;

  try {
    yield call(API.axiosApi.post, '/auth/tfa/recovery-login', { token });

    const { currentUser } = yield select();

    let teamData = {};

    if (currentUser.accountType === BROKER || currentUser.accountType === BORROWER) {
      const teamDataRequest = yield call(API.axiosApi.get, '/auth/team-settings');
      teamData = teamDataRequest.data.response;
    }

    if (currentUser.accountType === LENDER) {
      yield put(getLenderTeamMembersRequest());
      yield put(getLenderCompanyDataRequest());
    }

    // cause we getting 403 for team settings without confirmed authorization (2fa)
    yield put(recoveryLoginSuccess({ teamData, team: teamData.members }));
    yield put(push('/dashboard/profile'));
  } catch (e) {
    yield put(recoveryLoginFailure({ error: getError(e) }));
  }
}

export default [takeLatest(types.RECOVERY_LOGIN_REQUEST, recoveryLogin)];
