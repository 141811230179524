import styled, { css } from 'styled-components/macro';

export const OfferModalWrapper = styled.div`
  position: relative;
`;

export const OfferModalHeader = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0 -1px;
  padding: 24px 24px 20px;
  font-size: 22px;
  line-height: 32px;
  font-weight: 400;
  border-bottom: 1px solid #e0e0e0;
`;

export const OfferModalTitle = styled.div`
  flex-shrink: 0;
`;

export const OfferModalContent = styled.div`
  padding: 10px 24px 24px;
`;

export const OfferModalTabs = styled.div`
  
`;

export const OfferModalDescription = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-grow: 1;
  margin-left: 10px;
`;

export const OfferModalLegend = styled.div`
  margin: 0 8px 0 5px;
  font-size: 16px;
  display: flex;
  align-items: center;
`;

const OfferModalTabCommon = styled.div`
  position: relative;
  display: inline-flex;
  vertical-align: middle;
  font-size: 18px;
  padding: 20px 30px 20px;
  cursor: pointer;
`;

export const OfferModalTab = styled(OfferModalTabCommon)`

  .text{
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: #1C3353;
  }

  ${(p) =>
    p.active &&
    css`
      background: #FAFAFB;
    `}
`;

export const OfferRequest = styled.div`
  margin-bottom: 50px;
  position: relative;
`;

export const OfferHeading = styled.div`
  display: flex;
  //padding-left: 33%;
`;

export const OfferHeadingTitle = styled.div`
  flex: 1;
  font-weight: 700;
  font-size: 16px;
  line-height: 28px;
  padding: 10px 0;

  span{
    margin-left: 50%;
  }
  
  &:last-child{
    span{
      margin-left: 15%;
    }
  }
`;


export const OfferRequestField = styled.div`
  margin-bottom: 20px;
`;

export const OfferModalAdd = styled.button`
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  font-size: 30px;
  margin-left: 20px;
  height: 36px;
  width: 36px;
  border-radius: 50%;
  line-height: 1px;
  text-align: center;
  background: #ff9b12;
  color: #fff;
  border: none;
  outline: none;

  &::before {
    content: '+';
    font-weight: bold;
    font-size: inherit;
    text-rendering: auto;
  }
`;

export const OfferModalActions = styled.div`
  text-align: right;
`;

export const StateIco = styled.img`
  margin-right: 8px;
`;
