import React, { useState } from 'react';
import styled from 'styled-components/macro';
import {
  ModalBody,
  ModalHeading,
  ModalActions,
  ModalWithContentWrapper,
} from 'ui/Modal/styles';
import { Button } from 'ui/Button';
import { FolderIcon, DocumentIcon } from 'components/SvgIcons';
import { CheckCircle } from 'ui/CheckCircle';
import { Text } from 'ui/Text';
import { FileUpload } from 'components/FileUpload';
import { DocumentRejectionHandler } from 'components/DocumentRejectionHandler';
import { allowedDocTypes } from 'config/allowedDocTypes';

export const DealRoomAttachFilesModal = ({
  hideModal,
  loanDocuments,
  reUpload,
  submit,
}) => {
  const { recommended, sections } = loanDocuments;
  const [selectedDocuments, toggleDocumentSelection] = useState([]);

  const handleDocumentClick = (documentId) => {
    if (selectedDocuments.indexOf(documentId) !== -1) {
      toggleDocumentSelection(selectedDocuments.filter((id) => id !== documentId));
    } else if (selectedDocuments.length === 0 || !reUpload) {
      toggleDocumentSelection([...selectedDocuments, documentId]);
    }
  };

  const attachRequestsDocuments = (files) => {
    const formData = new FormData();

    if (files) {
      files.forEach((file, i) => {
        formData.append(`files[${i}]`, file);
      });
    }

    selectedDocuments.forEach((id, i) => {
      formData.append(`loan_docs[${i}]`, id);
    });

    submit(formData);
    hideModal();
  };

  const reUploadFile = (files) => {
    const formData = new FormData();

    if (files) {
      formData.append('file', files[0]);
    } else {
      formData.append('loan_doc', selectedDocuments[0]);
    }

    submit(formData);
    hideModal();
  };

  const handleRequestSend = () => {
    if (selectedDocuments.length) {
      if (reUpload) {
        reUploadFile();
      } else {
        attachRequestsDocuments();
      }
    }
  };

  const handleDocumentUpload = (files) => {
    if (files) {
      if (reUpload) {
        reUploadFile(files);
      } else {
        attachRequestsDocuments(files);
      }
    }
  };

  const filteredRecommendedDocuments = recommended.filter(
    (folder) => !!folder.documents.length
  );

  const filteredSectionsDocuments = sections.reduce((acc, section) => {
    const filteredSectionFolders = section.folders.filter(
      (folder) => !!folder.documents.length
    );

    if (filteredSectionFolders.length) {
      return [...acc, { ...section, folders: filteredSectionFolders }];
    } else {
      return acc;
    }
  }, []);

  return (
    <ModalWithContentWrapper>
      <ModalHeading>Attach Files</ModalHeading>
      <ModalBody>
        <FileUploadWrapper>
          <DocumentRejectionHandler>
            {({ handleOnDropReject }) => (
              <FileUpload
                onDrop={handleDocumentUpload}
                multiple={!reUpload}
                accept={allowedDocTypes.common}
                onDropRejected={handleOnDropReject}
              >
                <UploadBtn>UPLOAD FILES</UploadBtn>
              </FileUpload>
            )}
          </DocumentRejectionHandler>
        </FileUploadWrapper>
        {filteredRecommendedDocuments.map((folder) => (
          <div key={folder.id}>
            <FolderTitle>
              <FolderIcon />
              {folder.name}
            </FolderTitle>
            <DocumentsList>
              {folder.documents.map((docItem) => (
                <FileItem
                  key={docItem.id}
                  onClick={() => {
                    handleDocumentClick(docItem.id);
                  }}
                >
                  <DocumentIcon />
                  <Text>{docItem.filename}</Text>
                  <StyledCheckCircle
                    variant={selectedDocuments.indexOf(docItem.id) !== -1 && 'blue'}
                  />
                </FileItem>
              ))}
            </DocumentsList>
          </div>
        ))}
        {filteredSectionsDocuments.map((section) => (
          <div key={section.sectionId}>
            <SectionName>{section.sectionName}</SectionName>
            {section.folders.map((folder) => (
              <div key={folder.id}>
                <FolderTitle>
                  <FolderIcon />
                  {folder.name}
                </FolderTitle>
                <DocumentsList>
                  {folder.documents.map((docItem) => (
                    <FileItem
                      key={docItem.id}
                      onClick={() => {
                        handleDocumentClick(docItem.id);
                      }}
                    >
                      <DocumentIcon />
                      <Text>{docItem.filename}</Text>
                      <StyledCheckCircle
                        variant={selectedDocuments.indexOf(docItem.id) !== -1 && 'blue'}
                      />
                    </FileItem>
                  ))}
                </DocumentsList>
              </div>
            ))}
          </div>
        ))}
      </ModalBody>
      <ModalActions>
        <Button secondary onClick={handleRequestSend}>
          Attach documents
        </Button>
      </ModalActions>
    </ModalWithContentWrapper>
  );
};

const SectionName = styled(Text)`
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 16px;
`;

const FolderTitle = styled(Text)`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid #e8e9ed;

  svg {
    margin-right: 8px;
  }
`;

const DocumentsList = styled.div`
  padding-left: 24px;
`;

const StyledCheckCircle = styled(CheckCircle)``;

const FileItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  cursor: pointer;

  &:hover {
    text-decoration: underline;

    ${StyledCheckCircle} {
      background-color: #4394ea;

      svg polygon {
        fill: #fff;
      }
    }
  }

  ${Text} {
    margin: 0 auto 0 8px;
  }
`;

const UploadBtn = styled.button`
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  color: #4394ea;
  font-size: 10px;
  background-color: #fafafb;
  cursor: pointer;

  &::before {
    content: url('/images/icons/ico_upload_small.svg');
    margin-right: 8px;
  }
`;

const FileUploadWrapper = styled.div`
  margin-bottom: 24px;
`;
