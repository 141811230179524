import types from 'constants/actionTypes';
import immutable from 'object-path-immutable';

const initialState = {
  profile: {},
  installedApps: {
    list: [],
    isFetched: false,
  },
  isFetched: false,
};

export function profile(state, action = {}) {
  const currentState = state || initialState;

  const { type, payload } = action;

  switch (type) {
    case types.GET_LENDING_CRITERIA_SUCCESS:
    case types.REMOVE_LENDING_CRITERIA_BROCHURE_SUCCESS:
      return { ...currentState, ...payload, isFetched: true };

    case types.GET_USER_PROFILE_SUCCESS: {
      return immutable.set(currentState, 'profile', action.payload);
    }

    case types.CHANGE_USER_PROFILE_SUCCESS: {
      return immutable.set(currentState, 'profile', action.payload);
    }

    case types.RESET_PROFILE:
    case types.CHANGE_LENDING_CRITERIA_REQUEST: {
      return initialState;
    }

    case types.GET_INSTALLED_APPS_SUCCESS: {
      return {
        ...currentState,
        installedApps: {
          list: payload.appsList,
          isFetched: true,
        },
      };
    }

    case types.REMOVE_INSTALLED_APP_SUCCESS: {
      return {
        ...currentState,
        installedApps: {
          ...currentState.installedApps,
          list: currentState.installedApps.list.filter(({ id }) => id !== payload.id),
        },
      };
    }

    default:
      return currentState;
  }
}
