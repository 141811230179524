import React from 'react';
import {
  ModalBody,
  ModalControls,
  ModalHeading,
  ModalWithContentWrapper,
} from 'ui/Modal/styles';
import { Button } from 'ui/Button';
import { Input, WrappedRadio } from 'components/Form';
import { Form, Field } from 'react-final-form';
import styled from 'styled-components/macro';
import { Text } from 'ui/Text';
import { LENDER_TEAM_MEMBER_TYPES } from 'constants/appRelated';
import { simpleMemoize, delay } from 'lib/utils';
import { validateEmail } from 'utils/validate';

import {
  addLenderCompanyMemberRequest,
  checkLenderCompanyMemeberEmailRequest,
} from 'features/LenderCompany/actions';

const validateEmailAllowed = simpleMemoize(async (email, dispatch) => {
  await delay(1000).then(
    () =>
      new Promise((resolve, reject) => {
        dispatch(checkLenderCompanyMemeberEmailRequest({ email, resolve, reject }));
      })
  );
});

export class LenderCompanyTeamInvite extends React.Component {
  validate = async (values) => {
    const errors = {};

    if (!values.firstName) {
      errors.firstName = 'First name is required';
    }
    if (!values.lastName) {
      errors.lastName = 'Last name is required';
    }
    if (!values.email) {
      errors.email = 'Еmail is required';
    }
    if (!validateEmail(values.email)) {
      errors.email = 'Еmail is required';
    }

    if (Object.keys(errors).length) {
      return errors;
    }

    try {
      await validateEmailAllowed(values.email, this.props.dispatch);
    } catch (e) {
      errors.email = e.message;
    }

    return errors;
  };

  render() {
    const { hideModal, dispatch, teamId, canAssignCompanyAdmin } = this.props;

    return (
      <ModalWithContentWrapper>
        <ModalHeading>Invite team member</ModalHeading>
        <ModalBody>
          <Form
            initialValues={{ permissionsLevel: LENDER_TEAM_MEMBER_TYPES.USER }}
            validate={this.validate}
            onSubmit={(values) => {
              dispatch(addLenderCompanyMemberRequest({ ...values, teamId }));
              hideModal();
            }}
          >
            {({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <FormPartsWrapper>
                  <div>
                    <Field
                      autoComplete="off"
                      tabIndex="1"
                      component={Input}
                      name="firstName"
                      required
                      label="First name"
                    />
                    <Field
                      autoComplete="off"
                      tabIndex="1"
                      component={Input}
                      name="lastName"
                      required
                      label="Last name"
                    />
                    <Field
                      autoComplete="off"
                      tabIndex="1"
                      component={Input}
                      name="email"
                      required
                      label="Email"
                    />
                  </div>
                  <div>
                    <SwitchSubtitle>Member Status</SwitchSubtitle>
                    <RadioBtnWrapper>
                      <Field
                        component={WrappedRadio}
                        name="permissionsLevel"
                        label="User"
                        value={LENDER_TEAM_MEMBER_TYPES.USER}
                        type="radio"
                      />
                    </RadioBtnWrapper>
                    <RadioBtnWrapper>
                      <Field
                        component={WrappedRadio}
                        name="permissionsLevel"
                        label="Team Admin"
                        value={LENDER_TEAM_MEMBER_TYPES.ADMIN}
                        type="radio"
                        subtitle="can manage team users"
                      />
                    </RadioBtnWrapper>
                    {canAssignCompanyAdmin && (
                      <RadioBtnWrapper>
                        <Field
                          component={WrappedRadio}
                          name="permissionsLevel"
                          label="Company Admin"
                          value={LENDER_TEAM_MEMBER_TYPES.COMPANY_ADMIN}
                          type="radio"
                          subtitle="can manage teams and users"
                        />
                      </RadioBtnWrapper>
                    )}
                  </div>
                </FormPartsWrapper>
                <ModalControls alignCenter>
                  <Button type="submit">Send Invitation</Button>
                </ModalControls>
              </form>
            )}
          </Form>
        </ModalBody>
      </ModalWithContentWrapper>
    );
  }
}

const FormPartsWrapper = styled.div`
  display: flex;
  margin-bottom: 28px;

  & > div {
    flex-basis: 50%;
    flex-shrink: 0;

    &:first-child {
      border-right: 1px solid #e8e9ed;
      padding-right: 24px;
    }

    &:last-child {
      padding-left: 24px;
    }
  }
`;

const SwitchSubtitle = styled(Text)`
  margin-bottom: 20px;
  font-weight: bold;
`;

const RadioBtnWrapper = styled.div`
  margin-bottom: 20px;
  position: relative;
`;
