import React, { useEffect } from 'react';
import styled from 'styled-components/macro';
import { Recent } from './components/Recent';
import Result from './components/Result';
import { connect } from 'react-redux';
import { 
    setSearchBar, 
    setShowSearchBarInResultPage, 
    setTags,
    getSearchResultsRequest, 
    getSearchHistoryRequest,
    moreScrollingSearchResults,
} from 'features/GlobalSearch/actions';
import SearchBar from 'features/GlobalSearch/components/SearchBar';
import { hideModal } from 'actions/modalActions';
import { positionModal } from 'actions/modalActions';
import { Link } from 'react-router-dom';
import { createLoadingSelector, getRoughRequestAction } from 'lib/state';
import { Spinner } from 'components/Spinner';
import { HIGHLIGHTER_TAG_REGEXP, HIGHLIGHTER_NEW_TAG } from 'constants/appRelated';
import { setAttrHighlight } from 'utils/globalSearch';
import { history } from 'store';

const GlobalSearch = ({
    setShowSearchBarInResultPage, 
    setSearchBar, 
    searchBar, 
    setTags,
    tags,
    hideModal,
    getSearchResults,
    positionModal,
    searchResults,
    isFetching,
    accountType,
    getSearchHistory,
    searchHistory,
    moreScrollingSearchResults,
}) => {

    useEffect(() => {
        let currentModalPosition = 'defTopPosition';

        if(!searchBar) getSearchHistory();
        if(searchBar){
            currentModalPosition = 'isSearchTopPosition';
        }
        if(!searchResults.results?.length && searchBar){
            currentModalPosition = 'noMatchesTopPosition';
        }

        positionModal(currentModalPosition);
        setShowSearchBarInResultPage(searchBar);
    }, [searchBar, searchResults]);

    // redirect on search result page by Enter press
    useEffect(() => {
        const listener = event => {
            if (event.code === "Enter" || event.code === "NumpadEnter") {
                event.preventDefault();
                hideModal(false);
                history.push("/dashboard/search-result"); 
            }
        }
        document.addEventListener("keydown", listener);
        return () => {
          document.removeEventListener("keydown", listener);
        };
    }, [searchResults]);

    const handleClearTags = (currentTag) => {
        const chosenTags = tags.chosen.filter((tag) => tag.value !== currentTag.value && tag);
        const allTags = [...tags.all, currentTag]

        setTags({ all: allTags, chosen: chosenTags });
        getSearchResults({keyword: searchBar, tags: chosenTags});
    }

    const handleTags = (currentTag) => {
        const chosenTags = [...tags.chosen, currentTag];
        const allTags = tags.all.filter((tag) => tag.value !== currentTag.value && tag);

        setTags({ all: allTags, chosen: chosenTags });
        getSearchResults({keyword: searchBar, tags: chosenTags});
    }

    const handleChooseRecentText = (currentResentText) => {
        setSearchBar(currentResentText);
        moreScrollingSearchResults(true);
        getSearchResults({keyword: currentResentText, tags: tags.chosen});
    }

    /* Tag will new feature in search bar */
    return(
    <>
        <SearchBar
            isModalSearch
            chosen={tags.chosen.length}
            // children={
            //     !!tags.chosen.length &&
            //     <SearchTagsWrapper>
            //         {tags.chosen.map((tag) => 
            //             <Tag
            //                 isNotClickable
            //                 key={tag.value}
            //                 name={tag.label}
            //                 handleClearTags={() => handleClearTags(tag)} 
            //             /> 
            //         )}
            //     </SearchTagsWrapper>
            // }
        />

        {!!searchResults.results?.length && 
        <SearchResultLabel 
            onClick={() => {hideModal(false)}} 
            to={"/dashboard/search-result"}
        >
            <span>{
                `Show all search results
                ${tags.chosen.length ? 'in ' + tags.chosen.map((tag) => tag.label).join(', ') : ''}
                for “${searchBar}” `}
            </span>
        </SearchResultLabel>}

        <SearchHelpers> 
            {/* {!!tags.all.length &&           
            <SearchTags>
                <Title>Search in...</Title>
                <SearchTagsWrapper>
                    {tags.all.map((tag) => 
                        <Tag 
                            key={tag.value} 
                            handleTags={() => handleTags(tag)} 
                            name={tag.label}
                        /> 
                    )}
                </SearchTagsWrapper>
            </SearchTags>} */}

            {!searchBar ? 
            <SearchRecent>
                <Title>Recent searches</Title>
                <SearchRecentWrapper>
                    { searchHistory.recent.map((text, index) => 
                        <Recent
                            handleChooseRecentText={() => handleChooseRecentText(text.query)} 
                            key={index}  
                            text={text.query}
                        />)
                    }
                </SearchRecentWrapper>
            </SearchRecent> 
            : isFetching ?
                <Spinner show defStyles = {{postiton: true, width: true, height: true}} /> 
            : searchResults.results?.length ? 
            <SearchResult>
                {searchResults.results?.slice(0, 5).map((result) => 
                    <Result
                        highlights={setAttrHighlight(result.highlights)}
                        roomChatId={result.obj?.room}
                        messageId={result.obj?.id}
                        task={result.obj} 
                        document={{name: result?.obj?.filename,  uuid: result.obj?.loan?.uuid}}
                        checklist={result?.obj}
                        uuid={result.obj?.uuid}
                        accountType={accountType}
                        key={result.id}  
                        type={result.type}
                        label={result.highlights?.name.replace(HIGHLIGHTER_TAG_REGEXP,  HIGHLIGHTER_NEW_TAG)} 
                        clientId={result.obj?.pk}
                    />)
                }
            </SearchResult>
            : 
            <div>No matches</div>}
        </SearchHelpers>
    </> 
   )
}

const loadingSelector = createLoadingSelector([
    getRoughRequestAction(getSearchResultsRequest().type),
]);

const mapStateToProps = (state) => ({
    searchBar: state.globalSearch.searchBar,
    searchHistory: state.globalSearch.searchHistory,
    tags: state.globalSearch.globalSearchTags,
    searchResults: state.globalSearch.searchResults,
    isFetching: loadingSelector(state),
    accountType: state.currentUser.accountType,
});
  
const mapDispatchToProps = {
    setSearchBar,
    setShowSearchBarInResultPage,
    setTags,
    hideModal,
    getSearchResults: getSearchResultsRequest,
    positionModal,
    getSearchHistory: getSearchHistoryRequest,
    moreScrollingSearchResults,
};
  
export default connect( mapStateToProps, mapDispatchToProps )(GlobalSearch);


const SearchResult = styled.div`
    margin-bottom: 14px;
`;

const SearchResultLabel = styled(Link)`
    display: block;
    background: rgba(67, 148, 234, 0.1);
    padding: 15px 0 17px 40px;
    margin-top: 8px; 
    position: relative;
    span{
        font-size: 14px;
        line-height: 16px;
        color: #778598;
    }

    &:before {
        content: '';
        z-index: 3;
        position: absolute;
        left: 12px;
        top: 50%;
        transform: translateY(-50%);
        height: 18px;
        width: 18px;
        background: url('/images/icons/ico_search_gray.svg') no-repeat;
      }
`;

const SearchTagsWrapper = styled.div`
    display: flex;
    overflow-y: auto;
    padding-bottom: 10px;
`;

const SearchRecentWrapper = styled.div`

`;

const Title = styled.h1`
    font-size: 12px;
    line-height: 16px;
    color: #778598;
    font-style: normal;
    font-weight: normal;
    margin: 0 0 16px;
`;

const SearchHelpers = styled.div`
    margin: 16px 16px 25px;
`;

const SearchRecent = styled.div`
    margin-bottom: 14px;
`;

const SearchTags = styled.div`
    margin-bottom: 15px;
`;