import types from 'constants/actionTypes';

export const standaloneLoanDataRequest = (payload = {}) => ({
  type: types.STANDALONE_LOAN_DATA_REQUEST,
  payload,
});
export const standaloneLoanDataSuccess = (payload = {}) => ({
  type: types.STANDALONE_LOAN_DATA_SUCCESS,
  payload,
});
export const standaloneLoanDataFailure = (payload = {}) => ({
  type: types.STANDALONE_LOAN_DATA_FAILURE,
  payload,
});

export const standaloneLoanReset = (payload = {}) => ({
  type: types.STANDALONE_LOAN_RESET,
  payload,
});
