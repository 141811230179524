import React from 'react';
import styled from 'styled-components/macro';
import { isOfferAuthor } from 'features/LoanNew/helpers';
import { Button, ButtonGhost } from 'ui/Button';
import { LENDER } from 'constants/appRelated';
import { Link } from 'react-router-dom';

import { OfferButton } from './';

const Wrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px 24px;
  z-index: 10;
  background: #fff;
  text-align: right;
`;

export const OfferControls = ({
  negotiation,
  hasAccepted,
  accountType,
  edit,
  offerResolution,
  startEditing,
  loanClosed,
  hasMessage,
  messageUuid,
  hideModal,
}) => (
  <Wrapper>
    {edit && (
      <Button type="submit" mr={2}>
        Submit
      </Button>
    )}
    {!loanClosed && !(negotiation && (hasAccepted || negotiation.state === 'rejected')) && (
      <React.Fragment>
        {!edit && (
          <React.Fragment>
            {hasMessage && messageUuid && accountType !== LENDER && (
              <Link
                to={`/dashboard/chat/loans/${negotiation.chatId}?message_uuid=${messageUuid}`}
                onClick={hideModal}
              >
                <Button secondary mr={2}>
                  Open in chat
                </Button>
              </Link>
            )}
            {!isOfferAuthor(negotiation) && (
              <OfferButton
                callback={() => {
                  offerResolution('reject');
                }}
              >
                Pass
              </OfferButton>
            )}
            <OfferButton callback={startEditing}>
              {isOfferAuthor(negotiation) ? 'Edit' : 'Negotiate'}
            </OfferButton>
            {!isOfferAuthor(negotiation) && (
              <OfferButton
                callback={() => {
                  offerResolution('accept');
                }}
              >
                Accept
              </OfferButton>
            )}
          </React.Fragment>
        )}
        {negotiation && accountType === LENDER && (
          <ButtonGhost
            onClick={() => {
              offerResolution('cancel');
            }}
          >
            Cancel quote
          </ButtonGhost>
        )}
      </React.Fragment>
    )}
  </Wrapper>
);
