import React from 'react';
import styled from 'styled-components';
import { Section } from 'components/Modals/ManualOfferModal/styles';
import { EditableValue } from 'components/Modals/Offer/components';
import { Text } from 'ui/Text';
import { DisplayLoanValue } from 'components/Loans';
import { Field } from 'react-final-form';
import { Textarea } from 'components/Form';

const LoanValueDisplay = ({ field, loan, type }) => {
  if (type === 'indexSpread') {
    return (
      <>
        <DisplayLoanValue field="index" value={loan.index} /> +{' '}
        <DisplayLoanValue field="spread" value={loan.spread} />
      </>
    );
  }

  return <DisplayLoanValue field={field} value={loan[field]} />;
};

export const OfferFields = ({ fields, loan }) => (
  <Wrapper>
    <Row>
      <RowCell />
      <RowCell>
        <Title>Loan request</Title>
      </RowCell>
      <RowCell>
        <Title>Lender’s quote</Title>
      </RowCell>
    </Row>
    {fields.map((row) =>
      !row.hidden ? (
        <Row key={row.field}>
          <RowCell>
            <Title>{row.title}</Title>
          </RowCell>
          <RowCell>
            <Text>
              <LoanValueDisplay field={row.field} type={row.type} loan={loan} />
            </Text>
          </RowCell>
          <RowCell>
            <EditableValue row={row} isLender={false} />
          </RowCell>
        </Row>
      ) : null
    )}
    <CommentWrapper>
      <Text mb={3}>ADD COMMENT</Text>
      <Field component={Textarea} name="message" rows={2} expandable />
    </CommentWrapper>
  </Wrapper>
);

const Wrapper = styled(Section)`
  flex: 1 1 auto;
  overflow: auto;
`;

const Row = styled.div`
  display: flex;
  margin-bottom: 24px;
`;

const RowCell = styled.div`
  flex: 1;
`;

const Title = styled(Text)`
  font-weight: bold;
`;

const CommentWrapper = styled.div`
  margin-bottom: 25px;
`;
