import React from 'react';
import styled from 'styled-components/macro';

const DocumentItemActions = ({handlerActionFile, srces, item, actions}) =>  (
    <DocumentItemActionsWrapper>
        <img onClick={() => handlerActionFile(actions[0], item)} alt="" src={srces[0]}/>
        <img onClick={() => handlerActionFile(actions[1], item)} alt="" src={srces[1]}/>
    </DocumentItemActionsWrapper>
);


const DocumentItemActionsWrapper = styled.div`
    img:first-child{
        margin-right: 10px;
    }
`;


export default DocumentItemActions;
