import React, { Component } from 'react';
import { connect } from 'react-redux';
import { modals } from 'constants/modalTypes';
import { showModal, hideModal } from 'actions/modalActions';
import { filter, equals } from 'ramda';
import { Form } from 'react-final-form';
import { normalizeDollars } from 'lib/normalizers';
import { formDecorator } from 'lib/form';
import { takeOfferData, takeLastOffer, makeSelectItem } from 'lib/loan';
import { isOfferAuthor } from 'features/LoanNew/helpers';
import {
  submitOfferRequest,
  negotiateOfferRequest,
  editOfferRequest,
  acceptOfferRequest,
  rejectOfferRequest,
} from 'features/LoanNew/actions';
import { indexOptions, fixedOrVariableOptions, recourseOptions } from 'utils/fields';
import { unitOptions } from 'utils/fields';
import { LENDER, LOAN_STATUS, FIELD_TYPES } from 'constants/appRelated';
import { OfferRequest, OfferRequestField } from 'styles/offer';
import styled from 'styled-components/macro';
import { showLenderAcceptOfferPopup, showBBAcceptOfferPopup } from 'actions/modalActions';

import { offerFields } from './config';
import {
  OfferRow,
  OfferTitle,
  OfferControls,
  OfferAttaches,
  OfferComments,
  OfferDocuments,
} from './components';
import { normalizeOfferData } from './helpers';

const focusOnErrors = formDecorator();

const formValidator = (values) => {
  const errors = {};
  const message = "can't be blank";

  if (!values.loanAmount) {
    errors.loanAmount = message;
  }

  if (!values.index && !values.spread && !values.interest) {
    errors.index = message;
    errors.spread = message;
    errors.interest = message;
  }

  if (!values.index && values.spread && !values.interest) {
    errors.index = message;
  }

  if (values.index && !values.spread && !values.interest) {
    errors.spread = message;
  }

  return errors;
};

class Offer extends Component {
  constructor(props) {
    super(props);
    const { negotiation } = props;

    this.state = {
      edit: !negotiation || props.forceEdit,
      documents: [],
      additionInfoVisible: !!negotiation?.messages?.length,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (!equals(nextProps.negotiation, this.props.negotiation)) {
      if (!nextProps.negotiation) {
        this.setState({ edit: true, documents: [] });
      } else {
        this.setState({ edit: false });
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.negotiation.pk !== this.props.negotiation.pk) {
      this.setState({
        additionInfoVisible: !!this.props.negotiation?.messages?.length,
      });
    }
  }

  onDrop = (files) => {
    this.setState((state) => ({
      documents: [
        ...state.documents,
        { file: files[0], filename: files[0].name || 'file', id: files[0].preview },
      ],
    }));
  };

  removeUploadingDocument = (id) => {
    this.setState((state) => ({
      documents: state.documents.filter((file) => file.id !== id),
    }));
  };

  startEditing = (e) => {
    e.preventDefault();
    this.setState({ edit: true });
  };

  formSubmit = (values) => {
    const {
      negotiation,
      loan: { id },
      dispatch,
      userId,
      accountType,
      afterSubmit,
      dealRooms,
      hideQuotesWidget
    } = this.props;
    const { documents } = this.state;

    let type;

    if (!negotiation) {
      type = 'new';
    } else {
      type = isOfferAuthor(negotiation) ? 'edit' : 'negotiate';
    }

    const offerData = takeOfferData(negotiation, userId, accountType);

    const request = filter((field) => field !== null && field !== undefined, {
      ...normalizeOfferData(
        offerFields.reduce((acc, arr) => [...acc, ...arr], []),
        values
      ),
      message: values.message,
    });

    const negotiationPk = negotiation && negotiation.pk;
    const offerId = negotiation && offerData && offerData.id;
    switch (type) {
      case 'new':
        dispatch(
          submitOfferRequest({
            offer: request,
            documents,
            id,
            dealRooms,
            userId,
          })
        );
        break;

      case 'negotiate':
        this.closeModal();
        dispatch(
          negotiateOfferRequest({
            offer: request,
            documents,
            id,
            negotiation_pk: negotiationPk,
            afterSubmit,
          })
        );
        break;

      case 'edit':
        this.closeModal();
        dispatch(
          editOfferRequest({
            offer: request,
            documents,
            id,
            negotiation_pk: negotiationPk,
            offer_id: offerId,
          })
        );
        break;
      default:
    }
    hideQuotesWidget();
  };

  offerResolution = (type) => {
    const {
      dispatch,
      negotiation: { pk },
      loan: { id },
      afterSubmit,
      accountType,
      hideQuotesWidget
    } = this.props;

    switch (type) {
      case 'accept':
        this.closeModal();
        dispatch(acceptOfferRequest({ id, negotiation_pk: pk, afterSubmit }));

        if (accountType === LENDER) {
          dispatch(showLenderAcceptOfferPopup());
        } else {
          dispatch(showBBAcceptOfferPopup());
        }
        break;        
      case 'reject':
        dispatch(
          showModal(modals.REJECT_OFFER_MODAL, {
            size: 'sm',
            asLender: accountType === LENDER,
            onSubmit: ({ reason }) => {
              dispatch(
                rejectOfferRequest({ id, negotiation_pk: pk, afterSubmit, reason })
              );
            },
          })
        );
        break;

      case 'cancel':
        dispatch(showModal(modals.CANCEL_OFFER, { id, negotiation_pk: pk }));
        break;

      default:
    }
    hideQuotesWidget();
  };

  closeModal = () => {
    const { dispatch } = this.props;
    dispatch(hideModal());
  };

  renderFields = (arrayNumber) => {
    const { loan, negotiation, accountType } = this.props;
    const { edit } = this.state;
    const isLender = accountType === LENDER;
    const isOfferNew = !negotiation;
    let leftColumn = loan;
    let rightColumn = negotiation ? negotiation.offers[0] : loan;

    if (edit) {
      leftColumn =
        (!isOfferNew &&
          takeLastOffer({ offers: negotiation.offers, rule: ({ isMy }) => !isMy })) ||
        loan;
    } else if (isOfferNew || negotiation.offers.length === 1) {
      leftColumn = loan;
      rightColumn = negotiation ? negotiation.offers[0] : loan;
    } else {
      leftColumn = takeLastOffer({
        offers: negotiation.offers,
        rule: ({ isMy }) => !isMy,
      });
      rightColumn = takeLastOffer({
        offers: negotiation.offers,
        rule: ({ isMy }) => isMy,
      });
    }

    return offerFields[arrayNumber].map(
      (row, i) =>
        !row.hidden && (
          <OfferRow
            row={row}
            displayValues={leftColumn}
            editableValues={rightColumn}
            edit={edit}
            key={`${i}-offer-row`}
            isLender={isLender}
            newOffer={isOfferNew || negotiation.offers.length === 1}
          />
        )
    );
  };

  toggleAdditionInfo = () => {
    this.setState((state) => ({ additionInfoVisible: !state.additionInfoVisible }));
  };

  render() {
    const {
      loan,
      negotiation,
      accountType,
      userId,
      hasAccepted,
      initialValues,
      isSoftQuote,
      loanClosed,
      negotiation: { messages, documents },
      hideModal,
    } = this.props;
    const { edit, additionInfoVisible } = this.state;
    const messageUuid = negotiation?.offers?.[0]?.chatMessageUuid;
    return (
      <Form
        onSubmit={this.formSubmit}
        validate={(values) => formValidator(values)}
        decorators={[focusOnErrors]}
        initialValues={initialValues}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <OfferRequest>
              <OfferTitle
                edit={edit}
                negotiation={negotiation}
                accountType={accountType}
              />
              <OfferRequestField>{loan && this.renderFields(0)}</OfferRequestField>
              <ToggleBtn onClick={this.toggleAdditionInfo} type="button">
                {additionInfoVisible ? '- Hide' : '+ Show'} additional info
              </ToggleBtn>
              {additionInfoVisible && (
                <React.Fragment>
                  <OfferRequestField>{loan && this.renderFields(1)}</OfferRequestField>
                  {messages && messages.length > 0 && (
                    <OfferComments
                      messages={messages}
                      isLender={accountType === 3}
                      userId={userId}
                    />
                  )}
                  {documents && documents.length > 0 && (
                    <OfferDocuments documents={documents} />
                  )}
                  {edit && (
                    <OfferAttaches
                      isSoftQuote={isSoftQuote}
                      documents={this.state.documents}
                      onDrop={this.onDrop}
                      removeUploadingDocument={this.removeUploadingDocument}
                    />
                  )}
                </React.Fragment>
              )}
            </OfferRequest>
            <OfferControls
              negotiation={negotiation}
              userId={userId}
              hasAccepted={hasAccepted}
              accountType={accountType}
              edit={edit}
              offerResolution={this.offerResolution}
              startEditing={this.startEditing}
              loanClosed={loanClosed}
              hasMessage={!!(messages && messages.length)}
              messageUuid={messageUuid}
              hideModal={hideModal}
            />
          </form>
        )}
      />
    );
  }
}

function mapStateToProps(state, props) {
  const { negotiation, loan } = props;
  const {
    currentUser: { id, accountType },
    loanNew: {data: {dealRooms}},
  } = state;
  const offer = takeOfferData(negotiation, id, accountType) || loan;
  const initialValues = {};

  const isSoftQuote = accountType === 3 && !negotiation;

  offerFields
    .reduce((acc, arr) => [...acc, ...arr], [])
    .forEach((item) => {
      const { type, field } = item;
      const value = !isSoftQuote ? offer[field] ?? null : null;

      if (type === FIELD_TYPES.PERCENT) {
        initialValues[field] = value;
      } else if (type === FIELD_TYPES.DOLLARS) {
        initialValues[field] = normalizeDollars(String(value));
      } else {
        initialValues[field] = value;
      }
    });

  initialValues.index = makeSelectItem(initialValues.index, indexOptions);
  initialValues.fixedOrVariable = makeSelectItem(
    initialValues.fixedOrVariable,
    fixedOrVariableOptions
  );

  initialValues.loanTermUnit = offer.loanTermUnit ? unitOptions[1] : unitOptions[0];
  initialValues.interestOnlyUnit = offer.interestOnlyUnit
    ? unitOptions[1]
    : unitOptions[0];
  initialValues.recourse = makeSelectItem(initialValues.recourse, recourseOptions);

  const loanClosed =
    loan.status === LOAN_STATUS.CLOSED_LOST ||
    loan.status === LOAN_STATUS.CLOSED_WON ||
    loan.status === LOAN_STATUS.CLOSED_WITHDRAWN;

  return {
    initialValues,
    userId: id,
    accountType,
    negotiation,
    loanAmount: loan.loanAmount,
    loanClosed,
    isSoftQuote,
    dealRooms,
  };
}

export default connect(mapStateToProps)(Offer);

const ToggleBtn = styled.button`
  padding: 0;
  margin-bottom: 20px;
  border: none;
  background-color: #fafafb;
  cursor: pointer;
  font-weight: bold;
  outline: none;
`;
