import React, { useState } from 'react';
import { Input as SearchInput } from 'ui/Input';
import { connect } from 'react-redux';
import { 
  setTags,
  setSearchBar, 
  getSearchResultsRequest, 
  setShowSearchBarInResultPage,
  clearSearchResults,
  moreScrollingSearchResults,
  getSearchCountSuccess,
} from 'features/GlobalSearch/actions';
import styled, { css } from 'styled-components/macro';
import { searchTags } from 'features/GlobalSearch/config';

const SearchBar = ({
  setShowSearchBarInResultPage, 
  children, 
  chosen, 
  setSearchBar, 
  getSearchResults, 
  searchBar, 
  isModalSearch,
  setTags,
  tags,
  clearSearchResults,
  moreScrollingSearchResults,
  openGlobalSearch,
  getSearchCount,
}) => {
    const [searchTimeout, setSearchTimeout] = useState({ typingTimeout: 0 });     

    const handleClear = () => {
        setSearchBar('');
        clearSearchResults();
        getSearchCount({});
    }

    const handlerHideSearchBar = () => {
      getSearchCount({});
      setSearchBar('');
      clearSearchResults();
      setShowSearchBarInResultPage(false);
      setTags({all: searchTags, chosen: []});
    }
    
    const handleSearch = ({target: { value }}) => {      
      searchTimeout.typingTimeout && clearTimeout(searchTimeout.typingTimeout);
      moreScrollingSearchResults(true);
      setSearchBar(value);
      if(value.length > 2){
        setSearchTimeout({
          typingTimeout: setTimeout(function () {
              getSearchResults({keyword: value, tags: value ? tags.chosen : []});
          }, 500)
        });
      }else{
        clearSearchResults();
      }
    }
    
    return (
        <SearchInputWrapper chosen={chosen} isModalSearch={isModalSearch}>
            { children }
            
            { isModalSearch ? (
              <SearchInput
                className={'search-input'}
                onChange={handleSearch}
                value={searchBar}
                placeholder={'Type here to search'}
              />
            ) : (
              <SearchResult onClick={openGlobalSearch}>
                <span className={'forDesktop'}>Search results for "{searchBar}"</span>
                <span className={'forMobile'}>{searchBar}</span>
              </SearchResult>
            )}
            
            {searchBar && <ClearInput onClick={isModalSearch ? handleClear : handlerHideSearchBar}>Clear</ClearInput>}
        </SearchInputWrapper>
    );
}


const mapStateToProps = (state) => ({
    searchBar: state.globalSearch.searchBar,
    tags: state.globalSearch.globalSearchTags,
});
  
const mapDispatchToProps = {
    setSearchBar,
    getSearchResults: getSearchResultsRequest,
    getSearchCount: getSearchCountSuccess,
    setShowSearchBarInResultPage,
    setTags,
    clearSearchResults,
    moreScrollingSearchResults,
};
  
export default connect( mapStateToProps, mapDispatchToProps )(SearchBar);

const SearchResult = styled.div`
  margin-left: 15px;
  height: 40px;
  width: 100%;
  cursor: pointer;

  @media(max-width: 425px){
    display: flex;
    align-items: center;

    font-size: 12px;
    margin-left: 5px;
  }

  span{
    height: 100%;
    display: flex;
    align-items: center; 
  }
  .forMobile{
    display: none;
  }

  .forDescktop{
    @media(max-width: 375px){
      display: none;
    }
  }
  @media(max-width: 375px){
    .forDesktop{
      display: none;
    }    
    .forMobile{
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 80px;
      height: auto;
    }
  }
  @media(max-width: 320px){
    .forMobile{
      width: 90px;
    }
  }
`

const ClearInput = styled.span`
    z-index: 3;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #778598;
    margin-left: 15px;
    cursor: pointer;
    position: absolute;
    right: 5%;

    @media(max-width: 768px){
      right: 7%;
    }

    @media(max-width: 600px){
      right: 9%;
    }

    @media(max-width: 425px){
      right: 12%;
    }

    @media(max-width: 375px){
      right: 14%;
    }

    @media(max-width: 320px){
      font-size: 11px;
      right: 20%;
    }
`;

const SearchInputWrapper = styled.div`
  width: 100%;
  position: relative;
  margin-top: 10px;

  div[class^="GlobalSearch__SearchTagsWrapper-"]{
    padding-left: 40px;
    overflow-y: initial;
    padding-bottom: 0;

    //width: calc(40% - 50px);

    @media(max-width: 960px){
      overflow-y: auto;
      width: ${(p) => p.chosen > 3 && '700px'};
      padding: 5px 0 10px 0;
      margin-left: 40px;
    }

    @media(max-width: 768px){
      width: ${(p) => p.chosen > 1 && '350px'};
    }

    @media(max-width: 600px){
      width: ${(p) => p.chosen > 1 && '250px'};
    }

    @media(max-width: 425px){
      width: 100px};
    }
  }  
  ${(p) => p.isModalSearch ?
    css`
      border-bottom: 1px solid #E8E9ED;
    `
    :
    css`
      border: 1px solid #E8E9ED;
      box-sizing: border-box;
      border-radius: 4px;
    `
  }

  display: flex;
  align-items: center;

  .search-input{
    width: 90%;
    margin-bottom: 0px;

    //width: calc(60% - 20px);
  }

  .switchTo-input{
    margin-bottom: 0px;
  }

  ${(p) => p.isModalSearch && 
    `
      &:before {
        content: '';
        z-index: 3;
        position: absolute;
        left: 12px;
        top: 50%;
        transform: translateY(-50%);
        height: 18px;
        width: 18px;
        background: url('/images/icons/ico_search_gray.svg') no-repeat;
      }
    `
  }
  input {
    padding-left: ${(p) => !p.isModalSearch || p.chosen ? '15px' : '40px'};
    border: none;
    border-radius: initial;
    background:  ${(p) => p.isModalSearch &&  '#fafafb'};

    margin-bottom: 0px; 
    height: 40px;
    width: 100%;

    @media(max-width: 550px){
      width: ${(p) => p.chosen > 0 ? '75%' : '90%'};
    }
  }

  input::placeholder{
    font-size: 14px;
    line-height: 16px;
    color: #CFD0D7;
  }
`;