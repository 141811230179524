import React from 'react';
import {
  ModalBody,
  ModalHeading,
  ModalWithContentWrapper,
  ModalActions,
} from 'ui/Modal/styles';
import { Button } from 'ui/Button';

import { Form, Field } from 'react-final-form';
import { Textarea } from 'components/Form';
import { closeLoanRequest } from 'features/MyLoans/actions';

export class LoanDealLostForm extends React.Component {
  closeLoan = (values) => {
    const { loanId, dispatch, hideModal, loan } = this.props;

    dispatch(closeLoanRequest({ loanId, closeType: 'lost', data: values, loan }));
    hideModal();
  };
  render() {
    return (
      <Form
        onSubmit={this.closeLoan}
        render={({ handleSubmit, validating }) => (
          <form onSubmit={handleSubmit}>
            <ModalWithContentWrapper>
              <ModalHeading>Add a reason why the deal is lost</ModalHeading>
              <ModalBody>
                <Field
                  component={Textarea}
                  placeholder="Type in a lost reason"
                  name="reason"
                  validate={(value) =>
                    value && value.trim() ? undefined : 'Reason is required'
                  }
                  mb={0}
                  rows={4}
                />
              </ModalBody>
              <ModalActions>
                <Button secondary>Close loan</Button>
              </ModalActions>
            </ModalWithContentWrapper>
          </form>
        )}
      />
    );
  }
}
