export const loanRequestFields = [
  'loanType',
  'planUseProperty',
  'loanAmount',
  'loanTerm',
  'desiredClosingDate',
];

export const propertyDetailsFields = ['propertyType'];

export const sponsorProfileFields = [
  // 'story',
  'liquidity',
  'ficoScore',
  'netWorth',
  'yearsOfExperienceInCre',
  'avgPropertyPrice',
  'investmentOwn',
  'propertiesPurchased',
  'state',
];
