import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { Text } from 'ui/Text';

const RadioWrap = styled.label`
  display: flex;
  align-items: flex-start;
  margin-right: 0;
  cursor: pointer;

  input {
    display: none;

    & + span {
      margin-right: 8px;
      position: relative;
      border-radius: 50%;
      display: block;
      width: 16px;
      height: 16px;
      border: 1px solid ${(p) => p.theme.colors.blues[6]};
      box-sizing: initial;
    }

    &:checked + span {
      &:after {
        content: '';
        position: absolute;
        top: 4px;
        left: 4px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: ${(p) => p.theme.colors.blues[6]};
      }
    }
  }

  &[disabled] {
    opacity: 0.7;
    cursor: not-allowed;
    pointer-events: none;
  }
`;

const Subtitle = styled.span`
  color: #778598;
`;

export const Radio = ({
  label,
  name,
  disabled,
  mr,
  checked,
  value,
  onChange,
  className,
  subtitle,
  ...rest
}) => (
  <RadioWrap disabled={disabled} mr={mr} className={className} data-testid="radioBtn">
    <input
      type="radio"
      name={name}
      checked={checked}
      value={value}
      className={checked ? 'checked' : ''}
      onChange={(e) => {
        if (onChange) {
          if (Number.isInteger(Number(e.target.value))) {
            onChange(Number(e.target.value));
          } else {
            onChange(e.target.value);
          }
        }
      }}
      {...rest}
    />
    <span />
    <div>
      <Text mb="0">{label}</Text>
      {subtitle && <Subtitle>{subtitle}</Subtitle>}
    </div>
  </RadioWrap>
);

Radio.propTypes = {
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
};

Radio.defaultProps = {
  checked: false,
  disabled: false,
  label: '',
  name: '',
};

Radio.displayName = 'Radio';
