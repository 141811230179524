import { put, takeLatest, call, takeEvery, select } from 'redux-saga/effects';
import types from 'constants/actionTypes';
import * as API from 'api';
import { decamelizeKeys } from 'humps';
import { getError } from 'sagas/utils';
import { normalize } from 'normalizr';
import { contactsDirectoryListSchema, clientsDirectoryListSchema } from 'utils/schemas';

import {
  newClientInviteSuccess,
  clientDirectoryInviteSuccess,
  clientDirectoryInviteFailure,
  getClientsDirectoryDataFailure,
  getClientsDirectoryDataSuccess,
  checkClientDirectoryEmailSuccess,
  checkClientDirectoryEmailFailure,
  updateClientDirectoryDataSuccess,
  updateClientDirectoryDataFailure,
  getMoreClientsFailure,
  getMoreClientsSuccess
} from './actions';

function* inviteClients(action) {
  const { request } = action.payload;
  try {
    const data = {};
    const {
      data: { response },
    } = yield call(API.axiosApi.post, `clients/?no_page=${true}`, decamelizeKeys(request));
    const { entities, result } = normalize([response], contactsDirectoryListSchema);

    data.clients = { ...entities.contacts };
    data.clientsArray = result;

    yield put(newClientInviteSuccess({ response }));
    yield put(clientDirectoryInviteSuccess({ ...data }));
  } catch (e) {
    console.log(e);
    yield put(clientDirectoryInviteFailure({ message: getError(e) }));
  }
}

function* getMoreClients(action) {
  try {
    let nextPage = action.payload;
    const requestUri = '';
    const {
      data: { response },
    } = yield call(
      API.axiosApi.get,
      `clients?page=${nextPage}${requestUri ? `&${requestUri}` : ''}`
    );

    const { entities, result } = normalize(response.results, clientsDirectoryListSchema);
    let newClients = { ...entities.clients };
    let newClientsArray = result;
    let pageNumber = response.next ? new URL(response.next).searchParams.get("page") : null;
    let count = response.count;

    yield put(getMoreClientsSuccess({ newClients, newClientsArray, pageNumber, count }));
  } catch (e) {
    yield put(getMoreClientsFailure());
  }
}

function* getData(action) {
  try {
    const paginationFlag = action.payload;
    const data = {};
    const {
      data: { response },
    } = yield call(API.axiosApi.get, `clients/${paginationFlag ? '' : '?no_page=true'}`);

    const { entities, result } = normalize(paginationFlag ? response.results : response, contactsDirectoryListSchema);
    data.clients = entities.contacts;
    data.clientsArray = result || [];
    data.pageNumber = response.next ? new URL(response.next).searchParams.get("page") : null;
    data.count = response.count;
    yield put(getClientsDirectoryDataSuccess(data));
  } catch (e) {
    console.log(e);
    yield put(getClientsDirectoryDataFailure({ message: getError(e) }));
  }
}

function* checkClientEmail(action) {
  const { email, reject, resolve } = action.payload;
  try {
    const {
      data: { response },
    } = yield call(API.axiosApi.post, `clients/check_email/`, { email });

    const data = { result: response && response.result };
    yield put(checkClientDirectoryEmailSuccess(data));

    if (response.result === 'exists') {
      reject('A user with this email is already in your Client Directory');
    } else if (response.result === 'its_lender') {
      reject(
        'User with this email is already registered as a lender. Please use another email.'
      );
    } else {
      resolve();
    }
  } catch (e) {
    console.error(e);
    yield put(checkClientDirectoryEmailFailure());
    reject();
  }
}

function* updateClientDirectoryData(action) {
  try {
    const { searchString, page } = action.payload;
    const data = {};
    const {
      data: { response },
    } = yield call(
      API.axiosApi.get,
      `clients${searchString ? `?search=${searchString}` : ''}${page ? '' : '&no_page=true'}`
    );
    const { entities, result } = normalize(page ? response.results : response, contactsDirectoryListSchema);
    data.clients = entities.contacts;
    data.clientsArray = result || [];
    data.pageNumber = response.next ? new URL(response.next).searchParams.get("page") : null;
    data.count = response.count;

    yield put(updateClientDirectoryDataSuccess({ ...data, searchString }));
  } catch (e) {
    yield put(updateClientDirectoryDataFailure({ message: getError(e) }));
  }
}

export default [
  takeLatest(types.GET_MORE_CLIENTS_REQUEST, getMoreClients),
  takeLatest(types.CLIENT_DIRECTORY_INVITE_REQUEST, inviteClients),
  takeLatest(types.GET_CLIENTS_DIRECTORY_DATA_REQUEST, getData),
  takeEvery(types.CHECK_CLIENT_DIRECTORY_EMAIL_REQUEST, checkClientEmail),
  takeEvery(types.UPDATE_CLIENT_DIRECTORY_DATA_REQUEST, updateClientDirectoryData),
];
