import React from 'react';
import { Text } from 'ui/Text';
import styled from 'styled-components/macro';
import { Tooltip } from 'ui/Tooltip';

import { DatePicker } from 'ui/DatePicker';

export const NewDatepicker = ({
  input: { onChange, value },
  meta: { touched, error } = {},
  label,
  input,
  minDate,
  mb,
  hint,
  placeholder,
  variant,
  withoutInput,
  required,
  semiRequired,
  className,
  ...rest
}) => (
  <Container data-testid="datePickerContainer" className={className}>
    <Title>
      <Text fontSize="0" mr={2} color={touched && error ? 'error' : ''} fontFamily="muli">
        {label}
        {required && <Asterix> *</Asterix>}
      </Text>
      {hint && <Tooltip text={hint} />}
    </Title>
    <DatePicker
      onChange={onChange}
      value={value}
      error={touched && error}
      minDate={minDate}
      placeholder={placeholder}
      variant={variant}
      withoutInput={withoutInput}
      semiRequired={semiRequired}
      {...rest}
    />
    {touched && error && (
      <Text fontSize="12px" color="error">
        {error}
      </Text>
    )}
  </Container>
);

const Container = styled.div`
  margin-bottom: ${(p) =>
    ((p.mb || typeof p.mb === 'number') && `${p.theme.space[p.mb]}px`) || '24px'};
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;

const Asterix = styled.span`
  color: #e32019;
`;
