import types from 'constants/actionTypes';

const initialState = {};

export default function loanActions(state = initialState, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case types.LOAN_ACTIONS_SUCCESS:
      return {
        ...state,
        ...payload,
        id: payload.uuid
      };

    case types.UPLOAD_ACTION_DOCUMENT_SUCCESS:
      return state.id ? { ...state, ...payload, id: payload.uuid} : state;

    case types.DELETE_ACTION_DOCUMENT_SUCCESS:
      return state.id
        ? {
            ...state,
            documents: state.documents.filter((document) => document.id !== payload.id),
          }
        : state;

    default:
      return state;
  }
}
