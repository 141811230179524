import React from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import { Text } from 'ui/Text';
import { Heading } from 'ui/Heading';
import { Button } from 'ui/Button';

const Wrapper = styled.div`
  padding: 20px 40px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled(Heading)`
  font-size: 24px;
  font-weight: 500;
  padding: 0 30px;
  margin-bottom: 24px;
`;

const CustomText = styled(Text)`
  margin-bottom: 20px;
  line-height: 1.5em;

  &:last-child {
    margin-bottom: 40px;
  }
`;

const AcceptButton = styled(Button)`
  width: 100%;
  background-image: linear-gradient(124deg, #ffe269 -90%, #fd8440 100%);
`;

const InfoIcon = styled.div`
  width: 40px;
  height: 38px;
  margin-bottom: 24px;
  background: url(/images/icons/ico_info_success.svg) no-repeat center center;
`;

const ConfirmButtonsBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const ResetPasswordRequestSuccess = ({ hideModal, email }) => (
  <Wrapper>
    <InfoIcon />
    <Title>Email has been sent</Title>
    <CustomText>
      If there is an account associated with <b>{email}</b>, you’ll receive an email with
      a link to create a new password.
    </CustomText>
    <CustomText>
      If you don’t see this email in your inbox within 15 minutes, look for it in your
      junk mail folder. If you find it there, please mark the email as Not Junk and add{' '}
      <b>info@realatom.com</b> to your address book.
    </CustomText>

    <ConfirmButtonsBox>
      <AcceptButton onClick={hideModal}>OK</AcceptButton>
    </ConfirmButtonsBox>
  </Wrapper>
);

ResetPasswordRequestSuccess.propTypes = {
  email: PropTypes.string,
  hideModal: PropTypes.func,
};
