export const NAV_SECTIONS = {
  OVERVIEW: 0,
  LOAN_REQUEST: 1,
  PROPERTY_DETAILS: 2,
  EXECUTIVE_SUMMARY: 3,
  MARKET_INFO: 4,
  SPONSOR_PROFILE: 5,
  DOCUMENTS: 6,
  OFFERS_LIST: 7,
  SU: 8,
  INVESTMENT_HIGHLIGHTS: 9,
  QUOTES: 10,
  MARKETING_DESCRIPTION: 11,
};

export const NAV_TITLES = {
  [NAV_SECTIONS.OVERVIEW]: 'OVERVIEW',
  [NAV_SECTIONS.LOAN_REQUEST]: 'LOAN REQUEST',
  [NAV_SECTIONS.PROPERTY_DETAILS]: 'PROPERTY DETAILS',
  [NAV_SECTIONS.EXECUTIVE_SUMMARY]: 'EXECUTIVE SUMMARY',
  [NAV_SECTIONS.MARKET_INFO]: 'MARKET INFO',
  [NAV_SECTIONS.SPONSOR_PROFILE]: 'SPONSOR PROFILE',
  [NAV_SECTIONS.DOCUMENTS]: 'DOCUMENTS',
  [NAV_SECTIONS.OFFERS_LIST]: 'QUOTES',
  [NAV_SECTIONS.SU]: 'S&U',
  [NAV_SECTIONS.INVESTMENT_HIGHLIGHTS]: 'INVESTMENT HIGHLIGHTS',
  [NAV_SECTIONS.QUOTES]: 'QUOTES',
  [NAV_SECTIONS.MARKETING_DESCRIPTION]: 'MARKETING DESCRIPTION',
};

const bluredText =
  'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.';

export const bluredContent = {
  address: 'This is the secret address',
  summary: bluredText,
  marketInfo: bluredText,
  sponsorProfile: bluredText,
  questions: [
    {
      id: 1,
      question: 'Is this the best CRE platform on the market?',
      answer: 'Yes, it is!',
    },
    {
      id: 2,
      question: 'Is this the best CRE platform on the market?',
      answer: 'Yes, it is!',
    },
    {
      id: 3,
      question: 'Is this the best CRE platform on the market?',
      answer: 'Yes, it is!',
    },
    {
      id: 4,
      question: 'Is this the best CRE platform on the market?',
      answer: 'Yes, it is!',
    },
  ],
};
