import React from 'react';
import { Form, Field } from 'react-final-form';
import { Textarea } from 'components/Form';
import styled from 'styled-components/macro';
import {
  ModalBody,
  ModalHeading,
  ModalWithContentWrapper,
  ModalActions,
} from 'ui/Modal/styles';
import { Button } from 'ui/Button';

const Text = styled.p`
  margin: 0 0 40px;
  padding: 0;
  line-height: 1.5em;
  text-align: center;
`;

const CancelButton = styled(Button)`
  margin-right: 16px;
`;

export const RejectOfferModal = ({ hideModal, onSubmit, asLender }) => (
  <Form
    onSubmit={({ reason }) => {
      onSubmit({ reason });
      hideModal();
    }}
    render={({ handleSubmit }) => (
      <form onSubmit={handleSubmit} data-testid="offer-pass-form">
        <ModalWithContentWrapper>
          <ModalHeading>
            {asLender
              ? 'Tell client why you are passing'
              : 'Tell the lender why you are passing'}
          </ModalHeading>
          <ModalBody>
            <Text>
              {asLender
                ? `Providing a reason will help the client better understand what you are looking for and provide a better quote.`
                : `Providing a reason will help the lender better understand what you are looking for and provide a better quote.`}
            </Text>
            <Field
              component={Textarea}
              rows={4}
              name="reason"
              validate={(value) => (value && value.trim() ? undefined : "Can't be blank")}
            />
          </ModalBody>
          <ModalActions>
            <CancelButton secondary onClick={hideModal}>
              Cancel
            </CancelButton>
            <Button type="submit">Pass</Button>
          </ModalActions>
        </ModalWithContentWrapper>
      </form>
    )}
  />
);
