import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';
import { Text } from 'ui/Text';

export const SectionWrapperCommon = styled.div`
  margin: 0 15px;
  width: 50%;
  flex-grow: 1;

  @media (max-width: ${(p) => p.theme.breakpoints[1]}px) {
    display: none;
    width: 100%;
  }
`;

export const SectionWrapper = styled(SectionWrapperCommon)`
  @media (max-width: ${(p) => p.theme.breakpoints[1]}px) {
    ${(p) => p.isVisible && 'display: block'};
  }
`;

export const SectionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 -15px;
`;

export const StatsContainerSub = styled.div`
  display: flex;
  flex-shrink: 0;
  justify-content: space-between;
  min-width: 50%;
  align-items: flex-start;
`;

export const StatsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  overflow: auto;

  ${StatsContainerSub}:first-child {
    > *:first-child {
      margin-left: 0;
    }
  }
  ${StatsContainerSub}:last-child {
    > *:last-child {
      margin-right: 0;
    }
  }

  @media (max-width: ${(p) => p.theme.breakpoints[1]}px) {
    margin: 0 0 2px;
  }
`;

export const SectionHead = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 48px;
  width: 100%;
  padding-left: 24px;
  margin-bottom: 16px;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 2px 14px rgba(194, 198, 210, 0.2);

  @media (max-width: ${(p) => p.theme.breakpoints[1]}px) {
    padding: 0;
    margin: 0;
    height: auto;
    box-shadow: none;
    background-color: transparent;

    p {
      display: none;
    }
  }
`;

export const NotificationWrapper = styled.div`
  padding: 16px 24px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 4px 10px rgba(194, 198, 210, 0.2);
  margin-bottom: 16px;
  position: relative;
`;

export const NotificationImageWrapper = styled.div`
  width: 38px;
  flex-shrink: 0;
`;

export const NotificationHeadline = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;

export const HoverContent = styled.span`
  display: none;
  position: absolute;
  left: calc(100% + 18px);
  top: -5px;
  display: none;
  padding: 5px 10px;
  background-color: #fff;
  box-shadow: 0 2px 14px 0 rgba(194, 198, 210, 0.5);
  z-index: 100;
  white-space: nowrap;

  &:after {
    content: '';
    position: absolute;
    left: -5px;
    top: 8px;
    width: 12px;
    height: 12px;
    background-color: #fff;
    transform: rotate(45deg);
    box-shadow: -2px 3px 3px 0 rgba(194, 198, 210, 0.2);
  }

  &:before {
    content: '';
    position: absolute;
    width: 30px;
    left: -30px;
    height: 100%;
  }
`;

export const NotificationDate = styled(Text)`
  font-size: 12px;
  line-height: 16px;
  color: #778598;

  flex-shrink: 0;
  position: relative;

  @media (min-width: ${(p) => p.theme.breakpoints[1]}px) {
    &:hover {
      ${HoverContent} {
        display: block;
      }
    }
  }
`;

export const NotificationTitle = styled.div`
  display: flex;
  font-weight: 600;
  width: 100%;
  font-size: 14px;
`;

export const NotificationBottomLine = styled.div`
  margin-top: 16px;
  border-top: 1px solid #e8e9ed;
  padding-top: 16px;
`;

export const LoanTitle = styled(Link)`
  font-weight: 600;
  margin-bottom: 16px;
  color: #4394ea;
  text-decoration: none;
  display: block;
`;

export const LinkButton = styled(Link)`
  text-decoration: none;
`;

export const MemberWrapper = styled.span`
  display: block;
  margin-left: 8px;
`;

export const NoNotifications = styled.p`
  text-align: center;
  font-weight: 600;
  font-size: 16px;
`;

export const RemoveNotification = styled.button`
  position: relative;
  cursor: pointer;
  appearance: none;
  border: none;
  position: absolute;
  bottom: 26px;
  right: 16px;
  background-color: #fff;
  z-index: 2;

  svg {
    position: relative;
    z-index: -1;
  }

  &:focus {
    outline: none;
  }

  @media (min-width: ${(p) => p.theme.breakpoints[1]}px) {
    &:hover {
      ${HoverContent} {
        display: block;
      }
    }
  }
`;
