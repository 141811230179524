import React from 'react';
import styled, { css } from 'styled-components/macro';
import { UserRepresentation } from 'styles/global';

export class QuestionItem extends React.PureComponent {
  state = { expanded: false };

  toggleQuestion = () => {
    this.setState((state) => ({ expanded: !state.expanded }));
  };

  render() {
    const {
      question,
      answer,
      id,
      openQuestionForm,
      isOwner,
      isPublic,
      lender,
      lenderId,
      showLenderDetails,
    } = this.props;

    const { expanded } = this.state;
    return (
      <Wrapper>
        {!!(isOwner && lender) && (
          <Author>
            Question by:{' '}
            <UserRepresentation onClick={() => showLenderDetails(lenderId)}>
              {lender}
            </UserRepresentation>
          </Author>
        )}
        <Head clickable={answer} onClick={answer ? this.toggleQuestion : null}>
          <Title expanded={!expanded}>
            {!!expanded && 'Question: '}
            {question}
          </Title>
          {answer && <Icon expanded={expanded} />}
        </Head>
        {expanded && (
          <div>
            <QuestionText>{`Answer: ${answer}`}</QuestionText>
          </div>
        )}
        {!answer && isOwner && (
          <AnswerButton
            data-id={id}
            data-question={question}
            data-variant={isPublic ? 'public' : 'private'}
            onClick={openQuestionForm}
          >
            Answer
          </AnswerButton>
        )}
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  padding-bottom: 16px;
  margin-bottom: 16px;
  border-bottom: 1px solid #e8e9ed;
`;

const Head = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;

  ${(p) =>
    p.clickable &&
    css`
      cursor: pointer;
    `}
`;

const Title = styled.p`
  font-weight: 600;
  margin: 0;
  line-height: 21px;

  ${(p) =>
    p.expanded &&
    css`
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    `}
`;

const QuestionText = styled.p`
  font-size: 14px;
  line-height: 21px;
  color: #2f243a;
  margin: 0;
`;

const IconCommon = styled.span`
  margin: 0;
  padding: 5px;
  outline: none;
  border: none;

  &::before {
    content: url('/images/icons/ico_chevron_black_down.svg');
  }
`;

const Icon = styled(IconCommon)`
  ${(p) =>
    p.expanded &&
    css`
      &::before {
        content: url('/images/icons/ico_chevron_black_up.svg');
      }
    `}
`;

const AnswerButton = styled.button`
  color: #4394ea;
  cursor: pointer;
  appearance: none;
  border: none;
  padding: 0;
`;

const Author = styled.div`
  font-size: 10px;
`;
