import types from 'constants/actionTypes';

export const getDealRoomRequest = (payload = {}) => ({
  type: types.GET_DEAL_ROOM_REQUEST,
  payload,
});
export const getDealRoomSuccess = (payload = {}) => ({
  type: types.GET_DEAL_ROOM_SUCCESS,
  payload,
});
export const getDealRoomFailure = (payload = {}) => ({
  type: types.GET_DEAL_ROOM_FAILURE,
  payload,
});

export const acceptDocsConfidentialityAgreementRequest = (payload = {}) => ({
  type: types.ACCEPT_DOCS_CONFIDENTIALITY_AGREEMENT_REQUEST,
  payload,
});
export const acceptDocsConfidentialityAgreementSuccess = (payload = {}) => ({
  type: types.ACCEPT_DOCS_CONFIDENTIALITY_AGREEMENT_SUCCESS,
  payload,
});
export const acceptDocsConfidentialityAgreementFailure = (payload = {}) => ({
  type: types.ACCEPT_DOCS_CONFIDENTIALITY_AGREEMENT_FAILURE,
  payload,
});

export const updateDealRoomDocumentStateRequest = (payload = {}) => ({
  type: types.UPDATE_DEAL_ROOM_DOCUMENT_STATE_REQUEST,
  payload,
});
export const updateDealRoomDocumentStateSuccess = (payload = {}) => ({
  type: types.UPDATE_DEAL_ROOM_DOCUMENT_STATE_SUCCESS,
  payload,
});
export const updateDealRoomDocumentStateFailure = (payload = {}) => ({
  type: types.UPDATE_DEAL_ROOM_DOCUMENT_STATE_FAILURE,
  payload,
});

export const createDealRoomRequestSignature = (payload = {}) => ({
  type: types.CREATE_DEAL_ROOM_REQUEST_SIGNATURE_REQUEST,
  payload,
});

export const setReviewFileSuccess = (payload = {}) => ({
  type: types.SET_REVIEW_FILE_SUCCESS,
  payload,
})

export const setReviewFileFailure = (payload = {}) => ({
  type: types.SET_REVIEW_FILE_FAILURE,
  payload,
})

export const setReviewFileRequest = (payload = {}) => ({
  type: types.SET_REVIEW_FILE_REQUEST,
  payload,
})

export const setDealRoomRejectionReason = (payload = {}) => ({
  type: types.DEAL_ROOM_REJECTION_REASON,
  payload,
});

export const setDeleteFileRequest = (payload = {}) => ({
  type: types.SET_DELETE_FILE_REQUEST,
  payload,
});

export const setDeleteFileSuccess = (payload = {}) => ({
  type: types.SET_DELETE_FILE_SUCCESS,
  payload,
});

export const setDeleteFileFailure = (payload = {}) => ({
  type: types.SET_DELETE_FILE_FAILURE,
  payload,
});

export const setUpdateFileRequest = (payload = {}) => ({
  type: types.SET_UPDATE_FILE_REQUEST,
  payload,
});

export const setUpdateFileSuccess = (payload = {}) => ({
  type: types.SET_UPDATE_FILE_SUCCESS,
  payload,
});

export const setUpdateFileFailure = (payload = {}) => ({
  type: types.SET_UPDATE_FILE_FAILURE,
  payload,
});

export const saveFilesRequest = (payload = {}) => ({
  type: types.SAVE_FILES_REQUEST,
  payload,
});

export const addFilesSuccess = (payload = {}) => ({
  type: types.SAVE_FILES_SUCCESS,
  payload,
});

export const addFilesFailure = (payload = {}) => ({
  type: types.SAVE_FILES_FAILURE,
  payload,
});

export const getFilesRequest = (payload = {}) => ({
  type: types.GET_FILES_REQUEST,
  payload,
});

export const getFilesSuccess = (payload = {}) => ({
  type: types.GET_FILES_SUCCESS,
  payload,
});

export const getFilesFailure = (payload = {}) => ({
  type: types.GET_FILES_FAILURE,
  payload,
});

export const setUploadSignedFileSuccess = (payload = {}) => ({
  type: types.SET_UPLOAD_SIGNED_FILE_SUCCESS,
  payload,
});

export const setUploadSignedFileFailure = (payload = {}) => ({
  type: types.SET_UPLOAD_SIGNED_FILE_FAILURE,
  payload,
}); 

export const setUploadSignedFileRequest = (payload = {}) => ({
  type: types.SET_UPLOAD_SIGNED_FILE_REQUEST,
  payload,
});

export const createDealRoomRequestSignatureSuccess = (payload = {}) => ({
  type: types.CREATE_DEAL_ROOM_REQUEST_SIGNATURE_SUCCESS,
  payload,
});

export const createDealRoomChecklistRequest = (payload = {}) => ({
  type: types.CREATE_DEAL_ROOM_CHECKLIST_REQUEST,
  payload,
});
export const createDealRoomChecklistSuccess = (payload = {}) => ({
  type: types.CREATE_DEAL_ROOM_CHECKLIST_SUCCESS,
  payload,
});
export const createDealRoomChecklistFailure = (payload = {}) => ({
  type: types.CREATE_DEAL_ROOM_CHECKLIST_FAILURE,
  payload,
});

export const editDealRoomChecklistRequest = (payload = {}) => ({
  type: types.EDIT_DEAL_ROOM_CHECKLIST_REQUEST,
  payload,
});
export const editDealRoomChecklistSuccess = (payload = {}) => ({
  type: types.EDIT_DEAL_ROOM_CHECKLIST_SUCCESS,
  payload,
});
export const editDealRoomChecklistFailure = (payload = {}) => ({
  type: types.EDIT_DEAL_ROOM_CHECKLIST_FAILURE,
  payload,
});

export const attachDealRoomFilesRequest = (payload = {}) => ({
  type: types.ATTACH_DEAL_ROOM_FILES_REQUEST,
  payload,
});
export const attachDealRoomFilesSuccess = (payload = {}) => ({
  type: types.ATTACH_DEAL_ROOM_FILES_SUCCESS,
  payload,
});
export const attachDealRoomFilesFailure = (payload = {}) => ({
  type: types.ATTACH_DEAL_ROOM_FILES_FAILURE,
  payload,
});

export const reUploadDealRoomFileRequest = (payload = {}) => ({
  type: types.RE_UPLOAD_DEAL_ROOM_FILE_REQUEST,
  payload,
});
export const reUploadDealRoomFileSuccess = (payload = {}) => ({
  type: types.RE_UPLOAD_DEAL_ROOM_FILE_SUCCESS,
  payload,
});
export const reUploadDealRoomFileFailure = (payload = {}) => ({
  type: types.RE_UPLOAD_DEAL_ROOM_FILE_FAILURE,
  payload,
});
export const setDealroomClosingDateRequest = (payload = {}) => ({
  type: types.SET_DEALROOM_CLOSING_DATE_REQUEST,
  payload,
});
export const setDealroomClosingDateSuccess = (payload = {}) => ({
  type: types.SET_DEALROOM_CLOSING_DATE_SUCCESS,
  payload,
});
export const setDealroomClosingDateFailure = (payload = {}) => ({
  type: types.SET_DEALROOM_CLOSING_DATE_FAILURE,
  payload,
});

export const markDealDocumentCompleteRequest = (payload = {}) => ({
  type: types.MARK_DEAL_DOCUMENT_COMPLETE_REQUEST,
  payload,
});
export const markDealDocumentCompleteSuccess = (payload = {}) => ({
  type: types.MARK_DEAL_DOCUMENT_COMPLETE_SUCCESS,
  payload,
});
export const markDealDocumentCompleteFailure = (payload = {}) => ({
  type: types.MARK_DEAL_DOCUMENT_COMPLETE_FAILURE,
  payload,
});

export const dealDocumentsDownloadRequest = (payload = {}) => ({
  type: types.DEAL_DOCUMENTS_DOWNLOAD_REQUEST,
  payload,
});
export const dealDocumentsDownloadSuccess = (payload = {}) => ({
  type: types.DEAL_DOCUMENTS_DOWNLOAD_SUCCESS,
  payload,
});
export const dealDocumentsDownloadFailure = (payload = {}) => ({
  type: types.DEAL_DOCUMENTS_DOWNLOAD_FAILURE,
  payload,
});

export const deleteDealRoomFileRequest = (payload = {}) => ({
  type: types.DELETE_DEAL_ROOM_FILE_REQUEST,
  payload,
});
export const deleteDealRoomFileSuccess = (payload = {}) => ({
  type: types.DELETE_DEAL_ROOM_FILE_SUCCESS,
  payload,
});
export const deleteDealRoomFileFailure = (payload = {}) => ({
  type: types.DELETE_DEAL_ROOM_FILE_FAILURE,
  payload,
});

export const restoreDealRoomFileRequest = (payload = {}) => ({
  type: types.RESTORE_DEAL_ROOM_FILE_REQUEST,
  payload,
});
export const restoreDealRoomFileSuccess = (payload = {}) => ({
  type: types.RESTORE_DEAL_ROOM_FILE_SUCCESS,
  payload,
});
export const restoreDealRoomFileFailure = (payload = {}) => ({
  type: types.RESTORE_DEAL_ROOM_FILE_FAILURE,
  payload,
});

export const deleteDealDocumentRequest = (payload = {}) => ({
  type: types.DELETE_DEAL_DOCUMENT_REQUEST,
  payload,
});
export const deleteDealDocumentSuccess = (payload = {}) => ({
  type: types.DELETE_DEAL_DOCUMENT_SUCCESS,
  payload,
});
export const deleteDealDocumentFailure = (payload = {}) => ({
  type: types.DELETE_DEAL_DOCUMENT_FAILURE,
  payload,
});

export const restoreDealDocumentRequest = (payload = {}) => ({
  type: types.RESTORE_DEAL_DOCUMENT_REQUEST,
  payload,
});
export const restoreDealDocumentSuccess = (payload = {}) => ({
  type: types.RESTORE_DEAL_DOCUMENT_SUCCESS,
  payload,
});
export const restoreDealDocumentFailure = (payload = {}) => ({
  type: types.RESTORE_DEAL_DOCUMENT_FAILURE,
  payload,
});

export const markDealDocumentIncompleteRequest = (payload = {}) => ({
  type: types.MARK_DEAL_DOCUMENT_INCOMPLETE_REQUEST,
  payload,
});
export const markDealDocumentIncompleteSuccess = (payload = {}) => ({
  type: types.MARK_DEAL_DOCUMENT_INCOMPLETE_SUCCESS,
  payload,
});
export const markDealDocumentIncompleteFailure = (payload = {}) => ({
  type: types.MARK_DEAL_DOCUMENT_INCOMPLETE_FAILURE,
  payload,
});

export const createDealRoomWithDocumentsRequest = (payload = {}) => ({
  type: types.CREATE_DEAL_ROOM_WITH_DOCUMENTS_REQUEST,
  payload,
});
export const createDealRoomWithDocumentsSuccess = (payload = {}) => ({
  type: types.CREATE_DEAL_ROOM_WITH_DOCUMENTS_SUCCESS,
  payload,
});
export const createDealRoomWithDocumentsFailure = (payload = {}) => ({
  type: types.CREATE_DEAL_ROOM_WITH_DOCUMENTS_FAILURE,
  payload,
});
