import types from 'constants/actionTypes';

export const getChatRoomsRequest = (payload = {}) => ({
  type: types.GET_CHAT_ROOMS_REQUEST,
  payload,
});

export const getChatRoomsSuccess = (payload = {}) => ({
  type: types.GET_CHAT_ROOMS_SUCCESS,
  payload,
});

export const getChatRoomsFailure = (payload = {}) => ({
  type: types.GET_CHAT_ROOMS_FAILURE,
  payload,
});

export const getAdditionalChatRoomsRequest = ({ cursor, tabType }) => ({
  type: types.GET_ADDITIONAL_CHAT_ROOMS_REQUEST,
  payload: { cursor, tabType },
});

export const getAdditionalChatRoomsSuccess = (payload = {}) => ({
  type: types.GET_ADDITIONAL_CHAT_ROOMS_SUCCESS,
  payload,
});

export const getAdditionalChatRoomsFailure = (payload = {}) => ({
  type: types.GET_ADDITIONAL_CHAT_ROOMS_FAILURE,
  payload,
});

export const getChatRoomRequest = ({ roomId, tabType }) => ({
  type: types.GET_CHAT_ROOM_REQUEST,
  payload: { id: roomId, tabType },
});

export const getChatRoomSuccess = (payload = {}) => ({
  type: types.GET_CHAT_ROOM_SUCCESS,
  payload,
});

export const getChatRoomFailure = (payload = {}) => ({
  type: types.GET_CHAT_ROOM_FAILURE,
  payload,
});

export const uploadChatDocumentRequest = ({ file, roomId, accountType, tabType }) => ({
  type: types.UPLOAD_CHAT_DOCUMENT_REQUEST,
  payload: { room: roomId, file, accountType, tabType },
});

export const uploadChatDocumentSuccess = (payload = {}) => ({
  type: types.UPLOAD_CHAT_DOCUMENT_SUCCESS,
  payload,
});

export const uploadChatDocumentFailure = (payload = {}) => ({
  type: types.UPLOAD_CHAT_DOCUMENT_FAILURE,
  payload,
});

export const getAdditionalChatRoomRequest = ({ roomId, cursor, tabType, direction }) => ({
  type: types.GET_ADDITIONAL_CHAT_ROOM_REQUEST,
  payload: { roomId, cursor, tabType, direction },
});

export const getAdditionalChatRoomSuccess = (payload = {}) => ({
  type: types.GET_ADDITIONAL_CHAT_ROOM_SUCCESS,
  payload,
});

export const getAdditionalChatRoomFailure = (payload = {}) => ({
  type: types.GET_ADDITIONAL_CHAT_ROOM_FAILURE,
  payload,
});

export const readRoomMessagesRequest = (roomId) => ({
  type: types.READ_ROOM_MESSAGES_REQUEST,
  payload: { roomId },
});

export const readRoomMessagesSuccess = (payload = {}) => ({
  type: types.READ_ROOM_MESSAGES_SUCCESS,
  payload,
});

export const readRoomMessagesFailure = (payload = {}) => ({
  type: types.READ_ROOM_MESSAGES_FAILURE,
  payload,
});

export const chatOfferEditRequest = (payload = {}) => ({
  type: types.CHAT_OFFER_EDIT_REQUEST,
  payload,
});

export const chatOfferEditSuccess = (payload = {}) => ({
  type: types.CHAT_OFFER_EDIT_SUCCESS,
  payload,
});

export const chatOfferEditFailure = (payload = {}) => ({
  type: types.CHAT_OFFER_EDIT_FAILURE,
  payload,
});

export const chatOfferNegotiateRequest = (payload = {}) => ({
  type: types.CHAT_OFFER_NEGOTIATE_REQUEST,
  payload,
});

export const chatOfferNegotiateSuccess = (payload = {}) => ({
  type: types.CHAT_OFFER_NEGOTIATE_SUCCESS,
  payload,
});

export const chatOfferNegotiateFailure = (payload = {}) => ({
  type: types.CHAT_OFFER_NEGOTIATE_FAILURE,
  payload,
});

export const chatOfferSubmitRequest = (payload = {}) => ({
  type: types.CHAT_OFFER_SUBMIT_REQUEST,
  payload,
});

export const chatOfferSubmitSuccess = (payload = {}) => ({
  type: types.CHAT_OFFER_SUBMIT_SUCCESS,
  payload,
});

export const chatOfferSubmitFailure = (payload = {}) => ({
  type: types.CHAT_OFFER_SUBMIT_FAILURE,
  payload,
});

export const chatOfferRejectRequest = (payload = {}) => ({
  type: types.CHAT_OFFER_REJECT_REQUEST,
  payload,
});

export const chatOfferRejectSuccess = (payload = {}) => ({
  type: types.CHAT_OFFER_REJECT_SUCCESS,
  payload,
});

export const chatOfferRejectFailure = (payload = {}) => ({
  type: types.CHAT_OFFER_REJECT_FAILURE,
  payload,
});

export const chatOfferAcceptRequest = (payload = {}) => ({
  type: types.CHAT_OFFER_ACCEPT_REQUEST,
  payload,
});

export const chatOfferAcceptSuccess = (payload = {}) => ({
  type: types.CHAT_OFFER_ACCEPT_SUCCESS,
  payload,
});

export const chatOfferAcceptFailure = (payload = {}) => ({
  type: types.CHAT_OFFER_ACCEPT_FAILURE,
  payload,
});

export const chatFileToLoanRequest = (payload = {}) => ({
  type: types.CHAT_FILE_TO_LOAN_REQUEST,
  payload,
});

export const chatFileToLoanSuccess = (payload = {}) => ({
  type: types.CHAT_FILE_TO_LOAN_SUCCESS,
  payload,
});

export const chatFileToLoanFailure = (payload = {}) => ({
  type: types.CHAT_FILE_TO_LOAN_FAILURE,
  payload,
});

export const setCurrentChatRoom = (room) => ({
  type: types.SET_CURRENT_CHAT_ROOM,
  payload: { room },
});

export const setCurrentChatTab = (tab) => ({
  type: types.SET_CURRENT_CHAT_TAB,
  payload: { tab },
});

export const resetChatData = () => ({
  type: types.RESET_CHAT_DATA,
  payload: {},
});

export const createChatTeamGroupRequest = (payload = {}) => ({
  type: types.CREATE_CHAT_TEAM_GROUP_REQUEST,
  payload,
});
export const createChatTeamGroupSuccess = (payload = {}) => ({
  type: types.CREATE_CHAT_TEAM_GROUP_SUCCESS,
  payload,
});
export const createChatTeamGroupFailure = (payload = {}) => ({
  type: types.CREATE_CHAT_TEAM_GROUP_FAILURE,
  payload,
});

export const sendOfferToChatRequest = (payload = {}) => ({
  type: types.SEND_OFFER_TO_CHAT_REQUEST,
  payload,
});
export const sendOfferToChatSuccess = (payload = {}) => ({
  type: types.SEND_OFFER_TO_CHAT_SUCCESS,
  payload,
});
export const sendOfferToChatFailure = (payload = {}) => ({
  type: types.SEND_OFFER_TO_CHAT_FAILURE,
  payload,
});

export const searchInChatRequest = (payload = {}) => ({
  type: types.SEARCH_IN_CHAT_REQUEST,
  payload,
});
export const searchInChatSuccess = (payload = {}) => ({
  type: types.SEARCH_IN_CHAT_SUCCESS,
  payload,
});
export const searchInChatFailure = (payload = {}) => ({
  type: types.SEARCH_IN_CHAT_FAILURE,
  payload,
});

export const searchMessageInChatRequest = (payload = {}) => ({
  type: types.SEARCH_MESSAGE_IN_CHAT_REQUEST,
  payload,
});
export const searchMessageInChatSuccess = (payload = {}) => ({
  type: types.SEARCH_MESSAGE_IN_CHAT_SUCCESS,
  payload,
});
export const searchMessageInChatFailure = (payload = {}) => ({
  type: types.SEARCH_MESSAGE_IN_CHAT_FAILURE,
  payload,
});

export const resetChatSearchMessage = ({ roomsType, roomId }) => ({
  type: types.RESET_CHAT_SEARCH_MESSAGE,
  payload: { roomsType, roomId },
});

export const resetSearchInChat = (payload = {}) => ({
  type: types.RESET_SEARCH_IN_CHAT,
  payload,
});
