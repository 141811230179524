import React, { PureComponent } from 'react';
import { Section, SectionTitle, EditLink, WysiwygText } from 'styles/loan';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import { bluredContent } from 'features/LoanNew/constants';
import { BlurContent } from 'components/BlurContent';

import { FieldEdited } from './';

const SectionWrapper = styled.div`
  overflow: hidden;
  max-height: ${(p) => (p.cropped ? '188px' : 'auto')};
`;

const ExpandWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const SeeMore = styled.button`
  border: none;
  outline: none;
  background: none;
  appearance: none;
  text-transform: uppercase;
  margin-top: 24px;
  font-size: 10px;
  font-weight: 600;
  color: #4394ea;
  cursor: pointer;
`;

const MAX_STRING_LENGTH = 750;

export class Summary extends PureComponent {
  componentDidMount() {
    const textHeight =
      this.textRef.current &&
      window.getComputedStyle(this.textRef.current).getPropertyValue('height');

    if (textHeight) {
      this.setState({ textHeight: Number(textHeight.replace('px', '')) });
    }
  }
  static propTypes = {
    summary: PropTypes.string,
    id: PropTypes.number,
    editable: PropTypes.bool,
    access: PropTypes.objectOf(PropTypes.bool),
    fieldsHistory: PropTypes.objectOf(PropTypes.any),
    componentRef: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({ current: PropTypes.instanceOf(HTMLDivElement) }),
    ]),
  };

  textRef = React.createRef();

  state = { isExpanded: false, textHeight: null };

  expandSection = () => {
    this.setState({ isExpanded: true });
  };

  render() {
    const {
      summary,
      editable,
      id,
      componentRef,
      fieldsHistory = {},
      isStandAloneLoan,
    } = this.props;
    const { isExpanded, textHeight } = this.state;

    const isBlured = isStandAloneLoan && !summary;

    return (
      <Section ref={componentRef} data-testid="summary">
        {editable && <EditLink to={`/dashboard/loan-creation/${id}/summary`} />}
        <SectionTitle>Executive Summary</SectionTitle>
        {fieldsHistory.summary && <FieldEdited fieldName="summary" />}
        <BlurContent blured={isBlured}>
          {isBlured ? (
            bluredContent.summary
          ) : (
            <>
              <SectionWrapper cropped={!isExpanded}>
                <WysiwygText
                  ref={this.textRef}
                  dangerouslySetInnerHTML={{ __html: summary }}
                />
              </SectionWrapper>
              {!isExpanded &&
                (summary.length >= MAX_STRING_LENGTH ||
                  (!!textHeight && textHeight > 188)) && (
                  <ExpandWrapper>
                    <SeeMore onClick={this.expandSection}>See More</SeeMore>
                  </ExpandWrapper>
                )}
            </>
          )}
        </BlurContent>
      </Section>
    );
  }
}
