import React from 'react';
import { useDispatch } from 'react-redux';
import { showAlert } from 'actions';

export const DocumentRejectionHandler = ({ children }) => {
  const dispatch = useDispatch();
  const handleOnDropReject = (files, maxSize) => {
    const file = files[0];

    if (file.size > maxSize) {
      dispatch(
        showAlert({
          message:
            'File you want to upload is too large. If you are trying to upload zip-archive, unpack it and upload files one by one',
        })
      );
    } else {
      dispatch(
        showAlert({
          message: `Incompatible file${files.length > 1 ? 's' : ''} type: ${files
            .map(({ name }) => name)
            .join(', ')}`,
        })
      );
    }
  };

  return <>{children({ handleOnDropReject })}</>;
};
