import React from 'react';
import { ModalBody, ModalHeading, ModalWithContentWrapper } from 'ui/Modal/styles';
import { FormattedRelative } from 'react-intl';

import styled from 'styled-components/macro';

export const FieldHistory = ({ fieldHistory }) => {
  return (
    <ModalWithContentWrapper>
      <ModalHeading>Editing history</ModalHeading>
      <ModalBody>
        {fieldHistory &&
          fieldHistory.map(({ user, oldValue, newValue, timestamp }) => (
            <FieldItem key={timestamp}>
              Changed{' '}
              {oldValue && (
                <span>
                  from <b>{oldValue}</b>
                </span>
              )}{' '}
              to <b>{newValue}</b> <FormattedRelative value={new Date(timestamp)} /> by{' '}
              {`${user.firstName} ${user.lastName}`}{' '}
            </FieldItem>
          ))}
      </ModalBody>
    </ModalWithContentWrapper>
  );
};

const FieldItem = styled.div`
  padding: 16px 0;
  border-top: 1px solid #e8e9ed;
`;
