import React from 'react';
import { Input as FormInput } from 'ui/Input';

export const Input = ({
  input: { name, onChange, value, ...restInput },
  label,
  withUnit,
  meta: { touched, error },
  className,
  semiRequired,
  ...rest
}) => {
  return (
    <FormInput
      name={name}
      label={label}
      error={touched && error ? error : ''}
      onChange={onChange}
      value={value}
      className={className}
      semiRequired={semiRequired}
      {...rest}
      {...restInput}
    />
  );
};
