export const NOTIFICATION_TYPES = {
  YOUR_TASK_COMPLETED: 59,
  USER_ASSIGNED_TO_TASK: 58,
  YOUR_TASK_DUE_SOON: 60,
  // # Borrower's
  LOAN_REQUEST_WAS_DECLINED: 2,
  CONCIERGE_READY_FOR_APPROVAL: 3,
  CONCIERGE_POSTED: 4,
  YOU_HAVE_OFFER: 5,
  YOU_HAVE_COUNTEROFFER: 6,
  COUNTEROFFER_REJECTED: 7,
  COUNTEROFFER_ACCEPTED: 8,
  YOU_HAVE_NEW_QUESTION: 9,
  YOU_HAVE_NEW_TERMSHEET: 10,
  LENDER_ACCEPT_INVITATION: 12,
  // # Lender's

  LENDER_SENT_LOAN: 57,

  YOUR_ACCOUNT_WAS_APPROVED: 13,
  BORROWER_MADE_COUNTEROFFER: 14,
  BORROWER_ACCEPTED_OFFER: 15,
  BORROWER_ACCEPTED_COUNTEROFFER: 16,
  BORROWER_REJECTED_OFFER: 17,
  BORROWER_REJECTED_COUNTEROFFER: 18,
  BORROWER_ANSWERED_QUESTION: 19,
  BORROWER_REJECTED_TERMSHEET: 20,
  BORROWER_SIGNED_TERMSHEET: 21,
  REQUESTED_DOCUMENT_UPLOADED: 24,
  YOU_HAVE_BEEN_MATCHED: 23,
  BORROWER_AWAITS_TERMSHEET: 25,
  BORROWER_UPLOADED_NEW_DOCUMENT: 26,
  BORROWER_UPLOAD_NEW_DEAL_DOCUMENT: 27,
  LENDER_REJECTED_DEAL_DOCUMENT: 28,
  DDR_CREATED: 29,
  // borrower team
  NEW_TEAM_MEMBER_JOINED: 40,
  // sales broker
  LENDER_MADE_PREQUALIFICATION: 41,
  NEW_DOCUMENT_REQUEST: 42,
  LENDER_PASSED_LOAN: 43,
  BORROWER_ADD_LENDER_TO_LD: 46,
  BORROWER_ADD_LENDER_TO_LOAN: 47,
  BB_INVITED_BY_LENDER: 48,
  LENDER_INVITED_TO_COMPANY: 49,
  NEW_LOAN_ASSIGNED: 50,
  LOAN_REASSIGNED_TO_DIFFERENT_LENDER: 51,
  OFFER_CANCELLED: 52,
  LENDER_ACCEPTS_DEAL_DOCUMENT: 30,
  DEAL_DOCUMENT_REQUEST_COMPLETED: 31,
  
  DEAL_DOCUMENT_SIGN_REQUEST_UPLOADED: 53,
  DEAL_DOCUMENT_BB_SIGNED_REQUEST: 54,
  DEAL_DOCUMENT_SIGN_REQUEST_ACCEPTED: 55,
  DEAL_DOCUMENT_SIGN_REQUEST_REJECTED: 56
};

export const BB_ACTIVITY_TYPES = {
  PASSED_LOAN: 'passed_loan',
  UPLOADED_TS: 'uploaded_ts',
  GOT_OFFER: 'got_offer',
  MADE_OFFER: 'made_offer',
  REQUEST_DOC: 'request_doc',
  GOT_ANSWER: 'got_answer',
  ASKED_QUESTION: 'asked_question',
  // INVITED_TO_LD: 'invited_to_ld', future
  SATISFY_DOC_REQUEST: 'satisfy_doc_request',
  // OFFER_REJECTED: 'offer_rejected', future
  // MADE_OFFLINE_OFFER: 'made_offline_offer', future
  CA_SIGNED: 'signed_loan_ca',
  LOAN_WAS_SENT_TO_LD: 'loan_was_sent_to_ld',
  LOAN_WAS_SENT_TO_RA_LENDERS: 'loan_was_sent_to_ra_lenders',
  LOAN_DRAFT_CREATED: 'loan_draft_created',
  OFFER_REJECTED_BY_LENDER: 'offer_rejected_by_lender',
  OFFER_REJECTED_BY_BB: 'offer_rejected_by_bb',
  LOAN_NOTE_CREATED: 'loan_note_created',
  POSTED_LOAN_CHANGED: 'loan_posted_changed',
  LOAN_CLOSED_LOST: 'loan_closed_lost',
  OFFER_ACCEPTED_BY_LENDER: 'offer_accepted_by_lender',
  OFFER_ACCEPTED_BY_BB: 'offer_accepted_by_bb',
  BB_UPLOADED_NEW_DOCUMENT: 'bb_uploaded_new_document',
  MADE_OFFLINE_OFFER: 'made_offline_offer',
  TS_RETURNED: 'ts_rejected',
  TS_SIGNED: 'ts_signed',
  CLOSED_LOAN_WON: 'closed_loan_won',
  OFFLINE_TS_ADDED: 'offline_ts_added',
  DDR_CREATED: 'ddr_created',
  DDR_COMPLETED: 'ddr_completed',
  DD_UPLOADED: 'dd_uploaded',
  DD_REJECTED: 'dd_rejected',
  OFFER_CANCELED: 'offer_canceled',
  LENDER_UPLOADED_DOCUMENT_TO_SIGN: 'lender_uploaded_document_to_sign',
  BB_UPLOADED_SIGNED_DOCUMENT: 'bb_uploaded_signed_document',
  LENDER_APPROVED_SIGNED_DOCUMENT: 'lender_approved_signed_document',
  LENDER_REJECTED_SIGNED_DOCUMENT: 'lender_rejected_signed_document',  
};

export const LENDER_ACTIVITY_TYPES = {
  POSTED_LOAN_CHANGED: 'posted_loan_changed',
  PASSED_LOAN: 'passed_loan',
  SIGNED_LOAN_CA: 'signed_loan_ca',
  UPLOADED_TS: 'uploaded_ts',
  TS_SIGNED: 'ts_signed',
  TS_RETURNED: 'ts_returned',
  GOT_OFFER: 'got_offer',
  MADE_OFFER: 'made_offer',
  OFFER_REJECTED_BY_BB: 'offer_rejected_by_bb',
  OFFER_REJECTED_BY_LENDER: 'offer_rejected_by_lender',
  OFFER_ACCEPTED_BY_BB: 'offer_accepted_by_bb',
  OFFER_ACCEPTED_BY_LENDER: 'offer_accepted_by_lender',
  LENDER_INVITED_TO_COMPANY: 'lender_invited_to_company',
  LENDER_REASSIGNED_LOAN: 'lender_reassigned_loan',
  OFFER_CANCELED: 'offer_canceled',
  LENDER_UPLOADED_DOCUMENT_TO_SIGN: 'lender_uploaded_document_to_sign',
  BB_UPLOADED_SIGNED_DOCUMENT: 'bb_uploaded_signed_document',
  LENDER_APPROVED_SIGNED_DOCUMENT: 'lender_approved_signed_document',
  LENDER_REJECTED_SIGNED_DOCUMENT: 'lender_rejected_signed_document',
};
