import React from 'react';
import styled from 'styled-components/macro';
import { Text } from 'ui/Text';
import { Section } from 'components/Modals/ManualOfferModal/styles';

export const LenderInfo = ({ lender }) => (
  <Section>
    <Title>Lender:</Title>
    <Label>
      {lender.firstName} {lender.lastName}
    </Label>
    <Email href={`mailto:${lender.email}`}>{lender.email}</Email>
    <Label>{lender.businessName}</Label>
  </Section>
);

const Title = styled(Text)`
  font-size: 12px;
  color: #778598;
`;

const Label = styled(Text)`
  font-weight: bold;
`;

const Email = styled.a`
  line-height: 1.5;
  color: #4394ea;
  text-decoration: none;
  font-weight: bold;
`;
