import { put, takeLatest, call } from 'redux-saga/effects';
import types from 'constants/actionTypes';
import { decamelizeKeys } from 'humps';
import * as API from 'api';
import { OTHER_PROPERTY_TYPE } from 'constants/appRelated';
import { dashboardDataRequest } from 'features/Dashboard/actions';
import { getError } from 'sagas/utils';
import { push } from 'connected-react-router';
import {
  finishRegistrationSuccess,
  finishRegistrationFailure,
  getProfileInfoSuccess,
  getProfileInfoFailure,
} from './actions';

function* finishRegistration(action) {
  const { values, loanId } = action.payload;
  const {
    assetLocations,
    assetLocationAllUsa,
    sponsorLocations,
    sponsorLocationAllUsa,
    addresses,
    city,
    purposes,
    ltcRangeMin,
    ltcRangeMax,
    ltvRangeMin,
    ltvRangeMax,
    nonRecourse,
    sizeOfCrePortfolio,
    type,
    state,
    role,
    zip,
    minimumDscr,
    maximumDscr,
    amountMin,
    amountMax,
    loanTermMin,
    loanTermMax,
    loanTermMinUnit,
    loanTermMaxUnit,
    loanTypes,
    propertyTypes,
    brochure,
    otherPurposes,
    otherLoanTypes,
    otherPropertyTypes,
    onlyOwnerOccupied,
  } = values;

  const loanTermMinCalc = loanTermMin * (loanTermMinUnit.value ? 12 : 1);
  const loanTermMaxCalc = loanTermMax * (loanTermMaxUnit.value ? 12 : 1);

  try {
    const {
      data: { response },
    } = yield call(
      API.axiosApi.put,
      'auth/profile',
      decamelizeKeys({
        address1: addresses[0],
        city,
        assetLocations,
        assetLocationAllUsa: !!assetLocationAllUsa,
        sponsorLocations,
        sponsorLocationAllUsa: !!sponsorLocationAllUsa,
        ltcRange: { lower: ltcRangeMin, upper: ltcRangeMax },
        ltvRange: { lower: ltvRangeMin, upper: ltvRangeMax },
        nonRecourse: !nonRecourse,
        sizeOfCrePortfolio: sizeOfCrePortfolio.value,
        type: type.value,
        state,
        title: role,
        zip,
        propertyTypes: propertyTypes
          .map((item) => item.value)
          .filter((value) => value !== OTHER_PROPERTY_TYPE),
        dscrRange: { lower: minimumDscr, upper: maximumDscr },
        amount: {
          lower: amountMin.replace(/[^\d]/g, ''),
          upper: amountMax.replace(/[^\d]/g, ''),
        },
        loanTerms: {
          lower: loanTermMinCalc > loanTermMaxCalc ? loanTermMaxCalc : loanTermMinCalc,
          upper: loanTermMinCalc > loanTermMaxCalc ? loanTermMinCalc : loanTermMaxCalc,
        },
        purposes: purposes.map((item) => item.value),
        loanTypes: loanTypes.map((item) => item.value),
        otherPurposes,
        otherLoanTypes,
        otherPropertyTypes,
        onlyOwnerOccupied: !!onlyOwnerOccupied,
      })
    );

    if (brochure) {
      const formData = new FormData();
      formData.append('brochure', brochure, brochure.name);
      yield call(API.axiosApi.put, 'auth/lending-criteria/brochure', formData);
    }

    if (loanId) {
      yield put(push(`/dashboard/property-details/${loanId}`));
    } else {
      yield put(push('/dashboard'));
    }

    yield put(finishRegistrationSuccess(response));
    yield put(dashboardDataRequest());
  } catch (e) {
    console.error(e);
    yield put(finishRegistrationFailure({ message: getError(e) }));
  }
}

function* getProfileInfo() {
  try {
    const {
      data: { response },
    } = yield call(API.axiosApi.get, '/auth/profile');
    yield put(getProfileInfoSuccess(response));
  } catch (e) {
    yield put(getProfileInfoFailure({ message: getError(e) }));
  }
}

export default [
  takeLatest(types.FINISH_REGISTRATION_REQUEST, finishRegistration),
  takeLatest(types.GET_PROFILE_INFO_REQUEST, getProfileInfo),
];
