export const simpleMemoize = (fn) => {
  let lastArg;
  let lastResult;
  return (arg, ...rest) => {
    if (!lastArg || arg !== lastArg) {
      lastArg = arg;
      lastResult = fn(arg, ...rest);
    }
    return lastResult;
  };
};
