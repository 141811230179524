import types from 'constants/actionTypes';

export const dismissAlert = () => ({
  type: types.DISMISS_ALERT,
});

export const stopWebsocket = () => ({ type: types.STOP_WEBSOCKET });

export const sendWebsocketMessage = (payload = {}) => ({
  type: types.SEND_WEBSOCKET_MESSAGE,
  payload,
});

export const updateWebsocketTasks = (payload = {}) => ({
  type: types.UPDATE_WEBSOCKET_TASKS,
  payload,
});

export const updateWebsocketMessage = (payload = {}) => ({
  type: types.UPDATE_WEBSOCKET_MESSAGE,
  payload,
});

export const handleWebsocketMessage = (payload = {}) => ({
  type: types.HANDLE_WEBSOCKET_MESSAGE,
  payload,
});

export const addNotification = (payload = {}) => ({
  type: types.ADD_NOTIFICATION,
  payload,
});

export const setNotificationCount = (payload = {}) => ({
  type: types.SET_NOTIFICATION_COUNT,
  payload,
});

export const removeNotificaion = (pk) => ({ type: types.REMOVE_NOTIFICATION, pk });

export const verifyLender = () => ({ type: types.VERIFY_LENDER });

export const showAlert = (payload = {}) => ({
  type: types.SHOW_ALERT,
  payload,
});

export const resetChatRoomCounter = (payload = {}) => ({
  type: types.RESET_CHAT_ROOM_COUNTER,
  payload,
});

export const updateLenderApproveStatus = ({ status }) => ({
  type: types.UPDATE_LENDER_APPROVE_STATUS,
  payload: { status },
});

export const hideSpinner = (payload = {}) => ({
  type: types.HIDE_SPINNER,
  payload,
});

export const setCurrentUri = (payload = {}) => ({
  type: types.SET_CURRENT_URI,
  payload,
});
