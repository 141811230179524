import React, { PureComponent } from 'react';
import styled from 'styled-components/macro';

import { DisplayTitle } from './DisplayTitle';
import { DisplayValue } from './DisplayValue';
import { EditableValue } from './EditableValue';

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
  align-items: center;
  padding: 5px 0;
`;

const Item = styled.div`
  width: 25%;
  max-width: 25%;

  &:nth-child(2){
    width: 32%;
    max-width: 32%;
  }

  &:last-child{
    width: 41%;
    max-width: 41%;
  }

  &:first-child{
    p{ font-weight: 400 }
  }
  div{ 
    color: #778598;
    
    span{ 
      font-weight: 700;
      font-size: 14px;
      color: #193353;
    }
  }

  div[placeholder = 'Select Index']{
    width: 140px;
  }
  
`;

export default class OfferRow extends PureComponent {
  render() {
    const { row, displayValues, editableValues, edit, isLender, newOffer } = this.props;
    return (
      <Wrapper data-testid="offerRow">
        <Item data-testid={row.field}>
          <DisplayTitle title={row.title} required={row.required} />
        </Item>
        <Item>
          <DisplayValue
            row={row}
            values={displayValues}
            editableValues={editableValues}
            edit={edit}
            isLender={isLender}
            newOffer={newOffer}
          />
        </Item>
        <Item>
          {edit ? (
            <EditableValue
              row={row}
              values={editableValues}
              edit={edit}
              isLender={isLender}
            />
          ) : (
            <DisplayValue
              row={row}
              values={editableValues}
              displayValues={displayValues}
            />
          )}
        </Item>
      </Wrapper>
    );
  }
}
