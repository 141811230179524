
import React from 'react';
import styled from 'styled-components/macro';

export const Action = ({label, click, icon}) => (
    <Wrapper onClick={click}>
        <img alt="" src={icon}/>
        <span>{label}</span>
    </Wrapper>
)


const Wrapper = styled.div`
    padding: 10px 0 8px 16px;
    display: flex;
    align-items: center;
    &:hover{
        background-color: rgba(67, 148, 234, 0.05);
        cursor: pointer;
        color: #1C3353;
    }
    
    img{
        margin-right: 9px;
    }

    span, a{
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        color: #1C3353;
    }
`