import React from 'react';
import styled from 'styled-components/macro';
import { Section, SectionTitle, SectionHead } from 'styles/loan';
import { keys } from 'ramda';
import { Button, ButtonGhost } from 'ui/Button';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { DocumentItem } from './DocumentItem';
import { DocumentTypesList } from './DocumentTypesList';
import { DocumentsSection } from './DocumentsSection';
import { DocumentsEmpty } from './DocumentsEmpty';

export const Documents = React.forwardRef(
  (
    {
      highlightsDoc,
      documents,
      isOwner,
      uid,
      id,
      canRequestDocuments,
      canDownloadDocument,
      openDocumentsRequest,
      downloadAllDocuments,
      loanClosing,
      standAlone,
      openConfidentialityAgreementModal,
      hasProtectedDocuments,
      hasUploadedDocuments,
      userType,
      performActionOnDocument,
    },
    ref
  ) => {
    const recommendedDocuments = documents?.recommended || [];
    const sections = documents?.sections || [];

    const documentItemDefaultProps = {
      canDownloadDocument,
      standAlone,
      performActionOnDocument,
    };

    return (
      <Wrapper ref={ref}>
        <SectionHead>
          <SectionTitle>Documents</SectionTitle>
          <ButtonWrapper>
            {isOwner && (
              <Link to={`/dashboard/loan-creation/${id}/files`}>
                <ButtonGhost>Manage Documents</ButtonGhost>
              </Link>
            )}
            {canRequestDocuments && !loanClosing && hasUploadedDocuments && (
              <Button onClick={openDocumentsRequest}>Request Documents</Button>
            )}
            {canDownloadDocument && userType?.lender && hasUploadedDocuments && (
              <Button
                onClick={
                  hasProtectedDocuments
                    ? () => {
                        openConfidentialityAgreementModal({ allDocuments: true });
                      }
                    : downloadAllDocuments
                }
              >{`Download${keys(documents).length > 1 ? ' All' : ''}`}</Button>
            )}
          </ButtonWrapper>
        </SectionHead>

        {!hasUploadedDocuments && userType?.lender && (
          <DocumentsEmpty
            canRequestDocuments={canRequestDocuments}
            standAlone={standAlone}
            requestDocuments={openDocumentsRequest}
          />
        )}

        {recommendedDocuments.map((folder) =>
          folder.documents.length ? (
            <DocumentTypesList key={folder.id} name={folder.name}>
              {folder.documents.map((item) => (
                <DocumentItem
                  key={item.id}
                  id={item.id}
                  highlightsDoc={highlightsDoc}
                  name={item.filename}
                  url={item.url}
                  protectedDocument={item.protected}
                  folderId={folder.id}
                  openConfidentialityAgreementModal={openConfidentialityAgreementModal}
                  {...documentItemDefaultProps}
                />
              ))}
            </DocumentTypesList>
          ) : null
        )}

        {sections.map((documentsSection) => (
          <DocumentsSection
            key={`${documentsSection.sectionId}`}
            documentsSection={documentsSection}
          >
            {documentsSection.folders.map((folder) =>
              folder.documents.length ? (
                <DocumentTypesList key={folder.id} name={folder.name}>
                  {folder.documents.map((item) => (
                    <DocumentItem
                      highlightsDoc={highlightsDoc}
                      key={item.id}
                      id={item.id}
                      name={item.filename}
                      url={item.url}
                      protectedDocument={item.protected}
                      folderId={folder.id}
                      sectionId={documentsSection.sectionId}
                      openConfidentialityAgreementModal={
                        openConfidentialityAgreementModal
                      }
                      {...documentItemDefaultProps}
                    />
                  ))}
                </DocumentTypesList>
              ) : null
            )}
          </DocumentsSection>
        ))}
      </Wrapper>
    );
  }
);

Documents.displayName = 'Documents';
Documents.propTypes = {
  documents: PropTypes.shape({
    recommended: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
    sections: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  }).isRequired,
  isOwner: PropTypes.bool,
  userType: PropTypes.objectOf(PropTypes.any),
  uid: PropTypes.string,
  id: PropTypes.number,
  canRequestDocuments: PropTypes.bool,
  canDownloadDocument: PropTypes.bool,
  openDocumentsRequest: PropTypes.func,
  downloadAllDocuments: PropTypes.func,
  loanClosing: PropTypes.bool,
  standAlone: PropTypes.bool,
  openConfidentialityAgreementModal: PropTypes.func,
  hasProtectedDocuments: PropTypes.bool,
  hasUploadedDocuments: PropTypes.bool,
};

const Wrapper = styled(Section)`
  @media (max-width: ${(p) => p.theme.breakpoints[0]}px) {
    padding-bottom: 88px;
  }
`;

const ButtonWrapper = styled.div`
  ${Button} {
    margin-left: 8px;
  }
  @media (max-width: ${(p) => p.theme.breakpoints[0]}px) {
    position: absolute;
    bottom: 24px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;

    & > * {
      margin-bottom: 8px;
    }

    ${Button} {
      margin-left: 0;
    }
  }
`;
