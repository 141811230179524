import React, { useEffect, useRef } from 'react';
import styled from 'styled-components/macro';
import { FieldArray } from 'react-final-form-arrays';
import { Text } from 'ui/Text';
import {
  ModalBody,
  ModalHeading,
  ModalActions,
  modalWithContentWrapperStyles,
} from 'ui/Modal/styles';
import { Button } from 'ui/Button';

import { UploadCsv, FieldsBlock } from './';

export const FormBlock = ({
  handleSubmit,
  onDrop,
  asyncValidate,
  push,
  submiting,
  validating,
}) => {
  const lastFormBlockRef = useRef(null);
  const formBodyRef = useRef(null);

  /*
    Implemented to got rid off eslint warning
  */
  const lastFormBlock = lastFormBlockRef.current;

  useEffect(() => {
    const formOffset = lastFormBlockRef?.current?.offsetTop;
    if (formOffset) {
      formBodyRef.current.scrollTop += formOffset;
    }
  }, [lastFormBlock]);

  return (
    <FormWrapper onSubmit={handleSubmit}>
      <ModalHeading>Adding new lender</ModalHeading>
      <ModalBody ref={formBodyRef}>
        <UploadCsvWrapper>
          <UploadCsv onDrop={onDrop} />
        </UploadCsvWrapper>
        <Text
          textAlign="center"
          fontWeight="bold"
          fontFamily="muli"
          color="#2B4C78"
          mb={3}
        >
          or add your lenders manually
        </Text>
        <FieldsBlock validateEmail={asyncValidate} name="lender" />
        <FieldArray name="lenders">
          {({ fields }) => {
            return fields.map((name, index) => (
              <div ref={lastFormBlockRef} key={name}>
                <FieldsBlock
                  name={name}
                  index={index}
                  remove={fields.remove}
                  validateEmail={asyncValidate}
                />
              </div>
            ));
          }}
        </FieldArray>
        <ButtonAdd type="button" onClick={() => push('lenders', {})}>
          <Text color="#4394EA">+ Add another lender</Text>
        </ButtonAdd>
      </ModalBody>
      <ModalActions>
        <Button type="submit" secondary disabled={submiting || validating}>
          Add to Lender Directory
        </Button>
      </ModalActions>
    </FormWrapper>
  );
};

const UploadCsvWrapper = styled.div`
  margin-bottom: 16px;
`;

const PureButton = styled.button`
  padding: 0;
  border: 0;
  background-color: transparent;
  outline: none;
  cursor: pointer;
`;

const ButtonAdd = styled(PureButton)`
  position: relative;
  top: -50px;

  ${Text} {
    cursor: pointer;
  }
`;

const FormWrapper = styled.form`
  ${modalWithContentWrapperStyles}
`;
