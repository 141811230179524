import { keys } from 'ramda';
import { decamelizeKeys } from 'humps';

export const makeOfferFormData = (offer, documents) => {
  const form = new FormData();
  const decamelizeOffer = decamelizeKeys(offer);

  keys(decamelizeOffer).forEach((key) => {
    if (key !== 'documents') {
      form.append(`${key}`, decamelizeOffer[key]);
    }
  });

  if (documents) {
    documents.forEach((file, i) => {
      form.append(`documents[${i}][file]`, file.file, file.filename);
      form.append(`documents[${i}][filename]`, file.filename);
    });
  }

  return form;
};
