/*eslint-disable  */
import { setIn } from 'final-form';
import Yup from 'yup';

export const validate = async (values, schema, context) => {
  if (typeof schema === 'function') {
    schema = schema();
  }

  try {
    await schema.validate(values, { abortEarly: false, context });
  } catch (e) {
    return e.inner.reduce(
      (errors, error) => setIn(errors, error.path, error.message),
      {}
    );
  }
};

export const validateEmail = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

export const validateSelectOption = (message) =>
  Yup.object({
    value: Yup.number(),
    label: Yup.string(),
  })
    .nullable()
    .test(function(option) {
      const { createError } = this;
      if (!option || (!option.value && !option.label)) {
        return createError({ message });
      } else {
        return true;
      }
    });
