import React from 'react';
import styled from 'styled-components/macro';
import {
  ModalBody,
  ModalHeading,
  ModalActions,
  modalWithContentWrapperStyles,
} from 'ui/Modal/styles';
import { Button, ButtonAdd } from 'ui/Button';
import { Form, Field } from 'react-final-form';
import { Input } from 'components/Form';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import { without } from 'ramda';

import { DealDocumentRequest } from './DealDocumentRequest';

const formValidator = (values) => {
  const errors = {};

  if (!values.name || !values.name.trim()) {
    errors.name = "can't be blank";
  }

  const notEmptyRequests = values.requests.filter((request) => request.name);

  if (!notEmptyRequests.length) {
    errors.requests = [{ name: "can't be blank" }];
  }

  return errors;
};

export const DealRoomChecklistModal = ({ hideModal, checklist, isEdit, submit }) => (
  <Form
    onSubmit={({ name, requests }) => {
      const request = {
        name: name.trim(),
        requests: requests.filter((item) => item?.name?.length),
      };

      if (isEdit && checklist) {
        request.deletedIds = without(
          requests.map(({ id }) => id),
          checklist.requests.map(({ id }) => id)
        );
      }

      submit(request);
      hideModal();
    }}
    validate={formValidator}
    mutators={{
      ...arrayMutators,
    }}
    initialValues={checklist || { name: '', requests: [{ name: '', closingDate: null }] }}
  >
    {({
      handleSubmit,
      values: formValues,
      form: {
        mutators: { push },
        change: changeFieldValue,
      },
    }) => (
      <StyledForm onSubmit={handleSubmit}>
        <ModalHeading>
          {isEdit ? 'Change Checklist' : 'Create new checklist'}
        </ModalHeading>
        <ModalBody>
          <Field
            name="name"
            component={Input}
            maxLength={250}
            placeholder={isEdit ? 'Write checklist name' : 'Write a checks list name'}
          />
          <RequestsList>
            <FieldArray name="requests">
              {({ fields }) =>
                fields.map((name, index) => (
                  <DealDocumentRequest
                    key={name}
                    name={name}
                    index={index}
                    values={formValues.requests[index]}
                    changeFieldValue={changeFieldValue}
                    remove={fields.remove}
                  />
                ))
              }
            </FieldArray>
          </RequestsList>
          <AddRequestBtn
            type="button"
            onClick={() => push('requests', {})}
            color="#4394EA"
          >
            {isEdit ? 'ADD NEW TASK OR DOCUMENT' : 'ADD NEW LIST ITEM'}
          </AddRequestBtn>
        </ModalBody>
        <StyledModalActions>
          <Button type="submit" secondary>
            Save
          </Button>
        </StyledModalActions>
      </StyledForm>
    )}
  </Form>
);

const StyledModalActions = styled(ModalActions)`
  margin: 0 24px;
  border-top: 1px solid #e8e9ed;
`;

const StyledForm = styled.form`
  ${modalWithContentWrapperStyles}
`;

const AddRequestBtn = styled(ButtonAdd)`
  font-size: 10px;
`;

const RequestsList = styled.div`
  min-height: 256px;
`;
