import React from 'react';
import styled from 'styled-components/macro';

export const MenuButtonItem = ({ close, onClick, children, Icon, iconUrl, ...rest }) => {
  return (
    <ContextMenuChild
      onClick={(e) => {
        if (onClick) {
          onClick(e);
        }
      }}
      iconUrl={iconUrl}
      {...rest}
    >
      {Icon || null}
      {children}
    </ContextMenuChild>
  );
};

const ContextMenuChild = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;

  &,
  a {
    color: ${(p) => p.theme.colors.black};
    text-decoration: none;
  }

  cursor: pointer;

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    text-decoration: underline;
  }

  &::before {
    content: ${(p) => (p.iconUrl ? `url(${p.iconUrl})` : 'none')};
    width: 15px;
    height: 15px;
    margin-right: 8px;
  }

  svg {
    margin-right: 8px;
    width: 16px;
  }
`;
