import React from 'react';
import styled, { css } from 'styled-components/macro';
import PropTypes from 'prop-types';
import { Text } from 'ui/Text';
import { CheckIcon } from 'components/SvgIcons';

const WrapperCommon = styled.label`
  margin-bottom: 24px;
  position: relative;
  display: flex;
  overflow: hidden;
  cursor: pointer;

  input {
    position: absolute;
    opacity: 0;
  }

  input:disabled + div {
    opacity: 0.7;

    span {
      box-shadow: 0 0 0 3px #d2d6dd;
    }
  }

  input: disabled + div + p {
    color: #8d99a8;
  }

  input:checked + div {
    border-color: #4394ea;
    svg {
      display: block;
    }
  }
`;

const Wrapper = styled(WrapperCommon)`
  ${(p) =>
    (p.mb || typeof p.mb === 'number') &&
    css`
      margin-bottom: ${p.theme.space[p.mb] ? `${p.theme.space[p.mb]}px` : p.mb};
    `}
`;

const CheckArea = styled.div`
  position: relative;
  display: inline-block;
  height: 16px;
  width: 16px;
  border: 1px solid #778598;
  border-radius: 3px;
  overflow: hidden;
  box-sizing: border-box;
  flex-shrink: 0;

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: none;
  }
`;

export const Checkbox = ({
  name,
  disabled,
  checked,
  label,
  mb,
  withoutLabel,
  className,
  ...rest
}) => (
  <Wrapper mb={mb} className={className}>
    <input type="checkbox" name={name} disabled={disabled} checked={checked} {...rest} />
    <CheckArea>
      <CheckIcon color="#4394EA" />
    </CheckArea>
    {!withoutLabel && label && (
      <React.Fragment>
        {label && typeof label === 'object' ? (
          label
        ) : (
          <Text mb="0" ml="8px">
            {label}
          </Text>
        )}
      </React.Fragment>
    )}
  </Wrapper>
);

Checkbox.propTypes = {
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

Checkbox.defaultProps = {
  checked: false,
  disabled: false,
  label: '',
  name: '',
};
