import types from 'constants/actionTypes';
import immutable from 'object-path-immutable';

const initialState = {
  id: null,
  additionalFunctionality: null,
  data: {
    lendingCriteria: {},
  },
  possibleTags: [],
  activitiesData: {},
};

export const userDetails = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.GET_LENDER_DETAILS_REQUEST: {
      const { id, additionalFunctionality } = payload;
      return { ...state, id, additionalFunctionality };
    }
    case types.GET_USERS_SUCCESS:
    case types.GET_LENDER_DETAILS_SUCCESS:
    case types.GET_BB_DETAILS_SUCCESS: {
      const { data, tags } = payload;
      return { ...state, data, possibleTags: tags };
    }

    case types.GET_USERS_REQUEST:
    case types.GET_BB_DETAILS_REQUEST: {
      const { id, highlights } = payload;
      return { ...state, id, highlights };
    }

    case types.ADD_TAG_TO_LENDER_SUCCESS: {
      const { tagName } = payload;

      return immutable(state)
        .update(['data', 'tags'], (v) => [...v, { label: tagName, value: tagName }])
        .update('possibleTags', (v) =>
          v.filter((tag) => tag.label === tagName).length
            ? v
            : [...v, { label: tagName, value: tagName }]
        )
        .value();
    }

    case types.ADD_NOTE_TO_LENDER_SUCCESS:
    case types.ADD_NOTE_TO_CLIENT_SUCCESS: {
      const { response } = payload;
      return immutable(state)
        .update(['data', 'notes'], (v) => [response, ...v])
        .value();
    }

    case types.REMOVE_TAG_FROM_LENDER_SUCCESS: {
      const { tagName } = payload;

      return immutable(state)
        .update(['data', 'tags'], (v) => v.filter((tag) => tag.label !== tagName))
        .value();
    }

    case types.GET_LENDER_ACTIVITIES_SUCCESS: {
      const { activities, nextActivities, page, filter } = payload;

      return immutable(state)
        .update(['activitiesData', 'activities'], (v) =>
          page ? [...v, ...activities] : activities
        )
        .set('activitiesData.nextActivities', nextActivities)
        .set('activitiesData.activitiesFilter', filter || '')
        .value();
    }

    case types.CLEAR_LENDER_DETAILS:
    case types.GET_LENDER_DETAILS_FAILURE:
    case types.GET_BB_DETAILS_FAILURE: {
      return initialState;
    }

    default:
      return state;
  }
};
