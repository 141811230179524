import React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { getFieldHistoryRequest } from 'actions/modalActions';

export const FieldEdited = ({ fieldName, sponsorProfileField }) => {
  const dispatch = useDispatch();

  return (
    <Wrapper>
      Edited{' '}
      <EditLink
        onClick={() =>
          dispatch(getFieldHistoryRequest({ fieldName, sponsorProfileField }))
        }
      >
        See Changes
      </EditLink>
    </Wrapper>
  );
};

const Wrapper = styled.span`
  margin-top: 8px;
  font-size: 12px;
  line-height: 16px;
  color: #778598;
  display: block;
`;

const EditLink = styled.span`
  text-transform: uppercase;
  color: #4394ea;
  cursor: pointer;
`;
