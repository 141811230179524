import React from 'react';
import {
  ModalBody,
  ModalControls,
  ModalHeading,
  ModalWithContentWrapper,
} from 'ui/Modal/styles';
import { Button } from 'ui/Button';
import { Input } from 'components/Form';
import { Form, Field } from 'react-final-form';

import {
  createLenderCompanyTeamRequest,
  updateLenderCompanyTeamRequest,
} from 'features/LenderCompany/actions';

export const LenderCompanyTeamEditing = ({
  hideModal,
  dispatch,
  isEditing,
  teamId,
  currentName,
}) => {
  return (
    <ModalWithContentWrapper>
      <ModalHeading>{isEditing ? 'Edit team name' : 'Add new team'}</ModalHeading>
      <ModalBody>
        <Form
          initialValues={{ name: currentName }}
          onSubmit={({ name }) => {
            if (isEditing) {
              dispatch(updateLenderCompanyTeamRequest({ name, teamId }));
            } else {
              dispatch(createLenderCompanyTeamRequest({ name }));
            }
            hideModal();
          }}
        >
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Field
                tabIndex="1"
                component={Input}
                name="name"
                required
                label="Team name"
                validate={(value) => (value ? undefined : 'Team name is required')}
              />
              <ModalControls alignCenter>
                <Button type="submit">Save</Button>
              </ModalControls>
            </form>
          )}
        </Form>
      </ModalBody>
    </ModalWithContentWrapper>
  );
};
