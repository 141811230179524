import React from 'react';
import styled from 'styled-components/macro';
import { ModalHeader, ModalBody, ModalTitle } from 'ui/Modal/styles';
import { Text } from 'ui/Text';
import { Link } from 'react-router-dom';

const LinkRedirect = styled(Link)`
  display: inline;
  color: #fd8a42;
  font-size: 16px;
  cursor: pointer;
  text-decoration: none;
`;

export const EmailAlreadyConfirmed = ({ hideModal }) => (
  <div>
    <ModalHeader>
      <ModalTitle>Email confirmed</ModalTitle>
    </ModalHeader>
    <ModalBody>
      <Text fontSize={1} fontWeight={1}>
        Your email address has already been confirmed.{' '}
        <span>
          Please{' '}
          <LinkRedirect to="/login" onClick={hideModal}>
            log in
          </LinkRedirect>
          .
        </span>
      </Text>
      <Text fontSize={1} fontWeight={1} mt={3}>
        If you have forgotten your password{' '}
        <LinkRedirect to="/password-reset" onClick={hideModal}>
          click here
        </LinkRedirect>{' '}
        to reset.
      </Text>
    </ModalBody>
  </div>
);
