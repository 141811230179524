import React from 'react';
import { LoanFieldsSet } from 'components/Loans';
import { LOAN_SECTIONS } from 'constants/appRelated';
import PropTypes from 'prop-types';

export const LoanRequest = React.forwardRef(
  (
    { loan, fields, normalizedOptions, editable, id, customFields, fieldsHistory = {} },
    ref
  ) => (
    <div ref={ref} data-testid="loanDesiredTermsSection">
      <LoanFieldsSet
        title="Desired terms"
        editLink={`/dashboard/loan-creation/${id}/${LOAN_SECTIONS.TERMS}`}
        loan={loan}
        fields={fields}
        normalizedOptions={normalizedOptions}
        editable={editable}
        customFields={customFields}
        fieldsHistory={fieldsHistory}
        fieldModifier={(fieldData) => {
          if (fieldData.fieldName === 'amortization' && loan.amortizationCustom) {
            return null;
          }
          if (fieldData.fieldName === 'amortizationCustom' && loan.amortizationCustom) {
            return { ...fieldData, label: 'Amortization' };
          }

          return fieldData;
        }}
      />
    </div>
  )
);

LoanRequest.displayName = 'LoanRequest';
LoanRequest.propTypes = {
  loan: PropTypes.objectOf(PropTypes.any).isRequired,
  fields: PropTypes.arrayOf(PropTypes.string).isRequired,
  normalizedOptions: PropTypes.objectOf(PropTypes.object).isRequired,
  id: PropTypes.number,
  editable: PropTypes.bool,
  customFields: PropTypes.arrayOf(PropTypes.object),
  fieldsHistory: PropTypes.objectOf(PropTypes.any),
};
