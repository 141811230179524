import React from 'react';
import { Field } from 'react-final-form';
import { Input } from 'components/Form';
import styled, { css } from 'styled-components/macro';
import { normalizeMobile, normalizeInteger } from 'lib/normalizers';
import { WorkPhone, WorkPhoneWrapper, WorkPhoneExt } from 'styles/auth';
import { BucketIcon } from 'components/SvgIcons';

const Wrapper = styled.div`
  margin-bottom: 32px;
  ${(p) =>
    p.listItem &&
    css`
      padding-top: 32px;
      border-top: 1px solid #c9ced6;
    `};
`;

const FieldWrap = styled.div`
  flex: 1 0;
`;

const FieldWrapSpace = styled(FieldWrap)`
  margin-right: 16px;
`;

const FieldsWrapper = styled.div`
  display: flex;
`;

const TrashButton = styled.button`
  width: 15px;
  display: block;
  margin-left: auto;
  border: none;
  background-color: transparent;
  padding: 0;
  cursor: pointer;
  outline: none;
`;

export const FieldsBlock = ({ name, index, remove, validateEmail }) => (
  <Wrapper listItem={name}>
    <FieldsWrapper>
      <FieldWrapSpace>
        <Field
          name={name ? `${name}.firstName` : 'firstName'}
          component={Input}
          label="First name"
          required
          maxLength="30"
        />
      </FieldWrapSpace>
      <FieldWrap>
        <Field
          name={name ? `${name}.lastName` : 'lastName'}
          component={Input}
          label="Last name"
          required
          maxLength="30"
        />
      </FieldWrap>
    </FieldsWrapper>
    <FieldsWrapper>
      <FieldWrapSpace flex="1 0" mr={3}>
        <Field
          name={name ? `${name}.email` : 'email'}
          component={Input}
          validate={(values) => {
            return validateEmail(values, name);
          }}
          label="Email"
          required
          maxLength="256"
          parse={(value) => value.trim()}
        />
      </FieldWrapSpace>
      <FieldWrap>
        <Field
          name={name ? `${name}.companyName` : 'companyName'}
          component={Input}
          label="Company"
          required
          maxLength="100"
        />
      </FieldWrap>
    </FieldsWrapper>
    <FieldsWrapper>
      <FieldWrapSpace flex="1 0" mr={3}>
        <Field
          name={name ? `${name}.mobilePhone` : 'mobilePhone'}
          component={Input}
          label="Mobile phone"
          parse={normalizeMobile}
        />
      </FieldWrapSpace>
      <FieldWrap>
        <WorkPhoneWrapper>
          <WorkPhone>
            <Field
              name={name ? `${name}.phone` : 'phone'}
              component={Input}
              label="Work phone"
              parse={normalizeMobile}
            />
          </WorkPhone>
          <WorkPhoneExt>
            <Field
              name={name ? `${name}.phoneExt` : 'phoneExt'}
              component={Input}
              placeholder="ext."
              parse={normalizeInteger}
              maxLength="4"
            />
          </WorkPhoneExt>
        </WorkPhoneWrapper>
      </FieldWrap>
    </FieldsWrapper>
    {remove && (
      <TrashButton
        onClick={() => {
          remove(index);
        }}
        type="button"
      >
        <BucketIcon />
      </TrashButton>
    )}
  </Wrapper>
);
