import types from 'constants/actionTypes';
import { paginationTmp } from 'config/paginationTmp';
import { append, update } from 'ramda';
import immutable from 'object-path-immutable';

import { relationshipLendersSortOptions } from './constants';

const initialState = {
  realAtomLenders: [],
  realAtomLendersSelected: [],
  excludeCompanies: [],
  relationshipLendersIds: [],
  relationshipLendersNormalized: {},
  relationshipLenderSelected: [],
  relationshipLendersExclude: [],
  relationshipLendersAllSelected: false,
  relationshipLendersSearchString: '',
  relationshipLendersSortType: relationshipLendersSortOptions[2],
  relationshipLendersTotalCount: 0,
  interactedLendersCount: 0,
  ...paginationTmp,
};

export default function loanSendToLenders(state = initialState, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case types.GET_REALATOM_LENDERS_DATA_SUCCESS:
      return {
        ...state,
        realAtomLenders: payload.realAtomLenderTypes,
        invitation: payload.invitation,
        relationshipLenderSelected: payload.realationshipLendersSelected,
        excludeCompanies: payload.realAtomLendersBlackList.length
          ? payload.realAtomLendersBlackList
          : state.excludeCompanies,
        realAtomLendersSelected: payload.realAtomLenderTypes.reduce(
          (acc, item) => (item.selected ? [...acc, item.type] : acc),
          []
        ),
        interactedLendersCount: payload.interactedLendersCount || 0,
      };

    case types.SET_SEND_LOAN_TO_LENDER_SEARCH_STRING:
      return {
        ...state,
        relationshipLendersSearchString: payload,
      };

    case types.LENDER_DIRECTORY_INVITE_SUCCESS:
      return {
        ...state,
        relationshipLendersTotalCount:
          state.relationshipLendersTotalCount + payload.lendersArray.length,
        count: state.count + payload.lendersArray.length,
        relationshipLenderSelected: [
          ...state.relationshipLenderSelected,
          ...payload.lendersArray,
        ],
        relationshipLendersNormalized:
          state.relationshipLendersIds.length < state.pageSize
            ? {
                ...state.relationshipLendersNormalized,
                ...payload.lendersArray
                  .slice(0, state.pageSize - state.relationshipLendersIds.length)
                  .reduce((acc, id) => ({ ...acc, [id]: payload.lenders[id] }), {}),
              }
            : state.relationshipLendersNormalized,
        relationshipLendersIds:
          state.relationshipLendersIds.length < state.pageSize
            ? [
                ...state.relationshipLendersIds,
                ...payload.lendersArray.slice(
                  0,
                  state.pageSize - state.relationshipLendersIds.length
                ),
              ]
            : state.relationshipLendersIds,
      };

    case types.GET_RELATIONSHIP_LENDERS_SUCCESS:
      return {
        ...state,
        relationshipLendersIds: payload.relationshipLendersIds,
        relationshipLendersNormalized: payload.relationshipLendersNormalized,
        count: payload.count,
        next: payload.next,
        pageNumber: payload.pageNumber,
        pageSize: payload.pageSize,
        previous: payload.previous,
        relationshipLendersTotalCount: payload.count,
      };

    case types.UPDATE_RELATIONSHIP_LENDERS_SUCCESS:
      return {
        ...state,
        relationshipLendersIds: payload.relationshipLendersIds,
        relationshipLendersNormalized: payload.relationshipLendersNormalized,
        count: payload.count,
        next: payload.next,
        pageNumber: payload.pageNumber,
        pageSize: payload.pageSize,
        previous: payload.previous,
      };

    case types.SELECT_RELATIONSHIP_LENDER:
      return immutable.update(
        state,
        state.relationshipLendersAllSelected
          ? 'relationshipLendersExclude'
          : 'relationshipLenderSelected',
        (lenders) =>
          lenders.indexOf(action.payload) === -1
            ? [...lenders, action.payload]
            : lenders.filter((id) => id !== action.payload)
      );

    case types.CHANGE_RELATIONSHIP_LENDER_SORT:
      return {
        ...state,
        relationshipLendersSortType: action.payload,
      };

    case types.SELECT_PAGE_RELATIONSHIP_LENDERS:
      return {
        ...state,
        relationshipLenderSelected: payload.lenders,
      };

    case types.SELECT_ALL_RELATIONSHIP_LENDERS:
      return {
        ...state,
        relationshipLendersAllSelected: true,
        relationshipLendersExclude: [],
      };

    case types.SELECT_NONE_RELATIONSHIP_LENDERS:
      return {
        ...state,
        relationshipLendersAllSelected: false,
        relationshipLenderSelected: [],
        relationshipLendersExclude: [],
      };

    case types.SELECT_REAL_ATOM_LENDER:
      return {
        ...state,
        realAtomLendersSelected:
          state.realAtomLendersSelected.indexOf(action.payload) === -1
            ? [...state.realAtomLendersSelected, action.payload]
            : state.realAtomLendersSelected.filter(
                (itemType) => itemType !== action.payload
              ),
      };

    case types.EXCLUDE_COMPANY_ADD:
      return {
        ...state,
        excludeCompanies: [...state.excludeCompanies, undefined], // Add an empty company cell
      };

    case types.EXCLUDE_COMPANY_REMOVE:
      return {
        ...state,
        excludeCompanies: state.excludeCompanies.filter((_, i) => i !== action.payload),
      };
    case types.EXCLUDE_ALL_LENDERS:
      return {
        ...state,
        excludeAllLenders: payload,
      };
    case types.EXCLUDE_COMPANY_UPDATE:
      return {
        ...state,
        excludeCompanies:
          state.excludeCompanies.length === action.payload.index
            ? append(action.payload.value, state.excludeCompanies)
            : update(action.payload.index, action.payload.value, state.excludeCompanies),
      };

    case types.SEND_TO_LENDERS_RESET:
      return initialState;

    default:
      return state;
  }
}
