
import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { Text } from 'ui/Text';
import { ButtonGhost, Button } from 'ui/Button';
import { getMemberPermissionLabel } from 'constants/appRelated';
import { Link } from 'react-router-dom';


export const OpenInviteColleagues = ({lenders, handlerCancel, saveInvitedLenders}) => {
    const [persons, setPersons] = useState([]);
    const [invitedLenders, setInvitedLenders] = useState([]);
    const [chosenLenders, setChosenLenders] = useState([]);
    useEffect(() => {        
        if(lenders.length){
          setPersons(lenders)
        }
    }, []);

    const handlerChoose = (lender) => {
        const isIdExist = invitedLenders.indexOf( lender.userId ) !== -1;

        if(isIdExist){
            setChosenLenders(chosenLenders.filter(chosenLender => chosenLender.userId !== lender.userId));
            setInvitedLenders(invitedLenders.filter((lenderId) => lenderId !== lender.userId ));
        }else{
            setChosenLenders([...chosenLenders, lender]);
            setInvitedLenders([...invitedLenders, lender.userId]);
        }

        setPersons([
            ...persons.map(person => 
                lender.userId === person.userId ? {...person, active: isIdExist ? false  : true} 
            : {...person })
        ])
    }

    const handlerMouseOver = (index) => {}
    const handlerMouseOut = (index) => {}

    return (
        <InviteColleaguesWrapper>
            <TextTitle>Share with team</TextTitle>
            {persons.length ? (
                <LenderIcons>
                    {persons.map((person, index) => 
                        <LenderIconWrapper
                            style={person.isInvited ? {
                                cursor: 'not-allowed',
                                opacity: '0.7',
                            } : {}}
                            key={index}
                            onClick={() => !person.isInvited && handlerChoose(person)}
                            onMouseOver={() => !person.isInvited && handlerMouseOver(person.userId)} 
                            onMouseOut={() => !person.isInvited && handlerMouseOut(person.userId)} 
                        >
                            <Icon 
                                color={person.isInvited ? '#8890a2' : 'white' } 
                                bg={person.isInvited ? '#ecedee' : person.lenderTypeColor}
                            >
                                <span>{`${person.firstName[0]}${person.lastName[0]}`}</span>
                            </Icon>
                            <TextLenderName className="name">
                                {`${person.firstName} ${person.lastName}`}
                            </TextLenderName>
                            <TextLenderRole className="role">
                                {getMemberPermissionLabel(person.permissionsLevel)}
                            </TextLenderRole>
                            <CheckMark>
                                <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M8.25 0.283813C3.85 0.283813 0.25 3.88381 0.25 8.28381C0.25 12.6838 3.85 16.2838 8.25 16.2838C12.65 16.2838 16.25 12.6838 16.25 8.28381C16.25 3.88381 12.65 0.283813 8.25 0.283813ZM7.25 11.6838L3.85 8.28381L5.25 6.88381L7.25 8.88381L11.25 4.88381L12.65 6.28381L7.25 11.6838Z" 
                                    fill={person.isInvited || person.active ? "#4394EA" : "#CFD0D7"}
                                    />
                                </svg>
                            </CheckMark>
                        </LenderIconWrapper>
                    )}
                </LenderIcons>
            ) : (
                <TextDescription>You haven’t yet invited anybody to the RealAtom</TextDescription>
            )}
            <BtnWrapper>
                {persons.length ? (
                <>
                <ButtonGhost style={{marginRight: '12px'}} onClick={handlerCancel}>Cancel</ButtonGhost>
                <Button
                    secondary
                    disabled={invitedLenders.length ? false : true}
                    onClick={() => saveInvitedLenders(invitedLenders, chosenLenders)}>Invite</Button>
                </>
                ) : (
                    <Link to={'/dashboard/lender-company/members'}>
                        <Button onClick = {handlerCancel} secondary> Invite </Button>
                    </Link>
                )}
            </BtnWrapper>            
        </InviteColleaguesWrapper>
    );
}

const TextDescription = styled(Text)`
    margin-bottom: 24px;
    text-align: center;
    color: #1C3353;
    font-size: 16px;
`;


const TextTitle = styled(Text)`
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    margin-bottom: 30px;
`;

const InviteColleaguesWrapper = styled.div`
    padding: 24.5px 24px 40px;
`;

const LenderIconWrapper = styled.div`
    width: 105px;
    margin: 0 auto;
    position: relative;
   // margin-right: 22px;

   display: flex;
   flex-direction: column;
   align-items: center;
   margin-bottom: 24px;
   cursor: pointer;
`;

const Icon = styled.div`
    width: 56px;
    height: 56px;
    background:  ${(p) => p.bg || 'grey'};
    margin-bottom: 8px;
    border-radius: 50%;
    margin-top: 10px;
    span{
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: ${(p) => p.color}; 
        display: block;
        text-align: center;
        margin-top: 25%;
    }
`

const BtnWrapper = styled.div`
    text-align: center;

    .link{
        color: white;
    }
`;

const CheckMark = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    width: 17px;
    height: 17px;
`;

const LenderIcons = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    margin: 0 20px;
    padding: 0 15px 24px;
    border-bottom: 1px solid #E8E9ED;
    margin-bottom: 35px;
`;

const TextLenderName = styled(Text)`
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 2px;
`;
const TextLenderRole = styled(Text)`
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #778598;
`;


