import types from 'constants/actionTypes';

export const forgotPasswordRequest = (payload = {}) => ({
  type: types.FORGOT_PASSWORD_REQUEST,
  payload,
});
export const forgotPasswordSuccess = (payload = {}) => ({
  type: types.FORGOT_PASSWORD_SUCCESS,
  payload,
});
export const forgotPasswordFailure = (payload = {}) => ({
  type: types.FORGOT_PASSWORD_FAILURE,
  payload,
});
