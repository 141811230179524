import types from 'constants/actionTypes';

const initialState = {
  isValidated: false,
};

export default function reducer(state, action) {
  const currentState = state || initialState;
  const { type, payload } = action;

  switch (type) {
    case types.VALIDATE_AUTH_PARAMS_SUCCESS:
      return {
        ...currentState,
        isValidated: true,
        response_type: payload.response_type,
        client_id: payload.client_id,
        redirect_uri: payload.redirect_uri,
        scope: payload.scope,
        state: payload.state,
        scopes: payload.scopes,
      };

    default:
      return currentState;
  }
}
