import React from 'react';
import styled from 'styled-components';
import { Text } from 'ui/Text';
import { ButtonIcon } from 'ui/Button';
import { AddDocumentIcon } from 'components/SvgIcons';

export const LenderStub = ({ openCreatChecklistModal }) => (
  <Wrapper>
    <Content>
      <ButtonIcon
        variant="blue"
        Icon={<AddDocumentIcon />}
        onClick={openCreatChecklistModal}
      >
        New Checklist
      </ButtonIcon>
      <Description>Create new checklists to request documents from borrower</Description>
    </Content>
  </Wrapper>
);

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
  padding: 46px 0;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: ${(p) => p.theme.shadow};
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Description = styled(Text)`
  margin-top: 8px;
  color: #778598;
  font-size: 12px;
`;
