import React from 'react';
import { OfferRowSingle } from 'components/Modals/Offer/components';
import { OfferRequestField } from 'styles/offer';

export const FieldsSet = ({ userType, fields, quote, listing, edit, newQuote }) =>
  fields.map(
    (row, i) =>
      !row.hidden && (
        <OfferRequestField key={`${i}-offer-row`}>
          <OfferRowSingle
            row={row}
            displayValues={listing}
            editableValues={quote}
            edit={edit}
            isLender={userType.lender}
            newOffer={newQuote}
          />
        </OfferRequestField>
      )
  );
