import React, { useEffect, useState } from 'react';
import { Form, Field } from 'react-final-form';
import styled, { css } from 'styled-components/macro';
import { Input, NewDatepicker, Textarea, DropdownList } from 'components/Form';
import { useDispatch, connect } from 'react-redux';
import {
  ModalBody,
  ModalHeading,
  ModalActions,
  modalWithContentWrapperStyles,
} from 'ui/Modal/styles';
import { Button } from 'ui/Button';
import Yup from 'yup';
import { validate } from 'utils/validate';
import { UserAvatar } from 'components/UserAvatar';
import { format } from 'date-fns';
import { seenTaskRequest, getTasksMatchingLoansRequest } from 'containers/MyTasksWrapper/actions';
import { parseDueDate } from 'lib/date/parseDueDate';
import { Spinner } from 'components/Spinner';
import { createLoadingSelector, getRoughRequestAction } from 'lib/state';

const schema = Yup.object().shape({
  name: Yup.string()
    .required('Task name is required')
    .max(500, 'Please use shorter task name'),
});

const CurrentTask = ({
  task, 
  submit, 
  teamOptions, 
  matchingLoans, 
  isLoanPage, 
  currentLoan, 
  displayVariant, 
  displayFormat,
  isFetching
}) => {
  const dispatch = useDispatch();

  const [initialValues, setInitialValues] = useState({});
  const [heading, setHeading] = useState('');

  const [team, setTeam] = useState([]);
  const [loans, setLoans] = useState([]);

  useEffect(() => {
    if(task && !task.seen && displayVariant !== displayFormat?.createdByMe){
      dispatch(seenTaskRequest(task.id));
    }
    if(!matchingLoans?.length){
      dispatch(getTasksMatchingLoansRequest());
    }
  }, []);

  useEffect(() => {
    setHeading(`${task ? 'Edit' : 'New'} task`);
    setTeam(teamOptions?.map((data) => {
      return {
        ...data, 
        label: <div style={{display: 'flex', alignItems: 'center'}}>
                <UserAvatar
                  author={{ firstName: data.firstName, lastName: data.lastName, accountType: data.accountType }}
                /> {' '} <span style={{ marginLeft: '5px' }}>{data.label}</span>
              </div>,
      }
    }));

    if(matchingLoans){
      setLoans(matchingLoans?.map((loan) => { 
        return {
            label: <span>{loan.propertyName}</span>,
            value: loan.propertyName,
            loanId: loan.id
        }
      }));
    }

    if(task){
      let assigned = task.assigned?.map((data) => {
        return {
          ...data,
          value: data.id,
          label: <div style={{display: 'flex', alignItems: 'center'}}>
                  <UserAvatar
                    author={{ firstName: data.firstName, lastName: data.lastName, accountType: data.accountType }}
                  /> {' '} <span style={{ marginLeft: '5px' }}>{data.fullname}</span>
                </div>,
        }
      })

      setInitialValues({
        name: task.name,
        dueDate: task.dueDate && parseDueDate(task.dueDate, 'MM/dd/yyyy'),
        description: task.description,
        loan: {loanId: task.loan?.id, value: task.loan?.propertyName, label: task.loan?.propertyName},
        assigned,
      });
    }
  }, [matchingLoans, teamOptions]);

  const submitForm = (values) => {  
    submit({
      taskId: task && task.id,
      description: values.description || '',
      name: values.name,
      loan_id: isLoanPage ? currentLoan.loanId : values.loan?.loanId,
      due_date: values.dueDate ? format(new Date(values.dueDate), 'yyyy-MM-dd') : undefined,
      assigned: values.assigned?.map((user) => {
        return {
          id: user.value, 
          first_name: user?.firstName, 
          last_name: user?.lastName
      }})
    });
    
  };
  return (
    <Form
      onSubmit={submitForm}
      validate={(values) => validate(values, schema)}
      initialValues={{ ...initialValues}}
      render={({ handleSubmit, submiting, validating }) => (
        <FormWrapper onSubmit={handleSubmit}>
          {!isFetching ? (
          <>
            <ModalHeading>{heading}</ModalHeading>
            <ModalBody>
              <Field
                className={'newTask-input-modal'}
                name="name"
                component={Input}
                label="Task name"
                placeholder={''}
                required/>
              <Field
                isMulti
                data-testid={'newTaskDropdownList'}
                className={'newTask-dropdownList-modal'}
                options={ team }
                name="assigned"
                component={DropdownList}
                label="Assign to"
                placeholder={''}
                mb={4}/>
              <Field
                className={'newTask-datePicker-modal'}
                name="dueDate"
                component={NewDatepicker}
                label="Due date"
                placeholder={''}/>
              <Field
                name="description"
                component={Textarea}
                label="Task description"
                placeholder={''}/>
              {!isLoanPage ? <Field
                options={loans}
                name="loan"
                component={DropdownList}
                label="Link to loan"
                placeholder={'Select...'}/> : <></>}
            </ModalBody>
            <ModalActions>
              <Button
                type="submit"
                secondary
                disabled={submiting || validating}
              >
                {task ? 'Save' : 'Create'}
              </Button>
            </ModalActions>
          </>
          ):(
            <Spinner show style={{width: 'auto'}}/>
          )}
        </FormWrapper>
      )}
    />
  );
}

const loadingSelector = createLoadingSelector([
  getRoughRequestAction(getTasksMatchingLoansRequest().type),
]);

function mapStateToProps(state) {
  return {
    currentLoan: state.loanNew.data,
    matchingLoans: state.myTasks.matchingLoans,  
    teamOptions: state.configuration.options.teamOptions,
    isFetching: loadingSelector(state),
  };
}

export default connect(mapStateToProps)(CurrentTask);

const FormWrapper = styled.form`
  ${modalWithContentWrapperStyles}

  ${DropdownList}{
    .Select__multi-value__label{
      div[class^="UserAvatar__"]{
        display: none;
      }
    }
  }
  
  overflow: initial;

  textarea[name="description"]{
    height: 80px;
    line-height: 21px;
    color: #1C3353;
  }
  input{   
    color: #1C3353;
  }

  .newTask-datePicker-modal,
  .newTask-input-modal{
    display: flex;
    align-items: baseline;

    div[class^="Text__TextCommon-"]{
      width: 85px;
    }
    div[class^="Input__InputWrapCommon-"]{
      width: 100%;
    }
  }
  .newTask-input-modal{
    div:nth-child(3){
      position: absolute;
      width: 100%;
      bottom: -16px;
      left: 95px;
    }
  }

  div[class^="DropDown__WrapperContainer-"]:nth-child(2){
    display: flex;
    width: 100%;

    .newTask-dropdownList-modal{
      width: 100%;
    }
    div[class^="Text__TextCommon-"]{
      width: 85px;
    }
  }
`;
