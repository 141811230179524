import React from 'react';
import styled from 'styled-components/macro';
import { Text } from 'ui/Text';
import { getLoanTerm, getOptionLabel } from 'lib/loan';
import { CURRENCY } from 'constants/appRelated';
import { FormattedNumber } from 'react-intl';
import { OfferRejectReason } from 'components/OfferRejectReason';
import { recourseOptions } from 'utils/fields';
import {
  NOTIFICATION_TYPES,
  BB_ACTIVITY_TYPES,
  LENDER_ACTIVITY_TYPES,
} from 'features/DashboardActionItems/constants';

import User from './User';

const OfferIco = styled.div`
  margin-bottom: 16px;
  width: 45px;
  height: 23px;
  background: url(/images/icons/notifications/${(p) =>
      p.isMy ? 'ico_offer_in.svg' : 'ico_offer_out.svg'});
`;

const OfferWrapper = styled.div`
  max-width: 345px;
  width: 100%;
  background: #fbfbfc;
  padding: 16px;
  margin-bottom: 16px;
`;

const OfferRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px 0;
  border-bottom: 1px solid #e8e9ed;
  &:last-child {
    border-bottom: 0;
    padding-bottom: 0;
  }
`;

const OfferLabel = styled(Text)`
  font-weight: 600;
  font-size: 14px;
`;

const OfferValue = styled(Text)`
  font-size: 14px;
`;

const RejectReasonWrapper = styled.div`
  width: 100%;
  max-width: 345px;
`;

const typesToShowRejectionReason = [
  NOTIFICATION_TYPES.BORROWER_REJECTED_COUNTEROFFER,
  NOTIFICATION_TYPES.BORROWER_REJECTED_OFFER,
  NOTIFICATION_TYPES.COUNTEROFFER_REJECTED,
  BB_ACTIVITY_TYPES.OFFER_REJECTED_BY_LENDER,
  LENDER_ACTIVITY_TYPES.OFFER_REJECTED_BY_BB,
];

export const ContentOffer = ({
  user,
  type,
  offer: {
    loanAmount,
    ltv,
    interest,
    amortization,
    amortizationUnit,
    recourse,
    isMy,
    rejectReason,
  },
}) => (
  <div>
    <OfferIco isMy={isMy} />
    {!!user && <User user={user} />}
    <OfferWrapper>
      <OfferRow>
        <OfferLabel>Loan Amount</OfferLabel>
        <OfferValue>
          <FormattedNumber
            style={CURRENCY}
            currency="USD"
            maximumFractionDigits={0}
            minimumFractionDigits={0}
            value={loanAmount}
          />
        </OfferValue>
      </OfferRow>
      <OfferRow>
        <OfferLabel>Interest</OfferLabel>
        <OfferValue>{(interest && `${interest}%`) || 'N/A'}</OfferValue>
      </OfferRow>
      <OfferRow>
        <OfferLabel>LTV</OfferLabel>
        <OfferValue>{(ltv && `${ltv}%`) || 'N/A'}</OfferValue>
      </OfferRow>
      <OfferRow>
        <OfferLabel>Amortization</OfferLabel>
        <OfferValue>
          {amortization ? getLoanTerm(amortization, amortizationUnit) : 'N/A'}
        </OfferValue>
      </OfferRow>
      <OfferRow>
        <OfferLabel>Recourse</OfferLabel>
        <OfferValue>{getOptionLabel(recourseOptions, recourse) || 'N/A'}</OfferValue>
      </OfferRow>
    </OfferWrapper>
    {rejectReason && typesToShowRejectionReason.includes(type) && (
      <RejectReasonWrapper>
        <OfferRejectReason rejectReason={rejectReason} />
      </RejectReasonWrapper>
    )}
  </div>
);
