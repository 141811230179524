import types from 'constants/actionTypes';
import immutable from 'object-path-immutable';
import { clone } from 'ramda';
import { paginationTmp } from 'config/paginationTmp';

const initialState = {
  loans: [],
  isFetched: false,
  ordering: null,
  ...paginationTmp,
};

export default function loan(state, action = {}) {
  const currentState = state || clone(initialState);

  const { type, payload } = action;

  switch (type) {
    case types.RESET_LOANS:
      return initialState;

    case types.MARKETPLACE_DATA_SUCCESS:
      return immutable(currentState)
        .set('loans', [...payload.response.results.map(loan => loan ? ({...loan, id: loan.uuid, pk: loan.uuid}) : null)])
        .set('next', payload.response.next)
        .set('previous', payload.response.previous)
        .set('pageSize', payload.response.pageSize)
        .set('pageNumber', payload.response.pageNumber)
        .set('count', payload.response.count)
        .set('ordering', payload.ordering)
        .set('isFetched', true)
        .value();

    case types.UPDATE_MARKETPLACE_DATA_SUCCESS:
      return immutable(currentState)
        .set('loans', [...payload.results.map(loan => loan ? ({...loan, id: loan.uuid, pk: loan.uuid}) : null)])
        .set('next', payload.next)
        .set('previous', payload.previous)
        .set('pageSize', payload.pageSize)
        .set('pageNumber', payload.pageNumber)
        .set('count', payload.count)
        .value();

    case types.SET_MARKETPLACE_ORDERING:
      return {
        ...currentState,
        ordering: action.payload.ordering,
      };

    default:
      return currentState;
  }
}
