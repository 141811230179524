import types from 'constants/actionTypes';
import { modals, INFO_MODAL_TYPE } from 'constants/modalTypes';

/*----------SYNC ACTIONS----------*/

export const positionModal = (payload = {}) => ({
  type: types.POSITION_MODAL,
  payload,
});

export const showModal = (modalType, modalProps = {}) => ({
  type: types.SHOW_MODAL,
  modalType,
  modalProps,
});

export const showLenderAcceptOfferPopup = () => ({
  type: types.SHOW_MODAL,
  modalType: modals.INFO_MODAL,
  modalProps: {
    size: 'sm',
    infoType: INFO_MODAL_TYPE.SUCCESS,
    title: 'Hooray on reaching agreement!',
    text:
      'Please prepare and upload your term sheet in the “Term Sheets” tab of the loan request.',
    buttonText: 'Ok',
  },
});

export const showBBAcceptOfferPopup = () => ({
  type: types.SHOW_MODAL,
  modalType: modals.INFO_MODAL,
  modalProps: {
    size: 'sm',
    infoType: INFO_MODAL_TYPE.SUCCESS,
    title: 'Congratulations on getting a good quote!',
    text:
      'We will notify the lender about your decision and ask them to provide a term sheet. Will keep you updated!',
    buttonText: 'Ok',
  },
});

export const hideModal = () => ({ type: types.HIDE_MODAL });

export const updatedOfferModal = (payload = {}) => ({
  type: types.UPDATE_OFFER_MODAL,
  payload,
});

/*----------ASYNC ACTIONS----------*/

export const switchToSearchRequest = (payload = {}) => ({
  type: types.SWITCH_TO_SEARCH_REQUEST,
  payload,
});
export const switchToSearchSuccess = (payload = {}) => ({
  type: types.SWITCH_TO_SEARCH_SUCCESS,
  payload,
});
export const switchToSearchFailure = (payload = {}) => ({
  type: types.SWITCH_TO_SEARCH_FAILURE,
  payload,
});

export const getSwitchToModalRequest = (payload = {}) => ({
  type: types.GET_SWITCH_TO_MODAL_REQUEST,
  payload,
});
export const getSwitchToModalSuccess = (payload = {}) => ({
  type: types.GET_SWITCH_TO_MODAL_SUCCESS,
  payload,
});
export const getSwitchToModalFailure = (payload = {}) => ({
  type: types.GET_SWITCH_TO_MODAL_FAILURE,
  payload,
});

export const switchToMemberViewRequest = (payload = {}) => ({
  type: types.SWITCH_TO_MEMBER_VIEW_REQUEST,
  payload,
});
export const switchToMemberViewSuccess = (payload = {}) => ({
  type: types.SWITCH_TO_MEMBER_VIEW_SUCCESS,
  payload,
});
export const switchToMemberViewFailure = (payload = {}) => ({
  type: types.SWITCH_TO_MEMBER_VIEW_FAILURE,
  payload,
});

export const manualOfferModalRequest = (payload = {}) => ({
  type: types.MANUAL_OFFER_MODAL_REQUEST,
  payload,
});
export const manualOfferModalSuccess = (payload = {}) => ({
  type: types.MANUAL_OFFER_MODAL_SUCCESS,
  payload,
});
export const manualOfferModalFailure = (payload = {}) => ({
  type: types.MANUAL_OFFER_MODAL_FAILURE,
  payload,
});

export const loanDealWonModalRequest = (payload = {}) => ({
  type: types.LOAN_DEAL_WON_MODAL_REQUEST,
  payload,
});
export const loanDealWonModalSuccess = (payload = {}) => ({
  type: types.LOAN_DEAL_WON_MODAL_SUCCESS,
  payload,
});
export const loanDealWonModalFailure = (payload = {}) => ({
  type: types.LOAN_DEAL_WON_MODAL_FAILURE,
  payload,
});

export const getFieldHistoryRequest = (payload = {}) => ({
  type: types.GET_FIELD_HISTORY_MODAL_REQUEST,
  payload,
});
export const getFieldHistorySuccess = (payload = {}) => ({
  type: types.GET_FIELD_HISTORY_MODAL_SUCCESS,
  payload,
});
export const getFieldHistoryFailure = (payload = {}) => ({
  type: types.GET_FIELD_HISTORY_MODAL_FAILURE,
  payload,
});

export const manualTermsheetModalRequest = (payload = {}) => ({
  type: types.MANUAL_TERMSHEET_MODAL_REQUEST,
  payload,
});
export const manualTermsheetModalSuccess = (payload = {}) => ({
  type: types.MANUAL_TERMSHEET_MODAL_SUCCESS,
  payload,
});
export const manualTermsheetModalFailure = (payload = {}) => ({
  type: types.MANUAL_TERMSHEET_MODAL_FAILURE,
  payload,
});
