import types from 'constants/actionTypes';

export const dashboardNotificationsRequest = (payload = {}) => ({
  type: types.DASHBOARD_NOTIFICATIONS_REQUEST,
  payload,
});
export const dashboardNotificationsSuccess = (payload = {}) => ({
  type: types.DASHBOARD_NOTIFICATIONS_SUCCESS,
  payload,
});
export const dashboardNotificationsFailure = (payload = {}) => ({
  type: types.DASHBOARD_NOTIFICATIONS_FAILURE,
  payload,
});

export const notificationAnswerQuestionRequest = (payload = {}) => ({
  type: types.NOTIFICATION_ANSWER_QUESTION_REQUEST,
  payload,
});
export const notificationAnswerQuestionSuccess = (payload = {}) => ({
  type: types.NOTIFICATION_ANSWER_QUESTION_SUCCESS,
  payload,
});
export const notificationAnswerQuestionFailure = (payload = {}) => ({
  type: types.NOTIFICATION_ANSWER_QUESTION_FAILURE,
  payload,
});

export const notificationUploadDocumentRequest = (payload = {}) => ({
  type: types.NOTIFICATION_UPLOAD_DOCUMENT_REQUEST,
  payload,
});
export const notificationUploadDocumentSuccess = (payload = {}) => ({
  type: types.NOTIFICATION_UPLOAD_DOCUMENT_SUCCESS,
  payload,
});
export const notificationUploadDocumentFailure = (payload = {}) => ({
  type: types.NOTIFICATION_UPLOAD_DOCUMENT_FAILURE,
  payload,
});

export const removeNotificationRequest = (payload = {}) => ({
  type: types.REMOVE_NOTIFICATION_REQUEST,
  payload,
});
export const removeNotificationSuccess = (payload = {}) => ({
  type: types.REMOVE_NOTIFICATION_SUCCESS,
  payload,
});
export const removeNotificationFailure = (payload = {}) => ({
  type: types.REMOVE_NOTIFICATION_FAILURE,
  payload,
});

export const uploadLenderDocumentRequest = (payload = {}) => ({
  type: types.UPLOAD_LENDER_DOCUMENT_REQUEST,
  payload,
});
export const uploadLenderDocumentSuccess = (payload = {}) => ({
  type: types.UPLOAD_LENDER_DOCUMENT_SUCCESS,
  payload,
});
export const uploadLenderDocumentFailure = (payload = {}) => ({
  type: types.UPLOAD_LENDER_DOCUMENT_FAILURE,
  payload,
});

export const answerLenderCommentRequest = (payload = {}) => ({
  type: types.ANSWER_LENDER_COMMENT_REQUEST,
  payload,
});
export const answerLenderCommentSuccess = (payload = {}) => ({
  type: types.ANSWER_LENDER_COMMENT_SUCCESS,
  payload,
});
export const answerLenderCommentFailure = (payload = {}) => ({
  type: types.ANSWER_LENDER_COMMENT_FAILURE,
  payload,
});

export const getMoreDashboardActionItemsRequest = (payload = {}) => ({
  type: types.GET_MORE_DASHBOARD_ACTION_ITEMS_REQUEST,
  payload,
});
export const getMoreDashboardActionItemsSuccess = (payload = {}) => ({
  type: types.GET_MORE_DASHBOARD_ACTION_ITEMS_SUCCESS,
  payload,
});
export const getMoreDashboardActionItemsFailure = (payload = {}) => ({
  type: types.GET_MORE_DASHBOARD_ACTION_ITEMS_FAILURE,
  payload,
});

export const filterDashboardActionItemsRequest = (payload = {}) => ({
  type: types.FILTER_DASHBOARD_ACTION_ITEMS_REQUEST,
  payload,
});
export const filterDashboardActionItemsSuccess = (payload = {}) => ({
  type: types.FILTER_DASHBOARD_ACTION_ITEMS_SUCCESS,
  payload,
});
export const filterDashboardActionItemsFailure = (payload = {}) => ({
  type: types.FILTER_DASHBOARD_ACTION_ITEMS_FAILURE,
  payload,
});

export const updateUserDashboardFeedTypeRequest = (payload = {}) => ({
  type: types.UPDATE_USER_DASHBOARD_FEED_TYPE_REQUEST,
  payload,
});
export const updateUserDashboardFeedTypeSuccess = (payload = {}) => ({
  type: types.UPDATE_USER_DASHBOARD_FEED_TYPE_SUCCESS,
  payload,
});
export const updateUserDashboardFeedTypeFailure = (payload = {}) => ({
  type: types.UPDATE_USER_DASHBOARD_FEED_TYPE_FAILURE,
  payload,
});

export const signCaAndOpenDaiDocRequest = (payload = {}) => ({
  type: types.SIGN_CA_AND_OPEN_DAI_DOC_REQUEST,
  payload,
});
export const signCaAndOpenDaiDocSuccess = (payload = {}) => ({
  type: types.SIGN_CA_AND_OPEN_DAI_DOC_SUCCESS,
  payload,
});
export const signCaAndOpenDaiDocFailure = (payload = {}) => ({
  type: types.SIGN_CA_AND_OPEN_DAI_DOC_FAILURE,
  payload,
});

export const updateActionItemsFilters = (payload = {}) => ({
  type: types.UPDATE_ACTION_ITEMS_FILTERS,
  payload,
});

export const setTeammateActionItems = (payload = {}) => ({
  type: types.SET_TEAMMATE_ACTION_ITEMS,
  payload,
});

export const notificationUpdateDocumentProtectionRequest = (payload = {}) => ({
  type: types.NOTIFICATION_UPDATE_DOCUMENT_PROTECTION_REQUEST,
  payload,
});
export const notificationUpdateDocumentProtectionSuccess = (payload = {}) => ({
  type: types.NOTIFICATION_UPDATE_DOCUMENT_PROTECTION_SUCCESS,
  payload,
});
export const notificationUpdateDocumentProtectionFailure = (payload = {}) => ({
  type: types.NOTIFICATION_UPDATE_DOCUMENT_PROTECTION_FAILURE,
  payload,
});
