import types from 'constants/actionTypes';
import immutable from 'object-path-immutable';
import { findIndex, propEq, dissoc } from 'ramda';
import { LOAN_STATUS, LOAN_STATUS_TITLE } from 'constants/appRelated';

const initialState = {
  loans: [{}],
  normalizedLoans: {},
  ordering: null,
  displayVariant: '',
  //invitedLenders: [],
};

export default function reducer(state, action = {}) {
  const currentState = state || initialState;

  const { type, payload } = action;

  switch (type) {
    case types.MY_LOANS_DATA_SUCCESS:
      return {
        ...state,
        loans: payload.loans,
        normalizedLoans: payload.normalizedLoans,
        displayVariant: payload.displayVariant,
        ordering: payload.initialOrdering,
      };
    // case types.LOAN_DEAL_WON_MODAL_SUCCESS:
    //   return {
    //     ...state,
    //     invitedLenders: payload
    //   };
    case types.MY_LOANS_UPDATE_SUCCESS:
      return {
        ...state,
        loans: payload.loans,
        normalizedLoans: payload.normalizedLoans,
      };

    case types.MY_LOANS_ORDERING_UPDATE:
      return immutable.set(currentState, 'ordering', payload);

    case types.CLOSE_LOAN_SUCCESS: {
      const { closeType, loanId } = payload;
      const index = findIndex(propEq('uuid', loanId))(currentState.loans);
      return immutable.update(currentState, `loans.${index}`, (loan) => ({
        ...loan,
        status: closeType === 'won' ? LOAN_STATUS.CLOSED_WON : LOAN_STATUS.CLOSED_LOST,
        statusTitle: LOAN_STATUS_TITLE.CLOSED,
        winningStatus: closeType,
      }));
    }

    case types.ASSIGN_LOAN_SUCCESS: {
      const { shouldRemoveItem, teamId, loanId, data } = payload;
      const fullname = `${data.owner.firstName} ${data.owner.lastName}`;

      return shouldRemoveItem
        ? {
            ...state,
            normalizedLoans: dissoc(loanId, state.normalizedLoans),
            loans: state.loans.filter((loan) => loan.id !== Number(loanId)),
          }
        : {
            ...state,
            normalizedLoans: {
              ...state.normalizedLoans,
              [loanId]: {
                ...state.normalizedLoans[loanId],
                owner: { 
                  ...state.normalizedLoans[loanId].owner, 
                  id: teamId, 
                  firstName: data.owner.firstName, 
                  lastName: data.owner.lastName,
                  fullname,
                },
                ownerType: data.ownerType,
                ownerName: fullname,
                producer: fullname
              },
            },
          };
    }

    default:
      return currentState;
  }
}
