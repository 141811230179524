import React from 'react';
import styled from 'styled-components/macro';
import { iconTypes } from 'features/GlobalSearch/config';
import { SEARCH_TYPES } from 'constants/appRelated'
import { history } from 'store';
import { hideModal } from 'actions/modalActions';
import { connect } from 'react-redux';
import { NAV_SECTIONS } from 'features/LoanNew/constants';
import { modals } from 'constants/modalTypes';
import { getTaskIdSuccess } from 'containers/MyTasksWrapper/actions';
import { showModal } from 'actions/modalActions';
import { getUsersRequest } from 'containers/UserDetails/actions';
import { showChatWidget } from 'containers/ChatWidget/actions';

const Result = ({
    type, label, task, uuid, clientId,
    hideModal, 
    getTaskIdSuccess, 
    showModal, 
    getUsersRequest,
    roomChatId,
    messageId,
    highlights,
    document,
    checklist,
    showChatWidget
}) => {
    const handleRedirectOn = () => {
        hideModal();
        switch (type) {
            case SEARCH_TYPES[0]:
                history.push({
                    pathname:`/dashboard/property-details/${document.uuid}`,
                    search: `?nav=${NAV_SECTIONS.DOCUMENTS}`,
                    state: { highlightsDoc: {highlights, name: document.name} }
                });
              break;
            case SEARCH_TYPES[1]:
                history.push(`/dashboard/search-result`);
                
                getUsersRequest({id: clientId, highlights});
              break;
            case SEARCH_TYPES[2]:
                history.push(`/dashboard/search-result`);
                getTaskIdSuccess({currentTask: {...task, highlights}});
                showModal(modals.REVIEW_TASK_MODAL, { size: 'sm' });
              break;
            case SEARCH_TYPES[3]:
                history.push({
                    pathname:`/dashboard/property-details/${uuid}`,
                    state: { highlights }
                });
              break;
            case SEARCH_TYPES[4]:
                history.push('/dashboard/search-result');
                showChatWidget({ roomId: roomChatId, roomType: 'loan', messageUuid: uuid, highlights, messageId})
              break;
            case SEARCH_TYPES[5]:
                history.push({
                    pathname: `/dashboard/property-details/${checklist.loan.uuid}/deal-room/${checklist.dealroomId}/all`,
                    state: {highlights, name: checklist.name }
                })
              break;
        }
    }

    return (
        <Wrapper onClick={handleRedirectOn} icon={iconTypes[type]}>
            <Label dangerouslySetInnerHTML={{__html: label}} />
            <Type>{type}</Type>
        </Wrapper>
    )
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
    hideModal,
    getTaskIdSuccess,
    showModal,
    getUsersRequest,
    showChatWidget,
}

export default connect( mapStateToProps, mapDispatchToProps)(Result);

const Wrapper = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    &:last-child{
        margin-bottom: 0;
    }
    &::before{
        content: '';
        height: 16px;
        width: 16px;
        background:  url(${(p) => p.icon}) no-repeat center;
    }
`;

const IconType = styled.img``;

const Type = styled.span`
    font-style: italic;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #4394EA;
`;

const Label = styled.span`
    padding-left: 16px;
    margin-right: 8px;

    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #1C3353;

    @media(max-width: 375px){
        font-size: 12px;
    }
`


