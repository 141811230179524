export const asyncActions = [
  'GET_CHAT_ROOMS',
  'GET_ADDITIONAL_CHAT_ROOMS',
  'GET_CHAT_ROOM',
  'UPLOAD_CHAT_DOCUMENT',
  'GET_ADDITIONAL_CHAT_ROOM',
  'READ_ROOM_MESSAGES',
  'CHAT_OFFER_EDIT',
  'CHAT_OFFER_NEGOTIATE',
  'CHAT_OFFER_SUBMIT',
  'CHAT_OFFER_REJECT',
  'CHAT_OFFER_ACCEPT',
  'CHAT_FILE_TO_LOAN',
  'CREATE_CHAT_TEAM_GROUP',
  'SEND_OFFER_TO_CHAT',
  'SEARCH_IN_CHAT',
  'SEARCH_MESSAGE_IN_CHAT',
];
export const syncActions = [
  'SEND_WEBSOCKET_MESSAGE',
  'UPDATE_WEBSOCKET_MESSAGE',
  'HANDLE_WEBSOCKET_MESSAGE',
  'RESET_CHAT_ROOM_COUNTER',
  'SET_CURRENT_CHAT_ROOM',
  'SET_CURRENT_CHAT_TAB',
  'RESET_CHAT_DATA',
  'RESET_CHAT_SEARCH_MESSAGE',
  'RESET_SEARCH_IN_CHAT',
];
