export const allocateUrl = ({ url, urlsFilterSet }) => {
  const urlMap = url
    .replace('?', '')
    .split('&')
    .reduce((acc, param) => [...acc, [...param.split('=')]], []);

  const allocatedUrl = urlMap
    .filter(([field, _]) => urlsFilterSet.indexOf(field) !== -1)
    .map((param) => param.join('='))
    .join('&');

  const restUrl = urlMap
    .filter(([field, _]) => urlsFilterSet.indexOf(field) === -1)
    .map((param) => param.join('='))
    .join('&');

  return { allocatedUrl, restUrl };
};
